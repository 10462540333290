/*
 * Staff Messages
 *
 * This contains all the text for the Staff container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Staff';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Staff container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Staff',
  },
  registrationNumber: {
    id: `${scope}.registrationNumber`,
    defaultMessage: 'Employee Number',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First Name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last Name',
  },
  nominative: {
    id: `${scope}.nominative`,
    defaultMessage: 'Nominative',
  },
  fiscalcode: {
    id: `${scope}.fiscalcode`,
    defaultMessage: 'Fiscal code',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  telephone: {
    id: `${scope}.telephone`,
    defaultMessage: 'Phone',
  },
  new_staff: {
    id: `${scope}.new_staff`,
    defaultMessage: 'New employee',
  },
  role: {
    id: `${scope}.role`,
    defaultMessage: 'Role',
  },
  department: {
    id: `${scope}.department`,
    defaultMessage: 'Department',
  },
});
