/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-underscore-dangle */
import { createSelector } from 'reselect';
import _ from 'lodash';
import { initialState } from './reducer';

/**
 * Direct selector to the can state domain
 */

const selectCanDomain = state => state.get('user', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Can
 */

const makeSelectCan = () =>
  createSelector(selectCanDomain, substate => substate.toJS());

// Turns a dashed-string in a camelString
const camelCased = str => str.replace(/-([a-z])/g, s => s[1].toUpperCase());

// ACL name parsing
const formatACLName = name => {
  const stripVersion = name.split(']/')[1];
  if (name === 'custom') {
    return camelCased(name);
  }
  const detail = stripVersion ? stripVersion.split('[/')[0] : 'customKey';
  if (!!detail) return camelCased(detail);
  return camelCased(stripVersion);
};

// ACL object with camelled and parsed keys
const formattedKeys = substate =>
  _.mapKeys(substate.userIdentity._acl, (value, key) => formatACLName(key));

// Formatted ACL with permissions
const formatACL = substate => {
  const aclObj = formattedKeys(substate);

  Object.keys(aclObj)
    // .filter(key => key !== 'customKey') // custom resource are not parsed but used
    .map(key => {
      const array = aclObj[key];
      aclObj[key] = {
        read: {
          can: _.get(aclObj[key], 'rest.entity', []).includes('GET'),
          onlyMine: _.get(aclObj[key], 'can', []).includes('fetch:own'),
        },
        create: {
          can: _.get(aclObj[key], 'rest.collection', []).includes('POST'), // Collection instead entity cause is not possible and logic to create on a Entity
          onlyMine: _.get(aclObj[key], 'can', []).includes('create:own'), // TODO: Verify if it is possible
        },
        edit: {
          can:
            _.get(aclObj[key], 'rest.entity', []).includes('PUT') ||
            _.get(aclObj[key], 'rest.entity', []).includes('PATCH'), // TODO: Verify if a check on PATCH verb is needed
          onlyMine: _.get(aclObj[key], 'can', []).includes('update:own'), // TODO: Verify if a check on PATCH verb is needed
        },
        remove: {
          can: _.get(aclObj[key], 'rest.entity', []).includes('DELETE'),
          onlyMine: _.get(aclObj[key], 'can', []).includes('delete:own'),
        },
        list: {
          can: _.get(aclObj[key], 'rest.collection', []).includes('GET'),
          onlyMine: _.get(aclObj[key], 'can', []).includes('fetchAll:own'), // TODO: Verify if a check on PATCH verb is needed
        },
        creteBulk: {
          can: _.get(aclObj[key], 'rest.collection', []).includes('POST'),
          onlyMine: false,
        },
        use: {
          // eslint-disable-next-line prettier/prettier
          can: _.get(
            _.get(aclObj[key], ['rpc'], []),
            Object.keys(_.get(aclObj[key], ['rpc'], []))[0],
            [],
          ).includes('POST'),
        },
      };
      if (key === 'custom') {
        const data = {};
        array.map(permission => {
          data[permission] = { can: true };
          return null;
        });
        Object.assign(aclObj.custom, data);
      }
      return false;
    });

  return aclObj;
};

const makeSelectACL = () =>
  createSelector(makeSelectCan(), substate => formatACL(substate));

export default makeSelectCan;
export { selectCanDomain, makeSelectCan, makeSelectACL, formatACL };
