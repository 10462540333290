export const THREE_STATE = {
  INDETERMINATE: null,
  POSITIVE: true,
  NEGATIVE: false,
};

export const THREE_STATE_CLASS = {
  [THREE_STATE.INDETERMINATE]: 'checkbox',
  [THREE_STATE.POSITIVE]: 'checkbox positive',
  [THREE_STATE.NEGATIVE]: 'checkbox negative',
};
