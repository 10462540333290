import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Divider, Statistic } from 'semantic-ui-react';

import messages from './messages';
import CommissionTriangle from './CommissionTriangle';

const CommissionOverall = ({ amount, edited }) => {
  const diff = Math.sign(edited - amount);
  let color = null;

  switch (diff) {
    case -1:
      color = 'red';
      break;

    case 1:
      color = 'green';
      break;

    default:
      break;
  }

  return (
    <Statistic size="huge" color={color}>
      <Statistic.Label>
        <FormattedMessage {...messages.totalAmount} />
      </Statistic.Label>
      <Statistic.Value>
        <CommissionTriangle amount={amount} edited={edited} />
      </Statistic.Value>
    </Statistic>
  );
};

/**
 * The commission statistics.
 * @param {Object <{ values: Object>}} props the Formik properties.
 * @returns a JSX component
 */
const CommissionStatistic = ({ values }) => (
  <>
    <Statistic.Group widths={1}>
      <CommissionOverall
        amount={values.totalAmount}
        edited={values.editedTotalAmount}
      />
    </Statistic.Group>
    <Divider />
    <Statistic.Group widths={3} size="mini">
      <Statistic>
        <Statistic.Label>
          <FormattedMessage {...messages.totalCommissionAmount} />
        </Statistic.Label>
        <Statistic.Value>
          <CommissionTriangle
            amount={values.totalCommissionAmount}
            edited={values.editedTotalCommissionAmount}
          />
        </Statistic.Value>
      </Statistic>
      <Statistic>
        <Statistic.Label>
          <FormattedMessage {...messages.totalRefundAmount} />
        </Statistic.Label>
        <Statistic.Value>
          <CommissionTriangle
            amount={values.totalRefundAmount}
            edited={values.editedTotalRefundAmount}
          />
        </Statistic.Value>
      </Statistic>
      <Statistic>
        <Statistic.Label>
          <FormattedMessage {...messages.totalBonusAmount} />
        </Statistic.Label>
        <Statistic.Value>
          <CommissionTriangle
            amount={values.totalBonusAmount}
            edited={values.editedTotalBonusAmount}
          />
        </Statistic.Value>
      </Statistic>
    </Statistic.Group>
  </>
);

CommissionStatistic.propTypes = {
  values: PropTypes.object.isRequired,
};

export default CommissionStatistic;
