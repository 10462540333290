import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { CanI } from '../Can/utils';
import messages from './messages';

export const menuItems = ({ intl }) =>
  [
    CanI('accessNote', 'custom') && {
      id: 'NotesHub',
      title: (
        <NavLink to="/notes">
          <Icon name="sticky note outline" />
          <FormattedMessage {...messages.notes_hub} />
        </NavLink>
      ),
      content: null,
    },
    (CanI('accessProspect', 'custom') ||
      CanI('accessCustomer', 'custom') ||
      CanI('accessDestination', 'custom') ||
      CanI('accessHeadquarter', 'custom') ||
      CanI('accessProduct', 'custom') ||
      CanI('accessAgent', 'custom') ||
      CanI('accessStaff', 'custom')) && {
      id: 'Registry',
      tooltip: intl.formatMessage(messages.registry),
      title: [
        <Icon key="users" name="users" />,
        intl.formatMessage(messages.registry),
      ],
      sections: [
        CanI('accessProspect', 'custom') && {
          id: 'Prospect',
          title: (
            <NavLink to="/prospect">
              <Icon name="pencil alternate" />
              <FormattedMessage {...messages.prospects} />
            </NavLink>
          ),
          content: null,
        },
        CanI('accessCustomer', 'custom') && {
          id: 'Customers',
          title: (
            <NavLink to="/customer">
              <Icon name="id card" />
              <FormattedMessage {...messages.customers} />
            </NavLink>
          ),
          content: null,
        },
        CanI('accessDestination', 'custom') && {
          id: 'Destination',
          title: (
            <NavLink to="/destination">
              <Icon name="map marker alternate" />
              <FormattedMessage {...messages.destinations} />
            </NavLink>
          ),
          content: null,
        },
        CanI('accessHeadquarter', 'custom') && {
          id: 'Headquarter',
          title: (
            <NavLink to="/headquarter">
              <Icon name="home" />
              <FormattedMessage {...messages.headquarters} />
            </NavLink>
          ),
          content: null,
        },
        CanI('accessProduct', 'custom') && {
          id: 'products',
          title: (
            <NavLink to="/product">
              <Icon name="list layout" />
              {intl.formatMessage(messages.products)}
            </NavLink>
          ),
          content: null,
        },
        CanI('accessAgent', 'custom') && {
          id: 'Agent',
          title: (
            <NavLink to="/agent">
              <Icon name="id card" />
              <FormattedMessage {...messages.agents} />
            </NavLink>
          ),
          content: null,
        },
        CanI('accessStaff', 'custom') && {
          id: 'Staff',
          title: (
            <NavLink to="/staff">
              <Icon name="id card" />
              <FormattedMessage {...messages.staff} />
            </NavLink>
          ),
          content: null,
        },
      ].filter(o => o),
    },
    (CanI('accessMasterPriceList', 'custom') ||
      CanI('accessCustomerPriceList', 'custom')) && {
      id: 'PriceList',
      tooltip: intl.formatMessage(messages.price_list),
      title: [<Icon name="tags" />, intl.formatMessage(messages.price_list)],
      sections: [
        CanI('accessMasterPriceList', 'custom') && {
          id: 'PriceListMaster',
          tooltip: intl.formatMessage(messages.price_list_master),
          title: (
            <NavLink to="/price-list-master">
              <Icon name="tag" />
              <FormattedMessage {...messages.price_list_master} />
            </NavLink>
          ),
          content: null,
        },
        CanI('accessCustomerPriceList', 'custom') && {
          id: 'PriceListCustomer',
          tooltip: intl.formatMessage(messages.price_list_customer),
          title: (
            <NavLink to="/price-list-customer">
              <Icon name="tag" />
              <FormattedMessage {...messages.price_list_customer} />
            </NavLink>
          ),
          content: null,
        },
      ].filter(o => o),
    },
    CanI('accessOffer', 'custom') && {
      id: 'Offer',
      tooltip: intl.formatMessage(messages.offers),
      title: (
        <NavLink to="/offer">
          <Icon name="handshake" />
          <FormattedMessage {...messages.offers} />
        </NavLink>
      ),
      content: null,
    },
    CanI('accessOrder', 'custom') && {
      id: 'Orders',
      tooltip: intl.formatMessage(messages.orders),
      title: (
        <NavLink to="/order">
          <Icon name="cart" />
          <FormattedMessage {...messages.orders} />
        </NavLink>
      ),
      content: null,
    },
    CanI('accessStaffWorkorder', 'custom') && {
      id: 'Staff workorders',
      tooltip: intl.formatMessage(messages.menu_technical_interventions),
      title: (
        <NavLink to="/staff-workorder">
          <Icon name="wrench" />
          <FormattedMessage {...messages.menu_technical_interventions} />
        </NavLink>
      ),
      content: null,
    },
    CanI('accessInvoice', 'custom') && {
      id: 'Invoices',
      tooltip: intl.formatMessage(messages.menu_invoices),
      title: (
        <NavLink to="/invoice">
          <Icon name="file alternate outline" />
          <FormattedMessage {...messages.menu_invoices} />
        </NavLink>
      ),
      content: null,
    },
    CanI('accessCommission', 'custom') && {
      id: 'Commission',
      title: (
        <NavLink to="/commission">
          <Icon name="id card" />
          <FormattedMessage {...messages.menu_commissions} />
        </NavLink>
      ),
      content: null,
    },
    CanI('accessAppointment', 'custom') && {
      id: 'Agenda',
      tooltip: intl.formatMessage(messages.menu_agenda),
      title: (
        <NavLink to="/agenda">
          <Icon name="calendar alternate outline" />
          <FormattedMessage {...messages.menu_agenda} />
        </NavLink>
      ),
      content: null,
    },
    CanI('accessSetting', 'custom') && {
      id: 'Settings',
      tooltip: intl.formatMessage(messages.settings),
      title: [
        <Icon key="cog" name="cog" />,
        intl.formatMessage(messages.settings),
      ],
      sections: [
        {
          id: 'Users',
          title: (
            <NavLink exact to="/gestione-utenti">
              <Icon name="users" />
              <FormattedMessage {...messages.menu_users} />
            </NavLink>
          ),
          content: null,
        },
        {
          id: 'Gestione-acl',
          title: (
            <NavLink to="/gestione-acl">
              <Icon name="id card" />
              <FormattedMessage {...messages.menu_roles_acl} />
            </NavLink>
          ),
          content: null,
        },
        CanI('accessProductCategory', 'custom') && {
          id: 'Product-categories',
          title: (
            <NavLink to="/product-categories">
              <Icon name="sitemap" />
              <FormattedMessage {...messages.menu_categories} />
            </NavLink>
          ),
          content: null,
        },
        {
          id: 'Editable-lists',
          title: (
            <NavLink to="/editable-lists">
              <Icon name="cog" />
              <FormattedMessage {...messages.menu_editable_lists} />
            </NavLink>
          ),
          content: null,
        },
        CanI('accessSupplier', 'custom') && {
          id: 'Supplier',
          title: (
            <NavLink to="/supplier">
              <Icon name="shipping" />
              <FormattedMessage {...messages.suppliers} />
            </NavLink>
          ),
          content: null,
        },
      ],
    },
  ].filter(o => o);

export default menuItems;
