
import { get } from 'lodash';
import moment from 'moment';
import SimpleFormikField from 'novigo-simple-formik-field';
import React from 'react';
import { Button, Grid, Popup, Icon, Checkbox } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import FormikDate from '../../components/FormikDate';
import { PaddedColumn } from '../../components/Layout';
import DestinationService from '../../shared/services/destination';
import OrderService from '../../shared/services/order';
import OrderTypologyService from '../../shared/services/ordertypology';
import AgentService from '../../shared/services/agent';
import appMessages from '../App/messages';
import messages from './messages';
import PermissionManager from '../../components/Permission';
import ProductService from '../../shared/services/product';
import FormikInput from '../../components/FormikInput';
import { RemoveBtn } from '../../components/Buttons';
import { getNetPrice } from '../../components/Forms/OrderForm/manageForm/functions';
import { applyMerchDiscount } from './functions';

export const DEFAULT_ACTION = 'app/Order/DEFAULT_ACTION';

export const ORDER_STATE_INSERTED = 'Inserito';
export const ORDER_STATE_TO_BE_ACCEPTED = 'Da Autorizzare';
export const ORDER_STATE_ACCEPTED = 'Approvato';
export const ORDER_STATE_NOT_ACCEPTED = 'Non Approvato';
export const ORDER_STATE_SUSPENDED = 'Sospeso';
export const ORDER_STATE_ALL = 'TUTTI';

export const ORDER_STATES = [
  ORDER_STATE_INSERTED,
  ORDER_STATE_TO_BE_ACCEPTED,
  ORDER_STATE_ACCEPTED,
  ORDER_STATE_NOT_ACCEPTED,
  ORDER_STATE_SUSPENDED,
];

export const RELATED_TABLES = [
  {
    type: 'leftjoin',
    field: 'destination',
    alias: 'destination',
  },
  {
    type: 'leftjoin',
    field: 'orderType',
    alias: 'orderType',
  },
  { type: 'eq', field: 'finalized', value: 1 },
  { type: 'eq', field: 'state', value: ORDER_STATE_ACCEPTED },
];

export const OrderStateColorMap = {
  [ORDER_STATE_ACCEPTED]: 'green',
  [ORDER_STATE_SUSPENDED]: 'olive',
  [ORDER_STATE_INSERTED]: 'yellow',
  [ORDER_STATE_TO_BE_ACCEPTED]: 'yellow',
  [ORDER_STATE_NOT_ACCEPTED]: 'yellow',
};

const NetPriceEdited = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  span:nth-child(2) {
    font-size: 8pt;
    color: darkgrey;
  }
`;
/**
 * Returns the list of columns to be shown in the orders table.
 * @param {*} intl
 * @param {*} manageAction
 * @returns
 */
export const OrderColumns = (intl, manageAction) => [
  {
    key: 'number',
    name: intl.formatMessage(messages.orderNumber),
    searchable: true,
    sortable: true,
    width: 1,
    formatter: ({ data }) => {
      const number = data.documentNumber || data.number || '--';
      return number;
    },
  },
  {
    key: 'code',
    name: intl.formatMessage(messages.customer),
    useAlias: 'destination',
    searchable: true,
    sortable: true,
    formatter: data => get(data, 'data._embedded.destination.code', '--'),
    exportFormatter: data => get(data, 'data._embedded.destination.code', '--'),
  },
  {
    key: 'businessName',
    name: intl.formatMessage(messages.businessName),
    useAlias: 'destination',
    searchable: true,
    sortable: true,
    width: 400,
    formatter: data => {
      const businessName = get(
        data,
        'data._embedded.destination.businessName',
        '--',
      );
      const { internalNote, delivery, state } = data.data;
      const stateColor = OrderStateColorMap[state] || 'grey';

      return (
        <div
          style={{
            height: '100%',
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              background: `${stateColor}`,
              width: '4px',
              borderRadius: '2px',
              height: 'calc(100% + 8px)',
              position: 'absolute',
              top: '-4px',
            }}
          />
          <div style={{ marginLeft: '1rem' }}>
            {businessName}
            {delivery && (
              <>
                <br />
                <b>{intl.formatMessage(messages.destinationDelivery)}</b>
                {delivery}
              </>
            )}
            {internalNote && (
              <Popup
                inverted
                trigger={
                  <div className="note">
                    <b>{intl.formatMessage(messages.orderNote)}</b>
                    {internalNote}
                  </div>
                }
                content={internalNote}
                position="bottom left"
                style={{ borderRadius: '6px' }}
              />
            )}
          </div>
        </div>
      );
    },
    exportFormatter: data =>
      get(data, 'data._embedded.destination.businessName', '--'),
  },
  {
    key: 'agent',
    name: 'Agente',
    formatter: ({ data }) => {
      const agent = get(data, '_embedded.destination.agent1', null);
      return agent ? `${agent.displayName} (${agent.code})` : '--';
    },
    exportFormatter: ({ data }) => {
      const agent = get(data, '_embedded.destination.agent1', null);
      return agent ? `${agent.displayName} (${agent.code})` : '--';
    },
    searchable: false,
    sortable: false,
  },
  {
    key: 'referenceDate',
    name: intl.formatMessage(messages.orderDate),
    formatter: ({ data }) => {
      const { referenceDate } = data;
      const formattedReferenceDate = referenceDate
        ? moment(referenceDate).format('DD/MM/YYYY')
        : '--';
      return formattedReferenceDate;
    },
    searchable: true,
    sortable: true,
  },
  {
    key: 'referenceTime',
    name: 'Ora',
    formatter: ({ data }) => {
      const { referenceDate } = data;

      if (!referenceDate) {
        return '--';
      }

      const date = moment.utc(referenceDate, "YYYY-MM-DD HH:mm:ss").local();

      // Format hours, minutes, and seconds with leading zeros if necessary
      const formattedTime = date.format('HH:mm:ss');

      return formattedTime;
    },
    searchable: true,
    sortable: true,
  },
  {
    key: 'totalPriceAmount',
    formatter: ({ data }) => {
      const totalPriceAmount = data.totalPriceAmount || '0.00';
      return totalPriceAmount;
    },
    name: intl.formatMessage(messages.totalPriceAmount),
    searchable: true,
    sortable: true,
  },
  {
    key: 'avarageDiscount',
    formatter: ({ data }) => {
      const averageDiscount = data.avarageDiscount || '0.00';
      return parseFloat(averageDiscount).toFixed(2);
    },
    name: intl.formatMessage(messages.averageDiscount),
    searchable: true,
    sortable: true,
  },
  {
    key: 'maxDiscount',
    formatter: ({ data }) => {
      const maxDiscount = data.maxDiscount || '0.00';
      return maxDiscount;
    },
    name: intl.formatMessage(messages.maxDiscount),
    searchable: true,
    sortable: true,
  },
  {
    key: 'actions',
    name: intl.formatMessage(messages.actions),
    searchable: false,
    sortable: false,
    formatter: ({ data }) => OrderActions(manageAction, data),
  },
];

/**
 * Returns the list of columns to be shown in the order rows table.
 * @param {*} intl
 * @param {*} onChangeRowData
 * @param {*} onAction
 * @param {*} isEdit
 * @returns
 */
export const OrderRowColumns = (
  intl,
  onChangeRowData,
  onAction,
  isEdit,
  handleOnArticleRowChange,
) => [
  // Product code
  {
    key: 'product.productCode',
    name: isEdit
      ? `${intl.formatMessage(messages.productCode)} / ${intl.formatMessage(
        messages.productNote,
      )}`
      : intl.formatMessage(messages.productCode),
    searchable: true,
    sortable: true,
    width: isEdit ? 'auto' : 1,
    minWidth: isEdit ? '200px' : 1,
    formatter: ({ data }) =>
      isEdit ? (
          <>
            <FormikAsyncSelect
              name="product"
              label=" "
              initialValue={data.product}
              onChange={product => handleOnArticleRowChange(product.value, data)}
              asyncSelectProps={{
                isClearable: true,
              }}
              loadOptions={(searchText, callback) =>
                ProductService.getOptions(searchText, callback)
              }
            />

            <FormikInput
              label=" "
              placeholder="Nota interna"
              name="note"
              initialValue={data.note}
              onChange={e => onChangeRowData(e.target.value, 'note', data.id)}
            />
          </>
      ) : (
          <>
            <span>{get(data, 'product.productCode', '')}</span>
            {data.isProcessed && (
              <Popup
                trigger={
                  <Icon
                    name="check circle"
                    style={{
                      fontSize: '16px',
                      marginLeft: '10px',
                      color: 'rgb(33, 133, 208)',
                    }}
                  />
                }
                inverted
                content="Articolo evaso"
                position="top center"
                style={{ borderRadius: '6px' }}
              />
            )}
            <div>{get(data, 'product.title', '')}</div>
          </>
      ),
  },
  ...(isEdit
    ? []
    : [
      {
        key: 'product.notes',
        name: intl.formatMessage(messages.productNote),
        searchable: true,
        sortable: true,
        width: 200,
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => <NetPriceEdited>
          <span>{get(data, 'note', '')}</span>
          {data.netPriceEdited && <span>{intl.formatMessage(messages.net_price_edited_message)}</span>}
        </NetPriceEdited>,
      },
    ]),
  // Product price master
  {
    key: 'productPriceMaster',
    name: intl.formatMessage(messages.productPriceMaster),
    searchable: true,
    sortable: true,
    width: 1,
    formatter: ({ data }) => data.productMasterPrice,
  },
  // Product price customer
  {
    key: 'productPriceCustomer',
    name: intl.formatMessage(messages.productPriceCustomer),
    searchable: true,
    sortable: true,
    width: 1,
    formatter: ({ data }) => data.productCustomerPrice,
  },
  // Price offer
  {
    key: 'offerPrice',
    name: intl.formatMessage(messages.priceOffer),
    searchable: true,
    sortable: true,
    width: 1,
    formatter: ({ data }) => {
      const offerPrice = get(data, 'offerPrice', null)
        ? parseFloat(data.offerPrice).toFixed(2)
        : '--';
      return offerPrice;
    },
  },
  // Net price
  {
    key: 'netPrice',
    name: intl.formatMessage(messages.netPrice),
    searchable: true,
    sortable: true,
    width: isEdit ? 100 : 1,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) =>
      isEdit ? (
        <FormikInput
          name="netPrice"
          label=" "
          disabled={data.netPriceDisabled}
          initialValue={data.netPrice}
          onChange={e => {
            const np = getNetPrice(data);
            const value = e.target.value.replace(',', '.');
            if (
              np !== null &&
                parseFloat(np) !== 0 &&
                data.productMasterPrice !== 0
            )
              onChangeRowData(
                parseFloat(
                  100 - (value * 100) / data.productMasterPrice,
                ).toFixed(2),
                'discountValue',
                data.id,
              );

            onChangeRowData('0', 'extraDiscount', data.id);
            onChangeRowData(value, 'netPriceRow', data.id);
            onChangeRowData(true, 'netPriceEdited', data.id);
            onChangeRowData(
              e.target.value * parseFloat(data.quantity, 10),
              'priceAmount',
              data.id,
            );

            onChangeRowData(
              e.target.value.replace(/[^0-9.]/g, ''),
              'netPrice',
              data.id,
            );

            if (parseFloat(data.buyPrice) !== 0)
              onChangeRowData(
                (
                  parseFloat((data.netPrice - data.buyPrice) / data.buyPrice) *
                    100
                ).toFixed(2),
                'markup',
                data.id,
              );
          }}
        />
      ) : (
        get(data, 'netPrice', '')
      ),
  },
  // Markup
  {
    key: 'markup',
    name: intl.formatMessage(messages.markup),
    searchable: true,
    sortable: true,
    width: 1,
    minWidth: isEdit ? '100px' : 1,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) =>
      isEdit ? (
        <FormikInput
          name="markup"
          label=" "
          initialValue={data.markup}
          onChange={e => {
            const value = e.target.value.replace(',', '.');
            const np =
                (value / 100) * parseFloat(data.buyPrice) +
                parseFloat(data.buyPrice);

            if (np !== null && parseFloat(np) !== 0 && data.buyPrice !== 0)
              onChangeRowData(value, 'markup', data.id);

            onChangeRowData(np, 'netPrice', data.id);
            onChangeRowData(
              parseFloat(100 - (np * 100) / data.productMasterPrice).toFixed(2),
              'discountValue',
              data.id,
            );
            onChangeRowData('0', 'extraDiscount', data.id);
            onChangeRowData(
              parseFloat(np * parseFloat(data.quantity, 10)).toFixed(2),
              'priceAmount',
              data.id,
            );
          }}
        />
      ) : (
        get(data, 'markup', '')
      ),
  },
  // Quantity
  {
    key: 'quantity',
    name: intl.formatMessage(messages.qt),
    searchable: true,
    sortable: true,
    width: isEdit ? 100 : 1,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) =>
      isEdit ? (
        <FormikInput
          name="quantity"
          label=" "
          initialValue={data.quantity}
          onChange={e => {
            onChangeRowData(e.target.value, 'quantity', data.id);
            onChangeRowData(
              data.netPrice * parseFloat(data.quantity, 10),
              'priceAmount',
              data.id,
            );
          }}
        />
      ) : (
        get(data, 'quantity', '')
      ),
  },
  // Discount value
  {
    key: 'discountValue',
    name: intl.formatMessage(messages.discountValue),
    searchable: true,
    sortable: true,
    width: isEdit ? 100 : 1,
    formatter: ({ data }) =>
      isEdit ? (
          <>
            <FormikInput
              name="discountValue"
              label=" "
              disabled={data.discountDisabled || false}
              placeholder={intl.formatMessage(messages.discountValue)}
              initialValue={data.discountValue}
              onChange={e => {
                let basePrice = getNetPrice(data);
                let discountExtra = data.extraDiscount;

                const totalDiscount =
                  parseFloat(e.target.value) + parseFloat(discountExtra);
                if (totalDiscount !== 0) basePrice = data.productMasterPrice;

                const value = parseFloat(e.target.value.replace(',', '.'), 10);
                const discount = value || 0;
                discountExtra = parseFloat(discountExtra) || 0;
                if (discountExtra !== '') {
                  const discountedBase = (basePrice / 100) * (100 - discount);
                  onChangeRowData(
                    parseFloat(
                      (discountedBase / 100) * (100 - discountExtra),
                    ).toFixed(2),
                    'netPrice',
                    data.id,
                  );
                }

                onChangeRowData(false, 'netPriceEdited', data.id);
                onChangeRowData(
                  data.netPrice * parseFloat(data.minQuantity, 10),
                  'amount',
                  data.id,
                );

                if (parseFloat(data.buyPrice) !== 0)
                  onChangeRowData(
                    (
                      parseFloat(
                        (data.netPrice - data.buyPrice) / data.buyPrice,
                      ) * 100
                    ).toFixed(2),
                    'markup',
                    data.id,
                  );

                if (e.target.value !== '')
                  onChangeRowData(e.target.value, 'discountValue', data.id);
                else onChangeRowData('0.00', 'discountValue', data.id);
              }}
            />

            <FormikInput
              name="extraDiscount"
              label=" "
              disabled={data.discountExtraDisabled || false}
              placeholder={intl.formatMessage(messages.extraDiscount)}
              initialValue={data.extraDiscount}
              onChange={e => {
                let basePrice = getNetPrice(data);
                let discount = data.discountValue;

                // NOTE: If any discount is applied use productMasterPrice as base
                if (parseFloat(discount) + parseFloat(e.target.value) !== 0)
                  basePrice = data.productMasterPrice;

                const value = parseFloat(e.target.value, 10);
                const discountExtra = value || 0;
                discount = parseFloat(discount) || 0;
                if (discountExtra !== '') {
                  const discountedBase = (basePrice / 100) * (100 - discount);
                  onChangeRowData(
                    parseFloat(
                      (discountedBase / 100) * (100 - discountExtra),
                    ).toFixed(2),
                    'netPrice',
                    data.id,
                  );
                }

                onChangeRowData(
                  data.netPrice * parseFloat(data.minQuantity, 10),
                  'amount',
                  data.id,
                );

                if (parseFloat(data.buyPrice) !== 0)
                  onChangeRowData(
                    (
                      parseFloat(
                        (data.netPrice - data.buyPrice) / data.buyPrice,
                      ) * 100
                    ).toFixed(2),
                    'markup',
                    data.id,
                  );

                if (e.target.value !== '')
                  onChangeRowData(e.target.value, 'extraDiscount', data.id);
                else onChangeRowData('0.00', 'extraDiscount', data.id);
              }}
            />
          </>
      ) : (
          <>
            {get(data, 'discountValue', '')}
            <br />
            {get(data, 'extraDiscount', '')}
          </>
      ),
  },
  // Price amount
  {
    key: 'priceAmount',
    name: intl.formatMessage(messages.priceAmount),
    searchable: true,
    sortable: true,
    width: 1,
    formatter: ({ data }) => get(data, 'priceAmount', ''),
  },
  // Goods discount
  {
    key: 'goodsDiscount',
    name: intl.formatMessage(messages.goodsDiscount),
    searchable: true,
    sortable: true,
    width: 1,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) =>
      isEdit ? (
        <Checkbox
          name="goodsDiscount"
          label=" "
          checked={data.goodsDiscount}
          onChange={(value, toggleData) => {
            applyMerchDiscount(
              toggleData.checked,
              data,
              onChangeRowData,
              getNetPrice,
            );
            onChangeRowData(toggleData.checked, 'goodsDiscount', data.id);
            onChangeRowData(false, 'loan', data.id);
          }}
        />
      ) : (
          <>
            {data.goodsDiscount
              ? intl.formatMessage(messages.yes)
              : intl.formatMessage(messages.no)}
          </>
      ),
  },
  // Loan
  {
    key: 'loan',
    name: intl.formatMessage(messages.loan),
    searchable: true,
    sortable: true,
    width: 1,
    formatter: ({ data }) =>
      isEdit ? (
          <>
            <Checkbox
              name="loan"
              label=" "
              checked={data.loan}
              onChange={(value, toggleData) => {
                applyMerchDiscount(
                  toggleData.checked,
                  data,
                  onChangeRowData,
                  getNetPrice,
                );
                onChangeRowData(toggleData.checked, 'loan', data.id);
                onChangeRowData(false, 'goodsDiscount', data.id);
              }}
            />
          </>
      ) : (
          <>
            {data.loan
              ? intl.formatMessage(messages.yes)
              : intl.formatMessage(messages.no)}
          </>
      ),
  },
  // Actions
  ...(!isEdit
    ? []
    : [
      {
        key: 'actions',
        name: intl.formatMessage(messages.actions),
        width: 70,
        searchable: true,
        sortable: true,
        formatter: ({ data }) => OrderRowActions(onAction, data),
      },
    ]),
];

export const OrderActions = (manageAction, data) => {
  const canView = PermissionManager.CanI('read', 'order');
  const canEdit =
    PermissionManager.CanI('edit', 'order') &&
    data.state !== ORDER_STATE_ACCEPTED &&
    !data.imported;
  // const state = data ? data.state : '';
  // const canApprove =
  //   PermissionManager.CanI('statusUpdateApproved', 'custom') &&
  //   state !== ORDER_STATE_ACCEPTED &&
  //   !data.imported;
  // const canUnapprove =
  //   PermissionManager.CanI('statusUpdateApproved', 'custom') &&
  //   ![ORDER_STATE_NOT_ACCEPTED, ORDER_STATE_ACCEPTED].includes(state) &&
  //   !data.imported;

  return (
    <div>
      {/* Show button */}
      {canView ? (
        <span className="order-action">
          <Popup
            basic
            content={<FormattedMessage {...appMessages.action_view} />}
            trigger={
              <Button
                circular
                color="blue"
                icon="eye"
                onClick={() => manageAction('view', data)}
              />
            }
          />
        </span>
      ) : null}
      {/* Edit button */}
      {canEdit ? (
        <span className="order-action">
          <Popup
            basic
            content={<FormattedMessage {...appMessages.action_modify} />}
            trigger={
              <Button
                circular
                color="blue"
                icon="edit"
                onClick={() => manageAction('modify', data)}
              />
            }
          />
        </span>
      ) : null}
      {/* Delete button */}
      {canEdit ? (
        <span className="order-action">
          <Popup
            basic
            content={<FormattedMessage {...appMessages.action_delete} />}
            trigger={
              <Button
                circular
                color="red"
                icon="trash"
                onClick={() => manageAction('delete', data)}
              />
            }
          />
        </span>
      ) : null}
      {/* <ButtonDropdownCollapse
        key="collapse-button"
        dropdownItems={[
          <Dropdown.Item
            style={{ color: 'red' }}
            key={<FormattedMessage {...appMessages.action_delete} />}
            onClick={() => manageAction('delete', data)}
          >
            <Icon name="trash" />
            <FormattedMessage {...appMessages.action_delete} />
          </Dropdown.Item>,
          ...(canApprove
            ? [
                <Dropdown.Item
                  style={{ color: 'green' }}
                  key={<FormattedMessage {...appMessages.action_approve} />}
                  onClick={() => manageAction('approve', data)}
                >
                  <Icon name="checkmark" />
                  <FormattedMessage {...appMessages.action_approve} />
                </Dropdown.Item>,
              ]
            : []),
          ...(canUnapprove
            ? [
                <Dropdown.Item
                  style={{ color: 'orange' }}
                  key={<FormattedMessage {...appMessages.action_not_approve} />}
                  onClick={() => manageAction('unapprove', data)}
                >
                  <Icon name="ban" />
                  <FormattedMessage {...appMessages.action_not_approve} />
                </Dropdown.Item>,
              ]
            : []),
        ]}
        isGrouped={false}
      /> */}
    </div>
  );
};

export const OrderRowActions = (onAction, data) => {
  const canDelete = PermissionManager.CanI('remove', 'orderRow');

  return (
    <>
      {/* Delete button */}
      {canDelete ? (
        <span className="order-action">
          <RemoveBtn
            action={() => onAction('delete', data)}
            notice
            icon="trash"
          />
        </span>
      ) : null}
    </>
  );
};

/**
 * Returns the list of order filters.
 * @param {*} intl
 * @returns
 */
export const OrderFilters = intl => ({
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  formStructure: (
    <>
      <Grid.Column>
        <SimpleFormikField
          name="number"
          label={intl.formatMessage(messages.orderNumber)}
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="destination"
          label={intl.formatMessage(messages.customer)}
          loadOptions={(searchText, callback) =>
            DestinationService.getOptions(searchText, callback, true)
          }
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <FormikDate
          name="createdAtFrom"
          label={intl.formatMessage(messages.start)}
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <FormikDate
          name="createdAtTo"
          label={intl.formatMessage(messages.end)}
        />
      </Grid.Column>
      <PaddedColumn>
        <FormikAsyncSelect
          name="state"
          label={intl.formatMessage(messages.state)}
          loadOptions={(searchText, callback) =>
            OrderService.getStateOptions(callback)
          }
        />
      </PaddedColumn>

      <PaddedColumn>
        <FormikAsyncSelect
          name="agent"
          label={intl.formatMessage(messages.agent)}
          loadOptions={(searchText, callback) =>
            AgentService.getOptions(searchText, callback)
          }
        />
      </PaddedColumn>
      <PaddedColumn>
        <FormikAsyncSelect
          name="orderType"
          label={intl.formatMessage(messages.orderType)}
          loadOptions={(searchText, callback) =>
            OrderTypologyService.getOptions(searchText, callback)
          }
        />
      </PaddedColumn>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];

    if (values.number) {
      filterLabels.push({
        key: 'number',
        name: intl.formatMessage(messages.orderNumber),
        value: values.number,
        filterField: 'number',
      });
      filters.push({
        type: 'like',
        where: 'and',
        field: 'number',
        value: `%${values.number}%`,
      });
    }

    if (values.destination) {
      filterLabels.push({
        key: 'destination',
        name: intl.formatMessage(messages.destination),
        value: values.destination.label,
        filterField: 'id',
      });
      filters.push({
        type: 'like',
        where: 'and',
        alias: 'destination',
        field: 'id',
        value: `%${values.destination.value}%`,
      });
    }

    if (values.createdAtFrom) {
      filterLabels.push({
        key: 'createdAtFrom',
        name: intl.formatMessage(messages.start),
        value: `${moment(values.createdAtFrom).format('YYYY-MM-DD')} `,
        filterField: 'createdAtFrom',
      });
      filters.push({
        type: 'gte',
        where: 'and',
        field: 'createdAt',
        value: `${moment(values.createdAtFrom).format('YYYY-MM-DD')} 00:00:00`,
        format: 'Y-m-d H:i:s',
      });
    }

    if (values.createdAtTo) {
      filterLabels.push({
        key: 'createdAtTo',
        name: intl.formatMessage(messages.end),
        value: `${moment(values.createdAtTo).format('YYYY-MM-DD')}`,
        filterField: 'referenceDate',
      });
      filters.push({
        type: 'lte',
        where: 'and',
        field: 'createdAt',
        value: `${moment(values.createdAtTo).format('YYYY-MM-DD')} 23:59:59`,
        format: 'Y-m-d H:i:s',
      });
    }
    if (values.state) {
      filterLabels.push({
        key: 'state',
        name: intl.formatMessage(messages.state),
        value: values.state.label,
        filterField: 'state',
      });
      if (values.state.value) {
        filters.push({
          type: 'eq',
          where: 'and',
          field: 'state',
          value: `${values.state.value}`,
        });
      } else {
        filters.push({
          type: 'isnotnull',
          where: 'and',
          field: 'state',
        });
      }
    }
    if (values.agent) {
      filterLabels.push({
        key: 'agent',
        name: intl.formatMessage(messages.agent),
        value: values.agent.label,
        filterField: 'agent1Name',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'agent1',
        alias: 'destination',
        value: `${values.agent.value}`,
      });
    }

    if (values.orderType) {
      filterLabels.push({
        key: 'orderType',
        name: intl.formatMessage(messages.orderType),
        value: values.orderType.label,
        filterField: 'id',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'id',
        alias: 'orderType',
        value: `${values.orderType.value}`,
      });
    }

    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
});
