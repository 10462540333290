/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Async as AsyncSelect } from 'react-select';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form } from 'semantic-ui-react';
import AddressPicker from 'novigo-address-picker';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikField from '../../../FormikField';
import { SearchAddressWrapper, PaddedColumn } from '../../../Layout';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import FiscalCodeField from './fiscalCodeField';
import FormikSelect from '../../../FormikSelect';
import FormikDate from '../../../FormikDate';

/**
 * TODO move out these elements using the ocnctants
 */
const sexOptions = [
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'F',
    value: 'F',
  },
];

const formatComuneWithProvincia = data =>
  data.birthProvinceShort
    ? `${data.birthCity} (${data.birthProvinceShort})`
    : data.birthCity;

function IndividualPart(props) {
  const {
    values,
    setFieldValue,
    readOnly = false,
    municipalityService,
  } = props;

  const fiscalDomicileOptions = [
    {
      label: props.intl.formatMessage(messages.resident),
      value: 'R',
    },
    {
      label: props.intl.formatMessage(messages.not_resident),
      value: 'N',
    },
  ];

  return (
    <div>
      <Grid>
        <Grid.Row columns={3}>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="firstName"
              label={props.intl.formatMessage(messages.name)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="lastName"
              label={props.intl.formatMessage(messages.surname)}
              required
            />
          </PaddedColumn>
          <PaddedColumn style={{ zIndex: 999 }}>
            <FormikField
              name="birthCity"
              label={props.intl.formatMessage(messages.city_of_birth)}
              {...props}
            >
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={{
                  label: formatComuneWithProvincia(values),
                  value: values.birthCity,
                }}
                loadOptions={_.debounce(
                  municipalityService.getComuneOptions,
                  1000,
                )}
                onChange={data => {
                  setFieldValue('birthCity', data.label);
                  setFieldValue(
                    'birthProvince',
                    data.denominationSupraMunicipal,
                  );
                  setFieldValue(
                    'birthProvinceShort',
                    data.automotiveAbbreviation,
                  );
                }}
              />
            </FormikField>
          </PaddedColumn>
          <PaddedColumn>
            <Grid>
              <Grid.Column width={8}>
                <FormikDate
                  {...props}
                  name="birthDate"
                  label={props.intl.formatMessage(messages.date_of_birth)}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <FormikSelect
                  {...props}
                  name="gender"
                  options={sexOptions}
                  label={props.intl.formatMessage(messages.gender)}
                  defaultValue={
                    values.gender
                      ? { label: values.gender, value: values.gender }
                      : null
                  }
                  value={
                    values.gender
                      ? { label: values.gender, value: values.gender }
                      : null
                  }
                />
              </Grid.Column>
            </Grid>
          </PaddedColumn>
          <PaddedColumn>
            <Grid>
              <Grid.Column width={16}>
                <SimpleFormikField
                  {...props}
                  name="birthCountry"
                  label={props.intl.formatMessage(messages.citizenship)}
                />
              </Grid.Column>
            </Grid>
          </PaddedColumn>

          <PaddedColumn>
            <FiscalCodeField
              {...props}
              required={props.mainEntity !== 'prospect'}
            />
          </PaddedColumn>
        </Grid.Row>

        <Grid.Row columns={2}>
          <PaddedColumn>
            <Grid.Column width={10}>
              <Form.Field>
                <SearchAddressWrapper>
                  <AddressPicker
                    readOnly={readOnly}
                    name="residence_address"
                    search={values.residenceAddressSelectionType || 'auto'}
                    labels={{
                      autoBtn: props.intl.formatMessage(appMessages.automatic),
                      manualBtn: props.intl.formatMessage(appMessages.manual),
                      city: props.intl.formatMessage(appMessages.city),
                      province: props.intl.formatMessage(appMessages.province),
                      state: props.intl.formatMessage(appMessages.state),
                      postalCode: props.intl.formatMessage(
                        appMessages.postal_code,
                      ),
                      address: props.intl.formatMessage(appMessages.address),
                      autoPlaceholder: props.intl.formatMessage(
                        appMessages.search_an_address,
                      ),
                      auto: props.intl.formatMessage(
                        appMessages.residential_address,
                      ),
                    }}
                    element={{
                      address: values.residenceStreet,
                      title: values.residenceAddressTitle,
                      city: values.residenceCity,
                      province: values.residenceProvinceShort,
                      cap: values.residencePostalCode,
                      country: values.residenceCountry,
                      latitude: values.residenceLatitude,
                      longitude: values.residenceLongitude,
                    }}
                    onChange={(name, value) => {
                      const [latitude, longitude] = value.coords || [];

                      setFieldValue('residenceStreet', value.address);
                      setFieldValue('residenceCity', value.city);
                      setFieldValue('residenceProvinceShort', value.province);
                      setFieldValue('residencePostalCode', value.cap);
                      setFieldValue('residenceCountry', value.country);
                      setFieldValue('residenceAddressTitle', value.title);
                      setFieldValue('residenceLatitude', latitude);
                      setFieldValue('residenceLongitude', longitude);
                    }}
                    onSwitch={el =>
                      setFieldValue('residenceAddressSelectionType', el)
                    }
                    // eslint-disable-next-line no-unused-vars
                    onBlur={(name, value) => null}
                  />
                </SearchAddressWrapper>
              </Form.Field>
            </Grid.Column>
          </PaddedColumn>
          <PaddedColumn>
            <Grid.Column width={6} style={{ marginTop: -24 }}>
              <FormikSelect
                {...props}
                name="fiscalDomicile"
                defaultValue={
                  fiscalDomicileOptions.filter(
                    o => o.value === values.fiscalDomicile,
                  )[0] || fiscalDomicileOptions[0]
                }
                value={
                  fiscalDomicileOptions.filter(
                    o => o.value === values.fiscalDomicile,
                  )[0]
                }
                options={fiscalDomicileOptions}
                label={props.intl.formatMessage(messages.fiscal_domicile)}
              />
            </Grid.Column>
          </PaddedColumn>
        </Grid.Row>
      </Grid>
    </div>
  );
}

IndividualPart.defaultProps = {
  values: {},
  readOnly: false,
};

IndividualPart.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default injectIntl(IndividualPart);
