import { useEffect, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment';

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

export const formatLabelValue = (label, value) => ({
  label,
  value,
});

/**
 * @param {string} reloadIndex
 * @param {function} callback
 * */
export const withReloadIndex = (reloadIndex, callback) => {
  const reload = _.cloneDeep(reloadIndex);
  callback(reload);
  return reload;
};

// eslint-disable-next-line no-unused-vars
export const toItalianDate = ({ value }) => moment(value).format('DD/MM/YYYY');

export const downloadFileFromAjaxResponse = response => {
  const filename = response.headers['content-disposition']
    .split('filename=')[1]
    .replace(/"/g, '')
    .trim();
  const file = new Blob([response.data]);
  const url = window.URL.createObjectURL(file);
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', url);
  element.setAttribute('download', filename);
  element.style.display = '';
  element.click();
  document.body.removeChild(element);
};

export const convertUTCToLocal = (date, format = 'DD/MM/YYYY HH:mm') =>
  // eslint-disable-next-line prettier/prettier
  date ? moment.utc(date).local().format(format): null;
