/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import {
  Form,
  Grid,
  Card,
  Button,
  Icon,
  Image,
  Loader,
} from 'semantic-ui-react';
import messages from '../messages';
import { RemovePhotoBtn } from '../../../Buttons';
import { addNotification } from '../../../../utils/notification';
import attachmentService from '../../../../shared/services/attachments';
import StaffWorkorderAttachmentService from '../../../../shared/services/staffworkorderattachment';

class UploadPhotoSection extends React.Component {
  state = {
    files: [],
    isLoading: false,
    isUploading: false,
  };

  componentDidMount() {
    if (!this.props.isNew) {
      this.loadImages();
    }
    if (this.props.isNew) {
      this.setState({ files: this.props.images ? this.props.images : [] });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.entityId !== this.props.entityId) {
      this.loadImages();
    }
  }

  loadImages = () => {
    this.setState({ isLoading: true });
    StaffWorkorderAttachmentService.get(this.props.entityId)
      .then(response => {
        const workOrderattachments = _.get(
          response,
          'data._embedded.staff-workorder-attachment',
        );
        if (workOrderattachments.length > 0) {
          const array = [];
          workOrderattachments.map(item =>
            array.push(_.get(item, '_embedded.attachmentId.id')),
          );
          attachmentService.downloadMultiple(array).then(res => {
            this.setState({
              files: Object.values(res.data),
              isLoading: false,
            });
          });
        } else {
          this.setState({
            files: [],
            isLoading: false,
          });
        }
      })
      .catch(err => {
        this.setState({ isLoading: false });
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  handleUpload = e => {
    this.setState({ isUploading: true });
    if (e.target.files.length > 0) {
      const imageArray = e.target.files;
      const files = [];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < imageArray.length; i++) {
        if (imageArray[i].name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
          files.push(imageArray[i]);
        } else {
          addNotification({
            title: null,
            message: `Image with name ${imageArray[i].name
              } not in required format!`,
            isError: true,
          });
        }
      }
      if (files.length === 0) {
        this.setState({ isUploading: false });
        addNotification({
          title: null,
          message: 'Please upload valid images!',
          isError: true,
        });
      } else if (files.length > 8 - this.state.files.length) {
        this.setState({ isUploading: false });
        addNotification({
          title: null,
          message: 'You can only upload 8 images!',
          isError: true,
        });
      } else {
        const data = {
          entityType: `Fotografia_${this.props.entityId}`,
          entityId: this.props.entityId,
          entityName: 'StaffWorkorder',
          files,
        };
        attachmentService.uploadMultiple(data).then(res => {
          const attachmentId = [];
          const resArray = [Object.values(_.get(res, 'data', []))];
          resArray[0].map(arr => attachmentId.push(arr.result));
          StaffWorkorderAttachmentService.upload({
            attachmentId,
            staffWorkorder: this.props.entityId,
          })
            .then(() => {
              this.setState({ isUploading: false });
              addNotification({
                title: null,
                message: this.props.intl.formatMessage(messages.upload_success),
                isError: false,
              });
              this.loadImages();
            })
            .catch(err => {
              this.setState({ isLoading: false, isUploading: false });
              addNotification({
                title: null,
                message: err.data.detail,
                isError: true,
              });
            });
        });
      }
    }
  };

  removeImage = (imageId, attachmentId) => {
    this.setState({ isLoading: true });
    StaffWorkorderAttachmentService.remove(attachmentId)
      .then(() => {
        attachmentService.remove(imageId);
        addNotification({
          title: null,
          message: this.props.intl.formatMessage(messages.remove_success),
          isError: false,
        });
        this.loadImages();
      })
      .catch(err => {
        this.setState({ isLoading: false });
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  previewImages = e => {
    const { files } = this.state;
    this.setState({ isUploading: true });
    if (e.target.files.length > 0) {
      if (e.target.files.length <= 8 - files.length) {
        const imageArray = e.target.files;
        const filesArray = files;
        let errorFiles = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < imageArray.length; i++) {
          if (imageArray[i].name.match(/\.(jpg|jpeg|png|gif)$/)) {
            filesArray.push(imageArray[i]);
          } else {
            errorFiles += 1;
          }
        }
        if (errorFiles > 0) {
          addNotification({
            title: null,
            message: 'Not previewed files are not in required format!',
            isError: true,
          });
        }
        this.setState({
          files: filesArray,
        });
        this.props.uploadImage(filesArray);
        this.setState({ isUploading: false });
      } else {
        this.setState({ isUploading: false });
        addNotification({
          title: null,
          message: 'You can only upload 8 images!',
          isError: true,
        });
      }
    }
  };

  removePreviewedImage = index => {
    this.setState({ isLoading: true });
    const { files } = this.state;
    const filesArray = files;
    filesArray.splice(index, 1);
    this.setState({ files: filesArray });
    this.props.uploadImage(filesArray);
    this.setState({ isLoading: false });
  };

  downloadImage = item => {
    if (item.id) {
      attachmentService.download(item.id).then(res => {
        const contentDisposition = res.headers['content-disposition'];
        const filename = contentDisposition.match(`filename="(.*)"`)[1];
        const newBlob = new Blob([res.data], { type: res.data.type });
        const fileUrl = window.URL.createObjectURL(newBlob);
        const tempLink = document.createElement('a');
        tempLink.href = fileUrl;
        tempLink.setAttribute('download', filename);
        tempLink.click();
      });
    }
  }

  render() {
    const { files, isLoading, isUploading } = this.state;
    const { isNew, isView } = this.props;
    return (
      <div>
        <Form>
          <Grid>
            <Grid.Row>
              <Grid.Column width={11} />
              <Grid.Column width={5}>
                {isLoading ? (
                  <Loader active />
                ) : (
                  <Card.Group itemsPerRow={4}>
                    {files.map((item, index) => (
                      <Card key={item.id} onClick={() => this.downloadImage(item)}>
                        <Image
                          src={
                            isNew
                              ? URL.createObjectURL(item)
                              : `data:image/jpeg;base64,${item.image}`
                          }
                          width={200}
                          height={100}
                        />
                        <RemovePhotoBtn
                          action={() =>
                            isNew
                              ? this.removePreviewedImage(index)
                              : this.removeImage(
                                item.id,
                                item.workorderAttachment,
                              )
                          }
                        />
                      </Card>
                    ))}
                  </Card.Group>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={14} />
              <Grid.Column textAlign="right" width={2}>
                <Button
                  disabled={isView || this.props.readOnly}
                  loading={isUploading}
                  icon
                  labelPosition="left"
                  positive
                  type="button"
                >
                  {this.props.intl.formatMessage(messages.uploadImage)}
                  <Icon name="upload" />
                  <input
                    type="file"
                    label="UPLOAD"
                    className="form-control"
                    accept="image/*"
                    style={{
                      opacity: '0',
                      position: 'absolute',
                      top: '0',
                      bottom: '0',
                      left: '0',
                      right: '0',
                    }}
                    onChange={isNew ? this.previewImages : this.handleUpload}
                    multiple
                    disabled={isView}
                  />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </div>
    );
  }
}

UploadPhotoSection.propTypes = {
  entityId: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  isView: PropTypes.bool,
  uploadImage: PropTypes.func,
  images: PropTypes.array,
  intl: intlShape.isRequired,
  readOnly: PropTypes.bool,
};

export default injectIntl(UploadPhotoSection);
