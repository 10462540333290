const EMPTY_ROW = {
  key: 'empty',
  label: '',
  value: '',
};

export const TOOL_OPTIONS = [
  EMPTY_ROW,
  {
    key: 'Esca',
    label: 'Esca',
    value: 'Esca',
  },
  {
    key: 'Trappola',
    label: 'Trappola',
    value: 'Trappola',
  },
];

export const AREA_OPTIONS = [
  EMPTY_ROW,
  {
    key: 'Interna',
    label: 'Interna',
    value: 'Interna',
  },
  {
    key: 'Esterna',
    label: 'Esterna',
    value: 'Esterna',
  },
  {
    key: 'Non sensibile',
    label: 'Non sensibile',
    value: 'Non sensibile',
  },
];

export const PRINCIPLE_OPTIONS = [
  EMPTY_ROW,
  {
    key: 'BM',
    label: 'BM',
    value: 'BM',
  },
  {
    key: 'BD',
    label: 'BD',
    value: 'BD',
  },
  {
    key: 'DF',
    label: 'DF',
    value: 'DF',
  },
  {
    key: 'EV',
    label: 'EV',
    value: 'EV',
  },
];

export const DEFAULT_INDICATOR = {
  id: null,
  key: 1,
  name: 'P1',
  tool: { key: '', label: '', value: null },
  area: { key: '', label: '', value: '' },
  principle: { key: '', label: '', value: '' },
  percent: 0,
  description: '',
  specie: '',
};

export const prepareDefaultTabs = staffWorkOrderId => [
  {
    id: null,
    staffWorkOrder: staffWorkOrderId,
    tabIdentifier: 'tab_deratization',
    note: '',
    indicators: [],
  },
  {
    id: null,
    staffWorkOrder: staffWorkOrderId,
    tabIdentifier: 'tab_monitor_cockroach',
    note: '',
    indicators: [],
  },
  {
    id: null,
    staffWorkOrder: staffWorkOrderId,
    tabIdentifier: 'tab_monitor_flying',
    note: '',
    indicators: [],
  },
  {
    id: null,
    staffWorkOrder: staffWorkOrderId,
    tabIdentifier: 'tab_monitor_goods',
    note: '',
    indicators: [],
  },
  {
    id: null,
    staffWorkOrder: staffWorkOrderId,
    tabIdentifier: 'tab_disinfection',
    note: '',
    indicators: [],
  },
];

// export const TYPE_RODENT_CONTROL_DISINFECTION = [
//   'Derattizzazione',
//   'Disinfestazione',
// ];

// export const canShowMonitorTabs = ({ workOrderType }) =>
//   TYPE_RODENT_CONTROL_DISINFECTION.includes(workOrderType);
