import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Accordion from '../../../Accordion';
import messages from '../messages';
import contactService from '../../../../shared/services/destinationcontacts';
import ContactsPart from '../../Anagrafica/formParts/contactsPart';

function ContactsTab(props) {
  return (
    <div>
      <Accordion title={props.intl.formatMessage(messages.tab_contacts)}>
        <ContactsPart
          {...props}
          contactService={contactService}
          entity="destination"
        />
      </Accordion>
    </div>
  );
}

ContactsTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ContactsTab);
