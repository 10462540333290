import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { Field } from 'formik';
import styled from 'styled-components';

const TransparentFormatter = styled(CurrencyFormat)`
  :not(:hover) {
    border-color: transparent !important;
  }
`;

/**
 * The component allows to inline editing commission currency.
 * @param {Object<{ name: String, values: Object, setFieldValue: Func>}} props the properties.
 * @returns a JSX component.
 */
const CommissionCurrency = ({ name, values, setFieldValue }) => {
  /**
   * The current value (the pending one, not saved into the Formik bag).
   */
  const [editingValue, setEditingValue] = useState(values[name]);

  /**
   * Update the editing value every thing the input value changes.
   */
  useEffect(
    () => {
      if (values[name] !== editingValue) {
        setEditingValue(values[name]);
      }
    },
    [values[name]],
  );

  /**
   * Set the editing value every time the input value changes.
   * @param {Object<{ floatValue: Number>}} props The numeric value.
   * @returns
   */
  const onChange = ({ floatValue }) => setEditingValue(floatValue || 0);

  /**
   * Blur the input when the exit from the input focus.
   * @param {Event} event the event.
   */
  const onKeyDown = event => {
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.target.blur();
    }
  };

  /**
   * The value will be saved into the Formik bag when the user goes away from the input.
   */
  const onBlur = () => {
    setFieldValue(name, editingValue || 0);
  };

  return (
    <Field name={name}>
      {() => (
        <TransparentFormatter
          name={name}
          value={editingValue}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale={2}
          prefix="€ "
          allowNegative={false}
          onValueChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
      )}
    </Field>
  );
};

/**
 * The prop types.
 */
CommissionCurrency.propTypes = {
  /**
   * The field name.
   */
  name: PropTypes.string.isRequired,

  /**
   * The Formik values.
   */
  values: PropTypes.object.isRequired,

  /**
   * The function to set the Formik value.
   */
  setFieldValue: PropTypes.func.isRequired,
};

export default CommissionCurrency;
