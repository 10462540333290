function Validation(values) {
  const errors = {};

  if (!values.destination) {
    errors.destination = { required: 'This field is required' };
  }

  return errors;
}

export default Validation;
