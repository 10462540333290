import moment from 'moment';
import messages from './messages';
import { getNetPrice } from '../../Forms/OrderForm/manageForm/functions';

export const getFilterData = ({ values, intl }) => {
  const filters = [];
  const filterLabels = [];

  // Offer number
  if (values.number) {
    filters.push({
      type: 'like',
      field: 'number',
      where: 'and',
      value: `%${values.number}%`,
      filterField: 'number',
    });
    filterLabels.push({
      key: 'number',
      name: intl.formatMessage(messages.offer_number),
      value: values.number,
      filterField: 'number',
    });
  }
  // Destination
  if (values.destination) {
    filters.push({
      alias: 'destination',
      field: 'id',
      filterField: 'destination',
      type: 'eq',
      value: values.destination.value,
      where: 'and',
    });
    filterLabels.push({
      filterField: 'destination',
      key: 'businessName',
      name: intl.formatMessage(messages.customer),
      value: values.destination.label,
    });
  }
  // Creation date
  if (values.createdAt) {
    const createdAtFrom = moment(values.createdAt)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .format('YYYY-MM-DD HH:mm:ss');
    const createdAtTo = moment(values.createdAt)
      .hours(23)
      .minutes(59)
      .seconds(59)
      .format('YYYY-MM-DD HH:mm:ss');
    filters.push({
      field: 'createdAt',
      filterField: 'createdAt',
      format: 'Y-m-d H:i:s',
      type: 'gte',
      value: `${createdAtFrom}`,
      where: 'and',
    });
    filters.push({
      field: 'createdAt',
      filterField: 'createdAt',
      format: 'Y-m-d H:i:s',
      type: 'lte',
      value: `${createdAtTo}`,
      where: 'and',
    });
    filterLabels.push({
      filterField: 'createdAt',
      key: 'createdAt',
      name: intl.formatMessage(messages.insert_date),
      value: moment(values.createdAt).format('DD/MM/YYYY'),
    });
  }
  // Expiration date
  if (values.expirationDate) {
    filters.push({
      field: 'expirationDate',
      filterField: 'expirationDate',
      format: 'Y-m-d',
      type: 'lte',
      value: `${moment(values.expirationDate).format('YYYY-MM-DD')}`,
      where: 'and',
    });
    filterLabels.push({
      filterField: 'expirationDate',
      key: 'expirationDate',
      name: intl.formatMessage(messages.expirationDate),
      value: moment(values.expirationDate).format('DD/MM/YYYY'),
    });
  }
  // Offer state
  if (values.state) {
    if (values.state.value) {
      filters.push({
        field: 'state',
        filterField: 'state',
        type: 'eq',
        value: `${values.state.value}`,
        where: 'and',
      });
    } else {
      filters.push({
        field: 'state',
        filterField: 'state',
        type: 'isnotnull',
        where: 'and',
      });
    }
    filterLabels.push({
      filterField: 'state',
      key: 'state',
      name: intl.formatMessage(messages.status),
      value: values.state.label,
    });
  }
  // Offer typology
  if (values.typology) {
    filters.push({
      alias: 'typology',
      field: 'id',
      filterField: 'typology',
      type: 'eq',
      value: values.typology.value,
      where: 'and',
    });
    filterLabels.push({
      filterField: 'typology',
      key: 'typology',
      name: intl.formatMessage(messages.offer_type),
      value: values.typology.label,
    });
  }
  // Product
  if (values.product) {
    filters.push({
      alias: 'offerrow',
      field: 'product',
      filterField: 'product',
      type: 'eq',
      value: values.product.value,
      where: 'and',
    });
    filterLabels.push({
      filterField: 'product',
      key: 'product',
      name: intl.formatMessage(messages.product),
      value: values.product.label,
    });
  }
  // Agent
  if (values.agent1) {
    filterLabels.push({
      filterField: 'agent1',
      key: 'agent1',
      name: intl.formatMessage(messages.agent),
      value: values.agent1.label,
    });
    filters.push({
      alias: 'destination',
      field: 'agent1',
      filterField: 'agent1',
      type: 'eq',
      value: `${values.agent1.value}`,
      where: 'and',
    });
  }

  return {
    filters,
    filterLabels,
  };
};

export const applyMerchDiscount = (checked, data, onChange) => {
  if (checked) {
    onChange(true, 'discountDisabled', data.id);
    onChange(true, 'discountExtraDisabled', data.id);
    onChange(true, 'netPriceDisabled', data.id);
    onChange(100, 'discountValue', data.id);
    onChange('0', 'extraDiscount', data.id);
    onChange('0', 'netPrice', data.id);
  }
  if (!checked) {
    onChange(false, 'discountDisabled', data.id);
    onChange(false, 'discountExtraDisabled', data.id);
    onChange(false, 'netPriceDisabled', data.id);
    onChange('0', 'discountValue', data.id);
    onChange('0', 'extraDiscount', data.id);

    const netPrice = getNetPrice(data);

    if (data.discountValue + data.discountExtra < 100)
      if (data.discountValue !== '') {
        const percentage = 100 - (data.discountValue + data.discountExtra);
        onChange(
          parseFloat((netPrice / 100) * percentage).toFixed(2),
          'netPrice',
          data.id,
        );
      }
  }

  if (!checked)
    onChange(
      data.netPrice * parseFloat(data.minQuantity, 10),
      'amount',
      data.id,
    );
  if (checked) onChange(0, 'amount', data.id);
};
