import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.APPOINTMENT,
    method: 'GET',
  });

const getBetween = (startDate, endDate) =>
  request({
    baseURL: API.BASE_URL,
    url: API.APPOINTMENT,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'between',
          field: 'startDate',
          from: startDate,
          to: endDate,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.APPOINTMENT,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'displayName',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.customer', []).map(customer => ({
        value: customer.id,
        label: customer.displayName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.APPOINTMENT,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.APPOINTMENT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.APPOINTMENT}/${id}`,
    method: 'DELETE',
  });

const CustomerService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
  getBetween,
};

export default CustomerService;
