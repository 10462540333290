/**
 *
 * TopBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

import { Input, Icon, Button } from 'semantic-ui-react';

import { injectIntl } from 'react-intl';
import { IntlShape, TranslationsShape } from './Shapes';
import messages from './messages/SearchBarMessages';

/* eslint-disable react/prefer-stateless-function */
class SearchBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  /**
   * Catch if press enter during search
   */
  onKeyPress = target => {
    const { onSearch = null } = this.props;
    if (onSearch && target.charCode === 13) {
      onSearch(this.textInput.current.inputRef.current.value);
    }
  };

  /**
   * When click reset
   */
  onReset = () => {
    const { onSearch = null } = this.props;
    this.textInput.current.inputRef.current.value = '';
    onSearch('');
  };

  /**
   * When search
   */
  onSearch = () => {
    const { onSearch = null } = this.props;
    onSearch(this.textInput.current.inputRef.current.value);
  };

  render() {
    const {
      active = false,
      searchedValue = '',
      intl,
      translations = {},
    } = this.props;

    const { simpleSearchBar = {} } = translations;

    const {
      placeholder = intl.formatMessage(messages.placeholder),
    } = simpleSearchBar;

    if (!active) {
      return null;
    }

    return (
      <Input
        ref={this.textInput}
        onKeyPress={this.onKeyPress}
        className="search"
        defaultValue={searchedValue || ''}
        placeholder={placeholder}
        icon={<Icon name="delete" link onClick={this.onReset} />}
        iconPosition="left"
        label={
          <Button primary icon="search" type="button" onClick={this.onSearch} />
        }
        labelPosition="right"
      />
    );
  }
}

SearchBar.defaultProps = {
  onSearch: null,
  active: false,
  searchPlaceholder: '',
  searchedValue: '',
};

SearchBar.propTypes = {
  onSearch: PropTypes.func,
  active: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  searchPlaceholder: PropTypes.string,
  /**
   * The default value of the searchbox
   * Default is empty, but filled if is searched and re-mounted
   */
  searchedValue: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  intl: IntlShape,
  // eslint-disable-next-line react/require-default-props
  translations: TranslationsShape,
};

export default injectIntl(SearchBar);
