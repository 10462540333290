/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import { RemoveBtn, EditBtn, ModalNewBtn } from '../../../Buttons';
import { WrapperTool } from '../../../Layout';
import Modal from '../../../Modal';
import ContactForm from '../../CustomerContact';
import { addNotification } from '../../../../utils/notification';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function ContactsPart(props) {
  const [contacts, setContacts] = useState([]);
  const [contactTypes, setContactTypes] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);
  const { contactService, entity } = props;

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      props.values.id && loadData();
    },
    [props.values.id, paginationData.page],
  );

  useEffect(() => {
    contactService.getContactTypeOptions().then(res => {
      setContactTypes(res);
    });
  }, []);

  const loadData = page => {
    setLoading(true);
    contactService
      .getByOwner(props.values.id, page || paginationData.page)
      .then(res => {
        const data = _.get(res, 'data', { ...paginationDefault });
        setPaginationData({
          page: data.page,
          page_count: data.page_count,
          page_size: data.page_size,
          total_items: data.total_items,
        });
        setLoading(false);
        setContacts(_.get(res, `data._embedded.${entity}-contact`), []);
      });
  };

  const closeModal = () => {
    setSelectedContact(null);
    setModalOpened(false);
  };

  const openModal = () => {
    setSelectedContact({});
    setModalOpened(true);
  };

  const columns = () =>
    [
      {
        key: 'id',
        name: props.intl.formatMessage(messages.contact_type),
        formatter: ({ data }) =>
          _.get(data, '_embedded.contactTypeId.description', ''),
      },
      {
        key: 'fullName',
        name: props.intl.formatMessage(messages.nominative),
        formatter: ({ data }) => `${data.firstName} ${data.lastName}`,
      },
      {
        key: 'phoneNumber',
        name: props.intl.formatMessage(messages.phone),
      },
      {
        key: 'mobileNumber',
        name: props.intl.formatMessage(messages.mobile),
      },
      {
        key: 'email',
        name: 'Email',
      },
      {
        key: 'emailPec',
        name: 'PEC',
      },
      !props.readOnly && {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 80 }}>
            <EditBtn
              action={() => {
                setSelectedContact(data);
                setModalOpened(true);
              }}
            />
            <RemoveBtn
              action={() => {
                contactService
                  .remove(data.id)
                  .then(() => {
                    loadData();
                    addNotification({
                      title: null,
                      message: props.intl.formatMessage(
                        messages.contact_removed_success,
                      ),
                      isError: false,
                    });
                  })
                  .catch(error =>
                    addNotification({
                      title: null,
                      message: error.data.detail,
                      isError: true,
                    }),
                  );
              }}
              {...props}
            />
          </div>
        ),
        width: '80px',
      },
    ].filter(o => o);

  return (
    <div>
      <Table
        elements={contacts}
        rowClassKey="role-row-class"
        columns={columns()}
        canSort={{
          active: true,
        }}
        isLoading={loading}
        canPaginate={{
          active: paginationData.total_items > 0,
          pageCount: paginationData.page_count,
          pageSize: paginationData.page_size,
          totalItems: paginationData.total_items,
          page: paginationData.page,
          onSelect: page => {
            setPaginationData({
              ...paginationData,
              page,
            });
          },
        }}
        canAction={{
          active: !props.readOnly,
          actions: [
            <WrapperTool key="wrapper">
              <Modal
                open={modalOpened}
                onClose={() => {
                  closeModal();
                }}
                title={
                  <h2>
                    <Icon name="users" />{' '}
                    {props.intl.formatMessage(messages.new_contact)}
                  </h2>
                }
                trigger={
                  <ModalNewBtn onClick={openModal}>
                    {props.intl.formatMessage(messages.new_contact)}
                  </ModalNewBtn>
                }
                size="mini"
                style={{ width: 650 }}
                scrolling
              >
                <ContactForm
                  entityId={props.values.id}
                  entity={props.entity}
                  contactService={props.contactService}
                  contactTypes={contactTypes}
                  close={closeModal}
                  initialValues={selectedContact}
                  update={() => loadData(1)}
                />
              </Modal>
            </WrapperTool>,
          ],
        }}
        hiddenHeaderIfEmpty
        emptyResults={
          <div>{props.intl.formatMessage(appMessages.no_results)}</div>
        }
      />
    </div>
  );
}

ContactsPart.defaultProps = {
  values: {},
  readOnly: false,
};

ContactsPart.propTypes = {
  values: PropTypes.object,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  contactService: PropTypes.object,
  entity: PropTypes.string,
};

export default injectIntl(ContactsPart);
