import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the technicalIntervention state domain
 */

const selectWorkorder = state => state.get('workorder', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by TechnicalIntervention
 */

const makeSelectImages = () =>
  createSelector(selectWorkorder, substate => substate.get('images'));

export { selectWorkorder, makeSelectImages };
