import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.INVOICE_TYPOLOGY,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.invoice-typology', []).map(item => ({
        value: item.id,
        label: item.description,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const InvoiceTypologyService = {
  getOptions,
};

export default InvoiceTypologyService;
