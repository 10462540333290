/**
 *
 * Commission
 *
 */

/*
   * Specific import
   */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Button, Dropdown, Header } from 'semantic-ui-react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import ConfirmModal from '../../components/Modal/confirmModal';
import ListManager from '../../components/DeterchimicaEntityGrid/index';
import ElaborationFormModal from '../../components/FormManagerModal';
import CommissionCreate from '../../components/Forms/CommissionCreate/CommissionCreate';
import validation from '../../components/Forms/CommissionCreate/validation';
import CommissionView from '../../components/Forms/CommissionView/CommissionView';
import { ListWrapper, Page } from '../../components/Layout';
import PermissionManager from '../../components/Permission';
import { API } from '../../global-constants';
import exportCommissionService from '../../shared/services/agentcommissionelaboration';
import CommissionService from '../../shared/services/commission';
import request from '../../shared/services/request';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import { downloadFileFromAjaxResponse } from '../../utils/functions';
import { addNotification } from '../../utils/notification';
import appMessages from '../App/messages';
import { TableWrap } from './custom-styles';
import {
  commissionColumns,
  commissionSearch,
  COMMISSION_RELATIONS,
} from './constants';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import makeSelectCommission from './selectors';
import './css/commission.css';
import { handleFilters } from './functions';

export class Commission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showNewModal: false,
      showDeleteConfirm: false,
      showConfirmModalConfirm: false,
      reloadIndex: 0,
      filters: [],
      selectedCommissions: [],
      commissions: [],
      filterValues: {},
      deleteAll: false,
      confirmIsProcessing: false,
    };
  }

  /**
   * Evaluate if you need a form or a List
   */
  evaluateAction() {
    const { action, id } = _.get(this.props, 'match.params');
    if (id === 'new') {
      this.setState({
        showNewModal: true,
        showModal: false,
        showDeleteConfirm: false,
        showConfirmModalConfirm: false,
      });
    } else if (action === 'view') {
      this.setState({
        showNewModal: false,
        showModal: true,
        showDeleteConfirm: false,
        showConfirmModalConfirm: false,
      });
    } else if (action === 'delete') {
      this.setState({
        showNewModal: false,
        showModal: false,
        showDeleteConfirm: true,
        showConfirmModalConfirm: false,
      });
    } else {
      this.setState({
        showNewModal: false,
        showModal: false,
        showDeleteConfirm: false,
        showConfirmModalConfirm: false,
      });
    }
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'commission',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  /**
   * If you change location revalutate all the View
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.checkPermission();
      this.evaluateAction();
    }
  }

  /**
   * Evaluate which component you need
   */
  componentDidMount() {
    this.checkPermission();
    this.evaluateAction();
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
    this.props.history.push('/commission');
  }

  openNewModal() {
    this.setState({
      showNewModal: true,
    });
  }

  closeNewModal() {
    this.props.history.push('/commission');
    // this.setState({
    //   showNewModal: false,
    // });
  }

  reloadData = () => {
    this.closeNewModal();
    this.closeModal();
    this.setState(prevState => ({
      reloadIndex: prevState.reloadIndex + 1,
    }));
  };

  exportElaboration = id => {
    exportCommissionService
      .exportData(id)
      .then(response => {
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: _.get(
            response,
            'data.message',
            this.context.intl.formatMessage(appMessages.success),
          ),
          isError: false,
        });
        downloadFileFromAjaxResponse(response);
      })
      .catch(err => {
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  transformData = data => ({
    ...data,
    filter: data.filter ? data.filter : 'ALL',
    referenceDate: data.referenceDate ? moment(data.referenceDate.date) : null,
  });

  exportPDF = () => {
    const { filters } = this.state;

    const filter = {};
    filters.map(item => {
      filter[item.key] = item.exportFilterValue;
      return null;
    });
    exportCommissionService
      .exportPDF(filter)
      .then(response => {
        downloadFileFromAjaxResponse(response);
      })
      .catch(err => {
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  exportExcel = commissionId => {
    const { filters } = this.state;

    const filter = {};

    if (!commissionId) {
      filters.map(item => {
        filter[item.key] = item.exportFilterValue;
        return null;
      });
    } else {
      filter.commissionId = commissionId;
    }
    exportCommissionService
      .exportExcel(filter)
      .then(response => {
        downloadFileFromAjaxResponse(response);
      })
      .catch(err => {
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  setFilters = (filters, values) => {
    this.setState({ filters, filterValues: values });
  };

  handleDelete = () => {
    const { id } = _.get(this.props, 'match.params');
    const {
      selectedCommissions,
      filterValues,
      searchedValue,
      deleteAll,
    } = this.state;

    let method;
    let params;
    if (deleteAll) {
      params = handleFilters(filterValues);
      method = CommissionService.removeAll;
      if (searchedValue && searchedValue.length) {
        params.searchValue = searchedValue;
      }
    } else if (selectedCommissions.length) {
      method = CommissionService.removeBulk;
      params = selectedCommissions.map(el => ({ id: el }));
    } else {
      method = CommissionService.remove;
      params = id;
    }
    this.setState({
      confirmIsProcessing: true,
    });
    method(params)
      .then(response => {
        this.setState({ confirmIsProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: _.get(
            response,
            'data.message',
            this.context.intl.formatMessage(appMessages.success),
          ),
          isError: false,
        });

        this.setState({ selectedCommissions: [] }, () => this.reloadData());
      })
      .catch(error => {
        this.setState({ confirmIsProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.detail',
            _.get(
              error,
              'detail',
              this.context.intl.formatMessage(
                appMessages.an_error_has_occurred,
              ),
            ),
          ),
          isError: true,
        });
      })
      .finally(() => {
        this.handleDeleteModalClose();
      });
  };

  /**
   * Set the commission state of the selected commissions or of all of them.
   * @param {*} commissionState
   */
  setCommissionState = (commissionState, skipConfirm = false) => {
    const { selectedCommissions, filterValues, searchedValue } = this.state;
    const setAll = selectedCommissions.length === 0;

    // If the operation is "Confirm", then ask for confirmation before proceeding
    if (commissionState === 'Confermato' && !skipConfirm) {
      this.setState({
        showConfirmModalConfirm: true,
      });
      return;
    }

    let method;
    let params = {};
    if (setAll) {
      params = handleFilters(filterValues);
      method = CommissionService.setStateAll;
      if (searchedValue && searchedValue.length) {
        params.searchValue = searchedValue;
      }
    } else if (selectedCommissions.length > 0) {
      method = CommissionService.setStateBulk;
      params.ids = selectedCommissions.map(el => ({ id: el }));
    }
    params.state = commissionState;

    this.setState({
      confirmIsProcessing: true,
    });
    method(params, commissionState)
      .then(response => {
        this.setState({ confirmIsProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: _.get(
            response,
            'data.message',
            this.context.intl.formatMessage(appMessages.success),
          ),
          isError: false,
        });

        this.setState({ selectedCommissions: [] }, () => this.reloadData());
      })
      .catch(error => {
        this.setState({ confirmIsProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.detail',
            _.get(
              error,
              'detail',
              this.context.intl.formatMessage(
                appMessages.an_error_has_occurred,
              ),
            ),
          ),
          isError: true,
        });
      })
      .finally(() => {
        this.handleDeleteModalClose();
      });
  };

  handleDeleteModalClose = () => {
    this.setState(
      {
        showDeleteConfirm: false,
        searchedValue: '',
        deleteAll: false,
      },
      () => {
        this.props.history.push('/commission');
      },
    );
  };

  actionsCreate = () => {
    const actions = [];
    const { selectedCommissions } = this.state;
    if (PermissionManager.CanI('read', 'commission')) {
      actions.push({
        icon: 'file excel',
        color: 'green',
        label: this.context.intl.formatMessage(messages.exportExcel),
        onClick: () => this.exportExcel(),
      });
    }
    if (PermissionManager.CanI('read', 'commission')) {
      actions.push({
        icon: 'download',
        color: 'green',
        label: this.context.intl.formatMessage(messages.exportPdf),
        onClick: () => this.exportPDF(),
      });
    }
    if (PermissionManager.CanI('edit', 'commission')) {
      actions.push({
        color: 'transparent',
        onClick: () => null,
        label: (
          <Button.Group color="blue">
            <Button
              style={{ marginLeft: 2, marginRight: 2 }}
              onClick={() => {
                this.setCommissionState('Confermato');
              }}
            >
              {selectedCommissions.length === 0
                ? this.context.intl.formatMessage(messages.confirmAll)
                : this.context.intl.formatMessage(messages.confirmSelected)}
            </Button>
            <Button
              style={{ marginLeft: 2, marginRight: 2 }}
              onClick={() => {
                this.setCommissionState('Creato');
              }}
            >
              {selectedCommissions.length === 0
                ? this.context.intl.formatMessage(messages.cancelAll)
                : this.context.intl.formatMessage(messages.cancelSelected)}
            </Button>
          </Button.Group>
        ),
      });
    }
    if (PermissionManager.CanI('remove', 'commission')) {
      if (selectedCommissions.length > 0) {
        actions.push({
          color: 'transparent',
          onClick: () => null,
          disabled: selectedCommissions.length === 0,
          label: (
            <Button.Group color="red">
              <Button
                onClick={() => {
                  if (selectedCommissions.length !== 0) {
                    this.setState({
                      showDeleteConfirm: true,
                    });
                  }
                }}
              >
                {this.context.intl.formatMessage(messages.remove)}
              </Button>
              <Dropdown
                className="button icon"
                floating
                trigger={<></>}
                options={[
                  {
                    key: 'deleteSelected',
                    icon: 'trash',
                    text: this.context.intl.formatMessage(
                      messages.removeSelected,
                    ),
                    onClick: () => {
                      if (selectedCommissions.length !== 0) {
                        this.setState({
                          showDeleteConfirm: true,
                        });
                      }
                    },
                  },
                  {
                    key: 'deleteAll',
                    icon: 'trash',
                    text: this.context.intl.formatMessage(messages.removeAll),
                    onClick: () => {
                      this.setState({
                        deleteAll: true,
                        showDeleteConfirm: true,
                      });
                    },
                  },
                ]}
              />
            </Button.Group>
          ),
        });
      } else {
        actions.push({
          icon: 'trash',
          color: 'red',
          label: this.context.intl.formatMessage(messages.removeAll),
          onClick: () => {
            this.setState({
              deleteAll: true,
              showDeleteConfirm: true,
            });
          },
        });
      }
    }

    return actions.filter(o => o);
  };

  handleSelectRow = row => {
    const { selectedCommissions } = this.state;
    const rows = [...selectedCommissions, row.id];
    this.setState({ selectedCommissions: rows });
  };

  handleDeselectRow = row => {
    const { selectedCommissions } = this.state;
    const rows = selectedCommissions.filter(el => el !== row.id);
    this.setState({ selectedCommissions: rows });
  };

  handleSelectAll = () => {
    const { selectedCommissions, commissions } = this.state;
    const selectedVisibleIds = commissions.map(el => el.id);
    const rows = [...new Set([...selectedCommissions, ...selectedVisibleIds])];
    this.setState({ selectedCommissions: rows });
  };

  handleDeselectAll = () => {
    this.setState({ selectedCommissions: [] });
  };

  afterLoad = res => {
    const data = _.get(res, '_embedded.commission', []);
    this.setState({
      commissions: data,
    });
  };

  reconciliation = (Ids, elements) =>
    elements.map(el =>
      Object.assign({}, el, {
        'entitygrid-selected': Boolean(_.includes(Ids, el.id)),
      }),
    );

  getElements = () =>
    this.reconciliation(this.state.selectedCommissions, this.state.commissions);

  render() {
    const routeParams = _.get(this.props, 'match.params');
    return (
      <>
        <Helmet>
          <title>{this.context.intl.formatMessage(messages.commissions)}</title>
          <meta
            name="description"
            content={this.context.intl.formatMessage(messages.commissions)}
          />
        </Helmet>
        <Page>
          <Header as="h2" dividing>
            {this.context.intl.formatMessage(messages.commissions)}
          </Header>
          {this.state.showNewModal && (
            <ElaborationFormModal
              {...this.props}
              modalSize="large"
              open={this.state.showNewModal}
              onClose={() => this.closeNewModal()}
              reloadData={this.reloadData}
              modalTitle={actionToBreadcrumb({
                action: this.props.match.params.action,
                intl: this.context.intl,
              })}
              formManagerProps={{
                key: this.props.match.params.action,
                router: this.props,
                entityName: 'commission',
                permissions: this.props.user,
                onCleanData: this.cleanData,
                afterCreate: () => this.reloadData(),
                client: pars =>
                  request(pars).then(res => this.transformData(res.data)),
                validation: values => validation(values, this.context.intl),
                data: this.props.match.params.id === 'new' && {
                  filter: 'ALL',
                },
                mask: fmProps => (
                  <CommissionCreate
                    reloadData={this.reloadData}
                    onClose={() => this.closeNewModal()}
                    {...fmProps}
                    editRoute={`/commission/${
                      this.props.match.params.id
                    }/modify`}
                    history={this.props.history}
                  />
                ),
              }}
            />
          )}
          {this.state.showModal && (
            <>
              <BreadcrumbsItem to="/commission/modify">
                {actionToBreadcrumb({
                  action: routeParams.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <CommissionView
                commission={this.props.match.params.id}
                open={this.state.showModal}
                onClose={() => this.closeModal()}
                reloadData={this.reloadData}
              />
              {/* <AgentCommissionTable
                 {...this.props}
                 open={this.state.showModal}
                 onClose={() => this.closeModal()}
                 reloadData={this.reloadData}
               /> */}
            </>
          )}
          <ListWrapper>
            <div className="commission-wrap">
              <TableWrap>
                <ListManager
                  entityName="commission"
                  sessionKey="commission-"
                  basePath={API.BASE_URL}
                  permissions={this.props.user}
                  reloadIndex={this.state.reloadIndex}
                  router={this.props}
                  client={pars => request(pars).then(res => res.data)}
                  defaultOrder={[
                    {
                      type: 'field',
                      field: 'createdAt',
                      direction: 'desc',
                    },
                  ]}
                  locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                  translations={{
                    createButton: {
                      label: this.context.intl.formatMessage(
                        messages.newCommission,
                      ),
                    },
                  }}
                  aliases={COMMISSION_RELATIONS}
                  canSearchCustom={commissionSearch(
                    this.context.intl,
                    this.setFilters,
                  )}
                  counterLabels={{
                    selectedItems: false,
                  }}
                  afterLoad={this.afterLoad}
                  columns={commissionColumns(this.context.intl)}
                  canCreate={{
                    active: true,
                    label: this.context.intl.formatMessage(
                      messages.newCommission,
                    ),
                  }}
                  elements={this.getElements()}
                  defaultActions={{
                    read: PermissionManager.CanI('read', 'commission'),
                    modify: false,
                    delete: PermissionManager.CanI('remove', 'commission'),
                    grouped: false,
                    moreActions: [
                      {
                        icon: 'file excel',
                        label: 'edit element',
                        key: 'exportExcel',
                        action: ({ id }) => this.exportExcel(id),
                      },
                    ],
                  }}
                  canSelect={{
                    active: PermissionManager.CanI('read', 'commission'),
                    selectAll: PermissionManager.CanI('read', 'commission'),
                    actions: this.actionsCreate(),
                    isSelectedProperty: 'entitygrid-selected',
                    handleSelectRow: this.handleSelectRow,
                    handleDeselectRow: this.handleDeselectRow,
                    handleSelectAll: this.handleSelectAll,
                    handleDeselectAll: this.handleDeselectAll,
                  }}
                  canExport={{
                    active: false,
                    exportLabelPrefix: this.context.intl.formatMessage(
                      messages.toCommission,
                    ),
                    labelButton: this.context.intl.formatMessage(
                      messages.exportCommission,
                    ),
                  }}
                  searchColumnWidth={4}
                  actionsColumnWidth={12}
                />
              </TableWrap>
            </div>
          </ListWrapper>
        </Page>
        {/* Confirm modal for delete operation */}
        <ConfirmModal
          open={this.state.showDeleteConfirm}
          content={this.context.intl.formatMessage(messages.removeCommission)}
          onCancel={() => this.props.history.push('/commission')}
          onConfirm={this.handleDelete}
          isLoading={this.state.confirmIsProcessing}
        />
        {/* Confirm modal for confirm operation */}
        <ConfirmModal
          open={this.state.showConfirmModalConfirm}
          content={this.context.intl.formatMessage(
            this.state.selectedCommissions.length === 0
              ? messages.confirmAllCommission
              : messages.confirmSelectedCommission,
          )}
          onCancel={() => this.props.history.push('/commission')}
          onConfirm={() => this.setCommissionState('Confermato', true)}
          isLoading={this.state.confirmIsProcessing}
        />
      </>
    );
  }
}

Commission.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  commission: makeSelectCommission(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({
  key: 'commission',
  reducer,
});
const withSaga = injectSaga({ key: 'commission', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Commission);

Commission.contextTypes = {
  intl: PropTypes.object.isRequired,
};
