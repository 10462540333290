import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import { PaddedColumn } from '../../../Layout';
import messages from '../messages';

const InvoicePart = (props, context) => {
  const { intl } = context;

  return (
    <div>
      <Grid>
        <Grid.Row columns={3}>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="sdi"
              label={intl.formatMessage(messages.sdi)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="emailPec"
              label={intl.formatMessage(messages.emailPec)}
            />
          </PaddedColumn>
        </Grid.Row>
      </Grid>
    </div>
  );
};

InvoicePart.defaultProps = {
  values: {},
  readOnly: false,
};

InvoicePart.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
};

InvoicePart.contextTypes = {
  intl: intlShape,
};

export default InvoicePart;
