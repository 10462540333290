import { defineMessages } from 'react-intl';

export const scope = 'novigo.entity.grid.counters';

export default defineMessages({
  elementName: {
    id: `${scope}.elementName`,
    defaultMessage: 'Elements',
  },
  selectedLabel: {
    id: `${scope}.selectedLabel`,
    defaultMessage: 'Selected',
  },
});
