import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from 'semantic-ui-react';

import { IntlShape, TranslationsShape } from './Shapes';

import messages from './messages/CreateButtonMessages';

const createButton = props => {
  const {
    onCreate = null,
    canCreateLoading = false,
    canCreateDisabled = false,
    intl,
    translations = {},
  } = props;

  // eslint-disable-next-line no-shadow
  const { createButton = {} } = translations;

  const { label = intl.formatMessage(messages.createLabel) } = createButton;

  return (
    <Button
      primary
      icon
      labelPosition="left"
      disabled={canCreateLoading || canCreateDisabled}
      loading={canCreateLoading}
      type="button"
      onClick={onCreate}
    >
      <Icon name="plus" />
      {label}
    </Button>
  );
};

createButton.defaultProps = {
  canCreateLabel: 'New',
  canCreateLoading: false,
  canCreateDisabled: false,
};

createButton.propTypes = {
  onCreate: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  canCreateLabel: PropTypes.string,
  /**
   * Handle the loading state of the button
   */
  canCreateLoading: PropTypes.bool,
  /**
   * Handle the disabled state of the button
   */
  canCreateDisabled: PropTypes.bool,

  // eslint-disable-next-line react/require-default-props
  intl: IntlShape,
  // eslint-disable-next-line react/require-default-props
  translations: TranslationsShape,
};

export default createButton;
