/*
 *
 * Login reducer
 *
 */

import { fromJS } from 'immutable';
import {
  CHANGE_USERNAME,
  CHANGE_PASSWORD,
  LOGIN_PASSWORD_GRANT,
  CHANGE_RECAPTCHA,
  STOP_LOADING,
  FORCE_PASSWORD_RESET,
  UNFORCE_PASSWORD_RESET,
} from './constants';

import { SET_TOKEN, LOG_OUT } from '../App/constants';

export const initialState = fromJS({
  username: '',
  password: '',
  isLogging: false,
  passwordExpired: false,
});

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_USERNAME:
      return state.set('username', action.name);
    case CHANGE_PASSWORD:
      return state.set('password', action.password);
    case LOGIN_PASSWORD_GRANT:
      return state.set('isLogging', true);
    case SET_TOKEN:
      return state.set('isLogging', false);
    case STOP_LOADING:
      return state.set('isLogging', false);
    case LOG_OUT:
      return state.set('isLogging', false);
    case CHANGE_RECAPTCHA:
      return state.set('captcha', action.captcha);
    case FORCE_PASSWORD_RESET:
      return state.set('passwordExpired', true);
    case UNFORCE_PASSWORD_RESET:
      return state.set('passwordExpired', false);
    default:
      return state;
  }
}

export default loginReducer;
