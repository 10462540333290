export const headerKeys = [
  {
    message: 'orderNumber',
    key: 'number',
  },
  {
    message: 'commercial_director',
    key: 'commercialDirector.displayName',
  },
  {
    message: 'destination',
    key: '_embedded.destination.businessName',
  },
  {
    message: 'orderDate',
    key: 'referenceDate',
    isDate: true,
  },
  {
    message: 'agent_1',
    key: 'agent1.displayName',
  },
  {
    message: 'payment_condition',
    key: '_embedded.destinationPaymentTerm.description',
  },
  {
    message: 'orderType',
    key: 'orderType.description',
  },
  {
    message: 'agent_2',
    key: 'agent2.displayName',
  },
  {
    message: 'cup',
    key: '_embedded.destination.cup',
  },
  {
    message: 'customer',
    key: '_embedded.customer.displayName',
  },
  {
    message: 'sub_agent',
    key: 'subagent.displayName',
  },
  {
    message: 'cig',
    key: '_embedded.destination.cig',
  },
  {
    message: 'area_manager',
    key: 'areaManager.displayName',
  },
  {
    message: 'signaller',
    key: 'signaller.displayName',
  },
  {
    message: 'status',
    key: 'state',
  },
  {
    message: 'buyingGroup',
    key: '_embedded.buyingGroup.displayName',
  },
  {
    message: 'dtm_bank',
    key: '_embedded.deterchimicaBank.displayName.',
  },
  {
    message: 'customer_bank',
    key: '_embedded.destinationBank.displayName',
  },
  {
    message: 'requested_delivery',
    key: 'deliveryRequestDate',
    isDate: true,
  },
  {
    message: 'promissed_delivery',
    key: 'deliveryPromiseDate',
    isDate: true,
  },
  {
    message: 'scheduled_delivery',
    key: 'deliveryPlanedDate',
    isDate: true,
  },
  {
    message: 'shipping_warehouse',
    key: 'shippedStoreDate',
    isDate: true,
  },
];

export const switchPayload = (code, payload) => {
  const data = payload;
  switch (code) {
    case 'PERCENTAGE':
      delete data.flatValue;
      // delete data.netPrice;
      delete data.markup;
      return data;
    case 'FLAT':
      delete data.discountValue;
      delete data.extraDiscount;
      delete data.netPrice;
      delete data.markup;
      return data;
    case 'FIXED':
      delete data.discountValue;
      delete data.extraDiscount;
      delete data.flatValue;
      delete data.markup;
      return data;
    case 'MARKUP':
      delete data.discountValue;
      delete data.extraDiscount;
      delete data.flatValue;
      delete data.netPrice;
      return data;
    default:
      return data;
  }
};
