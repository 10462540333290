import React from 'react';
import { get } from 'lodash';

import { Button, Modal as SimpleModal } from 'semantic-ui-react';
import messages from '../../messages';

const DiscountConfirmModal = props => {
  const {
    confirmModalState,
    setPromotionPrice,
    setConfirmModalState,
    values,
    promotionPrice,
    insertOrderRow,
  } = props;
  return (
    <>
      <SimpleModal
        size="small"
        open={confirmModalState}
        onClose={() => {
          setPromotionPrice(null);
          setConfirmModalState(false);
        }}
        closeIcon
      >
        <SimpleModal.Header>Add product</SimpleModal.Header>
        <SimpleModal.Content>
          <p>
            {props.intl.formatMessage(messages.promotion_exist)}
            {get(values, 'product.value')
              ? promotionPrice +
                props.intl.formatMessage(messages.promotion_exist_part)
              : ''}
            . {props.intl.formatMessage(messages.apply)}
          </p>
        </SimpleModal.Content>
        <SimpleModal.Actions>
          <Button
            negative
            onClick={() => {
              insertOrderRow(0);
              setConfirmModalState(false);
            }}
          >
            No
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="left"
            content="Yes"
            onClick={() => {
              insertOrderRow(1);
              setConfirmModalState(false);
            }}
          />
        </SimpleModal.Actions>
      </SimpleModal>
    </>
  );
};

export default DiscountConfirmModal;
