export const validation = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Required';
  }
  if (!values.delivery) {
    errors.delivery = 'Required';
  }

  return errors;
};
