import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import includes from 'lodash/includes';
import { injectIntl, intlShape } from 'react-intl';
import { Form, Grid, Pagination } from 'semantic-ui-react';
import ListManager from '../../../DeterchimicaEntityGrid/index';
import { ListWrapper } from '../../../Layout';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';

// Services
import {
  WorkOrderUnplannedColumns,
  WorkorderUnplannedFilters,
} from './tableHelper';

export const WorkorderUnplannedSelectionPart = props => {
  const [workOrderRows, setWorkOrderRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
    pageSize: 5,
    totalItems: 0,
  });
  const { handleSubmit, readOnly } = props;

  /**
   * Handles the changes of reload flag.
   */
  useEffect(
    () => {
      // Deselect all rows
      setSelectedRows([]);
    },
    [props.reload],
  );

  const onSelectedRow = row => {
    setSelectedRows([...selectedRows, row.id]);
  };

  const onDeselectedRow = row => {
    setSelectedRows(selectedRows.filter(id => id !== row.id));
  };

  const onSelectAllRows = () => {
    const selectedVisibleIds = workOrderRows.map(el => el.id);
    setSelectedRows([...new Set([...selectedRows, ...selectedVisibleIds])]);
  };

  const onDeselectAllRows = () => {
    const selectedVisibleIds = workOrderRows.map(el => el.id);
    const cleanIds = selectedRows.filter(
      id => !includes(selectedVisibleIds, id),
    );
    setSelectedRows(cleanIds);
  };

  const afterLoad = res => {
    const data = _.get(res, '_embedded.staff-workorder', []);
    setWorkOrderRows(data);
  };

  useEffect(
    () => {
      props.onSelectedRowsChange(selectedRows);
    },
    [selectedRows],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column width="16">
            <ListWrapper>
              <ListManager
                sessionKey="unplannedworkorders"
                // key={props.reload}
                reloadIndex={props.reload}
                entityName="staff-workorder"
                basePath={API.BASE_URL}
                locale={get(props, 'user.defaultLanguage', 'it')}
                router={props}
                client={pars => request(pars).then(res => res.data)}
                columns={WorkOrderUnplannedColumns(props.intl)}
                canSearch={false}
                canSearchCustom={WorkorderUnplannedFilters(
                  props.intl,
                  props.onSearch,
                )}
                canCreate={{
                  active: false,
                }}
                canSelect={{
                  active: !readOnly,
                  selectAll: true,
                  pageMax: 5,
                  handleSelectRow: row => onSelectedRow(row),
                  handleDeselectRow: row => onDeselectedRow(row),
                  handleSelectAll: () => onSelectAllRows(),
                  handleDeselectAll: () => onDeselectAllRows(),
                }}
                canPaginate={{
                  active: true,
                  render: (
                    <Pagination
                      activePage={pagination.page}
                      onPageChange={(e, { activePage }) => {
                        setPagination({ ...pagination, page: activePage });
                      }}
                      size="mini"
                      totalPages={pagination.pageCount}
                    />
                  ),
                }}
                defaultOrder={[
                  {
                    type: 'field',
                    field: 'createdAt',
                    direction: 'desc',
                  },
                ]}
                emptyResults={<div>No results!</div>}
                hiddenHeaderIfEmpty
                persistentFilter={[
                  {
                    type: 'eq',
                    field: 'state',
                    value: 'Da pianificare',
                  },
                ]}
                afterLoad={afterLoad}
              />
            </ListWrapper>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

WorkorderUnplannedSelectionPart.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  reload: PropTypes.func.isRequired,
  onSelectedRowsChange: PropTypes.func.isRequired,
  /**
   * The search callback.
   */
  onSearch: PropTypes.func,
};

export default injectIntl(WorkorderUnplannedSelectionPart);
