/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import { ModalFakeActions } from '../../../../../Layout';
import appMessages from '../../../../../../containers/App/messages';
import BaseForm from './baseForm';

class LastOfferForm extends Component {
  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            validate={this.validate}
            validateOnBlur
            validateOnChange
            onSubmit={this.onSubmit}
            initialValues={this.props.initialValues || {}}
            render={props => <BaseForm {...props} fields={this.props.fields} />}
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.onClose}
            >
              <Icon name="remove" />
              {this.props.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
        </ModalFakeActions>
      </Grid>
    );
  }
}

LastOfferForm.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LastOfferForm);
