import React from 'react';
import SimpleFormikField from 'novigo-simple-formik-field';
import Select from 'react-select';
import PropTypes from 'prop-types';

function FormikSelect(props) {
  return (
    <SimpleFormikField name={props.name} label={props.label} {...props}>
      {({
        // field, // { name, value, onChange, onBlur }
        form: { setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={props.defaultValue || ''}
          name={props.name}
          options={props.options}
          value={props.value || false}
          isLoading={props.isLoading}
          isMulti={props.isMulti}
          isDisabled={props.isDisabled}
          isClearable={props.isClearable}
          onChange={
            props.onChange || (data => setFieldValue(props.name, data.value))
          }
        />
      )}
    </SimpleFormikField>
  );
}

FormikSelect.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  loadOptions: PropTypes.func,
  isClearable: PropTypes.bool,
};

export default FormikSelect;
