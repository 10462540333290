function Validation(values) {
  const errors = {};

  // Agent is required
  if (!values.agent) {
    errors.agent = { required: 'Required' };
  }
  // Validation range is required
  if (!values.startDate) {
    errors.startDate = { required: 'Required' };
  }
  if (!values.endDate) {
    errors.endDate = { required: 'Required' };
  }

  return errors;
}

export default Validation;
