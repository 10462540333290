/**
 *
 * Invoices
 *
 */

import _ from 'lodash';
import moment from 'moment';
import ListManager from 'novigo-entity-grid';
import FormManager from 'novigo-form-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Header } from 'semantic-ui-react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import InvoicesForm, { validation } from '../../components/Forms/InvoiceForm';
import { ListWrapper, Page } from '../../components/Layout';
/*
 * Specific import
 */
import { API } from '../../global-constants';
import request from '../../shared/services/request';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import currencyFormatter from '../../utils/currencyFormatter';
import { addNotification } from '../../utils/notification';
import appMessages from '../App/messages';
import { makeSelectStyles } from '../App/selectors';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import InvoiceFilters from './search';
import makeSelectInvoices from './selectors';

/* eslint-disable react/prefer-stateless-function */
export class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      aliases: [
        {
          type: 'leftjoin',
          field: 'destination',
          alias: 'destination',
        },
        {
          type: 'leftjoin',
          field: 'customer',
          alias: 'customer',
        },
        {
          type: 'leftjoin',
          field: 'agents',
          alias: 'agent',
        },
        {
          type: 'leftjoin',
          field: 'typology',
          alias: 'typology',
        },
      ],
      searchFilter: null,
      filters: [],
      filterLabels: [],
      initialValues: {},
      searchedValue: '',
      removedFilter: '',
    };
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'view' ||
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'modify' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showForm: true });
    } else {
      this.setState({ showForm: false });
    }
  }

  handleInitialFilters() {
    const { aliases, initialValues, filterLabels } = this.state;

    /** Add referenceFrom and referenceTo in order to obtain current year invoices by default */
    const referenceDateFrom = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);
    const referenceDateTo = new Date(
      new Date().getFullYear(),
      11,
      31,
      23,
      59,
      59,
    );

    filterLabels.push({
      key: 'referenceDateFrom',
      name: this.context.intl.formatMessage(messages.referenceDateFrom),
      label: `${referenceDateFrom.toLocaleDateString()} `,
      filterField: 'referenceDateFrom',
    });
    aliases.push({
      type: 'gte',
      where: 'and',
      field: 'referenceDate',
      value: `${moment(referenceDateFrom).format('YYYY-MM-DD')} 00:00:00`,
      format: 'Y-m-d H:i:s',
    });
    initialValues.referenceDateFrom = `${moment(referenceDateFrom)}`;

    filterLabels.push({
      key: 'referenceDateTo',
      name: this.context.intl.formatMessage(messages.referenceDateTo),
      label: `${referenceDateTo.toLocaleDateString()} `,
      filterField: 'referenceDate',
    });
    aliases.push({
      type: 'lte',
      where: 'and',
      field: 'referenceDate',
      value: `${moment(referenceDateTo).format('YYYY-MM-DD')} 23:59:59`,
      format: 'Y-m-d H:i:s',
    });
    initialValues.referenceDateTo = `${moment(referenceDateTo)}`;

    this.setState({ aliases, initialValues, filterLabels });
  }

  onDefaultSearch = (val, filters) => {
    this.setState({ searchedValue: val, searchFilter: filters });
  };

  handleSearch = (filters, values, labels) => {
    const { aliases } = this.state;

    const updatedAliases = aliases.map(alias => {
      // If the filters already contain this field, return filters value
      // otherwise return the predefined alias
      const filterData = filters.find(
        item => item.field === alias.field && item.type === alias.type,
      );

      if (filterData) {
        return filterData;
      }
      return alias;
    });

    return new Promise(resolve => {
      this.setState({
        filters,
        initialValues: values,
        aliases: updatedAliases,
        filterLabels: labels,
      });
      resolve(true);
    });
  };

  handleRemovedFilter = name => {
    const { initialValues } = this.state;
    initialValues[name] = null;
    this.setState({
      initialValues,
      removedFilter: '',
    });
  };

  onRemoveFilter = val => {
    const { initialValues } = this.state;

    const filterLabels = this.state.filterLabels.filter(
      el => el.filterField !== val,
    );
    const filters = this.state.filters.filter(el => el.field !== val);

    _.unset(initialValues, val);

    this.setState(prev => ({
      removedFilter: prev.removedFilter ? '' : val,
      filterLabels,
      filters,
      initialValues,
    }));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.evaluteAction();
    this.handleInitialFilters();
  }

  downloadDocument = res => {
    const contentDisposition = res.headers['content-disposition'];
    const filename = contentDisposition.match(`filename="(.*)"`)[1];
    const newBlob = new Blob([res.data], { type: res.data.type });
    const fileUrl = window.URL.createObjectURL(newBlob);
    const tempLink = document.createElement('a');
    tempLink.href = fileUrl;
    tempLink.setAttribute('download', filename);
    tempLink.click();
  };

  handleLabel = (data = null) => {
    if (data !== null || !_.isEmpty(data)) {
      let code = '';
      if (data.code) {
        code = `${data.code} -`;
      }
      return `${code} ${data.displayName}`;
    }
    return '';
  };

  transformData = data => ({
    ...data,
    customer: {
      label: this.handleLabel(data.customer ? data.customer : null),
      value: _.get(data, 'customer.id', ''),
    },
    destination: {
      label: this.handleLabel(data.destination ? data.destination : null),
      value: _.get(data, 'destination.id', ''),
      address: _.get(data, 'destination.address', ''),
    },
    typology: {
      label: _.get(data, 'typology.description', ''),
      value: _.get(data, 'typology.id', ''),
    },
    referenceDate: _.get(data, 'referenceDate.date')
      ? moment(data.referenceDate.date)
      : null,
    agents: _.get(data, 'agents', [])
      .map(
        agent =>
          agent && {
            label: agent.displayName,
            value: agent.id,
          },
      )
      .filter(agent => agent),
  });

  render() {
    return (
      <>
        <Helmet>
          <title>Invoices</title>
          <meta name="description" content="Invoices" />
        </Helmet>
        <Page>
          <Header as="h2" dividing>
            <FormattedMessage {...messages.title} />
          </Header>
          <BreadcrumbsItem to="/invoice">
            {this.context.intl.formatMessage(messages.title)}
          </BreadcrumbsItem>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/invoice/edit">
                {actionToBreadcrumb({
                  action: this.props.match.params.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                key={this.props.match.params.action} // necessary to reload the current view upon redirect
                router={this.props} // necessary to use the internal routing
                client={pars =>
                  request(pars).then(res => this.transformData(res.data))
                }
                mask={props => (
                  <InvoicesForm
                    {...props}
                    editRoute={`/invoice/${this.props.match.params.id}/view`}
                    history={this.props.history}
                  />
                )}
                validation={validation}
                onCleanData={data => {
                  const cleanData = _.cloneDeep(data);

                  _.unset(cleanData, 'id');
                  _.unset(cleanData, '_links');
                  _.unset(cleanData, '_embedded');
                  _.unset(cleanData, 'displayName');
                  _.unset(cleanData, 'createdAt');
                  _.unset(cleanData, 'updatedAt');
                  _.unset(cleanData, 'deletedAt');
                  _.unset(cleanData, 'paymentCondition');
                  _.unset(cleanData, 'paymentTerm');
                  _.unset(cleanData, 'expirationDate');
                  _.unset(cleanData, 'holder');
                  _.unset(cleanData, 'kind');
                  _.unset(cleanData, 'invoiceOrder');
                  _.unset(cleanData, 'bank');
                  _.unset(cleanData, 'isMultipleInvoice');
                  _.unset(cleanData, 'isManualInvoice');
                  _.unset(cleanData, 'readAt');
                  _.unset(cleanData, 'writeAt');

                  cleanData.customer =
                    _.get(cleanData, 'customer.value') || cleanData.customer;

                  cleanData.invoiceDate = cleanData.invoiceDate
                    ? cleanData.invoiceDate.format('YYYY-MM-DD')
                    : null;

                  cleanData.month = _.get(cleanData, 'month.value')
                    ? cleanData.month.value
                    : cleanData.month;
                  cleanData.year = cleanData.year.value
                    ? cleanData.year.value
                    : cleanData.year;

                  return cleanData;
                }}
                entityName="invoice"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                data={
                  this.props.match.params.id === 'new' && {
                    number: '',
                    vat: '',
                    year: '',
                    month: '',
                    customer: '',
                  }
                }
                afterCreate={() => false}
                onError={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  })
                }
                onSuccess={() =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
              />
            </>
          ) : (
            <ListWrapper>
              <ListManager
                entityName="invoice"
                sessionKey="invoice"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                router={this.props}
                persistentFilter={[
                  {
                    type: 'eq',
                    where: 'and',
                    field: 'isActive',
                    value: '1',
                  },
                ]}
                client={pars => request(pars).then(res => res.data)}
                canCreate={{
                  active: false,
                }}
                locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                canExport={{
                  active: false,
                  exportLabelPrefix: this.context.intl.formatMessage(
                    appMessages.export_to,
                  ),
                }}
                canSearchCustom={InvoiceFilters({
                  intl: this.context.intl,
                  initialValues: this.state.initialValues,
                  filters: this.state.filters,
                  removedFilter: this.state.removedFilter,
                  hasCustomerFilter: true,
                  preselectCurrentYear: true,
                  handleRemovedFilter: this.handleRemovedFilter,
                  onRemoveFilter: this.onRemoveFilter,
                  onSearch: (filters, values, labels) =>
                    this.handleSearch(filters, values, labels),
                })}
                defaultOrder={[
                  {
                    type: 'field',
                    field: 'referenceDate',
                    direction: 'DESC',
                  },
                ]}
                aliases={this.state.aliases}
                customSearchFilters={this.state.filters}
                filterLabels={this.state.filterLabels}
                searchedValue={this.state.searchedValue}
                searchFilter={this.state.searchFilter}
                onRemoveFilter={this.onRemoveFilter}
                onDefaultSearch={this.onDefaultSearch}
                columns={[
                  {
                    key: 'documentNumber',
                    name: this.context.intl.formatMessage(
                      messages.invoice_number,
                    ),
                    searchable: true,
                    sortable: true,
                    width: '10%',
                  },
                  {
                    key: 'referenceDate',
                    name: this.context.intl.formatMessage(
                      messages.referenceDate,
                    ),
                    searchable: true,
                    sortable: true,
                    width: '10%',
                    formatter: ({ data }) => {
                      if (data.referenceDate) {
                        return moment(data.referenceDate.date).format(
                          'DD-MM-YYYY',
                        );
                      }
                      return '--';
                    },
                    exportFormatter: ({ data }) => {
                      if (data.referenceDate) {
                        return moment(data.referenceDate.date).format(
                          'DD-MM-YYYY',
                        );
                      }
                      return '--';
                    },
                  },
                  {
                    key: 'headquarter',
                    name: this.context.intl.formatMessage(messages.headquarter),
                    searchable: false,
                    sortable: false,
                    formatter: ({ data }) =>
                      _.get(data, 'customer.displayName', '--'),
                    exportFormatter: ({ data }) =>
                      _.get(data, 'customer.displayName', '--'),
                    width: '30%',
                  },
                  {
                    key: 'agent',
                    name: this.context.intl.formatMessage(messages.agent),
                    searchable: false,
                    sortable: false,
                    formatter: ({ data }) =>
                      _.get(data, 'agents', [])
                        .map(agent => agent && agent.displayName)
                        .filter(agent => agent)
                        .join(','),
                    exportFormatter: ({ data }) =>
                      _.get(data, 'agents', [])
                        .map(agent => agent && agent.displayName)
                        .filter(agent => agent)
                        .join(','),
                    width: '20%',
                  },
                  {
                    key: 'typology',
                    name: this.context.intl.formatMessage(messages.type),
                    searchable: false,
                    sortable: false,
                    formatter: ({ data }) =>
                      _.get(data, 'typology.description', '--'),
                    exportFormatter: ({ data }) =>
                      _.get(data, 'typology.description', '--'),
                  },
                  {
                    key: 'totalAmount',
                    name: this.context.intl.formatMessage(messages.totalAmount),
                    searchable: false,
                    sortable: false,
                    formatter: ({ data }) =>
                      currencyFormatter.format(data.totalAmount || 0),
                    exportFormatter: ({ data }) =>
                      currencyFormatter.format(data.totalAmount || 0),
                  },
                ]}
                defaultActions={{
                  visualize: true,
                  modify: false,
                  delete: false,
                  grouped: false,
                  moreActions: [],
                }}
              />
            </ListWrapper>
          )}
        </Page>
      </>
    );
  }
}

Invoices.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  invoices: makeSelectInvoices(),
  styles: makeSelectStyles(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'invoices', reducer });
const withSaga = injectSaga({ key: 'invoices', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Invoices);

Invoices.contextTypes = {
  intl: PropTypes.object.isRequired,
};
