/**
 * CAN FOR Functions management
 */
import _ from 'lodash';
import { store } from '../../../configureStore';
import { formatACL } from '../selectors';

function Can(acl, action, subject, where, matchWith) {
  const checkCan = _.get(acl, `${subject}.${action}.can`);
  // const checkOwn = _.get(acl, `${subject}.${action}.onlyMine`); // TODO: concordare con il backend una strategia, ora non presente nemmeno createdBy
  if (where && matchWith) return where === matchWith;
  return !!checkCan;
}

export const CanI = (action, subject, where, matchWith) => {
  const ACL = formatACL(
    store
      .getState()
      .get('user')
      .toJS(),
  );
  return Can(ACL, action, subject, where, matchWith);
};

export const CanManage = user => {
  const roles = _.get(user, 'roles', []);
  const isAdmin = roles.includes('ROLE_ADMIN');
  return isAdmin;
};

export const IsUser = user => {
  const currentUser = store
    .getState()
    .get('user')
    .toJS();

  return currentUser.userIdentity.id === user;
};

/**
 * Get the user roles.
 * @returns {Array} the roles
 */
export const GetCurrentRoles = () =>
  _.get(
    store
      .getState()
      .get('user')
      .toJS(),
    'userIdentity.roles',
    [],
  );
