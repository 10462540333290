/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Icon, Form, Accordion, Label } from 'semantic-ui-react';
import { Formik } from 'formik';
import forOwn from 'lodash/forOwn';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectStyles } from '../../containers/App/selectors';
import appMessages from '../../containers/App/messages';

/**
 * The custom search.
 * @param {Object} props
 * @returns
 */
const BaseSearch = props => {
  const {
    customSearchFilterNumber,
    onSearch,
    onReset,
    formStructure,
    initialValues,
  } = props;
  const [isAccordionOpen, setAccordionOpen] = useState(false);

  /**
   * If a custom reset function is present then call it or else do normal reset
   */
  const handleReset = ({ values, setFieldValue }) => {
    if (onReset && typeof onReset === 'function') {
      onReset({ values, setFieldValue });
    } else {
      forOwn(values, (_value, key) => setFieldValue(key, null));
    }
  };

  return (
    <Grid style={{ marginBottom: '1em' }}>
      <Grid.Row>
        <Grid.Column>
          <Accordion fluid styled>
            <Accordion.Title
              onClick={() => setAccordionOpen(!isAccordionOpen)}
              active={isAccordionOpen}
              style={{
                backgroundColor: props.styles.MAIN_COLOR,
                color: 'white',
              }}
            >
              <Icon name="dropdown" />
              <FormattedMessage {...appMessages.search} />
              <Label
                style={{
                  color: 'white',
                  backgroundColor: 'rgb(92, 163, 77)',
                  borderRadius: '50%',
                  display: 'inline-block',
                  float: 'right',
                }}
              >
                {customSearchFilterNumber}
              </Label>
            </Accordion.Title>
            <Accordion.Content active={isAccordionOpen}>
              <Formik
                onSubmit={onSearch}
                initialValues={initialValues}
                enableReinitialize
                render={formikBag => (
                  <Form
                    onSubmit={formikBag.handleSubmit}
                    loading={formikBag.isSubmitting || formikBag.isValidating}
                    style={{ width: '100%' }}
                  >
                    <Grid>
                      <Grid.Row columns={4}>
                        {formStructure(formikBag)}
                      </Grid.Row>
                    </Grid>

                    <Grid.Row columns={4}>
                      <Grid>
                        <Grid.Row textAlign="right">
                          <Grid.Column>
                            <Button
                              type="button"
                              icon
                              labelPosition="left"
                              onClick={() => handleReset(formikBag)}
                            >
                              <Icon name="times" />
                              <FormattedMessage {...appMessages.reset} />
                            </Button>
                            <Button
                              loading={
                                formikBag.isSubmitting || formikBag.isValidating
                              }
                              type="button"
                              onClick={() => formikBag.submitForm()}
                              icon
                              color="blue"
                              labelPosition="left"
                            >
                              <Icon name="search" />
                              <FormattedMessage {...appMessages.search} />
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Row>
                  </Form>
                )}
              />
            </Accordion.Content>
          </Accordion>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

/**
 * The prop types.
 */
BaseSearch.propTypes = {
  /**
   * The number of the element in the form.
   * TODO: I guess there is another way to get this property.
   */
  customSearchFilterNumber: PropTypes.number,

  /**
   * The function to call when the button "search" is pressed.
   * It returns a formikBag with all the values and the actions.
   */
  onSearch: PropTypes.func,

  /**
   * The internal form structure.
   */
  formStructure: PropTypes.func,

  /**
   * The initial values.
   */
  initialValues: PropTypes.object,

  styles: PropTypes.object,
  onReset: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  styles: makeSelectStyles(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(BaseSearch);
