/* eslint-disable no-underscore-dangle */
import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Segment, Dimmer, Loader } from 'semantic-ui-react';

export const transformData = data => {
  const res = {
    ...data,
    number: _.get(data, 'number'),
    note: _.get(data, 'note'),
    customer: _.get(data, '_embedded.customer.id', null) && {
      value: _.get(data, '_embedded.customer.id'),
      label: _.get(data, '_embedded.customer.displayName'),
    },
    staff: _.get(data, 'staff.id', null) && {
      value: _.get(data, 'staff.id'),
      label: _.get(data, 'staff.displayName'),
    },
    destination: _.get(data, 'destination.id', null) && {
      value: data.destination.id,
      label: `${data.destination.code} ${data.destination.businessName}`,
      businessAddressTitle: data.destination.businessAddressTitle,
      code: data.destination.code,
      businessName: data.destination.businessName,
    },
    destinationContact: _.get(
      data,
      '_embedded.destinationContact.id',
      null,
    ) && {
      value: _.get(data, '_embedded.destinationContact.id', ''),
      label: `${
        _.get(data, '_embedded.destinationContact') &&
        data._embedded.destinationContact.firstName
          ? data._embedded.destinationContact.firstName
          : ''
      } ${
        _.get(data, '_embedded.destinationContact') &&
        data._embedded.destinationContact.lastName
          ? data._embedded.destinationContact.lastName
          : ''
      }`,
    },
    contract: _.get(data, '_embedded.contract.id', null) && {
      value: _.get(data, '_embedded.contract.id', ''),
      label: `${_.get(data, '_embedded.contract.number', '')} ${_.get(
        data,
        '_embedded.contract.note',
        '',
      )}`,
    },
    plannedDate: _.get(data, 'plannedDate')
      ? moment(data.plannedDate.date, 'YYYY-MM-DD HH:mm')
      : null,
    startDate: _.get(data, 'startDate')
      ? moment(data.startDate.date, 'YYYY-MM-DD HH:mm')
      : moment(),
    endDate: _.get(data, 'endDate')
      ? moment(data.endDate.date, 'YYYY-MM-DD HH:mm')
      : moment(),
    evaluationExpirationDate: _.get(data, 'evaluationExpirationDate')
      ? moment(data.evaluationExpirationDate.date, 'YYYY-MM-DD')
      : moment(),
    workorderType: _.get(data, '_embedded.workorderType.id', null) && {
      value: _.get(data, '_embedded.workorderType.id'),
      label: _.get(data, '_embedded.workorderType.description'),
    },
    state: {
      value: _.get(data, 'state', 'Pianificato'),
      label: _.get(data, 'state', 'Pianificato'),
    },
    tabs: _.get(data, '_embedded.tabs', []),
    disinfectedAreaNote: _.get(data, 'disinfectedAreaNote', null),
    disinfectedAreaCockroachG: _.get(data, 'disinfectedAreaCockroachG', false),
    disinfectedAreaCockroachO: _.get(data, 'disinfectedAreaCockroachO', false),
    disinfectedAreaMosquito: _.get(data, 'disinfectedAreaMosquito', false),
    disinfectedAreaFlies: _.get(data, 'disinfectedAreaFlies', false),
    disinfectedAreaSpider: _.get(data, 'disinfectedAreaSpider', false),
    disinfectedAreaOther: _.get(data, 'disinfectedAreaOther', null),
    noteReason: _.get(data, 'noteReason.id', null) && {
      value: _.get(data, 'noteReason.id'),
      label: _.get(data, 'noteReason.description'),
      isPestControl: _.get(data, 'noteReason.isPestControl'),
    },
    reportSigner:
      data.reportSigner ||
      _.get(data, '_embedded.destination.contactPerson', null),
    reportEmail:
      data.reportEmail || _.get(data, '_embedded.destination.email', null),
  };
  return res;
};

export const onCleanData = data => {
  const cleanData = _.cloneDeep(data);
  _.unset(cleanData, 'id');
  _.unset(cleanData, '_links');
  _.unset(cleanData, '_embedded');
  _.unset(cleanData, 'createdAt');
  _.unset(cleanData, 'updatedAt');
  _.unset(cleanData, 'deletedAt');
  _.unset(cleanData, 'duration');
  _.unset(cleanData, 'customer');
  _.unset(cleanData, 'workorderType');
  _.unset(cleanData, 'number'); // temporary disabled
  _.unset(cleanData, 'createdBy');

  cleanData.staff = _.get(cleanData, 'staff.value', null);
  cleanData.destination = _.get(cleanData, 'destination.value', null);
  cleanData.destinationContact = _.get(
    cleanData,
    'destinationContact.value',
    null,
  );
  cleanData.contract = _.get(cleanData, 'contract.value', null);
  cleanData.note = _.get(cleanData, 'note', null);
  cleanData.plannedDate = cleanData.plannedDate
    ? cleanData.plannedDate.format('YYYY-MM-DD')
    : null;
  cleanData.startDate = cleanData.startDate
    ? cleanData.startDate.format('YYYY-MM-DD HH:mm:ss')
    : null;
  cleanData.endDate = cleanData.endDate
    ? cleanData.endDate.format('YYYY-MM-DD HH:mm:ss')
    : null;
  cleanData.evaluationExpirationDate = cleanData.evaluationExpirationDate
    ? cleanData.evaluationExpirationDate.format('YYYY-MM-DD')
    : null;
  cleanData.noteReason = _.get(cleanData, 'noteReason.value', null);

  cleanData.state = _.get(cleanData, 'state.value', null);

  cleanData.tabs = formatRequestTabs(_.get(cleanData, 'tabs', []));

  cleanData.disinfectedAreaNote = _.get(cleanData, 'disinfectedAreaNote', null);

  /** new fields */
  let disinfectedAreaCockroachG = _.get(
    cleanData,
    'disinfectedAreaCockroachG',
    false,
  );

  let disinfectedAreaCockroachO = _.get(
    cleanData,
    'disinfectedAreaCockroachO',
    false,
  );

  let disinfectedAreaMosquito = _.get(
    cleanData,
    'disinfectedAreaMosquito',
    false,
  );

  let disinfectedAreaFlies = _.get(cleanData, 'disinfectedAreaFlies', false);

  let disinfectedAreaSpider = _.get(cleanData, 'disinfectedAreaSpider', false);

  disinfectedAreaCockroachG = !disinfectedAreaCockroachG ? '0' : '1';
  disinfectedAreaCockroachO = !disinfectedAreaCockroachO ? '0' : '1';
  disinfectedAreaMosquito = !disinfectedAreaMosquito ? '0' : '1';
  disinfectedAreaFlies = !disinfectedAreaFlies ? '0' : '1';
  disinfectedAreaSpider = !disinfectedAreaSpider ? '0' : '1';
  cleanData.disinfectedAreaCockroachG = disinfectedAreaCockroachG;
  cleanData.disinfectedAreaCockroachO = disinfectedAreaCockroachO;
  cleanData.disinfectedAreaMosquito = disinfectedAreaMosquito;
  cleanData.disinfectedAreaFlies = disinfectedAreaFlies;
  cleanData.disinfectedAreaSpider = disinfectedAreaSpider;
  cleanData.disinfectedAreaOther = _.get(
    cleanData,
    'disinfectedAreaOther',
    null,
  );

  return cleanData;
};

const formatRequestTabs = data => {
  if (Array.isArray(data) && data.length > 0) {
    return data.map(item => {
      if (Array.isArray(item.indicators) && item.indicators.length > 0) {
        const formattedIndicators = item.indicators.map(indicator => ({
          ...indicator,
          ...{
            tool: indicator.tool.value,
            area: indicator.area.value,
            principle: indicator.principle.value,
            percentage: indicator.percent,
          },
        }));

        return { ...item, ...{ indicators: formattedIndicators } };
      }
      return item;
    });
  }
  return [];
};

export const formatIndicatorsResponse = data => {
  if (Array.isArray(data) && data.length > 0) {
    return data.map((item, index) => ({
      ...item,
      ...{
        key: index + 1,
        id: item.id,
        name: item.name,
        tool: { key: item.tool, label: item.tool, value: item.tool },
        area: { key: item.area, label: item.area, value: item.area },
        principle: {
          key: item.principle,
          label: item.principle,
          value: item.principle,
        },
        percent: item.percentage,
        description: item.description,
        specie: item.specie,
      },
    }));
  }
  return [];
};

export const updateTab = ({ tab, values, setFieldValue }) => {
  const currentTabs = _.get(values, 'tabs', []);
  if (Array.isArray(currentTabs)) {
    const updatedTabs = currentTabs.map(item => {
      if (item.tabIdentifier === tab.tabIdentifier) {
        return tab;
      }
      return item;
    });

    setFieldValue('tabs', updatedTabs);
  }
};

export const Loading = () => (
  <Segment style={{ padding: '20px auto', height: '50px' }} basic>
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  </Segment>
);
