import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';
import messages from '../../../components/Forms/OfferForm/messages';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.DISCOUNT_TYPE,
    method: 'GET',
  });

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.DISCOUNT_TYPE,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, intl, callback) => {
  searchByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.discount-type', []).map(discountType => ({
        value: discountType.id,
        code: discountType.code,
        label: intl
          ? intl.formatMessage(messages[discountType.code])
          : discountType.description,
      })),
    );
  });
};

const getAllOptions = intl =>
  request({
    baseURL: API.BASE_URL,
    url: API.DISCOUNT_TYPE,
    method: 'GET',
    params: {},
  }).then(res =>
    _.get(res, 'data._embedded.discount-type', []).map(discountType => ({
      value: discountType.id,
      code: discountType.code,
      label: intl
        ? intl.formatMessage(messages[discountType.code])
        : discountType.description,
    })),
  );

const DiscountTypeService = {
  getAll,
  getOptions,
  getAllOptions,
  searchByName,
};

export default DiscountTypeService;
