import React, { useEffect } from 'react';
import _, { get } from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import Accordion from '../../../Accordion';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import LegalPart from '../../Anagrafica/formParts/legalPart';
import InvoicePart from '../../Anagrafica/formParts/invoicePart';
import messages from '../messages';
import PermissionManager from '../../../Permission';

// Services
import ProfileService from '../../../../shared/services/profile';
import customerService from '../../../../shared/services/customer';
import prospectService from '../../../../shared/services/prospect';
import DestinationStateService from '../../../../shared/services/destinationstate';

function GeneralTab(props) {
  const { isNew, setValues, setFieldValue, values } = props;
  const isProspect = props.mode === 'prospect';

  useEffect(
    () => {
      if (
        props.history.location.customer &&
        props.history.location.customer.value
      ) {
        const method = props.history.location.isCustomer
          ? customerService
          : prospectService;

        method.read(props.history.location.customer.value).then(res => {
          const customer = _.get(res, 'data');
          setValues({
            ...props.values,
            customer: props.history.location.customer,
            businessName: _.get(customer, 'displayName', null),
            vatNumber: _.get(customer, 'vatNumber', null),
            businessFiscalCode:
              _.get(customer, 'fiscalCode', null) ||
              _.get(customer, 'businessFiscalCode', null),
            businessStreet:
              _.get(customer, 'businessStreet', null) ||
              _.get(customer, 'residenceStreet', null),
            businessAddressTitle:
              _.get(customer, 'businessAddressTitle', null) ||
              _.get(customer, 'residenceAddressTitle', null),
            businessCity:
              _.get(customer, 'businessCity', null) ||
              _.get(customer, 'residenceCity', null),
            businessProvinceShort:
              _.get(customer, 'businessProvinceShort', null) ||
              _.get(customer, 'residenceProvinceShort', null),
            businessPostalCode:
              _.get(customer, 'businessPostalCode', null) ||
              _.get(customer, 'residencePostalCode', null),
            businessCountry:
              _.get(customer, 'businessCountry', null) ||
              _.get(customer, 'residenceCountry', null),
            businessLatitude:
              _.get(customer, 'businessLatitude', null) ||
              _.get(customer, 'residenceLatitude', null),
            businessLongitude:
              _.get(customer, 'businessLongitude', null) ||
              _.get(customer, 'residenceLongitude', null),
          });
        });
      }
    },
    [props.history.location.customer],
  );

  useEffect(() => {
    // Preselect ACTIVE state if I'm creating a new entry
    if (isNew) {
      DestinationStateService.getMatchingDescription('ACTIVE', res => {
        if (res && res.length > 0) {
          setFieldValue('destinationState', res[0]);
        }
      });
    }
  }, []);

  return (
    <div>
      <Grid style={{ paddingBottom: 15 }}>
        <Grid.Row columns={3}>
          {(!isNew || (isNew && !isProspect)) && (
            <Grid.Column>
              <FormikAsyncSelect
                {...props}
                required={!isProspect}
                name="customer"
                readOnly={
                  !PermissionManager.CanI('destinationCustomerEdit', 'custom')
                }
                isDisabled={
                  !PermissionManager.CanI('destinationCustomerEdit', 'custom')
                }
                label={props.intl.formatMessage(messages.headquarter)}
                defaultValue={
                  props.history.location.customer
                    ? props.history.location.customer
                    : ''
                }
                loadOptions={(searchText, callback) =>
                  customerService.getOptions(searchText, callback, true)
                }
                onBlur={() => { }}
              />
            </Grid.Column>
          )}
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              isClearable
              readOnly={props.readOnly || !isProspect}
              name="signaller"
              label={props.intl.formatMessage(messages.signaller)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAgentOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Accordion title={props.intl.formatMessage(messages.destination_data)}>
        <LegalPart {...props} />
      </Accordion>
      {// eslint-disable-next-line prettier/prettier
        !isNew &&
        get(values, 'customer', false) && (
          <Accordion title={props.intl.formatMessage(messages.invoice_data)}>
            <InvoicePart {...props} />
          </Accordion>
        )}
    </div>
  );
}

GeneralTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(GeneralTab);
