import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';

import { Formik } from 'formik';

import { intlShape } from 'react-intl';

import { get /* has */ } from 'lodash';
import moment from 'moment';
import Modal from '../../Modal';
import CommissionService from '../../../shared/services/commission';
import CommissionTable from './CommissionTable';

const CommissionView = props => {
  const { open, commission } = props;

  const [commissionData, setCommissionData] = useState();
  const [commissionTitle, setCommissionTitle] = useState();

  const [initialValues, setInitialValues] = useState({
    id: commission,
    totalBonusAmount: 0,
    totalCommissionAmount: 0,
    totalRefundAmount: 0,
    totalAmount: 0,
    editedTotalBonusAmount: 0,
    editedTotalCommissionAmount: 0,
    editedTotalRefundAmount: 0,
    editedTotalAmount: 0,
  });

  useEffect(() => {
    loadCommission();
  }, []);

  /**
   * Load the commission data.
   */
  const loadCommission = () => {
    CommissionService.get(commission).then(response => {
      const data = get(response, 'data');
      setCommissionData(data);
      mapToInitialValues(data);

      const titleParts = [
        data.code,
        moment(data.referenceDate.date).format('DD/MM/YYYY'),
        data.agent.displayName,
        data.customer.displayName,
        data.destination.displayName,
      ].filter(o => o);

      setCommissionTitle(titleParts.join(' - '));
    });
  };

  /**
   * Transform the data from the commission API responto into a Formik-readable format.
   * @param {Object} data the data from the commission API
   */
  const mapToInitialValues = data => {
    const {
      id,
      totalBonusAmount = 0,
      totalCommissionAmount = 0,
      totalRefundAmount = 0,
      totalAmount = 0,
      editedTotalBonusAmount = 0,
      editedTotalCommissionAmount = 0,
      editedTotalRefundAmount = 0,
      editedTotalAmount = 0,
    } = data;

    setInitialValues({
      id,
      totalBonusAmount,
      totalCommissionAmount,
      totalRefundAmount,
      totalAmount,
      editedTotalBonusAmount,
      editedTotalCommissionAmount,
      editedTotalRefundAmount,
      editedTotalAmount,
    });
  };

  const onUpdateCommission = values => {
    const {
      id,
      editedTotalBonusAmount,
      editedTotalCommissionAmount,
      editedTotalRefundAmount,
      editedTotalAmount,
    } = values;

    CommissionService.update({
      id,
      editedTotalBonusAmount,
      editedTotalCommissionAmount,
      editedTotalRefundAmount,
      editedTotalAmount,
    }).then(response => {
      const data = get(response, 'data');
      setCommissionData(data);
      mapToInitialValues(data);
    });
  };

  /**
   * Overrides the onClose method with the reloading data feature.
   */
  const onClose = () => {
    props.onClose();
    props.reloadData();
  };

  return (
    <Modal open={open} onClose={onClose} size="large" title={commissionTitle}>
      <Dimmer active={!commissionData} inverted>
        <Loader />
      </Dimmer>

      <Grid>
        <Formik
          component={formikBag => (
            <CommissionTable {...formikBag} onClose={onClose} />
          )}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onUpdateCommission}
        />
      </Grid>
    </Modal>
  );
};

CommissionView.propTypes = {
  /**
   * Flag to open the modal.
   */
  open: PropTypes.bool.isRequired,

  /**
   * Callback to close the modal.
   */
  onClose: PropTypes.func.isRequired,

  /**
   * The ID of the commission.
   */
  commission: PropTypes.string.isRequired,

  /**
   * It reloads the commission data.
   */
  reloadData: PropTypes.func,
};

CommissionView.contextTypes = {
  intl: intlShape,
};

export default CommissionView;
