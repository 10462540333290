import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the notesHub state domain
 */

const selectNotesHubDomain = state => state.get('notesHub', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by NotesHub
 */

const makeSelectNotesHub = () =>
  createSelector(selectNotesHubDomain, substate => substate.toJS());

export default makeSelectNotesHub;
export { selectNotesHubDomain };
