import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

const commentStyle = {
  marginLeft: -22,
  marginRight: -22,
  marginBottom: 0,
  padding: 22,
  paddingTop: 0,
  borderBottom: '1px solid #ccc',
};

const dateStyle = {
  fontSize: 10,
  color: '#ccc',
  fontStyle: 'italic',
  opacity: 0.7,
};

export default class Comment extends Component { // eslint-disable-line

  static propTypes = {
    comment: PropTypes.object,
    styles: PropTypes.object,
  };

  usernameStyle = {
    marginTop: 22,
    marginBottom: 22,
    fontSize: 12,
    color: this.props.styles.SECOND_COLOR,
    fontWeight: 'bold',
    opacity: 0.8,
  };

  render() {
    return (
      <div style={commentStyle}>
        <div style={this.usernameStyle}>
          {_.get(this.props.comment, 'author.displayName') || '--'}{' '}
          <span style={dateStyle}>
            {moment(new Date(this.props.comment.created)).format('DD/MM/YYYY')}{' '}
            <span style={{ fontSize: 9 }}>
              {moment(new Date(this.props.comment.created)).format('HH:MM')}
            </span>
          </span>
        </div>
        <div>{this.props.comment.body}</div>
      </div>
    );
  }
}
