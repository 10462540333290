import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const print = invoiceId =>
  request({
    baseURL: API.BASE_URL,
    url: API.INVOICING_MANAGER,
    method: 'POST',
    responseType: 'blob',
    data: {
      method: 'print_pdf',
      params: {
        invoiceId,
      },
    },
  });

const getXML = invoiceId =>
  request({
    baseURL: API.BASE_URL,
    url: API.INVOICING_MANAGER,
    method: 'POST',
    responseType: 'blob',
    data: {
      method: 'export_xml',
      params: {
        invoiceId,
      },
    },
  });

const getCommissionableInvoices = (
  customer,
  createdFrom,
  createdTo,
  filterType,
  agents,
  page,
) =>
  request({
    baseURL: API.BASE_URL,
    url: API.INVOICE,
    method: 'GET',
    params: {
      page,
      pageSize: 5,
      filter: [
        {
          type: 'commissionable_invoice_filter',
        },
        customer && {
          type: filterType,
          field: 'customer',
          value: customer,
        },
        agents && {
          type: 'innerjoin',
          field: 'agents',
          alias: 'agent',
        },
        agents && {
          type: 'eq',
          field: 'agent',
          alias: 'agent',
          value: agents,
        },
        createdFrom && {
          type: 'gte',
          field: 'referenceDate',
          value: `${createdFrom.format('YYYY-MM-DD')}`,
        },
        createdTo && {
          type: 'lte',
          field: 'referenceDate',
          value: `${createdTo.format('YYYY-MM-DD')}`,
        },
      ].filter(f => f),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getLastInvoiceTurnover = (destination, year) =>
  request({
    baseURL: API.BASE_URL,
    url: API.INVOICE,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'last_invoice_turnover',
          destination,
          year,
        },
      ].filter(f => f),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const InvoiceService = {
  print,
  getXML,
  getCommissionableInvoices,
  getLastInvoiceTurnover,
};

export default InvoiceService;
