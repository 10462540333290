import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Form } from 'formik';
import { Grid } from 'semantic-ui-react';
import FormikMonthSelect from '../../../FormikMonthSelect';
import { PaddedColumn } from '../../../Layout';
import messages from '../messages';
import AgentCurrencyFormatter from './AgentCurrencyFormatter';

const AgentPlannedForm = props => (
  <Form>
    <Grid padded>
      <Grid.Row>
        <PaddedColumn width={16}>
          <FormikMonthSelect
            name="month"
            required
            label={props.intl.formatMessage(messages.month)}
            {...props}
          />
        </PaddedColumn>
      </Grid.Row>
      <Grid.Row>
        <PaddedColumn width={16}>
          <AgentCurrencyFormatter
            name="planned"
            required
            label={props.intl.formatMessage(messages.planned)}
            placeholder={props.intl.formatMessage(messages.planned)}
            {...props}
          />
        </PaddedColumn>
      </Grid.Row>
    </Grid>
  </Form>
);

AgentPlannedForm.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AgentPlannedForm);
