import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import BaseForm from './form';
import { addNotification } from '../../../utils/notification';
import { ModalFakeActions } from '../../Layout';
import appMessages from '../../../containers/App/messages';
import getError from '../../../utils/getError';
import attachmentService from '../../../shared/services/attachments';
import StaffWorkorderAttachmentService from '../../../shared/services/staffworkorderattachment';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  noteText: _.get(newValues, 'noteText', ''),
  noteNumber: _.get(newValues, 'noteNumber', ''),
  noteType: {
    value: _.get(newValues, 'noteType.id', ''),
    label: _.get(newValues, 'noteType.description', ''),
  },
  noteReason: {
    value: _.get(newValues, 'noteReason.id', ''),
    label: _.get(newValues, 'noteReason.description', ''),
    technical: _.get(newValues, 'noteReason.technical', ''),
    isPestControl: _.get(newValues, 'noteReason.isPestControl', ''),
  },
  staff: {
    value: _.get(newValues, 'staff.id', ''),
    label: _.get(newValues, 'staff.displayName', ''),
  },
  assignee: {
    value: _.get(newValues, 'assignee.value', ''),
    label: _.get(newValues, 'assignee.label', null),
  },
  expireDate: _.get(newValues, 'expireDate', ''),
  users: _.get(newValues, 'users', []),
  closedNote: _.get(newValues, 'closedNote', null),
});

export default class CustomerNote extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    noteId: PropTypes.string,
    noteService: PropTypes.object,
    entity: PropTypes.string,
    isReadOnly: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      images: [],
    };
  }

  handleImagesChange = images => {
    this.setState({
      images,
    });
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const expireDate = _.get(values, 'expireDate', null);
    const payload = {
      id: values.id || null,
      noteText: _.get(values, 'noteText', null),
      noteNumber: _.get(values, 'noteNumber', null),
      assignee: _.get(values, 'assignee.value', null),
      noteType: _.get(values, 'noteType.value', null),
      noteReason: _.get(values, 'noteReason.value', null),
      staff: _.get(values, 'staff.value', null),
      expireDate: expireDate ? expireDate.format('YYYY-MM-DD HH:mm') : null,
      users:
        _.get(values, 'users', []).length > 0
          ? _.get(values, 'users').map(user => user.value)
          : [],
      [this.props.entity]: this.props.entityId,
    };
    const isNew = !payload.id;

    const method = isNew
      ? this.props.noteService.create
      : this.props.noteService.update;

    method(payload)
      .then(res => {
        // If it's new, save also the added images
        if (isNew && this.state.images && this.state.images.length > 0) {
          this.uploadImagesAttachments(
            this.state.images,
            res.id,
            setSubmitting,
          );
        } else {
          setSubmitting(false);
          addNotification({
            title: null,
            message: 'Nota salvata con successo',
            isError: false,
          });
          this.props.update();
          this.props.close();
        }
      })
      .catch(err => {
        setSubmitting(false);
        setErrors(getError(err));
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  uploadImagesAttachments = (images, entityId, setSubmitting) => {
    const imageArray = images;
    const files = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < imageArray.length; i++) {
      if (imageArray[i].name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {
        files.push(imageArray[i]);
      } else {
        addNotification({
          title: null,
          message: `Image with name ${
            imageArray[i].name
          } not in required format!`,
          isError: true,
        });
      }
    }
    if (files.length === 0) {
      addNotification({
        title: null,
        message: 'Please upload valid images!',
        isError: true,
      });
    } else if (files.length > 8 - this.state.files.length) {
      addNotification({
        title: null,
        message: 'You can only upload 8 images!',
        isError: true,
      });
    } else {
      const data = {
        entityType: `Fotografia_${this.props.entityId}`,
        entityId: this.props.entityId,
        entityName: 'StaffWorkorder',
        files,
      };
      attachmentService.uploadMultiple(data).then(res => {
        const attachmentId = [];
        const resArray = [Object.values(_.get(res, 'data', []))];
        resArray[0].map(arr => attachmentId.push(arr.result));
        StaffWorkorderAttachmentService.upload({
          attachmentId,
          destinationNote: entityId,
        })
          .then(() => {
            setSubmitting(false);
            addNotification({
              title: null,
              message: 'Nota salvata con successo',
              isError: false,
            });
            this.props.update();
            this.props.close();
          })
          .catch(err => {
            // this.setState({ isLoading: false, isUploading: false });
            addNotification({
              title: null,
              message: err.data.detail,
              isError: true,
            });
          });
      });
    }
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    const { isReadOnly = false } = this.props;

    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={initialValues(this.props.initialValues || {})}
            onSubmit={this.onSubmit}
            render={props => (
              <BaseForm
                {...props}
                isReadOnly={isReadOnly}
                noteTypes={this.props.noteTypes}
                noteReasons={this.props.noteReasons}
                onImagesChange={this.handleImagesChange}
              />
            )}
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.close}
            >
              <Icon name="remove" />
              {this.context.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          {!isReadOnly && (
            <div
              style={{
                display: 'inline-block',
                width: '50%',
                textAlign: 'right',
              }}
            >
              <Button
                type="button"
                icon
                labelPosition="left"
                color="green"
                onClick={this.submit}
              >
                <Icon name="checkmark" />
                {this.context.intl.formatMessage(appMessages.save)}
              </Button>
            </div>
          )}
        </ModalFakeActions>
      </Grid>
    );
  }
}

CustomerNote.contextTypes = {
  intl: PropTypes.object.isRequired,
};
