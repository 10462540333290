import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Accordion as SemanticAccordion,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { makeSelectStyles } from '../../../../../containers/App/selectors';
import messages from '../../messages';
import NoteForm from './noteForm';

/* TODO: ADD REQUESTS */
const NotesSection = props => {
  const { styles, intl, isOpen = true, setIsNotesOpen } = props;
  return (
    <SemanticAccordion fluid>
      <SemanticAccordion.Title
        active={isOpen}
        onClick={() => setIsNotesOpen(!isOpen)}
        style={{ position: 'relative' }}
      >
        <Header as="h4" style={{ marginBottom: 10 }}>
          <span
            style={{
              backgroundColor: 'white',
              position: 'absolute',
              zIndex: 2,
              paddingRight: 10,
            }}
          >
            <Icon style={{ color: styles.SECOND_COLOR }} name="dropdown" />
            {intl.formatMessage(messages.notePart)}
          </span>
          <hr
            style={{
              position: 'absolute',
              top: 5,
              width: '100%',
              zIndex: 1,
              borderTop: `1px solid ${styles.SECOND_COLOR}`,
              //
            }}
          />
        </Header>
      </SemanticAccordion.Title>
      <SemanticAccordion.Content active={isOpen}>
        <Segment basic>
          <NoteForm {...props} />
        </Segment>
      </SemanticAccordion.Content>
    </SemanticAccordion>
  );
};

NotesSection.propTypees = {
  isOpen: PropTypes.bool.isRequired,
  setIsNotesOpen: PropTypes.func.isRequired,
  styles: PropTypes.shape({}),
  intl: intlShape,
};

const mapStateToProps = createStructuredSelector({
  styles: makeSelectStyles(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(NotesSection);
