import PropTypes from 'prop-types';
import React from 'react';
import { intlShape } from 'react-intl';

import SimpleFormikField from 'novigo-simple-formik-field';
import { PaddedColumn } from '../../Layout';
import DestinationService from '../../../shared/services/destination';
import UserService from '../../../shared/services/users';
import NoteService from '../../../shared/services/note';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import FormikToggle from '../../FormikToggle';
import messages from '../../Forms/Anagrafica/messages';
import AgentService from '../../../shared/services/agent';

const SharedSearchBar = ({ intl, setFieldValue, ...props }) => (
  <>
    <PaddedColumn width={4}>
      <FormikAsyncSelect
        {...props}
        name="noteType"
        asyncSelectProps={{
          isClearable: true,
        }}
        label={intl.formatMessage(messages.noteType)}
        loadOptions={(searchText, callback) =>
          NoteService.getAsyncNoteTypeOptions(searchText, callback)
        }
      />
    </PaddedColumn>
    <PaddedColumn width={4}>
      <FormikAsyncSelect
        {...props}
        name="noteReason"
        label={intl.formatMessage(messages.noteReason)}
        asyncSelectProps={{
          isClearable: true,
        }}
        loadOptions={(searchText, callback) =>
          NoteService.getAsyncNoteReasonOptions(searchText, callback)
        }
      />
    </PaddedColumn>
    <PaddedColumn width={4}>
      <SimpleFormikField
        {...props}
        name="shopSign"
        label={intl.formatMessage(messages.shopSign)}
      />
    </PaddedColumn>
    <PaddedColumn width={4}>
      <FormikAsyncSelect
        {...props}
        name="destination"
        label={intl.formatMessage(messages.destination)}
        asyncSelectProps={{
          isClearable: true,
        }}
        loadOptions={(searchText, callback) =>
          DestinationService.getOptions(searchText, callback, true)
        }
      />
    </PaddedColumn>
    <PaddedColumn width={2}>
      <FormikDate
        {...props}
        name="noteDate"
        label={intl.formatMessage(messages.noteDate)}
      />
    </PaddedColumn>
    <PaddedColumn width={2}>
      <FormikDate
        {...props}
        name="expireDate"
        label={intl.formatMessage(messages.expire_date)}
      />
    </PaddedColumn>
    <PaddedColumn width={4}>
      <FormikAsyncSelect
        {...props}
        name="assignee"
        label={intl.formatMessage(messages.assignee)}
        asyncSelectProps={{
          isClearable: true,
        }}
        loadOptions={UserService.getAsyncUserOptions}
      />
    </PaddedColumn>
    <PaddedColumn width={4}>
      <FormikAsyncSelect
        {...props}
        name="reporter"
        label={intl.formatMessage(messages.reporter)}
        asyncSelectProps={{
          isClearable: true,
        }}
        loadOptions={UserService.getAsyncUserOptions}
      />
    </PaddedColumn>
    <PaddedColumn width={4}>
      <FormikAsyncSelect
        {...props}
        name="agent"
        label={intl.formatMessage(messages.agent)}
        asyncSelectProps={{
          isClearable: true,
        }}
        loadOptions={AgentService.getOptions}
      />
    </PaddedColumn>
    <PaddedColumn width={1}>
      <FormikToggle
        {...props}
        name="executed"
        label={intl.formatMessage(messages.noteExecuted)}
      />
    </PaddedColumn>
    <PaddedColumn width={1}>
      <FormikToggle
        {...props}
        name="expireToday"
        label={intl.formatMessage(messages.expireToday)}
        onChange={(value, toggleData) => {
          setFieldValue('expireToday', toggleData.checked);
          if (toggleData.checked) {
            setFieldValue('expireWithinToday', false);
          }
        }}
      />
    </PaddedColumn>
    <PaddedColumn width={1}>
      <FormikToggle
        {...props}
        name="expireWithinToday"
        label={intl.formatMessage(messages.expireWithinToday)}
        onChange={(value, toggleData) => {
          setFieldValue('expireWithinToday', toggleData.checked);
          if (toggleData.checked) {
            setFieldValue('expireToday', false);
          }
        }}
      />
    </PaddedColumn>
  </>
);

SharedSearchBar.defaultProps = {
  isDestination: false,
};

SharedSearchBar.propTypes = {
  isDestination: PropTypes.bool,
};

SharedSearchBar.contextTypes = {
  intl: PropTypes.shape(intlShape),
};

export { SharedSearchBar };
