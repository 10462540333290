import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.ORDER_TYPOLOGY,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'code',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.order-type', []).map(orderTypology => ({
        value: orderTypology.id,
        label: `${orderTypology.code} - ${orderTypology.description}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const getTypes = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.ORDER_TYPOLOGY,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const OrderTypologyService = {
  getOptions,
  getTypes,
};

export default OrderTypologyService;
