import React, { Component } from 'react';
import { Pagination, Label, Button, Icon } from 'semantic-ui-react';
// import { Helmet } from 'react-helmet';
import Table from 'semantic-table-grid';
import PropTypes from 'prop-types';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import Modal from '../Modal';
import EmptyMessage from '../EmptyMessage';
import TicketDetail from './ticketDetail';
import { H2 } from '../Layout';

// import { AUTH_ACTION } from 'containers/Auth/constants';

export default class TicketList extends Component {
  static propTypes = {
    url: PropTypes.string,
    token: PropTypes.string,
    projectId: PropTypes.string,
    offset: PropTypes.string,
    limit: PropTypes.string,
    user: PropTypes.object, // eslint-disable-line
    styles: PropTypes.object,
    service: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      files: [],
      summary: null, // eslint-disable-line
      description: null, // eslint-disable-line
      id: null, // eslint-disable-line
      jiraKey: null, // eslint-disable-line
      orderField: '',
      orderColumn: 'created',
      orderDirection: 'DESC',
      offset: props.offset,
      pageCount: 0,
      elements: [],
      modalViewOpened: false,
      currentTicket: null,
    };
  }

  componentDidMount() {
    this.refresh();
  }

  onCloseView = () => this.setState({ modalViewOpened: false });

  getTicket = ticket => {
    const Basic = this.getToken();
    return axios({
      method: 'get',
      url: `${this.props.url}/issue/${ticket.key}/comment`,
      withCredentials: true,
      headers: {
        Authorization: Basic,
        'Content-Type': 'application/json',
      },
    });
  };

  getToken = () => {
    const { token } = this.props;
    const hash = btoa(token);
    const Basic = `Basic ${hash}`;
    return Basic;
  };

  refresh() {
    this.loadData({ text: '', status: '' })
      .then(res =>
        this.setState({
          elements: res.data.issues,
          pageCount: Math.ceil(res.data.total / res.data.maxResults),
        }),
      )
      .catch();
  }

  loadData = filterData => {
    const searchWithText = filterData.text !== '' ? ` AND ((summary~"${filterData.text}")OR(description~"${filterData.text}") OR (key="${filterData.text}"))` : ''; // eslint-disable-line
    const searchWithStatus = filterData.status !== '' ? ` AND (status="${filterData.status}")` : ''; // eslint-disable-line
    const searchCondition = searchWithStatus + searchWithText;
    const baseUrl = `search?jql=(username~${_.get(this.props, 'user.username')})AND(project=${this.props.projectId})${searchCondition}+order+by+${this.state.orderColumn}+${this.state.orderDirection}&startAt=${this.state.offset}&maxResults=${this.props.limit}&validateQuery=false&fields=summary,description,created,status`; // eslint-disable-line

    // const url = this.state.id ? `${this.props.url}/issue/${this.state.id}` : `${this.props.url}/issue`;
    // const Basic = this.getToken();
    return this.props.service.getAll({ route: baseUrl });

    /* return axios({
      method: 'get',
      url: baseUrl,
      headers: {
        Authorization: Basic,
        'Content-Type': 'application/json',
      },
    }); */
  };

  openDetail = ticket => {
    this.getTicket(ticket)
      .then()
      .catch();
  };

  columns = [
    {
      key: 'status',
      name: 'Stato',
      formatter: ({ data }) => (
        <div style={{ textAlign: 'center' }}>
          <Label color="teal" horizontal>
            {data.fields.status.name}
          </Label>
        </div>
      ),
      width: '10%',
    },
    {
      key: 'created',
      name: 'Data',
      formatter: ({ data }) => (
        <div>
          <div style={{ fontSize: 12 }}>
            {moment(new Date(data.fields.created)).format('DD/MM/YYYY')}
          </div>
          <div style={{ fontSize: 11, color: '#ccc' }}>
            {moment(new Date(data.fields.created)).format('HH:MM')}
          </div>
        </div>
      ),
      sortable: true,
      width: 100,
    },
    {
      key: 'code',
      name: 'Codice',
      formatter: ({ data }) => <b>{data.key}</b>,
      sortable: true,
      width: 80,
    },
    {
      key: 'title',
      name: 'Oggetto',
      formatter: ({ data }) => data.fields.summary,
      sortable: true,
      width: '30%',
    },
    {
      key: 'description',
      name: 'Descrizione',
      formatter: ({ data }) => data.fields.description,
      sortable: true,
    },
    {
      key: 'azioni',
      name: 'Azioni',
      formatter: ({ data }) => (
        <Button
          primary
          circular
          icon
          onClick={() =>
            this.setState({ currentTicket: data }, this.openModal())
          }
        >
          <Icon name="comment" />
        </Button>
      ),
      sortable: true,
      width: 10,
    },
  ];

  ToolBar = () => <div key="filter">Filtri</div>;

  openModal = () => this.setState({ modalViewOpened: true });

  render() {
    return (
      <div>
        <H2>Gestione ticket di assistenza</H2>
        <Table
          isLoading={this.state.isLoading}
          columns={this.columns}
          canSelect={{
            active: false,
            isSelectedProperty: 'isSelected',
            onSelect: this.onSelect,
            onDeselect: this.onDeselect,
            selectAll: true,
          }}
          elements={this.state.elements}
          hiddenHeaderIfEmpty
          emptyResults={
            <EmptyMessage>Nessun ticket da visualizzare</EmptyMessage>
          }
          canAction={{
            actions: [this.ToolBar()],
            active: true,
          }}
          canPaginate={{
            active: true,
            render: (
              <Pagination
                activePage={this.state.page || 0}
                boundaryRange={1}
                onPageChange={(e, { activePage }) =>
                  this.setState(
                    {
                      page: activePage,
                      offset: this.props.limit * (activePage - 1),
                    },
                    () => this.refresh(),
                  )
                }
                size="mini"
                siblingRange={1}
                totalPages={this.state.pageCount || 0}
                ellipsisItem="..."
              />
            ),
          }}
          canSort={{
            active: true,
            onSort: (field, dir) => {
              this.setState({ orderField: field, orderDirection: dir }, () =>
                this.loadData(this.state.page, this.state.lastSearch),
              );
            },
          }}
        />
        <Modal
          size="tiny"
          title={
            <h2>
              <Icon name="ticket" /> Ticket{' '}
              <span style={{ color: this.props.styles.SECOND_COLOR }}>
                {_.get(this.state, 'currentTicket.key')}
              </span>
            </h2>
          }
          open={this.state.modalViewOpened}
          onClose={this.onCloseView}
        >
          <TicketDetail
            service={this.props.service}
            ticket={this.state.currentTicket}
            getToken={this.getToken}
            url={this.props.url}
            styles={this.props.styles}
          />
        </Modal>
      </div>
    );
  }
}
