/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

// import { FormattedMessage } from 'react-intl';
import { Form } from 'semantic-ui-react';
import validation from './validation';
import FormActionsBar from '../../FormActionsBar';
import messages from '../Anagrafica/messages';
import { FormTab } from '../../Layout';
import { GeneralDataTab } from './tabs/generalDataTab';
import CommissionsTab from './tabs/commissionsTab';

export class AgentMask extends React.Component {
  state = {
    activeIndex: 0,
    allowedStates: [],
    allowedStatesLoading: true,
  };

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  render() {
    const {
      handleSubmit,
      readOnly = false,
      isSubmitting = false,
      isValid = false,
      onDelete = false,
      isDeleting = false,
      showDeleteButton = false,
      isNew,
      user,
    } = this.props;

    const panes = [
      {
        menuItem: this.context.intl.formatMessage(messages.tab_general_data),
        render: () => (
          <FormTab.Pane>
            <GeneralDataTab
              {...this.props}
              allowedStates={this.state.allowedStates}
              allowedStatesLoading={this.state.allowedStatesLoading}
              user={user}
            />
          </FormTab.Pane>
        ),
      },
      !isNew && {
        menuItem: this.context.intl.formatMessage(messages.tab_commissions),
        render: () => (
          <FormTab.Pane>
            <CommissionsTab {...this.props} />
          </FormTab.Pane>
        ),
      },
    ];

    return (
      <Form onSubmit={handleSubmit}>
        <FormTab
          panes={panes}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
          className="formTabs"
        />
        <FormActionsBar
          showDeleteButton={showDeleteButton}
          readOnly={readOnly}
          isValid={isValid}
          isDeleting={isDeleting}
          isSubmitting={isSubmitting}
          editRoute={this.props.editRoute}
          onDelete={onDelete}
          history={this.props.history}
        />
      </Form>
    );
  }
}

AgentMask.defaultProps = {
  errors: [],
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

AgentMask.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  user: PropTypes.object,
};

AgentMask.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default AgentMask;

export { validation };
