import { connect } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import appMessages from '../../../containers/App/messages';
import { getFilterData } from './functions';
import { SharedSearchBar } from './index';

const SEARCH_BAR_STATUS = {
  WAITING: 1,
  LOADED: 2,
};

const SearchBar = props => {
  const {
    // filters,
    initialValues,
    intl,
    formik,
    removedFilter,
  } = props;

  useEffect(
    () => {
      if (!isEmpty(initialValues)) {
        formik.setValues(initialValues);
        formik.setStatus(SEARCH_BAR_STATUS.WAITING);
      }
    },
    [initialValues],
  );

  /**
   * Formik v1 doesn't use async methods, so I had to run the submit form this way after the first useEffect.
   */
  useEffect(
    () => {
      if (formik.status === SEARCH_BAR_STATUS.WAITING) {
        formik.submitForm();
        formik.setStatus(SEARCH_BAR_STATUS.LOADED);
      }
    },
    [formik.status, formik.values],
  );

  useEffect(
    () => {
      if (removedFilter) {
        formik.setFieldValue(removedFilter, null);
      }
    },
    [removedFilter],
  );

  return <SharedSearchBar intl={intl} formik={{ ...formik }} />;
};

SearchBar.propTypes = {
  initialValues: PropTypes.object,
  formik: PropTypes.object,
  intl: PropTypes.object,
  removedFilter: PropTypes.string,
};

const ConnectedSearchBar = connect(SearchBar);

const ApplyFilter = props => {
  // eslint-disable-next-line no-unused-vars
  const { values, formActionsBag, applyFilterFunction, intl, onSearch } = props;

  const { filters, filterLabels } = getFilterData({
    values,
    intl,
  });

  onSearch(filters, values, filterLabels).then(() => {
    applyFilterFunction(filters, filterLabels, formActionsBag);
  });
};

const ProductSearchBar = ({
  intl,
  initialSearch,
  filters,
  initialValues,
  onSearch,
  removedFilter,
  onRemoveFilter,
  preSelectActive = false,
}) => ({
  formStructure: (
    <ConnectedSearchBar
      intl={intl}
      initialSearch={initialSearch}
      filters={filters}
      initialValues={initialValues}
      onRemoveFilter={onRemoveFilter}
      removedFilter={removedFilter}
      preSelectActive={preSelectActive}
    />
  ),
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  onApplyFilter: (values, formActionsBag, applyFilterFunction) => {
    ApplyFilter({
      values,
      formActionsBag,
      applyFilterFunction,
      intl,
      onSearch,
      preSelectActive,
    });
  },
});

ProductSearchBar.propTypes = {};

export default ProductSearchBar;
