import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import SimpleFormikField from 'novigo-simple-formik-field';
import { TextArea } from 'semantic-ui-react';
import FormikSelect from '../../../FormikSelect';
import FormikField from '../../../FormikField';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import { PaddedColumn } from '../../../Layout';
import messages from '../messages';
import CurrencyFormatter from '../../../CurrencyFormatter';

// Services
import destinationContactService from '../../../../shared/services/destinationcontacts';
import StaffWorkOrderTypeService from '../../../../shared/services/staffworkordertype';
import StaffWorkOrderStateService from '../../../../shared/services/staffworkorderstate';
import staffService from '../../../../shared/services/staff';

const WorkorderForm = props => {
  const { setFieldValue, values } = props;
  const [states, setStates] = useState([]);
  useEffect(
    () => {
      StaffWorkOrderStateService.getAvailableStates(
        values.state ? values.state.value : null,
      ).then(response => {
        const responseStates = response.map(item => ({
          label: item,
          value: item,
        }));
        setStates(_.union([values.state], responseStates));
      });
    },
    [values.state],
  );

  useEffect(() => {
    if (!values.customer.value && !values.destination.value) {
      setFieldValue('state', { value: 'Pianificato', label: 'Pianificato' });
    }
  }, []);

  return (
    <>
      <PaddedColumn width={8}>
        <CurrencyFormatter
          {...props}
          name="amount"
          label={props.intl.formatMessage(messages.amount)}
        />
      </PaddedColumn>
      <PaddedColumn width={8}>
        <FormikSelect
          {...props}
          name="state"
          required
          label={props.intl.formatMessage(messages.state)}
          options={states}
          defaultValue={values.state}
          value={values.state}
          onChange={target => {
            setFieldValue('state', {
              value: target.value,
              label: target.value,
            });
          }}
        />
      </PaddedColumn>
      <PaddedColumn width={8}>
        <SimpleFormikField
          {...props}
          name="number"
          label={props.intl.formatMessage(messages.number)}
          placeholder={props.intl.formatMessage(messages.number)}
        />
      </PaddedColumn>
      <PaddedColumn width={8}>
        <FormikAsyncSelect
          {...props}
          name="workorderType"
          required
          label={props.intl.formatMessage(messages.typeODL)}
          loadOptions={(searchText, callback) =>
            StaffWorkOrderTypeService.getOptions(searchText, callback)
          }
        />
      </PaddedColumn>
      <PaddedColumn width={8}>
        <FormikAsyncSelect
          {...props}
          name="staff"
          label={props.intl.formatMessage(messages.technical)}
          loadOptions={(searchText, callback) =>
            staffService.getOptions(searchText, callback)
          }
        />
      </PaddedColumn>
      <PaddedColumn width={8}>
        <FormikAsyncSelect
          {...props}
          key={`destination-${_.get(values, 'destination.value', null)}`}
          name="destinationContact"
          label={props.intl.formatMessage(messages.referent)}
          loadOptions={(searchText, callback) =>
            destinationContactService.getOwnerOptions(
              searchText,
              _.get(values, 'destination.value', null),
              callback,
            )
          }
          asyncSelectProps={{
            isDisabled: !_.get(values, 'destination.value', null),
          }}
        />
      </PaddedColumn>
      <PaddedColumn width={16}>
        <FormikField
          name="note"
          label={props.intl.formatMessage(messages.description)}
          {...props}
        >
          <TextArea
            onChange={(e, data) => props.setFieldValue('note', data.value)}
            value={_.get(props, 'values.note', '')}
            placeholder={props.intl.formatMessage(
              messages.description_placeholder,
            )}
          />
        </FormikField>
      </PaddedColumn>
    </>
  );
};

WorkorderForm.propsType = {
  intl: intlShape.isRequired,
};

export default injectIntl(WorkorderForm);
