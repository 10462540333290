/*
 * Customer note Messages
 *
 * This contains all the text for the Customer note container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.PriceListCustomer';

export default defineMessages({
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Valid from',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'Valid to',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  price_list_saved: {
    id: `${scope}.price_list_saved`,
    defaultMessage: 'Price list saved succesfully!',
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Code',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
});
