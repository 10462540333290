/*
 *
 * Global actions
 *
 */

import {
  SET_TOKEN,
  LOG_OUT,
  SET_ACL,
  LOAD_ACL,
  SET_ERROR,
  SET_NOTIFICATION,
} from './constants';

export function setToken(tokens) {
  return {
    type: SET_TOKEN,
    tokens,
  };
}

export function logout() {
  return {
    type: LOG_OUT,
  };
}

export function loadAcl() {
  return {
    type: LOAD_ACL,
  };
}

export function setAcl(data) {
  return {
    type: SET_ACL,
    data,
  };
}

export function setError(error) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function setNotification(data) {
  return {
    type: SET_NOTIFICATION,
    data,
  };
}
