/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import { RemoveBtn, EditBtn, ModalNewBtn } from '../../../Buttons';
import { WrapperTool } from '../../../Layout';
import Modal from '../../../Modal';
import ProductCommissionForm from '../../ProductCommission';
import { addNotification } from '../../../../utils/notification';
import currencyFormatter from '../../../../utils/currencyFormatter';
import percentageFormatter from '../../../../utils/percentageFormatter';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';

// import messages from './messages';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function CommissionsPart(props) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);
  const { commissionService, entity, entityName } = props;

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      props.values.id && loadData();
    },
    [props.values.id, paginationData.page],
  );

  const loadData = page => {
    setLoading(true);
    commissionService
      .getByOwner(props.values.id, page || paginationData.page)
      .then(res => {
        const data = _.get(res, 'data', { ...paginationDefault });
        setPaginationData({
          page: data.page,
          page_count: data.page_count,
          page_size: data.page_size,
          total_items: data.total_items,
        });
        setLoading(false);
        setItems(
          _.get(res, `data._embedded.${entityName || entity}-commission`),
          [],
        );
      });
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalOpened(false);
  };

  const openModal = () => {
    setSelectedItem({});
    setModalOpened(true);
  };

  const columns = () =>
    [
      {
        key: 'startDate',
        name: props.intl.formatMessage(messages.valid_from),
        formatter: ({ data }) =>
          _.get(data, 'startDate.date', null) &&
          moment(data.startDate.date).format('DD-MM-YYYY'),
      },
      {
        key: 'endDate',
        name: props.intl.formatMessage(messages.valid_to),
        formatter: ({ data }) =>
          _.get(data, 'endDate.date', null) &&
          moment(data.endDate.date).format('DD-MM-YYYY'),
      },

      props.entity === 'product' && {
        key: 'fromPercentage',
        name: props.intl.formatMessage(messages.fromPercentage),
        formatter: ({ data }) => {
          const value = _.get(data, 'fromPercentage', 0.0) / 100 || 0.0;
          return percentageFormatter.format(value);
        },
      },
      props.entity === 'product' && {
        key: 'toPercentage',
        name: props.intl.formatMessage(messages.toPercentage),
        formatter: ({ data }) => {
          const value = _.get(data, 'toPercentage', 0.0) / 100 || 0.0;
          return percentageFormatter.format(value);
        },
      },
      {
        key: 'flatUnit',
        name: props.intl.formatMessage(messages.flat_unit),
        formatter: ({ data }) => {
          const value = _.get(data, 'flatUnit', 0.0) / 100 || 0.0;
          return currencyFormatter.format(value);
        },
      },
      {
        key: 'subCategory',
        name: props.intl.formatMessage(messages.subCategory),
        formatter: ({ data }) => {
          const code = _.get(
            data,
            'subCategory.code',
            _.get(data, '_embedded.subCategory.code', null),
          );
          const desc = _.get(
            data,
            'productSubCategory.description',
            _.get(data, '_embedded.subCategory.description', ''),
          );

          if (code && desc) return `${code} - ${desc}`;
          if (desc) return desc;
          return '--';
        },
      },
      {
        key: 'commission',
        name: props.intl.formatMessage(messages.commissions),
        formatter: ({ data }) => {
          const value = _.get(data, 'commission', 0.0) / 100 || 0.0;
          return percentageFormatter.format(value);
        },
      },
      {
        key: 'refund',
        name: props.intl.formatMessage(messages.refund),
        formatter: ({ data }) => {
          const value = _.get(data, 'refund', 0.0) / 100 || 0.0;
          return percentageFormatter.format(value);
        },
      },
      props.entity === 'product' && {
        key: 'isIncludedInBonus',
        width: 100,
        name: props.intl.formatMessage(messages.isIncludedInBonus),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) =>
          props.intl.formatMessage(
            appMessages[data.includedInBonus ? 'yes' : 'no'],
          ),
      },

      !props.readOnly && {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 80 }}>
            <EditBtn
              action={() => {
                setSelectedItem(data);
                setModalOpened(true);
              }}
            />
            <RemoveBtn
              action={() => {
                commissionService
                  .remove(data.id)
                  .then(() => {
                    loadData();
                    addNotification({
                      title: null,
                      message: props.intl.formatMessage(
                        messages.note_removed_success,
                      ),
                      isError: false,
                    });
                  })
                  .catch(error =>
                    addNotification({
                      title: null,
                      message: error.data.detail,
                      isError: true,
                    }),
                  );
              }}
            />
          </div>
        ),
        width: '80px',
      },
    ].filter(o => o);

  return (
    <div>
      <Table
        elements={items}
        rowClassKey="role-row-class"
        columns={columns()}
        canSort={{
          active: true,
        }}
        isLoading={loading}
        canPaginate={{
          active: paginationData.total_items > 0,
          pageCount: paginationData.page_count,
          pageSize: paginationData.page_size,
          totalItems: paginationData.total_items,
          page: paginationData.page,
          onSelect: page => {
            setPaginationData({
              ...paginationData,
              page,
            });
          },
        }}
        canAction={{
          active: !props.readOnly,
          actions: [
            <WrapperTool key="wrapper">
              <Modal
                open={modalOpened}
                onClose={() => {
                  closeModal();
                }}
                title={
                  <h2>
                    <Icon name="sticky note" />{' '}
                    {props.intl.formatMessage(messages.new_commission)}
                  </h2>
                }
                trigger={
                  <ModalNewBtn onClick={openModal}>
                    {props.intl.formatMessage(messages.new_commission)}
                  </ModalNewBtn>
                }
                size="mini"
                style={{ width: 650 }}
                scrolling
              >
                <ProductCommissionForm
                  entityId={props.values.id}
                  entity={entity}
                  commissionService={props.commissionService}
                  close={closeModal}
                  initialValues={selectedItem}
                  update={() => loadData(1)}
                />
              </Modal>
            </WrapperTool>,
          ],
        }}
        hiddenHeaderIfEmpty
        emptyResults={
          <div>{props.intl.formatMessage(appMessages.no_results)}</div>
        }
      />
    </div>
  );
}

CommissionsPart.defaultProps = {
  values: {},
  readOnly: false,
};

CommissionsPart.propTypes = {
  values: PropTypes.object,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  commissionService: PropTypes.object,
  entity: PropTypes.string,
  entityName: PropTypes.string,
};

export default injectIntl(CommissionsPart);
