import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT,
    method: 'GET',
    params: {
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
        {
          type: 'field',
          field: 'productCode',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const searchByNameAndCategory = (searchText, id) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'title',
          value: `%${searchText}%`,
          where: 'or',
        },
        searchText && {
          type: 'like',
          field: 'productCode',
          value: `%${searchText}%`,
          where: 'or',
        },
        id && {
          type: 'neq',
          field: 'id',
          value: id,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const searchByNameAndCategoryKIT = (searchText, productKit) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'title',
          value: `%${searchText}%`,
          where: 'or',
        },
        searchText && {
          type: 'like',
          field: 'productCode',
          value: `%${searchText}%`,
          where: 'or',
        },
        productKit && {
          type: 'eq',
          field: 'kit',
          value: productKit,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback, productKit) => {
  searchByNameAndCategory(inputValue, productKit).then(results => {
    callback(
      _.get(results, 'data._embedded.product', []).map(product => ({
        value: product.id,
        label: `${product.productCode} - ${product.title}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const getOptionsKIT = (inputValue, callback, productKit) => {
  searchByNameAndCategoryKIT(inputValue, productKit).then(results => {
    callback(
      _.get(results, 'data._embedded.product', []).map(product => ({
        value: product.id,
        label: `${product.productCode} - ${product.title}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const getProductKit = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_KIT,
    method: 'GET',
  });

const getKitOptions = (text, callback) => {
  getProductKit().then(res => {
    callback(
      _.get(res, 'data._embedded.product-kit', []).map(el => ({
        label: el.description,
        value: el.id,
      })),
    );
  });
};

const getOptionsFilteredNeqId = (inputValue, id, callback) => {
  searchByNameAndCategory(inputValue, id).then(results => {
    callback(
      _.get(results, 'data._embedded.product', []).map(product => ({
        value: product.id,
        label: `${product.productCode} - ${product.title}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchForOrderDiscounts = (
  categoryId,
  subcategoryId,
  domainId,
  searchText,
) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'leftjoin',
          field: 'productDomains',
          alias: 'productDomains',
        },
        searchText && {
          type: 'like',
          field: 'title',
          value: `%${searchText}%`,
          where: 'or',
        },
        searchText && {
          type: 'like',
          field: 'productCode',
          value: `%${searchText}%`,
          where: 'or',
        },
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
          where: 'or',
        },
        categoryId && {
          type: 'eq',
          field: 'category',
          value: categoryId,
          where: 'and',
        },
        subcategoryId && {
          type: 'eq',
          field: 'subcategory',
          value: subcategoryId,
          where: 'and',
        },
        domainId && {
          type: 'eq',
          field: 'id',
          value: domainId,
          alias: 'productDomains',
          where: 'and',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptionsForOrderDiscounts = (
  categoryId,
  subcategoryId,
  domainId,
  inputValue,
  callback,
) => {
  searchForOrderDiscounts(categoryId, subcategoryId, domainId, inputValue).then(
    results => {
      callback(
        _.get(results, 'data._embedded.product', []).map(product => ({
          value: product.id,
          label: `${product.productCode} - ${product.title}`,
        })),
      );
    },
  );
  // eslint-disable-next-line no-useless-return
  return;
};

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT}/${id}`,
    method: 'DELETE',
  });

const getProductsFamily = (searchText, category, subcategory) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'leftjoin',
          field: 'subcategory',
          alias: 'productSubCategory',
        },
        searchText && {
          type: 'like',
          field: 'title',
          value: `%${searchText}%`,
          where: 'or',
        },
        searchText && {
          type: 'like',
          field: 'productCode',
          value: `%${searchText}%`,
          where: 'or',
        },
        category && {
          type: 'eq',
          field: 'category',
          value: category,
          where: !subcategory ? 'or' : 'and',
          alias: 'productSubCategory',
        },
        subcategory && {
          type: 'eq',
          field: 'subcategory',
          value: subcategory,
          where: !category ? 'or' : 'and',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptionsByFamily = (inputValue, callback, category, subcategory) => {
  getProductsFamily(inputValue, category, subcategory).then(results => {
    callback(
      _.get(results, 'data._embedded.product', []).map(product => ({
        value: product.id,
        label: `${product.productCode} - ${product.title}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchByAlreadyCreated = (searchText, isOffer, destination) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'title',
          value: `%${searchText}%`,
          where: 'or',
        },
        searchText && {
          type: 'like',
          field: 'productCode',
          value: `%${searchText}%`,
          where: 'or',
        },
        destination && {
          type: 'search_product',
          field: isOffer ? 'offered' : 'ordered',
          value: destination,
        },
        {
          type: 'eq',
          field: 'isActive',
          where: 'and',
          value: true,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAlreadyCreated = (isOffer, destination) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT,
    method: 'GET',
    params: {
      filter: [
        destination && {
          type: 'search_product',
          field: isOffer ? 'offered' : 'ordered',
          value: destination,
        },
        {
          type: 'eq',
          field: 'isActive',
          where: 'and',
          value: true,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAlreadyCreatedOptions = (
  callback,
  searchText,
  isOffer,
  destination,
) => {
  searchByAlreadyCreated(searchText, isOffer, destination).then(results => {
    callback(
      _.get(results, 'data._embedded.product', []).map(product => ({
        value: product.id,
        label: `${product.productCode} - ${product.title}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const getCrmProduct = (searchText, crm) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'title',
          value: `%${searchText}%`,
          where: 'or',
        },
        searchText && {
          type: 'like',
          field: 'productCode',
          value: `%${searchText}%`,
          where: 'or',
        },
        crm && {
          type: 'eq',
          field: 'crmCategory',
          where: 'and',
          value: crm,
        },
        {
          type: 'eq',
          field: 'isActive',
          where: 'and',
          value: true,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptionsByCrm = (inputValue, callback, crm) => {
  getCrmProduct(inputValue, crm).then(results => {
    callback(
      _.get(results, 'data._embedded.product', []).map(product => ({
        value: product.id,
        label: `${product.productCode} - ${product.title}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const getProductPriceMasterByIds = ids =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_MANAGER,
    method: 'POST',
    data: {
      method: 'get-price-list-master',
      params: {
        ids,
      },
    },
  });

const getProductsDetailsByIds = (destination, ids) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_MANAGER,
    method: 'POST',
    data: {
      method: 'get-details',
      params: {
        destination,
        ids,
      },
    },
  });

const ProductService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
  getKitOptions,
  getOptionsForOrderDiscounts,
  getOptionsFilteredNeqId,
  getOptionsKIT,
  getOptionsByFamily,
  getAlreadyCreatedOptions,
  getAlreadyCreated,
  getOptionsByCrm,
  getProductPriceMasterByIds,
  getProductsDetailsByIds,
};

export default ProductService;
