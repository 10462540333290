/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import _, { get } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import {
  Divider,
  Header,
  Label,
  Grid,
  Dimmer,
  Loader,
  Button,
  Form,
  List,
  Icon,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import OrderManageForm from '../../components/Forms/OrderForm/manageForm';
import { Page } from '../../components/Layout';
import PermissionManager from '../../components/Permission';
import OrderService from '../../shared/services/order';
import { addNotification } from '../../utils/notification';
import appMessages from '../App/messages';
import {
  OrderColumns,
  ORDER_STATE_ACCEPTED,
  ORDER_STATE_INSERTED,
  ORDER_STATE_NOT_ACCEPTED,
  ORDER_STATE_TO_BE_ACCEPTED,
  ORDER_STATE_ALL,
  OrderRowColumns,
} from './constants';
import messages from './messages';
import BaseSearch from '../../components/BaseSearch';
import { SharedSearchBar } from '../../components/SearchBars/Order';
import FilterBox from '../../components/FilterBox';
import { getFilterData } from '../../components/SearchBars/Order/functions';
import { FormBackBtn } from '../../components/Buttons';
import DeterchimicaTableGridCustom from '../../components/DeterchimicaTableGridCustom';
import SideBarOrder from './sidebar';
import ChangeCustomerModal from '../../components/Forms/OrderForm/manageForm/modals/changeCustomerModal';
import OrderRowService from '../../shared/services/orderrow';
import GoodsDiscountModal from '../../components/Forms/OrderForm/manageForm/modals/goodsDiscountModal';
import AddProductModal from '../../components/Forms/OrderForm/manageForm/modals/addProductModal';
import './css/order.css';
import FormikInput from '../../components/FormikInput';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import OrderTypologyService from '../../shared/services/ordertypology';
import {
  createOrder,
  deleteOrder,
  setOrderState,
  updateOrder,
} from './actions';
import ProductService from '../../shared/services/product';
import { usePrevious } from '../../shared/lib/common';
import NoteSection from '../../components/Forms/OfferForm/manageForm/formParts/NoteSection';
import DiscountTypeService from '../../shared/services/discountType';

const Order = props => {
  const { history, intl, stylesSetting } = props;
  const persistentFilters = [
    {
      type: 'leftjoin',
      field: 'destination',
      alias: 'destination',
    },
    {
      type: 'leftjoin',
      field: 'orderType',
      alias: 'orderType',
    },
    { type: 'eq', field: 'finalized', value: 1 },

    {
      type: 'groupby',
      field: 'id',
    },
  ];

  /** Forms and modals display */
  const [showOrderForm, setShowOrderForm] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showGoodsDiscountModal, setShowGoodsDiscountModal] = useState(false);
  const [showAddProductModal, setShowAddProductModal] = useState(false);

  /** Filters */
  const [sortFilter, setSortFilter] = useState({
    type: 'field',
    field: 'createdAt',
    direction: 'desc',
  });
  const [filters, setFilters] = useState([
    {
      type: 'eq',
      field: 'state',
      value: ORDER_STATE_TO_BE_ACCEPTED,
    },
  ]);
  const [initialValues, setInitialValues] = useState({
    state: {
      label: ORDER_STATE_TO_BE_ACCEPTED,
      value: ORDER_STATE_TO_BE_ACCEPTED,
    },
  });
  const [filterLabels, setFilterLabels] = useState([
    {
      filterField: 'state',
      name: 'State',
      value: ORDER_STATE_TO_BE_ACCEPTED,
    },
  ]);
  const [removedFilter, setRemovedFilter] = useState('');
  const [searchFilter, setSearchFilter] = useState(null);
  const [searchedValue, setSearchedValue] = useState('');
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
    pageSize: 5,
    totalItems: 0,
  });

  /** Selected order data */
  const [selectedOrder, setSelectedOrder] = useState(null);
  const previousSelectedOrder = usePrevious(selectedOrder);
  const [selectedOrderRows, setSelectedOrderRows] = useState([]);
  const [selectedOrderRowsUndo, setSelectedOrderRowsUndo] = useState([]);
  const [selectedOrderErrors, setSelectedOrderErrors] = useState([]);

  /** Loading flags */
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [isLoadingOrderRows, setIsLoadingOrderRows] = useState(false);
  const [isInsertingOrderRow, setIsInsertingOrderRow] = useState(false);
  const [isSavingOrderRows, setIsSavingOrderRows] = useState(false);
  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const [isDisapprovingOrder, setIsDisapprovingOrder] = useState(false);
  const [isApprovingOrder, setIsApprovingOrder] = useState(false);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);

  const [orders, setOrders] = useState([]);
  const [productOrderType, setProductOrderType] = useState('');
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isRowEditable, setIsRowEditable] = useState(false);

  /**
   * Handles component mount.
   */
  useEffect(() => {
    checkPermission();
    loadOrderTypeProduct();
  }, []);

  /**
   * Handles the change of current page.
   */
  useEffect(
    () => {
      loadOrderData();
    },
    [pagination.page],
  );

  /**
   * Handles the change of form visibility.
   */
  useEffect(
    () => {
      if (!showOrderForm) {
        loadOrderData();
      }
    },
    [showOrderForm],
  );

  /**
   * Handles the change of filters and sort.
   */
  useEffect(
    () => {
      // Reset pagination to page 1. This will also trigger a new data load.
      if (pagination.page !== 1) {
        setPagination({
          ...pagination,
          page: 1,
        });
      } else loadOrderData();
    },
    [filters, sortFilter],
  );

  /** Handles the change of orders list. */
  useEffect(
    () => {
      // Refresh the selected order if any
      if (selectedOrder) {
        const foundOrder = orders.find(o => o.id === selectedOrder.id);
        if (foundOrder) setSelectedOrder(transformData(foundOrder));
      }
    },
    [orders],
  );

  /**
   * Handles the change of url and url params.
   */
  useEffect(
    () => {
      checkPermission();
      // Reset order selection
      setSelectedOrder(null);

      if (
        props.match.params.action === 'view' ||
        props.match.params.action === 'new' ||
        props.match.params.action === 'modify' ||
        props.match.params.action === 'delete' ||
        props.match.params.action === 'manage' ||
        props.match.params.action === 'duplicate' ||
        props.match.params.id === 'new'
      ) {
        setShowOrderForm(true);
      } else {
        setShowOrderForm(false);
      }
    },
    [props.location.key, props.match.params.action, props.match.params.id],
  );

  /**
   * Handles the change of the selected order.
   */
  useEffect(
    () => {
      if (selectedOrder) {
        // Reset the editable mode if the order id has changed
        if (
          selectedOrder &&
          previousSelectedOrder &&
          selectedOrder.id !== previousSelectedOrder.id
        ) {
          setIsRowEditable(false);
        }
        // Set the compliant errors
        setSelectedOrderErrors([
          // Average discount error
          ...(selectedOrder.nonCompliantAverageDiscount
            ? [intl.formatMessage(messages.non_compliant_average_discount)]
            : []),
          // Product discount error
          ...(selectedOrder.nonCompliantProductDiscount > 0
            ? [
              `${intl.formatMessage(messages.non_compliant_product_discount, {
                value: selectedOrder.nonCompliantProductDiscount,
              })}`,
            ]
            : []),
          // Product markup error
          ...(selectedOrder.nonCompliantProductMarkup > 0
            ? [
              `${intl.formatMessage(messages.non_compliant_product_markup, {
                value: selectedOrder.nonCompliantProductMarkup,
              })}`,
            ]
            : []),
          // Insolvent error
          ...(selectedOrder.nonCompliantInsolvent
            ? [intl.formatMessage(messages.non_compliant_insolvent)]
            : []),
            // Net price edited
          ...(selectedOrder.netPriceEdited > 0
            ? [
              `${intl.formatMessage(messages.net_price_edited, {
                value: selectedOrder.netPriceEdited,
              })}`,
            ]
            : []),
        ]);
        let rows = [];
        setIsLoadingOrderRows(true);
        // Get the following information for the order rows:
        // - last offer price
        const ids = selectedOrder._embedded.rows.map(el => el.product.id);
        const destination = get(selectedOrder, '_embedded.destination.id', '');
        ProductService.getProductsDetailsByIds(destination, ids)
          .then(res => {
            const { details } = res.data;

            selectedOrder._embedded.rows.forEach(row => {
              const newRow = {
                ...row,
                product: {
                  label: `${get(row, 'product.productCode', null)} - ${get(
                    row,
                    'product.title',
                    null,
                  )}`,
                  value: get(row, 'product.id', null),
                  productCode: get(row, 'product.productCode', null),
                  title: get(row, 'product.title', null),
                },
              };
              // Assign a class to each order based on the its compliancy
              newRow.rowClassKey = row.notCompliant
                ? 'row-not-compliant'
                : 'row-compliant';
              // Assign the additional details
              const detail = details.find(d => d.id === row.product.id);
              newRow.isOrdered = detail ? detail.ordered : false;
              newRow.offerPrice = detail ? detail.offerPrice : 0;
              newRow.discountValue = parseFloat(newRow.discountValue).toFixed(
                2,
              );
              newRow.extraDiscount = parseFloat(newRow.extraDiscount).toFixed(
                2,
              );
              rows.push(newRow);
            });
            rows = rows.sort((a, b) => (a.rowNumber < b.rowNumber ? -1 : 1));
            rows = rows.map(row => {
              if (row.loan || row.goodsDiscount) {
                row.discountDisabled = true;
                row.discountExtraDisabled = true;
                row.netPriceDisabled = true;
              } else {
                row.discountDisabled = false;
                row.discountExtraDisabled = false;
                row.netPriceDisabled = false;
              }
              return row;
            });
            setSelectedOrderRows(rows);
            // We make a deepy copy in order to restore the original state
            setSelectedOrderRowsUndo(JSON.parse(JSON.stringify(rows)));
            setIsLoadingOrderRows(false);
          })
          .catch(err => {
            addNotification({
              title: this.context.intl.formatMessage(appMessages.error),
              message: _.get(
                err,
                'detail',
                this.context.intl.formatMessage(
                  appMessages.an_error_has_occurred,
                ),
              ),
              isError: true,
            });
            setIsLoadingOrderRows(false);
          });
      } else {
        setSelectedOrderErrors([]);
        setSelectedOrderRows([]);
        setSelectedOrderRowsUndo([]);
      }
    },
    [selectedOrder],
  );

  /**
   * Gets the order type 'Prodotti'.
   */
  const loadOrderTypeProduct = () => {
    OrderTypologyService.getTypes('Prodotti').then(({ data }) => {
      const type = _.get(data, '_embedded.order-type[0].id', null);
      setProductOrderType(type);
    });
  };

  /**
   * Gets the orders.
   */
  const loadOrderData = (resetSelectedOrder = true) => {
    // Reset selected order if needed
    if (resetSelectedOrder) setSelectedOrder(null);
    setIsLoadingOrder(true);
    // Get orders
    OrderService.getOrders(
      pagination.page,
      pagination.pageSize,
      filters.concat(persistentFilters),
      [sortFilter],
    )
      .then(result => {
        setIsLoadingOrder(false);
        setIsRowEditable(false);
        const resultOrders = result.data._embedded.order;

        // Assign a class to each order based on the its compliancy
        resultOrders.forEach((row, index) => {
          resultOrders[index].rowClassKey = row.nonCompliant
            ? 'row-not-compliant'
            : 'row-compliant';
        });

        // Update order and pagination
        setOrders(resultOrders);
        setPagination({
          ...pagination,
          totalItems: result.data.total_items,
          pageCount: result.data.page_count,
        });
      })
      .catch(error => {
        addNotification({
          title: intl.formatMessage(appMessages.error),
          message: get(
            error,
            'data.detail',
            intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
        setIsLoadingOrder(false);
      });
  };

  /**
   * Handles the selection of an order.
   * @param {*} data
   */
  const onSelectOrder = data => {
    setSelectedOrder(transformData(data));
  };

  /**
   * Handles the changes of a single order row data.
   * @param {*} value
   * @param {*} field
   * @param {*} id
   */
  const onChangeRowData = (value, field, id) => {
    const rows = [...selectedOrderRows];
    const row = rows.find(r => r.id === id);
    row[field] = value;

    setSelectedOrderRows(rows);
  };

  /**
   * Transform data after fetch request.
   * @param {*} data
   * @returns
   */
  const transformData = data => ({
    ...data,
    orderType: {
      label: `${_.get(data, 'orderType.code')} -
        ${_.get(data, 'orderType.description')}`,
      value: _.get(data, 'orderType.id'),
    },
  });

  /**
   * Checks for permissions.
   */
  const checkPermission = () => {
    PermissionManager.checkRESTPermission(
      'order',
      props.history,
      props.match,
      props.intl,
    );
  };

  /**
   * Manage the actions that can be executed on the order.
   * @param {} action
   */
  const onOrderAction = (action, data) => {
    const ruoterManager = get(props, 'history');
    switch (action) {
      case 'delete':
      case 'modify':
      case 'view':
        {
          const { id } = data;
          const routerPath = 'order';
          if (ruoterManager) {
            ruoterManager.push(`/${routerPath}/${id}/${action}`);
          }
        }
        break;
      case 'new':
        {
          const routerPath = 'order';
          if (ruoterManager) {
            ruoterManager.push(`/${routerPath}/${action}`);
          }
        }
        break;
      case 'approve':
        {
          const payload = {
            id: data.id,
            state: ORDER_STATE_ACCEPTED,
          };
          updateOrder(payload, true);
        }
        break;
      case 'unapprove':
        {
          const payload = {
            id: data.id,
            state: ORDER_STATE_NOT_ACCEPTED,
          };
          updateOrder(payload, true);
        }
        break;
      case 'changeCustomer':
        setShowCustomerModal(true);
        break;
      case 'sendAhr':
        {
          const payload = {
            delivery: data.delivery,
            id: data.id,
            orderType: data.orderType.value,
          };
          updateOrder(payload, false);
        }
        break;
      case 'verifyGoodsDiscount':
        setShowGoodsDiscountModal(true);
        break;
      default:
        break;
    }
  };

  /**
   * Manage the actions that can be executed on the order rows.
   * @param {} action
   */
  const onOrderRowAction = (action, data) => {
    switch (action) {
      case 'delete':
        removeOrderRow(data.id);
        break;
      default:
        break;
    }
  };

  /**
   * Removes the selected order row.
   * @param {*} id
   */
  const removeOrderRow = id => {
    OrderRowService.remove([{ id }])
      .then(res => {
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: _.get(
            res,
            'data.message',
            props.intl.formatMessage(messages.subject_removed_success),
          ),
          isError: false,
        });
        loadOrderData();
      })
      .catch(err =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      );
  };

  const handleDuplication = values => {
    setIsDuplicating(true);
    const duplicateData = {
      isDuplicate: true,
      orderType: _.get(values, 'orderType.id'),
      orderId: values.id,
      destination: _.get(values, '_embedded.destination.id', null),
      totalBuyPriceAmount: _.get(values, 'totalBuyPriceAmount', 0),
      totalGrossPriceAmount: _.get(values, 'totalGrossPriceAmount', 0),
      totalPriceAmount: _.get(values, 'totalPriceAmount', 0),
      delivery: _.get(values, 'delivery', null),
      internalNote: _.get(values, 'internalNote', null),
      deliveryNote: _.get(values, 'deliveryNote', null),
      finalized: 1,
      referenceDate:
        values.referenceDate !== null
          ? moment(values.referenceDate).format('YYYY-MM-DD')
          : null,
      destinationOrderAmountCommissionPercentage: _.get(
        values,
        'destinationOrderAmountCommissionPercentage',
        null,
      ),
      destinationOrderAmountRefundPercentage: _.get(
        values,
        'destinationOrderAmountRefundPercentage',
        null,
      ),
      destinationOrderAmountCommission: _.get(
        values,
        'destinationOrderAmountCommission',
        null,
      ),
      destinationOrderAmountRefund: _.get(
        values,
        'destinationOrderAmountRefund',
        null,
      ),
      amountForEndYearBonus: _.get(values, 'amountForEndYearBonus', null),
      totalRowsMaster: _.get(values, 'totalRowsMaster', null),
      totalRowsCustomer: _.get(values, 'totalRowsCustomer', null),
      totalRowsFlatUnit: _.get(values, 'totalRowsFlatUnit', null),
      totalRowsCommissionAmount: _.get(
        values,
        'totalRowsCommissionAmount',
        null,
      ),
      totalRowsRefundAmount: _.get(values, 'totalRowsRefundAmount', null),
      state: ORDER_STATE_INSERTED,
      avarageDiscount: _.get(values, 'avarageDiscount', null),
      avarageMarkup: _.get(values, 'avarageMarkup', null),
      nonCompliant: _.get(values, 'nonCompliant', null),
      nonCompliantAverageDiscount: _.get(
        values,
        'nonCompliantAverageDiscount',
        null,
      ),
      nonCompliantProductDiscount: _.get(
        values,
        'nonCompliantProductDiscount',
        null,
      ),
      nonCompliantProductMarkup: _.get(
        values,
        'nonCompliantProductMarkup',
        null,
      ),
      maxDiscount: _.get(values, 'maxDiscount', null),
    };
    OrderService.duplicate(duplicateData)
      .then(() => {
        loadOrderData();
      })
      .catch(err =>
        addNotification({
          title: intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      )
      .finally(() => {
        setIsDuplicating(false);
      });
  };

  const onRemoveFilter = val => {
    // Remove the filter label
    const tmpFilterLabels = filterLabels.filter(el => el.filterField !== val);
    // Remove the filter
    const tmpFilters = filters.filter(el => el.filterField !== val);
    // Reset the field value into initialValues, this is necessary to reset the searchbar formikbag
    const tmpInitialValues = initialValues;
    tmpInitialValues[val] = '';
    // If state filter was removed, set it to "ALL"
    if (val === 'state') {
      tmpInitialValues.state = {
        label: ORDER_STATE_ALL,
        value: null,
      };
      tmpFilterLabels.push({
        filterField: 'state',
        name: 'State',
        value: ORDER_STATE_ALL,
      });
    }

    // Update state
    setRemovedFilter(removedFilter ? '' : val);
    setFilterLabels(tmpFilterLabels);
    setFilters(tmpFilters);
    setInitialValues(tmpInitialValues);
  };

  /**
   * Refactored to correctly update the aliases so other states can be shown.
   * @param {*} values
   * @param {*} param1
   * @returns
   */
  const handleSearch = (values, { setSubmitting }) => {
    const {
      filterLabels: tmpFilterLabels,
      filters: tmpFilters,
    } = getFilterData({ values, intl });

    return new Promise(resolve => {
      setFilters(tmpFilters);
      setInitialValues(values);
      setFilterLabels(tmpFilterLabels);
      resolve(true);
      setSubmitting(false);
    });
    // return new Promise(resolve => {
    //   const resetAliases = persistentFilters.map(el => {
    //     if (el.field === 'state') {
    //       return {
    //         type: 'eq',
    //         field: 'state',
    //         value: ORDER_STATE_TO_BE_ACCEPTED,
    //       };
    //     }
    //     return el;
    //   });

    //   setAliases(resetAliases);
    //   setFilters([
    //     {
    //       type: 'eq',
    //       field: 'state',
    //       value: ORDER_STATE_TO_BE_ACCEPTED,
    //     },
    //   ]);
    //   setInitialValues({
    //     state: {
    //       value: ORDER_STATE_TO_BE_ACCEPTED,
    //       label: ORDER_STATE_TO_BE_ACCEPTED,
    //     },
    //   });
    //   setFilterLabels([
    //     {
    //       filterField: 'state',
    //       key: 'state',
    //       name: 'State',
    //       value: ORDER_STATE_TO_BE_ACCEPTED,
    //     },
    //   ]);
    //   setSubmitting(false);
    //   resolve(true);
    // });
  };

  /**
   * Handles the filter reset.
   */
  const onReset = () => {
    // Reset filters
    setFilters([
      {
        type: 'eq',
        field: 'state',
        value: ORDER_STATE_TO_BE_ACCEPTED,
      },
    ]);
    // Reset filter labels
    setFilterLabels([
      {
        filterField: 'state',
        key: 'state',
        name: 'State',
        value: ORDER_STATE_TO_BE_ACCEPTED,
      },
    ]);
    // Set all the initialValues to empty values
    const tmpInitialValues = initialValues;
    Object.keys(tmpInitialValues).forEach(key => {
      tmpInitialValues[key] = '';
    });
    // Reset state to "ALL"
    tmpInitialValues.state = {
      label: ORDER_STATE_TO_BE_ACCEPTED,
      value: ORDER_STATE_TO_BE_ACCEPTED,
    };
    setInitialValues(tmpInitialValues);
  };

  const onSaveOrder = values => {
    setIsLoadingOrder(true);

    const payload = {
      id: values.id,
      delivery: values.delivery,
      cig: values.cig,
      customerOrderNumber: values.customerOrderNumber,
      orderType: values.orderType ? values.orderType.value : null,
      internalNote: values.internalNote,
    };
    OrderService.patch(payload)
      .then(() => {
        setIsLoadingOrder(false);
        loadOrderData();
        addNotification({
          title: intl.formatMessage(appMessages.success),
          message: intl.formatMessage(messages.state_update_success),
          isError: false,
        });
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        setIsLoadingOrder(false);
        addNotification({
          title: intl.formatMessage(appMessages.error),
          message: get(
            error,
            'data.detail',
            intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  /**
   * Handles the save of all the order rows.
   */
  const onSaveOrderRows = async () => {
    // Set loading flag
    setIsSavingOrderRows(true);
    const rows = [];

    const discountType = await DiscountTypeService.searchByName('Percentage');

    // Build the payload for each row
    selectedOrderRows.forEach(row => {
      const rowPayload = {
        ...row,
        product: _.get(row, 'product.value', null),
        note: _.get(row, 'note', null),
        discountType: _.get(row, 'discountType.value', null),
        loan: _.get(row, 'loan') === true ? 1 : 0,
        goodsDiscount: _.get(row, 'goodsDiscount') === true ? 1 : 0,
        isSample: _.get(row, 'isSample') === true ? 1 : 0,
        discountValue:
          _.get(row, 'discountValue.value', null) ||
          _.get(row, 'discountValue', null),
        extraDiscount:
          _.get(row, 'extraDiscount.value', null) ||
          _.get(row, 'extraDiscount', null),
        markup: _.get(row, 'markup.value', null) || _.get(row, 'markup', null),
        flatValue:
          _.get(row, 'flatValue.value', null) || _.get(row, 'flatValue', null),
        netPrice:
          _.get(row, 'netPriceRow.value', null) || _.get(row, 'netPrice', null),
        netPriceEdited: _.get(row, 'netPriceEdited', null),
      };

      const transformedPayload = {
        id: rowPayload.id,
        product: rowPayload.product,
        competitorNote: null,
        discountType: discountType.data._embedded['discount-type'][0].id,
        amount: rowPayload.amount,
        quantity: rowPayload.quantity,
        buyPrice: rowPayload.buyPrice,
        competitorPrice: null,
        discountValue: rowPayload.discountValue,
        extraDiscount: rowPayload.extraDiscount,
        netPrice: rowPayload.netPrice,
        loan: rowPayload.loan,
        goodsDiscount: rowPayload.goodsDiscount,
        note: rowPayload.note,
        description: null,
        netPriceEdited: rowPayload.netPriceEdited,
      };

      rows.push(transformedPayload);
    });
    const payLoad = {
      orderRows: rows,
    };
    // Send the update command
    OrderRowService.updateRows(payLoad)
      .then(() => {
        // Reset loading flag
        setIsSavingOrderRows(false);
        // Reload the results
        loadOrderData(false);
        // Notify the result
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: props.intl.formatMessage(appMessages.success),
          isError: false,
        });
      })
      .catch(err => {
        // Reset loading flag
        setIsSavingOrderRows(false);
        // Notify the error
        addNotification({
          title: null,
          message: get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  /**
   * Handles the orders data sorting when clicked on the column header.
   */
  const onSortOrder = (field, direction) => {
    let sort = '';

    if (direction === 'none') {
      setSortFilter({
        type: 'field',
        field: 'createdAt',
        direction: 'desc',
      });
      return;
    }

    switch (field) {
      case 'code':
        sort = {
          type: 'field',
          field: 'code',
          alias: 'destination',
          direction,
        };
        break;
      case 'number':
        sort = {
          type: 'field',
          field: 'number',
          direction,
        };
        break;
      case 'businessName':
        sort = {
          type: 'field',
          field: 'businessName',
          alias: 'destination',
          direction,
        };
        break;
      case 'referenceDate':
        sort = {
          type: 'field',
          field: 'referenceDate',
          direction,
        };
        break;
      case 'totalPriceAmount':
        sort = {
          type: 'field',
          field: 'totalPriceAmount',
          direction,
        };
        break;
      case 'avarageDiscount':
        sort = {
          type: 'field',
          field: 'avarageDiscount',
          direction,
        };
        break;
      case 'maxDiscount':
        sort = {
          type: 'field',
          field: 'maxDiscount',
          direction,
        };
        break;
      default:
        sort = {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        };
        break;
    }
    setSortFilter(sort);
  };

  const canEditOrder = () =>
    PermissionManager.CanI('edit', 'order') &&
    selectedOrder &&
    selectedOrder.state !== ORDER_STATE_ACCEPTED &&
    !selectedOrder.imported;

  const handleOnArticleRowChange = (products, data) => {
    setIsLoadingOrderRows(true);
    OrderRowService.remove([{ id: data.id }]).then(removeResponse => {
      const payload = {
        destination: selectedOrder._embedded.destination.id,
        order: selectedOrder.id, // NOTE: != as on Offers
        products: [products],
        quantity: data.quantity,
        flatValue: data.flatValue,
        discountValue: data.discountValue,
        extraDiscount: data.extraDiscount,
        domain: _.get(selectedOrder, 'productDomain.value', null),
        frequency: _.get(selectedOrder, 'frequency.value', null),
        loan: data.loan,
        goodsDiscount: data.goodsDiscount,
      };

      OrderRowService.create(payload)
        .then(() => {
          setIsLoadingOrderRows(false);
          setIsLoadingOrder(true);
          OrderService.findOneById(selectedOrder.id).then(offer => {
            setSelectedOrder(transformData(offer.data));
            if (_.get(offer, 'data.state', null)) {
              // FIX: setFieldValue('state', _.get(offer, 'data.state', ''));
            }
            setIsLoadingOrder(false);
          });
        })
        .catch(err => {
          setIsLoadingOrderRows(false);
          addNotification({
            title: props.intl.formatMessage(appMessages.error),

            message: _.get(
              err,
              'data.detail',
              props.intl.formatMessage(appMessages.an_error_has_occurred),
            ),
            isError: true,
          });
        });
    });
  };

  return (
    <>
      {/* Right menu sidebar */}
      {!showOrderForm && (
        <SideBarOrder
          {...props}
          collapsed={false}
          items={
            <Formik
              enableReinitialize
              initialValues={selectedOrder}
              onSubmit={values => {
                const payload = {
                  delivery: values.delivery,
                  id: values.id,
                  orderType: values.orderType.value,
                };
                updateOrder(payload, intl, setIsUpdatingOrder);
              }}
            >
              {({ values, setFieldValue, handleSubmit }) => {
                const isOrderSelected = values && values.id;
                const canApprove =
                  isOrderSelected &&
                  PermissionManager.CanI('statusUpdateApproved', 'custom') &&
                  values.state !== ORDER_STATE_ACCEPTED &&
                  !values.imported;
                const canDisapprove =
                  isOrderSelected &&
                  PermissionManager.CanI('statusUpdateApproved', 'custom') &&
                  ![ORDER_STATE_NOT_ACCEPTED, ORDER_STATE_ACCEPTED].includes(
                    values.state,
                  ) &&
                  !values.imported;
                const canEdit =
                  PermissionManager.CanI('edit', 'order') &&
                  values &&
                  values.state !== ORDER_STATE_ACCEPTED &&
                  !values.imported;

                return (
                  <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
                    <Grid>
                      <Grid.Column>
                        <Grid.Row>
                          <Icon
                            name="window maximize"
                            style={{
                              fontSize: '18px',
                              marginRight: '10px',
                              color: '#2185d0',
                            }}
                          />
                          <span style={{ fontSize: '16px', fontWeight: '600' }}>
                            {intl.formatMessage(messages.actions)}
                          </span>
                        </Grid.Row>
                        {/* New order button */}
                        <Grid.Row>
                          <Button
                            color="blue"
                            content={intl.formatMessage(messages.new_order)}
                            icon="file"
                            labelPosition="left"
                            loading={isCreatingOrder}
                            onClick={() =>
                              createOrder(
                                history,
                                productOrderType,
                                intl,
                                setIsCreatingOrder,
                              )
                            }
                            type="button"
                          />
                        </Grid.Row>
                        {/* Buttons and fields related to the selected order */}
                        {isOrderSelected && (
                          <>
                            {/* Change customer button */}
                            {canEdit && (
                              <Grid.Row>
                                <Button
                                  basic
                                  color="blue"
                                  content={intl.formatMessage(
                                    messages.change_customer,
                                  )}
                                  icon="arrows alternate horizontal"
                                  onClick={() => setShowCustomerModal(true)}
                                  type="button"
                                />
                              </Grid.Row>
                            )}
                            {/* Verify goods discount button */}
                            <Grid.Row>
                              <Button
                                basic
                                color="blue"
                                content={intl.formatMessage(
                                  messages.verifyGoodsDiscount,
                                )}
                                icon="list"
                                onClick={() => setShowGoodsDiscountModal(true)}
                                type="button"
                              />
                            </Grid.Row>
                            {/* Approve button */}
                            {canApprove && (
                              <Grid.Row>
                                <Button
                                  basic
                                  color="green"
                                  content={intl.formatMessage(
                                    messages.action_approve,
                                  )}
                                  icon="checkmark"
                                  loading={isApprovingOrder}
                                  onClick={() =>
                                    setOrderState(
                                      selectedOrder.id,
                                      ORDER_STATE_ACCEPTED,
                                      intl,
                                      loadOrderData,
                                      setIsApprovingOrder,
                                    )
                                  }
                                  type="button"
                                />
                              </Grid.Row>
                            )}
                            {/* Disapprove button */}
                            {canDisapprove && (
                              <Grid.Row>
                                <Button
                                  basic
                                  color="orange"
                                  content={intl.formatMessage(
                                    messages.action_not_approve,
                                  )}
                                  icon="ban"
                                  loading={isDisapprovingOrder}
                                  onClick={() =>
                                    setOrderState(
                                      selectedOrder.id,
                                      ORDER_STATE_NOT_ACCEPTED,
                                      intl,
                                      loadOrderData,
                                      setIsDisapprovingOrder,
                                    )
                                  }
                                  type="button"
                                />
                              </Grid.Row>
                            )}
                            {/* Delete order button */}
                            {canEdit && (
                              <Grid.Row>
                                <Button
                                  basic
                                  color="red"
                                  content={intl.formatMessage(
                                    messages.deleteOrder,
                                  )}
                                  icon="trash"
                                  onClick={() =>
                                    deleteOrder(
                                      history,
                                      selectedOrder.id,
                                      loadOrderData,
                                    )
                                  }
                                  type="button"
                                />
                              </Grid.Row>
                            )}
                            <hr />
                            {/* Delivery */}
                            <Grid.Row>
                              <FormikInput
                                initialValue={
                                  values.delivery
                                    ? values.delivery
                                    : values._embedded.destination.delivery
                                }
                                label={intl.formatMessage(
                                  messages.deliveryDate,
                                )}
                                name="delivery"
                                onChange={e => {
                                  setFieldValue('delivery', e.target.value);
                                }}
                                readOnly={!canEdit}
                              />
                            </Grid.Row>
                            {/* Order type */}
                            <Grid.Row>
                              <FormikAsyncSelect
                                label={intl.formatMessage(messages.orderType)}
                                loadOptions={(searchText, callback) =>
                                  OrderTypologyService.getOptions(
                                    searchText,
                                    callback,
                                  )
                                }
                                name="orderType"
                                required
                                readOnly={!canEdit}
                                onChange={e => {
                                  setFieldValue('orderType', e);
                                }}
                              />
                            </Grid.Row>
                            {/* Send to AHR button */}
                            {canEdit && (
                              <Grid.Row>
                                <Button
                                  color="green"
                                  content={intl.formatMessage(
                                    messages.sendToAhr,
                                  )}
                                  icon="send"
                                  labelPosition="left"
                                  loading={isUpdatingOrder}
                                />
                              </Grid.Row>
                            )}
                          </>
                        )}
                        {/* List of errors on the order */}
                        {selectedOrderErrors.length > 0 ? (
                          <Grid.Row>
                            <span>
                              <FormattedMessage {...messages.errors} />
                            </span>
                            <div
                              style={{
                                border: '1px solid',
                                flex: 1,
                                borderRadius: '4px',
                                borderColor: '#ced3d9',
                                padding: '11px 12px',
                              }}
                            >
                              <List bulleted style={{ borderWidth: 'thin' }}>
                                {selectedOrderErrors.map(err => (
                                  <List.Item
                                    key={err}
                                    style={{
                                      color:
                                        err !==
                                          intl.formatMessage(
                                            messages.net_price_edited,
                                          )
                                          ? 'red'
                                          : 'orange',
                                    }}
                                  >
                                    {err}
                                  </List.Item>
                                ))}
                              </List>
                            </div>
                          </Grid.Row>
                        ) : null}
                      </Grid.Column>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          }
          openMenu
          stylesSetting={stylesSetting}
        />
      )}
      <Helmet>
        <title>Order</title>
        <meta name="description" content="Orders" />
      </Helmet>
      <Page
        style={{ marginRight: stylesSetting.SIDEBAR_WIDTH }}
        className="restyle"
      >
        <BreadcrumbsItem to="/order">
          {intl.formatMessage(messages.title)}
        </BreadcrumbsItem>
        {/* Order table */}
        {!showOrderForm && (
          <>
            <Header as="h2" dividing>
              <FormattedMessage {...messages.title} />
            </Header>
            <Grid>
              {isLoadingOrder && (
                <Dimmer active={isLoadingOrder}>
                  <Loader size="big" />
                </Dimmer>
              )}
              {/* Search bar */}
              <Grid.Row>
                <Grid.Column>
                  <BaseSearch
                    initialValues={initialValues}
                    onSearch={handleSearch}
                    onReset={onReset}
                    customSearchFilterNumber={filters.length || 0}
                    formStructure={formik => (
                      <SharedSearchBar intl={intl} {...formik} />
                    )}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Active filter labels */}
              {filterLabels.length > 0 && (
                <Grid.Row>
                  <Grid.Column>
                    <FilterBox
                      filterLabels={filterLabels}
                      removeFilter={onRemoveFilter}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              {/* Orders table */}
              <Grid.Row>
                <Grid.Column>
                  <DeterchimicaTableGridCustom
                    canSelect={{
                      active: true,
                      onSelect: onSelectOrder,
                    }}
                    canPaginate={{
                      active: true,
                      pageCount: pagination.pageCount,
                      pageSize: pagination.pageSize,
                      totalItems: pagination.totalItems,
                      page: pagination.page,
                      onSelect: pageNumber => {
                        setPagination({
                          ...pagination,
                          page: pageNumber,
                        });
                      },
                      pageMax: Math.min(10, pagination.pageCount),
                    }}
                    canSort={{
                      active: true,
                      onSort: onSortOrder,
                    }}
                    columns={OrderColumns(intl, onOrderAction)}
                    elements={orders}
                    rowClassKey="rowClassKey"
                    selectedElement={selectedOrder}
                  />
                </Grid.Column>
              </Grid.Row>
              {/* Order rows commands */}
              {canEditOrder() && (
                <>
                  <Grid.Row className="detail-header">
                    <div style={{ height: 'fit-content' }}>
                      <span style={{ fontSize: 'large', fontWeight: 'bold' }}>
                        {selectedOrder._embedded.destination.code}{' '}
                        {selectedOrder._embedded.destination.businessName}
                      </span>
                    </div>
                    <div style={{ display: 'flex' }}>
                      {/* Add product button */}
                      <Button
                        basic
                        color="blue"
                        content={intl.formatMessage(messages.add_product)}
                        icon="plus"
                        loading={isInsertingOrderRow}
                        onClick={() => setShowAddProductModal(true)}
                        style={{ maxHeight: '36px' }}
                        type="button"
                      />
                      {/* Edit button */}
                      <Button
                        basic
                        color="blue"
                        content={intl.formatMessage(
                          isRowEditable ? messages.cancel : messages.edit,
                        )}
                        icon={isRowEditable ? 'cancel' : 'edit'}
                        onClick={() => {
                          if (isRowEditable)
                            setSelectedOrderRows(selectedOrderRowsUndo);
                          setIsRowEditable(!isRowEditable);
                        }}
                        type="button"
                      />
                      {/* Save button */}
                      <Button
                        color="blue"
                        content={intl.formatMessage(messages.save)}
                        disabled={!isRowEditable}
                        icon="save"
                        loading={isSavingOrderRows}
                        onClick={() => onSaveOrderRows()}
                        type="button"
                      />
                    </div>
                  </Grid.Row>
                </>
              )}
              {/* Order row table */}
              {selectedOrder && (
                <Grid.Row>
                  {isLoadingOrderRows && (
                    <Dimmer active={isLoadingOrderRows}>
                      <Loader size="big" />
                    </Dimmer>
                  )}
                  <Grid.Column>
                    <Formik>
                      <Form>
                        <DeterchimicaTableGridCustom
                          canSelect={{ active: false }}
                          rowClassKey="rowClassKey"
                          elements={selectedOrderRows}
                          columns={OrderRowColumns(
                            intl,
                            onChangeRowData,
                            onOrderRowAction,
                            isRowEditable,
                            handleOnArticleRowChange,
                          )}
                          hiddenHeaderIfEmpty
                          emptyResults={
                            <div>
                              {props.intl.formatMessage(appMessages.no_results)}
                            </div>
                          }
                        />
                      </Form>
                    </Formik>
                  </Grid.Column>
                </Grid.Row>
              )}
              {/* Order rows total labels */}
              {selectedOrder && (
                <Grid.Row>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                      fontWeight: 'bold',
                    }}
                  >
                    <span style={{ marginRight: 10 }}>
                      {`${intl.formatMessage(messages.total_order)} € ${selectedOrder.totalPriceAmount
                          ? parseFloat(selectedOrder.totalPriceAmount).toFixed(
                            2,
                          )
                          : 0
                        } -
                      ${intl.formatMessage(messages.avarageDiscount)} % ${selectedOrder.avarageDiscount
                          ? parseFloat(selectedOrder.avarageDiscount).toFixed(2)
                          : 0
                        } -
                      ${intl.formatMessage(messages.avarageMarkup)} % ${selectedOrder.avarageMarkup
                          ? parseFloat(selectedOrder.avarageMarkup).toFixed(2)
                          : 0
                        }
 `}
                    </span>
                  </div>
                </Grid.Row>
              )}
              {selectedOrder && (
                <Grid.Row style={{ margin: 10 }}>
                  <Formik
                    enableReinitialize
                    initialValues={getInitialValuesForNotes(selectedOrder)}
                  >
                    {formikProps => (
                      <Form style={{ width: '100%' }}>
                        <NoteSection
                          {...props}
                          {...formikProps}
                          readOnly
                          isOrder
                          isOpen
                        />
                      </Form>
                    )}
                  </Formik>
                </Grid.Row>
              )}
            </Grid>

            {/* Customer change modal */}
            {selectedOrder && (
              <ChangeCustomerModal
                intl={intl}
                open={showCustomerModal}
                onClose={() => {
                  setShowCustomerModal(false);
                }}
                onUpdate={() => {
                  loadOrderData(false);
                }}
                order={selectedOrder}
              />
            )}

            {/* Add product modal */}
            {selectedOrder && (
              <AddProductModal
                intl={intl}
                open={showAddProductModal}
                onClose={() => {
                  setShowAddProductModal(false);
                }}
                onUpdate={() => {
                  loadOrderData(false);
                }}
                orderId={selectedOrder.id}
                destinationId={selectedOrder._embedded.destination.id}
              />
            )}

            {/* Verify goods discount modal */}
            {selectedOrder && (
              <GoodsDiscountModal
                destinationId={selectedOrder._embedded.destination.id}
                open={showGoodsDiscountModal}
                onClose={() => setShowGoodsDiscountModal(false)}
              />
            )}
          </>
        )}
        {/* Order form */}
        {showOrderForm && (
          <>
            <BreadcrumbsItem to="/order/manage">
              {intl.formatMessage(messages.manage)}
            </BreadcrumbsItem>
            <Header as="h2" style={{ marginBottom: '1px' }}>
              <FormattedMessage {...messages.manage_order} />
            </Header>
            <Label style={{ backgroundColor: 'unset', padding: 0 }}>
              <FormattedMessage {...messages.manage_order_articles} />
            </Label>
            <Divider />
            <FormBackBtn history={props.history} />
            <OrderManageForm {...props} />
          </>
        )}
      </Page>
    </>
  );
};

function getInitialValuesForNotes(selectedOrder) {
  return {
    number: selectedOrder.number != null ? selectedOrder.number : '',
    referenceDate:
      selectedOrder.referenceDate != null ? selectedOrder.referenceDate : '',
    expirationDate:
      selectedOrder.expirationDate != null
        ? selectedOrder.expirationDate
        : null,
    delivery: selectedOrder.delivery != null ? selectedOrder.delivery : '',
    finalized:
      selectedOrder.finalized != null ? selectedOrder.finalized : false,
    createdAt: selectedOrder.createdAt != null ? selectedOrder.createdAt : null,
    updatedAt: selectedOrder.updatedAt != null ? selectedOrder.updatedAt : null,
    maxDiscount:
      selectedOrder.maxDiscount != null ? selectedOrder.maxDiscount : 0,
    avarageDiscount:
      selectedOrder.avarageDiscount != null ? selectedOrder.avarageDiscount : 0,
    avarageMarkup:
      selectedOrder.avarageMarkup != null ? selectedOrder.avarageMarkup : 0,
    nonCompliant:
      selectedOrder.nonCompliant != null ? selectedOrder.nonCompliant : false,
    nonCompliantAverageDiscount:
      selectedOrder.nonCompliantAverageDiscount != null
        ? selectedOrder.nonCompliantAverageDiscount
        : false,
    nonCompliantInsolvent:
      selectedOrder.nonCompliantInsolvent != null
        ? selectedOrder.nonCompliantInsolvent
        : false,
    nonCompliantProductDiscount:
      selectedOrder.nonCompliantProductDiscount != null
        ? selectedOrder.nonCompliantProductDiscount
        : 0,
    nonCompliantProductMarkup:
      selectedOrder.nonCompliantProductMarkup != null
        ? selectedOrder.nonCompliantProductMarkup
        : 0,
    rowClassKey:
      selectedOrder.rowClassKey != null ? selectedOrder.rowClassKey : '',
    totalBuyPriceAmount:
      selectedOrder.totalBuyPriceAmount != null
        ? selectedOrder.totalBuyPriceAmount
        : 0,
    totalGrossPriceAmount:
      selectedOrder.totalGrossPriceAmount != null
        ? selectedOrder.totalGrossPriceAmount
        : 0,
    totalPriceAmount:
      selectedOrder.totalPriceAmount != null
        ? selectedOrder.totalPriceAmount
        : 0,
    state: selectedOrder.state != null ? selectedOrder.state : '',
    orderType:
      selectedOrder.orderType != null
        ? selectedOrder.orderType
        : { label: '', value: '' },
    amountForEndYearBonus:
      selectedOrder.amountForEndYearBonus != null
        ? selectedOrder.amountForEndYearBonus
        : 0,
    destinationOrderAmountCommission:
      selectedOrder.destinationOrderAmountCommission != null
        ? selectedOrder.destinationOrderAmountCommission
        : 0,
    destinationOrderAmountCommissionPercentage:
      selectedOrder.destinationOrderAmountCommissionPercentage != null
        ? selectedOrder.destinationOrderAmountCommissionPercentage
        : 0,
    destinationOrderAmountRefund:
      selectedOrder.destinationOrderAmountRefund != null
        ? selectedOrder.destinationOrderAmountRefund
        : 0,
    destinationOrderAmountRefundPercentage:
      selectedOrder.destinationOrderAmountRefundPercentage != null
        ? selectedOrder.destinationOrderAmountRefundPercentage
        : 0,
    description:
      selectedOrder.description != null ? selectedOrder.description : '',
    internalNote:
      selectedOrder.internalNote != null ? selectedOrder.internalNote : '',
    noteText: selectedOrder.noteText != null ? selectedOrder.noteText : '',
    totalRowsCommissionAmount:
      selectedOrder.totalRowsCommissionAmount != null
        ? selectedOrder.totalRowsCommissionAmount
        : 0,
    totalRowsCustomer:
      selectedOrder.totalRowsCustomer != null
        ? selectedOrder.totalRowsCustomer
        : 0,
    totalRowsFlatUnit:
      selectedOrder.totalRowsFlatUnit != null
        ? selectedOrder.totalRowsFlatUnit
        : 0,
    totalRowsMaster:
      selectedOrder.totalRowsMaster != null ? selectedOrder.totalRowsMaster : 0,
    totalRowsRefundAmount:
      selectedOrder.totalRowsRefundAmount != null
        ? selectedOrder.totalRowsRefundAmount
        : 0,
    receiptCosts:
      selectedOrder.receiptCosts != null ? selectedOrder.receiptCosts : 0,
    stampCosts: selectedOrder.stampCosts != null ? selectedOrder.stampCosts : 0,
    transportCosts:
      selectedOrder.transportCosts != null ? selectedOrder.transportCosts : 0,
    imported: selectedOrder.imported != null ? selectedOrder.imported : false,
    isActive: selectedOrder.isActive != null ? selectedOrder.isActive : true,
    // Add any other fields as required
  };
}

export default Order;
