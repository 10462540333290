import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import Select from 'react-select';
import FormikSelect from '../../../FormikSelect';
import FormikDate from '../../../FormikDate';
import FormikTelephone from '../../../FormikTelephone';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import { addNotification } from '../../../../utils/notification';

// Services
import customerService from '../../../../shared/services/customer';
import prospectService from '../../../../shared/services/prospect';
import DestinationAreaService from '../../../../shared/services/destinationarea';
import DestinationTypologyService from '../../../../shared/services/destinationtypology';
import InvoiceService from '../../../../shared/services/invoice';
import PermissionManager from '../../../Permission';
import ProfileService from '../../../../shared/services/profile';
import AddressFields from '../formParts/addressFieldsPart';
import { CanI } from '../../../../containers/Can/utils';
import DestinationGroupService from '../../../../shared/services/destinationgroup';
import DestinationRangeService from '../../../../shared/services/destinationrange';

function CustomerRegistryTab(props) {
  const { mode, readOnly } = props;

  const [areas, setAreas] = useState([]);
  const [areaLoading, setAreaLoading] = useState(true);
  const weekDays = [
    props.intl.formatMessage(appMessages.monday),
    props.intl.formatMessage(appMessages.tuesday),
    props.intl.formatMessage(appMessages.wednesday),
    props.intl.formatMessage(appMessages.thursday),
    props.intl.formatMessage(appMessages.friday),
    props.intl.formatMessage(appMessages.saturday),
    props.intl.formatMessage(appMessages.sunday),
  ];
  const weekDaysOption = weekDays.map(day => ({ label: day, value: day }));
  const { setValues, values, isNew } = props;
  /** Get whether the current entity is a prospect or not */
  const isProspect = props.mode === 'prospect';

  /**
   * Manages the mount of the component.
   */
  useEffect(() => {
    /** Get all the destination areas */
    DestinationAreaService.getAll()
      .then(res =>
        get(res, 'data._embedded.destination-area', []).map(area => ({
          value: area.id,
          label: `${area.code} - ${area.description}`,
          subAreas: area.subAreas,
        })),
      )
      .then(res => {
        setAreas(res);
        setAreaLoading(false);
      });
  }, []);

  /**
   * Manages the change of location.
   */
  useEffect(
    () => {
      if (
        props.history.location.customer &&
        props.history.location.customer.value
      ) {
        const method = props.history.location.isCustomer
          ? customerService
          : prospectService;

        method.read(props.history.location.customer.value).then(() => {
          setValues({
            ...props.values,
            customer: props.history.location.customer,
          });
        });
      }
    },
    [props.history.location.customer],
  );

  /**
   * Manages the change of entity id.
   */
  useEffect(
    () => {
      if (props.values.id == null) {
        return;
      }
      /** Get the last invoice and year turnover */
      InvoiceService.getLastInvoiceTurnover(
        props.values.id,
        new Date().getFullYear(),
      )
        .then(res => {
          const invoices = get(res, 'data._embedded.invoice', []);

          props.setFieldValue('turnoverYear', invoices[0].turnoverYear);
        })
        .catch(error =>
          addNotification({
            title: null,
            message: error.data.detail,
            isError: true,
          }),
        );
    },
    [props.values.id],
  );

  return (
    <div>
      <Grid style={{ paddingBottom: 15, marginTop: 20 }}>
        <Grid.Row columns={3}>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name={isProspect ? 'companyName' : 'customer.businessName'}
              required={!isNew}
              label={props.intl.formatMessage(messages.businessName)}
              value={
                isProspect
                  ? get(props.values, 'companyName', '')
                  : get(props.values, 'customer.businessName', '')
              }
              readOnly={readOnly || !isProspect}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="businessName"
              required
              label={props.intl.formatMessage(messages.shopSign)}
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <AddressFields
              {...props}
              isRequired
              readOnly={
                readOnly ||
                (mode !== 'prospect' &&
                  !PermissionManager.CanI('editDestinationAddress', 'custom'))
              }
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={4}>
          <Grid.Column width={4}>
            <FormikTelephone
              {...props}
              name="phoneNumber"
              label={props.intl.formatMessage(messages.phone_number)}
              required
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FormikTelephone
              {...props}
              name="mobileNumber"
              label={props.intl.formatMessage(messages.mobile_number)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="visit"
              label={props.intl.formatMessage(messages.visits)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="delivery"
              required={readOnly || !isProspect}
              label={props.intl.formatMessage(messages.deliveries)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={4}>
          <Grid.Column width={4}>
            <FormikSelect
              {...props}
              options={weekDaysOption}
              name="closingDays"
              isMulti
              value={props.values.closingDays || []}
              label={props.intl.formatMessage(messages.closing_days)}
              onChange={val => props.setFieldValue('closingDays', val)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="contactPerson"
              label={props.intl.formatMessage(messages.contact_person)}
              required
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FormikDate
              {...props}
              readOnly
              name="lastInvoiceDate"
              label={props.intl.formatMessage(messages.last_invoice)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              readOnly
              name="invoiceDetail"
              label={props.intl.formatMessage(messages.turnover_year)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="destinationArea"
              label={props.intl.formatMessage(messages.destination_area)}
            >
              {({
                form: {
                  setFieldValue,
                  values: { destinationSubArea },
                },
              }) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={values.destinationArea}
                  isLoading={areaLoading}
                  name="destinationArea"
                  options={areas}
                  onChange={value => {
                    if (
                      destinationSubArea &&
                      destinationSubArea.value !== value.value
                    ) {
                      setFieldValue('destinationSubArea', null);
                    }
                    setFieldValue('destinationArea', value);
                  }}
                />
              )}
            </SimpleFormikField>
          </Grid.Column>
          <Grid.Column width={4}>
            <FormikAsyncSelect
              {...props}
              name="destinationTypology"
              label={props.intl.formatMessage(messages.category)}
              loadOptions={(searchText, callback) =>
                DestinationTypologyService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FormikAsyncSelect
              {...props}
              required
              readOnly={
                readOnly ||
                (isProspect &&
                  !CanI('editDestinationProspectAgent', 'custom')) ||
                !isProspect
              }
              name="agent1"
              isClearable
              label={props.intl.formatMessage(messages.reference_agent)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAgentOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="email"
              label={props.intl.formatMessage(messages.email)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="homepage"
              label={props.intl.formatMessage(messages.web_site)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FormikTelephone
              {...props}
              name="fax"
              label={props.intl.formatMessage(messages.fax)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="employeesNumber"
              label={props.intl.formatMessage(messages.employees_number)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="roomsNumber"
              label={props.intl.formatMessage(messages.rooms_number)}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <SimpleFormikField
              {...props}
              name="seatsNumber"
              label={props.intl.formatMessage(messages.seats_number)}
            />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row columns={3}>
          <Grid.Column width={4}>
            <FormikAsyncSelect
              {...props}
              name="destinationGroup"
              isClearable
              label={props.intl.formatMessage(messages.destinationGroup)}
              loadOptions={DestinationGroupService.getOptions}
            />
          </Grid.Column>
          <Grid.Column width={4}>
            <FormikAsyncSelect
              {...props}
              name="destinationRange"
              isClearable
              label={props.intl.formatMessage(messages.destinationRange)}
              loadOptions={DestinationRangeService.getOptions}
            />
          </Grid.Column>
          <Grid.Column width={4} />
        </Grid.Row>
      </Grid>
    </div>
  );
}

CustomerRegistryTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CustomerRegistryTab);
