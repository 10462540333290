import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import { PaddedColumn } from '../../Layout';
import messages from '../Anagrafica/messages';

export const BaseForm = props => (
  <Form
    autoComplete="off"
    onSubmit={props.handleSubmit}
    loading={props.isSubmitting}
  >
    <Grid columns="equal">
      <Grid.Row>
        <PaddedColumn width={16}>
          <SimpleFormikField
            {...props}
            name="phoneNumber"
            label={props.intl.formatMessage(messages.phone_number)}
            required
          />
        </PaddedColumn>
      </Grid.Row>
      <Grid.Row>
        <PaddedColumn width={16}>
          <SimpleFormikField
            {...props}
            name="email"
            label={props.intl.formatMessage(messages.email)}
            required
          />
        </PaddedColumn>
      </Grid.Row>
      <Grid.Row>
        <PaddedColumn width={16}>
          <SimpleFormikField
            {...props}
            name="delivery"
            label={props.intl.formatMessage(messages.delivery)}
            required
          />
        </PaddedColumn>
      </Grid.Row>
    </Grid>
  </Form>
);

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
  isReadOnly: PropTypes.bool,
};

export default injectIntl(BaseForm);
