import { Field } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import appMessages from '../../../containers/App/messages';
import customerService from '../../../shared/services/customer';
import customerDestinationService from '../../../shared/services/customerdestination';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDateTime from '../../FormikDateTime';
import { ModalFakeActions, PaddedColumn } from '../../Layout';
import AppointmentForm from './formParts/appointmentForm';
import WorkorderForm from './formParts/workorderForm';
import messages from './messages';

export const BaseForm = props => {
  const { values } = props;
  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Field
          name="eventType"
          render={({ field }) => (
            <Form.Group widths="equal" style={{ paddingLeft: 5 }}>
              <Button.Group>
                <Button
                  type="button"
                  disabled={props.readOnly || values.id}
                  primary={field.value === 'appointment'}
                  checked={field.value === 'appointment'}
                  onClick={() =>
                    props.setFieldValue('eventType', 'appointment')
                  }
                >
                  <FormattedMessage {...messages.appointment} />
                </Button>
                <Button
                  type="button"
                  disabled={props.readOnly || values.id}
                  primary={field.value === 'workorder'}
                  checked={field.value === 'workorder'}
                  onClick={() => props.setFieldValue('eventType', 'workorder')}
                >
                  <FormattedMessage {...messages.workorder} />
                </Button>
              </Button.Group>
            </Form.Group>
          )}
        />
      </div>
      <Grid columns="equal">
        <Grid.Row>
          <PaddedColumn width={values.eventType === 'workorder' ? 5 : 8}>
            <FormikDateTime
              name="startDate"
              label={props.intl.formatMessage(messages.start_date)}
              required
            />
          </PaddedColumn>
          <PaddedColumn width={values.eventType === 'workorder' ? 5 : 8}>
            <FormikDateTime
              required
              name="endDate"
              label={props.intl.formatMessage(messages.end_date)}
            />
          </PaddedColumn>
          {values.eventType === 'workorder' && (
            <PaddedColumn width={5}>
              <FormikDateTime
                {...props}
                required
                name="plannedDate"
                label={props.intl.formatMessage(messages.planned)}
              />
            </PaddedColumn>
          )}
          <PaddedColumn width={values.eventType === 'workorder' ? 8 : 16}>
            <FormikAsyncSelect
              {...props}
              name="customer"
              required
              label={props.intl.formatMessage(messages.customer)}
              loadOptions={(searchText, callback) =>
                customerService.getOptions(searchText, callback)
              }
            />
          </PaddedColumn>
          <PaddedColumn width={values.eventType === 'workorder' ? 8 : 16}>
            <FormikAsyncSelect
              {...props}
              key={`customer-${_.get(values, 'customer.value', null)}`}
              name="destination"
              required
              label={props.intl.formatMessage(messages.destination)}
              loadOptions={(searchText, callback) =>
                customerDestinationService.getOwnerOptions(
                  searchText,
                  _.get(values, 'customer.value', null),
                  callback,
                )
              }
              asyncSelectProps={{
                isDisabled: !_.get(values, 'customer.value', null),
                isRequired: true,
              }}
            />
          </PaddedColumn>
          {values.eventType === 'appointment' && <AppointmentForm {...props} />}
          {values.eventType === 'workorder' && <WorkorderForm {...props} />}
        </Grid.Row>
        <Grid.Row>
          <ModalFakeActions>
            <div style={{ display: 'inline-block', width: '50%' }}>
              <Button
                type="button"
                icon
                labelPosition="left"
                onClick={props.onClose}
              >
                <Icon name="remove" />
                {props.intl.formatMessage(appMessages.cancel)}
              </Button>
            </div>
            <div
              style={{
                display: 'inline-block',
                width: '50%',
                textAlign: 'right',
              }}
            >
              <Button
                type="submit"
                icon
                labelPosition="left"
                color="green"
                disabled={!props.isValid}
                // onClick={this.submit}
              >
                <Icon name="checkmark" />
                {props.intl.formatMessage(appMessages.save)}
              </Button>
            </div>
          </ModalFakeActions>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BaseForm);
