import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Offer';
export const scope2 = 'app.containers.Order';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Offers',
  },
  new_offer: {
    id: `${scope}.new_offer`,
    defaultMessage: 'New Offer',
  },
  add_product: {
    id: `${scope}.add_product`,
    defaultMessage: 'Add product',
  },
  change_customer: {
    id: `${scope}.change_customer`,
    defaultMessage: 'Change customer',
  },
  verifyGoodsDiscount: {
    id: `${scope}.verifyGoodsDiscount`,
    defaultMessage: 'Verify SM',
  },
  deleteOffer: {
    id: `${scope}.deleteOffer`,
    defaultMessage: 'Delete offer',
  },
  manage_offer: {
    id: `${scope}.manage_offer`,
    defaultMessage: 'Manage Offer',
  },
  manage_offer_articles: {
    id: `${scope}.manage_offer_articles`,
    defaultMessage: 'Manage Offer Articles',
  },
  offer_number_year: {
    id: `${scope}.offer_number_year`,
    defaultMessage: 'Offer number and year',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  expiring_date: {
    id: `${scope}.expiring_date`,
    defaultMessage: 'Expiring Date',
  },
  offer_type: {
    id: `${scope}.offer_type`,
    defaultMessage: 'Offer Type',
  },
  offer_client: {
    id: `${scope}.offer_client`,
    defaultMessage: 'Client',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions',
  },
  modify: {
    id: `${scope}.modify`,
    defaultMessage: 'Modify',
  },
  edit_offer: {
    id: `${scope}.edit_offer`,
    defaultMessage: 'Edit offer',
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage',
  },
  export: {
    id: `${scope}.export`,
    defaultMessage: 'Export',
  },
  export_pdf: {
    id: `${scope}.export_pdf`,
    defaultMessage: 'Export pdf',
  },
  export_grouped_pdf: {
    id: `${scope}.export_grouped_pdf`,
    defaultMessage: 'Export pdf groups',
  },
  export_word: {
    id: `${scope}.export_word`,
    defaultMessage: 'Export word',
  },
  export_grouped_word: {
    id: `${scope}.export_grouped_word`,
    defaultMessage: 'Export word groups',
  },
  export_excel: {
    id: `${scope}.export_excel`,
    defaultMessage: 'Export excel',
  },
  expirationDate: {
    id: `${scope}.expirationDate`,
    defaultMessage: 'Expiration',
  },
  insert_date: {
    id: `${scope}.insert_date`,
    defaultMessage: 'Date',
  },
  order_type: {
    id: `${scope2}.order_type`,
    defaultMessage: 'Order Type',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  customer_prospect: {
    id: `${scope}.customer_prospect`,
    defaultMessage: 'Customer',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
  state_update_success: {
    id: `${scope}.state_update_success`,
    defaultMessage: 'Offer state update succesfully',
  },
  offer_update_success: {
    id: `${scope}.offer_update_success`,
    defaultMessage: 'Offer update succesfully',
  },
  non_compliant_average_discount: {
    id: `${scope}.non_compliant_average_discount`,
    defaultMessage: 'The average discount is above the expected threshold',
  },
  non_compliant_product_discount: {
    id: `${scope}.non_compliant_product_discount`,
    defaultMessage: 'product discount above the expected threshold',
  },
  non_compliant_product_markup: {
    id: `${scope}.non_compliant_product_markup`,
    defaultMessage: 'product markup above the expected threshold',
  },
  non_compliant_insolvent: {
    id: `${scope}.non_compliant_insolvent`,
    defaultMessage: 'The customer has due payments',
  },
  attention: {
    id: `${scope}.attention`,
    defaultMessage: 'Attention',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  total_offer: {
    id: `${scope}.total_offer`,
    defaultMessage: 'Total:',
  },
  avarageDiscount: {
    id: `${scope}.avarageDiscount`,
    defaultMessage: 'Avarage Discount:',
  },
  avarageMarkup: {
    id: `${scope}.avarageMarkup`,
    defaultMessage: 'Avarage Markup:',
  },
  referenceDate: {
    id: `${scope}.referenceDate`,
    defaultMessage: 'Reference date',
  },
  totalPriceAmount: {
    id: `${scope}.totalPriceAmount`,
    defaultMessage: 'Total price amount',
  },
  averageDiscount: {
    id: `${scope}.averageDiscount`,
    defaultMessage: 'Average discount',
  },
  maxDiscount: {
    id: `${scope}.maxDiscount`,
    defaultMessage: 'Max discount',
  },
  productCode: {
    id: `${scope}.productCode`,
    defaultMessage: 'Product code',
  },
  productTitle: {
    id: `${scope}.productTitle`,
    defaultMessage: 'Product title',
  },
  productNote: {
    id: `${scope}.productNote`,
    defaultMessage: 'Product note',
  },
  productPriceMaster: {
    id: `${scope}.productPriceMaster`,
    defaultMessage: 'Product price master',
  },
  productPriceCustomer: {
    id: `${scope}.productPriceCustomer`,
    defaultMessage: 'Product price customer',
  },
  priceOffer: {
    id: `${scope}.priceOffer`,
    defaultMessage: 'Price offer',
  },
  netPrice: {
    id: `${scope}.netPrice`,
    defaultMessage: 'Net price',
  },
  markup: {
    id: `${scope}.markup`,
    defaultMessage: 'Markup',
  },
  qt: {
    id: `${scope}.qt`,
    defaultMessage: 'Qt.',
  },
  discountValue: {
    id: `${scope}.discountValue`,
    defaultMessage: 'Discount value',
  },
  extraDiscount: {
    id: `${scope}.extraDiscount`,
    defaultMessage: 'Extra Discount',
  },
  priceAmount: {
    id: `${scope}.priceAmount`,
    defaultMessage: 'Price amount',
  },
  goodsDiscount: {
    id: `${scope}.goodsDiscount`,
    defaultMessage: 'Goods discount',
  },
  loan: {
    id: `${scope}.loan`,
    defaultMessage: 'Loan',
  },
  approveOffer: {
    id: `${scope}.approveOffer`,
    defaultMessage: 'Approve offer',
  },
  errors: {
    id: `${scope}.errors`,
    defaultMessage: 'Errors',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  action_win: {
    id: `${scope}.action_win`,
    defaultMessage: 'Win',
  },
  action_lose: {
    id: `${scope}.action_lose`,
    defaultMessage: 'Lose',
  },
  action_print: {
    id: `${scope}.action_print`,
    defaultMessage: 'Print PDF',
  },
  action_print_group: {
    id: `${scope}.action_print_group`,
    defaultMessage: 'Print PDF group',
  },
  action_export_doc: {
    id: `${scope}.action_export_doc`,
    defaultMessage: 'Print Word',
  },
  action_export_doc_group: {
    id: `${scope}.action_export_doc_group`,
    defaultMessage: 'Print Word group',
  },
  action_export_excel: {
    id: `${scope}.action_export_excel`,
    defaultMessage: 'Print Excel',
  },
  action_not_approve: {
    id: `${scope}.action_not_approve`,
    defaultMessage: 'Not approve',
  },
  offerType: {
    id: `${scope}.offerType`,
    defaultMessage: 'Offer type',
  },
  duplicateOffer: {
    id: `${scope}.duplicateOffer`,
    defaultMessage: 'Duplicate offer',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Businessname',
  },
  destinationDelivery: {
    id: `${scope}.destinationDelivery`,
    defaultMessage: 'Delivery:',
  },
  offerNote: {
    id: `${scope}.offerNote`,
    defaultMessage: 'Note:',
  },
  offerDate: {
    id: `${scope}.offerDate`,
    defaultMessage: 'Data offerta',
  },
  competitorNote: {
    id: `${scope}.competitorNote`,
    defaultMessage: 'Competitor note',
  },
  buyPrice: {
    id: `${scope}.buyPrice`,
    defaultMessage: 'Buy price',
  },
  competitorPrice: {
    id: `${scope}.competitorPrice`,
    defaultMessage: 'Competitor price',
  },
  subject_removed_success: {
    id: `${scope}.subject_removed_success`,
    defaultMessage: 'Offer row removed!',
  },
  offer_number: {
    id: `${scope}.offer_number`,
    defaultMessage: 'Offer number',
  },
  typology: {
    id: `${scope}.typology`,
    defaultMessage: 'Typology',
  },
  duplicateSuccess: {
    id: `${scope}.duplicateSuccess`,
    defaultMessage: 'Duplication Success',
  },
  net_price_edited: {
    id: `${scope}.net_price_edited`,
    defaultMessage: 'Invalid price',
  },
});
