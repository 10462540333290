import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Button, Icon, Confirm } from 'semantic-ui-react';
import _ from 'lodash';
import SimpleFormikField from 'novigo-simple-formik-field';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import { addNotification } from '../../../../utils/notification';
import CribisManagerService from '../../../../shared/services/cribismanager';
import CribisCompaniesModal from './CribisCompaniesModal';

const vatButtonStyle = {
  position: 'absolute',
  right: 11,
  top: 24,
  borderRadius: 0,
};

function VatNumberField(props) {
  const { values, setFieldValue, name, required } = props;
  const [vatLoading, setVatLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [autocomplete, setAutocomplete] = useState(false);
  const [confirmContent, setConfirmContent] = useState();
  const [modalOpened, setModalOpened] = useState(false);
  const [companyItems, setCompanyItems] = useState([]);

  const handleCalculation = () => {
    if (!values.vatNumber || _.isEmpty(values.vatNumber)) {
      addNotification({
        title: props.intl.formatMessage(appMessages.error),
        message: props.intl.formatMessage(messages.vat_require_error),
        isError: true,
      });
      return;
    }

    if (!autocomplete) {
      setConfirmContent(props.intl.formatMessage(messages.confirm_vat_message));
    } else {
      setConfirmContent(
        props.intl.formatMessage(messages.confirm_vat_message_again),
      );
    }
    setConfirmOpen(true);
  };

  const handleConfirm = () => {
    setConfirmOpen(false);
    requestCompanyData();
  };

  const handleCancel = () => {
    setConfirmOpen(false);
  };

  const requestCompanyData = () => {
    setCompanyItems([]);
    getCompanyItems();
    setVatLoading(true);
  };

  const getCompanyItems = () => {
    const payload = {
      method: 'company',
      params: {
        VATCode: values.vatNumber,
      },
    };
    CribisManagerService.getCompanyData(payload)
      .then(res => {
        const companyItemsRes = _.get(res, 'CompanyList.CompanyItem');
        if (companyItemsRes !== undefined) {
          setCompanyItems(_.get(res, companyItemsRes, []));
          setModalOpened(true);
        } else {
          getReportData(res.crifNumber);
        }
      })
      .catch(error => {
        setVatLoading(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.detail',
            props.intl.formatMessage(messages.vat_response_error),
          ),
          isError: true,
        });
      });
  };

  const getReportData = crifNumber => {
    const requestData = {
      method: 'company-report',
      params: {
        crifNumber,
      },
    };
    CribisManagerService.getCompanyReport(requestData)
      .then(res => {
        setAutocomplete(true);
        setFieldValue(
          'vatNumber',
          _.isNull(res.vatCode) ? '' : _.get(res, 'vatCode'),
        );
        setFieldValue(
          'businessName',
          _.isNull(res.companyName) ? '' : _.get(res, 'companyName'),
        );
        setFieldValue(
          'businessFiscalCode',
          _.isNull(res.taxCode) ? '' : _.get(res, 'taxCode'),
        );
        setFieldValue(
          'phoneNumber',
          _.isNull(res.telephoneNumber) ? '' : _.get(res, 'telephoneNumber'),
        );

        setFieldValue(
          'businessAddressTitle',
          _.isNull(res.address) ? '' : _.get(res, 'address'),
        );
        setFieldValue(
          'businessCountry',
          _.isNull(res.state) ? '' : _.get(res, 'state'),
        );
        setFieldValue(
          'businessCity',
          _.isNull(res.city) ? '' : _.get(res, 'city'),
        );
        setFieldValue(
          'businessProvinceShort',
          _.isNull(res.provinceCode) ? '' : _.get(res, 'provinceCode'),
        );
        setFieldValue(
          'businessPostalCode',
          _.isNull(res.zipCode) ? '' : _.get(res, 'zipCode'),
        );
        setFieldValue(
          'businessStreet',
          _.isNull(res.address) ? '' : _.get(res, 'address'),
        );
        setFieldValue(
          'emailPec',
          _.isNull(res.pecEmail) ? '' : _.get(res, 'pecEmail'),
        );
        setFieldValue(
          'email',
          _.isNull(res.publicEmail) ? '' : _.get(res, 'publicEmail'),
        );
        setFieldValue(
          'homepage',
          _.isNull(res.webSite) ? '' : _.get(res, 'webSite'),
        );

        props.setFieldValue('businessLatitude', _.get(res, 'latitude', ''));
        props.setFieldValue('businessLongitude', _.get(res, 'longitude', ''));

        setFieldValue('businessAddressSelectionType', 'manual');
        setVatLoading(false);
      })
      .catch(error => {
        setVatLoading(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.detail',
            props.intl.formatMessage(messages.vat_response_error),
          ),
          isError: true,
        });
      });
  };

  return (
    <>
      <SimpleFormikField
        {...props}
        name={name}
        label={props.intl.formatMessage(messages.vat_number)}
        maxlength="12"
        required={required}
        style={{ textTransform: 'uppercase' }}
      />
      <Button
        type="button"
        icon
        style={vatButtonStyle}
        onClick={handleCalculation}
        disabled={props.readOnly}
        loading={vatLoading}
      >
        <Icon name="calculator" />
      </Button>
      <Confirm
        open={confirmOpen}
        content={confirmContent}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
      <CribisCompaniesModal
        companies={companyItems}
        open={modalOpened}
        onClose={() => setModalOpened(false)}
        onSave={selectedValue => {
          getReportData(selectedValue);
        }}
      />
    </>
  );
}

VatNumberField.defaultProps = {
  values: {},
  readOnly: false,
};

VatNumberField.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default injectIntl(VatNumberField);
