import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_STATE,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_STATE}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_STATE}/${id}`,
    method: 'DELETE',
  });

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_STATE,
    method: 'GET',
    params: {},
  });

const findByDescription = description =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_STATE,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'description',
          value: description,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getMatchingDescription = (inputValue, callback) => {
  findByDescription(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.destination-state', []).map(state => ({
        value: state.id,
        label: state.description,
      })),
    );
  });
};

const searchByDescription = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_STATE,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByDescription(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.destination-state', []).map(state => ({
        value: state.id,
        label: state.description,
      })),
    );
  });
};

const DestinationStateService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
  getMatchingDescription,
};

export default DestinationStateService;
