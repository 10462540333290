import { defineMessages } from 'react-intl';

export const scope = 'novigo.entity.grid.customDropdown';

export default defineMessages({
  labelDropdown: {
    id: `${scope}.labelDropdown`,
    defaultMessage: 'Records per page',
  },
});
