import { defineMessages } from 'react-intl';

export const scope = 'app.components.FormikMonthSelect';

export default defineMessages({
  january: {
    id: `${scope}.january`,
    defaultMessage: 'January',
  },
  1: {
    id: `${scope}.1`,
    defaultMessage: 'January',
  },
  february: {
    id: `${scope}.february`,
    defaultMessage: 'February',
  },
  2: {
    id: `${scope}.2`,
    defaultMessage: 'February',
  },
  march: {
    id: `${scope}.march`,
    defaultMessage: 'March',
  },
  3: {
    id: `${scope}.3`,
    defaultMessage: 'March',
  },
  april: {
    id: `${scope}.april`,
    defaultMessage: 'April',
  },
  4: {
    id: `${scope}.4`,
    defaultMessage: 'April',
  },
  may: {
    id: `${scope}.may`,
    defaultMessage: 'May',
  },
  5: {
    id: `${scope}.5`,
    defaultMessage: 'May',
  },
  june: {
    id: `${scope}.june`,
    defaultMessage: 'June',
  },
  6: {
    id: `${scope}.6`,
    defaultMessage: 'June',
  },
  july: {
    id: `${scope}.july`,
    defaultMessage: 'July',
  },
  7: {
    id: `${scope}.7`,
    defaultMessage: 'July',
  },
  august: {
    id: `${scope}.august`,
    defaultMessage: 'August',
  },
  8: {
    id: `${scope}.8`,
    defaultMessage: 'August',
  },
  september: {
    id: `${scope}.september`,
    defaultMessage: 'September',
  },
  9: {
    id: `${scope}.9`,
    defaultMessage: 'September',
  },
  october: {
    id: `${scope}.october`,
    defaultMessage: 'October',
  },
  10: {
    id: `${scope}.10`,
    defaultMessage: 'October',
  },
  november: {
    id: `${scope}.november`,
    defaultMessage: 'November',
  },
  11: {
    id: `${scope}.11`,
    defaultMessage: 'November',
  },
  december: {
    id: `${scope}.december`,
    defaultMessage: 'December',
  },
  12: {
    id: `${scope}.12`,
    defaultMessage: 'December',
  },
});
