import React from 'react';
import { DatePicker } from 'novigo-date-time-picker';
import PropTypes from 'prop-types';
import moment from 'moment';

const DatePickerYear = props => {
  const { readOnly, onChange, value, ...rest } = props;

  return (
    <DatePicker
      format="YYYY"
      disabled={readOnly}
      views={['year']}
      value={moment(value, 'YYYY')}
      onChange={date => onChange(moment(date).format('YYYY'))}
      {...rest}
    />
  );
};

DatePickerYear.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
};

export default DatePickerYear;
