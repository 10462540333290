/*
 * Customer note Messages
 *
 * This contains all the text for the Customer note container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.ProductCommission';

export default defineMessages({
  commission_saved: {
    id: `${scope}.commission_saved`,
    defaultMessage: 'Commission successfully saved',
  },
  start_date: {
    id: `${scope}.start_date`,
    defaultMessage: 'Valid from',
  },
  end_date: {
    id: `${scope}.end_date`,
    defaultMessage: 'Valid to',
  },
  commission: {
    id: `${scope}.commission`,
    defaultMessage: 'Commission',
  },
  flat_unit: {
    id: `${scope}.flat_unit`,
    defaultMessage: 'Flat unit',
  },
  refund: {
    id: `${scope}.refund`,
    defaultMessage: 'Refund',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  crmCategory: {
    id: `${scope}.crmCategory`,
    defaultMessage: 'Crm category',
  },
  subCategory: {
    id: `${scope}.subCategory`,
    defaultMessage: 'Subcategory',
  },
  fromPercentage: {
    id: `${scope}.fromPercentage`,
    defaultMessage: 'Percentage from',
  },
  toPercentage: {
    id: `${scope}.toPercentage`,
    defaultMessage: 'Percentage to',
  },
  isIncludedInBonus: {
    id: `${scope}.isIncludedInBonus`,
    defaultMessage: 'Include in bonus [calculate]',
  },
});
