import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import { PaddedColumn } from '../../Layout';
import FormikDate from '../../FormikDate';
import FormActionsBar from '../../FormActionsBar';
import messages from './messages';
import customerDestinationService from '../../../shared/services/customerdestination';
import CustomerService from '../../../shared/services/customer';
import validation from './validation';

function PriceListCustomerForm(props) {
  const { isNew } = props;

  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting}
    >
      <Grid columns="equal">
        <Grid.Row columns={1}>
          {!isNew && (
            <PaddedColumn>
              <SimpleFormikField
                {...props}
                readOnly
                name="code"
                label={props.intl.formatMessage(messages.code)}
              />
            </PaddedColumn>
          )}
        </Grid.Row>
        <Grid.Row columns={1}>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="description"
              required
              label={props.intl.formatMessage(messages.description)}
            />
          </PaddedColumn>
        </Grid.Row>
        <Grid.Row columns={2}>
          <PaddedColumn>
            <FormikAsyncSelect
              {...props}
              name="customer"
              required
              label={props.intl.formatMessage(messages.customer)}
              loadOptions={(searchText, callback) =>
                CustomerService.getOptions(searchText, callback)
              }
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikAsyncSelect
              {...props}
              key={`customer-${_.get(props.values, 'customer.value', null)}`}
              name="destination"
              required
              label={props.intl.formatMessage(messages.destination)}
              loadOptions={(searchText, callback) =>
                customerDestinationService.getOwnerOptions(
                  searchText,
                  _.get(props.values, 'customer.value', null),
                  callback,
                )
              }
              asyncSelectProps={{
                isDisabled: !_.get(props.values, 'customer.value', null),
              }}
            />
          </PaddedColumn>
        </Grid.Row>
        <Grid.Row columns={2}>
          <PaddedColumn>
            <FormikDate
              {...props}
              name="startDate"
              required
              label={props.intl.formatMessage(messages.startDate)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikDate
              {...props}
              name="endDate"
              required
              label={props.intl.formatMessage(messages.endDate)}
            />
          </PaddedColumn>
        </Grid.Row>
      </Grid>
      <FormActionsBar {...props} />
    </Form>
  );
}

PriceListCustomerForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
  isNew: PropTypes.bool.isRequired,
};

export default injectIntl(PriceListCustomerForm);

export { validation };
