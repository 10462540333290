import _ from 'lodash';

const getUserName = data => {
  const FirstName = _.get(
    data,
    'user.firstname',
    _.get(data, '_embedded.user.firstname', ''),
  );
  const LastName = _.get(
    data,
    'user.lastname',
    _.get(data, '_embedded.user.lastname', ''),
  );

  return `${FirstName} ${LastName}`;
};

export const transformData = data => {
  const registryType = {
    value: _.get(data, '_embedded.registryType.id'),
    label: _.get(data, '_embedded.registryType.type'),
  };

  const ratesFraming = {
    value: _.get(data, '_embedded.ratesFraming.id'),
    label: _.get(data, '_embedded.ratesFraming.name'),
  };

  const user = {
    value: _.get(data, 'user.id', _.get(data, '_embedded.user.id', '')),
    label: getUserName(data),
  };

  // const watcher = {
  //   value: _.get(data, '_embedded.watcher.id'),
  //   label: _.get(data, '_embedded.watcher.user.displayName'),
  // };

  return {
    ...data,
    user,
    registryType: _.get(data, '_embedded.registryType') ? registryType : '',
    ratesFraming: _.get(data, '_embedded.ratesFraming') ? ratesFraming : '',
    // watcher: _.get(data, '_embedded.watcher') ? watcher : '',
  };
};

export const onCleanData = data => {
  const cleanData = _.cloneDeep(data);

  _.unset(cleanData, 'id');
  _.unset(cleanData, '_links');
  _.unset(cleanData, '_embedded');
  _.unset(cleanData, 'displayName');
  _.unset(cleanData, 'createdAt');
  _.unset(cleanData, 'updatedAt');
  _.unset(cleanData, 'deletedAt');
  _.unset(cleanData, 'contacts');
  _.unset(cleanData, 'addresses');
  _.unset(cleanData, 'notes');
  _.unset(cleanData, 'attachments');
  _.unset(cleanData, 'code');
  _.unset(cleanData, 'isActive');
  _.unset(cleanData, 'readAt');
  _.unset(cleanData, 'writeAt');

  cleanData.user = _.get(cleanData, 'user.value', null);

  cleanData.registryType =
    _.get(cleanData, 'registryType.value', null) || cleanData.registryType;

  cleanData.ratesFraming =
    _.get(cleanData, 'ratesFraming.value', null) || cleanData.ratesFraming;

  cleanData.reportingRate =
    _.get(cleanData, 'reportingRate', null) || cleanData.reportingRate;

  // cleanData.watcher =
  //   _.get(cleanData, 'watcher.value', null) || cleanData.watcher;

  return cleanData;
};

export const handleValueFromBlur = (name, value, setFieldValue) => {
  if (!name || !value) {
    return;
  }

  let defaultName = name;

  if (name === 'cap') {
    defaultName = 'PostalCode';
  }

  if (name === 'address') {
    defaultName = 'street';
  }

  if (name === 'province') {
    defaultName = 'ProvinceShort';
  }

  const readyName = `residence${
    name !== 'cap' && name !== 'province'
      ? _.capitalize(defaultName)
      : defaultName
  }`;
  setFieldValue(readyName, value);
};
