import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Accordion, Icon, Button, Dimmer, Loader } from 'semantic-ui-react';
import FaqService from '../../shared/services/faq';

export default class qa extends Component {
  static propTypes = {
    changeMode: PropTypes.func,
    styles: PropTypes.object,
  };

  state = {
    activeIndex: null,
    grouped: {},
    loading: true,
  };

  componentDidMount() {
    FaqService.getAll()
      .then(res => {
        const grouped = _.groupBy(
          _.get(res.data, '_embedded.faq', []),
          faq => faq.category,
        );
        this.setState({ grouped, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const keys = Object.keys(this.state.grouped);

    return (
      <div style={{ paddingBottom: 80 }}>
        {this.state.loading ? (
          <Dimmer active inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
        ) : (
          keys.map(key => (
            <div>
              <h4
                style={{
                  marginBottom: 5,
                  marginTop: 11,
                  color: this.props.styles.SECOND_COLOR,
                }}
              >
                {key}
              </h4>
              <Accordion styled>
                {this.state.grouped[key].map(el => [
                  <Accordion.Title
                    style={{ color: this.props.styles.GREY }}
                    active={activeIndex === el.id}
                    index={el.id}
                    onClick={this.handleClick}
                  >
                    <Icon name="dropdown" />
                    {el.question}
                  </Accordion.Title>,
                  <Accordion.Content
                    active={activeIndex === el.id}
                    style={{
                      background: this.props.styles.SECOND_COLOR,
                      color: '#fff',
                      paddingTop: 14,
                    }}
                  >
                    <p>{el.answer}</p>
                  </Accordion.Content>,
                ])}
              </Accordion>
            </div>
          ))
        )}
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            padding: 11,
            background: '#fff',
            left: 0,
            right: 0,
            paddingTop: 0,
            paddingBottom: 19,
            borderTop: '1px solid #efefef',
          }}
        >
          <Button
            onClick={this.props.changeMode}
            style={{ marginTop: 22 }}
            fluid
            primary
          >
            <Icon name="bell" />
            Non ho trovato la risposta che cercavo
          </Button>
        </div>
      </div>
    );
  }
}
