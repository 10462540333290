import React from 'react';
import { Grid } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import propTypes from 'prop-types';
import _ from 'lodash';
import { intlShape } from 'react-intl';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import agentFormMessages from '../messages';
import AgentTypeService from '../../../../shared/services/agenttype';

const CustomSearchForm = props => {
  const { intl } = props;
  return (
    <>
      <Grid.Column>
        <SimpleFormikField
          name="code"
          label={intl.formatMessage(agentFormMessages.code)}
        />
      </Grid.Column>
      <Grid.Column>
        <SimpleFormikField
          name="businessName"
          label={intl.formatMessage(agentFormMessages.businessName)}
        />
      </Grid.Column>
      <Grid.Column>
        <SimpleFormikField
          name="firstName"
          label={intl.formatMessage(agentFormMessages.firstName)}
        />
      </Grid.Column>
      <Grid.Column>
        <SimpleFormikField
          name="lastName"
          label={intl.formatMessage(agentFormMessages.lastName)}
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="registryType"
          label={intl.formatMessage(agentFormMessages.agentType)}
          loadOptions={(searchText, callback) =>
            AgentTypeService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
    </>
  );
};

CustomSearchForm.propTypes = {
  intl: intlShape.isRequired,
};

// function to handle on apply filter

export const onApplyFilter = ({
  values,
  formActionsBag,
  applyFilterFunction,
  intl,
}) => {
  const filters = [];
  const filterLabels = [];

  if (values.code) {
    filters.push({
      type: 'like',
      field: 'code',
      value: `%${values.code}%`,
    });

    filterLabels.push({
      key: 'code',
      name: intl.formatMessage(agentFormMessages.code),
      value: values.code,
      filterField: 'code',
    });
  }

  if (values.businessName) {
    filters.push({
      type: 'like',
      field: 'businessName',
      value: `%${values.businessName}%`,
    });

    filterLabels.push({
      key: 'businessName',
      name: intl.formatMessage(agentFormMessages.businessName),
      value: values.businessName,
      filterField: 'businessName',
    });
  }

  if (values.firstName) {
    filters.push({
      type: 'like',
      field: 'firstName',
      value: `%${values.firstName}%`,
    });

    filterLabels.push({
      key: 'firstName',
      name: intl.formatMessage(agentFormMessages.firstName),
      value: values.firstName,
      filterField: 'firstName',
    });
  }

  if (values.lastName) {
    filters.push({
      type: 'like',
      field: 'lastName',
      value: `%${values.lastName}%`,
    });

    filterLabels.push({
      key: 'lastName',
      name: intl.formatMessage(agentFormMessages.lastName),
      value: values.lastName,
      filterField: 'lastName',
    });
  }

  if (values.registryType) {
    filters.push({
      type: 'eq',
      field: 'registryType',
      value: `${values.registryType.value}`,
    });

    filterLabels.push({
      key: 'registryType',
      name: intl.formatMessage(agentFormMessages.agentType),
      value: _.get(values, 'registryType.label', ''),
      filterField: 'registryType',
    });
  }

  applyFilterFunction(filters, filterLabels, formActionsBag);
};

onApplyFilter.propTypes = {
  values: propTypes.object.isRequired,
  formActionsBag: propTypes.object.isRequired,
  applyFilterFunction: propTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default CustomSearchForm;
