/*
 *
 * Commission constants
 *
 */

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Grid } from 'semantic-ui-react';
import messages from './messages';
import appMessages from '../App/messages';
import FormikDate from '../../components/FormikDate';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import currencyFormatter from '../../utils/currencyFormatter';

// Services
import customerService from '../../shared/services/customer';
import destinationService from '../../shared/services/destination';
import AgentService from '../../shared/services/agent';

export const DEFAULT_ACTION = 'app/Commission/DEFAULT_ACTION';

export const COMMISSION_RELATIONS = [
  {
    type: 'leftjoin',
    field: 'customer',
    alias: 'customer',
  },
  {
    type: 'leftjoin',
    field: 'destination',
    alias: 'destination',
  },
];

/**
 * Format entity with code and display name.
 */
const CodeNameFormatter = ({ code, displayName }) => {
  const textParts = [code, displayName].filter(t => t);

  return textParts ? textParts.join(' - ') : '--';
};

export const commissionColumns = intl => [
  {
    key: 'code',
    name: (
      <span>
        {intl.formatMessage(messages.referenceDate)}
        <br />
        {intl.formatMessage(messages.code)}
      </span>
    ),
    searchable: true,
    sortable: true,
    width: 30,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const code = _.get(data, 'code', null);
      const referenceDate = _.get(data, 'referenceDate.date', null)
        ? moment(data.referenceDate.date).format('MM/YYYY')
        : null;

      return (
        <span>
          {referenceDate}
          <br />
          {code}
        </span>
      );
    },
  },
  {
    key: 'referenceDate',
    name: intl.formatMessage(messages.referenceDate),
    width: 70,
    searchable: true,
    sortable: true,
    formatter: ({ data }) =>
      _.get(data, 'referenceDate.date', null)
        ? moment(data.referenceDate.date).format('MM/YYYY')
        : null,
  },
  {
    key: 'displayName',
    width: 200,
    name: (
      <span>
        {intl.formatMessage(messages.headquarter)}
        <br />
        {intl.formatMessage(messages.customer)}
        <br />
        {intl.formatMessage(messages.agent)}
      </span>
    ),
    useAlias: 'customer',
    formatter: ({ data }) => {
      const { customer, destination, agent } = data;
      return (
        <span>
          <CodeNameFormatter {...customer} />
          <br />
          <CodeNameFormatter {...destination} />
          <br />
          <CodeNameFormatter {...agent} />
        </span>
      );
    },
    searchable: true,
    sortable: true,
  },
  {
    key: 'editedTotalCommissionAmount',
    name: (
      <span>
        {intl.formatMessage(messages.totalCommissionAmount)}
        <br />
        {intl.formatMessage(messages.totalRefundAmount)}
      </span>
    ),
    width: 70,
    sortable: true,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const totalCommission = currencyFormatter.format(
        data.editedTotalCommissionAmount || 0,
      );

      const totalRefund = currencyFormatter.format(
        data.editedTotalRefundAmount || 0,
      );

      return (
        <span>
          {totalCommission}
          <br />
          {totalRefund}
        </span>
      );
    },
  },
  {
    key: 'totalYearBonusAmount',
    name: (
      <span>
        {intl.formatMessage(messages.totalYearInvoiceAmount)}
        <br />
        {intl.formatMessage(messages.totalYearBonusAmount)}
        <br />
        {intl.formatMessage(messages.yearToDate)}
      </span>
    ),
    sortable: true,
    width: 70,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const totalYearInvoiceAmount = currencyFormatter.format(
        data.totalYearInvoiceAmount || 0,
      );

      const totalYearBonusAmount = currencyFormatter.format(
        data.totalYearBonusAmount || 0,
      );

      return (
        <span>
          {totalYearInvoiceAmount}
          <br />
          {totalYearBonusAmount}
        </span>
      );
    },
  },
  {
    key: 'totalYearPlannedSaleAmount',
    name: (
      <span>
        {intl.formatMessage(messages.totalYearPlannedSaleAmount)}
        <br />
        {intl.formatMessage(messages.yearToDate)}
      </span>
    ),
    sortable: true,
    width: 70,
    formatter: ({ data }) =>
      currencyFormatter.format(data.totalYearPlannedSaleAmount || 0),
  },
  {
    key: 'editedTotalAmount',
    name: intl.formatMessage(messages.totalAmount),
    width: 70,
    sortable: true,
    formatter: ({ data }) =>
      currencyFormatter.format(data.editedTotalAmount || 0),
  },
  {
    key: 'growthBonusAmount',
    name: intl.formatMessage(messages.growthBonus),
    width: 120,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const growthBonusAmount = _.get(data, 'growthBonusAmount', 0) || 0;
      const growthBonusPercentage =
        _.get(data, 'growthBonusPercentage', 0) || 0;

      return `${growthBonusAmount} € (${growthBonusPercentage}%)`;
    },
    sortable: true,
  },
  {
    key: 'state',
    name: intl.formatMessage(messages.state),
    width: 70,
    sortable: true,
  },
];

export const commissionSearch = (intl, setFilters) => ({
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  formStructure: (
    <>
      <Grid.Column width={3}>
        <FormikDate
          name="referenceDateStart"
          label={intl.formatMessage(messages.referenceDateFrom)}
        />
        <FormikDate
          name="referenceDateEnd"
          label={intl.formatMessage(messages.referenceDateTo)}
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <FormikDate
          name="invoiceReferenceDateStart"
          label={intl.formatMessage(messages.invoiceDateFrom)}
        />
        <FormikDate
          name="invoiceReferenceDateEnd"
          label={intl.formatMessage(messages.invoiceDateTo)}
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <FormikAsyncSelect
          name="agent"
          label={intl.formatMessage(messages.agent)}
          loadOptions={(searchText, callback) =>
            AgentService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <FormikAsyncSelect
          name="customer"
          label={intl.formatMessage(messages.headquarter)}
          loadOptions={(searchText, callback) =>
            customerService.getOptions(searchText, callback, true)
          }
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <FormikAsyncSelect
          name="destination"
          label={intl.formatMessage(messages.customer)}
          loadOptions={(searchText, callback) =>
            destinationService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];

    if (values.referenceDateStart) {
      filterLabels.push({
        key: 'referenceDateStart',
        name: intl.formatMessage(messages.referenceDateFrom),
        value: moment(values.referenceDateStart).format('YYYY-MM-DD'),
        exportFilterValue: moment(values.referenceDateStart).format(
          'YYYY-MM-DD',
        ),
        filterField: 'referenceDate',
      });
      filters.push({
        type: 'gte',
        where: 'and',
        field: 'referenceDate',
        value: `${moment(values.referenceDateStart).format('YYYY-MM-DD')}`,
      });
    }

    if (values.referenceDateEnd) {
      filterLabels.push({
        key: 'referenceDateEnd',
        name: intl.formatMessage(messages.referenceDateTo),
        value: moment(values.referenceDateEnd).format('YYYY-MM-DD'),
        exportFilterValue: moment(values.referenceDateEnd).format('YYYY-MM-DD'),
        filterField: 'referenceDate',
      });
      filters.push({
        type: 'lte',
        where: 'and',
        field: 'referenceDate',
        value: `${moment(values.referenceDateEnd).format('YYYY-MM-DD')}`,
      });
    }

    if (values.agent) {
      filterLabels.push({
        key: 'agent',
        name: intl.formatMessage(messages.agent),
        value: values.agent.label,
        exportFilterValue: values.agent.value,
        filterField: 'agent',
      });
      filters.push({
        type: 'eq',
        field: 'agent',
        value: `${values.agent.value}`,
      });
    }

    if (values.invoiceReferenceDateStart) {
      filterLabels.push({
        key: 'invoiceReferenceDateStart',
        name: intl.formatMessage(messages.invoiceDateFrom),
        value: moment(values.invoiceReferenceDateStart).format('YYYY-MM-DD'),
        exportFilterValue: moment(values.invoiceReferenceDateStart).format(
          'YYYY-MM-DD',
        ),
        filterField: 'referenceDate',
      });
      filters.push({
        type: 'gte',
        where: 'and',
        field: 'referenceDate',
        value: `${moment(values.invoiceReferenceDateStart).format(
          'YYYY-MM-DD',
        )}`,
      });
    }

    if (values.invoiceReferenceDateEnd) {
      filterLabels.push({
        key: 'invoiceReferenceDateEnd',
        name: intl.formatMessage(messages.invoiceDateTo),
        value: moment(values.invoiceReferenceDateEnd).format('YYYY-MM-DD'),
        exportFilterValue: moment(values.invoiceReferenceDateEnd).format(
          'YYYY-MM-DD',
        ),
        filterField: 'referenceDate',
      });
      filters.push({
        type: 'lte',
        where: 'and',
        field: 'referenceDate',
        value: `${moment(values.orderReferenceDateEnd).format('YYYY-MM-DD')}`,
      });
    }

    if (values.customer) {
      filterLabels.push({
        key: 'customer',
        name: intl.formatMessage(messages.headquarter),
        value: values.customer.label,
        exportFilterValue: values.customer.value,
        filterField: 'customer',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        alias: 'customer',
        field: 'id',
        value: `${values.customer.value}`,
      });
    }

    if (values.destination) {
      filterLabels.push({
        key: 'destination',
        name: intl.formatMessage(messages.customer),
        value: values.destination.label,
        exportFilterValue: values.destination.value,
        filterField: 'destination',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        alias: 'destination',
        field: 'id',
        value: `${values.destination.value}`,
      });
    }
    setFilters(filterLabels, values);
    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
});
