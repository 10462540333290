import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the manageRolePermissions state domain
 */

const selectManageRolePermissionsDomain = state =>
  state.get('manageRolePermissions', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ManageRolePermissions
 */

const makeSelectManageRolePermissions = () =>
  createSelector(selectManageRolePermissionsDomain, substate =>
    substate.toJS(),
  );

export default makeSelectManageRolePermissions;
export { selectManageRolePermissionsDomain };
