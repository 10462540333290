import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import currencyFormatter from '../../../utils/currencyFormatter';

/**
 * Format the statistic value using increase/decrese information by the original amount.
 */
const CommissionTriangle = ({ amount, edited, onlyTriangle = false }) => {
  const diff = Math.sign(edited - amount);

  const statistic = currencyFormatter.format(edited);
  let icon = null;

  switch (diff) {
    case -1:
      icon = <Icon name="triangle down" color="red" />;
      break;

    case 1:
      icon = <Icon name="triangle up" color="green" />;
      break;

    default:
      break;
  }

  return (
    <>
      {icon}
      {!onlyTriangle && statistic}
    </>
  );
};

CommissionTriangle.propTypes = {
  amount: PropTypes.number.isRequired,
  edited: PropTypes.number.isRequired,
  onlyTriangle: PropTypes.bool,
};

export default CommissionTriangle;
