/**
 *
 * PriceListMaster
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import moment from 'moment';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import _ from 'lodash';
import ListManager from 'novigo-entity-grid';
import SimpleFormikField from 'novigo-simple-formik-field';
import { Grid, Divider, Header } from 'semantic-ui-react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import makeSelectPriceListMaster from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import appMessages from '../App/messages';
import FormikDate from '../../components/FormikDate';
import { ListWrapper, Page } from '../../components/Layout';
import PermissionManager from '../../components/Permission';

/*
 * Specific import
 */

import { API } from '../../global-constants';
import request from '../../shared/services/request';
import PriceListMasterForm, {
  validation,
} from '../../components/Forms/PriceListMaster';
import PriceListFormModal from '../../components/FormManagerModal';
import ProductPriceMaster from '../ProductPriceMaster';

export class PriceListMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      showModal: false,
      reloadIndex: 0,
    };
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'priceListMaster',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'modify' ||
      this.props.match.params.action === 'view'
    ) {
      this.setState({ showForm: true });
    } else if (
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'manage' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showModal: true });
      this.setState({ showForm: false });
    } else {
      this.setState({ showModal: false });
      this.setState({ showForm: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.checkPermission();
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.checkPermission();
    this.evaluteAction();
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
    this.props.history.push('/price-list-master');
  }

  reloadData = () => {
    this.closeModal();
    this.setState(prevState => ({
      reloadIndex: prevState.reloadIndex + 1,
    }));
  };

  transformData = data => ({
    ...data,
    startDate: data.startDate ? moment(data.startDate) : null,
    endDate: data.endDate ? moment(data.endDate) : null,
  });

  cleanData = data => {
    const cleanData = _.cloneDeep(data);

    _.unset(cleanData, 'id');
    _.unset(cleanData, '_links');
    _.unset(cleanData, '_embedded');
    _.unset(cleanData, 'createdAt');
    _.unset(cleanData, 'updatedAt');
    _.unset(cleanData, 'deletedAt');
    _.unset(cleanData, 'readAt');
    _.unset(cleanData, 'writeAt');
    _.unset(cleanData, 'isActive');
    _.unset(cleanData, 'createdBy');
    _.unset(cleanData, 'updatedBy');
    _.unset(cleanData, 'prices');

    cleanData.startDate =
      _.get(cleanData, 'startDate') &&
      moment(cleanData.startDate).format('YYYY-MM-DD');

    cleanData.endDate =
      _.get(cleanData, 'endDate') &&
      moment(cleanData.endDate).format('YYYY-MM-DD');

    return cleanData;
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.context.intl.formatMessage(messages.title)}</title>
          <meta
            name="description"
            content={this.context.intl.formatMessage(messages.title)}
          />
        </Helmet>
        <Page>
          <BreadcrumbsItem to="/price-list-master">
            {this.context.intl.formatMessage(messages.title)}
          </BreadcrumbsItem>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/price-list-master/manage">
                {this.context.intl.formatMessage(messages.manage)}
              </BreadcrumbsItem>
              <Header as="h2" style={{ marginBottom: '1px' }}>
                <FormattedMessage {...messages.manage_price_list} />
              </Header>
              <Divider />
              <ProductPriceMaster {...this.props} />
            </>
          ) : (
            <>
              <Header as="h2" dividing>
                <FormattedMessage {...messages.title} />
              </Header>
              {this.state.showModal && (
                <>
                  <BreadcrumbsItem to="/price-list-master/edit">
                    {actionToBreadcrumb({
                      action: this.props.match.params.action,
                      intl: this.context.intl,
                    })}
                  </BreadcrumbsItem>
                  <PriceListFormModal
                    {...this.props}
                    modalSize="tiny"
                    open={this.state.showModal}
                    onClose={() => this.closeModal()}
                    reloadData={this.reloadData}
                    modalProps={{ style: { width: 400 } }}
                    modalTitle={actionToBreadcrumb({
                      action: this.props.match.params.action,
                      intl: this.context.intl,
                    })}
                    formManagerProps={{
                      key: this.props.match.params.action,
                      router: this.props,
                      entityName: 'price-list-master',
                      permissions: this.props.user,
                      onCleanData: this.cleanData,
                      afterCreate: () => this.reloadData(),
                      client: pars =>
                        request(pars).then(res => this.transformData(res.data)),
                      validation: values =>
                        validation(values, this.context.intl),
                      mask: fmProps => (
                        <PriceListMasterForm
                          {...fmProps}
                          editRoute={`/price-list-master/${this.props.match.params.id
                          }/modify`}
                          history={this.props.history}
                        />
                      ),
                    }}
                  />
                </>
              )}
              <ListWrapper>
                <ListManager
                  entityName="price-list-master"
                  sessionKey="price-list-master"
                  basePath={API.BASE_URL}
                  permissions={this.props.user}
                  router={this.props}
                  client={pars => request(pars).then(res => res.data)}
                  defaultOrder={[
                    {
                      type: 'field',
                      field: 'createdAt',
                      direction: 'desc',
                    },
                  ]}
                  locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                  reloadIndex={this.state.reloadIndex}
                  canCreate={{
                    active: PermissionManager.CanI('create', 'priceListMaster'),
                    label: <FormattedMessage {...appMessages.action_new} />,
                  }}
                  canExport={{
                    exportLabelPrefix: this.context.intl.formatMessage(
                      appMessages.export_to,
                    ),
                  }}
                  columns={[
                    {
                      key: 'code',
                      name: this.context.intl.formatMessage(messages.code),
                      searchable: true,
                      sortable: true,
                    },
                    {
                      key: 'description',
                      name: this.context.intl.formatMessage(
                        messages.description,
                      ),
                      searchable: true,
                      sortable: true,
                    },
                    {
                      key: 'startDate',
                      name: this.context.intl.formatMessage(messages.startDate),
                      searchable: true,
                      sortable: true,
                      formatter: ({ data }) =>
                        _.get(data, 'startDate', null) &&
                        moment(data.startDate).format('DD/MM/YYYY'),
                      exportFormatter: ({ data }) =>
                        _.get(data, 'startDate', null) &&
                        moment(data.startDate).format('DD/MM/YYYY'),
                    },
                    {
                      key: 'endDate',
                      name: this.context.intl.formatMessage(messages.endDate),
                      searchable: true,
                      sortable: true,
                      formatter: ({ data }) =>
                        _.get(data, 'endDate', null) &&
                        moment(data.endDate).format('DD/MM/YYYY'),
                      exportFormatter: ({ data }) =>
                        _.get(data, 'endDate', null) &&
                        moment(data.endDate).format('DD/MM/YYYY'),
                    },
                  ]}
                  canSearchCustom={{
                    active: true,
                    title: this.context.intl.formatMessage(appMessages.search),
                    buttonLabel: this.context.intl.formatMessage(
                      appMessages.search,
                    ),
                    formStructure: (
                      <>
                        <Grid.Column>
                          <SimpleFormikField
                            name="code"
                            label={this.context.intl.formatMessage(
                              messages.code,
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <SimpleFormikField
                            name="description"
                            label={this.context.intl.formatMessage(
                              messages.description,
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <FormikDate
                            name="startDate"
                            label={this.context.intl.formatMessage(
                              messages.startDate,
                            )}
                          />
                        </Grid.Column>
                        <Grid.Column width={2}>
                          <FormikDate
                            name="endDate"
                            label={this.context.intl.formatMessage(
                              messages.endDate,
                            )}
                          />
                        </Grid.Column>
                      </>
                    ),
                    onApplyFilter: (
                      values,
                      formikActionsBag,
                      applyFilterFunction,
                    ) => {
                      const filters = [];
                      const filterLabels = [];

                      if (values.code) {
                        filterLabels.push({
                          key: 'code',
                          name: this.context.intl.formatMessage(messages.code),
                          value: values.code,
                          filterField: 'code',
                        });
                        filters.push({
                          type: 'like',
                          where: 'and',
                          field: 'code',
                          value: `%${values.code}%`,
                        });
                      }

                      if (values.description) {
                        filterLabels.push({
                          key: 'description',
                          name: this.context.intl.formatMessage(
                            messages.description,
                          ),
                          value: values.description,
                          filterField: 'description',
                        });
                        filters.push({
                          type: 'like',
                          where: 'and',
                          field: 'description',
                          value: `%${values.description}%`,
                        });
                      }

                      if (values.startDate) {
                        filterLabels.push({
                          key: 'startDate',
                          name: this.context.intl.formatMessage(
                            messages.startDate,
                          ),
                          value: moment(values.startDate).format('YYYY-MM-DD'),
                          filterField: 'startDate',
                        });
                        filters.push({
                          type: 'gte',
                          where: 'and',
                          field: 'startDate',
                          value: `${values.startDate.format(
                            'YYYY-MM-DD',
                          )} 00:00:00`,
                        });
                      }

                      if (values.endDate) {
                        filterLabels.push({
                          key: 'endDate',
                          name: this.context.intl.formatMessage(
                            messages.endDate,
                          ),
                          value: moment(values.endDate).format('YYYY-MM-DD'),
                          filterField: 'endDate',
                        });
                        filters.push({
                          type: 'lte',
                          where: 'and',
                          field: 'endDate',
                          value: `${values.endDate.format(
                            'YYYY-MM-DD',
                          )} 23:59:59`,
                        });
                      }

                      applyFilterFunction(
                        filters,
                        filterLabels,
                        formikActionsBag,
                      );
                    },
                  }}
                  defaultActions={{
                    visualize: PermissionManager.CanI(
                      'read',
                      'priceListMaster',
                    ),
                    modify: PermissionManager.CanI('edit', 'priceListMaster'),
                    delete: PermissionManager.CanI('remove', 'priceListMaster'),
                    grouped: false,
                    moreActions: [
                      PermissionManager.CanI('edit', 'productPriceMaster') && {
                        icon: 'th list',
                        label: this.context.intl.formatMessage(
                          appMessages.manage,
                        ),
                        key: 'manageRelatedData',
                        action: data => {
                          this.props.history.push(
                            `/price-list-master/${data.id}/manage`,
                          );
                        },
                      },
                    ].filter(o => o),
                  }}
                />
              </ListWrapper>
            </>
          )}
        </Page>
      </>
    );
  }
}

PriceListMaster.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  priceListMaster: makeSelectPriceListMaster(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'priceListMaster', reducer });
const withSaga = injectSaga({ key: 'priceListMaster', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(PriceListMaster);

PriceListMaster.contextTypes = {
  intl: PropTypes.object.isRequired,
};
