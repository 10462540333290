import { defineMessages } from 'react-intl';

export const scope = 'novigo.entity.grid.menuActions';

export default defineMessages({
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  modify: {
    id: `${scope}.modify`,
    defaultMessage: 'Modify',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },

  // Visualizza, Modifica, Cancella
});
