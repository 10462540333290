/**
 *
 * AddressPicker
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Form, Grid, Segment, Icon } from 'semantic-ui-react';

import Suggest from 'react-geosuggest';
import { GeoInput } from './GeoInput';
import SwitchMode from './SwitchMode';

import './style.css';

function getErrorMessages(errorMessages) {
  return errorMessages.map(error => (
    <span style={{ color: 'red', marginRight: 3 }}>
      <Icon disabled name="warning sign" />
      {error}
    </span>
  ));
}

/**
 * Componente per la gestione dell'indirizzo
 */
export class AddressPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: this.props.search || 'manual',
    };

    this.suggestRef = React.createRef();
  }

  /**
   * if the value of the change during the loading change it!
   */
  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search) {
      this.changeMode(this.props.search);
    }
  }

  changeMode = val =>
    this.setState({ search: val }, () => this.props.onSwitch(val));

  getFieldValue = (arr, label, attr) => {
    const field = arr.filter(item => item.types[0] === label)[0];
    return field && field[attr] ? field[attr] : '';
  };

  handleChange = value => {
    try {
      const components = value.gmaps.address_components;

      if (components) {
        // Get the city
        let city = this.getFieldValue(components, 'locality', 'long_name');
        // If city is missing, use the administrative area level 3 instead
        if (!city) {
          city = this.getFieldValue(
            components,
            'administrative_area_level_3',
            'long_name',
          );
        }
        const province = this.getFieldValue(
          components,
          'administrative_area_level_2',
          'short_name',
        );
        const streetNumber = this.getFieldValue(
          components,
          'street_number',
          'long_name',
        );
        const incompleteAddress = this.getFieldValue(
          components,
          'route',
          'long_name',
        );
        const cap = this.getFieldValue(components, 'postal_code', 'long_name');
        const country = this.getFieldValue(components, 'country', 'long_name');

        const title = value.gmaps.formatted_address; // return to server

        const address = `${incompleteAddress} ${streetNumber}`.trim(); // return to server
        const coords = [value.location.lat, value.location.lng];

        const { name } = value.gmaps;

        const place = {
          address,
          title,
          city,
          province,
          cap,
          country,
          coords,
          name,
        };

        this.props.onChange(this.props.name, place);
      } else if (this.props.element) {
        this.props.onChange(this.props.name, this.props.element);
      }
    } catch (e) {
      this.props.onChange(this.props.name, {});
    }
  };

  handleBlur = value => {
    this.props.onBlur(this.props.name, value);
  };

  handleManualChange = values => {
    const {
      city = '',
      province = '',
      address = '',
      cap = '',
      country = '',
    } = values;

    const place = {
      address,
      title: `${address}, ${cap} ${city} ${province}${
        country ? ', ' : ''
      }${country}`.trim(),
      city,
      province,
      cap,
      country,
    };

    this.props.onChange(this.props.name, place);
  };

  render() {
    const {
      showAuto,
      showManual,
      element,
      errors = {},
      touched = {},
      name,
      readOnly = false,
    } = this.props;

    const labels = {
      ...AddressPicker.defaultProps.labels,
      ...this.props.labels,
    };
    const required = {
      ...AddressPicker.defaultProps.required,
      ...this.props.required,
    };

    const fieldError = errors[name] || '';
    const errorMessages = [];

    /**
     * if is composite get Error or is a String from FE
     */
    if (typeof fieldError !== 'string') {
      Object.keys(fieldError).forEach(key => {
        errorMessages.push(fieldError[key]);
      });
    } else {
      errorMessages.push(fieldError);
    }

    return (
      <Segment style={{ padding: 0 }} basic disabled={readOnly}>
        <Grid>
          <Grid.Row>
            <Grid.Column floated="right" width={5}>
              <SwitchMode
                disabled={readOnly}
                switch={this.changeMode}
                mode={this.state.search}
                showAuto={showAuto}
                showManual={showManual}
                labels={{
                  autoBtn: labels.autoBtn,
                  manualBtn: labels.manualBtn,
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {this.state.search === 'auto' ? (
              <Grid.Column width={16}>
                <Form.Field error={touched[name] && Boolean(fieldError)}>
                  <label htmlFor={this.props.name}>
                    {labels.auto}{' '}
                    {required.auto && <span style={{ color: 'red' }}>*</span>}
                  </label>
                  <Suggest
                    ref={this.suggestRef}
                    disabled={readOnly}
                    autoActivateFirstSuggest
                    placeholder={labels.autoPlaceholder}
                    onBlur={value => {
                      this.handleBlur(value);
                    }}
                    initialValue={element ? element.title : ''}
                    onSuggestSelect={value => {
                      this.handleChange(value);
                    }}
                    className="ui input"
                  />
                  {touched[name] &&
                    Boolean(fieldError) &&
                    getErrorMessages(errorMessages)}
                </Form.Field>
              </Grid.Column>
            ) : (
              <Grid.Column width={16}>
                <GeoInput
                  disabled={readOnly}
                  handleChange={this.handleManualChange}
                  handleBlur={this.props.onBlur}
                  element={element}
                  errors={errors}
                  touched={touched}
                  type={this.props.name}
                  required={{
                    city: required.city,
                    province: required.province,
                    state: required.state,
                    postalCode: required.postalCode,
                    address: required.address,
                  }}
                  labels={{
                    city: labels.city,
                    province: labels.province,
                    state: labels.state,
                    postalCode: labels.postalCode,
                    address: labels.address,
                  }}
                />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

AddressPicker.propTypes = {
  /**
   * Show automatic address insert with Google
   */
  showAuto: PropTypes.bool,
  /**
   * Show manual address insert
   */
  showManual: PropTypes.bool,
  /**
   * Labels
   */
  labels: PropTypes.shape({
    label: PropTypes.string,
    /**
     * The automatic button label.
     */
    autoBtn: PropTypes.string,
    /**
     * The manual button label.
     */
    manualBtn: PropTypes.string,
    /**
     * The automatic search input label.
     */
    auto: PropTypes.string,
    /**
     * The city input label.
     */
    city: PropTypes.string,
    /**
     * The province input label.
     */
    province: PropTypes.string,
    /**
     * The state input label.
     */
    state: PropTypes.string,
    /**
     * The postal code (CAP/ZIP) input label.
     */
    postalCode: PropTypes.string,
    /**
     * The address input label.
     */
    address: PropTypes.string,
    /**
     * The placeholder of the automatic search input.
     */
    autoPlaceholder: PropTypes.string,
  }),
  /**
   * Input value
   */
  element: PropTypes.shape({
    /**
     * The title.
     */
    title: PropTypes.string,
    /**
     * The street name.
     */
    address: PropTypes.string,
    /**
     * The city.
     */
    city: PropTypes.string,
    /**
     * The province.
     */
    province: PropTypes.string,
    /**
     * The postal code (CAP/ZIP).
     */
    cap: PropTypes.string,
    /**
     * The country.
     */
    country: PropTypes.string,
    /**
     * The geographic coordinates.
     */
    coords: PropTypes.array,
  }),
  /**
   * The error objects.
   */
  errors: PropTypes.array,
  /**
   * The touched flag.
   */
  touched: PropTypes.bool,
  /**
   * The handler when the one of the inputs changes.
   */
  onChange: PropTypes.func,
  /**
   * The handler when one of the inputs blurs.
   */
  onBlur: PropTypes.func,
  /**
   * The input object name.
   */
  name: PropTypes.string,
  /**
   * The handler of the switch mode button.
   */
  onSwitch: PropTypes.func,
  /**
   * The initial search mode
   */
  search: PropTypes.oneOf(['manual', 'auto']),
  /**
   * The flag read only.
   */
  readOnly: PropTypes.bool,
  /*
    The required object.
  */
  required: PropTypes.shape({
    /**
     * Flag to set the automatic input search as required.
     */
    auto: PropTypes.bool,
    /**
     * Flag to set the city as required.
     */
    city: PropTypes.bool,
    /**
     * Flag to set the province as required.
     */
    province: PropTypes.bool,
    /**
     * Flag to set the state as required.
     */
    state: PropTypes.bool,
    /**
     * Flag to set the postal code (CAP/ZIP) as required.
     */
    postalCode: PropTypes.bool,
    /**
     * Flag to set the address as required.
     */
    address: PropTypes.bool,
  }),
};

AddressPicker.defaultProps = {
  showAuto: true,
  showManual: true,
  search: 'manual',
  required: {
    auto: false,
    city: false,
    province: false,
    state: false,
    postalCode: false,
    address: false,
  },
  labels: {
    label: '',
    autoBtn: 'Ricerca',
    manualBtn: 'Inserimento manuale',
    city: 'Comune',
    province: 'Provincia',
    state: 'Nazione',
    postalCode: 'CAP',
    address: 'Indirizzo',
    autoPlaceholder: 'Cerca un luogo',
  },
};

export default AddressPicker;
