/* eslint-disable indent */
/* eslint-disable no-underscore-dangle */
/*
 *
 * TechnicalIntervention constants
 *
 */

import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import SimpleFormikField from 'novigo-simple-formik-field';
import { Grid } from 'semantic-ui-react';
import appMessages from '../App/messages';
import messages from './messages';
import FormikDate from '../../components/FormikDate';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';

// Services
import CustomerService from '../../shared/services/customer';
import destinationService from '../../shared/services/destination';
import staffService from '../../shared/services/staff';
import NoteService from '../../shared/services/note';

export const RELATED_TABLES = [
  {
    type: 'leftjoin',
    field: 'customer',
    alias: 'customer',
  },
  {
    type: 'leftjoin',
    field: 'destination',
    alias: 'destination',
  },
  {
    type: 'leftjoin',
    field: 'staff',
    alias: 'staff',
  },
  {
    type: 'leftjoin',
    field: 'workorderType',
    alias: 'workorderType',
  },
  {
    type: 'leftjoin',
    field: 'noteReason',
    alias: 'noteReason',
  },
];

export const StaffWorkorderColumns = intl => [
  {
    key: 'number',
    name: intl.formatMessage(messages.numberODL),
    searchable: true,
    sortable: true,
    width: 60,
  },
  {
    key: 'description',
    name: intl.formatMessage(messages.typeODL),
    width: 60,
    useAlias: 'noteReason',
    formatter: ({ data }) => _.get(data, 'noteReason.description', ''),
    exportFormatter: ({ data }) => _.get(data, 'noteReason.description', ''),
    searchable: true,
    sortable: true,
  },
  {
    key: 'code',
    name: intl.formatMessage(messages.customerCode),
    useAlias: 'customer',
    formatter: ({ data }) => _.get(data, 'customer.code', ''),
    exportFormatter: ({ data }) => _.get(data, 'customer.displayName', ''),
    searchable: true,
    sortable: true,
  },
  {
    key: 'businessName',
    name: intl.formatMessage(messages.customer),
    useAlias: 'destination',
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const businessName = _.get(data, 'destination.businessName', '');
      const businessAddressTitle = _.get(
        data,
        'destination.businessAddressTitle',
        '',
      );

      return (
        <div>
          {businessName}
          <br />
          {businessAddressTitle}
        </div>
      );
    },
    exportFormatter: ({ data }) => _.get(data, 'destination.businessName', ''),
    searchable: true,
    sortable: true,
  },
  {
    key: 'displayName',
    name: intl.formatMessage(messages.technical),
    useAlias: 'staff',
    formatter: ({ data }) => _.get(data, 'staff.displayName', null),
    exportFormatter: ({ data }) => _.get(data, 'staff.displayName', ''),
    searchable: true,
    sortable: true,
  },
  {
    key: 'plannedDate',
    name: intl.formatMessage(messages.plannedDate),
    formatter: ({ data }) =>
      _.get(data, 'plannedDate.date', null)
        ? moment(data.plannedDate.date).format('DD/MM/YYYY')
        : '',
    exportFormatter: ({ data }) =>
      _.get(data, 'plannedDate.date', null)
        ? moment(data.plannedDate.date).format('DD/MM/YYYY')
        : '',
    searchable: true,
    width: 60,
    sortable: true,
  },
  {
    key: 'hoursFrom',
    name: intl.formatMessage(messages.interventionHoursFrom),
    formatter: ({ data }) =>
      _.has(data, '_embedded.destination.interventionTimeFrom.date')
        ? moment(data._embedded.destination.interventionTimeFrom.date).format(
            'HH:mm:ss',
          )
        : null,
    exportFormatter: ({ data }) =>
      _.has(data, '_embedded.destination.interventionTimeFrom.date')
        ? moment(data._embedded.destination.interventionTimeFrom.date).format(
            'HH:mm:ss',
          )
        : null,
    searchable: false,
    sortable: false,
    width: '5%',
  },
  {
    key: 'hoursTo',
    name: intl.formatMessage(messages.interventionHoursTo),
    formatter: ({ data }) =>
      _.has(data, '_embedded.destination.interventionTimeTo.date')
        ? moment(data._embedded.destination.interventionTimeTo.date).format(
            'HH:mm:ss',
          )
        : null,
    exportFormatter: ({ data }) =>
      _.has(data, '_embedded.destination.interventionTimeTo.date')
        ? moment(data._embedded.destination.interventionTimeTo.date).format(
            'HH:mm:ss',
          )
        : null,
    searchable: false,
    sortable: false,
    width: '5%',
  },
  {
    key: 'state',
    name: intl.formatMessage(messages.state),
    searchable: true,
    width: 60,
    sortable: true,
  },
];

export const StaffWorkorderFilters = intl => ({
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  formStructure: (
    <>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="number"
          label={intl.formatMessage(messages.numberODL)}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="noteReason"
          label={intl.formatMessage(messages.typeODL)}
          loadOptions={(searchText, callback) =>
            NoteService.getAsyncNoteReasonOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="customer"
          label={intl.formatMessage(messages.headquarter)}
          loadOptions={(searchText, callback) =>
            CustomerService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="destination"
          label={intl.formatMessage(messages.customer)}
          loadOptions={(searchText, callback) =>
            destinationService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="staff"
          label={intl.formatMessage(messages.technical)}
          loadOptions={(searchText, callback) =>
            staffService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikDate
          name="startDate"
          label={intl.formatMessage(messages.startDate)}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikDate
          name="endDate"
          label={intl.formatMessage(messages.endDate)}
        />
      </Grid.Column>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];
    if (values.number) {
      filterLabels.push({
        key: 'number',
        name: intl.formatMessage(messages.numberODL),
        value: values.number,
        filterField: 'number',
      });
      filters.push({
        type: 'like',
        where: 'and',
        field: 'number',
        value: `%${values.number}%`,
      });
    }
    if (values.noteReason) {
      filterLabels.push({
        key: 'noteReason',
        name: intl.formatMessage(messages.typeODL),
        value: values.noteReason.label,
        filterField: 'noteReason',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'noteReason',
        value: values.noteReason.value,
      });
    }
    if (values.customer) {
      filterLabels.push({
        key: 'customer',
        name: intl.formatMessage(messages.customer),
        value: values.customer.label,
        filterField: 'customer',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'customer',
        value: values.customer.value,
      });
    }
    if (values.destination) {
      filterLabels.push({
        key: 'destination',
        name: intl.formatMessage(messages.destination),
        value: values.destination.label,
        filterField: 'destination',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'destination',
        value: values.destination.value,
      });
    }
    if (values.staff) {
      filterLabels.push({
        key: 'staff',
        name: intl.formatMessage(messages.technical),
        value: values.staff.label,
        filterField: 'staff',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'staff',
        value: values.staff.value,
      });
    }
    if (values.startDate) {
      filterLabels.push({
        key: 'startDate',
        name: intl.formatMessage(messages.startDate),
        value: moment(values.startDate).format('YYYY-MM-DD'),
        filterField: 'plannedDate',
      });
      filters.push({
        type: 'gte',
        where: 'and',
        field: 'plannedDate',
        value: moment(values.startDate).format('YYYY-MM-DD 00:00:00'),
      });
    }
    if (values.endDate) {
      filterLabels.push({
        key: 'endDate',
        name: intl.formatMessage(messages.endDate),
        value: moment(values.endDate).format('YYYY-MM-DD'),
        filterField: 'plannedDate',
      });
      filters.push({
        type: 'lte',
        where: 'and',
        field: 'plannedDate',
        value: moment(values.endDate).format('YYYY-MM-DD 23:59:59'),
      });
    }

    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
});
