import React, { useCallback, useState } from 'react';
import _, { get } from 'lodash';
import PropTypes from 'prop-types';
import ListManager from 'novigo-entity-grid';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import moment from 'moment';

import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch } from '../../../Layout';
import messages from '../../../../containers/Loan/messages';
import currencyFormatter from '../../../../utils/currencyFormatter';
import DestinationLoanService from '../../../../shared/services/destinationloan';
import { convertUTCToLocal } from '../../../../utils/functions';

export const TableWithoutButtonActions = styled.div`
  .ui.table thead > tr > th {
    .ten {
      .ui.button {
        background-color: transparent;
        color: black;
        pointer-events: none;
      }
    }
  }
`;

const LoanTab = props => {
  const columns = () => [
    {
      key: 'displayName',
      name: props.intl.formatMessage(messages.customer),
      useAlias: 'customer',
      searchable: false,
      sortable: true,
      formatter: data =>
        `${_.get(data, 'data._embedded.customer.displayName', '')}`,
      exportformatter: data =>
        `${_.get(data, 'data._embedded.customer.displayName', '')}`,
    },
    {
      key: 'title',
      name: props.intl.formatMessage(messages.product),
      useAlias: 'product',
      searchable: true,
      sortable: true,
      formatter: data =>
        `${_.get(data, 'data._embedded.product.productCode', '')} -
        ${_.get(data, 'data._embedded.product.title', '')}`,
    },
    {
      key: 'createdAt',
      name: props.intl.formatMessage(messages.insert_date),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const { createdAt } = data;
        const localCreatedAt = createdAt
          ? convertUTCToLocal(createdAt.date)
          : '--';
        return localCreatedAt;
      },
      exportFormatter: ({ data }) => {
        if (_.get(data, 'createdAt', false)) {
          return moment(_.get(data, 'createdAt.date')).format('DD/MM/YYYY');
        }
        return '--';
      },
    },
    {
      key: 'numberInstalled',
      name: props.intl.formatMessage(messages.number_installed),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => _.get(data, 'numberInstalled', '--'),
      exportFormatter: ({ data }) => _.get(data, 'numberInstalled', '--'),
    },
    {
      key: 'totalGrossPriceAmount',
      name: props.intl.formatMessage(messages.total_price_amount),
      searchable: true,
      sortable: true,
      formatter: ({ data }) =>
        currencyFormatter.format(_.get(data, 'totalGrossPriceAmount', '--')),
    },
  ];

  const getTotalLoanAmount = useCallback(
    () => {
      if (props.values.customer) {
        DestinationLoanService.getTotalLoanAmount(
          props.values.customer.value,
          props.values.id,
        ).then(response => {
          setTotalLoanAmount(response.data.totalGrossPriceAmount);
        });
      }
    },
    [props.values.customer],
  );

  const [totalLoanAmount, setTotalLoanAmount] = useState(getTotalLoanAmount());

  const totalLoans = () => [
    {
      label: props.intl.formatMessage(messages.total_loan_amount, {
        value: currencyFormatter.format(totalLoanAmount || 0),
      }),
    },
  ];

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <TableWithoutButtonActions>
          <ListManager
            entityName="destination-loan"
            basePath={API.BASE_URL}
            locale={_.get(props, 'user.defaultLanguage', 'it')}
            permissions={props.user}
            router={props}
            client={pars => request(pars).then(res => res.data)}
            aliases={[
              {
                type: 'leftjoin',
                field: 'product',
                alias: 'product',
              },
              {
                type: 'leftjoin',
                field: 'customer',
                alias: 'customer',
              },
            ]}
            columns={columns()}
            canSelect={{
              active: false,
              actions: totalLoans(),
            }}
            canCreate={{
              active: false,
            }}
            canExport={{
              active: false,
            }}
            defaultActions={{
              visualize: false,
              modify: false,
              delete: false,
              grouped: false,
              moreActions: [],
            }}
            persistentFilter={[
              {
                type: 'eq',
                field: 'customer',
                value: get(props, 'values.customer.value', ''),
              },
              {
                type: 'eq',
                field: 'destination',
                value: get(props, 'values.id', ''),
              },
            ].filter(o => o)}
          />
        </TableWithoutButtonActions>
      </TableGlobalSearch>
    </ListWrapper>
  );
};

LoanTab.defaultProps = {
  values: {},
};

LoanTab.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(LoanTab);
