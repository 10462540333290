/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prefer-stateless-function */
/**
 *
 * Export excel
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Icon } from 'semantic-ui-react';

import { injectIntl } from 'react-intl';
import { IntlShape, TranslationsShape } from './Shapes';
import messages from './messages/ExportManagerMessages';
/**
 * The export dropdown to select export file type
 */
class ExportManager extends React.PureComponent {
  render() {
    const {
      active = true,
      handleExport = null,
      exportParams = {},
      intl,
      translations = {},
    } = this.props;

    const { excel = true, pdf = true, csv = true } = exportParams;

    const { exportElements = {} } = translations;

    const {
      prefix = intl.formatMessage(messages.exportPrefix),
      label = intl.formatMessage(messages.buttonLabel),
    } = exportElements;

    /**
     * is is not active or noone service is requested null returned
     */
    if (!active || (excel === false && pdf === false && csv === false)) {
      return null;
    }

    return (
      <div style={{ padding: 10 }}>
        <Dropdown
          trigger={
            <>
              <Icon name="download" />
              {label}
            </>
          }
        >
          <Dropdown.Menu>
            {excel ? (
              <Dropdown.Item
                text={`${prefix} Excel`}
                icon="file excel outline"
                onClick={() => handleExport('Xlsx', 'xlsx')}
              />
            ) : null}
            {pdf ? (
              <Dropdown.Item
                text={`${prefix} PDF`}
                icon="file pdf outline"
                onClick={() => handleExport('Tcpdf', 'pdf')}
              />
            ) : null}
            {csv ? (
              <Dropdown.Item
                text={`${prefix} CSV`}
                icon="file alternate outline"
                onClick={() => handleExport('Csv', 'csv')}
              />
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

ExportManager.defaultProps = {
  active: true,
  handleExport: null,
  exportParams: {},
};

ExportManager.propTypes = {
  /**
   * Active button export
   */
  active: PropTypes.bool,
  /**
   * Function export excel of NovigoEntityGrid
   * reload when export excel,
   * export the excel file
   */
  handleExport: PropTypes.func,
  /**
   * Others params
   */
  exportParams: PropTypes.object,

  // eslint-disable-next-line react/require-default-props
  intl: IntlShape,
  // eslint-disable-next-line react/require-default-props
  translations: TranslationsShape,
};

export default injectIntl(ExportManager);
