/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Grid } from 'semantic-ui-react';
import Select from 'react-select';
import _ from 'lodash';

export default class PermissionForm extends Component {
  state = {
    permissions: _.get(this.props, 'currentPermission.permission')
      ? this.props.currentPermission.permission.privilege.map(permission => ({
          label: permission, // eslint-disable-line
          value: permission, // eslint-disable-line
        })) // eslint-disable-line
      : [],
  };

  static propTypes = {
    onSubmit: PropTypes.func,
    currentPermission: PropTypes.object,
    roleId: PropTypes.string,
    styles: PropTypes.object,
  };

  componentDidMount() {}

  getPermissionOptions = roles =>
    roles.map(role => ({
      value: role.id,
      label: role.roleId,
    }));

  onPermissionChange = opts => this.setState({ permissions: opts }, () => {});

  render() {
    const { permissions } = this.state;
    return (
      <Grid>
        <Grid.Column width={16}>
          <Form
            onSubmit={() =>
              this.props.onSubmit({
                privilege: permissions
                  ? permissions.map(permission => permission.value)
                  : [],
                role: this.props.roleId,
                resource: this.props.currentPermission.id,
                id: _.get(this.props.currentPermission, 'permission.id'),
              })
            }
            style={{ padding: 16 }}
          >
            <Form.Field>
              <label htmlFor="parent">
                Privileges for {this.props.currentPermission.name}
              </label>
              <Select
                defaultValue={this.state.permissions}
                isMulti
                name="colors"
                options={this.props.currentPermission.privilegies.map(
                  permission => ({ label: permission, value: permission }),
                )}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={this.onPermissionChange}
              />
            </Form.Field>
            <div
              style={{
                backgroundColor: this.props.styles.SEMANTIC_UI_ACTION_GREY,
                margin: 'auto -16px -16px -16px',
                padding: 16,
                borderTop: '1px solid #d8d9da',
                borderRadius: '0px 0px 4px 4px',
              }}
            >
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}
