/*
 * TechnicalIntervention Messages
 *
 * This contains all the text for the TechnicalIntervention container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.TechnicalIntervention';

export default defineMessages({
  tab_work_order: {
    id: `${scope}.tab_work_order`,
    defaultMessage: 'Work Order',
  },
  tab_task_order: {
    id: `${scope}.tab_task_order`,
    defaultMessage: 'Task',
  },
  tab_deratization: {
    id: `${scope}.tab_deratization`,
    defaultMessage: 'Deratization',
  },
  tab_disinfection: {
    id: `${scope}.tab_disinfection`,
    defaultMessage: 'Disinfection',
  },
  tab_monitor_cockroach: {
    id: `${scope}.tab_monitor_cockroach`,
    defaultMessage: 'Monitor Cockroach',
  },
  tab_monitor_flying: {
    id: `${scope}.tab_monitor_flying`,
    defaultMessage: 'Monitor Fliers',
  },
  tab_monitor_goods: {
    id: `${scope}.tab_monitor_goods`,
    defaultMessage: 'Monitor Goods',
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'Number',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  technical: {
    id: `${scope}.technical`,
    defaultMessage: 'Technical',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  referent: {
    id: `${scope}.referent`,
    defaultMessage: 'Referent',
  },
  contract: {
    id: `${scope}.contract`,
    defaultMessage: 'Contract',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  note_placeholder: {
    id: `${scope}.note_placeholder`,
    defaultMessage: 'Write note',
  },
  interventionCode: {
    id: `${scope}.interventionCode`,
    defaultMessage: 'Intervention Code',
  },
  interventionDescription: {
    id: `${scope}.interventionDescription`,
    defaultMessage: 'Intervention Description',
  },
  planned: {
    id: `${scope}.planned`,
    defaultMessage: 'Planned date',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Start',
  },
  end: {
    id: `${scope}.end`,
    defaultMessage: 'End',
  },
  intervention_list: {
    id: `${scope}.intervention_list`,
    defaultMessage: 'Intervention list',
  },
  intervention_removed_success: {
    id: `${scope}.intervention_removed_success`,
    defaultMessage: 'Note successfully saved',
  },
  new_intervention: {
    id: `${scope}.new_intervention`,
    defaultMessage: 'New Intervention',
  },
  typeODL: {
    id: `${scope}.typeODL`,
    defaultMessage: 'ODL Type',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  stateHistory: {
    id: `${scope}.stateHistory`,
    defaultMessage: 'State history',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Username',
  },
  duration: {
    id: `${scope}.duration`,
    defaultMessage: 'Duration',
  },
  signatory: {
    id: `${scope}.signatory`,
    defaultMessage: 'Signatory',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  raport_service: {
    id: `${scope}.raport_service`,
    defaultMessage: 'Raport Service',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Intervention description',
  },
  corrective_action: {
    id: `${scope}.corrective_action`,
    defaultMessage: 'Corrective action',
  },
  get_report: {
    id: `${scope}.get_report`,
    defaultMessage: 'Get Report',
  },
  upload_success: {
    id: `${scope}.upload_success`,
    defaultMessage: 'Upload success!',
  },
  remove_success: {
    id: `${scope}.remove_success`,
    defaultMessage: 'Remove image success!',
  },
  increment: {
    id: `${scope}.increment`,
    defaultMessage: 'Increment',
  },
  disinfectedArea: {
    id: `${scope}.disinfectedArea`,
    defaultMessage: 'Disinfected Area',
  },
  disinfectedAreaDescription: {
    id: `${scope}.disinfectedAreaDescription`,
    defaultMessage: 'Disinfected Area Note',
  },
  disinfectedAreaCockroachG: {
    id: `${scope}.disinfectedAreaCockroachG`,
    defaultMessage: 'Cockroach G.',
  },
  other: {
    id: `${scope}.other`,
    defaultMessage: 'Other',
  },
  targetInsects: {
    id: `${scope}.targetInsects`,
    defaultMessage: 'Target Insects',
  },
  disinfectedAreaCockroachO: {
    id: `${scope}.disinfectedAreaCockroachO`,
    defaultMessage: 'Cockroach O.',
  },
  disinfectedAreaMosquito: {
    id: `${scope}.disinfectedAreaMosquito`,
    defaultMessage: 'Mosquito',
  },
  disinfectedAreaFlies: {
    id: `${scope}.disinfectedAreaFlies`,
    defaultMessage: 'Flies',
  },
  disinfectedAreaSpider: {
    id: `${scope}.disinfectedAreaSpider`,
    defaultMessage: 'Spiders',
  },
  uploadImage: {
    id: `${scope}.uploadImage`,
    defaultMessage: 'Upload Image',
  },
  manager: {
    id: `${scope}.manager`,
    defaultMessage: 'Manager',
  },
  evaluation_expiration_date: {
    id: `${scope}.evaluation_expiration_date`,
    defaultMessage: 'Evaluation expiration date',
  },
  note_reason: {
    id: `${scope}.note_reason`,
    defaultMessage: 'Reason',
  },
  technician: {
    id: `${scope}.technician`,
    defaultMessage: 'Technician',
  },
  internal_note: {
    id: `${scope}.internal_note`,
    defaultMessage: 'Internal note',
  },
  description_reason: {
    id: `${scope}.description_reason`,
    defaultMessage: 'Reason',
  },
  send_report: {
    id: `${scope}.send_report`,
    defaultMessage: 'Send report',
  },
  send_save_report: {
    id: `${scope}.send_save_report`,
    defaultMessage: 'Save and send',
  },
  get_save_report: {
    id: `${scope}.get_save_report`,
    defaultMessage: 'Save and get',
  },
});
