/*
 * SideBar Messages
 *
 * This contains all the text for the SideBar component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Forms.User';

export default defineMessages({
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Active',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  surname: {
    id: `${scope}.surname`,
    defaultMessage: 'Surname',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Phone',
  },
  language: {
    id: `${scope}.language`,
    defaultMessage: 'Language',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Username',
  },
  plainPassword: {
    id: `${scope}.plainPassword`,
    defaultMessage: 'Password',
  },
  roles: {
    id: `${scope}.roles`,
    defaultMessage: 'Roles',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  firstName_required: {
    id: `${scope}.firstName_required`,
    defaultMessage: 'Firstname is required!',
  },
  lastName_required: {
    id: `${scope}.lastName_required`,
    defaultMessage: 'LastName is required!',
  },
  username_required: {
    id: `${scope}.username_required`,
    defaultMessage: 'Username is required!',
  },
  email_required: {
    id: `${scope}.email_required`,
    defaultMessage: 'Email is required!',
  },
  password_required: {
    id: `${scope}.password_required`,
    defaultMessage: 'Password is required!',
  },
});
