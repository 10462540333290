/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import _, { get, isEmpty } from 'lodash';
import moment from 'moment';
import { Icon, Label, Popup, Checkbox } from 'semantic-ui-react';
import {
  OFFER_STATE_INSERTED,
  OFFER_STATE_NOT_ACCEPTED,
  OFFER_STATE_TO_BE_ACCEPTED,
  OFFER_STATE_WIN,
  OFFER_STATE_LOSS,
  OFFER_STATE_ACCEPTED,
} from './constants';
import PermissionManager from '../../components/Permission';
import FormikToggle from '../../components/FormikToggle';
import { RemoveBtn } from '../../components/Buttons';
import percentageFormatter from '../../utils/percentageFormatter';
import FormikInput from '../../components/FormikInput';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import ProductService from '../../shared/services/product';
import { convertUTCToLocal } from '../../utils/functions';
import messages from './messages';
import { getNetPrice } from '../../components/Forms/OrderForm/manageForm/functions';
import { applyMerchDiscount } from '../../components/SearchBars/Offer/functions';
import currencyFormatter from '../../utils/currencyFormatter';

export const OfferStateColorMap = {
  [OFFER_STATE_INSERTED]: 'red',
  [OFFER_STATE_TO_BE_ACCEPTED]: 'orange',
  [OFFER_STATE_ACCEPTED]: 'green',
};

/**
 * Returns the list of columns to be shown in the offer rows table.
 * @param {*} intl
 * @param {*} onChangeRowData
 * @param {*} onAction
 * @param {*} isEdit
 * @returns
 */
export const OfferRowColumns = (
  intl,
  onChangeRowData,
  onAction,
  isEdit,
  handleOnArticleRowChange,
) => [
    // Product code
    {
      key: 'product.productCode',
      name: (
        <span>
          {intl.formatMessage(messages.productCode)}
          <br />
          {intl.formatMessage(messages.competitorNote)}
          <br />
          {'Descrizione'}
        </span>
      ),
      searchable: true,
      sortable: true,
      width: isEdit ? '200' : 200,
      formatter: ({ data }) =>
        isEdit ? (
          <>
            <FormikAsyncSelect
              name="product"
              label=" "
              initialValue={data.product}
              onChange={product => handleOnArticleRowChange(product.value, data)}
              asyncSelectProps={{
                isClearable: true,
              }}
              loadOptions={(searchText, callback) =>
                ProductService.getOptions(searchText, callback)
              }
            />

            <FormikInput
              label=" "
              name="competitorNote"
              initialValue={data.competitorNote}
              placeholder={intl.formatMessage(messages.competitorNote)}
              onChange={e => {
                onChangeRowData(e.target.value, 'competitorNote', data.id);
              }}
            />

            <FormikInput
              label=" "
              name="description"
              placeholder="Descrizione"
              initialValue={data.description}
              onChange={e => {
                onChangeRowData(e.target.value, 'description', data.id);
              }}
            />
          </>
        ) : (
          <>
            <span>{get(data, 'product.productCode', '')}</span>
            <br />
            <span>{get(data, 'product.title', '')}</span>
            <br />
            <span>{get(data, 'competitorNote', '')}</span>
            <br />
            <span>{get(data, 'description', '')}</span>
          </>
        ),
    },
    // Buy price
    {
      key: 'buyPrice',
      name: <span>{intl.formatMessage(messages.buyPrice)}</span>,
      searchable: true,
      width: isEdit ? 100 : 1,
      sortable: true,
      formatter: ({ data }) => {
        const { buyPrice } = data;
        return isEdit ? (
          <>
            <FormikInput
              label=" "
              name="buyPrice"
              initialValue={buyPrice}
              onChange={e => {
                let value = e.target.value.replace(',', '.');
                value = value === '' ? 0 : parseFloat(value);

                onChangeRowData(e.target.value, 'buyPrice', data.id);
                if (e.target.value !== '' && parseFloat(e.target.value) !== 0) {
                  onChangeRowData(
                    (
                      parseFloat(
                        (data.netPrice - parseFloat(value)) / parseFloat(value),
                      ) * 100
                    ).toFixed(2),
                    'markup',
                    data.id,
                  );

                  if (data.competitorPrice !== '' && parseFloat(data.competitorPrice) !== 0 && data.competitorPrice !== null) {
                    onChangeRowData(
                      parseFloat(
                        ((data.competitorPrice - value) / value) * 100,
                      ).toFixed(2),
                      'competitorMarkup',
                      data.id,
                    );
                    updateDifference(data, onChangeRowData);
                  } else {
                    onChangeRowData('0.00', 'competitorMarkup', data.id);
                    onChangeRowData('0.00', 'difference', data.id);
                  }
                } else {
                  onChangeRowData('0.00', 'buyPrice', data.id);
                  onChangeRowData('0.00', 'markup', data.id);
                }
              }}
            />
          </>
        ) : (
          <>{buyPrice}</>
        );
      },
    },
    {
      key: 'competitorPrice',
      name: <span>{intl.formatMessage(messages.competitorPrice)}</span>,
      searchable: true,
      width: isEdit ? 100 : 1,
      sortable: true,
      formatter: ({ data }) => {
        const { competitorPrice } = data;
        return isEdit ? (
          <FormikInput
            label=" "
            name="competitorPrice"
            initialValue={isEmpty(competitorPrice) ? '0.00' : competitorPrice}
            placeholder={intl.formatMessage(messages.competitorPrice)}
            onChange={e => {
              let value = e.target.value.replace(/[^0-9.]/g, '');
              value = value === "" ? "0.00" : value;


              if (parseFloat(value) !== 0 && (data.buyPrice === '' ? 0 : parseFloat(data.buyPrice) !== 0)) {
                onChangeRowData(
                  parseFloat(
                    ((value - data.buyPrice) / data.buyPrice) * 100,
                  ).toFixed(2),
                  'competitorMarkup',
                  data.id,
                );
              } else {
                onChangeRowData('0.00', 'competitorMarkup', data.id);
              }
              onChangeRowData(value, 'competitorPrice', data.id);
              updateDifference(data, onChangeRowData);
            }}
          />
        ) : (
          <>{competitorPrice}</>
        );
      },
    },
    {
      key: 'prices',
      width: isEdit ? 100 : 1,
      name: (
        <span>
          {intl.formatMessage(messages.productPriceMaster)}
          <br />
          {intl.formatMessage(messages.productPriceCustomer)}
          <br />
          {intl.formatMessage(messages.priceOffer)}
        </span>
      ),
      formatter: ({ data }) => {
        const { productCustomerPrice, offerPrice, productMasterPrice } = data;
        return (
          <>
            {productMasterPrice ? data.productMasterPrice : '--'}
            <br />
            {productCustomerPrice ? data.productCustomerPrice : '--'}
            <br />
            {offerPrice ? data.offerPrice : '--'}
          </>
        );
      },
    },
    // Discount value
    {
      key: 'discountValue',
      name: intl.formatMessage(messages.discountValue),
      searchable: true,
      sortable: true,
      width: isEdit ? 100 : 1,
      formatter: ({ data }) =>
        isEdit ? (
          <>
            <FormikInput
              name="discountValue"
              label=" "
              placeholder={intl.formatMessage(messages.discountValue)}
              initialValue={data.discountValue}
              disabled={data.discountDisabled || false}
              onChange={e => {
                let basePrice = getNetPrice(data);
                let discountExtra = data.extraDiscount;

                const totalDiscount =
                  parseFloat(e.target.value) + parseFloat(discountExtra);
                if (totalDiscount !== 0) basePrice = data.productMasterPrice;

                const value = parseFloat(e.target.value.replace(',', '.'), 10);
                const discount = value || 0;
                discountExtra = parseFloat(discountExtra) || 0;
                if (discountExtra !== '') {
                  const discountedBase = (basePrice / 100) * (100 - discount);
                  onChangeRowData(
                    parseFloat(
                      (discountedBase / 100) * (100 - discountExtra),
                    ).toFixed(2),
                    'netPrice',
                    data.id,
                  );
                }

                onChangeRowData(
                  data.netPrice * parseFloat(data.minQuantity, 10),
                  'amount',
                  data.id,
                );

                updateDifference(data, onChangeRowData);

                if (parseFloat(data.buyPrice) !== 0)
                  onChangeRowData(
                    (
                      parseFloat(
                        (data.netPrice - data.buyPrice) / data.buyPrice,
                      ) * 100
                    ).toFixed(2),
                    'markup',
                    data.id,
                  );

                if (e.target.value !== '')
                  onChangeRowData(e.target.value, 'discountValue', data.id);
                else onChangeRowData('0.00', 'discountValue', data.id);
              }}
            />

            <FormikInput
              name="extraDiscount"
              label=" "
              disabled={data.discountExtraDisabled || false}
              placeholder={intl.formatMessage(messages.extraDiscount)}
              initialValue={data.extraDiscount}
              onChange={e => {
                let basePrice = getNetPrice(data);
                let discount = data.discountValue;

                //NOTE: If any discount is applied use productMasterPrice as base
                if (parseFloat(discount) + parseFloat(e.target.value) !== 0)
                  basePrice = data.productMasterPrice;

                const value = parseFloat(e.target.value, 10);
                const discountExtra = value || 0;
                discount = parseFloat(discount) || 0;
                if (discountExtra !== '') {
                  const discountedBase = (basePrice / 100) * (100 - discount);
                  onChangeRowData(
                    parseFloat(
                      (discountedBase / 100) * (100 - discountExtra),
                    ).toFixed(2),
                    'netPrice',
                    data.id,
                  );
                }

                if (parseFloat(data.buyPrice) !== 0)
                  onChangeRowData(
                    (
                      parseFloat(
                        (data.netPrice - data.buyPrice) / data.buyPrice,
                      ) * 100
                    ).toFixed(2),
                    'markup',
                    data.id,
                  );

                onChangeRowData(
                  data.netPrice * parseFloat(data.minQuantity, 10),
                  'amount',
                  data.id,
                );

                updateDifference(data, onChangeRowData);
                if (e.target.value !== '')
                  onChangeRowData(e.target.value, 'extraDiscount', data.id);
                else onChangeRowData('0.00', 'extraDiscount', data.id);
              }}
            />
          </>
        ) : (
          <>
            {parseFloat(get(data, 'discountValue', '')).toFixed(2)}
            <br />
            {parseFloat(get(data, 'extraDiscount', '')).toFixed(2)}
          </>
        ),
    },
    // Net price
    {
      key: 'netPrice',
      name: intl.formatMessage(messages.netPrice),
      searchable: true,
      sortable: true,
      width: isEdit ? 100 : 1,
      formatter: ({ data }) =>
        isEdit ? (
          <FormikInput
            name="netPrice"
            label=" "
            initialValue={data.netPrice}
            disabled={data.netPriceDisabled}
            onChange={e => {
              const np = getNetPrice(data);
              const value = e.target.value.replace(',', '.');
              if (
                np !== null &&
                parseFloat(np) !== 0 &&
                data.productMasterPrice !== 0
              )
                onChangeRowData(
                  parseFloat(
                    100 - (value * 100) / data.productMasterPrice,
                  ).toFixed(2),
                  'discountValue',
                  data.id,
                );

              onChangeRowData('0', 'extraDiscount', data.id);
              onChangeRowData(value, 'netPriceRow', data.id);
              onChangeRowData(
                e.target.value * parseFloat(data.minQuantity, 10),
                'amount',
                data.id,
              );

              updateDifference(data, onChangeRowData);

              onChangeRowData(
                e.target.value.replace(/[^0-9.]/g, ''),
                'netPrice',
                data.id,
              );

              if (parseFloat(data.buyPrice) !== 0)
                onChangeRowData(
                  (
                    parseFloat((data.netPrice - data.buyPrice) / data.buyPrice) *
                    100
                  ).toFixed(2),
                  'markup',
                  data.id,
                );
            }}
          />
        ) : (
          get(data, 'netPrice', '')
        ),
    },
    // Quantity
    {
      key: 'minQuantity',
      name: intl.formatMessage(messages.qt),
      searchable: true,
      sortable: true,
      width: isEdit ? 100 : 1,
      formatter: ({ data }) =>
        isEdit ? (
          <FormikInput
            name="minQuantity"
            label=" "
            initialValue={data.minQuantity}
            onChange={e => {
              onChangeRowData(e.target.value, 'minQuantity', data.id);
              onChangeRowData(
                data.netPrice * parseFloat(data.minQuantity, 10),
                'amount',
                data.id,
              );
            }}
          />
        ) : (
          get(data, 'minQuantity', '')
        ),
    },
    // Price amount
    {
      key: 'amount',
      name: intl.formatMessage(messages.priceAmount),
      searchable: true,
      sortable: true,
      width: 1,
      formatter: ({ data }) => currencyFormatter.format(get(data, 'amount', '')),
    },
    // Markup
    {
      key: 'markup',
      name: intl.formatMessage(messages.markup),
      searchable: true,
      sortable: true,
      width: isEdit ? 100 : 1,
      formatter: ({ data }) =>
        isEdit ? (
          <FormikInput
            name="markup"
            label=" "
            initialValue={data.markup}
            onChange={e => {
              const value = e.target.value.replace(',', '.');
              const np =
                (value / 100) * parseFloat(data.buyPrice) +
                parseFloat(data.buyPrice);

              if (np !== null && parseFloat(np) !== 0 && data.buyPrice !== 0) {
                onChangeRowData(value, 'markup', data.id);
                onChangeRowData(np, 'netPrice', data.id);
              }

              updateDifference(data, onChangeRowData);

              if (parseFloat(data.productMasterPrice) !== 0 && data.productMasterPrice !== null)
                onChangeRowData(
                  parseFloat(100 - (np * 100) / data.productMasterPrice).toFixed(2),
                  'discountValue',
                  data.id,
                );

              onChangeRowData('0', 'extraDiscount', data.id);
              onChangeRowData(
                e.target.value * parseFloat(data.quantity, 10),
                'priceAmount',
                data.id,
              );
            }
            }
          />
        ) : (
          get(data, 'markup', '')
        ),
    },
    PermissionManager.CanI('accessProductInformation', 'custom') && {
      key: 'competitorMarkup',
      width: 80,
      name: (
        <span>
          R. Conc
          <br />
          Diff.
          <br />
        </span>
      ),
      formatter: ({ data }) => {
        const competitorMarkup = _.get(data, 'competitorMarkup', '');
        const difference = _.get(data, 'difference', '');
        return (
          <>
            {competitorMarkup
              ? percentageFormatter.format(competitorMarkup / 100)
              : '--'}
            <br />
            {difference ? percentageFormatter.format(difference / 100) : '--'}
          </>
        );
      },
    },
    // Goods discount
    {
      key: 'goodsDiscount',
      name: intl.formatMessage(messages.goodsDiscount),
      searchable: true,
      sortable: true,
      width: 1,
      formatter: ({ data }) =>
        isEdit ? (
          <Checkbox
            name="goodsDiscount"
            label=" "
            checked={data.goodsDiscount}
            onChange={(value, toggleData) => {
              applyMerchDiscount(
                toggleData.checked,
                data,
                onChangeRowData,
                getNetPrice,
              );
              updateDifference(data, onChangeRowData);
              onChangeRowData(toggleData.checked, 'goodsDiscount', data.id);
              onChangeRowData(false, 'loan', data.id);
            }}
          />
        ) : (
          <>
            {data.goodsDiscount
              ? intl.formatMessage(messages.yes)
              : intl.formatMessage(messages.no)}
          </>
        ),
    },
    // Loan
    {
      key: 'loan',
      name: intl.formatMessage(messages.loan),
      searchable: true,
      sortable: true,
      width: 1,
      formatter: ({ data }) =>
        isEdit ? (
          <>
            <Checkbox
              name="loan"
              label=" "
              checked={data.loan}
              onChange={(value, toggleData) => {
                applyMerchDiscount(
                  toggleData.checked,
                  data,
                  onChangeRowData,
                  getNetPrice,
                );
                updateDifference(data, onChangeRowData);
                onChangeRowData(toggleData.checked, 'loan', data.id);
                onChangeRowData(false, 'goodsDiscount', data.id);
              }}
            />
          </>
        ) : (
          <>
            {data.loan
              ? intl.formatMessage(messages.yes)
              : intl.formatMessage(messages.no)}
          </>
        ),
    },
    // Actions
    ...(!isEdit
      ? []
      : [
        {
          key: 'actions',
          name: intl.formatMessage(messages.actions),
          width: 70,
          searchable: true,
          sortable: true,
          formatter: ({ data }) => OfferRowActions(onAction, data),
        },
      ]),
  ];

export const OfferRowActions = (onAction, data) => {
  const canDelete = PermissionManager.CanI('remove', 'orderRow');

  return (
    <>
      {/* Delete button */}
      {canDelete ? (
        <span className="order-action">
          <RemoveBtn
            action={() => onAction('delete', data)}
            notice
            icon="trash"
          />
        </span>
      ) : null}
    </>
  );
};

export const columns = ({ intl }) => [
  {
    key: 'number',
    name: intl.formatMessage(messages.offer_number),
    searchable: true,
    sortable: true,
    formatter: ({ data }) => {
      const state = get(data, 'state', '--');
      const number = get(data, 'number', '--');
      return (
        (state === OFFER_STATE_WIN && (
          <div>
            <Label ribbon color="green">
              {state}
            </Label>
            {number}
          </div>
        )) ||
        ((state === OFFER_STATE_LOSS || state === OFFER_STATE_NOT_ACCEPTED) && (
          <div>
            <Label ribbon color="red">
              {state}
            </Label>
            {number}
          </div>
        )) ||
        (state !== OFFER_STATE_WIN &&
          state !== OFFER_STATE_LOSS && (
            <div>
              <Label ribbon color="yellow">
                {state}
              </Label>
              {number}
            </div>
          ))
      );
    },
  },
  {
    key: 'businessName',
    name: intl.formatMessage(messages.customer_prospect),
    useAlias: 'destination',
    searchable: true,
    sortable: true,
    formatter: ({ data }) =>
      get(data, '_embedded.destination.businessName', '--'),
    exportFormatter: ({ data }) =>
      get(data, '_embedded.destination.businessName', '--'),
  },
  {
    key: 'sentAt',
    name: intl.formatMessage(messages.offerDate),
    searchable: true,
    sortable: true,
    formatter: ({ data }) => {
      const { sentAt } = data;
      const localCreatedAt = sentAt ? convertUTCToLocal(sentAt.date, 'DD/MM/YYYY HH:mm')
        : '--';
      return localCreatedAt;
    },
    exportFormatter: ({ data }) => {
      if (get(data, 'sentAt', false)) {
        return moment(get(data, 'sentAt.date')).format('DD/MM/YYYY');
      }
      return '--';
    },
  },
  {
    key: 'expirationDate',
    name: intl.formatMessage(messages.expirationDate),
    searchable: true,
    sortable: true,
    // eslint-disable-next-line consistent-return
    formatter: ({ data }) => {
      if (get(data, 'expirationDate', false)) {
        return moment(get(data, 'expirationDate')).format('DD/MM/YYYY');
      }
      return '--';
    },
    exportFormatter: ({ data }) => {
      if (get(data, 'expirationDate', false)) {
        return moment(get(data, 'expirationDate')).format('DD/MM/YYYY');
      }
      return '--';
    },
  },
  {
    key: 'name',
    name: intl.formatMessage(messages.typology),
    useAlias: 'typology',
    searchable: true,
    sortable: true,
    formatter: ({ data }) => get(data, '_embedded.typology.name', '--'),
    exportFormatter: ({ data }) => get(data, '_embedded.typology.name', '--'),
  },
  {
    key: 'state',
    name: intl.formatMessage(messages.status),
    searchable: true,
    sortable: true,
    formatter: ({ data }) => {
      const state = get(data, 'state', '--');
      return (
        (state === OFFER_STATE_WIN && (
          <div>
            <Label ribbon color="green">
              <br />
            </Label>
            {state}
          </div>
        )) ||
        (state === OFFER_STATE_LOSS && (
          <div>
            <Label ribbon color="red">
              <br />
            </Label>
            {state}
          </div>
        )) || <span>{state}</span>
      );
    },
    exportFormatter: ({ data }) => get(data, 'state', '--'),
  },
];

export const updateDifference = (data, onChangeRowData) => {
  onChangeRowData(
    ((data.netPrice - data.competitorPrice) * 100 * 1) / data.competitorPrice,
    'difference',
    data.id,
  );

  if (data.difference === Infinity) {
    onChangeRowData(
      '0.00',
      'difference',
      data.id,
    );
  }
}
