import { get } from 'lodash';
import appMessages from '../App/messages';
import { addNotification } from '../../utils/notification';
import OfferService from '../../shared/services/offer';
import { API } from '../../global-constants';
import messages from './messages';
import { downloadFileFromAjaxResponse } from '../../utils/functions';

/**
 * Creates a new offer and navigates to offer detail page.
 * @param {*} history
 * @param {*} prodottiTypology
 * @param {*} setLoading
 */
export const createOffer = (history, prodottiTypology, intl, setLoading) => {
  setLoading(true);
  OfferService.create({
    typology: prodottiTypology,
    maxDiscount: 0,
  })
    .then(res => {
      setLoading(false);
      history.push(`${API.OFFER}/${res.data.id}/new`);
    })
    .catch(err => {
      setLoading(false);
      addNotification({
        title: intl.formatMessage(appMessages.error),
        message: get(
          err,
          'detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        ),
        isError: true,
      });
    });
};

/**
 * Navigates to the offer edit page.
 * @param {*} history
 * @param {*} id
 */
export const modifyOffer = (history, id) => {
  history.push(`/offer/${id}/modify`);
};

/**
 * Navigates to the offer delete page.
 * @param {*} history
 * @param {*} id
 */
export const deleteOffer = (history, id) => {
  history.push(`/offer/${id}/delete`);
};

/**
 * Navigates to the offer view page.
 * @param {*} history
 * @param {*} id
 */
export const viewOffer = (history, id) => {
  history.push(`/offer/${id}/view`);
};

/**
 * Updates an offer state.
 * @param {*} idOffer
 * @param {*} state
 * @param {*} intl
 * @param {*} reloadData
 * @param {*} setLoading
 */
export const setOfferState = (idOffer, state, intl, reloadData, setLoading) => {
  if (setLoading) setLoading(true);
  const payload = {
    id: idOffer,
    state,
  };
  OfferService.patch(payload)
    .then(() => {
      if (setLoading) setLoading(false);
      if (reloadData) reloadData();

      addNotification({
        title: intl.formatMessage(appMessages.success),
        message: intl.formatMessage(messages.state_update_success),
        isError: false,
      });
    })
    // eslint-disable-next-line no-unused-vars
    .catch(error => {
      if (setLoading) setLoading(false);
      addNotification({
        title: intl.formatMessage(appMessages.error),
        message: get(
          error,
          'data.detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        ),
        isError: true,
      });
    });
};

/**
 * Updates an offer with the given payload.
 * @param {*} payload
 * @param {*} intl
 * @param {*} setLoading
 */
export const updateOffer = (payload, intl, reloadData, setLoading) => {
  setLoading(true);

  OfferService.patch(payload)
    .then(() => {
      setLoading(false);
      addNotification({
        title: intl.formatMessage(appMessages.success),
        message: intl.formatMessage(messages.offer_update_success),
        isError: false,
      });
      reloadData();
    })
    .catch(error => {
      setLoading(false);
      addNotification({
        title: intl.formatMessage(appMessages.error),
        message: get(
          error,
          'data.detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        ),
        isError: true,
      });
    });
};

/**
 * TODO
 */
export const duplicateOffer = (values, intl, reloadData, setLoading) => {
  setLoading(true);
  const duplicateData = {
    typology: get(values, 'typology.value', null),
    destination: get(values, '_embedded.destination.id', null),
    isDuplicate: true,
    finalized: 1,
    offerId: get(values, 'id', null),
    totalBuyPriceAmount: get(values, 'totalBuyPriceAmount', 0),
    totalGrossPriceAmount: get(values, 'totalGrossPriceAmount', 0),
    totalPriceAmount: get(values, 'totalPriceAmount', 0),
    port: get(values, 'port', ''),
    payment: get(values, 'payment', ''),
    delivery: get(values, 'delivery', ''),
    avarageDiscount: get(values, 'avarageDiscount', null),
    avarageMarkup: get(values, 'avarageMarkup', null),
    maxDiscount: get(values, 'maxDiscount', null),
  };
  OfferService.duplicate(duplicateData)
    .then(() => {
      // setOpenDuplicateModal(false);
      addNotification({
        title: null,
        message: intl.formatMessage(messages.duplicateSuccess),
        isError: false,
      });
      reloadData();
    })
    .catch(err =>
      addNotification({
        title: intl.formatMessage(appMessages.error),
        message: get(
          err,
          'data.detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        ),
        isError: true,
      }),
    )
    .finally(() => {
      setLoading(false);
    });
};

/**
 * Exports the given offer as a PDF.
 * @param {*} id
 * @param {*} grouped
 * @param {*} intl
 */
export const exportOfferPdf = (id, grouped, intl) => {
  const payload = { offerId: id, fileType: 'pdf' };
  if (grouped) {
    payload.grouped = 1;
  }
  OfferService.exportPdf(payload)
    .then(response => {
      downloadFileFromAjaxResponse(response);
    })
    .catch(err => {
      addNotification({
        title: intl.formatMessage(appMessages.error),
        message: get(
          err,
          'data.detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        ),
        isError: true,
      });
    });
};

/**
 * Exports the given offer as a DOC.
 * @param {*} id
 * @param {*} grouped
 * @param {*} intl
 */
export const exportOfferWord = (id, grouped, intl) => {
  const data = { offerId: id };
  if (grouped) {
    data.grouped = true;
  }
  OfferService.exportWord(data)
    .then(response => {
      downloadFileFromAjaxResponse(response);
    })
    .catch(err => {
      addNotification({
        title: intl.formatMessage(appMessages.error),
        message: get(
          err,
          'data.detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        ),
        isError: true,
      });
    });
};
