/*
 * Customer note Messages
 *
 * This contains all the text for the Customer note container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.ProductPriceMaster';

export default defineMessages({
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Valid from',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'Valid to',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
  price_saved: {
    id: `${scope}.price_saved`,
    defaultMessage: 'Price saved succesfully!',
  },
  price_deleted: {
    id: `${scope}.price_deleted`,
    defaultMessage: 'Price deleted succesfully!',
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Price',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Supplier',
  },
  buyPrice: {
    id: `${scope}.buyPrice`,
    defaultMessage: 'Buy price',
  },
  grossPrice: {
    id: `${scope}.grossPrice`,
    defaultMessage: 'Gross price',
  },
  competitorPrice: {
    id: `${scope}.competitorPrice`,
    defaultMessage: 'Competitor price',
  },
  remark: {
    id: `${scope}.remark`,
    defaultMessage: 'Remark',
  },
  start_lt_end_date: {
    id: `${scope}.start_lt_end_date`,
    defaultMessage: 'The start date should be before the end date.',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Quantity',
  },
});
