import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import { PaddedColumn } from '../../Layout';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import messages from './messages';
import CurrencyFormatter from '../../CurrencyFormatter';
import FormActionsBar from '../../FormActionsBar';

import ProductService from '../../../shared/services/product';

import validation from './validation';

const ProductPriceMasterForm = props => {
  useEffect(
    () => {
      if (props.isNew && props.data) {
        props.setFieldValue('startDate', props.data.startDate);
        props.setFieldValue('endDate', props.data.endDate);
      }
    },
    [props.isNew, props.data],
  );
  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting}
    >
      <Grid columns="equal">
        <Grid.Row columns={2}>
          <PaddedColumn>
            <FormikAsyncSelect
              {...props}
              name="product"
              required
              label={props.intl.formatMessage(messages.product)}
              loadOptions={(searchText, callback) =>
                ProductService.getOptions(searchText, callback)
              }
            />
          </PaddedColumn>
          {/* <PaddedColumn>
            <FormikAsyncSelect
              {...props}
              name="supplier"
              label={props.intl.formatMessage(messages.supplier)}
              loadOptions={(searchText, callback) =>
                SupplierService.getOptions(searchText, callback)
              }
            />
          </PaddedColumn> */}
          {/* <PaddedColumn>
            <CurrencyFormatter
              {...props}
              name="buyPrice"
              label={props.intl.formatMessage(messages.buyPrice)}
            />
          </PaddedColumn> */}
          <PaddedColumn>
            <CurrencyFormatter
              {...props}
              name="grossPrice"
              label={props.intl.formatMessage(messages.grossPrice)}
            />
          </PaddedColumn>
          {/* <PaddedColumn>
            <CurrencyFormatter
              {...props}
              name="competitorPrice"
              label={props.intl.formatMessage(messages.competitorPrice)}
            />
          </PaddedColumn> */}
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              required
              type="number"
              name="quantity"
              label={props.intl.formatMessage(messages.quantity)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikDate
              {...props}
              name="startDate"
              label={props.intl.formatMessage(messages.startDate)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikDate
              {...props}
              name="endDate"
              label={props.intl.formatMessage(messages.endDate)}
            />
          </PaddedColumn>
        </Grid.Row>
      </Grid>
      <FormActionsBar {...props} />
    </Form>
  );
};

ProductPriceMasterForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(ProductPriceMasterForm);

export { validation };
