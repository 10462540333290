import { error, success } from 'react-notification-system-redux';
import { store } from '../configureStore';

export const notificationOpts = ({ title, message, isError, action }) => ({
  // uid: 'once-please', // you can specify your own uid if required
  title,
  message,
  position: 'tr',
  autoDismiss: isError ? 0 : 5,
  action,
});

export const dispatchNotification = notification => {
  store.dispatch(notification);
};

export const addNotification = ({ title, message, isError }) => {
  const notificationType = isError ? error : success;
  store.dispatch(
    notificationType(
      notificationOpts({
        title: title || (isError ? 'Errore' : 'Successo'),
        message: message || (isError ? 'Si è verificato un errore' : ''),
        isError,
        action: null,
      }),
    ),
  );
};
