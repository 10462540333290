/*
 * NotesHub Messages
 *
 * This contains all the text for the NotesHub container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.NotesHub';

export default defineMessages({
  NotesHub: {
    id: `${scope}.NotesHub`,
    defaultMessage: 'NotesHub',
  },
  newNotesHub: {
    id: `${scope}.newNotesHub`,
    defaultMessage: 'New NotesHub',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  noteType: {
    id: `${scope}.noteType`,
    defaultMessage: 'Note Type',
  },
  new_note: {
    id: `${scope}.new_note`,
    defaultMessage: 'New Note',
  },
  exportNotesHub: {
    id: `${scope}.exportNotesHub`,
    defaultMessage: 'Export NotesHub',
  },
  toNotesHub: {
    id: `${scope}.toNotesHub`,
    defaultMessage: 'To NotesHub',
  },
  confirmNoteDelete: {
    id: `${scope}.confirmNoteDelete`,
    defaultMessage: 'Are you sure?',
  },
  confirmDeleteContent: {
    id: `${scope}.confirmDeleteContent`,
    defaultMessage:
      'You are going to delete one note data. Are you sure you want to proceed?',
  },
  subject_removed_success: {
    id: `${scope}.subject_removed_success`,
    defaultMessage: 'Note removed successfully!',
  },
  note_execute_success: {
    id: `${scope}.note_execute_success`,
    defaultMessage: 'Note executed succesfully!',
  },
  execution_note_text: {
    id: `${scope}.execution_note_text`,
    defaultMessage: 'Execution note',
  },
});
