import _ from 'lodash';
import request from 'shared/services/request';
import { API } from '../../../global-constants';

const getComune = text =>
  request({
    baseURL: API.BASE_URL,
    url: API.PROXY,
    method: 'POST',
    data: {
      method: 'GET',
      route: `municipality?filter[0][type]=like&filter[0][field]=denomination&filter[0][value]=${text}%`,
      microserviceName: 'municipality',
      params: {},
      headers: {},
    },
  }).then(res =>
    _.get(res, 'data._embedded.municipality', []).map(comune => ({
      value: comune.id,
      label: comune.denomination,
      cadastralCode: comune.cadastralCode,
      automotiveAbbreviation: comune.automotiveAbbreviation,
      denominationSupraMunicipal: comune.denominationSupraMunicipal,
    })),
  );

const getProvince = text =>
  request({
    baseURL: API.BASE_URL,
    url: API.PROXY,
    method: 'POST',
    data: {
      method: 'GET',
      route: `municipality?filter[0][type]=search_municipality&filter[0][province]=1&fields=provinceCode,denominationSupraMunicipal&filter[1][type]=like&filter[1][field]=denomination&filter[1][value]=${text}%`,
      microserviceName: 'municipality',
      params: {},
      headers: {},
    },
  }).then(res =>
    _.get(res, 'data._embedded.municipality', []).map(provincia => ({
      value: provincia.id,
      label: provincia.denominationSupraMunicipal,
    })),
  );

const calculateCF = ({
  name,
  surname,
  birthDate,
  gender,
  municipality,
  province,
}) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PROXY,
    method: 'POST',
    data: {
      method: 'POST',
      route: `fiscal-code-calculation`,
      microserviceName: 'municipality',
      params: {
        name,
        surname,
        birthDate,
        gender,
        municipality,
        province,
      },
      headers: {},
    },
  }).then(res => res.data);

const getComuneOptions = (inputValue, callback) => {
  getComune(inputValue).then(results => callback(results));
  // eslint-disable-next-line no-useless-return
  return;
};

const getProvinceOptions = (inputValue, callback) => {
  getProvince(inputValue).then(results => callback(results));
  // eslint-disable-next-line no-useless-return
  return;
};

const MunicipalityService = {
  getComune,
  getComuneOptions,
  getProvinceOptions,
  calculateCF,
};

export default MunicipalityService;
