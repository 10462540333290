/* eslint-disable prefer-destructuring */
import _ from 'lodash';
import appMessages from '../containers/App/messages';

export const getError = obj => {
  const validationErrorsObj = _.get(obj, 'data.validation_messages', {});
  return validationErrorsObj;
};

export const getEntityError = (error, intl) => {
  let message = '';
  if (error.status === 404) {
    message = intl.formatMessage(appMessages.forbidden);
  } else {
    message = error.data.detail;
  }
  return message;
};

export default getError;
