import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form, TextArea } from 'semantic-ui-react';
import { PaddedColumn } from '../../Layout';
import FormikField from '../../FormikField';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import messages from '../Anagrafica/messages';

import UserService from '../../../shared/services/users';

export const BaseForm = props => {
  const { isReadOnly = false } = props;

  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting}
    >
      <Grid columns="equal">
        <Grid.Row>
          <PaddedColumn width={16}>
            <FormikAsyncSelect
              {...props}
              name="assignee"
              label={props.intl.formatMessage(messages.assignee)}
              readOnly={isReadOnly}
              value={props.values.assignee}
              onChange={val => props.setFieldValue('assignee', val)}
              asyncSelectProps={{
                disabled: isReadOnly,
              }}
              loadOptions={(searchText, callback) =>
                UserService.getAsyncUserOptions(searchText, callback)
              }
            />
          </PaddedColumn>
          <PaddedColumn width={16}>
            <FormikAsyncSelect
              {...props}
              name="users"
              asyncSelectProps={{
                isMulti: true,
                disabled: isReadOnly,
              }}
              value={props.values.recipients || []}
              readOnly={isReadOnly}
              label={props.intl.formatMessage(messages.send_mail_to)}
              onChange={val => props.setFieldValue('users', val)}
              loadOptions={(searchText, callback) =>
                UserService.getAsyncUserOptions(searchText, callback)
              }
            />
          </PaddedColumn>
          <PaddedColumn width={16}>
            <FormikField
              readOnly={isReadOnly}
              required
              name="noteExecutionText"
              label={props.intl.formatMessage(messages.note)}
              {...props}
            >
              <TextArea
                onChange={(e, data) =>
                  props.setFieldValue('noteExecutionText', data.value)
                }
                value={_.get(props, 'values.noteExecutionText', '')}
                placeholder={props.intl.formatMessage(
                  messages.note_placeholder,
                )}
              />
            </FormikField>
          </PaddedColumn>
          <PaddedColumn width={8}>
            <FormikDate
              name="expireDate"
              label={props.intl.formatMessage(messages.expire_date)}
              disabled={isReadOnly}
              readOnly={isReadOnly}
            />
          </PaddedColumn>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
  isReadOnly: PropTypes.bool,
};

export default injectIntl(BaseForm);
