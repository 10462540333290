/*
 * Invoices Messages
 *
 * This contains all the text for the Invoices container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Invoices';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Invoices container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Invoices',
  },
  invoice_number: {
    id: `${scope}.invoice_number`,
    defaultMessage: 'Invoice number',
  },
  new_invoice: {
    id: `${scope}.new_invoice`,
    defaultMessage: 'New invoice',
  },
  invoice_header: {
    id: `${scope}.invoice_header`,
    defaultMessage: 'Invoice header',
  },
  invoice_rows: {
    id: `${scope}.invoice_rows`,
    defaultMessage: 'Invoice rows',
  },
  invoice_year: {
    id: `${scope}.invoice_year`,
    defaultMessage: 'Invoice year',
  },
  invoice_month: {
    id: `${scope}.invoice_month`,
    defaultMessage: 'Invoice month',
  },
  invoice_issuer: {
    id: `${scope}.invoice_issuer`,
    defaultMessage: 'Invoice issuer',
  },
  invoice_holder: {
    id: `${scope}.invoice_holder`,
    defaultMessage: 'Invoice holder',
  },
  invoice_sequential_number: {
    id: `${scope}.invoice_sequential_number`,
    defaultMessage: 'Invoice sequential number  ',
  },
  new_row: {
    id: `${scope}.new_row`,
    defaultMessage: 'New row',
  },
  row_removed_success: {
    id: `${scope}.row_removed_success`,
    defaultMessage: 'Invoice row removed successfully.',
  },
  row_number: {
    id: `${scope}.row_numer`,
    defaultMessage: 'Row number',
  },
  row_vat: {
    id: `${scope}.row_vat`,
    defaultMessage: 'Row VAT %',
  },
  row_total_amount: {
    id: `${scope}.row_total_amount`,
    defaultMessage: 'Total amount',
  },
  row_description: {
    id: `${scope}.row_description`,
    defaultMessage: 'Row description',
  },
  headquarter: {
    id: `${scope}.headquarter`,
    defaultMessage: 'Headquarter',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  taxable: {
    id: `${scope}.taxable`,
    defaultMessage: 'Taxable amount',
  },
  invoice_date: {
    id: `${scope}.invoice_date`,
    defaultMessage: 'Invoice date',
  },
  referenceDate: {
    id: `${scope}.referenceDate`,
    defaultMessage: 'Reference date',
  },
  referenceDateFrom: {
    id: `${scope}.referenceDateFrom`,
    defaultMessage: 'Reference date from',
  },
  referenceDateTo: {
    id: `${scope}.referenceDateTo`,
    defaultMessage: 'Reference date to',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  agents: {
    id: `${scope}.agents`,
    defaultMessage: 'Agents',
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Quantity',
  },
  netPrice: {
    id: `${scope}.netPrice`,
    defaultMessage: 'Net price',
  },
  grossPrice: {
    id: `${scope}.grossPrice`,
    defaultMessage: 'Gross price',
  },
  totalNetPrice: {
    id: `${scope}.totalNetPrice`,
    defaultMessage: 'Total net price',
  },
  totalAmount: {
    id: `${scope}.totalAmount`,
    defaultMessage: 'Total amount',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
});
