/* eslint-disable react/no-unused-state */
/**
 *
 * TopBar
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Menu, Dropdown, Image, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectStyles } from '../../containers/App/selectors';
import LogoImg from '../../images/logo-menu.png';
import LogoCollapsed from '../../images/logo-collapsed.png';
import Ticket from '../Ticket';
import Clock from '../Clock';
import MyselfModal from '../Forms/Myself';
import ChangePasswordModal from '../Forms/ChangePassword';
import messages from './messages';
import JiraService from '../../shared/services/tickets';
import AttachmentService from '../../shared/services/attachments';
import SearchDestination from '../SearchDestination';

// DropDown Menu with styles
const DropMenu = styled.div`
  border-left: 1px solid rgba(34, 36, 38, 0.1);
  z-index: 1000;
  & .ui.item.dropdown {
    height: 100%;
  }
  & i.icon {
    font-size: 18px;
    color: ${props => props.styles.SECOND_COLOR};
  }
  & .text {
    text-transform: capitalize !important;
    margin-right: 15px;
    padding-left: 15px;
  }

  & > .menu.transition {
    margin-top: 1px;
  }

  & .menu.transition > .menu.transition {
    border-top: 0px solid;
  }

  & .menu.transition > .menu.transition .item {
    border-bottom: 1px solid #eee;
  }

  & .menu.transition > .menu.transition .item:last-child {
    border-bottom: 0px solid;
  }
  & .right.menu .dropdown:last-child .menu {
    right: 0 !important;
    left: 0 !important;
  }
`;

// Menu container with styles
const StyledMenu = styled(Menu)`
  height: 65px !important;
  border-radius: 0px !important;
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;
  background-color: ${props => props.styles.SECOND_COLOR} !important;
  & .header.item {
    background-color: #ffffff;
  }
  & .header.item:hover {
    background-color: #ffffff !important;
  }
`;

const CollapseBTN = styled(Icon)`
  font-size: 17px !important;
  margin-top: 14px !important;
  margin-left: 16px !important;
  cursor: pointer !important;
  color: #6e6e6e !important;
  background-color: #fff !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  &:hover {
    color: #999 !important;
  }
`;

const LogoStyle = {
  left: '50%',
  top: '50%',
  transform: 'translate(calc(-50% + 0.5px), calc(-50% + 0.5px))', // Added .5px compensation to avoid blurred image on some accelerated GC
  imageRendering: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
};

const LogoStyleCollapsed = {
  left: '50%',
  top: '50%',
  transform: 'translate(calc(-50% + 0.5px), -50%)', // Added .5px compensation on translateX to avoid blurred image on some accelerated GC
  imageRendering: 'auto',
  maxHeight: '100%',
  maxWidth: '100%',
};

/* eslint-disable react/prefer-stateless-function */
class TopBar extends React.Component {
  state = {};

  onLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('scope');
    localStorage.removeItem('token_type');
    this.props.logout();
  };

  loadLogo() {
    if (!_.get(this.props, 'user.logo')) return;
    this.setState({ logoLoading: true });
    AttachmentService.download(_.get(this.props, 'user.logo'))
      .then(res => {
        // const contentDisposition = res.headers['content-disposition'];
        // const filename = contentDisposition.match(`filename="(.*)"`)[1];
        const newBlob = new Blob([res.data], { type: res.data.type });
        const fileUrl = window.URL.createObjectURL(newBlob);
        this.setState({ logoUrl: fileUrl, logoLoading: false });
      })
      .catch(() => this.setState({ logoLoading: false }));
  }

  loadLogoSmall() {
    if (!_.get(this.props, 'user.logosmall')) return;
    this.setState({ logoSmallLoading: true });
    AttachmentService.download(_.get(this.props, 'user.logosmall'))
      .then(res => {
        const newBlob = new Blob([res.data], { type: res.data.type });
        const fileUrl = window.URL.createObjectURL(newBlob);
        this.setState({ logoSmallUrl: fileUrl, logoSmallLoading: false });
      })
      .catch(() => this.setState({ logoSmallLoading: false }));
  }

  componentDidMount() {
    this.loadLogo();
    this.loadLogoSmall();
  }

  componentDidUpdate(prevProps) {
    if (_.get(prevProps, 'user.logo') !== _.get(this.props, 'user.logo')) {
      this.loadLogo();
    }
    if (
      _.get(prevProps, 'user.logosmall') !== _.get(this.props, 'user.logosmall')
    ) {
      this.loadLogoSmall();
    }
  }

  render() {
    const { styles } = this.props;

    // Menu item style (Styled Components does not style correctly this element)
    const MenuItemStyle = {
      width: this.props.menuCollapsed
        ? styles.SIDEBAR_COLLAPSED_WIDTH
        : styles.SIDEBAR_WIDTH,
      textAlign: 'center',
      padding: '3px',
      position: 'relative',
      display: 'block',
      borderRadius: '0px!important',
      margin: '-1px!important',
      marginTop: '-1px!important',
      marginBottom: '-1px!important',
    };

    return (
      <StyledMenu styles={this.props.styles}>
        <Menu.Item
          header
          as={Link}
          to="/"
          style={MenuItemStyle}
          className="headerLogo"
        >
          {this.props.menuCollapsed ? (
            <Image
              alt="logo"
              src={this.state.logoSmallUrl || LogoCollapsed}
              style={LogoStyleCollapsed}
            />
          ) : (
            <Image
              alt="logo"
              src={this.state.logoUrl || LogoImg}
              style={LogoStyle}
            />
          )}
        </Menu.Item>
        <CollapseBTN
          circular
          name={this.props.menuCollapsed ? 'chevron right' : 'chevron left'}
          onClick={
            this.props.menuCollapsed
              ? this.props.openMenu
              : this.props.closeMenu
          }
        />
        <Menu.Item>
          <SearchDestination history={this.props.history} />
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item style={{ flexFlow: 'wrap' }}>
            <Clock lang={_.get(this.props.user, 'defaultLanguage', 'en')} />
          </Menu.Item>
          <Menu.Item>
            <Ticket
              url="https://pm.novigo-consulting.it/rest/api/2"
              token="helpdesk.novigo:HelpdeskNovigo2019!!"
              currentUser="helpdesk.novigo"
              service={JiraService}
              user={this.props.user}
              projectId="10418"
              ticketType="10100"
              styles={this.props.styles}
            />
          </Menu.Item>
          <DropMenu styles={this.props.styles}>
            <Dropdown
              item
              trigger={
                <div style={{ marginRight: 10 }}>
                  <div style={{ color: '#fff' }}>
                    {`${_.get(this.props, 'user.firstName')} ${_.get(
                      this.props,
                      'user.lastName',
                    )}`}
                  </div>
                  <div style={{ fontSize: 9, color: '#fff' }}>
                    <FormattedMessage {...messages.user_profile} />:{' '}
                    {_.get(this.props, 'user.username')}
                  </div>
                </div>
              }
              icon="user circle outline"
            >
              <Dropdown.Menu>
                <Dropdown.Menu scrolling>
                  <MyselfModal
                    currentUser={_.get(this.props, 'user', {})}
                    updateMyself={this.props.updateMyself}
                    styles={styles}
                  />
                  <ChangePasswordModal styles={styles} />
                  <Dropdown.Item
                    key="LOGOUT"
                    text="Log out"
                    icon="log out"
                    onClick={this.onLogout}
                  />
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </DropMenu>
        </Menu.Menu>
      </StyledMenu>
    );
  }
}

TopBar.propTypes = {
  logout: PropTypes.func,
  menuCollapsed: PropTypes.bool,
  openMenu: PropTypes.func,
  closeMenu: PropTypes.func,
  user: PropTypes.object,
};

TopBar.contextTypes = {
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  styles: makeSelectStyles(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(TopBar);
