import React, { useState } from 'react';
import SimpleFormikField from 'novigo-simple-formik-field';
import CurrencyFormat from 'react-currency-format';
import _ from 'lodash';
import PropTypes from 'prop-types';

function AgentCurrencyFormatter(props) {
  const [currentValue, setCurrentValue] = useState('');
  const [touched, setTouched] = useState(false);

  return (
    <SimpleFormikField name={props.name} label={props.label} {...props}>
      {({ form: { setFieldValue, values } }) => (
        <div className="ui fluid input">
          <CurrencyFormat
            value={
              touched
                ? currentValue || ''
                : +_.get(values, `${props.name}`) || ''
            }
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            prefix="€ "
            onValueChange={valuesObj => {
              const { floatValue } = valuesObj; // also formattedValue, value..
              setTouched(true);
              setCurrentValue(floatValue);
            }}
            onBlur={() =>
              currentValue && setFieldValue(props.name, currentValue)
            }
          />
        </div>
      )}
    </SimpleFormikField>
  );
}

AgentCurrencyFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default AgentCurrencyFormatter;
