function Validation(values) {
  const errors = {};
  if (!values.state) {
    errors.state = 'Field required!';
  }
  if (!values.destination.value) {
    errors.state = 'Field required!';
  }
  return errors;
}

export default Validation;
