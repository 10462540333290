import { defineMessages } from 'react-intl';

export const scope = 'novigo.entity.grid.searchBar';

export default defineMessages({
  placeholder: {
    id: `${scope}.placeholder`,
    defaultMessage: 'Search',
  },
});
