/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { Async as AsyncSelect } from 'react-select';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form } from 'semantic-ui-react';
import AddressPicker from 'novigo-address-picker';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikField from '../../../FormikField';
import UserService from '../../../../shared/services/users';
import { SearchAddressWrapper, PaddedColumn } from '../../../Layout';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import FiscalCodeField from '../formParts/fiscalCodeField';
import FormikSelect from '../../../FormikSelect';
import FormikDate from '../../../FormikDate';
import FormikTelephone from '../../../FormikTelephone';
import FormikAsyncSelect from '../../../FormikAsyncSelect';

/**
 * TODO move out these elements using the ocnctants
 */
const sexOptions = [
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'F',
    value: 'F',
  },
];

const formatComuneWithProvincia = data =>
  data.birthProvinceShort
    ? `${data.birthCity} (${data.birthProvinceShort})`
    : data.birthCity;

function GeneralTab(props) {
  const {
    values,
    setFieldValue,
    readOnly = false,
    municipalityService,
    staffService,
  } = props;

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    staffService.getStaffRolesOptions().then(res => {
      setRoles(res);
    });
  }, []);

  return (
    <div>
      <Grid>
        <Grid.Row columns={3}>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="registrationNumber"
              label={props.intl.formatMessage(messages.registration_number)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="firstName"
              label={props.intl.formatMessage(messages.name)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="lastName"
              label={props.intl.formatMessage(messages.surname)}
              required
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikField
              name="role"
              label={props.intl.formatMessage(messages.role)}
              {...props}
            >
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={values.staffRoleId}
                name="staffRoleId"
                options={roles}
                onChange={data => setFieldValue('staffRoleId', data)}
                disabled={readOnly}
              />
            </FormikField>
          </PaddedColumn>
          <PaddedColumn>
            <FormikAsyncSelect
              {...props}
              name="user"
              label={props.intl.formatMessage(messages.user)}
              loadOptions={(searchText, callback) =>
                UserService.getAsyncUserOptions(searchText, callback)
              }
            />
          </PaddedColumn>
          <PaddedColumn style={{ zIndex: 999 }}>
            <FormikField
              name="birthCity"
              label={props.intl.formatMessage(messages.city_of_birth)}
              {...props}
            >
              <AsyncSelect
                cacheOptions
                defaultOptions
                value={{
                  label: formatComuneWithProvincia(values),
                  value: values.birthCity,
                }}
                loadOptions={_.debounce(
                  municipalityService.getComuneOptions,
                  1000,
                )}
                onChange={data => {
                  setFieldValue('birthCity', data.label);
                  setFieldValue(
                    'birthProvince',
                    data.denominationSupraMunicipal,
                  );
                  setFieldValue(
                    'birthProvinceShort',
                    data.automotiveAbbreviation,
                  );
                }}
              />
            </FormikField>
          </PaddedColumn>
          <PaddedColumn>
            <Grid>
              <Grid.Column width={8}>
                <FormikDate
                  {...props}
                  name="birthDate"
                  label={props.intl.formatMessage(messages.date_of_birth)}
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <FormikSelect
                  {...props}
                  name="gender"
                  options={sexOptions}
                  label={props.intl.formatMessage(messages.gender)}
                  defaultValue={
                    values.gender
                      ? { label: values.gender, value: values.gender }
                      : null
                  }
                  value={
                    values.gender
                      ? { label: values.gender, value: values.gender }
                      : null
                  }
                />
              </Grid.Column>
            </Grid>
          </PaddedColumn>
          {/* <PaddedColumn>
            <Grid>
              <Grid.Column width={16}>
                <SimpleFormikField
                  {...props}
                  name="birthCountry"
                  label={props.intl.formatMessage(messages.citizenship)}
                />
              </Grid.Column>
            </Grid>
          </PaddedColumn> */}

          <PaddedColumn>
            <FiscalCodeField {...props} />
          </PaddedColumn>

          <PaddedColumn>
            <Grid.Column>
              <FormikTelephone
                {...props}
                name="phoneNumber"
                label={props.intl.formatMessage(messages.phone_number)}
              />
            </Grid.Column>
          </PaddedColumn>
          <PaddedColumn>
            <Grid.Column>
              <FormikTelephone
                {...props}
                name="mobileNumber"
                label={props.intl.formatMessage(messages.mobile_number)}
              />
            </Grid.Column>
          </PaddedColumn>
          <PaddedColumn>
            <Grid.Column>
              <SimpleFormikField
                {...props}
                name="email"
                label="Email"
                type="email"
              />
            </Grid.Column>
          </PaddedColumn>
          <PaddedColumn>
            <Grid.Column>
              <SimpleFormikField
                {...props}
                name="emailPec"
                label="PEC"
                type="email"
              />
            </Grid.Column>
          </PaddedColumn>
        </Grid.Row>

        <Grid.Row columns={1}>
          <PaddedColumn style={{ zIndex: 888 }}>
            <Grid.Column width={16}>
              <Form.Field>
                <SearchAddressWrapper>
                  <AddressPicker
                    readOnly={readOnly}
                    name="residence_address"
                    search={values.residenceAddressSelectionType || 'auto'}
                    labels={{
                      autoBtn: props.intl.formatMessage(appMessages.automatic),
                      manualBtn: props.intl.formatMessage(appMessages.manual),
                      city: props.intl.formatMessage(appMessages.city),
                      province: props.intl.formatMessage(appMessages.province),
                      state: props.intl.formatMessage(appMessages.state),
                      postalCode: props.intl.formatMessage(
                        appMessages.postal_code,
                      ),
                      address: props.intl.formatMessage(appMessages.address),
                      autoPlaceholder: props.intl.formatMessage(
                        appMessages.search_an_address,
                      ),
                      auto: props.intl.formatMessage(
                        appMessages.residential_address,
                      ),
                    }}
                    element={{
                      address: values.residenceStreet,
                      title: values.residenceAddressTitle,
                      city: values.residenceCity,
                      province: values.residenceProvinceShort,
                      cap: values.residencePostalCode,
                      country: values.residenceCountry,
                      latitude: values.residenceLatitude,
                      longitude: values.residenceLongitude,
                    }}
                    onChange={(name, value) => {
                      const [latitude, longitude] = value.coords || [];

                      setFieldValue('residenceStreet', value.address);
                      setFieldValue('residenceCity', value.city);
                      setFieldValue('residenceProvinceShort', value.province);
                      setFieldValue('residencePostalCode', value.cap);
                      setFieldValue('residenceCountry', value.country);
                      setFieldValue('residenceAddressTitle', value.title);
                      setFieldValue('residenceLatitude', latitude);
                      setFieldValue('residenceLongitude', longitude);
                    }}
                    onSwitch={el =>
                      setFieldValue('residenceAddressSelectionType', el)
                    }
                    // eslint-disable-next-line no-unused-vars
                    onBlur={(name, value) => null}
                  />
                </SearchAddressWrapper>
              </Form.Field>
            </Grid.Column>
          </PaddedColumn>
        </Grid.Row>
        <Grid.Row columns={1}>
          <PaddedColumn>
            <Grid.Column width={16}>
              <Form.Field>
                <SearchAddressWrapper>
                  <AddressPicker
                    readOnly={readOnly}
                    name="non_residence_address"
                    search={values.nonResidenceAddressSelectionType || 'auto'}
                    labels={{
                      autoBtn: props.intl.formatMessage(appMessages.automatic),
                      manualBtn: props.intl.formatMessage(appMessages.manual),
                      city: props.intl.formatMessage(appMessages.city),
                      province: props.intl.formatMessage(appMessages.province),
                      state: props.intl.formatMessage(appMessages.state),
                      postalCode: props.intl.formatMessage(
                        appMessages.postal_code,
                      ),
                      address: props.intl.formatMessage(appMessages.address),
                      autoPlaceholder: props.intl.formatMessage(
                        appMessages.search_an_address,
                      ),
                      auto: props.intl.formatMessage(
                        appMessages.domicile_address,
                      ),
                    }}
                    element={{
                      address: values.nonResidenceStreet,
                      title: values.nonResidenceAddressTitle,
                      city: values.nonResidenceCity,
                      province: values.nonResidenceProvinceShort,
                      cap: values.nonResidencePostalCode,
                      country: values.nonResidenceCountry,
                      latitude: values.nonResidenceLatitude,
                      longitude: values.nonResidenceLongitude,
                    }}
                    onChange={(name, value) => {
                      const [latitude, longitude] = value.coords || [];
                      setFieldValue('nonResidenceStreet', value.address);
                      setFieldValue('nonResidenceCity', value.city);
                      setFieldValue(
                        'nonResidenceProvinceShort',
                        value.province,
                      );
                      setFieldValue('nonResidencePostalCode', value.cap);
                      setFieldValue('nonResidenceCountry', value.country);
                      setFieldValue('nonResidenceAddressTitle', value.title);
                      setFieldValue('nonResidenceLatitude', latitude);
                      setFieldValue('nonResidenceLongitude', longitude);
                    }}
                    onSwitch={el =>
                      setFieldValue('nonResidenceAddressSelectionType', el)
                    }
                    // eslint-disable-next-line no-unused-vars
                    onBlur={(name, value) => null}
                  />
                </SearchAddressWrapper>
              </Form.Field>
            </Grid.Column>
          </PaddedColumn>
        </Grid.Row>
      </Grid>
    </div>
  );
}

GeneralTab.defaultProps = {
  values: {},
  readOnly: false,
};

GeneralTab.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default injectIntl(GeneralTab);
