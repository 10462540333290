/* eslint-disable no-unused-vars */
/**
 *
 * Customer Totally REST manager
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import moment from 'moment';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import _ from 'lodash';
import ListManager from 'novigo-entity-grid';
import FormManager from 'novigo-form-manager';
import { Header, Label } from 'semantic-ui-react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

import makeSelectHeadquarter from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import appMessages from '../App/messages';

import { API } from '../../global-constants';
import MunicipalityService from '../../shared/services/municipality';
import { addNotification } from '../../utils/notification';

import request from '../../shared/services/request';

import CustomerForm, { validation } from '../../components/Forms/Anagrafica';
import { ListWrapper, Page } from '../../components/Layout';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import AnagraficaSearchBar from '../../components/SearchBars/shared/Anagrafica/SearchBar';
import ProfileService from '../../shared/services/profile';

/* eslint-disable react/prefer-stateless-function */
export class Headquarter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      aliases: [
        {
          type: 'leftjoin',
          field: 'destinations',
          alias: 'destination',
        },
        {
          type: 'leftjoin',
          field: 'agent1',
          alias: 'agent',
          parentalias: 'destination',
        },
        {
          type: 'groupBy',
          field: 'id',
        },
      ],
      filters: [],
      filterLabels: [],
      isUserAgent: false,
      userAgentId: '',
    };
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'view' ||
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'modify' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showForm: true });
    } else {
      this.setState({ showForm: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.evaluteAction();
    this.handleInitialFilters();
  }

  handleInitialFilters() {
    const { aliases, initialValues, filterLabels } = this.state;

    if (this.props.user) {
      ProfileService.getAgentOptionsByUserId(this.props.user.id, res => {
        if (res && res.length > 0) {
          filterLabels.push({
            key: 'isOnlyMine',
            name: this.context.intl.formatMessage(messages.onlyMine),
            value: this.context.intl.formatMessage(messages.yes),
            filterField: 'isOnlyMine',
          });
          aliases.push({
            type: 'orx',
            conditions: [
              {
                type: 'eq',
                where: 'or',
                field: 'agent1',
                value: res[0].value,
                alias: 'destination',
              },
              {
                type: 'eq',
                where: 'or',
                field: 'agent2',
                value: res[0].value,
                alias: 'destination',
              },
              {
                type: 'eq',
                where: 'or',
                field: 'subagent',
                value: res[0].value,
                alias: 'destination',
              },
            ],
            where: 'and',
          });

          this.setState(
            {
              aliases,
              initialValues,
              filterLabels,
              isUserAgent: true,
              userAgentId: res[0].value,
            },
            () => {
              this.setState(prev => ({ reloadIndex: prev.reloadIndex + 1 }));
            },
          );
        }
      });
    }
  }

  handleSearch = (filters, values, labels) =>
    new Promise(resolve => {
      const { aliases } = this.state;

      this.setState(prev => ({
        filters,
        initialValues: values,
        filterLabels: labels,
      }));

      resolve(filters);
    });

  transformData = data => ({
    ...data,
    birthDate: data.birthDate ? moment(data.birthDate) : null,
  });

  /**
   * Render show the list or the form dependent by the route
   */
  render() {
    return (
      <>
        <Helmet>
          <title>{this.context.intl.formatMessage(messages.title)}</title>
          <meta name="description" content="Customers" />
        </Helmet>
        <BreadcrumbsItem to="/headquarter">
          {this.context.intl.formatMessage(messages.title)}
        </BreadcrumbsItem>
        <Page>
          <Header as="h2" dividing>
            <FormattedMessage {...messages.title} />
          </Header>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/headquarter/edit">
                {actionToBreadcrumb({
                  action: this.props.match.params.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                key={this.props.match.params.action} // necessary to reload the current view upon redirect
                router={this.props} // necessary to use the internal routing
                client={pars =>
                  request(pars).then(res => this.transformData(res.data))
                }
                mask={props => (
                  <CustomerForm
                    {...props}
                    mainEntity="customer"
                    municipalityService={MunicipalityService}
                    editRoute={`/headquarter/${
                      this.props.match.params.id
                    }/modify`}
                    history={this.props.history}
                  />
                )}
                validation={validation}
                onCleanData={data => {
                  const cleanData = _.cloneDeep(data);

                  _.unset(cleanData, 'id');
                  _.unset(cleanData, '_links');
                  _.unset(cleanData, '_embedded');
                  _.unset(cleanData, 'displayName');
                  _.unset(cleanData, 'createdAt');
                  _.unset(cleanData, 'updatedAt');
                  _.unset(cleanData, 'deletedAt');
                  _.unset(cleanData, 'contacts');
                  _.unset(cleanData, 'addresses');
                  _.unset(cleanData, 'notes');
                  _.unset(cleanData, 'attachments');
                  _.unset(cleanData, 'staffWorkorders');
                  _.unset(cleanData, 'isProspect');
                  _.unset(cleanData, 'isActive');
                  _.unset(cleanData, 'duePayments');
                  _.unset(cleanData, 'mobileNumber');
                  _.unset(cleanData, 'readAt');
                  _.unset(cleanData, 'writeAt');
                  _.unset(cleanData, 'invoices');
                  _.unset(cleanData, 'invoiceDetail');
                  _.unset(cleanData, 'monitoringAttachment');
                  _.unset(cleanData, 'prospectCode');
                  _.unset(cleanData, 'customerCode');
                  _.unset(cleanData, 'code');
                  _.unset(cleanData, 'destinations');

                  cleanData.birthDate = cleanData.birthDate
                    ? cleanData.birthDate.format('YYYY-MM-DD')
                    : null;

                  return cleanData;
                }}
                data={
                  this.props.match.params.id === 'new' && {
                    registryType: 'L',
                    gender: 'M',
                  }
                }
                afterCreate={res => {
                  this.props.history.push(`/headquarter/${res.id}/modify`);
                }}
                afterUpdate={() => {}}
                afterDelete={() => this.props.history.push(`/headquarter`)}
                entityName="customer"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                onError={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  })
                }
                onSuccess={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
              />
            </>
          ) : (
            <ListWrapper>
              <ListManager
                canCreate={{
                  active: true,
                }}
                locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                translations={{
                  createButton: {
                    label: this.context.intl.formatMessage(
                      messages.new_headquarter,
                    ),
                  },
                }}
                sessionKey="headquarter"
                canExportExcel={{
                  active: true,
                }}
                canExport={{
                  exportLabelPrefix: this.context.intl.formatMessage(
                    appMessages.export_to,
                  ),
                }}
                entityName="customer"
                routerName="headquarter"
                aliases={this.state.aliases}
                basePath={API.BASE_URL}
                permissions={this.props.user}
                router={this.props}
                canSearchCustom={AnagraficaSearchBar({
                  intl: this.context.intl,
                  filters: this.state.filters,
                  initialSearch: {},
                  isHeadQuarter: true,
                  isUserAgent: this.state.isUserAgent,
                  userAgentId: this.state.userAgentId,
                  preSelectOnlyMine: this.state.isUserAgent,
                  removedFilter: this.state.removedFilter,
                  handleRemovedFilter: this.handleRemovedFilter,
                  initialValues: this.state.values,
                  onSearch: (filters, values, labels, customAliases) =>
                    this.handleSearch(filters, values, customAliases),
                })}
                defaultOrder={[
                  {
                    type: 'field',
                    field: 'createdAt',
                    direction: 'desc',
                  },
                ]}
                client={pars => request(pars).then(res => res.data)}
                columns={[
                  {
                    key: 'code',
                    name: this.context.intl.formatMessage(messages.code),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'displayName',
                    name: this.context.intl.formatMessage(messages.nominative),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'email',
                    name: this.context.intl.formatMessage(messages.email),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'phoneNumber',
                    name: this.context.intl.formatMessage(messages.telephone),
                    searchable: true,
                    sortable: false,
                  },
                ]}
                defaultActions={{
                  visualize: true,
                  modify: true,
                  delete: true,
                  grouped: false,
                  moreActions: [],
                }}
              />
            </ListWrapper>
          )}
        </Page>
      </>
    );
  }
}

Headquarter.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  headquarter: makeSelectHeadquarter(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'headquarter', reducer });
const withSaga = injectSaga({ key: 'headquarter', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Headquarter);

Headquarter.contextTypes = {
  intl: PropTypes.object.isRequired,
};
