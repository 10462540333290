import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.ORDER,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const patch = ({ id, ...content }) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER}/${id}`,
    method: 'PATCH',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (entityId, entity, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: entity,
          value: entityId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.ORDER,
    method: 'GET',
    params: {
      page: 1,
      pageSize: 8,
      'order-by': [
        {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOrders = (page, pageSize, filter, sort) =>
  request({
    baseURL: API.BASE_URL,
    url: API.ORDER,
    method: 'GET',
    params: {
      page,
      pageSize,
      filter,
      'order-by': sort || [
        {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const findOneById = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER}/${id}`,
    method: 'GET',
  });

const getStates = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.STATE_MACHINE,
    method: 'POST',
    data: {
      method: 'get-config',
      params: {
        module: 'order',
      },
    },
  });

const defaultStates = callback =>
  getStates().then(res => {
    const states = _.get(res, 'data.order-state.transitions', []);
    const keys = Object.keys(states);
    const responseStates = keys.map(item => ({
      label: item,
      value: item,
    }));
    callback(responseStates);
  });

const getAvailableStates = currentState =>
  getStates().then(res => {
    const transitions = _.get(res, 'data.order-state.transitions');
    const availableTransitions = [];
    if (currentState) {
      Object.keys(transitions).forEach(key => {
        if (transitions[key].from.includes(currentState)) {
          availableTransitions.push(key);
        }
      });
    } else {
      Object.keys(transitions).forEach(key => {
        availableTransitions.push(key);
      });
    }
    return availableTransitions;
  });

const getOrderLogs = (orderId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER_LOGS}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'objectId',
          value: orderId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getStateOptions = callback => {
  getStates().then(results => {
    const states = _.get(results, 'data.order-state.states', []);
    callback(
      [
        {
          value: null,
          label: 'TUTTI',
        },
      ].concat(
        Object.keys(states).map(state => ({
          value: state,
          label: state,
        })),
      ),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const getFilteredOrders = (
  isNew,
  agentCommissionElaboration,
  destination,
  createdFrom,
  createdTo,
  filterType,
  page,
) =>
  request({
    baseURL: API.BASE_URL,
    url: API.ORDER,
    method: 'GET',
    params: {
      page,
      pageSize: 5,
      filter: [
        isNew && {
          type: 'isnull',
          where: 'and',
          field: 'agentCommissionElaboration',
        },
        agentCommissionElaboration && {
          type: 'eq',
          where: 'and',
          field: 'agentCommissionElaboration',
          value: agentCommissionElaboration,
        },
        destination && {
          type: filterType,
          where: 'and',
          field: 'destination',
          value: destination,
        },
        createdFrom && {
          type: 'gte',
          where: 'and',
          field: 'referenceDate',
          value: `${createdFrom.format('YYYY-MM-DD')}`,
        },
        createdTo && {
          type: 'like',
          where: 'and',
          field: 'referenceDate',
          value: `${createdTo.format('YYYY-MM-DD')}`,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getGoodDiscount = filter =>
  request({
    baseURL: API.BASE_URL,
    url: API.VERIFY_GOODS_DISCOUNT,
    method: 'GET',
    params: {
      filter,
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getStatesForOrder = () =>
  getStates().then(res => {
    const states = _.get(res, 'data.order-state.states', []);
    const response = Object.keys(states);

    return response;
  });

const duplicate = data =>
  request({
    baseURL: API.BASE_URL,
    url: API.ORDER,
    method: 'POST',
    data,
  });

const OrderService = {
  getAll,
  findOneById,
  getStates,
  getAvailableStates,
  getStateOptions,
  getOrderLogs,
  getFilteredOrders,
  getGoodDiscount,
  getByOwner,
  create,
  update,
  patch,
  remove,
  getStatesForOrder,
  defaultStates,
  duplicate,
  getOrders,
};

export default OrderService;
