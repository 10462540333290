/*
 *
 * TechnicalIntervention actions
 *
 */

import { UPLOAD_IMAGE } from './constants';

export function uploadImage(images) {
  return {
    type: UPLOAD_IMAGE,
    images,
  };
}
