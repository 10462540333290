/*
 *
 * Offer constants
 *
 */
import { includes } from 'lodash';

export const DEFAULT_ACTION = 'app/Offer/DEFAULT_ACTION';

export const OFFER_STATE_INSERTED = 'Inserita';
export const OFFER_STATE_ACCEPTED = 'Approvata';
export const OFFER_STATE_NOT_ACCEPTED = 'Non Approvata';
export const OFFER_STATE_TO_BE_ACCEPTED = 'Da Autorizzare';
export const OFFER_STATE_WIN = 'Vinta';
export const OFFER_STATE_LOSS = 'Persa';
export const OFFER_STATE_ALL = 'TUTTI';

export const OFFER_STATES = [
  OFFER_STATE_INSERTED,
  OFFER_STATE_ACCEPTED,
  OFFER_STATE_NOT_ACCEPTED,
  OFFER_STATE_TO_BE_ACCEPTED,
  OFFER_STATE_WIN,
  OFFER_STATE_LOSS,
];

export const OFFER_TYPE_DERATIZATION = 'Derattizazione';
export const OFFER_TYPE_DISINFECTION = 'Disinfestazione';
export const OFFER_TYPE_HYGIENE = 'Igiene';
export const OFFER_TYPE_HACCP = 'HACCP';
export const OFFER_TYPE_PLANNING = 'Pianificazione';

export const OFFER_TYPES_FOR_SERVICES = [
  OFFER_TYPE_DERATIZATION,
  OFFER_TYPE_DISINFECTION,
  OFFER_TYPE_HYGIENE,
  OFFER_TYPE_HACCP,
  OFFER_TYPE_PLANNING,
];

export const OFFER_SERVICES_PRODUCT_CATEGORIES_ALLOWED = ['Servizi'];

export const isSellServicesOffer = type =>
  includes(OFFER_TYPES_FOR_SERVICES, type);

export const currencyFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});
