/* eslint-disable no-nested-ternary */
import _, { get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

export const handleHeaderLink = values => {
  const id = values.destination ? values.destination.value : null;
  if (id !== null) {
    return (
      <>
        <Link
          to={`/customer/${id}/view`}
          style={{ color: 'black', textDecorationLine: 'underline' }}
        >
          <h3>
            {get(values, 'destination.code', '--')} -{' '}
            {_.get(values, 'destination.businessName', '')}
          </h3>
        </Link>
        <br />
        <p>{_.get(values, 'destination.businessAddressTitle', '--')}</p>
      </>
    );
  }
  return (
    <>
      <h3>
        {get(values, 'destination.code', '--')} -{' '}
        {_.get(values, 'destination.businessName', '')}
      </h3>
      <br />
      <p>{_.get(values, 'destination.businessAddressTitle', '--')}</p>
    </>
  );
};
