/* eslint-disable indent */
/* eslint-disable prettier/prettier */
/**
 *
 * Tickets
 *
 */
import FullCalendar from '@fullcalendar/react';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/timegrid/main.css';
import enLocale from '@fullcalendar/core/locales/en-gb';
import itLocale from '@fullcalendar/core/locales/it';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Dimmer, Grid, Icon } from 'semantic-ui-react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import EventForm from '../../components/Forms/Event';
import eventMessages from '../../components/Forms/Event/messages';
// import makeSelectTickets from './selectors';
// import { makeSelectStyles } from '../App/selectors';
import { Wrapper } from '../../components/Layout';
import Modal from '../../components/Modal';
import agendaService from '../../shared/services/agenda';
import workorderService from '../../shared/services/staffworkorder';
import { addNotification } from '../../utils/notification';
import appMessages from '../App/messages';
import './calendarStyle.css';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';

// const BoldContent = styled.div`
//   width: 33%;
//   margin: 0 10px 0 10px;
//   word-break: break-word;
// `;

// const ExtraContent = styled.div`
//   min-width: 80%;
//   display: flex;
//   margin-left: 10px;
//   margin-right: 70px;
//   flex: 1;
//   justify-content: space-between;
// `;

export class Agenda extends React.Component {
  // eslint-disable-line

  state = {
    height: false,
    modalOpened: false,
    event: {},
    events: [],
    isLoading: false,
  };

  currStart = null;

  currEnd = null;

  langSwitch = lang => ({ it: itLocale, en: enLocale }[lang] || enLocale); // Default if none of the above matches

  handleDateClick = () => {
    this.setState({ modalOpened: true });
  };

  handleEventClick = data => {
    const {
      event: { extendedProps, start, end, id },
    } = data;
    let event = null;
    if (extendedProps.eventType === 'appointment') {
      event = {
        id,
        title: extendedProps.subject,
        note: extendedProps.note,
        customer: extendedProps.customer,
        state: extendedProps.state,
        agent: extendedProps.agent,
        eventType: extendedProps.eventType,
        startDate: start,
        endDate: end,
        destination: extendedProps.destination,
      };
    }

    if (extendedProps.eventType === 'workorder') {
      event = {
        id,
        number: extendedProps.number,
        amount: extendedProps.amount,
        note: extendedProps.note,
        plannedDate: extendedProps.plannedDate,
        state: extendedProps.state,
        customer: extendedProps.customer,
        workorderType: extendedProps.workorderType,
        staff: extendedProps.staff,
        destination: extendedProps.destination,
        destinationContact: extendedProps.destinationContact,
        eventType: extendedProps.eventType,
        startDate: start,
        endDate: end,
      };
    }

    this.setState({
      modalOpened: true,
      event,
    });
  };

  handleEventDrop = data => {
    const {
      event: { extendedProps, start, end, id },
    } = data;

    const payload =
      extendedProps.eventType === 'appointment'
        ? {
            agent: _.get(extendedProps, 'agent.id', null),
            customer: _.get(extendedProps, 'customer.id', null),
            startDate: start
              ? moment(start).format('YYYY-MM-DD HH:mm:ss')
              : null,
            state: _.get(extendedProps, 'state', null),
            endDate: end ? moment(end).format('YYYY-MM-DD HH:mm:ss') : null,
            subject: _.get(extendedProps, 'subject', null),
            note: _.get(extendedProps, 'note', null),
            id: id || null,
            destination: _.get(extendedProps, 'destination.id', null),
          }
        : {
            customer: _.get(extendedProps, 'customer.id', null),
            workorderType: _.get(extendedProps, 'workorderType.id', null),
            staff: _.get(extendedProps, 'staff.id', null),
            destination: _.get(extendedProps, 'destination.id', null),
            destinationContact: _.get(
              extendedProps,
              'destinationContact.id',
              null,
            ),
            state: _.get(extendedProps, 'state', null),
            startDate: start
              ? moment(start).format('YYYY-MM-DD HH:mm:ss')
              : null,
            endDate: end ? moment(end).format('YYYY-MM-DD HH:mm:ss') : null,
            plannedDate: extendedProps.plannedDate
              ? moment(extendedProps.plannedDate).format('YYYY-MM-DD HH:mm')
              : null,
            note: _.get(extendedProps, 'note', null),
            number: _.get(extendedProps, 'number', null),
            amount: _.get(extendedProps, 'amount', null),
            id: id || null,
          };

    const service =
      extendedProps.eventType === 'appointment'
        ? agendaService
        : workorderService;
    const method = payload.id ? service.update : service.create;

    method(payload)
      .then(() => {
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: this.context.intl.formatMessage(
            eventMessages.event_date_changed_success,
          ),
          isError: false,
        });
      })
      .catch(err => {
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  closeModal = () => {
    this.setState({ modalOpened: false });
  };

  loadData = (startD, endD) => {
    const startDate = moment(startD).format('YYYY-MM-DD HH:mm:ss'); // currentDate.startOf('month').format('YYYY-MM-DD HH:mm:ss');
    const endDate = moment(endD).format('YYYY-MM-DD HH:mm:ss'); // currentDate.endOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.setState({ isLoading: true });
    agendaService
      .getBetween(startDate, endDate)
      .then(res =>
        _.get(res, 'data._embedded.appointment', []).map(event => ({
          id: event.id,
          title: event.subject,
          note: event.note,
          subject: event.subject,
          state: event.state,
          customer: _.get(event, '_embedded.customer', {}),
          agent: _.get(event, '_embedded.agent', {}),
          start: _.get(event, 'startDate.date', ''),
          end: _.get(event, 'endDate.date', ''),
          eventType: 'appointment',
          destination: _.get(event, '_embedded.destination', {}),
        })),
      )
      .then(appointments => {
        this.setState({ events: appointments });
        workorderService
          .getBetween(startDate, endDate)
          .then(res =>
            _.get(res, 'data._embedded.staff-workorder', []).map(event => ({
              id: event.id,
              title: event.note,
              number: event.number,
              amount: event.amount,
              plannedDate: _.get(event, 'plannedDate.date', ''),
              state: event.state,
              note: event.note,
              customer: _.get(event, '_embedded.customer', {}),
              workorderType: _.get(event, '_embedded.workorderType', {}),
              staff: _.get(event, '_embedded.staff', {}),
              destination: _.get(event, '_embedded.destination', {}),
              destinationContact: _.get(
                event,
                '_embedded.destinationContact',
                {},
              ),
              start: _.get(event, 'startDate.date', ''),
              end: _.get(event, 'endDate.date', ''),
              color: 'red',
              eventType: 'workorder',
            })),
          )
          .then(workorders =>
            this.setState(prevState => ({
              ...prevState,
              events: [...prevState.events, ...workorders],
              isLoading: false,
            })),
          );
      });
  };

  setHeight = () =>
    this.setState({
      height:
        Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0,
        ) - 147,
    });

  componentDidMount() {
    window.addEventListener('resize', this.setHeight, true);
    this.setHeight();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setHeight, true);
  }

  renderEventContent = eventInfo => {
    // eslint-disable-next-line prefer-destructuring
    const eventType = eventInfo.event.extendedProps.eventType;
    if (eventInfo.view.type === 'listWeek') {
      return (
        <>
          <div className="leftDetail">
            <div
              className="circle"
              style={{
                backgroundColor:
                  eventType === 'appointment' ? '#3788d8' : 'red',
              }}
            />
            <p>
              <b>{eventInfo.timeText}</b>
              <i>{eventInfo.event.title || ''}</i>
            </p>
          </div>

          <div className="rightDetail">
            <div>
              {_.get(eventInfo, 'event.extendedProps.customer.displayName')}
            </div>
            <div>
              {_.get(eventInfo, 'event.extendedProps.destination.businessName')}
            </div>

            <div>{_.get(eventInfo, 'event.extendedProps.state')}</div>
          </div>
        </>
      );
    }
    return (
      <div
        style={{
          backgroundColor: eventType === 'appointment' ? '#3788d8' : 'red',
        }}
      >
        <p className="textAgenda">
          <b>{eventInfo.timeText}</b>
          <i style={{ marginLeft: '15px' }}>{eventInfo.event.title}</i>
        </p>
      </div>
    );
  };

  callbackFunction = info => {
    const {
      view: { currentStart, currentEnd },
    } = info;
    const formatStart = moment(currentStart).format('YYYY-MM-DD HH:mm:ss');
    const formatEnd = moment(currentEnd).format('YYYY-MM-DD HH:mm:ss');
    if (formatStart !== this.currStart || formatEnd !== this.currEnd) {
      this.currStart = formatStart;
      this.currEnd = formatEnd;
      this.loadData(currentStart, currentEnd);
    }
  };

  reloadData = () => this.loadData(this.currStart, this.currEnd);

  render() {
    return (
      <>
        <Wrapper style={{ margin: '1rem 0' }}>
          <BreadcrumbsItem to="/agenda">
            {this.context.intl.formatMessage(messages.title)}
          </BreadcrumbsItem>
          <Grid.Row columns={1}>
            <Grid.Column stretched largeScreen="1">
              <FullCalendar
                height={this.state.height || 'auto'}
                plugins={[
                  dayGridPlugin,
                  timeGridPlugin,
                  interactionPlugin,
                  listPlugin,
                ]}
                headerToolbar={{
                  left: 'prev,next,today',
                  center: 'title',
                  right:
                    'dayGridMonth,timeGridWeek,timeGridDay,listWeek,newEventButton',
                }}
                initialView="listWeek"
                editable
                edit
                aspectRatio={16 / 9}
                locale={this.langSwitch(
                  _.get(this.props, 'user.defaultLanguage'),
                )}
                datesSet={info => this.callbackFunction(info)}
                events={this.state.events}
                customButtons={{
                  newEventButton: {
                    text: this.context.intl.formatMessage(messages.new_event),
                    click: () =>
                      this.setState({
                        modalOpened: true,
                        event: { eventType: 'appointment' },
                      }),
                    color: 'blue',
                  },
                }}
                dateClick={this.handleDateClick}
                eventClick={this.handleEventClick}
                eventDrop={this.handleEventDrop}
                eventResize={this.handleEventDrop}
                eventContent={this.renderEventContent}
              />
              <Modal
                open={this.state.modalOpened}
                onClose={() => {
                  this.closeModal();
                }}
                title={
                  <h2>
                    <Icon name="calendar" />{' '}
                    {this.context.intl.formatMessage(messages.event)}
                  </h2>
                }
                size="tiny"
                scrolling
              >
                <EventForm
                  close={this.closeModal}
                  initialValues={this.state.event}
                  update={this.reloadData}
                />
              </Modal>
              <Dimmer inverted active={this.state.isLoading} />
            </Grid.Column>
          </Grid.Row>
        </Wrapper>
      </>
    );
  }
}

Agenda.propTypes = {
  user: PropTypes.object, // eslint-disable-line
};

Agenda.contextTypes = {
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'tickets', reducer });
const withSaga = injectSaga({ key: 'tickets', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Agenda);
