/* eslint-disable indent */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { IntlShape, TranslationsShape } from './Shapes';
import messages from './messages/CounterLabelMessages';

const CounterLabels = ({
  counter,
  selectedCounter,
  intl,
  translations = {},
}) => {
  const { active: counterIsActive = false, number = 0 } = counter;

  const {
    active: selectedCounterIsActive = false,
    selectedNumber = 0,
    showSelected = true,
  } = selectedCounter;

  const { counters = {} } = translations;

  const {
    elementName = intl.formatMessage(messages.elementName),
    selectedLabel = intl.formatMessage(messages.selectedLabel),
  } = counters;

  return (
    <>
      {counterIsActive && (
        <span style={{ marginLeft: 10 }}>{`${number} ${elementName}`}</span>
      )}
      {selectedCounterIsActive &&
        showSelected &&
        selectedNumber !== 0 && (
          <span
            style={{ marginLeft: 5 }}
          >{`| ${selectedNumber} ${selectedLabel}`}</span>
        )}
    </>
  );
};

CounterLabels.defaultProps = {
  counter: null,
  selectedCounter: null,
  intl: {},
};

CounterLabels.propTypes = {
  counter: PropTypes.object,
  selectedCounter: PropTypes.object,
  intl: IntlShape,
  // eslint-disable-next-line react/require-default-props
  translations: TranslationsShape,
};

export default injectIntl(CounterLabels);
