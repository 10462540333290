import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ListManager from 'novigo-entity-grid';
import { injectIntl, intlShape } from 'react-intl';

import moment from 'moment';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch } from '../../../Layout';

import messages from '../../Anagrafica/messages';
import appMessages from '../../../../containers/App/messages';

const LinkedDestinationTab = props => {
  const columns = () =>
    [
      {
        key: 'customerCode',
        name: props.intl.formatMessage(messages.customerCode),
        formatter: ({ data }) => _.get(data, 'customer.customerCode'),
        searchable: false,
        useAlias: 'customer',
      },
      {
        key: 'description',
        name: props.intl.formatMessage(messages.destinationState),
        formatter: ({ data }) => _.get(data, 'destinationState.description'),
        useAlias: 'destinationState',
      },
      {
        key: 'destinationCode',
        name: props.intl.formatMessage(messages.destinationCode),
        sortable: true,
      },
      {
        key: 'businessName',
        name: props.intl.formatMessage(messages.destinationArea),
        sortable: true,
      },
      {
        key: 'businessCity',
        name: props.intl.formatMessage(messages.birthCity),
        sortable: true,
        formatter: ({ data }) =>
          _.get(data, 'businessCity', _.get(data, 'residenceCity', '')),
      },
      {
        key: 'businessAddressTitle',
        name: props.intl.formatMessage(messages.address),
        sortable: true,
        formatter: ({ data }) =>
          _.get(
            data,
            'businessAddressTitle',
            _.get(data, 'residenceAddressTitle', ''),
          ),
      },
      {
        key: 'lastOrderDate',
        name: props.intl.formatMessage(messages.lastOrderDate),
        sortable: true,
        formatter: ({ data }) =>
          data.lastOrderDate
            ? moment(_.get(data, 'lastOrderDate')).format('DD-MM-YYYY')
            : '--',
      },
    ].filter(c => c);

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <ListManager
          entityName="destination"
          basePath={API.BASE_URL}
          locale={_.get(props, 'user.defaultLanguage', 'it')}
          permissions={props.user}
          router={props}
          client={pars => request(pars).then(res => res.data)}
          aliases={[
            {
              type: 'innerjoin',
              field: 'customer',
              alias: 'customer',
            },
            {
              type: 'leftjoin',
              field: 'destinationState',
              alias: 'destinationState',
            },
          ]}
          columns={columns()}
          canCreate={{
            active: false,
          }}
          canExport={{
            active: false,
          }}
          defaultActions={{
            visualize: false,
            modify: false,
            delete: false,
            grouped: false,
            moreActions: [
              !props.readOnly && {
                icon: 'edit',
                label: props.intl.formatMessage(appMessages.edit),
                key: 'edit',
                action: data => {
                  props.history.push(`/destination/${data.id}/modify`);
                },
              },
              {
                icon: 'eye',
                label: props.intl.formatMessage(appMessages.action_view),
                key: 'visualize',
                action: data => {
                  props.history.push(`/destination/${data.id}/view`);
                },
              },
            ].filter(x => x),
          }}
          persistentFilter={[
            _.has(props, 'values.customer.value') && {
              type: 'eq',
              field: 'customer',
              value: props.values.customer.value,
            },
            {
              type: 'neq',
              field: 'id',
              value: props.values.id,
            },
          ].filter(o => o)}
        />
      </TableGlobalSearch>
    </ListWrapper>
  );
};

LinkedDestinationTab.defaultProps = {
  values: {},
};

LinkedDestinationTab.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(LinkedDestinationTab);
