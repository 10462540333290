/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import Table from 'semantic-table-grid';
import styled from 'styled-components';
import messages from '../messages';
import NoteService from '../../../../shared/services/note';
import UsersService from '../../../../shared/services/users';
import StaffNoteService from '../../../../shared/services/staffnote';
import { RemoveBtn, EditBtn, ModalNewBtn } from '../../../Buttons';
import { WrapperTool } from '../../../Layout';
import Modal from '../../../Modal';
import NoteForm from '../formParts/EmployeeNote';
import { addNotification } from '../../../../utils/notification';
import appMessages from '../../../../containers/App/messages';

const TableOverflow = styled.div`
  display: block;
  margin: -14px -14px -10px;
  overflow-x: auto;
  padding: 14px;
  & td {
    position: relative;
  }
`;

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function NotesTab(props) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [noteTypes, setNoteTypes] = useState([]);
  const [noteReasons, setNoteReasons] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);
  const { entity } = props;

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      props.values.id && loadData();
    },
    [props.values.id, paginationData.page],
  );

  useEffect(() => {
    NoteService.getNoteTypeOptions().then(res => {
      setNoteTypes(res);
    });
    NoteService.getReasonTypeOptions().then(res => {
      setNoteReasons(res);
    });
    UsersService.getUserOptions().then(res => {
      setUsersList(res);
    });
  }, []);

  const loadData = page => {
    setLoading(true);
    StaffNoteService.getByOwner(
      props.values.id,
      page || paginationData.page,
    ).then(res => {
      const data = _.get(res, 'data', { ...paginationDefault });
      setPaginationData({
        page: data.page,
        page_count: data.page_count,
        page_size: data.page_size,
        total_items: data.total_items,
      });
      setLoading(false);
      setItems(_.get(res, `data._embedded.${entity}-note`), []);
    });
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalOpened(false);
  };

  const openModal = () => {
    setSelectedItem({});
    setModalOpened(true);
  };

  const columns = () =>
    [
      {
        key: 'noteNumber',
        name: props.intl.formatMessage(messages.noteNumber),
      },
      {
        key: 'date',
        name: props.intl.formatMessage(messages.date),
        formatter: ({ data }) =>
          _.get(data, 'createdAt.date', null)
            ? moment(data.createdAt.date).format('DD-MM-YYYY HH:mm')
            : null,
      },
      {
        key: 'user',
        name: props.intl.formatMessage(messages.user),
        formatter: ({ data }) =>
          `${_.get(data, '_embedded.staff.displayName', '')}`,
      },
      {
        key: 'noteText',
        name: props.intl.formatMessage(messages.note),
      },
      !props.readOnly && {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 80 }}>
            <EditBtn
              action={() => {
                setSelectedItem(data);
                setModalOpened(true);
              }}
            />
            <RemoveBtn
              action={() => {
                StaffNoteService.remove(data.id)
                  .then(() => {
                    loadData();
                    addNotification({
                      title: null,
                      message: props.intl.formatMessage(
                        messages.note_removed_success,
                      ),
                      isError: false,
                    });
                  })
                  .catch(error =>
                    addNotification({
                      title: null,
                      message: error.data.detail,
                      isError: true,
                    }),
                  );
              }}
            />
          </div>
        ),
        width: '80px',
      },
    ].filter(o => o);

  return (
    <div>
      <TableOverflow>
        <Table
          elements={items}
          rowClassKey="role-row-class"
          columns={columns()}
          canSort={{
            active: true,
          }}
          isLoading={loading}
          canPaginate={{
            active: paginationData.total_items > 0,
            pageCount: paginationData.page_count,
            pageSize: paginationData.page_size,
            totalItems: paginationData.total_items,
            page: paginationData.page,
            onSelect: page => {
              setPaginationData({
                ...paginationData,
                page,
              });
            },
          }}
          canAction={{
            active: !props.readOnly,
            actions: [
              <WrapperTool key="wrapper">
                <Modal
                  open={modalOpened}
                  onClose={() => {
                    closeModal();
                  }}
                  title={
                    <h2>
                      <Icon name="sticky note" />{' '}
                      {props.intl.formatMessage(messages.new_note)}
                    </h2>
                  }
                  trigger={
                    <ModalNewBtn onClick={openModal}>
                      {props.intl.formatMessage(messages.new_note)}
                    </ModalNewBtn>
                  }
                  size="mini"
                  style={{ width: 650 }}
                  scrolling
                >
                  <NoteForm
                    entityId={props.values.id}
                    entity={props.entity}
                    staffNoteService={StaffNoteService}
                    noteTypes={noteTypes}
                    noteReasons={noteReasons}
                    usersList={usersList}
                    close={closeModal}
                    initialValues={selectedItem}
                    update={() => loadData(1)}
                  />
                </Modal>
              </WrapperTool>,
            ],
          }}
          hiddenHeaderIfEmpty
          emptyResults={
            <div>{props.intl.formatMessage(appMessages.no_results)}</div>
          }
        />
      </TableOverflow>
    </div>
  );
}

NotesTab.defaultProps = {
  values: {},
  readOnly: false,
};

NotesTab.propTypes = {
  values: PropTypes.object,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  entity: PropTypes.string,
};

export default injectIntl(NotesTab);
