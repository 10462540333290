/*
 * Customer note Messages
 *
 * This contains all the text for the Customer note container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.CustomerExecutionNote';

export default defineMessages({
  note_saved: {
    id: `${scope}.note_saved`,
    defaultMessage: 'Note successfully saved',
  },
  note_execute_success: {
    id: `${scope}.note_execute_success`,
    defaultMessage: 'Note successfully executed.',
  },
});
