import SimpleFormikField from 'novigo-simple-formik-field';
import React from 'react';
import moment from 'moment';
import { Grid } from 'semantic-ui-react';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import FormikDate from '../../components/FormikDate';
import { PaddedColumn } from '../../components/Layout';
import CustomerService from '../../shared/services/customer';
import ProductService from '../../shared/services/product';
import appMessages from '../App/messages';
import messages from './messages';
import InvoiceTypologyService from '../../shared/services/invoicetypology';

export const InvoiceFilters = (intl, hasCustomerFilter = true) => ({
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  formStructure: (
    <>
      <Grid.Column>
        <SimpleFormikField
          name="number"
          label={intl.formatMessage(messages.invoice_number)}
        />
      </Grid.Column>
      {hasCustomerFilter && (
        <Grid.Column>
          <FormikAsyncSelect
            name="customer"
            label={intl.formatMessage(messages.headquarter)}
            loadOptions={(searchText, callback) =>
              CustomerService.getOptions(searchText, callback)
            }
          />
        </Grid.Column>
      )}
      <Grid.Column width={2}>
        <FormikDate
          name="referenceDateFrom"
          label={intl.formatMessage(messages.referenceDateFrom)}
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <FormikDate
          name="referenceDateTo"
          label={intl.formatMessage(messages.referenceDateTo)}
        />
      </Grid.Column>

      <PaddedColumn>
        <FormikAsyncSelect
          name="product"
          label={intl.formatMessage(messages.product)}
          entityName="product"
          loadOptions={(searchText, callback) =>
            ProductService.getOptions(searchText, callback)
          }
        />
      </PaddedColumn>
      <PaddedColumn>
        <FormikAsyncSelect
          name="typology"
          label={intl.formatMessage(messages.type)}
          entityName="invoice_typology"
          loadOptions={InvoiceTypologyService.getOptions}
        />
      </PaddedColumn>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];

    if (values.number) {
      filterLabels.push({
        key: 'number',
        name: intl.formatMessage(messages.invoice_number),
        value: values.number,
        filterField: 'documentNumber',
      });
      filters.push({
        type: 'like',
        where: 'and',
        field: 'documentNumber',
        value: `%${values.number}%`,
      });
    }

    if (values.destination) {
      filterLabels.push({
        key: 'destination',
        name: intl.formatMessage(messages.destination),
        value: values.destination.label,
        filterField: 'id',
      });
      filters.push({
        type: 'like',
        where: 'and',
        alias: 'destination',
        field: 'id',
        value: `%${values.destination.value}%`,
      });
    }

    if (values.referenceDateFrom) {
      filterLabels.push({
        key: 'referenceDateFrom',
        name: intl.formatMessage(messages.referenceDateFrom),
        value: `${moment(values.referenceDateFrom).format('YYYY-MM-DD')} `,
        filterField: 'referenceDateFrom',
      });
      filters.push({
        type: 'gte',
        where: 'and',
        field: 'referenceDate',
        value: `${moment(values.referenceDateFrom).format(
          'YYYY-MM-DD',
        )} 00:00:00`,
        format: 'Y-m-d H:i:s',
      });
    }

    if (values.referenceDateTo) {
      filterLabels.push({
        key: 'referenceDateTo',
        name: intl.formatMessage(messages.referenceDateTo),
        value: `${moment(values.referenceDateTo).format('YYYY-MM-DD')}`,
        filterField: 'referenceDate',
      });
      filters.push({
        type: 'lte',
        where: 'and',
        field: 'referenceDate',
        value: `${moment(values.referenceDateTo).format(
          'YYYY-MM-DD',
        )} 23:59:59`,
        format: 'Y-m-d H:i:s',
      });
    }

    if (hasCustomerFilter && values.customer) {
      filterLabels.push({
        key: 'customer',
        name: intl.formatMessage(messages.customer),
        value: values.customer.label,
        filterField: 'customer',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'id',
        alias: 'customer',
        value: `${values.customer.value}`,
      });
    }

    // if (values.agent) {
    //   filterLabels.push({
    //     key: 'agent',
    //     name: intl.formatMessage(messages.agent),
    //     value: values.agent.label,
    //     filterField: 'agent',
    //   });
    //   filters.push({
    //     type: 'eq',
    //     where: 'and',
    //     field: 'agent',
    //     alias: 'agent',
    //     value: `${values.agent.value}`,
    //   });
    // }

    if (values.product) {
      filterLabels.push({
        key: 'product',
        name: intl.formatMessage(messages.product),
        value: values.product.label,
        filterField: 'product',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'id',
        alias: 'product',
        value: `${values.product.value}`,
      });
    }

    if (values.typology) {
      filterLabels.push({
        key: 'typology',
        name: intl.formatMessage(messages.type),
        value: values.typology.label,
        filterField: 'typology',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'id',
        alias: 'typology',
        value: `${values.typology.value}`,
      });
    }

    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
});
