import React from 'react';
import SimpleFormikField from 'novigo-simple-formik-field';
import _ from 'lodash';
import { DateTimePicker } from 'novigo-date-time-picker';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { makeSelectLocale } from '../../containers/LanguageProvider/selectors';

function FormikDateTime(props) {
  return (
    <SimpleFormikField name={props.name} label={props.label} {...props}>
      {({
        field: { value },
        form: { setFieldValue, setFieldTouched, initialValues }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <DateTimePicker
          format="DD/MM/YYYY HH:mm"
          autoOk
          value={value ? moment(value) : null}
          locale={_.get(props, 'locale')}
          name={props.name}
          onChange={internalValue => {
            setFieldTouched(
              props.name,
              _.get(initialValues, 'props.name', false) &&
                !initialValues[props.name].isSame(internalValue),
            );
            setFieldValue(props.name, internalValue);
          }}
          disabled={props.readOnly}
        />
      )}
    </SimpleFormikField>
  );
}

FormikDateTime.propTypes = {
  locale: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
};

const mapStateToProps = createSelector(makeSelectLocale(), locale => ({
  locale,
}));

export default connect(
  mapStateToProps,
  null,
)(FormikDateTime);
