/**
 *
 * NotesHub
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

// import { FormattedMessage } from 'react-intl';
import { Form /* , Button, Grid, Icon */ } from 'semantic-ui-react';

// import { Field } from 'formik';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormActionsBar from '../../FormActionsBar';

import messages from './messages';

import validation from './validation';

// eslint-disable-next-line react/prefer-stateless-function
export class NotesHub extends React.Component {
  render() {
    const {
      handleSubmit,
      // setFieldValue,
      // values,
      readOnly = false,
      isSubmitting = false,
      isValid = false,
      onDelete = false,
      isDeleting = false,
      showDeleteButton = false,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <SimpleFormikField
          {...this.props}
          name="description"
          label={this.context.intl.formatMessage(messages.description)}
          required
        />
        <FormActionsBar
          showDeleteButton={showDeleteButton}
          readOnly={readOnly}
          isValid={isValid}
          isDeleting={isDeleting}
          isSubmitting={isSubmitting}
          editRoute={this.props.editRoute}
          onDelete={onDelete}
          history={this.props.history}
        />
      </Form>
    );
  }
}

NotesHub.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

NotesHub.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default NotesHub;

export { validation };
