import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';
import {
  formatDropdownOptions,
  formatProfileOptions,
} from '../../../utils/formatDropdownOptions';

const getByOwner = (entityId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'customer',
          value: entityId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const searchByName = (searchText, customerId) =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'businessName',
          value: `%${searchText}%`,
        },
        searchText && {
          type: 'like',
          where: 'or',
          field: 'destinationCode',
          value: `%${searchText}%`,
        },
        customerId && {
          type: 'eq',
          field: 'customer',
          value: customerId,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOwnerOptions = (inputValue, customerId, callback) => {
  searchByName(inputValue, customerId).then(results => {
    callback(
      _.get(results, 'data._embedded.destination', []).map(destination => ({
        value: destination.id,
        label: `${destination.destinationCode} - ${destination.businessName}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const getOwnerOptionsExtendedData = (
  inputValue,
  customerId,
  callback,
  setValuesForDestination,
) => {
  searchByName(inputValue, customerId).then(results => {
    const res = _.get(results, 'data._embedded.destination', []).map(
      destination => ({
        value: destination.id,
        label: `${destination.destinationCode} - ${destination.businessName}`,
        commercialDirector: formatProfileOptions(
          destination.profiles,
          'commercialDirector',
        ),
        rawLabel: destination.businessName || '',
        areaManager: formatProfileOptions(destination.profiles, 'areaManager'),
        agent1: formatProfileOptions(destination.profiles, 'agent1'),
        agent2: formatProfileOptions(destination.profiles, 'agent2'),
        subagent: formatProfileOptions(destination.profiles, 'subagent'),
        buyingGroup: _.get(destination, '_embedded.buyingGroup')
          ? formatDropdownOptions(destination, 'buyingGroup', true)
          : null,
        signaller: formatProfileOptions(destination.profiles, 'signaller'),
      }),
    );
    if (setValuesForDestination) setValuesForDestination(res);
    callback(res);
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchByTerm = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_CUSTOMER,
    method: 'GET',
    params: {
      search: `%${searchText}%`,
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getJoinedOptions = (inputValue, callback) => {
  searchByTerm(inputValue).then(results => {
    callback(
      _.get(results, 'data.payload', [])
        .map(
          item =>
            item.type !== 'headquarter' && {
              value: item.id,
              label: [item.code, item.label].filter(o => o).join(' - '),
              type: item.type,
            },
        )
        .filter(o => o),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const CustomerDestinationService = {
  searchByName,
  getByOwner,
  getOwnerOptions,
  getJoinedOptions,
  getOwnerOptionsExtendedData,
};

export default CustomerDestinationService;
