import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT_TYPE,
    method: 'GET',
  });

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT_TYPE,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'type',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'type',
          direction: 'asc',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.agent-type', []).map(item => ({
        value: item.id,
        label: item.type,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const AgentTypeService = {
  getAll,
  getOptions,
};

export default AgentTypeService;
