import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.Agent';

export default defineMessages({
  // General data
  tab_general_data: {
    id: `${scope}.tab_general_data`,
    defaultMessage: 'General Data',
  },
  codeAdHoc: {
    id: `${scope}.codeAdHoc`,
    defaultMessage: 'Code AD-HOC',
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Code',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Business Name',
  },
  businessName_required: {
    id: `${scope}.businessName_required`,
    defaultMessage: 'Business Name is required',
  },
  vatNumber: {
    id: `${scope}.vatNumber`,
    defaultMessage: 'Vat Number',
  },
  vatNumber_required: {
    id: `${scope}.vatNumber_required`,
    defaultMessage: 'Vat Number is required',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First Name',
  },
  firstName_required: {
    id: `${scope}.firstName_required`,
    defaultMessage: 'First Name is required',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last Name',
  },
  lastName_required: {
    id: `${scope}.lastName_required`,
    defaultMessage: 'Last Name is required',
  },
  agentType: {
    id: `${scope}.agentType`,
    defaultMessage: 'Type',
  },
  agentType_required: {
    id: `${scope}.agentType_required`,
    defaultMessage: 'Agent type is required',
  },
  residence_required: {
    id: `${scope}.residence_required`,
    defaultMessage: 'Residence is required',
  },
  fiscalCode: {
    id: `${scope}.fiscalCode`,
    defaultMessage: 'Fiscal Code',
  },

  ratesFraming: {
    id: `${scope}.ratesFraming`,
    defaultMessage: 'Rates Level',
  },
  uploadContract: {
    id: `${scope}.uploadContract`,
    defaultMessage: 'Upload Contract',
  },
  downloadContract: {
    id: `${scope}.downloadContract`,
    defaultMessage: 'Download Contract',
  },
  documents: {
    id: `${scope}.documents`,
    defaultMessage: 'Documents',
  },
  new_planned: {
    id: `${scope}.new_planned`,
    defaultMessage: 'New Planned',
  },
  planned_saved: {
    id: `${scope}.planned_saved`,
    defaultMessage: 'Planned sales saved successfully!',
  },
  month: {
    id: `${scope}.month`,
    defaultMessage: 'Month',
  },
  planned: {
    id: `${scope}.planned`,
    defaultMessage: 'Planned',
  },
  billed: {
    id: `${scope}.billed`,
    defaultMessage: 'Billed',
  },
  deviation: {
    id: `${scope}.deviation`,
    defaultMessage: 'Deviation',
  },
  codeAdHoc_required: {
    id: `${scope}.codeAdHoc_required`,
    defaultMessage: 'Code field is required!',
  },
  fiscalCode_required: {
    id: `${scope}.fiscalCode_required`,
    defaultMessage: 'Fiscal code is required!',
  },
  ratesFraming_required: {
    id: `${scope}.ratesFraming_required`,
    defaultMessage: 'Rates framing is required!',
  },
  reportingRate: {
    id: `${scope}.reportingRate`,
    defaultMessage: 'Reporting rate',
  },
  tab_commissions: {
    id: `${scope}.tab_commissions`,
    defaultMessage: 'Commissions',
  },

  requiredField: {
    id: `${scope}.requiredField`,
    defaultMessage: 'This field is required',
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: 'User',
  },
  budget: {
    id: `${scope}.budget`,
    defaultMessage: 'Budget',
  },
  inspector: {
    id: `${scope}.inspector`,
    defaultMessage: 'Inspector',
  },
});
