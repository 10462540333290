import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the kibana state domain
 */

const selectKibanaDomain = state => state.get('kibana', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Kibana
 */

const makeSelectKibana = () =>
  createSelector(selectKibanaDomain, substate => substate.toJS());

export default makeSelectKibana;
export { selectKibanaDomain };
