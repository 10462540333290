import request from 'shared/services/request';
import { API } from '../../../global-constants';

const getResources = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.RESOURCES,
    method: 'GET',
    params: {
      'page-size': 100,
    },
  });

const ResourceService = {
  getResources,
};

export default ResourceService;
