import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Grid, Button, Icon, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import WorkorderPlannedSelectionPart from './formParts/workorderPlannedSelectionPart';
import WorkorderUnplannedSelectionPart from './formParts/workorderUnplannedSelectionPart';
import { PaddedColumn } from '../../Layout';
import messages from './messages';
import FormikDate from '../../FormikDate';
import StaffWorkOrderService from '../../../shared/services/staffworkorder';
import appMessages from '../../../containers/App/messages';
import { addNotification } from '../../../utils/notification';
import { WorkorderAssignmentContext } from './context';
import './css/technicalIntervention.css';

export const WorkorderAssignmentForm = props => {
  const [isPlanning, setPlanning] = useState(false);
  const [isUnplanning, setUnplanning] = useState(false);
  const [reloadPlanned, doReloadPlanned] = useState(0);
  const [reloadUnplanned, doReloadUnplanned] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState();
  const [selectedWorkorderToPlan, setSelectedWorkorderToPlan] = useState([]);
  const [selectedWorkorderPlanned, setSelectedWorkorderPlanned] = useState([]);

  /**
   * Handle the selection change for the work-orders to be planned.
   * @param {*} selectedRows Selected work-orders.
   */
  const handleSelectionToPlan = selectedRows => {
    setSelectedWorkorderToPlan(selectedRows);
  };

  /**
   * Manages the plan action for the selected work-orders to plan.
   * @param {*} values
   */
  const planSelectedWorkorder = values => {
    const data = {
      plannedDate: moment(values.plannedDate).format('YYYY-MM-DD HH:mm:ss'),
      staff: selectedStaff,
      state: 'Pianificato',
      ids: selectedWorkorderToPlan,
    };
    setPlanning(true);

    StaffWorkOrderService.updateWorkorderPlan(data)
      .then(() => {
        setPlanning(false);
        doReloadPlanned(prev => !prev);
        doReloadUnplanned(prev => !prev);
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: props.intl.formatMessage(messages.workorder_planned_success),
          isError: false,
        });
      })
      .catch(error => {
        setPlanning(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.message',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      })
      .finally(() => {
        setSelectedWorkorderPlanned([]);
        setSelectedWorkorderToPlan([]);
      });
  };

  /**
   * Manages the plan cancelation action for the selected already planned work-orders.
   */
  const unplanSelectedWorkorder = () => {
    const data = {
      plannedDate: null,
      staff: null,
      state: 'Da pianificare',
      ids: selectedWorkorderPlanned,
    };
    setUnplanning(true);

    StaffWorkOrderService.updateWorkorderPlan(data)
      .then(response => {
        setUnplanning(false);
        doReloadPlanned(prev => prev + 1);
        doReloadUnplanned(prev => prev + 1);
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: _.get(
            response,
            'data.message',
            props.intl.formatMessage(
              appMessages.operation_performed_successfully,
            ),
          ),
          isError: false,
        });
      })
      .catch(error => {
        setUnplanning(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.message',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      })
      .finally(() => {
        setSelectedWorkorderPlanned([]);
        setSelectedWorkorderToPlan([]);
      });
  };

  return (
    <WorkorderAssignmentContext.Provider
      value={{
        selectedStaff,
        setSelectedStaff,
        selectedWorkorderToPlan,
        setSelectedWorkorderToPlan,
        selectedWorkorderPlanned,
        setSelectedWorkorderPlanned,
      }}
    >
      <Formik
        initialValues={{ staff: null, plannedDate: null }}
        onSubmit={planSelectedWorkorder}
      >
        {formikProps => (
          <>
            <Form onSubmit={formikProps.handleSubmit}>
              <Grid>
                <Grid.Row>
                  <PaddedColumn width={2}>
                    <FormikDate
                      required
                      name="plannedDate"
                      label={props.intl.formatMessage(messages.planned_date)}
                    />
                  </PaddedColumn>
                  <PaddedColumn width={2}>
                    <Button
                      loading={isPlanning}
                      disabled={
                        !formikProps.values.plannedDate ||
                        !selectedStaff ||
                        selectedWorkorderToPlan.length === 0
                      }
                      type="submit"
                      icon
                      color="green"
                      labelPosition="left"
                    >
                      <Icon name="check" />
                      {props.intl.formatMessage(messages.execute_plan)}
                    </Button>
                  </PaddedColumn>
                  <PaddedColumn width={2}>
                    <Button
                      style={{ marginLeft: 10 }}
                      loading={isUnplanning}
                      disabled={selectedWorkorderPlanned.length === 0}
                      type="button"
                      icon
                      color="red"
                      labelPosition="left"
                      onClick={unplanSelectedWorkorder}
                    >
                      <Icon name="check" />
                      {props.intl.formatMessage(messages.cancel_plan)}
                    </Button>
                  </PaddedColumn>
                </Grid.Row>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <div
          style={{ flex: '0 0 50%', width: '50%' }}
          className="workorderUnplanned"
        >
          <Formik
            initialValues={{ destinationFilter: null }}
            onSubmit={() => {}}
            render={formikProps => (
              <WorkorderUnplannedSelectionPart
                {...formikProps}
                onSelectedRowsChange={value => handleSelectionToPlan(value)}
                reload={reloadUnplanned}
                onSearch={() => doReloadPlanned(prev => prev + 1)}
              />
            )}
          />
        </div>
        <div
          style={{
            flex: '0 0 50%',
            width: '50%',
          }}
          className="workorderPlanned"
        >
          <WorkorderPlannedSelectionPart
            reload={reloadPlanned}
            onSearch={() => doReloadUnplanned(prev => prev + 1)}
            onStaffChanged={() => doReloadPlanned(prev => prev + 1)}
          />
        </div>
      </div>
    </WorkorderAssignmentContext.Provider>
  );
};

export default injectIntl(WorkorderAssignmentForm);
