/*
 * SideBar Messages
 *
 * This contains all the text for the SideBar component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Forms.Myself';

export default defineMessages({
  successMessage: {
    id: `${scope}.successMessage`,
    defaultMessage: 'Your profile has been updated.',
  },
  myProfile: {
    id: `${scope}.myProfile`,
    defaultMessage: 'My profile',
  },
  editProfile: {
    id: `${scope}.editProfile`,
    defaultMessage: 'Edit your profile',
  },
});
