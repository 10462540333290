/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Grid, Icon, Divider } from 'semantic-ui-react';
import Styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import SimpleFormikField from 'novigo-simple-formik-field';
import messages from './messages';
import FormikSelect from '../../FormikSelect';
import FormikToggle from '../../FormikToggle';
import validation from './validation';

const PaddedInfo = Styled.div`
  padding: 15px 15px 0px 15px;
`;
export default class RoleForm extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    role: PropTypes.array,
    isMyself: PropTypes.bool,
    styles: PropTypes.object,
  };

  getRoleOptions = roles =>
    roles.map(role => ({
      value: role.id,
      label: role.roleId,
    }));

  render() {
    const { isMyself } = this.props;

    return (
      <Grid>
        {isMyself && (
          <Grid.Column width={16} style={{ marginBottom: 0 }}>
            <Grid>
              <Grid.Column width={8}>
                <PaddedInfo>
                  <Icon name="user" /> {this.props.values.lastName}{' '}
                  {this.props.values.firstName}
                </PaddedInfo>
                <PaddedInfo>
                  <Icon name="map marker alternate" />{' '}
                  {this.props.values.country || '--'}
                </PaddedInfo>
                <PaddedInfo>
                  <Icon name="mobile" /> {this.props.values.phoneNumber || '--'}
                </PaddedInfo>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        )}
        <Grid.Column width={16} style={{ marginTop: -16, marginBottom: -32 }}>
          <Divider />
        </Grid.Column>
        <Grid.Column width={16}>
          <Form
            autoComplete="off"
            onSubmit={this.props.handleSubmit}
            loading={this.props.isSubmitting}
            style={{ padding: 16 }}
          >
            <Grid>
              {!isMyself && (
                <Grid.Column width={16}>
                  <FormikToggle
                    {...this.props}
                    name="active"
                    label={this.props.intl.formatMessage(messages.active)}
                  />
                </Grid.Column>
              )}
              {!isMyself && (
                <Grid.Column width={8}>
                  <SimpleFormikField
                    {...this.props}
                    required
                    name="firstName"
                    label={this.props.intl.formatMessage(messages.name)}
                  />
                </Grid.Column>
              )}
              {!isMyself && (
                <Grid.Column width={8}>
                  <SimpleFormikField
                    {...this.props}
                    required
                    name="lastName"
                    label={this.props.intl.formatMessage(messages.surname)}
                  />
                </Grid.Column>
              )}
              <Grid.Column width={8}>
                <SimpleFormikField
                  {...this.props}
                  required
                  type="email"
                  name="email"
                  label={this.props.intl.formatMessage(messages.email)}
                />
              </Grid.Column>
              {!isMyself && (
                <Grid.Column width={8}>
                  <SimpleFormikField
                    {...this.props}
                    name="phoneNumber"
                    label={this.props.intl.formatMessage(messages.phone)}
                  />
                </Grid.Column>
              )}
              <Grid.Column width={8}>
                <FormikSelect
                  {...this.props}
                  label={this.props.intl.formatMessage(messages.language)}
                  isLoading={false}
                  options={[
                    {
                      value: 'en',
                      label: 'English',
                    },
                    {
                      value: 'it',
                      label: 'Italiano',
                    },
                  ]}
                  defaultValue={this.props.values.defaultLanguage}
                  value={this.props.values.defaultLanguage}
                  name="defaultLanguage"
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  onChange={target => {
                    this.props.setFieldValue('defaultLanguage', target);
                  }}
                />
              </Grid.Column>
              {!isMyself && (
                <Grid.Column width={8}>
                  <SimpleFormikField
                    {...this.props}
                    name="country"
                    label={this.props.intl.formatMessage(messages.country)}
                  />
                </Grid.Column>
              )}
              {!isMyself && (
                <Grid.Column width={8}>
                  <SimpleFormikField
                    {...this.props}
                    required
                    name="username"
                    label={this.props.intl.formatMessage(messages.username)}
                  />
                </Grid.Column>
              )}
              {!isMyself && (
                <Grid.Column width={8}>
                  <SimpleFormikField
                    {...this.props}
                    type="password"
                    name="plainPassword"
                    label={this.props.intl.formatMessage(
                      messages.plainPassword,
                    )}
                  />
                </Grid.Column>
              )}
              {!isMyself && (
                <Grid.Column width={16}>
                  <FormikSelect
                    {...this.props}
                    isMulti
                    label={this.props.intl.formatMessage(messages.roles)}
                    isLoading={false}
                    options={
                      this.props.role
                        ? this.props.role.map(userrole => ({
                          label: userrole.roleId, // eslint-disable-line
                          value: userrole.id, // eslint-disable-line
                        })) // eslint-disable-line
                        : []
                    }
                    value={this.props.values.role}
                    name="roles"
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={data => {
                      this.props.setFieldValue('role', data);
                    }}
                  />
                </Grid.Column>
              )}
              <Grid.Column width={16}>
                <div
                  style={{
                    backgroundColor: this.props.styles.SEMANTIC_UI_ACTION_GREY,
                    margin: 'auto -16px -16px -16px',
                    padding: 16,
                    borderTop: '1px solid #d8d9da',
                    borderRadius: '0px 0px 4px 4px',
                    textAlign: 'right',
                  }}
                >
                  <Button type="submit" color="green">
                    <Icon name="save" /> <FormattedMessage {...messages.save} />
                  </Button>
                </div>
              </Grid.Column>
            </Grid>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export { validation };
