/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Button, Icon, Grid } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import moment from 'moment';
import messages from '../../messages';
// import { WrapperTool } from '../../../../Layout';
// import { addNotification } from '../../../../../utils/notification';
import { ModalFakeActions } from '../../../../Layout';
import appMessages from '../../../../../containers/App/messages';
import StaffWorkOrderLogService from '../../../../../shared/services/staffworkorderlogs';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function StateHistoryModal(props) {
  const [items, setItems] = useState([]);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);
  const { entity, onClose } = props;

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      loadData();
    },
    [paginationData.page],
  );

  const loadData = page => {
    setLoading(true);
    StaffWorkOrderLogService.getByOwner(
      props.entityId,
      page || paginationData.page,
    ).then(res => {
      const data = _.get(res, 'data', { ...paginationDefault });
      setPaginationData({
        page: data.page,
        page_count: data.page_count,
        page_size: data.page_size,
        total_items: data.total_items,
      });
      setLoading(false);
      setItems(_.get(res, `data._embedded.${entity}-logs`), []);
    });
  };

  const columns = () =>
    [
      {
        key: 'loggedAt',
        name: props.intl.formatMessage(messages.date),
        formatter: ({ data }) =>
          _.get(data, 'loggedAt.date', null)
            ? moment(data.loggedAt.date).format('DD/MM/YYYY')
            : null,
      },
      {
        key: 'username',
        name: props.intl.formatMessage(messages.username),
      },
      {
        key: 'data',
        name: props.intl.formatMessage(messages.state),
        formatter: ({ data }) => _.get(data, 'data.state', null),
      },
    ].filter(o => o);

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Table
              elements={items}
              rowClassKey="role-row-class"
              columns={columns()}
              canSort={{
                active: true,
              }}
              isLoading={loading}
              canPaginate={{
                active: paginationData.total_items > 0,
                pageCount: paginationData.page_count,
                pageSize: paginationData.page_size,
                totalItems: paginationData.total_items,
                page: paginationData.page,
                onSelect: page => {
                  setPaginationData({
                    ...paginationData,
                    page,
                  });
                },
              }}
              hiddenHeaderIfEmpty
              emptyResults={
                <div>{props.intl.formatMessage(appMessages.no_results)}</div>
              }
            />
          </Grid.Column>
        </Grid.Row>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button type="button" icon labelPosition="left" onClick={onClose}>
              <Icon name="remove" />
              {props.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
        </ModalFakeActions>
      </Grid>
    </div>
  );
}

StateHistoryModal.propTypes = {
  entityId: PropTypes.string,
  onClose: PropTypes.func,
  intl: intlShape.isRequired,
  entity: PropTypes.string,
};

export default injectIntl(StateHistoryModal);
