import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_PACKAGING_DIMENSION,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_PACKAGING_DIMENSION}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_PACKAGING_DIMENSION}/${id}`,
    method: 'DELETE',
  });

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_PACKAGING_DIMENSION,
    method: 'GET',
    params: {
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const searchByDescription = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_PACKAGING_DIMENSION,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByDescription(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.product-packaging-dimension', []).map(
        state => ({
          value: state.id,
          label: state.description,
        }),
      ),
    );
  });
};

const ProductPackagingDimensionService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
};

export default ProductPackagingDimensionService;
