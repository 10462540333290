/* eslint-disable */
import { injectIntl } from 'react-intl';
import messages from '../containers/App/messages';

const convert = ({action, intl }) => ({
    "view": intl.formatMessage(messages.action_view),
    "delete": intl.formatMessage(messages.action_delete),
    "modify": intl.formatMessage(messages.action_modify),
    "manage": intl.formatMessage(messages.action_modify),
  })[action] || intl.formatMessage(messages.action_new);

  export default convert;

