import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_PAYMENT_TERM,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_PAYMENT_TERM}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_PAYMENT_TERM}/${id}`,
    method: 'DELETE',
  });

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_PAYMENT_TERM,
    method: 'GET',
    params: {},
  });

const searchByDescription = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_PAYMENT_TERM,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByDescription(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.destination-payment-term', []).map(
        paymentTerm => ({
          value: paymentTerm.id,
          label: `${paymentTerm.code} - ${paymentTerm.description}`,
        }),
      ),
    );
  });
};

const searchByDestinationIdAndDescription = (searchText, destinationId) =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_PAYMENT_TERM,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
        {
          type: 'eq',
          field: 'destination',
          value: destinationId,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptionsByDestinationId = (inputValue, destinationId, callback) => {
  searchByDestinationIdAndDescription(inputValue, destinationId).then(
    results => {
      callback(
        _.get(results, 'data._embedded.destination-payment-term', []).map(
          paymentTerm => ({
            value: paymentTerm.id,
            label: `${paymentTerm.code} - ${paymentTerm.description}`,
          }),
        ),
      );
    },
  );
  // eslint-disable-next-line no-useless-return
  return;
};

const DestinationPaymentTermService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
  getOptionsByDestinationId,
};

export default DestinationPaymentTermService;
