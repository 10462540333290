import React from 'react';
import { IntlProvider } from 'react-intl';
import EntityGrid from './components/index';
import { GridShape } from './components/Shapes';
import { translationMessages } from './i18n';

// eslint-disable-next-line react/prefer-stateless-function
class LocalizedGrid extends React.Component {
  static propTypes = GridShape;

  render() {
    const { locale = 'en' } = this.props;

    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={translationMessages[locale]}
        textComponent={React.Fragment}
      >
        <EntityGrid {...this.props} />
      </IntlProvider>
    );
  }
}

export default LocalizedGrid;
