/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/**
 *
 * SimpleFormikForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Form, Icon } from 'semantic-ui-react';
import get from 'lodash/get';
import forOwn from 'lodash/forOwn';

function getErrorMessages(errorMessages) {
  return errorMessages.map(error => (
    <span
      key={`${error.fieldError}${error.value}`}
      style={{ color: 'red', marginRight: 3 }}
    >
      <Icon disabled name="warning sign" />
      {error.value}
    </span>
  ));
}

/**
 * A simple Formik Input manager with Error
 * @param {*} props
 */
function FormikField(props) {
  const {
    readOnly = false,
    errors = [],
    name = '',
    label = '',
    required = false,
    touched = {},
  } = props;

  const fieldError = get(errors, name);
  const errorMessages = [];

  /**
   * if is composite get Error or is a String from FE
   */
  if (typeof fieldError !== 'string') {
    forOwn(fieldError, value => errorMessages.push({ fieldError, value }));
  } else {
    errorMessages.push(fieldError);
  }

  return (
    <Form.Field
      disabled={readOnly}
      error={touched[name] && Boolean(fieldError)}
    >
      <label htmlFor={name}>
        {label || name} {required && <span style={{ color: 'red' }}>*</span>}
      </label>
      {props.children}
      {touched[name] && getErrorMessages(errorMessages)}
    </Form.Field>
  );
}

FormikField.defaultProps = {
  label: '',
  readOnly: false,
  errors: [],
  required: false,
  touched: {},
};

FormikField.propTypes = {
  /**
   * The name of the input, used by formik for creating the Object related
   */
  name: PropTypes.string.isRequired,
  /**
   * The label shown on the top on the field
   */
  label: PropTypes.string,
  /**
   * ReadOnly property passed to the element.
   * Id is a custom element it Need to be managed by the component
   */
  readOnly: PropTypes.bool,
  /**
   * Is the Errors array generated by Formik.
   * String or Composite Object are managed.
   */
  errors: PropTypes.object,
  /**
   * Is the Touched field array generated by Formik.
   * String or Composite Object are managed.
   */
  touched: PropTypes.object,
  /**
   * If is required the props is passed to the element and a * is added
   * to the original label
   */
  required: PropTypes.bool,
  /**
   * Children to be wrapped
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default FormikField;
