/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import { RemoveBtn, EditBtn, ModalNewBtn } from '../../../Buttons';
import { WrapperTool } from '../../../Layout';
import Modal from '../../../Modal';
import CommissionContractForm from '../../DestinationCommission';
import { addNotification } from '../../../../utils/notification';
import messages from '../messages';
import currencyFormatter from '../../../../utils/currencyFormatter';
import percentageFormatter from '../../../../utils/percentageFormatter';
import appMessages from '../../../../containers/App/messages';
import DestinationInvoiceAmountService from '../../../../shared/services/destinationinvoiceamount';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function InvoiceAmountPart(props) {
  const [commissions, setCommissions] = useState([]);
  const [selectedCommission, setSelectedCommission] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);
  const [isAmount] = useState(true);

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      props.values.id && loadData();
    },
    [props.values.id, paginationData.page],
  );

  const loadData = page => {
    setLoading(true);
    DestinationInvoiceAmountService.getByOwner(
      props.values.id,
      page || paginationData.page,
    ).then(res => {
      const data = _.get(res, 'data', { ...paginationDefault });
      setPaginationData({
        page: data.page,
        page_count: data.page_count,
        page_size: data.page_size,
        total_items: data.total_items,
      });
      setLoading(false);
      setCommissions(
        _.get(res, `data._embedded.destination-invoice-amount-commission`),
        [],
      );
    });
  };

  const closeModal = () => {
    setSelectedCommission(null);
    setModalOpened(false);
  };

  const openModal = () => {
    setSelectedCommission({});
    setModalOpened(true);
  };

  const columns = () =>
    [
      {
        key: 'startDate',
        name: props.intl.formatMessage(messages.commissionStartDate),
        formatter: ({ data }) =>
          _.get(data, 'startDate.date', null)
            ? moment(data.startDate.date).format('DD-MM-YYYY')
            : null,
      },
      {
        key: 'endDate',
        name: props.intl.formatMessage(messages.commissionEndDate),
        formatter: ({ data }) =>
          _.get(data, 'endDate.date', null)
            ? moment(data.endDate.date).format('DD-MM-YYYY')
            : null,
      },
      {
        key: 'fromAmount',
        name: props.intl.formatMessage(messages.commissionFrom),
        formatter: ({ data }) => {
          const value = _.get(data, 'fromAmount', 0.0) / 100 || 0.0;
          return currencyFormatter.format(value);
        },
      },
      {
        key: 'toAmount',
        name: props.intl.formatMessage(messages.commissionTo),
        formatter: ({ data }) => {
          const value = _.get(data, 'toAmount', 0.0) / 100 || 0.0;
          return currencyFormatter.format(value);
        },
      },
      {
        key: 'commission',
        name: props.intl.formatMessage(messages.commission),
        formatter: ({ data }) => {
          const value = _.get(data, 'commission', 0.0) / 100 || 0.0;
          return percentageFormatter.format(value);
        },
      },
      {
        key: 'refund',
        name: props.intl.formatMessage(messages.refund),
        formatter: ({ data }) => {
          const value = _.get(data, 'refund', 0.0) / 100 || 0.0;
          return percentageFormatter.format(value);
        },
      },
      !props.readOnly && {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 80 }}>
            <EditBtn
              action={() => {
                setSelectedCommission(data);
                setModalOpened(true);
              }}
            />
            <RemoveBtn
              action={() => {
                DestinationInvoiceAmountService.remove(data.id)
                  .then(() => {
                    loadData();
                    addNotification({
                      title: null,
                      message: props.intl.formatMessage(
                        messages.subject_removed_success,
                      ),
                      isError: false,
                    });
                  })
                  .catch(error =>
                    addNotification({
                      title: null,
                      message: error.data.detail,
                      isError: true,
                    }),
                  );
              }}
              {...props}
            />
          </div>
        ),
        width: '80px',
      },
    ].filter(o => o);

  return (
    <div>
      <Table
        elements={commissions}
        rowClassKey="role-row-class"
        columns={columns()}
        isLoading={loading}
        canPaginate={{
          active: paginationData.total_items > 0,
          pageCount: paginationData.page_count,
          pageSize: paginationData.page_size,
          totalItems: paginationData.total_items,
          page: paginationData.page,
          onSelect: page => {
            setPaginationData({
              ...paginationData,
              page,
            });
          },
        }}
        canAction={{
          active: !props.readOnly,
          actions: [
            <WrapperTool key="wrapper">
              <Modal
                open={modalOpened}
                onClose={() => {
                  closeModal();
                }}
                title={
                  <h2>
                    <Icon name="users" />{' '}
                    {props.intl.formatMessage(messages.add_new)}
                  </h2>
                }
                trigger={
                  <ModalNewBtn onClick={openModal}>
                    {props.intl.formatMessage(messages.add_new)}
                  </ModalNewBtn>
                }
                size="mini"
                style={{ width: 650 }}
                scrolling
              >
                <CommissionContractForm
                  entityId={props.values.id}
                  entity={props.entity}
                  contractId={props.values.id}
                  close={closeModal}
                  commissionService={DestinationInvoiceAmountService}
                  initialValues={selectedCommission}
                  isAmount={isAmount}
                  commissionType={props.commissionType}
                  update={() => loadData(1)}
                />
              </Modal>
            </WrapperTool>,
          ],
        }}
        hiddenHeaderIfEmpty
        emptyResults={
          <div>{props.intl.formatMessage(appMessages.no_results)}</div>
        }
      />
    </div>
  );
}

InvoiceAmountPart.defaultProps = {
  values: {},
  readOnly: false,
};

InvoiceAmountPart.propTypes = {
  values: PropTypes.object,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  commissionType: PropTypes.string,
  entity: PropTypes.string,
};

export default injectIntl(InvoiceAmountPart);
