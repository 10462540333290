import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT,
    method: 'GET',
  });

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT,
    method: 'GET',
    params: {
      'order-by': [
        {
          type: 'field',
          field: 'displayName',
          direction: 'ASC',
        },
      ],
      filter: [
        searchText && {
          type: 'like',
          field: 'displayName',
          value: `%${searchText}%`,
        },
        {
          type: 'eq',
          field: 'isActive',
          value: true,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.agent', [])
        .map(agent => ({
          value: agent.id,
          label: agent.displayName,
        }))
        .sort((a, b) => (a.displayName < b.displayName ? 1 : -1)),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.AGENT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.AGENT}/${id}`,
    method: 'DELETE',
  });

// const getInspectorList = inputValue =>
const getInspectorList = () =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.GET_INSPECTOR_LIST}`,
    method: 'GET',
  });

const AgentService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
  getInspectorList,
};

export default AgentService;
