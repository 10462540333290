import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getByOwner = (staffWorkorderId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER_LOGS}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'objectId',
          value: staffWorkorderId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const StaffWorkOrderInterventionService = {
  getByOwner,
};

export default StaffWorkOrderInterventionService;
