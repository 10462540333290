/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'semantic-ui-react';
import validation from './validation';
import FormActionsBar from '../../FormActionsBar';
import { FormTab } from '../../Layout';
import messages from './messages';
import GeneralTab from './tabs/generalTab';
import LogisticsTab from './tabs/logisticsTab';
import AttachmentsTab from './tabs/attachmentsTab';
import BillingTab from './tabs/billingTab';
import PackagingTab from './tabs/packagingTab';
import ExternalTradeTab from './tabs/externalTradeTab';
import CommissionsTab from './tabs/commissionsTab';
import PermissionManager from '../../Permission';

export class Product extends React.Component {
  state = {
    activeIndex: 0,
    allowedStates: [],
    allowedStatesLoading: true,
    canAccessAdvancedTab: false,
  };

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  componentDidMount() {
    const { isNew } = this.props;

    this.setState({
      canAccessAdvancedTab:
        !isNew && PermissionManager.CanI('accessProductAdvanced', 'custom'),
    });
  }

  render() {
    const {
      handleSubmit,
      isNew,
      readOnly = false,
      isSubmitting = false,
      isValid = false,
      onDelete = false,
      isDeleting = false,
      showDeleteButton = false,
    } = this.props;

    const { canAccessAdvancedTab } = this.state;

    const panes = [
      {
        menuItem: this.context.intl.formatMessage(messages.tab_general_data),
        render: () => (
          <FormTab.Pane>
            <GeneralTab
              {...this.props}
              allowedStates={this.state.allowedStates}
              allowedStatesLoading={this.state.allowedStatesLoading}
            />
          </FormTab.Pane>
        ),
      },
      canAccessAdvancedTab && {
        menuItem: this.context.intl.formatMessage(messages.tab_logistics),
        render: () => (
          <FormTab.Pane>
            <LogisticsTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      canAccessAdvancedTab && {
        menuItem: this.context.intl.formatMessage(messages.tab_attachments),
        render: () => (
          <FormTab.Pane>
            <AttachmentsTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      canAccessAdvancedTab && {
        menuItem: this.context.intl.formatMessage(messages.tab_billing),
        render: () => (
          <FormTab.Pane>
            <BillingTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      !isNew && {
        menuItem: this.context.intl.formatMessage(messages.tab_packaging),
        render: () => (
          <FormTab.Pane>
            <PackagingTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      canAccessAdvancedTab && {
        menuItem: this.context.intl.formatMessage(messages.tab_external_trade),
        render: () => (
          <FormTab.Pane>
            <ExternalTradeTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      canAccessAdvancedTab && {
        menuItem: this.context.intl.formatMessage(messages.tab_commissions),
        render: () => (
          <FormTab.Pane>
            <CommissionsTab {...this.props} />
          </FormTab.Pane>
        ),
      },
    ];

    return (
      <Form onSubmit={handleSubmit}>
        <FormTab
          panes={panes}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
        />
        <FormActionsBar
          showDeleteButton={showDeleteButton}
          readOnly={readOnly}
          isValid={isValid}
          isDeleting={isDeleting}
          isSubmitting={isSubmitting}
          editRoute={this.props.editRoute}
          onDelete={onDelete}
          history={this.props.history}
        />
      </Form>
    );
  }
}

Product.defaultProps = {
  errors: [],
  registryType: 'L',
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

Product.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  registryType: PropTypes.string,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

Product.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default Product;

export { validation };
