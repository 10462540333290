import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the headquarter state domain
 */

const selectHeadquarterDomain = state => state.get('headquarter', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Headquarter
 */

const makeSelectHeadquarter = () =>
  createSelector(selectHeadquarterDomain, substate => substate.toJS());

export default makeSelectHeadquarter;
export { selectHeadquarterDomain };
