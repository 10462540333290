import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import FormikSelect from '../FormikSelect';
import messages from './messages';

const FormikMonthSelect = props => {
  const availableMonths = [
    {
      text: props.intl.formatMessage(messages.january),
      label: props.intl.formatMessage(messages.january),
      value: 1,
      key: 1,
    },
    {
      text: props.intl.formatMessage(messages.february),
      label: props.intl.formatMessage(messages.february),
      value: 2,
      key: 2,
    },
    {
      text: props.intl.formatMessage(messages.march),
      label: props.intl.formatMessage(messages.march),
      value: 3,
      key: 3,
    },
    {
      text: props.intl.formatMessage(messages.april),
      label: props.intl.formatMessage(messages.april),
      value: 4,
      key: 4,
    },
    {
      text: props.intl.formatMessage(messages.may),
      label: props.intl.formatMessage(messages.may),
      value: 5,
      key: 5,
    },
    {
      text: props.intl.formatMessage(messages.june),
      label: props.intl.formatMessage(messages.june),
      value: 6,
      key: 6,
    },
    {
      text: props.intl.formatMessage(messages.july),
      label: props.intl.formatMessage(messages.july),
      value: 7,
      key: 7,
    },
    {
      text: props.intl.formatMessage(messages.august),
      label: props.intl.formatMessage(messages.august),
      value: 8,
      key: 8,
    },
    {
      text: props.intl.formatMessage(messages.september),
      label: props.intl.formatMessage(messages.september),
      value: 9,
      key: 9,
    },
    {
      text: props.intl.formatMessage(messages.october),
      label: props.intl.formatMessage(messages.october),
      value: 10,
      key: 10,
    },
    {
      text: props.intl.formatMessage(messages.november),
      label: props.intl.formatMessage(messages.november),
      value: 11,
      key: 11,
    },
    {
      text: props.intl.formatMessage(messages.december),
      label: props.intl.formatMessage(messages.december),
      value: 12,
      key: 12,
    },
  ];

  const findMonth = value =>
    availableMonths.filter(item => item.value === value)[0] ||
    availableMonths[0];

  const [value, setValue] = useState(findMonth(props.values.month));

  useEffect(
    () => {
      if (props.values.month !== value.value) {
        props.setFieldValue(props.name, value.value);
      }
    },
    [value.value],
  );

  return (
    <FormikSelect
      {...props}
      isLoading={false}
      options={availableMonths}
      defaultValue={value || availableMonths[0]}
      value={value || availableMonths[0]}
      onChange={target => {
        setValue(findMonth(target.value));
      }}
    />
  );
};

FormikMonthSelect.propTypes = {
  values: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(FormikMonthSelect);
