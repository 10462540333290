import qs from 'qs';
import get from 'lodash/get';
import request from '../request';
import { API } from '../../../global-constants';

const getById = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_NOTE}/${id}`,
    method: 'GET',
  });

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_NOTE,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_NOTE}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const patch = ({ id, ...content }) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_NOTE}/${id}`,
    method: 'PATCH',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_NOTE}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (destinationId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_NOTE}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      'order-by': [
        {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        },
      ],
      filter: [
        {
          type: 'eq',
          field: 'destination',
          value: destinationId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const executeNote = (id, content) =>
  request({
    baseURL: API.BASE_URL,
    url: API.NOTE_MANAGER,
    method: 'POST',
    data: { method: 'execute-note', params: { id, ...content } },
  });

/**
 * Gets the closure note configuration.
 * @param {*} noteType
 * @returns
 */
const getClosureNoteOptions = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.NOTE_MANAGER,
    method: 'POST',
    data: { method: 'get-closure-note-config', params: {} },
  }).then(res => Object.values(get(res, 'data', {})));

const NoteService = {
  getByOwner,
  getById,
  create,
  update,
  patch,
  remove,
  executeNote,
  getClosureNoteOptions,
};

export default NoteService;
