import React from 'react';
import SimpleFormikField from 'novigo-simple-formik-field';
import _ from 'lodash';
import moment from 'moment';
import { TimePicker } from 'novigo-date-time-picker';
import PropTypes from 'prop-types';

function FormikTime(props) {
  return (
    <SimpleFormikField name={props.name} label={props.label} {...props}>
      {({
        // field, // { name, value, onChange, onBlur }
        form: { setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <TimePicker
          value={_.get(values, props.name) || moment()}
          name={props.name}
          onChange={value => setFieldValue(props.name, value)}
          disabled={props.readOnly}
        />
      )}
    </SimpleFormikField>
  );
}

FormikTime.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
};

export default FormikTime;
