/* eslint-disable indent */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import { FormActions } from '../Layout';
import {
  FormBackBtn,
  FormEditBtn,
  FormRemoveBtn,
  FormDuplicateBtn,
} from '../Buttons';
import appMessages from '../../containers/App/messages';

function FormActionsBar(props) {
  const {
    showDeleteButton,
    showEditButton = true,
    showDuplicateButton,
    readOnly,
    editRoute,
    intl,
    onDelete,
    onDuplicate,
    isDuplicating,
    isDeleting,
    isSubmitting,
    history,
    isValid,
    showGoBack,
    moreButtons = null,
    customBackRoute = '',
  } = props;

  return (
    <FormActions>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            {showGoBack && (
              <FormBackBtn
                customBackRoute={customBackRoute}
                history={history}
              />
            )}
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            {showDeleteButton && (
              <FormRemoveBtn loading={isDeleting} action={onDelete} notice />
            )}
            {showDuplicateButton && (
              <FormDuplicateBtn loading={isDuplicating} action={onDuplicate} />
            )}
            {readOnly && !showDeleteButton && !editRoute ? (
              <FormEditBtn
                loading={isDeleting}
                action={() => history.push(editRoute)}
                notice
              />
            ) : null}
            {moreButtons}
            {readOnly &&
              editRoute &&
              history &&
              showEditButton &&
              !showDeleteButton && (
                <FormEditBtn
                  loading={isDeleting}
                  action={() => history.push(editRoute)}
                  notice
                />
              )}
            {!readOnly &&
              !showDuplicateButton && (
                <Button
                  loading={isSubmitting}
                  disabled={!isValid}
                  type="submit"
                  icon
                  color="green"
                  labelPosition="left"
                >
                  <Icon name="check" />
                  {intl.formatMessage(appMessages.save)}
                </Button>
              )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormActions>
  );
}

FormActionsBar.propTypes = {
  showDeleteButton: PropTypes.bool,
  showDuplicateButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
  readOnly: PropTypes.bool,
  isValid: PropTypes.bool,
  isDuplicating: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  editRoute: PropTypes.string,
  intl: intlShape.isRequired,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  history: PropTypes.object,
  showGoBack: PropTypes.bool,
  // eslint-disable-next-line react/no-unused-prop-types
  moreButtons: PropTypes.any,
  customBackRoute: PropTypes.string,
};

export default injectIntl(FormActionsBar);
