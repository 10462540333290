import React from 'react';
import PropTypes from 'prop-types';
import SimpleFormikField from 'novigo-simple-formik-field';

/**
 *
 * @param {*} props
 */
function FormikTelephone(props) {
  const { name, label, pattern = null, ...rest } = props;

  /**
   * It doesn't permit to insert not telephone number characters, but it doesn't perform a real validation.
   * @param {Event} event
   */
  const allowOnlyTelephoneDigit = event => {
    const { key } = event;
    // eslint-disable-next-line no-useless-escape
    const telephonePattern = pattern || /[\(\)\+0-9\- ]+/g;
    if (!telephonePattern.test(key)) {
      event.preventDefault();
    }
  };

  return (
    <SimpleFormikField {...rest} name={name} label={label}>
      {({ field }) => (
        <input {...field} type="tel" onKeyPress={allowOnlyTelephoneDigit} />
      )}
    </SimpleFormikField>
  );
}

FormikTelephone.propTypes = {
  /**
   * The simple-formik-field properties.
   */
  ...SimpleFormikField.propTypes,

  /**
   * The input name.
   */
  name: PropTypes.string,

  /**
   * The input label.
   */
  label: PropTypes.string,
  /**
   * The (optional) pattern for validate the telephone number.
   */
  pattern: PropTypes.string,
};

export default FormikTelephone;
