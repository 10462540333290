/* eslint-disable no-console */

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import FormikAsyncSelect from '../../../FormikAsyncSelect';

import messages from '../messages';

import ProductWarehouseService from '../../../../shared/services/productwarehouse';
import ProductBinService from '../../../../shared/services/productbin';

function LogisticsTab(props) {
  return (
    <div>
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="defaultWarehouse"
              label={props.intl.formatMessage(messages.defaultWarehouse)}
              loadOptions={(searchText, callback) =>
                ProductWarehouseService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="bin"
              label={props.intl.formatMessage(messages.bin)}
              loadOptions={(searchText, callback) =>
                ProductBinService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

LogisticsTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LogisticsTab);
