/*
 * TechnicalIntervention Messages
 *
 * This contains all the text for the TechnicalIntervention container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.TechnicalIntervention';

export default defineMessages({
  tab_work_order: {
    id: `${scope}.tab_work_order`,
    defaultMessage: 'Work Order',
  },
  tab_task_order: {
    id: `${scope}.tab_task_order`,
    defaultMessage: 'Task',
  },
  tab_deratization: {
    id: `${scope}.tab_deratization`,
    defaultMessage: 'Deratization',
  },
  tab_disinfection: {
    id: `${scope}.tab_disinfection`,
    defaultMessage: 'Disinfection',
  },
  tab_monitor_cockroach: {
    id: `${scope}.tab_monitor_cockroach`,
    defaultMessage: 'Monitor Cockroach',
  },
  tab_monitor_flying: {
    id: `${scope}.tab_monitor_flying`,
    defaultMessage: 'Monitor Fliers',
  },
  tab_monitor_goods: {
    id: `${scope}.tab_monitor_goods`,
    defaultMessage: 'Monitor Goods',
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'Number',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  technical: {
    id: `${scope}.technical`,
    defaultMessage: 'Technical',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  referent: {
    id: `${scope}.referent`,
    defaultMessage: 'Referent',
  },
  contract: {
    id: `${scope}.contract`,
    defaultMessage: 'Contract',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  note_placeholder: {
    id: `${scope}.note_placeholder`,
    defaultMessage: 'Write note',
  },
  interventionCode: {
    id: `${scope}.interventionCode`,
    defaultMessage: 'Intervention Code',
  },
  interventionDescription: {
    id: `${scope}.interventionDescription`,
    defaultMessage: 'Intervention Description',
  },
  planned: {
    id: `${scope}.planned`,
    defaultMessage: 'Planned date',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Start',
  },
  end: {
    id: `${scope}.end`,
    defaultMessage: 'End',
  },
  intervention_list: {
    id: `${scope}.intervention_list`,
    defaultMessage: 'Intervention list',
  },
  intervention_removed_success: {
    id: `${scope}.intervention_removed_success`,
    defaultMessage: 'Note successfully saved',
  },
  new_intervention: {
    id: `${scope}.new_intervention`,
    defaultMessage: 'New Intervention',
  },
  typeODL: {
    id: `${scope}.typeODL`,
    defaultMessage: 'ODL Type',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  stateHistory: {
    id: `${scope}.stateHistory`,
    defaultMessage: 'State history',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Username',
  },
  duration: {
    id: `${scope}.duration`,
    defaultMessage: 'Duration',
  },
  signatory: {
    id: `${scope}.signatory`,
    defaultMessage: 'Signatory',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  raport_service: {
    id: `${scope}.raport_service`,
    defaultMessage: 'Raport Service',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Intervention description',
  },
  corrective_action: {
    id: `${scope}.corrective_action`,
    defaultMessage: 'Corrective action',
  },
  get_report: {
    id: `${scope}.get_report`,
    defaultMessage: 'Get Report',
  },
  upload_success: {
    id: `${scope}.upload_success`,
    defaultMessage: 'Upload success!',
  },
  remove_success: {
    id: `${scope}.remove_success`,
    defaultMessage: 'Remove image success!',
  },
  increment: {
    id: `${scope}.increment`,
    defaultMessage: 'Increment',
  },
  disinfectedArea: {
    id: `${scope}.disinfectedArea`,
    defaultMessage: 'Disinfected Area',
  },
  disinfectedAreaDescription: {
    id: `${scope}.disinfectedAreaDescription`,
    defaultMessage: 'Disinfected Area Note',
  },
  disinfectedAreaCockroachG: {
    id: `${scope}.disinfectedAreaCockroachG`,
    defaultMessage: 'Cockroach G.',
  },
  other: {
    id: `${scope}.other`,
    defaultMessage: 'Other',
  },
  targetInsects: {
    id: `${scope}.targetInsects`,
    defaultMessage: 'Target Insects',
  },
  disinfectedAreaCockroachO: {
    id: `${scope}.disinfectedAreaCockroachO`,
    defaultMessage: 'Cockroach O.',
  },
  disinfectedAreaMosquito: {
    id: `${scope}.disinfectedAreaMosquito`,
    defaultMessage: 'Mosquito',
  },
  disinfectedAreaFlies: {
    id: `${scope}.disinfectedAreaFlies`,
    defaultMessage: 'Flies',
  },
  disinfectedAreaSpider: {
    id: `${scope}.disinfectedAreaSpider`,
    defaultMessage: 'Spiders',
  },
  uploadImage: {
    id: `${scope}.uploadImage`,
    defaultMessage: 'Upload Image',
  },
  referenceDate: {
    id: `${scope}.referenceDate`,
    defaultMessage: 'Reference date',
  },
  invoiceDateFrom: {
    id: `${scope}.invoiceDateFrom`,
    defaultMessage: 'Invoice date from',
  },
  invoiceDateTo: {
    id: `${scope}.invoiceDateTo`,
    defaultMessage: 'Invoice date to',
  },
  headquarter: {
    id: `${scope}.headquarter`,
    defaultMessage: 'Headquarter',
  },
  agents: {
    id: `${scope}.agents`,
    defaultMessage: 'Agents',
  },
  mode: {
    id: `${scope}.mode`,
    defaultMessage: 'Mode',
  },
  filterAll: {
    id: `${scope}.filterAll`,
    defaultMessage: 'All',
  },
  filterInclude: {
    id: `${scope}.filterInclude`,
    defaultMessage: 'Include',
  },
  filterExclude: {
    id: `${scope}.filterExclude`,
    defaultMessage: 'Exclude',
  },
  generate: {
    id: `${scope}.generate`,
    defaultMessage: 'Generate',
  },
  invoiceDate: {
    id: `${scope}.invoiceDate`,
    defaultMessage: 'Invoice date',
  },
  totalAmount: {
    id: `${scope}.totalAmount`,
    defaultMessage: 'Total amount',
  },
  numberODL: {
    id: `${scope}.numberODL`,
    defaultMessage: 'Number ODL',
  },
  planned_date: {
    id: `${scope}.planned_date`,
    defaultMessage: 'Planned date',
  },
  technician: {
    id: `${scope}.technician`,
    defaultMessage: 'Technician',
  },
  execute_plan: {
    id: `${scope}.execute_plan`,
    defaultMessage: 'Plan selected',
  },
  cancel_plan: {
    id: `${scope}.cancel_plan`,
    defaultMessage: 'Unplan selected',
  },
  creation_date: {
    id: `${scope}.creation_date`,
    defaultMessage: 'Creation date',
  },
  staff: {
    id: `${scope}.staff`,
    defaultMessage: 'Technician',
  },
  workorder_planned_success: {
    id: `${scope}.workorder_planned_success`,
    defaultMessage: 'Work-order planned succesfully',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
});
