import request from '../request';
import { API } from '../../../global-constants';
// import qs from 'qs';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.FAQ,
    method: 'GET',
    params: {
      pagination: false,
    },
  });

const Faq = {
  getAll,
};

export default Faq;
