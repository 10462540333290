import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Select from 'react-select';
import { Dropdown, Icon, Pagination, Table } from 'semantic-ui-react';
import '../css/notes.css';
import appMessages from '../../App/messages';
const TableFooter = ({
  intl,
  onExport,
  onChangePage,
  pageCount,
  onChangePageSize,
  page,
  pageSize,
}) => (
  <Table attached="bottom">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell width={6} textAlign="left">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 'normal',
            }}
          >
            {intl.formatMessage(appMessages.per_page)}
            <Select
              menuPlacement="top"
              className="basic-single"
              classNamePrefix="select"
              styles={{
                container: prev => ({ ...prev, width: 75, marginLeft: 15 }),
              }}
              // defaultValue={{ value: 10, label: '10' }}
              options={[
                { value: 10, label: '10' },
                { value: 25, label: '25' },
                { value: 50, label: '50' },
                { value: 100, label: '100' },
              ]}
              value={{ value: pageSize, label: pageSize }}
              isLoading={false}
              onChange={data => onChangePageSize(data.value)}
            />
          </div>
        </Table.HeaderCell>
        <Table.HeaderCell width={6}>
          <Pagination
            activePage={page}
            totalPages={pageCount}
            onPageChange={(e, data) => onChangePage(data.activePage)}
          />
        </Table.HeaderCell>
        <Table.HeaderCell width={6} textAlign="right">
          <div style={{ padding: 10, fontWeight: 'normal' }}>
            <Dropdown
              trigger={
                <>
                  <Icon name="download" />
                  {intl.formatMessage(appMessages.export_elements)}
                </>
              }
            >
              <Dropdown.Menu>
                <Dropdown.Item
                  text={`${intl.formatMessage(appMessages.exporting)} Excel`}
                  icon="file excel outline"
                  onClick={() => onExport('Xlsx', 'xlsx')}
                />
                <Dropdown.Item
                  text={`${intl.formatMessage(appMessages.exporting)} PDF`}
                  icon="file pdf outline"
                  onClick={() => onExport('Tcpdf', 'pdf')}
                />
                <Dropdown.Item
                  text={`${intl.formatMessage(appMessages.exporting)} CSV`}
                  icon="file alternate outline"
                  onClick={() => onExport('Csv', 'csv')}
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  </Table>
);

TableFooter.propTypes = {
  intl: PropTypes.shape(intlShape),
  onExport: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangePageSize: PropTypes.func,
  pageCount: PropTypes.number,
  page: PropTypes.number,
  pageSize: PropTypes.number,
};

export default injectIntl(TableFooter);
