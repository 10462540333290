/*
 * Loan Messages
 *
 * This contains all the text for the Loan container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Loan';

export default defineMessages({
  Loan: {
    id: `${scope}.Loan`,
    defaultMessage: 'Loan',
  },
  newLoan: {
    id: `${scope}.newLoan`,
    defaultMessage: 'New Loan',
  },
  exportLoan: {
    id: `${scope}.exportLoan`,
    defaultMessage: 'Export Loan',
  },
  toLoan: {
    id: `${scope}.toLoan`,
    defaultMessage: 'To Loan',
  },
  orderNumber: {
    id: `${scope}.orderNumber`,
    defaultMessage: 'Order number',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  orderType: {
    id: `${scope}.orderType`,
    defaultMessage: 'Order Type',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  article: {
    id: `${scope}.article`,
    defaultMessage: 'Article',
  },
  installed: {
    id: `${scope}.installed`,
    defaultMessage: 'Installed',
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Value',
  },
  subject_removed_success: {
    id: `${scope}.subject_removed_success`,
    defaultMessage: 'Order removed success!',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Date from',
  },
  end: {
    id: `${scope}.end`,
    defaultMessage: 'Date to',
  },
  insert_date: {
    id: `${scope}.insert_date`,
    defaultMessage: 'Insert date',
  },
  document_number: {
    id: `${scope}.document_number`,
    defaultMessage: 'Document number',
  },
  total_price_amount: {
    id: `${scope}.total_price_amount`,
    defaultMessage: 'Value',
  },
  due_date: {
    id: `${scope}.due_date`,
    defaultMessage: 'Due date',
  },
});
