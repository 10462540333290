import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    method: 'GET',
    url: API.OFFER_ROW_FREQUENCY,
    params: {
      'order-by': [
        {
          type: 'field',
          field: 'code',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const OfferRowFrequencyService = {
  getAll,
};

export default OfferRowFrequencyService;
