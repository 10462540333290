import messages from './messages';

function Validation(values, intl) {
  const errors = {};

  if (values.startDate && values.endDate) {
    if (values.startDate > values.endDate) {
      errors.startDate = intl.formatMessage(messages.start_lt_end_date);
    }
  }

  return errors;
}

export default Validation;
