import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_TYPOLOGY,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_TYPOLOGY}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_TYPOLOGY}/${id}`,
    method: 'DELETE',
  });

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_TYPOLOGY,
    method: 'GET',
    params: {
      'order-by': [
        {
          type: 'field',
          field: 'code',
          direction: 'asc',
        },
      ],
    },
  });

const searchByDescription = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_TYPOLOGY,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'code',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByDescription(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.destination-typology', []).map(
        typology => ({
          value: typology.id,
          label: `${typology.code} - ${typology.description}`,
        }),
      ),
    );
  });
};

const DestinationTypologyService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
};

export default DestinationTypologyService;
