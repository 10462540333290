/* eslint-disable indent */
import { createGlobalStyle } from 'styled-components';

/*
* Shades a color (#123456) by -1/+1 range
*/
const shadeColor = (color, percent) => {
  const f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF; // eslint-disable-line
  return "#"+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1); // eslint-disable-line
};

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    min-height: 100%;
    padding: 0px!important;
    overflow: initial;
  }

  .ui.fullscreen.modal.transition.visible.active {
    left: auto!important;
    display: block!important;
    & .content{
      overflow-y: initial!important;
      & .description {
        overflow-y: auto;
      }
    }
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body.fontLoaded {
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    background-color: ${props => props.styles.BG_COLOR};
    min-height: 100%;
    min-width: 100%;
    height: auto;
  }

  p,
  #app > div > div > div.login-form,
  #app > div > div,
  #app > div {
    height: 100%;
  }

  .login-form {
    z-index: 1;
    position: relative;
    pointer-events: none;
  }

  .login-form * {
    pointer-events: auto;
  }

  .main-color {
    color: ${props => props.styles.MAIN_COLOR}!important;
  }

  .main-color-btn{
    background-color: ${props => props.styles.MAIN_COLOR}!important;
    color: #fff!important;
  }

  .main-color-btn:hover{
    background-color: ${props =>
      shadeColor(props.styles.MAIN_COLOR, -0.1)}!important;
    color: #fff!important;
  }

  .brightness {
    -webkit-animation: brightness 20s infinite;
  }

  @-webkit-keyframes brightness {
    0%, 100%  { -webkit-filter: brightness(none); }
    50% { -webkit-filter: brightness(2); }
  }

  // SCROLLBAR
  .scrollbar-container {
    opacity: 0!important;
  }
  .scrollarea:hover .scrollbar-container {
    opacity: .2!important;
  }

  //Fade in img
  @keyframes fadeIn{
    0%{
      opacity: -2;
      filter: blur(20px) brightness(300%);
    }
    100%{
      opacity: 1;
      filter: blur(0px) brightness(100%);
    }
  }
  .fadeInImg{
    opacity: 1;
    animation: fadeIn 5s ease-in-out;
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transform: translate3d(0,0,0);
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000;
    transform: translate3d(0,0,0);
    transform: translateZ(0);
  }

/*
* Dimmer z-index fix
*/
.ui.dimmer {
  z-index: 1002 !important;
}

a.header.item.headerLogo {
  border-radius: 0px!important;
  margin: 0px!important;
  margin-top: -1px!important;
  margin-bottom: -1px!important;
}


/*
* Date padding fix TODO: Riportare su componente date
*/

.MuiInputBase-adornedEnd.MuiInputBase-formControl input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
  padding-left: 4px!important;
  padding-right: 0px!important;
}

/*
* Hicharts
*/

.highcharts-credits {
  display: none!important;
}

/* Modal icon color TODO: Move in component and config */
.modal .header i.icon {
  color: ${props => props.styles.SECOND_COLOR}!important;
}

.modal .header h2 {
  font-size: 20px !important;
}

/* Disabled field fix */
.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  pointer-events: none;
  opacity: 1!important;
}

.ui.form .disabled.field, .ui.form .disabled.fields .field, .ui.form .field :disabled {
  pointer-events: none;
  /* opacity: .45; */
}

/* Geosuggest fix */
.ui.disabled.segment {
  opacity: 1!important
}

.ui.disabled.segment .ui.buttons,
.ui.disabled.segment label
{
  opacity: .45!important
}

/* Select dropdown fix */
.select__menu {
  z-index:1000!important;
  margin-top: 0px!important;
  border-top-left-radius: 0px!important;
  border-top-right-radius: 0px!important;
}

.select__menu-list {
  padding: 0px!important;
  margin: 0px!important;
}

.ui.breadcrumb a {
  color: red!important;
}

/* Lighter dimmer FIX (GFB request) */
.ui.dimmer {
  background-color: rgba(0,0,0,.3)!important;
}
.ui.dimmer.inverted {
  background-color: rgba(255,255,255,.85)!important;
}

/* Fullcalendar overrides */
.fc-button .fc-icon {

}

.fc-button {
  background-color: ${props => props.styles.SECOND_COLOR}!important;
  border: 1px solid ${props => props.styles.SECOND_COLOR}!important;
  & .fc-icon {
    margin-top: -7px!important;
  }
}

.fc-button:hover, .fc-button-active {
  background-color: ${props => props.styles.SECOND_COLOR_HOVER}!important;
  border: 1px solid ${props => props.styles.SECOND_COLOR_HOVER}!important;
}

.fc-button:focus {
  outline: 0!important;
  box-shadow: none!important;
}

.fc-toolbar h2 {
  text-transform: capitalize;
  color: ${props => props.styles.GREY}!important;
}

.fc-event-container:hover {
  cursor: pointer;
}

td.fc-day:hover {
  cursor: pointer;
}

/* Inline column override */
.ui.grid>[class*="three column"].row>.column.inline {
  width: auto!important;
  display: inline-block;
}

/* LOGO LOADER */

.logoLoader.ui.centered.inline.loader.active, .logoLoader.ui.centered.inline.loader.visible::before {
  border-color: rgba(0,0,0,.15)!important;
}

/* HIDE GOOGLE RECAPTCHA V3 */
.grecaptcha-badge {
  display: none !important;
}
`;

export default GlobalStyle;
