import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Grid, Icon, Table } from 'semantic-ui-react';
import { FormattedMessage, intlShape } from 'react-intl';

import currencyFormatter from '../../../utils/currencyFormatter';
import messages from './messages';
import appMessages from '../../../containers/App/messages';
import CommissionCurrency from './CommissionCurrency';
import CommissionStatistic from './CommissionStatistic';
import { ModalFakeActions } from '../../Layout';

/**
 * Handle the changes on the commission data.
 *
 * @param {Object} props the component properties
 * @returns a React element
 */
const CommissionTable = props => {
  const {
    values,
    initialValues,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    isValidating,
    onClose,
  } = props;

  /**
   * Calculate the new "edited total amount" if one of its component change.
   */
  useEffect(
    () => {
      if (
        values.editedTotalCommissionAmount !==
          initialValues.editedTotalCommissionAmount ||
        values.editedTotalRefundAmount !== initialValues.editedTotalRefundAmount
      ) {
        setFieldValue(
          'editedTotalAmount',
          values.editedTotalCommissionAmount + values.editedTotalRefundAmount,
        );
      }
    },
    [values.editedTotalCommissionAmount, values.editedTotalRefundAmount],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Row style={{ margin: '20px' }}>
          <Grid.Column width={16}>
            <CommissionStatistic {...props} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Table padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell width={1}>
                  <FormattedMessage {...messages.performed} />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <FormattedMessage {...messages.fixed} />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell width={1}>
                  <FormattedMessage {...messages.totalCommissionAmount} />
                </Table.Cell>
                <Table.Cell width={1}>
                  {currencyFormatter.format(values.totalCommissionAmount)}
                </Table.Cell>
                <Table.Cell width={1}>
                  <CommissionCurrency
                    {...props}
                    name="editedTotalCommissionAmount"
                    value={values.editedTotalCommissionAmount}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={1}>
                  <FormattedMessage {...messages.totalRefundAmount} />
                </Table.Cell>
                <Table.Cell width={1}>
                  {currencyFormatter.format(values.totalRefundAmount)}
                </Table.Cell>
                <Table.Cell width={1}>
                  <CommissionCurrency
                    {...props}
                    name="editedTotalRefundAmount"
                    value={values.totalRefundAmount}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={1}>
                  <FormattedMessage {...messages.totalBonusAmount} />
                </Table.Cell>
                <Table.Cell width={1}>
                  {currencyFormatter.format(values.totalBonusAmount)}
                </Table.Cell>
                <Table.Cell width={1}>
                  <CommissionCurrency
                    {...props}
                    name="editedTotalBonusAmount"
                    value={values.editedTotalBonusAmount}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell width={1}>
                  <FormattedMessage {...messages.totalAmount} />
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  {values && currencyFormatter.format(values.totalAmount)}
                </Table.HeaderCell>
                <Table.HeaderCell width={1}>
                  <CommissionCurrency
                    {...props}
                    name="editedTotalAmount"
                    value={values.editedTotalAmount}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Row>
        <Grid.Row>
          <ModalFakeActions>
            <div style={{ display: 'inline-block', width: '50%' }}>
              <Button type="button" icon labelPosition="left" onClick={onClose}>
                <Icon name="remove" />
                <FormattedMessage {...appMessages.cancel} />
              </Button>
            </div>
            <div
              style={{
                display: 'inline-block',
                width: '50%',
                textAlign: 'right',
              }}
            >
              <Button
                type="button"
                icon
                labelPosition="left"
                color="green"
                disabled={isSubmitting || isValidating}
                onClick={handleSubmit}
              >
                <Icon name="checkmark" />
                <FormattedMessage {...appMessages.save} />
              </Button>
            </div>
          </ModalFakeActions>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

CommissionTable.propTypes = {
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

CommissionTable.contextTypes = {
  intl: intlShape,
};

export default CommissionTable;
