function Validation(values, isProspect = false) {
  const errors = {};

  /**
   * The customer Type should be selected
   */
  if (!values.registryType) {
    errors.registryType = {
      wrongTipology: 'Al cliente deve essere attribuita una tipologia',
    };
  }

  /**
   * If is an individual person, the name and the lastname of customer MUST NOT TO BE EMPTY
   */
  if (values.registryType === 'I' && !values.lastName) {
    errors.lastName = { required: 'Required' };
  }

  /**
   * Fiscal code
   */
  if (!isProspect && values.registryType === 'I' && !values.fiscalCode) {
    errors.fiscalCode = { required: 'Required' };
  }

  /**
   * Is a legal person, so the name of the business MUST TO BE NOT MEPTY
   */
  if (values.registryType === 'L' && !values.businessName) {
    errors.businessName = { required: 'Required' };
  }

  /**
   * Fiscal code
   */
  if (!isProspect && values.registryType === 'L' && !values.vatNumber) {
    errors.vatNumber = { required: 'Required' };
  }

  /**
   * Fiscal code
   */
  if (
    !isProspect &&
    values.registryType === 'L' &&
    !values.businessFiscalCode
  ) {
    errors.businessFiscalCode = { required: 'Required' };
  }

  return errors;
}

export default Validation;
