import { defineMessages } from 'react-intl';

export const orderScope = 'app.components.Form.Order';
export const offerScope = 'app.components.Form.Offer';

export default defineMessages({
  vettore_dtm: {
    id: `${orderScope}.vettore_dtm`,
    defaultMessage: 'DTM carrier',
  },
  vettore__cliente: {
    id: `${orderScope}.vettore__cliente`,
    defaultMessage: 'Customer carrier',
  },
  orderNumber: {
    id: `${orderScope}.orderNumber`,
    defaultMessage: 'Order number',
  },
  date: {
    id: `${orderScope}.date`,
    defaultMessage: 'Date',
  },
  orderDate: {
    id: `${orderScope}.orderDate`,
    defaultMessage: 'Order Date',
  },
  orderType: {
    id: `${orderScope}.orderType`,
    defaultMessage: 'Order Type',
  },
  customer: {
    id: `${orderScope}.customer`,
    defaultMessage: 'Customer',
  },
  commercial_director: {
    id: `${orderScope}.commercial_director`,
    defaultMessage: 'Commercial Director',
  },
  area_manager: {
    id: `${orderScope}.area_manager`,
    defaultMessage: 'Area manager',
  },
  agent_1: {
    id: `${orderScope}.agent_1`,
    defaultMessage: 'Agent 1',
  },
  agent_2: {
    id: `${orderScope}.agent_2`,
    defaultMessage: 'Agent 2',
  },
  sub_agent: {
    id: `${orderScope}.sub_agent`,
    defaultMessage: 'Sub agent',
  },
  buyingGroup: {
    id: `${orderScope}.buyingGroup`,
    defaultMessage: 'Buying Group',
  },
  state: {
    id: `${orderScope}.state`,
    defaultMessage: 'State',
  },
  username: {
    id: `${orderScope}.username`,
    defaultMessage: 'Username',
  },
  destination: {
    id: `${orderScope}.destination`,
    defaultMessage: 'Destination',
  },
  payment_condition: {
    id: `${orderScope}.payment_condition`,
    defaultMessage: 'Payment condition',
  },
  cig: {
    id: `${orderScope}.cig`,
    defaultMessage: 'CIG',
  },
  cup: {
    id: `${orderScope}.cup`,
    defaultMessage: 'CUP',
  },
  dtm_bank: {
    id: `${orderScope}.dtm_bank`,
    defaultMessage: 'DTM Bank',
  },
  status: {
    id: `${orderScope}.status`,
    defaultMessage: 'Status',
  },
  customer_bank: {
    id: `${orderScope}.customer_bank`,
    defaultMessage: 'Customer Bank',
  },
  requested_delivery: {
    id: `${orderScope}.requested_delivery`,
    defaultMessage: 'Requested delivery',
  },
  promissed_delivery: {
    id: `${orderScope}.promissed_delivery`,
    defaultMessage: 'Promissed delivery',
  },
  scheduled_delivery: {
    id: `${orderScope}.scheduled_delivery`,
    defaultMessage: 'Scheduled delivery',
  },
  shipping_warehouse: {
    id: `${orderScope}.shipping_warehouse`,
    defaultMessage: 'Shipping warehouse',
  },
  category: {
    id: `${orderScope}.category`,
    defaultMessage: 'Category',
  },
  subcategory: {
    id: `${orderScope}.subcategory`,
    defaultMessage: 'Subcategory',
  },
  productDomain: {
    id: `${orderScope}.productDomain`,
    defaultMessage: 'Field of use',
  },
  product: {
    id: `${orderScope}.product`,
    defaultMessage: 'Product',
  },
  quantity: {
    id: `${orderScope}.quantity`,
    defaultMessage: 'Quantity',
  },
  discount: {
    id: `${orderScope}.discount`,
    defaultMessage: 'Discount',
  },
  discountType: {
    id: `${orderScope}.discountType`,
    defaultMessage: 'Discount Type',
  },
  discountValue: {
    id: `${orderScope}.discountValue`,
    defaultMessage: 'Discount Value',
  },
  extraDiscount: {
    id: `${orderScope}.extraDiscount`,
    defaultMessage: 'Extra Discount',
  },
  article: {
    id: `${orderScope}.article`,
    defaultMessage: 'Article',
  },
  unit_gross_price: {
    id: `${orderScope}.unit_gross_price`,
    defaultMessage: 'Gross',
  },
  unit_net_price: {
    id: `${orderScope}.unit_net_price`,
    defaultMessage: 'Net',
  },
  loan: {
    id: `${orderScope}.loan`,
    defaultMessage: 'Loan',
  },
  total: {
    id: `${orderScope}.total`,
    defaultMessage: 'Total',
  },
  goods_discount: {
    id: `${orderScope}.goods_discount`,
    defaultMessage: 'GD',
  },
  isProcessed: {
    id: `${orderScope}.isProcessed`,
    defaultMessage: 'Processed',
  },
  actions: {
    id: `${orderScope}.actions`,
    defaultMessage: 'Actions',
  },
  state_history: {
    id: `${orderScope}.state_history`,
    defaultMessage: 'State history',
  },
  insert: {
    id: `${orderScope}.insert`,
    defaultMessage: 'Insert',
  },
  productCustomerPrice: {
    id: `${orderScope}.productCustomerPrice`,
    defaultMessage: 'Client',
  },
  productMasterPrice: {
    id: `${orderScope}.productMasterPrice`,
    defaultMessage: 'Master',
  },
  productOfferPrice: {
    id: `${orderScope}.productOfferPrice`,
    defaultMessage: 'Offer',
  },
  is_sample: {
    id: `${orderScope}.is_sample`,
    defaultMessage: 'Sample',
  },
  yes: {
    id: `${orderScope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${orderScope}.no`,
    defaultMessage: 'No',
  },
  discount_selected: {
    id: `${orderScope}.discount_selected`,
    defaultMessage: 'Discount selected',
  },
  remove_selected: {
    id: `${orderScope}.remove_selected`,
    defaultMessage: 'Remove selected',
  },
  subject_removed_success: {
    id: `${orderScope}.subject_removed_success`,
    defaultMessage: 'Order-row row removed!',
  },
  is_sample_modal: {
    id: `${orderScope}.is_sample_modal`,
    defaultMessage: 'Sample',
  },
  goods_discount_modal: {
    id: `${orderScope}.goods_discount_modal`,
    defaultMessage: 'Goods discount',
  },
  net_price_modal: {
    id: `${orderScope}.net_price_modal`,
    defaultMessage: 'Unit net price',
  },
  loan_modal: {
    id: `${orderScope}.loan_modal`,
    defaultMessage: 'Loan',
  },
  quantity_required: {
    id: `${orderScope}.quantity_required`,
    defaultMessage: 'Quantity field is required!',
  },
  discountValue_required: {
    id: `${orderScope}.discountValue_required`,
    defaultMessage: 'Discount value is requred!',
  },
  netPrice_required: {
    id: `${orderScope}.netPrice_required`,
    defaultMessage: 'Net price is required!',
  },
  flatValue_required: {
    id: `${orderScope}.flatValue_required`,
    defaultMessage: 'Discount value is requred!',
  },
  markup_required: {
    id: `${orderScope}.markup_required`,
    defaultMessage: 'Markup value is requred!',
  },
  promotion_exist: {
    id: `${orderScope}.promotion_exist`,
    defaultMessage: 'There is a promotion ',
  },
  promotion_exist_part: {
    id: `${orderScope}.promotion_exist_part`,
    defaultMessage: ' euro for this product',
  },
  apply: {
    id: `${orderScope}.apply`,
    defaultMessage: 'Apply ?',
  },
  total_order: {
    id: `${orderScope}.total_order`,
    defaultMessage: 'Total order: ',
  },
  notes: {
    id: `${orderScope}.notes`,
    defaultMessage: 'Note',
  },
  note: {
    id: `${orderScope}.note`,
    defaultMessage: 'Note',
  },
  discountTypeTable: {
    id: `${orderScope}.discountTypeTable`,
    defaultMessage: 'Type',
  },
  markup: {
    id: `${orderScope}.markup`,
    defaultMessage: ' Markup',
  },
  avarageDiscount: {
    id: `${orderScope}.avarageDiscount`,
    defaultMessage: 'Avarage Discount:',
  },
  avarageMarkup: {
    id: `${orderScope}.avarageMarkup`,
    defaultMessage: 'Avarage Markup:',
  },
  signaller: {
    id: `${orderScope}.signaller`,
    defaultMessage: 'Signaller',
  },
  verify_gd: {
    id: `${orderScope}.verify_gd`,
    defaultMessage: 'Verify GD',
  },
  verify_goodsDiscount: {
    id: `${orderScope}.verify_goodsDiscount`,
    defaultMessage: 'Verify Goods Discount',
  },
  code: {
    id: `${orderScope}.code`,
    defaultMessage: 'Code',
  },
  description: {
    id: `${orderScope}.description`,
    defaultMessage: 'Description',
  },
  insertArticle: {
    id: `${orderScope}.insertArticle`,
    defaultMessage: 'Insert Article',
  },
  FIXED: {
    id: `${offerScope}.FIXED`,
    defaultMessage: 'Fixed',
  },
  FLAT: {
    id: `${offerScope}.FLAT`,
    defaultMessage: 'Flat',
  },
  PERCENTAGE: {
    id: `${offerScope}.PERCENTAGE`,
    defaultMessage: 'Percentage',
  },
  MARKUP: {
    id: `${offerScope}.MARKUP`,
    defaultMessage: 'Markup',
  },
  requiredDestination: {
    id: `${offerScope}.requiredDestination`,
    defaultMessage: 'Customer/Prospect is required!',
  },
  duplication: {
    id: `${orderScope}.duplication`,
    defaultMessage: 'Duplicate',
  },
  duplicateSuccess: {
    id: `${offerScope}.duplicateSuccess`,
    defaultMessage: 'Duplicate success',
  },
  non_compliant_average_discount: {
    id: `${offerScope}.non_compliant_average_discount`,
    defaultMessage: 'The average discount is above the expected threshold',
  },
  non_compliant_product_discount: {
    id: `${offerScope}.non_compliant_product_discount`,
    defaultMessage: 'product discount above the expected threshold',
  },
  non_compliant_product_markup: {
    id: `${offerScope}.non_compliant_product_markup`,
    defaultMessage: 'product markup above the expected threshold',
  },
  non_compliant_insolvent: {
    id: `${offerScope}.non_compliant_insolvent`,
    defaultMessage: 'The customer has due payments',
  },
  attention: {
    id: `${offerScope}.attention`,
    defaultMessage: 'Attention',
  },
  destination_errors: {
    id: `${offerScope}.destination_errors`,
    defaultMessage: 'Destination errors:',
  },
  destination_edit_missing_fields: {
    id: `${offerScope}.destination_edit_missing_fields`,
    defaultMessage: 'Edit customer missing fields',
  },
  destination_saved: {
    id: `${offerScope}.destination_saved`,
    defaultMessage: 'Destination succesfully saved',
  },
  productCrm: {
    id: `${offerScope}.productCrm`,
    defaultMessage: 'Crm category',
  },
  net_price_edited: {
    id: `${orderScope}.net_price_edited`,
    defaultMessage: 'Found {value, plural, one {# invaid product} other {# invalid products}}',
  },
  net_price_edited_message: {
    id: `${orderScope}.net_price_edited_message`,
    defaultMessage: 'Discount not visible in invoice',
  },
});