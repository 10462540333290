/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/**
 *
 * TableToolbar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { filter, flow, size } from 'lodash';

import { Table, Button, Icon } from 'semantic-ui-react';

/**
 * DataTable footer.
 */
const TableToolbar = props => {
  const {
    columnsNumber,
    deleteAction,
    newAction,
    deleteLabel,
    newLabel,
    selectIsActive,
    elements,
  } = props;
  const checkIsSelected = elements =>
    flow(
      filter({ isSelected: true }),
      size(),
    )(elements);

  return (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell />
        <Table.HeaderCell colSpan={columnsNumber}>
          <Button
            floated="right"
            icon
            labelPosition="left"
            primary
            size="small"
            onClick={newAction}
          >
            <Icon name="plus" /> {newLabel || 'Nuovo'}
          </Button>
          {selectIsActive && (
            <Button
              size="small"
              labelPosition="left"
              icon
              negative
              onClick={deleteAction}
              disabled={checkIsSelected(elements) == 0}
            >
              <Icon name="remove" />
              {deleteLabel || 'Elimina'}
            </Button>
          )}
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

TableToolbar.propTypes = {
  /**
   * Numbers of column
   */
  columnsNumber: PropTypes.number,
  /**
   * New action
   */
  newAction: PropTypes.func,
  /**
   * Mass delete action
   */
  deleteAction: PropTypes.func,
  /**
   * Label for 'New' button
   */
  newLabel: PropTypes.string,
  /**
   * Label for 'Delete' button
   */
  deleteLabel: PropTypes.string,
  /**
   * Show/Hide checkbox for selection
   */
  selectIsActive: PropTypes.bool,
  /**
   * Elements to manage
   */
  elements: PropTypes.array,
};

export default TableToolbar;
