import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import Table from 'semantic-table-grid';
import PropTypes from 'prop-types';
import { Grid, Pagination } from 'semantic-ui-react';
import { intlShape } from 'react-intl';
import moment from 'moment';
import CustomerProductPurchaseService from '../../../../shared/services/customerproductpurchase';
import messages from '../messages';
import currencyFormatter from '../../../../utils/currencyFormatter';

const ProductPurchaseTab = ({ values }, { intl }) => {
  const [table, setTable] = useState({
    data: [],
    loading: false,
    enable: false,
    page: 1,
    pageCount: 1,
    pageSize: 5,
    totalItems: 0,
    order: [
      {
        type: 'field',
        field: 'lastPurchase',
        direction: 'asc',
      },
    ],
    filters: [
      {
        type: 'innerjoin',
        field: 'product',
        alias: 'p',
      },
    ],
    filterLabels: [],
  });

  /**
   * Sort the data when clicked on the column header.
   */
  const onSort = (field, direction) => {
    const order = [];
    if (direction === 'none') {
      setTable({
        ...table,
        order,
      });
      return;
    }

    if (field === 'product') {
      order.push({
        type: 'field',
        field: 'productCode',
        alias: 'p',
        direction,
      });
    }

    if (order.length === 0) {
      order.push({
        type: 'field',
        field,
        direction,
      });
    }

    setTable({
      ...table,
      order,
    });
  };

  /**
   * The destination ID has been loaded, so you can request the data.
   */
  useEffect(
    () => {
      if (!table.enable && values.id) {
        setTable({
          ...table,
          enable: true,
          filters: [
            {
              type: 'innerjoin',
              field: 'product',
              alias: 'p',
            },
            {
              type: 'eq',
              field: 'destination',
              value: values.id,
            },
          ],
        });
      }
    },
    [values.id],
  );

  /**
   * That's the effect to trigger every time the table data needs to be refreshed.
   *
   */
  useEffect(
    () => {
      if (table.enable) {
        setTable({ ...table, loading: true });
        CustomerProductPurchaseService.getAll(table)
          .then(({ data }) => {
            setTable({
              ...table,
              data: get(data, '_embedded.customer_product_purchase', []),
              loading: false,
              page: data.page > 0 ? data.page : 1,
              pageCount: data.page_count,
              pageSize: data.page_size,
              totalItems: data.total_items,
            });
          })
          .catch(() => setTable({ ...table, loading: false }));
      }
    },
    [table.enable, table.page, table.order, table.filters],
  );

  return (
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column width={16}>
          <Table
            elements={table.data}
            columns={[
              {
                key: 'product',
                name: intl.formatMessage(messages.product),
                sortable: true,
                formatter: ({ value }) =>
                  [value.code, value.displayName].filter(p => p).join(' - '),
              },
              {
                key: 'lastPurchase',
                name: intl.formatMessage(messages.lastPurchase),
                sortable: true,
                formatter: ({ value }) =>
                  value && value.date
                    ? moment(value.date).format('DD/MM/YYYY')
                    : '-',
              },
              {
                key: 'quantity',
                sortable: true,
                name: intl.formatMessage(messages.quantity),
              },
              {
                key: 'netPrice',
                sortable: true,
                name: intl.formatMessage(messages.netPrice),
                formatter: ({ value }) => currencyFormatter.format(value),
              },
              {
                key: 'firstDiscount',
                sortable: true,
                name: intl.formatMessage(messages.discount),
                formatter: ({ data }) => `${data.firstDiscount || 0}%`,
              },
              {
                key: 'grossPrice',
                sortable: true,
                name: intl.formatMessage(messages.grossPrice),
                formatter: ({ value }) => currencyFormatter.format(value),
              },
            ]}
            isLoading={table.loading}
            canSort={{
              active: true,
              onSort,
            }}
            canPaginate={{
              active: true,
              render: (
                <Pagination
                  activePage={table.page}
                  onPageChange={(_event, { activePage }) => {
                    setTable({ ...table, page: activePage || 1 });
                  }}
                  size="mini"
                  totalPages={table.pageCount}
                />
              ),
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

ProductPurchaseTab.contextTypes = {
  intl: intlShape,
};

ProductPurchaseTab.propTypes = {
  values: PropTypes.object,
};

export default ProductPurchaseTab;
