import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getByOwner = (staffWorkorderId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER_ATTACHMENT}`,
    method: 'POST',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'objectId',
          value: staffWorkorderId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const get = staffWorkorderId =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER_ATTACHMENT}`,
    method: 'GET',
    params: {
      page: 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'staffWorkorder',
          value: staffWorkorderId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const upload = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF_WORK_ORDER_ATTACHMENT_CUSTOM,
    method: 'POST',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER_ATTACHMENT}/${id}`,
    method: 'DELETE',
  });

const StaffWorkOrderInterventionService = {
  getByOwner,
  upload,
  remove,
  get,
};

export default StaffWorkOrderInterventionService;
