import styled from 'styled-components';

const COLORS = {
  green: '#66ff66',
  yellow: '#ffff00',
  orange: '#ffcc00',
  red: '#ff3300',
};

const handlePercent = percent => {
  if (percent <= 25) {
    return COLORS.green;
  }
  if (percent > 25 && percent <= 50) {
    return COLORS.yellow;
  }
  if (percent > 50 && percent <= 75) {
    return COLORS.orange;
  }
  return COLORS.red;
};

export const BarWrapper = styled.div`
  .ui.indicating.progress .bar {
    background-color: ${({ percent }) => handlePercent(percent)}!important;
  }
  .ui.progress .bar > .progress {
    color: black !important;
  }
`;
