import React from 'react';
import { Grid, Divider } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import PropTypes from 'prop-types';
import { PaddedColumn } from '../../../Layout';
import messages from '../messages';
import PercentageFormatter from '../../../PercentageFormatter';
// eslint-disable-next-line no-unused-vars
import appMessages from '../../../../containers/App/messages';
import AddressFields from '../formParts/addressFieldsPart';
import Accordion from '../../../Accordion';
import DocumentsPart from '../../Anagrafica/formParts/documentsPart';
import DocumentService from '../../../../shared/services/agentdocuments';
import UserService from '../../../../shared/services/users';
import AgentService from '../../../../shared/services/agent';
import PlannedSalesTable from '../formParts/plannedSalesTable';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import AgentTypeService from '../../../../shared/services/agenttype';
import AgentRateService from '../../../../shared/services/agentrate';
import { CanManage } from '../../../../containers/Can/utils';
// eslint-disable-next-line react/prefer-stateless-function
export class GeneralDataTab extends React.Component {
  render() {
    const { values, isNew, user } = this.props;

    const canModify = !CanManage(user);

    return (
      <>
        <Grid columns={3} padded="horizontally">
          <Grid.Row>
            {!isNew && (
              <PaddedColumn>
                <SimpleFormikField
                  {...this.props}
                  name="code"
                  label={this.context.intl.formatMessage(messages.codeAdHoc)}
                  readOnly
                />
              </PaddedColumn>
            )}
            <PaddedColumn>
              <SimpleFormikField
                {...this.props}
                name="businessName"
                label={this.context.intl.formatMessage(messages.businessName)}
                required
              />
            </PaddedColumn>
            <PaddedColumn>
              <SimpleFormikField
                {...this.props}
                name="vatNumber"
                required
                label={this.context.intl.formatMessage(messages.vatNumber)}
              />
            </PaddedColumn>
          </Grid.Row>
          <Grid.Row>
            <PaddedColumn>
              <SimpleFormikField
                {...this.props}
                name="firstName"
                label={this.context.intl.formatMessage(messages.firstName)}
                required
                readOnly={canModify}
              />
            </PaddedColumn>
            <PaddedColumn>
              <SimpleFormikField
                {...this.props}
                name="lastName"
                required
                label={this.context.intl.formatMessage(messages.lastName)}
                readOnly={canModify}
              />
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                {...this.props}
                name="registryType"
                label={this.context.intl.formatMessage(messages.agentType)}
                loadOptions={(searchText, callback) =>
                  AgentTypeService.getOptions(searchText, callback)
                }
                required
              />
            </PaddedColumn>
          </Grid.Row>
          <Grid.Row>
            <PaddedColumn>
              <SimpleFormikField
                {...this.props}
                name="fiscalCode"
                required
                label={this.context.intl.formatMessage(messages.fiscalCode)}
              />
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                {...this.props}
                name="ratesFraming"
                required
                label={this.context.intl.formatMessage(messages.ratesFraming)}
                loadOptions={(searchText, callback) =>
                  AgentRateService.getOptions(searchText, callback)
                }
              />
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                {...this.props}
                name="user"
                label={this.context.intl.formatMessage(messages.user)}
                loadOptions={(searchText, callback) =>
                  UserService.getAsyncUserOptions(searchText, callback)
                }
              />
            </PaddedColumn>
          </Grid.Row>
          <Grid.Row>
            <PaddedColumn>
              <FormikAsyncSelect
                {...this.props}
                name="inspector"
                label={this.context.intl.formatMessage(messages.inspector)}
                loadOptions={(searchText, callback) =>
                  AgentService.getInspectorList(searchText).then(res => {
                    callback(res.data.inspectors);
                  })
                }
              />
            </PaddedColumn>
            <PaddedColumn>
              <PercentageFormatter
                {...this.props}
                name="reportingRate"
                label={this.context.intl.formatMessage(messages.reportingRate)}
              />
            </PaddedColumn>
          </Grid.Row>
        </Grid>
        <Grid padded>
          <Grid.Row>
            <Grid.Column width={16}>
              <AddressFields {...this.props} isRequired canModify={canModify} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {values.id && (
          <Grid padded>
            <Grid.Row>
              <Grid.Column floated="right" width={16}>
                <Accordion
                  title={this.context.intl.formatMessage(messages.documents)}
                >
                  <DocumentsPart
                    {...this.props}
                    entity="agent"
                    documentService={DocumentService}
                  />
                </Accordion>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column floated="right" width={16}>
                <Accordion
                  title={this.context.intl.formatMessage(messages.budget)}
                >
                  <PlannedSalesTable {...this.props} intl={this.context.intl} />
                </Accordion>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </>
    );
  }
}

GeneralDataTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

GeneralDataTab.contextTypes = {
  intl: PropTypes.object.isRequired,
};
