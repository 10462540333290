/*
 * Staff Messages
 *
 * This contains all the text for the Agenda container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Agenda';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Agenda',
  },
  event: {
    id: `${scope}.event`,
    defaultMessage: 'Event',
  },
  new_event: {
    id: `${scope}.new_event`,
    defaultMessage: 'New event',
  },
});
