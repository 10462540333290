/*
 * App Messages
 *
 * This contains all the text for the App container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.App';

export default defineMessages({
  menu_agenda: {
    id: `${scope}.menu_agenda`,
    defaultMessage: 'Agenda',
  },
  menu_users: {
    id: `${scope}.menu_users`,
    defaultMessage: 'Users',
  },
  menu_homepage: {
    id: `${scope}.menu_homepage`,
    defaultMessage: 'Homepage',
  },
  menu_dashboard: {
    id: `${scope}.menu_dashboard`,
    defaultMessage: 'Dashboard',
  },
  menu_roles_acl: {
    id: `${scope}.menu_roles_acl`,
    defaultMessage: 'Roles & ACL',
  },
  menu_categories: {
    id: `${scope}.menu_categories`,
    defaultMessage: 'Categories',
  },
  menu_editable_lists: {
    id: `${scope}.menu_editable_lists`,
    defaultMessage: 'Editable lists',
  },
  menu_invoices: {
    id: `${scope}.menu_invoices`,
    defaultMessage: 'Invoices',
  },
  menu_static_reports: {
    id: `${scope}.menu_static_reports`,
    defaultMessage: 'Static reports',
  },
  menu_static_documents: {
    id: `${scope}.menu_static_documents`,
    defaultMessage: 'Static documents',
  },
  menu_agent_commission_elaboration: {
    id: `${scope}.menu_agent_commission_elaboration`,
    defaultMessage: 'Agent Commission',
  },
  menu_commissions: {
    id: `${scope}.menu_commissions`,
    defaultMessage: 'Commissions',
  },
  customers: {
    id: `${scope}.customers`,
    defaultMessage: 'Customers',
  },
  prospects: {
    id: `${scope}.prospects`,
    defaultMessage: 'Prospects',
  },
  destinations: {
    id: `${scope}.destinations`,
    defaultMessage: 'Destinations',
  },
  headquarters: {
    id: `${scope}.headquarters`,
    defaultMessage: 'Headquarters',
  },
  staff: {
    id: `${scope}.staff`,
    defaultMessage: 'Staff',
  },
  agents: {
    id: `${scope}.agents`,
    defaultMessage: 'Agents',
  },
  promotions: {
    id: `${scope}.promotions`,
    defaultMessage: 'Promotions',
  },
  suppliers: {
    id: `${scope}.suppliers`,
    defaultMessage: 'Suppliers',
  },
  assistance_ticket: {
    id: `${scope}.assistance_ticket`,
    defaultMessage: 'Assistance ticket',
  },
  an_error_has_occurred: {
    id: `${scope}.an_error_has_occurred`,
    defaultMessage: 'An error has occurred',
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Error',
  },
  forbidden: {
    id: `${scope}.forbidden`,
    defaultMessage: 'Forbidden, you cannot access to this data',
  },
  success: {
    id: `${scope}.success`,
    defaultMessage: 'Success',
  },
  operation_performed_successfully: {
    id: `${scope}.operation_performed_successfully`,
    defaultMessage: 'Operation performed successfully',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Search',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  search_an_address: {
    id: `${scope}.search_an_address`,
    defaultMessage: 'Search an address',
  },
  postal_code: {
    id: `${scope}.postal_code`,
    defaultMessage: 'Postal code',
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  import: {
    id: `${scope}.import`,
    defaultMessage: 'Import',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  province: {
    id: `${scope}.province`,
    defaultMessage: 'Province',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  manual: {
    id: `${scope}.manual`,
    defaultMessage: 'Manual',
  },
  automatic: {
    id: `${scope}.automatic`,
    defaultMessage: 'Automatic',
  },
  residential_address: {
    id: `${scope}.residential_address`,
    defaultMessage: 'Residential address',
  },
  domicile_address: {
    id: `${scope}.domicile_address`,
    defaultMessage: 'Domicile address',
  },
  no_results: {
    id: `${scope}.no_results`,
    defaultMessage: 'No results',
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions',
  },
  are_you_sure: {
    id: `${scope}.are_you_sure`,
    defaultMessage:
      'Are you sure you want to delete this item?\nThe operation cannot be canceled!',
  },
  action_view: {
    id: `${scope}.action_view`,
    defaultMessage: 'View',
  },
  action_modify: {
    id: `${scope}.action_modify`,
    defaultMessage: 'Edit',
  },
  action_delete: {
    id: `${scope}.action_delete`,
    defaultMessage: 'Delete',
  },
  action_new: {
    id: `${scope}.action_new`,
    defaultMessage: 'New',
  },
  action_print: {
    id: `${scope}.action_print`,
    defaultMessage: 'Print',
  },
  action_print_grouped: {
    id: `${scope}.action_print_grouped`,
    defaultMessage: 'Print grouped',
  },
  action_word: {
    id: `${scope}.action_word`,
    defaultMessage: 'Print word',
  },
  action_approve: {
    id: `${scope}.action_approve`,
    defaultMessage: 'Approve',
  },
  action_not_approve: {
    id: `${scope}.action_not_approve`,
    defaultMessage: 'Unapprove',
  },
  action_word_grouped: {
    id: `${scope}.action_word_grouped`,
    defaultMessage: 'Print grouped word',
  },
  registry: {
    id: `${scope}.registry`,
    defaultMessage: 'Registry',
  },
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  products: {
    id: `${scope}.products`,
    defaultMessage: 'Articles',
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: 'Orders',
  },
  loan: {
    id: `${scope}.loan`,
    defaultMessage: 'Loan',
  },
  contracts: {
    id: `${scope}.Contracts`,
    defaultMessage: 'Contracts',
  },
  agent_appointments: {
    id: `${scope}.agent_appointments`,
    defaultMessage: 'Agent Appointments',
  },
  static_documents: {
    id: `${scope}.static_documents`,
    defaultMessage: 'Static documents',
  },
  static_reports: {
    id: `${scope}.static_reports`,
    defaultMessage: 'Static reports',
  },
  menu_technical_interventions: {
    id: `${scope}.menu_technical_interventions`,
    defaultMessage: 'Technical Interventions',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  duplicate: {
    id: `${scope}.duplicate`,
    defaultMessage: 'Duplicate',
  },
  exportPdf: {
    id: `${scope}.exportPdf`,
    defaultMessage: 'Export PDF',
  },
  export_to: {
    id: `${scope}.export_to`,
    defaultMessage: 'Export to',
  },
  resetPasswordSuccess: {
    id: `${scope}.resetPasswordSuccess`,
    defaultMessage:
      'An email has been sent to your email in order to generate a new password.',
  },
  monday: {
    id: `${scope}.monday`,
    defaultMessage: 'monday',
  },
  tuesday: {
    id: `${scope}.tuesday`,
    defaultMessage: 'tuesday',
  },
  wednesday: {
    id: `${scope}.wednesday`,
    defaultMessage: 'wednesday',
  },
  thursday: {
    id: `${scope}.thursday`,
    defaultMessage: 'thursday',
  },
  friday: {
    id: `${scope}.friday`,
    defaultMessage: 'friday',
  },
  saturday: {
    id: `${scope}.saturday`,
    defaultMessage: 'saturday',
  },
  sunday: {
    id: `${scope}.sunday`,
    defaultMessage: 'sunday',
  },
  to_customer: {
    id: `${scope}.to_customer`,
    defaultMessage: 'Transform to client',
  },
  offers: {
    id: `${scope}.offers`,
    defaultMessage: 'Offers',
  },
  price_list: {
    id: `${scope}.price_list`,
    defaultMessage: 'Pricelist',
  },
  price_list_master: {
    id: `${scope}.price_list_master`,
    defaultMessage: 'Master',
  },
  price_list_customer: {
    id: `${scope}.price_list_customer`,
    defaultMessage: 'Customer',
  },
  not_positive_number: {
    id: `${scope}.not_positive_number`,
    defaultMessage: 'The value should be a positive number.',
  },
  not_valid_email: {
    id: `${scope}.not_valid_email`,
    defaultMessage: 'The value should be a valid email.',
  },
  not_valid_phone_number: {
    id: `${scope}.not_valid_phone_number`,
    defaultMessage: 'The value should be a valid phone number.',
  },
  newAppTitle: {
    id: `${scope}.newAppTitle`,
    defaultMessage: 'New App coming',
  },
  newAppMessage: {
    id: `${scope}.newAppMessage`,
    defaultMessage:
      'A new version need to restart your page. Save your work and click on "Update application"',
  },
  newAppAction: {
    id: `${scope}.newAppAction`,
    defaultMessage: 'Update application',
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage',
  },
  watchers: {
    id: `${scope}.watchers`,
    defaultMessage: 'Watchers',
  },
  watcher: {
    id: `${scope}.watcher`,
    defaultMessage: 'Watcher',
  },
  required_field: {
    id: `${scope}.required_field`,
    defaultMessage: 'The field is required!',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  notes_hub: {
    id: `${scope}.notes_hub`,
    defaultMessage: 'Notes hub',
  },
  reset: {
    id: `${scope}.reset`,
    defaultMessage: 'Reset',
  },
  per_page: {
    id: `${scope}.per_page`,
    defaultMessage: 'Records per page',
  },
  export_elements: {
    id: `${scope}.export_elements`,
    defaultMessage: 'Export elements',
  },
  exporting: {
    id: `${scope}.exporting`,
    defaultMessage: 'Export',
  },
  hoursAgo: {
    id: `${scope}.hoursAgo`,
    defaultMessage: 'hours ago',
  },
  daysAgo: {
    id: `${scope}.daysAgo`,
    defaultMessage: 'days ago',
  },
});
