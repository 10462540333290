import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the product-category state domain
 */

const selectProductCategoryDomain = state =>
  state.get('productCategory', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by product-category
 */

const makeSelectProductCategory = () =>
  createSelector(selectProductCategoryDomain, substate => substate.toJS());

export default makeSelectProductCategory;
export { selectProductCategoryDomain };
