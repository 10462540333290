import { call, put, takeLatest } from 'redux-saga/effects';
// import Notifications from 'react-notification-system-redux';
import wq from 'with-query';
import _ from 'lodash';
import request from '../../shared/services/request';
import { SET_ACL, LOG_OUT, LOAD_ACL } from './constants';
import { CHANGE_LOCALE } from '../LanguageProvider/constants';
import { API } from '../../global-constants';

import getPermissions from './acl';

export function* loadACL() {
  try {
    const userPermissions = yield call(
      request,
      wq(`${API.BASE_URL}${API.MYSELF}`),
    );

    const role = _.get(userPermissions, 'roles', ['ROLE_USER'])[0];

    const permissions = getPermissions(role);

    const userPermissionData = _.get(userPermissions, 'data', []);

    yield put({
      type: CHANGE_LOCALE,
      locale: _.get(userPermissions, 'data.defaultLanguage') || 'en',
    });

    yield put({
      type: SET_ACL,
      data: { ...userPermissionData, permissions },
    });
  } catch (error) {
    yield put({ type: LOG_OUT });
  }
}

export default function* setTokenTake() {
  yield takeLatest([LOAD_ACL], loadACL);
}
