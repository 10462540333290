import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Segment } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import BaseForm from '../../OfferForm';
import { addNotification } from '../../../../utils/notification';
import getError from '../../../../utils/getError';
import validation from '../../OfferForm/validation';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  number: _.get(newValues, 'number', ''),
  cig: _.get(newValues, 'cig', ''),
  cup: _.get(newValues, 'cup', ''),
  expirationDate: newValues.expirationDate
    ? moment(newValues.expirationDate)
    : null,
  typology: {
    value: _.get(newValues, '_embedded.typology.id'),
    label: _.get(newValues, '_embedded.typology.name'),
  },
  customer: {
    value: _.get(newValues, '_embedded.customer.id'),
    label: _.get(newValues, '_embedded.customer.displayName'),
  },
  commercialDirector: {
    value: _.get(newValues, '_embedded.destination.commercialDirector.id'),
    label: _.get(
      newValues,
      '_embedded.destination.commercialDirector.displayName',
    ),
  },
  areaManager: {
    value: _.get(newValues, '_embedded.destination.areaManager.id'),
    label: _.get(newValues, '_embedded.destination.areaManager.displayName'),
  },
  agent1: {
    value: _.get(newValues, '_embedded.destination.agent1.id'),
    label: _.get(newValues, '_embedded.destination.agent1.displayName'),
  },
  agent2: {
    value: _.get(newValues, '_embedded.destination.agent2.id'),
    label: _.get(newValues, '_embedded.destination.agent2.displayName'),
  },
  subAgent: {
    value: _.get(newValues, '_embedded.destination.subAgent.id'),
    label: _.get(newValues, '_embedded.destination.subAgent.displayName'),
  },
  signaller: {
    value: _.get(newValues, '_embedded.destination.signaller.id'),
    label: _.get(newValues, '_embedded.destination.signaller.displayName'),
  },
  destination: {
    value: _.get(newValues, '_embedded.destination.id'),
    label: _.get(newValues, '_embedded.destination.businessName'),
  },
});

export default class ProductCommission extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    service: PropTypes.object,
    entity: PropTypes.string,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      number: _.get(values, 'number', ''),
      cig: _.get(values, 'cig', ''),
      cup: _.get(values, 'cup', ''),
      expirationDate: _.get(values, 'expirationDate.date', null)
        ? values.expirationDate.format('YYYY-MM-DD')
        : null,
      typology: _.get(values, 'typology.value', ''),
      customer: _.get(values, 'customer.value', ''),
      commercialDirector: _.get(values, 'commercialDirector.value', ''),
      areaManager: _.get(values, 'areaManager.value', ''),
      agent1: _.get(values, 'agent1.value', ''),
      agent2: _.get(values, 'agent2.value', ''),
      subAgent: _.get(values, 'subAgent.value'),
      signaller: _.get(values, 'signaller.value', ''),
      destination: _.get(values, 'destination.value', ''),
      destinationPaymentTerm: _.get(values, 'destinationPaymentTerm.value', ''),
      [this.props.entity]: this.props.entityId,
    };

    const method = payload.id
      ? this.props.service.update
      : this.props.service.create;

    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: null,
          // message: this.context.intl.formatMessage(messages.commission_saved),
          message: 'Saved',
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setSubmitting(false);
        setErrors(getError(err));
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Segment basic>
        <Formik
          ref={c => {
            this.baseForm = c;
          }}
          initialValues={initialValues(this.props.initialValues || {})}
          validate={validation}
          validateOnBlur
          validateOnChange
          onSubmit={this.onSubmit}
          render={props => (
            <BaseForm
              {...this.props}
              {...props}
              submit={this.submit}
              modalSubmit
              entityId={this.props.entityId}
            />
          )}
        />
      </Segment>
    );
  }
}

ProductCommission.contextTypes = {
  intl: PropTypes.object.isRequired,
};
