import request from 'shared/services/request';
import { API } from '../../../global-constants';

const getRoles = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.ROLES,
    method: 'GET',
  });

const getById = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ROLES}/${id}`,
    method: 'GET',
  });

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.ROLES,
    method: 'POST',
    data: content,
  });

const put = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ROLES}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ROLES}/${id}`,
    method: 'DELETE',
  });

const RoleService = {
  getRoles,
  create,
  put,
  getById,
  remove,
};

export default RoleService;
