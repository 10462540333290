/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _, { get } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import ListManager from 'novigo-entity-grid';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import Modal from '../../../Modal';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch, WrapperTool } from '../../../Layout';
import messages from '../../../../containers/Order/messages';
import { addNotification } from '../../../../utils/notification';
import OrderService from '../../../../shared/services/order';
import OrderTypeService from '../../../../shared/services/ordertypology';
import OrderFormModal from '../formParts/orderPart';
import appMessages from '../../../../containers/App/messages';
import {
  ORDER_STATE_INSERTED,
  OrderStateColorMap,
} from '../../../../containers/Order/constants';
import OrderSearch from '../../../../containers/Order/search';

const OrderTab = props => {
  const [title, setTitle] = useState(null);
  const [baseEntityData, setBaseEntityData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [reloadIndex, setReloadIndex] = useState(0);
  const [isCreatingNewOrder, setIsCreatingNewOrder] = useState(false);
  const [orderType, setOrderType] = useState(null);

  const [filters, setFilters] = useState([]);
  const [initialValues, setInitialValues] = useState([]);
  const [filterLabels, setFilterLabels] = useState([]);
  const [removedFilter, setRemovedFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState(null);
  const [searchedValue, setSearchedValue] = useState(null);
  const [aliases, setAliases] = useState([
    {
      type: 'leftjoin',
      field: 'destination',
      alias: 'destination',
    },
    {
      type: 'leftjoin',
      field: 'orderType',
      alias: 'orderType',
    },
    { type: 'eq', field: 'finalized', value: 1 },
  ]);

  const router = useHistory();

  useEffect(
    () => {
      const destinationPaymentTerm = _.get(
        props,
        'values.destinationPaymentTerm',
        null,
      );
      const destinationBank = _.get(props, 'values.destinationBank', null);

      setBaseEntityData({
        customer: _.get(props, 'values.customer', {}),
        destination: {
          id: _.get(props, 'values.id', ''),
          businessName: _.get(props, 'values.businessName', ''),
          destinationPaymentTerm: destinationPaymentTerm
            ? {
                id: destinationPaymentTerm.value,
                description: destinationPaymentTerm.label,
              }
            : null,
          destinationBank: destinationBank
            ? {
                id: destinationBank.value,
                description: destinationBank.label,
              }
            : null,
        },
      });
    },
    [props.values.id],
  );

  useEffect(() => {
    // evaluatePersistentFilters();
    getOrderTypes();
  }, []);

  // const evaluatePersistentFilters = () => {
  //   const newAliases = [...aliases];
  //   const newFilterLabels = [...filterLabels];
  //   const newInitialValues = [...initialValues];

  //   newAliases.push({
  //     type: 'eq',
  //     field: 'state',
  //     value: ORDER_STATE_TO_BE_ACCEPTED,
  //   });

  //   newInitialValues.state = {
  //     label: ORDER_STATE_TO_BE_ACCEPTED,
  //     value: ORDER_STATE_TO_BE_ACCEPTED,
  //   };

  //   newFilterLabels.push({
  //     filterField: 'state',
  //     name: 'State',
  //     value: ORDER_STATE_TO_BE_ACCEPTED,
  //   });

  //   setAliases(newAliases);
  //   setInitialValues(newInitialValues);
  //   setFilterLabels(newFilterLabels);
  // };

  const getOrderTypes = () => {
    OrderTypeService.getTypes('Prodotti').then(({ data }) => {
      const type = _.get(data, '_embedded.order-type[0].id', null);
      setOrderType(type);
    });
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalOpened(false);
    setTitle(null);
  };

  const onCreateOrder = () => {
    setIsCreatingNewOrder(true);
    OrderService.create({
      orderType,
      state: ORDER_STATE_INSERTED,
      customer: _.get(baseEntityData, 'customer.value', null),
      destination: _.get(baseEntityData, 'destination.id', null),
    })
      .then(res => {
        props.history.push(`${API.ORDER}/${res.data.id}/new`);
      })
      .catch(err =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      )
      .finally(() => {
        setIsCreatingNewOrder(false);
      });
  };

  const onRemoveFilter = val => {
    const newFilterLabels = filterLabels.filter(el => el.filterField !== val);
    const newFilters = filters.filter(el => el.field !== val);
    if (val === 'outcome') {
      newFilters.filter(o => o.type !== 'orx');
    }

    _.unset(initialValues, val);

    setFilterLabels(newFilterLabels);
    setFilters(newFilters);
    setRemovedFilter(prevRemovedFilter => (prevRemovedFilter ? '' : val));
  };

  const onDefaultSearch = (val, searchFilters) => {
    setSearchedValue(val);
    setSearchFilter(searchFilters);
  };

  /**
   * Refactred to correctly update the aliases so other states can be shown
   * @param {*} filters
   * @param {*} values
   */
  const handleSearch = (searchFilters, values, labels) => {
    const filterData = searchFilters.find(item => item.field === 'state');

    const updatedAliases = aliases.map((alias, i) => {
      if (i === aliases.length - 1) {
        return filterData;
      }
      return alias;
    });

    // handle filters normally
    if (searchFilters.length) {
      return new Promise(resolve => {
        setFilters(searchFilters);
        setInitialValues(values);
        setAliases(updatedAliases);
        setFilterLabels(labels);
        resolve(true);
      });
    }

    // handle filters if reset
    return new Promise(resolve => {
      // const resetAliases = aliases.map(el => {
      //   if (el.field === 'state') {
      //     return {
      //       type: 'eq',
      //       field: 'state',
      //       value: ORDER_STATE_TO_BE_ACCEPTED,
      //     };
      //   }
      //   return el;
      // });

      // setAliases(resetAliases);
      // setFilters([
      //   {
      //     type: 'eq',
      //     field: 'state',
      //     value: ORDER_STATE_TO_BE_ACCEPTED,
      //   },
      // ]);
      // setInitialValues({
      //   state: {
      //     value: ORDER_STATE_TO_BE_ACCEPTED,
      //     label: ORDER_STATE_TO_BE_ACCEPTED,
      //   },
      // });
      // setFilterLabels([
      //   {
      //     filterField: 'state',
      //     key: 'state',
      //     name: 'State',
      //     value: ORDER_STATE_TO_BE_ACCEPTED,
      //   },
      // ]);
      resolve(true);
    });
  };

  const columns = intl => [
    {
      key: 'number',
      name: intl.formatMessage(messages.orderNumber),
      searchable: true,
      sortable: true,
      width: 1,
      formatter: ({ data }) => {
        const number = data.documentNumber || data.number || '--';
        return number;
      },
    },
    {
      key: 'code',
      name: intl.formatMessage(messages.customer),
      useAlias: 'destination',
      searchable: true,
      sortable: true,
      formatter: data => get(data, 'data._embedded.destination.code', '--'),
      exportFormatter: data =>
        get(data, 'data._embedded.destination.code', '--'),
    },
    {
      key: 'businessName',
      name: intl.formatMessage(messages.businessName),
      useAlias: 'destination',
      searchable: true,
      sortable: true,
      width: 400,
      formatter: data => {
        const businessName = get(
          data,
          'data._embedded.destination.businessName',
          '--',
        );
        const { internalNote, delivery, state } = data.data;
        const stateColor = OrderStateColorMap[state] || 'grey';

        return (
          <div style={{ borderLeft: `solid ${stateColor}` }}>
            {businessName}
            {delivery && (
              <>
                <br />
                <b>{intl.formatMessage(messages.destinationDelivery)}</b>
                {delivery}
              </>
            )}
            {internalNote && (
              <>
                <br />
                <b>{intl.formatMessage(messages.orderNote)}</b>
                {internalNote}
              </>
            )}
          </div>
        );
      },
      exportFormatter: data =>
        get(data, 'data._embedded.destination.businessName', '--'),
    },
    {
      key: 'agent',
      name: 'Agente',
      formatter: ({ data }) => {
        const agent = get(
          data,
          '_embedded.destination.agent1.displayName',
          '--',
        );
        return agent;
      },
      exportFormatter: ({ data }) => {
        const agent = get(
          data,
          '_embedded.destination.agent1.displayName',
          '--',
        );
        return agent;
      },
      searchable: false,
      sortable: false,
    },
    {
      key: 'referenceDate',
      name: intl.formatMessage(messages.orderDate),
      formatter: ({ data }) => {
        const { referenceDate } = data;
        const formattedReferenceDate = referenceDate
          ? moment(referenceDate).format('DD/MM/YYYY')
          : '--';
        return formattedReferenceDate;
      },
      searchable: true,
      sortable: true,
    },
    {
      key: 'totalPriceAmount',
      name: intl.formatMessage(messages.totalPriceAmount),
      searchable: true,
      sortable: true,
    },
    {
      key: 'avarageDiscount',
      formatter: ({ data }) => {
        const averageDiscount = data.avarageDiscount || 0;
        return averageDiscount;
      },
      name: intl.formatMessage(messages.averageDiscount),
      searchable: true,
      sortable: true,
    },
    {
      key: 'maxDiscount',
      formatter: ({ data }) => {
        const maxDiscount = data.maxDiscount || 0;
        return maxDiscount;
      },
      name: intl.formatMessage(messages.maxDiscount),
      searchable: true,
      sortable: true,
    },
  ];

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <ListManager
          defaultOrder={[
            {
              type: 'field',
              field: 'createdAt',
              direction: 'desc',
            },
          ]}
          locale={_.get(props, 'user.defaultLanguage', 'it')}
          entityName="order"
          basePath={API.BASE_URL}
          permissions={props.user}
          router={props}
          reloadIndex={reloadIndex}
          aliases={aliases}
          client={pars => request(pars).then(res => res.data)}
          customSearchFilters={filters}
          filterLabels={filterLabels}
          onRemoveFilter={onRemoveFilter}
          searchFilter={searchFilter}
          searchedValue={searchedValue}
          onDefaultSearch={onDefaultSearch}
          canSearchCustom={OrderSearch({
            intl: props.intl,
            filters,
            initialValues,
            removedFilter,
            onRemoveFilter,
            preSelectState: false,
            onSearch: (filter, values, labels) =>
              handleSearch(filter, values, labels),
          })}
          columns={columns(props.intl)}
          canCreate={{
            active: false,
          }}
          canExport={{
            active: false,
          }}
          defaultActions={{
            visualize: false,
            modify: false,
            delete: false,
            grouped: false,
            moreActions: [
              {
                icon: 'eye',
                label: 'view element',
                key: 'viewRelatedData',
                action: data => {
                  router.push(`/order/${data.id}/view`);
                },
              },
              {
                icon: 'edit',
                label: 'edit element',
                key: 'editRelatedData',
                action: data => {
                  router.push(`/order/${data.id}/modify`, {
                    destination: data.id,
                  });
                },
              },
              {
                icon: 'trash',
                label: 'delete element',
                key: 'deleteRelatedData',
                color: 'red',
                action: data => {
                  OrderService.remove(data.id)
                    .then(() => {
                      setReloadIndex(reloadIndex + 1);
                      addNotification({
                        title: null,
                        message: props.intl.formatMessage(
                          messages.subject_removed_success,
                        ),
                        isError: false,
                      });
                    })
                    .catch(error =>
                      addNotification({
                        title: null,
                        message: error.data.detail,
                        isError: true,
                      }),
                    );
                },
              },
            ],
          }}
          persistentFilter={[
            {
              type: 'eq',
              field: 'destination',
              value: props.values.id,
            },
          ]}
          canSelect={{
            active: false,
            selectAll: false,
            actions: [
              {
                icon: 'plus',
                color: 'blue',
                label: props.intl.formatMessage(messages.new_order),
                onClick: onCreateOrder,
                isLoading: isCreatingNewOrder,
              },
            ],
          }}
        />
      </TableGlobalSearch>
      <WrapperTool key="wrapper">
        <Modal
          open={modalOpened}
          onClose={() => {
            closeModal();
          }}
          title={
            <h2>
              <Icon name="calendar check outline" />{' '}
              {props.intl.formatMessage(
                title === 'modify' ? messages.edit_order : messages.new_order,
              )}
            </h2>
          }
          size="small"
          scrolling
        >
          <OrderFormModal
            entityId={props.values.id}
            entity={props.entity}
            close={closeModal}
            intl={props.intl}
            initialValues={selectedItem || baseEntityData}
            commissionService={OrderService}
            update={() => setReloadIndex(reloadIndex + 1)}
          />
        </Modal>
      </WrapperTool>
    </ListWrapper>
  );
};

OrderTab.defaultProps = {
  values: {},
};

OrderTab.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  entity: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(OrderTab);
