// "novigo-form-manager": "git+ssh://repo.novigo-consulting.it:7999/nxn/novigoformmanager.git",
import { Route, Switch, Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, Loader, Dimmer } from 'semantic-ui-react';
import { Breadcrumbs, BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

// import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import _ from 'lodash';
import LogoImg from 'images/logo.png';
import {
  makeSelectUser,
  makeSelectNotifications,
  makeSelectStyles,
} from './selectors';
import reducer from './reducer';
import { logout, loadAcl } from './actions';

import GestioneACL from '../GestioneAcl/Loadable';
import UserManagement from '../UserManagement/Loadable';
import EditableLists from '../EditableLists/Loadable';
import ManageRolePermissions from '../ManageRolePermissions';
import Can from '../Can/Loadable';

import { makeSelectVersion } from '../Login/selectors';

import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';

import ProductCategories from '../ProductCategories';

/* PLOP_INJECT_IMPORT */
import Commission from '../Commission';
import NotesHub from '../NotesHub';
// import AgentCommissionElaboration from '../AgentCommissionElaboration';
import TechnicalIntervention from '../TechnicalIntervention';
import Order from '../Order';
import PriceListMaster from '../PriceListMaster';
import PriceListCustomer from '../PriceListCustomer';
import Prospect from '../Prospect';
import Customer from '../Customer';
import Headquarter from '../Headquarter';
import Staff from '../Staff';
import Agent from '../Agent';
import Supplier from '../Supplier';
import Offer from '../Offer';
import Tickets from '../Tickets';
import Products from '../Products';
import Agenda from '../Agenda';
import Kibana from '../Kibana';
import Invoices from '../Invoices';
import Destination from '../Destination';
import {
  BreadcrumbWrapper,
  AuthenticatedWrapper,
} from '../../components/Layout';
import menuItems from './menuconfig';

// eslint-disable-next-line react/prefer-stateless-function
class authroutes extends Component {
  static propTypes = {
    logout: PropTypes.func,
    notifications: PropTypes.array,
    user: PropTypes.object,
    loadAcl: PropTypes.func.isRequired,
    version: PropTypes.string,
  };

  state = {
    menuCollapsed: false,
  };

  openMenu = () => this.setState({ menuCollapsed: false });

  closeMenu = () => this.setState({ menuCollapsed: true });

  /**
   * Load ACL in redux
   */
  componentDidMount() {
    if (!this.props.user) {
      this.props.loadAcl();
    }
  }

  render() {
    const { stylesSetting } = this.props;
    return !this.props.user ? (
      <Dimmer active inverted>
        <Loader content={<img alt="Logo" src={LogoImg} />} size="large" />
      </Dimmer>
    ) : (
      <>
        <span
          style={{
            color: 'white',
            position: 'fixed',
            zIndex: 999999,
            bottom: 0,
            transform: 'translate3D(0,0,0)',
          }}
        >
          {this.props.version}
        </span>
        <AuthenticatedWrapper
          className="base"
          collapsed={this.state.menuCollapsed}
        >
          <TopBar
            menuCollapsed={this.state.menuCollapsed}
            logout={this.props.logout}
            user={_.get(this.props, 'user')}
            openMenu={this.openMenu}
            closeMenu={this.closeMenu}
            updateMyself={this.props.loadAcl}
            history={this.props.history}
          />
          {/* Left menu sidebar */}
          <SideBar
            collapsed={this.state.menuCollapsed}
            version={this.props.version}
            items={menuItems({ intl: this.context.intl })}
            openMenu={this.openMenu}
            stylesSetting={stylesSetting}
          />
          <div
            style={{
              padding: stylesSetting.STANDARD_PADDING,
              position: 'relative',
              overflowX: 'auto',
              minHeight: '100vh',
            }}
          >
            <BreadcrumbWrapper>
              <Breadcrumbs
                separator={<b> › </b>}
                item={NavLink}
                finalItem="span"
                finalProps={{
                  style: { color: stylesSetting.LIGHT_GREY },
                }}
              />
            </BreadcrumbWrapper>
            <BreadcrumbsItem to="/">
              <Icon name="home" /> Home
            </BreadcrumbsItem>
            <Switch>
              <Can I="accessSetting" a="custom" path="/gestione-acl/:roleId">
                <Route
                  exact
                  path="/gestione-acl/:roleid"
                  render={props => (
                    <ManageRolePermissions
                      {...props}
                      notification={this.notificationWrapper}
                      user={_.get(this.props, 'user')}
                    />
                  )}
                />
              </Can>
              <Can I="accessSetting" a="custom" path="/gestione-acl">
                <Route
                  exact
                  path="/gestione-acl"
                  render={props => (
                    <GestioneACL
                      {...props}
                      notification={this.notificationWrapper}
                      user={_.get(this.props, 'user')}
                    />
                  )}
                />
              </Can>
              <Can I="accessSetting" a="custom" path="/gestione-utenti">
                <Route
                  exact
                  path="/gestione-utenti"
                  render={props => (
                    <UserManagement
                      {...props}
                      notification={this.notificationWrapper}
                      user={_.get(this.props, 'user')}
                    />
                  )}
                />
              </Can>
              <Can
                I="accessSetting"
                a="custom"
                path="/product-categories/:id?/:action?"
              >
                <Route
                  exact
                  path="/product-categories/:id?/:action?"
                  render={props => (
                    <ProductCategories
                      {...props}
                      notification={this.notificationWrapper}
                      user={_.get(this.props, 'user')}
                    />
                  )}
                />
              </Can>
              <Can I="accessSetting" a="custom" path="/editable-lists">
                <Route
                  exact
                  path="/editable-lists"
                  render={props => (
                    <EditableLists
                      {...props}
                      notification={this.notificationWrapper}
                      user={_.get(this.props, 'user')}
                    />
                  )}
                />
              </Can>
              {/*
                  *  Switch works only on first level children, providing a path props is crucial
                  *  if we do not want to break the router loop check.
                  */}
              <Can I="accessCustomer" a="custom" path="/customer/:id?/:action?">
                <Route
                  path="/customer/:id?/:action?"
                  render={props => (
                    <Customer {...props} user={_.get(this.props, 'user')} />
                  )}
                />
              </Can>
              <Can
                I="accessHeadquarter"
                a="custom"
                path="/headquarter/:id?/:action?"
              >
                <Route
                  path="/headquarter/:id?/:action?"
                  render={props => (
                    <Headquarter {...props} user={_.get(this.props, 'user')} />
                  )}
                />
              </Can>
              {/* PLOP_INJECT_ROUTE */}
              <Can
                I="accessCommission"
                a="custom"
                path="/commission/:id?/:action?"
              >
                <Route
                  path="/commission/:id?/:action?"
                  render={props => (
                    <Commission {...props} user={_.get(this.props, 'user')} />
                  )}
                />
              </Can>
              <Route
                path="/notes"
                render={props => (
                  <NotesHub {...props} user={_.get(this.props, 'user')} />
                )}
              />
              {/* <Route
                path="/agent-commission-elaboration/:id?/:action?"
                render={props => (
                  <AgentCommissionElaboration
                    {...props}
                    user={_.get(this.props, 'user')}
                  />
                )}
              /> */}
              <Can
                I="accessStaffWorkorder"
                a="custom"
                path="/staff-workorder/:id?/:action?"
              >
                <Route
                  path="/staff-workorder/:id?/:action?"
                  render={props => (
                    <TechnicalIntervention
                      {...props}
                      user={_.get(this.props, 'user')}
                    />
                  )}
                />
              </Can>
              <Route
                path="/order/:id?/:action?"
                render={props => (
                  <Order
                    {...props}
                    intl={this.context.intl}
                    user={_.get(this.props, 'user')}
                    stylesSetting={stylesSetting}
                  />
                )}
              />
              <Can I="accessProspect" a="custom" path="/prospect/:id?/:action?">
                <Route
                  path="/prospect/:id?/:action?"
                  render={props => (
                    <Prospect {...props} user={this.props.user} />
                  )}
                />
              </Can>
              <Can
                I="accessDestination"
                a="custom"
                path="/destination/:id?/:action?"
              >
                <Route
                  path="/destination/:id?/:action?"
                  render={props => (
                    <Destination {...props} user={this.props.user} />
                  )}
                />
              </Can>
              <Can I="accessStaff" a="custom" path="/staff/:id?/:action?">
                <Route
                  path="/staff/:id?/:action?"
                  render={props => (
                    <Staff {...props} user={_.get(this.props, 'user')} />
                  )}
                />
              </Can>
              <Can I="accessAgent" a="custom" path="/agent/:id?/:action?">
                <Route
                  path="/agent/:id?/:action?"
                  render={props => (
                    <Agent {...props} user={_.get(this.props, 'user')} />
                  )}
                />
              </Can>
              <Route
                path="/supplier/:id?/:action?"
                render={props => (
                  <Supplier {...props} user={_.get(this.props, 'user')} />
                )}
              />
              <Route
                path="/offer/:id?/:action?"
                render={props => (
                  <Offer
                    {...props}
                    intl={this.context.intl}
                    user={_.get(this.props, 'user')}
                    stylesSetting={stylesSetting}
                  />
                )}
              />
              <Can I="accessProduct" a="custom" path="/product/:id?/:action?">
                <Route
                  path="/product/:id?/:action?"
                  render={props => (
                    <Products {...props} user={_.get(this.props, 'user')} />
                  )}
                />
              </Can>
              <Can
                I="accessMasterPriceList"
                a="custom"
                path="/price-list-master/:id?/:action?"
              >
                <Route
                  path="/price-list-master/:id?/:action?"
                  render={props => (
                    <PriceListMaster
                      {...props}
                      user={_.get(this.props, 'user')}
                    />
                  )}
                />
              </Can>
              <Can
                I="accessCustomerPriceList"
                a="custom"
                path="/price-list-customer/:id?/:action?"
              >
                <Route
                  path="/price-list-customer/:id?/:action?"
                  render={props => (
                    <PriceListCustomer
                      {...props}
                      user={_.get(this.props, 'user')}
                    />
                  )}
                />
              </Can>
              <Route
                path="/invoice/:id?/:action?"
                render={props => (
                  <Invoices {...props} user={_.get(this.props, 'user')} />
                )}
              />
              <Route
                exact
                path="/tickets"
                render={props => <Tickets {...props} user={this.props.user} />}
              />
              <Route
                exact
                path="/agenda"
                render={props => <Agenda {...props} user={this.props.user} />}
              />
              <Route
                exact
                path="/logs"
                render={props => <Kibana {...props} user={this.props.user} />}
              />
              <Route path="/" render={() => <Redirect to="/notes" />} />
              <Route path="" render={() => <Redirect to="/notes" />} />
              {/* <Route
                path="/contract/:id?/:action?"
                render={props => (
                  <Contracts {...props} user={_.get(this.props, 'user')} />
                )}
              />
              <Route
                path="/static-reports"
                render={props => (
                  <StaticReports {...props} user={_.get(this.props, 'user')} />
                )}
              />
              <Route
                path="/static-documents"
                render={props => (
                  <StaticDocuments
                    {...props}
                    user={_.get(this.props, 'user')}
                  />
                )}
              /> */}
              {/* <Route
                path="/dashboard"
                render={props => (
                  <Dashboard {...props} user={_.get(this.props, 'user')} />
                )}
              /> */}
              {/* <Route
                path="/order-costum/comodati"
                render={props => (
                  <Loan {...props} user={_.get(this.props, 'user')} />
                )}
              /> */}
              {/* <Route
                path="/promotion/:id?/:action?"
                render={props => (
                  <Promotion {...props} user={_.get(this.props, 'user')} />
                )}
              /> */}
              {/* <Route
                path="/appointment/:id?/:action?"
                render={props => (
                  <AgentAppointments
                    {...props}
                    user={_.get(this.props, 'user')}
                  />
                )}
              /> */}
            </Switch>
          </div>
        </AuthenticatedWrapper>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  notifications: makeSelectNotifications(),
  version: makeSelectVersion(),
  stylesSetting: makeSelectStyles(),
});

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    loadAcl: () => dispatch(loadAcl()),
  };
}

authroutes.contextTypes = {
  intl: PropTypes.object.isRequired,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'user', reducer });
// const withSaga = injectSaga({ key: 'homePage', saga });

export default compose(
  withReducer,
  // withSaga,
  withConnect,
  injectIntl,
)(authroutes);
