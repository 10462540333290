/*
 * Agent Messages
 *
 * This contains all the text for the Agent container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Agent';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Agent container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Agents',
  },
  nominative: {
    id: `${scope}.nominative`,
    defaultMessage: 'Nominative',
  },
  fiscalcode: {
    id: `${scope}.fiscalcode`,
    defaultMessage: 'Fiscal code',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  telephone: {
    id: `${scope}.telephone`,
    defaultMessage: 'Phone',
  },
  new_agent: {
    id: `${scope}.new_agent`,
    defaultMessage: 'New agent',
  },
  parent: {
    id: `${scope}.parent`,
    defaultMessage: 'Senior',
  },
});
