/*
 * PriceListCustomer Messages
 *
 * This contains all the text for the PriceListCustomer container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.PriceListCustomer';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the PriceListCustomer container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Price List customer',
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Price list code',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Valid from',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'Valid to',
  },
  new_price_list: {
    id: `${scope}.new_price_list`,
    defaultMessage: 'New price list',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage',
  },
  manage_price_list: {
    id: `${scope}.manage_price_list`,
    defaultMessage: 'Manage price list',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  business_name: {
    id: `${scope}.business_name`,
    defaultMessage: 'Business name',
  },
});
