/**
 * The global state selectors
 */

import { createSelector } from 'reselect';
import Color from 'chroma-js';
import _ from 'lodash';

/** Emulate SASS behavior */
const lightenBy = (color, hslPercent) =>
  color.set('hsl.l', color.get('hsl.l') + hslPercent);

/** Emulate SASS behavior */
const darkenBy = (color, hslPercent) => lightenBy(color, -hslPercent);

/*
* Utility to compute color transforms needed: 
* https://www.ofcodeandcolor.com/cuttle/
*/
export const STYLES = {
  MAIN_COLOR: '#5ca34d',
  SECOND_COLOR: '#5ca34d',
  get SECOND_COLOR_HOVER() {
    return Color(STYLES.SECOND_COLOR).darken(0.2);
  },
  BG_COLOR: '#E0E0E0', // Neutral color, does not need to be set by the user
  get SUBMENU_COLOR() {
    return Color(STYLES.MAIN_COLOR)
      .brighten(0.15)
      .desaturate(0.5)
      .set('hsl.h', '-11')
      .hex();
  },
  get MENU_ACTIVE_COLOR() {
    return Color(STYLES.SUBMENU_COLOR)
      .brighten(1.0)
      .set('hsl.h', '+351')
      .desaturate(0.13)
      .hex();
  },
  GREY: '#666666', // Neutral color, does not need to be set by the user
  get DARK_GREY() {
    return darkenBy(Color(STYLES.GREY), 0.2).hex();
  },
  get LIGHT_GREY() {
    return lightenBy(Color(STYLES.GREY), 0.2).hex();
  },
  SEMANTIC_UI_ACTION_GREY: '#f8f9fa', // UI framework related, does not need to be set by the user
  SIDEBAR_WIDTH: 250,
  SIDEBAR_COLLAPSED_WIDTH: 39,
  TOPBAR_HEIGHT: 65,
  STANDARD_PADDING: 15,
};

function formatStyle(userIdentity) {
  STYLES.MAIN_COLOR = _.get(userIdentity, 'color1')
    ? _.get(userIdentity, 'color1')
    : STYLES.MAIN_COLOR; // Sets main color, otherwise returns default
  STYLES.SECOND_COLOR = _.get(userIdentity, 'color2')
    ? _.get(userIdentity, 'color2')
    : STYLES.SECOND_COLOR; // Sets second color, otherwise returns default
  return STYLES;
}

const selectGlobal = state => state.get('global');

const selectRouter = state => state.get('router');

const selectNotificationsRoot = state => state.get('notifications');

const makeSelectLoading = () =>
  createSelector(selectGlobal, globalState => globalState.get('loading'));

const makeSelectError = () =>
  createSelector(selectGlobal, globalState => globalState.get('error'));

const makeSelectNotifications = () =>
  createSelector(selectNotificationsRoot, root => root);

const makeSelectUser = () =>
  createSelector(selectGlobal, globalState =>
    globalState.getIn(['userIdentity']),
  );

const makeSelectLocation = () =>
  createSelector(selectRouter, routerState =>
    routerState.get('location').toJS(),
  );

const makeSelectStyles = () =>
  createSelector(makeSelectUser(), substate => formatStyle(substate));

export {
  selectGlobal,
  makeSelectLoading,
  makeSelectError,
  makeSelectLocation,
  makeSelectUser,
  makeSelectNotifications,
  makeSelectStyles,
};
