import { connect } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import appMessages from '../../../../containers/App/messages';
import { getFilterData } from './functions';
import { SharedSearchBar } from './index';

const SEARCH_BAR_STATUS = {
  WAITING: 1,
  LOADED: 2,
};

const SearchBar = props => {
  const {
    initialValues,
    intl,
    removedFilter,
    onRemoveFilter,
    isDestination = false,
    isCustomer = false,
    isProspect = false,
    isUserAgent = false,
    isHeadQuarter = false,
    formik,
  } = props;

  const [submitCount, setSubmitCount] = useState(0);

  useEffect(
    () => {
      if (!isEmpty(initialValues)) {
        formik.setValues(initialValues);
        formik.setStatus(SEARCH_BAR_STATUS.WAITING);
      }
    },
    [initialValues],
  );

  /**
   * Formik v1 doesn't use async methods, so I had to run the submit form this way after the first useEffect.
   */
  useEffect(
    () => {
      if (formik.status === SEARCH_BAR_STATUS.WAITING) {
        formik.submitForm();
        formik.setStatus(SEARCH_BAR_STATUS.LOADED);
      }
    },
    [formik.status, formik.values],
  );

  useEffect(
    () => {
      setSubmitCount(submitCount + 1);
    },
    [formik.isSubmitting],
  );

  useEffect(
    () => {
      if (removedFilter) {
        formik.setFieldValue(removedFilter, null);
      }
    },
    [removedFilter, onRemoveFilter],
  );

  return (
    <SharedSearchBar
      intl={intl}
      isDestination={isDestination}
      isCustomer={isCustomer}
      isProspect={isProspect}
      isUserAgent={isUserAgent}
      isHeadQuarter={isHeadQuarter}
      submitCount={submitCount}
      formik={{ ...formik }}
    />
  );
};

SearchBar.propTypes = {
  initialValues: PropTypes.object,
  formik: PropTypes.object,
  isDestination: PropTypes.bool,
  isCustomer: PropTypes.bool,
  isProspect: PropTypes.bool,
  intl: PropTypes.object,
  removedFilter: PropTypes.string,
  onRemoveFilter: PropTypes.func,
  isUserAgent: PropTypes.bool,
  isHeadQuarter: PropTypes.bool,
};

const ConnectedSearchBar = connect(SearchBar);

const ApplyFilter = props => {
  const {
    values,
    formActionsBag,
    applyFilterFunction,
    intl,
    onSearch,
    isCustomer,
    isHeadQuarter,
    isProspect,
    userAgentId,
  } = props;

  const { filters, filterLabels } = getFilterData({
    values,
    intl,
    isCustomer,
    isHeadQuarter,
    isProspect,
    userAgentId,
  });

  onSearch(filters, values, filterLabels).then(() => {
    applyFilterFunction(filters, filterLabels, formActionsBag);
  });
};

const AnagraficaSearchBar = ({
  intl,
  initialSearch,
  filters,
  initialValues,
  onSearch,
  onRemoveFilter,
  preSelectActive = false,
  preSelectOnlyMine = false,
  isDestination = false,
  isCustomer = false,
  isProspect = false,
  isUserAgent = false,
  isHeadQuarter = false,
  userAgentId = '',
}) => ({
  formStructure: (
    <ConnectedSearchBar
      intl={intl}
      initialSearch={initialSearch}
      filters={filters}
      initialValues={initialValues}
      onRemoveFilter={onRemoveFilter}
      preSelectActive={preSelectActive}
      preSelectOnlyMine={preSelectOnlyMine}
      isUserAgent={isUserAgent}
      isCustomer={isCustomer}
      isDestination={isDestination}
      isHeadQuarter={isHeadQuarter}
      isProspect={isProspect}
    />
  ),
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  onApplyFilter: (values, formActionsBag, applyFilterFunction) => {
    ApplyFilter({
      values,
      formActionsBag,
      applyFilterFunction,
      intl,
      onSearch,
      isCustomer,
      isHeadQuarter,
      isProspect,
      preSelectActive,
      preSelectOnlyMine,
      userAgentId,
    });
  },
});

AnagraficaSearchBar.propTypes = {};

export default AnagraficaSearchBar;
