import request from '../request';
import { API } from '../../../global-constants';

const getByYear = ({ year, agent }) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.AGENT_PLANNED_SALES}`,
    method: 'GET',
    params: {
      'filter[0][type]:': 'eq',
      'filter[0][field]': 'year',
      'filter[0][value]': year,
      'filter[1][type]': 'innerjoin',
      'filter[1][field]': 'agent',
      'filter[1][alias]': 'a',
      'filter[2][type]': 'eq',
      'filter[2][alias]': 'a',
      'filter[2][field]': 'id',
      'filter[2][value]': agent,
      'order-by[0][type]': 'field',
      'order-by[0][field]': 'month',
      'order-by[0][direction]': 'asc',
    },
  });

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT_PLANNED_SALES,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.AGENT_PLANNED_SALES}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.AGENT_PLANNED_SALES}/${id}`,
    method: 'DELETE',
  });

const AgentPlannedService = {
  create,
  update,
  remove,
  getByYear,
};

export default AgentPlannedService;
