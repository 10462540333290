/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import forOwn from 'lodash/forOwn';
import SimpleFormikField from 'novigo-simple-formik-field';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Accordion, Button, Form, Grid, Icon, Label } from 'semantic-ui-react';
import messages from './messages/CustomSearchMessages';
import { FormikShape, IntlShape, TranslationsShape } from './Shapes';

/* eslint-disable react/prefer-stateless-function */
class CustomSearch extends React.PureComponent {
  getForm = (searchableFields, formStructure) => {
    if (formStructure) {
      return formStructure;
    }

    return searchableFields.map(el => (
      <Grid.Column key={el.filterName || el.key}>
        <SimpleFormikField
          id={el.filterName || el.key}
          name={el.filterName || el.key}
          label={el.name}
        />
      </Grid.Column>
    ));
  };

  /**
   * Function that resets the form and calls optional function if you want
   * to handle the resetted filters in state.
   * @param {object} formprops Formik props
   */
  handleReset = formprops => {
    forOwn(formprops.values, (value, key) => formprops.setFieldValue(key, ''));
    formprops.submitForm();

    const { onResetFilters = null } = this.props;
    if (onResetFilters && typeof onResetFilters === 'function') {
      const keys = [];
      forOwn(formprops.values, (value, key) => keys.push({ key, value }));
      onResetFilters(keys);
    }
  };

  render() {
    const {
      customSearchHandleClick,
      customSearchIsOpen,
      searchableFields,
      formStructure = false,
      customSearchFilterNumber = 0,
      formikProps,
      intl,
      translations = {},
    } = this.props;

    const { searchBar = {} } = translations;

    const {
      label = intl.formatMessage(messages.searchLabel),
      reset = intl.formatMessage(messages.resetBtn),
      search = intl.formatMessage(messages.buttonLabel),
    } = searchBar;

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Accordion fluid styled>
              <Accordion.Title
                onClick={customSearchHandleClick}
                active={customSearchIsOpen}
                style={{ backgroundColor: '#5A5A5A', color: 'white' }}
              >
                <Icon name="dropdown" />
                {label}
                <Label
                  style={{
                    color: 'white',
                    backgroundColor: '#2185d0',
                    borderRadius: '50%',
                    display: 'inline-block',
                    float: 'right',
                  }}
                >
                  {customSearchFilterNumber}
                </Label>
              </Accordion.Title>
              <Accordion.Content active={customSearchIsOpen}>
                <Form
                  onSubmit={formikProps.handleSubmit}
                  style={{ width: '100%' }}
                >
                  <Grid>
                    <Grid.Row columns={4}>
                      {this.getForm(searchableFields, formStructure)}
                    </Grid.Row>
                  </Grid>
                  <Grid.Row columns={4}>
                    <Grid>
                      <Grid.Row textAlign="right">
                        <Grid.Column>
                          <Button
                            type="button"
                            icon
                            labelPosition="left"
                            onClick={() => this.handleReset(formikProps)}
                          >
                            <Icon name="times" />
                            {reset}
                          </Button>
                          <Button
                            loading={formikProps.isSubmitting}
                            // disabled={!formikProps.isValid}
                            type="button"
                            onClick={() => formikProps.submitForm()}
                            icon
                            color="blue"
                            labelPosition="left"
                          >
                            <Icon name="search" />
                            {search}
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Row>
                </Form>
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

CustomSearch.defaultProps = {
  searchableFields: [],
  customSearchHandleClick: null,
  customSearchIsOpen: false,
  formStructure: null,
  customSearchFilterNumber: 0,
  onResetFilters: null,
  formikProps: {},
  intl: {},
};

CustomSearch.propTypes = {
  searchableFields: PropTypes.array,
  customSearchHandleClick: PropTypes.func,
  customSearchIsOpen: PropTypes.bool,
  formStructure: PropTypes.node,
  customSearchFilterNumber: PropTypes.number,
  onResetFilters: PropTypes.func,
  formikProps: PropTypes.shape(FormikShape),
  intl: IntlShape,
  // eslint-disable-next-line react/require-default-props
  translations: TranslationsShape,
};

export default injectIntl(CustomSearch);
