import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the destination state domain
 */

const selectDestinationDomain = state => state.get('destination', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Destination
 */

const makeSelectDestination = () =>
  createSelector(selectDestinationDomain, substate => substate.toJS());

export default makeSelectDestination;
export { selectDestinationDomain };
