import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { Button, Icon, Form, Grid } from 'semantic-ui-react';
import { get } from 'lodash';
import appMessages from '../../../../../containers/App/messages';
import messages from '../../messages';
import { ModalFakeActions } from '../../../../Layout';
import { addNotification } from '../../../../../utils/notification';
import FormikAsyncSelect from '../../../../FormikAsyncSelect';
import FullModal from '../../../../Modal';
import ProductService from '../../../../../shared/services/product';
import FormikInput from '../../../../FormikInput';
import OrderRowService from '../../../../../shared/services/orderrow';
import { BUTTON_TYPES } from '../../../OfferForm/manageForm/formParts/InsertNewPart';
import ProductCrmService from '../../../../../shared/services/productcrm';
import DiscountTypeService from '../../../../../shared/services/discountType';
import FormikToggle from '../../../../FormikToggle';
import CurrencyFormatter from '../../../../CurrencyFormatter';
import PercentageFormatter from '../../../../PercentageFormatter';

export default function AddProductModal(props) {
  const { open, onClose, onUpdate, intl, orderId, destinationId } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeType, setActiveType] = useState(BUTTON_TYPES.famiglia);

  const isFamiglia = activeType === BUTTON_TYPES.famiglia;
  const isOrder = activeType === BUTTON_TYPES.ordinati;
  const isListino = activeType === BUTTON_TYPES.listino;

  const onButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setActiveType(event.currentTarget.id);
  };

  /**
   * Handles the submit of the form to insert a new order row.
   * @param {*} values
   */
  const onSubmit = values => {
    // Set loading flag
    setIsSubmitting(true);
    // Build the payload
    const payload = {
      destination: destinationId,
      order: orderId,
      products: get(values, 'product.value')
        ? [get(values, 'product.value')]
        : [],
      quantity: get(values, 'quantity', 1),
    };
    if (get(values, 'productKit.value')) {
      payload.productKit = get(values, 'productKit.value');
    }
    if (get(values, 'productCategory.value')) {
      payload.category = get(values, 'productCategory.value');
    }
    if (get(values, 'subcategory.value')) {
      payload.subcategory = get(values, 'subcategory.value');
    }
    // Send the insert command
    OrderRowService.create(payload)
      .then(res => {
        // Reset loading flag
        setIsSubmitting(false);
        // Notify the result
        addNotification({
          title: intl.formatMessage(appMessages.success),
          message: get(
            res,
            'data.message',
            intl.formatMessage(appMessages.success),
          ),
          isError: false,
        });
        onClose();
        onUpdate();
      })
      .catch(err => {
        // Reset loading flag
        setIsSubmitting(false);
        // Notify failure
        addNotification({
          title: intl.formatMessage(appMessages.error),
          message: get(
            err,
            'data.detail',
            intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  return (
    <FullModal
      open={open}
      onClose={() => onClose()}
      title="Aggiungi articolo"
      size="small"
      scrolling
    >
      <Formik
        enableReinitialize
        initialValues={{
          quantity: 1,
          discountType: {
            value: '',
            label: '',
          },
          discountValue: '0',
          extraDiscount: '0',
          markup: '0',
          netPrice: '0',
          flatValue: '0',
          loan: false,
          isSample: false,
          goodsDiscount: false,
        }}
        onSubmit={onSubmit}
        validateOnBlur
        validateOnChange
      >
        {({ values, setFieldValue, handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            style={{
              width: '100%',
            }}
          >
            <Grid>
              <Grid.Row>
                <div className="insert-part-container">
                  {/* Filter type button group */}
                  <Button.Group
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      content={BUTTON_TYPES.famiglia}
                      type="button"
                      id={BUTTON_TYPES.famiglia}
                      primary={activeType === BUTTON_TYPES.famiglia}
                      onClick={onButtonClick}
                    />
                    <Button
                      content={BUTTON_TYPES.ordinati}
                      id={BUTTON_TYPES.ordinati}
                      primary={activeType === BUTTON_TYPES.ordinati}
                      onClick={onButtonClick}
                    />
                    <Button
                      type="button"
                      content={BUTTON_TYPES.listino}
                      id={BUTTON_TYPES.listino}
                      primary={activeType === BUTTON_TYPES.listino}
                      onClick={onButtonClick}
                    />
                  </Button.Group>
                </div>
              </Grid.Row>
              <Grid.Row columns={3}>
                {/* Family type filters */}
                {isFamiglia && (
                  <>
                    <Grid.Column>
                      <FormikAsyncSelect
                        name="productCrm"
                        label={intl.formatMessage(messages.productCrm)}
                        loadOptions={ProductCrmService.getOptions}
                        onChange={data => {
                          setFieldValue('productCrm', data);
                          setFieldValue('product', null);
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <FormikAsyncSelect
                        key={`product-${get(values, 'productCrm.value', '')}--`}
                        name="product"
                        placeholder="Select..."
                        label={intl.formatMessage(messages.product)}
                        required
                        loadOptions={(input, callback) =>
                          ProductService.getOptionsByCrm(
                            input,
                            callback,
                            get(values, 'productCrm.value'),
                          )
                        }
                      />
                    </Grid.Column>
                  </>
                )}
                {/* Order type filters */}
                {isOrder && (
                  <Grid.Column>
                    <FormikAsyncSelect
                      name="product"
                      label={intl.formatMessage(messages.product)}
                      required
                      placeholder="Select..."
                      loadOptions={(input, callback) =>
                        ProductService.getAlreadyCreatedOptions(
                          callback,
                          input,
                          false,
                          get(destinationId),
                        )
                      }
                    />
                  </Grid.Column>
                )}
                {/* Quantity */}
                <Grid.Column>
                  <FormikInput
                    name="quantity"
                    label={intl.formatMessage(messages.quantity)}
                    onChange={e => {
                      setFieldValue('quantity', e.target.value);
                    }}
                  />
                </Grid.Column>
                {/* Discount type */}
                {isListino && (
                  <Grid.Column>
                    <FormikAsyncSelect
                      name="discountType"
                      label={intl.formatMessage(messages.discountType)}
                      loadOptions={(searchText, callback) =>
                        DiscountTypeService.getOptions(
                          searchText,
                          intl,
                          callback,
                        )
                      }
                    />
                  </Grid.Column>
                )}
              </Grid.Row>
              {isListino && (
                <Grid.Row columns={5}>
                  {values.discountType.code === 'PERCENTAGE' && (
                    <Grid.Column>
                      <PercentageFormatter
                        required
                        name="discountValue"
                        label={intl.formatMessage(messages.discountValue)}
                      />
                    </Grid.Column>
                  )}
                  {values.discountType.code === 'MARKUP' && (
                    <Grid.Column>
                      <PercentageFormatter
                        required
                        name="markup"
                        label={intl.formatMessage(messages.markup)}
                      />
                    </Grid.Column>
                  )}
                  {values.discountType.code === 'FLAT' && (
                    <Grid.Column>
                      <CurrencyFormatter
                        required
                        name="flatValue"
                        label={intl.formatMessage(messages.discountValue)}
                      />
                    </Grid.Column>
                  )}
                  {values.discountType.code === 'PERCENTAGE' && (
                    <Grid.Column>
                      <PercentageFormatter
                        name="extraDiscount"
                        label={intl.formatMessage(messages.extraDiscount)}
                      />
                    </Grid.Column>
                  )}
                  {values.discountType.code === 'FIXED' && (
                    <Grid.Column>
                      <CurrencyFormatter
                        name="netPrice"
                        label={intl.formatMessage(messages.net_price_modal)}
                      />
                    </Grid.Column>
                  )}
                  <Grid.Column>
                    <FormikToggle
                      name="loan"
                      label={intl.formatMessage(messages.loan_modal)}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormikToggle
                      name="isSample"
                      label={intl.formatMessage(messages.is_sample_modal)}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <FormikToggle
                      name="goodsDiscount"
                      label={intl.formatMessage(messages.goods_discount_modal)}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
            </Grid>
            {/* Discount modal */}
            {/* <FullModal
              open={showDiscountModal}
              onClose={() => {
                setShowDiscountModal(false);
              }}
              title="Nuovo"
              size="mini"
              style={{ width: 650 }}
              scrolling
            >
              <DiscountForm
                destination={destinationId}
                entityId={orderId}
                entity="order"
                close={() => {
                  setShowDiscountModal(false);
                }}
                initialValues={{}}
                isNewDiscountItem
                selectedRows={[]}
                update={() => {
                  setShowDiscountModal(false);
                  onUpdate();
                  onClose();
                }}
              />
            </FullModal> */}
            {/* Modal action buttons */}
            <ModalFakeActions>
              <div style={{ display: 'inline-block', width: '50%' }}>
                <Button
                  icon
                  labelPosition="left"
                  onClick={props.onClose}
                  type="button"
                >
                  <Icon name="remove" />
                  {intl.formatMessage(appMessages.cancel)}
                </Button>
              </div>
              <div
                style={{
                  display: 'inline-block',
                  width: '50%',
                  textAlign: 'right',
                }}
              >
                <Button
                  color="green"
                  icon
                  labelPosition="left"
                  loading={isSubmitting}
                >
                  <Icon name="checkmark" />
                  {intl.formatMessage(appMessages.save)}
                </Button>
              </div>
            </ModalFakeActions>
          </Form>
        )}
      </Formik>
    </FullModal>
  );
}

AddProductModal.propTypes = {
  intl: PropTypes.object,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool,
  orderId: PropTypes.string,
  destinationId: PropTypes.string,
};
