import _ from 'lodash';
import appMessages from '../../../containers/App/messages';

function Validation(values, intl) {
  const errors = {};

  if (!values.fiscalCode || _.isEmpty(values.fiscalCode)) {
    errors.fiscalCode = intl.formatMessage(appMessages.required_field);
  }

  if (!values.lastName || _.isEmpty(values.lastName)) {
    errors.lastName = intl.formatMessage(appMessages.required_field);
  }

  return errors;
}

export default Validation;
