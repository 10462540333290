/*
 *
 * Products constants
 *
 */

import React from 'react';
import { Grid } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import appMessages from '../App/messages';
import messages from './messages';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import ProductCategoryService from '../../shared/services/productcategory';
import ProductDomainService from '../../shared/services/productdomain';
import ProductSupplierService from '../../shared/services/productsupplier';
import ProductCrmService from '../../shared/services/productcrm';
import FormikToggle from '../../components/FormikToggle';

export const DEFAULT_ACTION = 'app/Products/DEFAULT_ACTION';

export const productFilters = ({ intl }) => ({
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  formStructure: (
    <>
      <Grid.Column>
        <SimpleFormikField
          name="productCode"
          label={intl.formatMessage(messages.productCode)}
        />
      </Grid.Column>
      <Grid.Column>
        <SimpleFormikField
          name="title"
          label={intl.formatMessage(messages.description)}
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="crmCategory"
          label={intl.formatMessage(messages.crmCategory)}
          loadOptions={(searchText, callback) =>
            ProductCrmService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="category"
          label={intl.formatMessage(messages.category)}
          loadOptions={(searchText, callback) =>
            ProductCategoryService.getAsyncCategoryOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="subcategory"
          label={intl.formatMessage(messages.subcategory)}
          loadOptions={(searchText, callback) =>
            ProductCategoryService.getAsyncSubByCategoryOptions(
              searchText,
              callback,
            )
          }
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="productDomain"
          label={intl.formatMessage(messages.domain)}
          loadOptions={(searchText, callback) =>
            ProductDomainService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="productSupplier"
          label={intl.formatMessage(messages.supplier)}
          loadOptions={(searchText, callback) =>
            ProductSupplierService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <FormikToggle
          name="isActive"
          label={intl.formatMessage(messages.isActive)}
        />
      </Grid.Column>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];

    if (values.productCode) {
      filterLabels.push({
        key: 'productCode',
        name: intl.formatMessage(messages.description),
        value: values.productCode,
        filterField: 'productCode',
      });
      filters.push({
        type: 'like',
        where: 'and',
        field: 'productCode',
        value: `%${values.productCode}%`,
      });
    }

    if (values.title) {
      filterLabels.push({
        key: 'title',
        name: intl.formatMessage(messages.productCode),
        value: values.title,
        filterField: 'title',
      });
      filters.push({
        type: 'like',
        where: 'and',
        field: 'title',
        value: `%${values.title}%`,
      });
    }

    if (values.category) {
      filterLabels.push({
        key: 'category',
        name: intl.formatMessage(messages.category),
        value: values.category.label,
        filterField: 'category',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        alias: 'category',
        field: 'description',
        value: `${values.category.label}`,
      });
    }

    if (values.subcategory) {
      filterLabels.push({
        key: 'subcategory',
        name: intl.formatMessage(messages.subcategory),
        value: values.subcategory.label,
        filterField: 'subcategory',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        alias: 'subcategory',
        field: 'description',
        value: `${values.subcategory.label}`,
      });
    }

    if (values.crmCategory) {
      filterLabels.push({
        key: 'crmCategory',
        name: intl.formatMessage(messages.crmCategory),
        value: values.crmCategory.label,
        filterField: 'crmCategory',
      });
      filters.push({
        type: 'eq',
        alias: 'crmCategory',
        where: 'and',
        field: 'id',
        value: `${values.crmCategory.value}`,
      });
    }

    if (values.productDomain) {
      filterLabels.push({
        key: 'productDomain',
        name: intl.formatMessage(messages.domain),
        value: values.productDomain.label,
        filterField: 'productDomain',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        alias: 'productDomains',
        field: 'id',
        value: `${values.productDomain.value}`,
      });
    }

    if (values.isActive) {
      filterLabels.push({
        key: 'isActive',
        name: intl.formatMessage(messages.isActive),
        value: intl.formatMessage(messages.yes),
        filterField: 'isActive',
      });
      filters.push({
        type: 'eq',
        where: 'and',
        field: 'isActive',
        value: 1,
      });
    }

    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
});
