/*
 * Customer Messages
 *
 * This contains all the text for the Customer container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Headquarter';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Headquarter container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Headquarters',
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Code',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Business Name',
  },
  firstname: {
    id: `${scope}.firstname`,
    defaultMessage: 'Firstname',
  },
  lastname: {
    id: `${scope}.lastname`,
    defaultMessage: 'Lastname',
  },
  nominative: {
    id: `${scope}.nominative`,
    defaultMessage: 'Nominative',
  },
  fiscalcode: {
    id: `${scope}.fiscalcode`,
    defaultMessage: 'Fiscal code',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  telephone: {
    id: `${scope}.telephone`,
    defaultMessage: 'Phone',
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions',
  },
  new_headquarter: {
    id: `${scope}.new_headquarter`,
    defaultMessage: 'New headquarter',
  },
  legal: {
    id: `${scope}.legal`,
    defaultMessage: 'Legal',
  },
  individual: {
    id: `${scope}.individual`,
    defaultMessage: 'Individual',
  },
  onlyMine: {
    id: `${scope}.onlyMine`,
    defaultMessage: 'Only mine',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
});
