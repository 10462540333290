import _ from 'lodash';
import request from '../request';
import { API } from '../../../global-constants';

const get = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.CRIBIS_MANAGER,
    method: 'POST',
    data: content,
  });

const getCompanyData = content =>
  get(content).then(response => _.get(response, 'data'));

const getCompanyReport = content =>
  get(content).then(response => _.get(response, 'data'));

const CribisManagerService = {
  get,
  getCompanyData,
  getCompanyReport,
};

export default CribisManagerService;
