import { defineMessages } from 'react-intl';

export const scope = 'app.components.Order.SearchBar';

export default defineMessages({
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  orderNumber: {
    id: `${scope}.orderNumber`,
    defaultMessage: 'Order',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Inserted from',
  },
  end: {
    id: `${scope}.end`,
    defaultMessage: 'Inserted to',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
});
