import _ from 'lodash';
import moment from 'moment';
import offerMessages from '../../../containers/Offer/messages';
import messages from '../../Forms/Anagrafica/messages';
import appMessages from '../../../containers/App/messages';

export const getFilterData = ({ values, intl }) => {
  const filters = [];
  const filterLabels = [];

  // push note type
  if (values.noteType && values.noteType.value) {
    filters.push({
      type: 'eq',
      field: 'id',
      value: `${values.noteType.value}`,
      alias: 'noteType',
    });

    filterLabels.push({
      key: 'noteType',
      name: intl.formatMessage(messages.noteType),
      value: values.noteType.label,
      filterField: 'noteType',
    });
  }

  // push note reason
  if (values.noteReason && values.noteReason.value) {
    filters.push({
      type: 'eq',
      field: 'id',
      value: `${values.noteReason.value}`,
      alias: 'noteReason',
    });

    filterLabels.push({
      key: 'noteReason',
      name: intl.formatMessage(messages.noteReason),
      value: values.noteReason.label,
      filterField: 'noteReason',
    });
  }

  // push note date
  if (values.noteDate) {
    filters.push({
      type: 'like',
      field: 'createdAt',
      value: `%${moment(values.noteDate).format('YYYY-MM-DD')}%`,
    });

    filterLabels.push({
      key: 'createdAt',
      name: intl.formatMessage(messages.noteDate),
      value: moment(values.noteDate).format('DD/MM/YYYY'),
      filterField: 'createdAt',
    });
  }

  // push expire wuthin today flag
  if (values.expireWithinToday) {
    filters.push({
      type: 'lte',
      field: 'expireDate',
      value: moment().format('YYYY-MM-DD'),
    });

    filterLabels.push({
      key: 'expireDate',
      name: intl.formatMessage(messages.expireWithinToday),
      value: intl.formatMessage(appMessages.yes),
      filterField: 'expireDate',
    });
  }

  // push expire today flag
  if (values.expireToday) {
    filters.push({
      type: 'eq',
      field: 'expireDate',
      value: moment().format('YYYY-MM-DD'),
    });

    filterLabels.push({
      key: 'expireDate',
      name: intl.formatMessage(messages.expireToday),
      value: intl.formatMessage(appMessages.yes),
      filterField: 'expireDate',
    });
  }

  // push customer to search payload
  if (values.shopSign) {
    filters.push({
      type: 'search_note_businessname',
      field: 'businessName',
      value: values.shopSign,
    });

    filterLabels.push({
      key: 'shopSign',
      name: intl.formatMessage(messages.shopSign),
      value: values.shopSign,
      filterField: 'shopSign',
    });
  }

  // push customer to search payload
  if (values.customer && values.customer.value) {
    filters.push({
      type: 'search_note',
      field: 'customer',
      value: values.customer.value,
    });

    filterLabels.push({
      key: 'customer',
      name: intl.formatMessage(messages.customer),
      value: values.customer.label,
      filterField: 'customer',
    });
  }

  // push destination in custom search
  if (values.destination && values.destination.value) {
    filters.push({
      type: 'search_note',
      field: 'destination',
      value: values.destination.value,
    });

    filterLabels.push({
      key: 'destination',
      name: intl.formatMessage(offerMessages.destination),
      value: values.destination.label,
      filterField: 'destination',
    });
  }

  if (_.isBoolean(values.executed)) {
    filters.push({
      type: values.executed ? 'isnotnull' : 'isnull',
      field: 'executedAt',
    });

    filterLabels.push({
      key: 'executedAt',
      name: intl.formatMessage(messages.noteExecuted),
      value: values.executed
        ? intl.formatMessage(messages.done)
        : intl.formatMessage(messages.not_done),
      filterField: 'executedAt',
    });
  }

  if (values.assignee && values.assignee.value) {
    filters.push({
      type: 'search_note_assignee',
      field: 'assignee',
      value: values.assignee.value,
    });

    filterLabels.push({
      key: 'assignee',
      name: intl.formatMessage(messages.assignee),
      value: values.assignee.label,
      filterField: 'assignee',
    });
  }

  if (values.reporter && values.reporter.value) {
    filters.push({
      type: 'eq',
      field: 'createdBy',
      value: values.reporter.value,
    });

    filterLabels.push({
      key: 'reporter',
      name: intl.formatMessage(messages.reporter),
      value: values.reporter.label,
      filterField: 'reporter',
    });
  }

  if (values.agent && values.agent.value) {
    filters.push({
      type: 'search_note_agent',
      value: values.agent.value,
    });

    filterLabels.push({
      key: 'agent',
      name: intl.formatMessage(messages.agent),
      value: values.agent.label,
      filterField: 'agent',
    });
  }

  // push expire date
  if (values.expireDate) {
    filters.push({
      type: 'eq',
      field: 'expireDate',
      value: moment(values.expireDate).format('YYYY-MM-DD'),
    });

    filterLabels.push({
      key: 'expireDate',
      name: intl.formatMessage(messages.expire_date),
      value: moment(values.expireDate).format('DD/MM/YYYY'),
      filterField: 'expireDate',
    });
  }

  // push the executed flag

  return {
    filters,
    filterLabels,
  };
};
