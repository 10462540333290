import appMessages from '../../../containers/App/messages';

function Validation(values, intl) {
  const errors = {};

  if (values.buyPrice < 0) {
    errors.buyPrice = intl.formatMessage(appMessages.not_positive_number);
  }
  if (values.maxDiscount < 0) {
    errors.maxDiscount = intl.formatMessage(appMessages.not_positive_number);
  }
  if (values.maxDiscountPerQuantity < 0) {
    errors.maxDiscountPerQuantity = intl.formatMessage(
      appMessages.not_positive_number,
    );
  }
  if (values.minQuantity < 0) {
    errors.minQuantity = intl.formatMessage(appMessages.not_positive_number);
  }
  if (values.numBoxPerPackage < 0) {
    errors.numBoxPerPackage = intl.formatMessage(
      appMessages.not_positive_number,
    );
  }
  if (values.numItemsPerBox < 0) {
    errors.numItemsPerBox = intl.formatMessage(appMessages.not_positive_number);
  }
  if (values.numPackagePerPallet < 0) {
    errors.numPackagePerPallet = intl.formatMessage(
      appMessages.not_positive_number,
    );
  }
  if (values.volumeUnit < 0) {
    errors.volumeUnit = intl.formatMessage(appMessages.not_positive_number);
  }
  if (values.grossWeight < 0) {
    errors.grossWeight = intl.formatMessage(appMessages.not_positive_number);
  }
  if (values.netWeight < 0) {
    errors.netWeight = intl.formatMessage(appMessages.not_positive_number);
  }

  if (values.quantityInStock < 0) {
    errors.quantityInStock = intl.formatMessage(
      appMessages.not_positive_number,
    );
  }

  if (values.quantityAvailable < 0) {
    errors.quantityAvailable = intl.formatMessage(
      appMessages.not_positive_number,
    );
  }

  return errors;
}

export default Validation;
