import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.OFFER,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.OFFER}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const patch = ({ id, ...content }) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.OFFER}/${id}`,
    method: 'PATCH',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.OFFER}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (destinationId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.OFFER}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'destination',
          value: destinationId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getFirstByOwnerAndProduct = (destinationId, productId) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.OFFER}`,
    method: 'GET',
    params: {
      page: 1,
      pageSize: 10,
      filter: [
        {
          type: 'leftjoin',
          field: 'rows',
          alias: 'offerrow',
        },
        {
          type: 'eq',
          field: 'destination',
          value: destinationId,
        },
        {
          type: 'eq',
          field: 'product',
          alias: 'offerrow',
          value: productId,
        },
        { type: 'eq', field: 'finalized', value: 1 },
      ],
      'order-by': [
        {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const findOneById = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.OFFER}/${id}`,
    method: 'GET',
  });

const getStates = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.STATE_MACHINE,
    method: 'POST',
    data: {
      method: 'get-config',
      params: {
        module: 'offer',
      },
    },
  });

const getStateOptions = callback => {
  getStates().then(results => {
    const states = _.get(results, 'data.offer-state.states', []);
    callback(
      [
        {
          value: null,
          label: 'TUTTI',
        },
      ].concat(
        Object.keys(states).map(state => ({
          label: state,
          value: state,
        })),
      ),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const getAvailableStates = currentState =>
  getStates().then(res => {
    // const states = _.get(res, 'data.contract-state.states');
    const transitions = _.get(res, 'data.offer-state.transitions');
    const availableTransitions = [];
    Object.keys(transitions).forEach(key => {
      if (transitions[key].from.includes(currentState)) {
        availableTransitions.push(key);
      }
    });
    return availableTransitions;
  });

const getStatesForOffer = () =>
  getStates().then(res => {
    const states = _.get(res, 'data.offer-state.states', []);
    const response = Object.keys(states);

    return response;
  });

const exportPdf = data =>
  request({
    baseURL: API.BASE_URL,
    url: API.OFFER_WORD,
    method: 'POST',
    responseType: 'blob',
    data,
  });

const exportWord = data =>
  request({
    baseURL: API.BASE_URL,
    url: API.OFFER_WORD,
    method: 'POST',
    responseType: 'blob',
    data,
  });

const exportData = id =>
  request({
    baseURL: API.BASE_URL,
    url: API.EXPORT_EXCEL,
    method: 'POST',
    responseType: 'blob',
    data: { method: 'export-excel', params: { code: '003', offerId: id } },
  });

const getDocList = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.DOCUMENT,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
        searchText && {
          type: 'like',
          field: 'code',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getDocumentOptions = (inputValue, callback) => {
  getDocList(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.document', []).map(user => ({
        value: user.id,
        label: `${user.code} ${user.description}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const duplicate = data =>
  request({
    baseURL: API.BASE_URL,
    url: API.OFFER,
    method: 'POST',
    data,
  });

const getOffers = (page, pageSize, filter, sort) =>
  request({
    baseURL: API.BASE_URL,
    url: API.OFFER,
    method: 'GET',
    params: {
      page,
      pageSize,
      filter,
      'order-by': sort || [
        {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const exportOfferCSV = async id => {
  try {
    const response = await request({
      baseURL: API.BASE_URL,
      url: API.OFFER_CSV,
      method: 'POST',
      data: { id },
      responseType: 'blob', // Important for binary data like files
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `offerta-${id}.csv`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (error) {
    console.error('Error downloading the file', error);
  }
};

const OfferService = {
  findOneById,
  getStates,
  getAvailableStates,
  getByOwner,
  create,
  update,
  remove,
  exportPdf,
  exportWord,
  exportData,
  getStatesForOffer,
  getDocumentOptions,
  duplicate,
  getFirstByOwnerAndProduct,
  patch,
  getOffers,
  getStateOptions,
  exportOfferCSV,
};

export default OfferService;
