/* eslint-disable no-underscore-dangle */
/* eslint-disable prettier/prettier */
/**
 *
 * Products
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Helmet } from 'react-helmet-async';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import _ from 'lodash';
import ListManager from 'novigo-entity-grid';
import FormManager from 'novigo-form-manager';
import { Header, Label, Divider, Placeholder } from 'semantic-ui-react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import appMessages from '../App/messages';
import filterMessages from "../../components/SearchBars/Product/messages";
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import PermissionManager from '../../components/Permission';
import ProductAttachmentService from '../../shared/services/productattachment';
import AttachmentService from '../../shared/services/attachments';
import ProductService from '../../shared/services/product';
import imagePlaceholder from '../../images/image-placeholder.png';

/*
 * Specific import
 */

import { API } from '../../global-constants';

import request from '../../shared/services/request';

import ProductForm, { validation } from '../../components/Forms/Product';

import { addNotification } from '../../utils/notification';
import { ListWrapper, Page } from '../../components/Layout';
import ProductSearchBar from '../../components/SearchBars/Product/SearchBar';

/* eslint-disable react/prefer-stateless-function */
export class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      reloadIndex: 0,
      product: null,
      attachments: [],
      loadingAttachments: true,
      prices: [],
      aliases: [
        {
          type: 'leftjoin',
          field: 'category',
          alias: 'category',
        },
        {
          type: 'leftjoin',
          field: 'subcategory',
          alias: 'subcategory',
        },
        {
          type: 'leftjoin',
          field: 'productDomains',
          alias: 'productDomains',
        },
        {
          type: 'leftjoin',
          field: 'domain',
          alias: 'domain',
        },
        {
          type: 'leftjoin',
          field: 'crmCategory',
          alias: 'crmCategory',
        },
        {
          type: 'leftjoin',
          field: 'productSupplier',
          alias: 'productSupplier',
        },
        {
          type: 'leftjoin',
          field: 'productStock',
          alias: 'productStock',
        },
        // {
        //   type: 'orderby_product_attachment',
        //   value: 'DESC',
        // },
      ],
      filters: [],
      filterLabels: [],
      initialValues: {},
      removedFilter: '',
    };
    this.inputOpenFileRef = React.createRef();
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'product',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  handleInitialFilters() {
    const { filters, aliases, initialValues, filterLabels } = this.state;

    filters.push({
      type: 'eq',
      where: 'and',
      field: 'isActive',
      value: 1,
    });
    filterLabels.push({
      key: 'isActive',
      name: this.context.intl.formatMessage(filterMessages.active),
      value: this.context.intl.formatMessage(filterMessages.onlyActive),
      filterField: 'isActive',
    });
    initialValues.isActive = true;

    this.setState({ filters, aliases, initialValues, filterLabels });
  }

  handleSearch = (filters, values, labels) => {
    const { aliases } = this.state;

    const updatedAliases = aliases.map((alias) => {
      // If the filters already contain this field, return filters value
      // otherwise return the predefined alias
      const filterData = filters.find(
        item => item.field === alias.field && item.type === alias.type,
      );
      if (filterData) {
        return filterData;
      }
      return alias;
    });

    return new Promise(resolve => {
      this.setState({
        filters,
        // initialValues: values,
        aliases: updatedAliases,
        filterLabels: labels,
      });
      resolve(true);
    });
  };

  onRemoveFilter = val => {

    if (!val) return;

    // const { initialValues } = this.state;

    const filterLabels = this.state.filterLabels.filter(
      el => el.filterField !== val,
    );

    const filters = this.state.filters.filter(el => el.field !== val);

    // _.unset(initialValues, val);

    this.setState(prev => ({
      removedFilter: prev.removedFilter ? null : val,
      filterLabels,
      filters,
      // initialValues,
    }));
  };

  handleRemovedFilter = name => {
    const { initialValues } = this.state;
    initialValues[name] = null;
    this.setState({
      initialValues,
      removedFilter: null,
    });
  };

  evaluteAction() {
    if (
      this.props.match.params.action === 'view' ||
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'modify' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showForm: true });
    } else {
      this.setState({ showForm: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.checkPermission();
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.checkPermission();
    this.evaluteAction();
    this.handleInitialFilters();
  }

  reloadData = () =>
    this.setState(prevState => ({
      reloadIndex: prevState.reloadIndex + 1,
    }));

  transformData = data => ({
    ...data,
    category: {
      value: _.get(data, 'subcategory.category.id'),
      label: _.get(data, 'subcategory.category.description'),
    },
    subcategory: {
      value: _.get(data, 'subcategory.id'),
      label: _.get(data, 'subcategory.description'),
    },
    productState: {
      value: _.get(data, 'productState.id'),
      label: _.get(data, 'productState.description'),
    },
    bin: {
      value: _.get(data, 'bin.id'),
      label: _.get(data, 'bin.description'),
    },
    warehouseCategory: {
      value: _.get(data, 'warehouseCategory.id'),
      label: _.get(data, 'warehouseCategory.description'),
    },
    taxRate: {
      value: _.get(data, 'taxRate.id'),
      label: _.get(data, 'taxRate.description'),
    },
    defaultWarehouse: {
      value: _.get(data, 'defaultWarehouse.id'),
      label: _.get(data, 'defaultWarehouse.description'),
    },
    countryOrigin: {
      value: _.get(data, 'countryOrigin.id'),
      label: _.get(data, 'countryOrigin.description'),
    },
    packagingType: {
      value: _.get(data, 'packagingType.id'),
      label: _.get(data, 'packagingType.description'),
    },
    packagingDimension: {
      value: _.get(data, 'packagingDimension.id'),
      label: _.get(data, 'packagingDimension.description'),
    },
    unitMeasure: {
      value: _.get(data, 'unitMeasure.id'),
      label: _.get(data, 'unitMeasure.description'),
    },
    kit: {
      value: _.get(data, 'kit.id'),
      label: _.get(data, 'kit.description'),
    },
    crmCategory: {
      value: _.get(data, 'crmCategory.id'),
      label: _.get(data, 'crmCategory.description'),
    },
    loanCategory: {
      value: _.get(data, 'loanCategory.id'),
      label: _.get(data, 'loanCategory.description'),
    },
    packagingProduct: {
      value: _.get(data, 'packagingProduct.id'),
      label: _.get(data, 'packagingProduct.displayName'),
    },
    relatedProduct: _.get(data, 'relatedProduct.id')
      ? {
        value: _.get(data, 'relatedProduct.id'),
        label: `${_.get(data, 'relatedProduct.productCode')} - ${_.get(
          data,
          'relatedProduct.title',
        )}`,
      }
      : null,
    replacementProduct: _.get(data, 'replacementProduct.id')
      ? {
        value: _.get(data, 'replacementProduct.id'),
        label: `${_.get(data, 'replacementProduct.productCode')} - ${_.get(
          data,
          'replacementProduct.title',
        )}`,
      }
      : null,
    groupProduct: _.get(data, 'groupProduct.id')
      ? {
        value: _.get(data, 'groupProduct.id'),
        label: `${_.get(data, 'groupProduct.productCode')} - ${_.get(
          data,
          'groupProduct.title',
        )}`,
      }
      : null,
    createdBy: {
      value: _.get(data, 'createdBy.id'),
      label: _.get(data, 'createdBy.username'),
    },
    relatedProducts: _.get(data, 'relatedProducts', []).map(p => ({
      value: p.id,
      label: p.productCode,
    })),
    productDomains: _.get(data, 'productDomains', []).map(productDomain => ({
      value: productDomain.id,
      label: productDomain.description,
    })),
    productSupplier: {
      value: _.get(data, 'productSupplier.id'),
      label: _.get(data, 'productSupplier.description'),
    },
    replacementProducts: _.get(data, 'replacementProducts', []).map(p => ({
      value: p.id,
      label: p.productCode,
    })),
  });

  loadProductAttachments = ids => {
    if (ids && ids.length > 0) {
      this.setState({ loadingAttachments: true });
      ProductAttachmentService.getAttachmentsForId(ids)
        .then(({ data }) => {
          const productData = _.get(data, '_embedded.product-attachment', [])
            .map(el => {
              const item = el._embedded;
              if (item.attachmentTypeId.description === 'Immagine App') {
                return {
                  productId: item.product.id,
                  attachmentId: item.attachmentId.id,
                };
              }
              return null;
            })
            .filter(o => o);

          const attachmentIds = productData.map(el => el.attachmentId);

          AttachmentService.downloadMultiple(attachmentIds).then(att => {
            const attachmentsArray = _.toArray(att.data).map(el => ({
              id: el.id,
              image: `data:image/jpeg;base64,${el.image}`,
            }));

            const attData = [];
            productData.forEach(prod => {
              attachmentsArray.forEach(attachment => {
                if (attachment.id === prod.attachmentId) {
                  const item = {
                    ...attachment,
                    productId: prod.productId,
                  };
                  attData.push(item);
                }
              });
            });

            this.setState({
              attachments: attData,
              loadingAttachments: false,
            });
          }).catch(() => {
            this.setState({ loadingAttachments: false });
          });
        })
        .catch(err => {
          this.setState({ loadingAttachments: false });
          addNotification({
            title: this.context.intl.formatMessage(appMessages.error),
            message: _.get(
              err,
              'detail',
              this.context.intl.formatMessage(
                appMessages.an_error_has_occurred,
              ),
            ),
            isError: true,
          });
        });
    }
  }

  loadProductPrices = ids => {
    ProductService.getProductPriceMasterByIds(ids).then(res => {
      if (!res.data.prices || res.data.prices.length === 0) {
        return;
      }
      const priceArray = res.data.prices.map(el => ({
        id: el.id,
        productId: el.product,
        price: el.masterPrice || 0,
      }));

      const attData = [];
      priceArray.forEach(price => {
        const item = {
          ...price,
        };
        attData.push(item);
      });

      this.setState({
        prices: attData,
      });
    })
      .catch(err => {
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'detail',
            this.context.intl.formatMessage(
              appMessages.an_error_has_occurred,
            ),
          ),
          isError: true,
        });
      });
  }

  afterLoad = res => {
    const products = _.get(res, '_embedded.product', []);
    const ids = products.map(el => el.id);
    if (ids && ids.length > 0) {
      // Load product attachments
      this.loadProductAttachments(ids);
      // Load product prices from price list master
      this.loadProductPrices(ids);
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.context.intl.formatMessage(messages.title)}</title>
          <meta
            name="description"
            content={this.context.intl.formatMessage(messages.title)}
          />
        </Helmet>
        <Page>
          <BreadcrumbsItem to="/product">
            {this.context.intl.formatMessage(messages.title)}
          </BreadcrumbsItem>
          {this.state.showForm ? (
            <>
              <Header as="h2" style={{ marginBottom: '1px' }}>
                <FormattedMessage {...messages.title} />
              </Header>
              <Label style={{ backgroundColor: 'unset', padding: 0 }}>
                {this.state.product &&
                  this.state.product.productCode &&
                  this.state.product.title &&
                  `${this.state.product.productCode} - ${
                    this.state.product.title
                  }`}
              </Label>
              <Divider />
              <BreadcrumbsItem to="/product/edit">
                {actionToBreadcrumb({
                  action: this.props.match.params.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                key={this.props.match.params.action} // necessary to reload the current view upon redirect
                router={this.props} // necessary to use the internal routing
                client={pars =>
                  request(pars).then(res => {
                    const productData = this.transformData(res.data);

                    this.setState({
                      product: productData,
                    });

                    return productData;
                  })
                }
                mask={props => (
                  <ProductForm
                    {...props}
                    editRoute={`/product/${this.props.match.params.id}/modify`}
                    history={this.props.history}
                  />
                )}
                validation={values => validation(values, this.context.intl)}
                onCleanData={data => {
                  const cleanData = _.cloneDeep(data);
                  cleanData.supplier = _.get(cleanData, 'supplier.value', null);
                  cleanData.productSupplier = _.get(cleanData, 'productSupplier.value', null);
                  cleanData.category = _.get(cleanData, 'category.value', null);
                  cleanData.subcategory = _.get(
                    cleanData,
                    'subcategory.value',
                    null,
                  );

                  cleanData.productState = _.get(
                    cleanData,
                    'productState.value',
                    null,
                  );
                  cleanData.domain = _.get(cleanData, 'domain.value', null);
                  cleanData.bin = _.get(cleanData, 'bin.value', null);
                  cleanData.kit = _.get(cleanData, 'kit.value', null);
                  cleanData.crmCategory = _.get(
                    cleanData,
                    'crmCategory.value',
                    null,
                  );
                  cleanData.loanCategory = _.get(
                    cleanData,
                    'loanCategory.value',
                    null,
                  );
                  cleanData.packagingProduct = _.get(
                    cleanData,
                    'packagingProduct.value',
                    null,
                  );
                  cleanData.relatedProduct = _.get(
                    cleanData,
                    'relatedProduct.value',
                    null,
                  );
                  cleanData.replacementProduct = _.get(
                    cleanData,
                    'replacementProduct.value',
                    null,
                  );
                  cleanData.groupProduct = _.get(
                    cleanData,
                    'groupProduct.value',
                    null,
                  );
                  cleanData.taxRate = _.get(cleanData, 'taxRate.value', null);
                  cleanData.defaultWarehouse = _.get(
                    cleanData,
                    'defaultWarehouse.value',
                    null,
                  );
                  cleanData.warehouseCategory = _.get(
                    cleanData,
                    'warehouseCategory.value',
                    null,
                  );
                  cleanData.countryOrigin = _.get(
                    cleanData,
                    'countryOrigin.value',
                    null,
                  );
                  cleanData.packagingType = _.get(
                    cleanData,
                    'packagingType.value',
                    null,
                  );
                  cleanData.packagingDimension = _.get(
                    cleanData,
                    'packagingDimension.value',
                    null,
                  );
                  cleanData.unitMeasure = _.get(
                    cleanData,
                    'unitMeasure.value',
                    null,
                  );

                  cleanData.relatedProducts = _.get(
                    cleanData,
                    'relatedProducts',
                    [],
                  ).map(p => p.value);

                  cleanData.replacementProducts = _.get(
                    cleanData,
                    'replacementProducts',
                    [],
                  ).map(p => p.value);

                  cleanData.productDomains = _.get(
                    cleanData,
                    'productDomains',
                    [],
                  ).map(productDomain => productDomain.value);

                  _.unset(cleanData, 'id');
                  _.unset(cleanData, '_links');
                  _.unset(cleanData, '_embedded');
                  _.unset(cleanData, 'createdAt');
                  _.unset(cleanData, 'updatedAt');
                  _.unset(cleanData, 'deletedAt');
                  _.unset(cleanData, 'commissions');
                  _.unset(cleanData, 'attachments');
                  _.unset(cleanData, 'createdBy');
                  _.unset(cleanData, 'synchronizedAt');
                  _.unset(cleanData, 'isActive');
                  _.unset(cleanData, 'updatedBy');
                  _.unset(cleanData, 'productStock');
                  _.unset(cleanData, 'readAt');
                  _.unset(cleanData, 'writeAt');
                  _.unset(cleanData, 'imported');
                  _.unset(cleanData, 'productPriceMaster');

                  return cleanData;
                }}
                data={
                  // TODO: provide all initial values https://jaredpalmer.com/formik/docs/1.5.8/guides/form-submission
                  this.props.match.params.id === 'new' && {
                    title: '',
                    productCode: '',
                  }
                }
                entityName="product"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                onError={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  })
                }
                onSuccess={() =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
                afterCreate={res => {
                  this.props.history.push(`/product/${res.id}/modify`);
                }}
              />
            </>
          ) : (
            <>
              <Header as="h2" dividing>
                <FormattedMessage {...messages.title} />
              </Header>
              <ListWrapper>
                <ListManager
                  entityName="product"
                  basePath={API.BASE_URL}
                  permissions={this.props.user}
                  router={this.props}
                  persistentFilter={[
                    {
                      type: 'groupby',
                      field: 'id',
                    },
                  ]}
                  reloadIndex={this.state.reloadIndex}
                  client={pars => request(pars).then(res => res.data)}
                  afterLoad={this.afterLoad}
                  defaultOrder={[
                    {
                      type: 'field',
                      field: 'productCode',
                      direction: 'ASC',
                    },
                  ]}
                  sessionKey="articles-products"
                  locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                  translations={{
                    createButton: {
                      label: this.context.intl.formatMessage(
                        messages.new_product,
                      ),
                    },
                  }}
                  canCreate={{
                    active: PermissionManager.CanI('create', 'product'),
                  }}
                  aliases={this.state.aliases}
                  filterLabels={this.state.filterLabels}
                  // canSearchCustom={productFilters({ intl:this.context.intl, preSelectIsActive: true})}
                  canSearchCustom={ProductSearchBar({
                    intl: this.context.intl,
                    initialValues: this.state.initialValues,
                    filters: this.state.filters,
                    removedFilter: this.state.removedFilter,
                    preSelectActive: true,
                    handleRemovedFilter: this.handleRemovedFilter,
                    onRemoveFilter: this.onRemoveFilter,
                    onSearch: (filters, values, labels) =>
                      this.handleSearch(filters, values, labels),
                  })}
                  onRemoveFilter={this.onRemoveFilter}
                  canExport={{
                    exportLabelPrefix: this.context.intl.formatMessage(
                      appMessages.export_to,
                    ),
                  }}
                  columns={[
                    {
                      key: 'coverImage',
                      width: 150,
                      name: this.context.intl.formatMessage(
                        messages.coverImage,
                      ),
                      searchable: false,
                      sortable: false,
                      formatter: ({ data }) => {
                        const { attachments, loadingAttachments } = this.state;

                        const currentProductImage = attachments.find(
                          el => el.productId === data.id,
                        );

                        if (loadingAttachments) {
                          return (
                            <Placeholder>
                              <Placeholder.Image square />
                            </Placeholder>
                          );
                        }

                        if (!_.isEmpty(currentProductImage)) {
                          return (
                            <div
                              style={{
                                width: '100%',
                                height: 120,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginRight: 0,
                              }}
                            >
                              <img
                                src={currentProductImage.image}
                                style={{
                                  objectFit: 'contain',
                                  height: '100%',
                                  width: '100%',
                                  marginRight: 0,
                                }}
                                alt=""
                              />
                            </div>
                          );
                        }
                        return (
                          <img
                            src={imagePlaceholder}
                            style={{
                              objectFit: 'contain',
                              height: 120,
                              width: '100%',
                              marginRight: 0,
                            }}
                            alt=""
                          />
                        );
                      },
                    },
                    {
                      key: 'productCode',
                      name: this.context.intl.formatMessage(
                        messages.productCode,
                      ),
                      searchable: true,
                      sortable: true,
                    },
                    {
                      key: 'title',
                      name: this.context.intl.formatMessage(
                        messages.description,
                      ),
                      searchable: true,
                      sortable: true,
                    },
                    {
                      key: 'crmCategory',
                      name: this.context.intl.formatMessage(
                        messages.crmCategory,
                      ),
                      useAlias: 'crmCategory',
                      filterName: 'description',
                      searchable: true,
                      sortable: true,
                      formatter: ({ data }) =>
                        _.get(data, 'crmCategory.description', '--'),
                      exportFormatter: ({ data }) =>
                        _.get(data, 'crmCategory.description', '--'),
                    },
                    {
                      key: 'category',
                      name: this.context.intl.formatMessage(messages.category),
                      useAlias: 'category',
                      filterName: 'description',
                      searchable: true,
                      sortable: true,
                      formatter: data =>
                        _.get(
                          data,
                          'data.subcategory.category.description',
                          '--',
                        ),
                      exportFormatter: data =>
                        _.get(
                          data,
                          'data.subcategory.category.description',
                          '--',
                        ),
                    },
                    {
                      key: 'subcategory',
                      name: this.context.intl.formatMessage(
                        messages.subcategory,
                      ),
                      useAlias: 'subcategory',
                      filterName: 'description',
                      searchable: true,
                      sortable: true,
                      formatter: data =>
                        _.get(data, 'data.subcategory.description', '--'),
                      exportFormatter: data =>
                        _.get(data, 'data.subcategory.description', '--'),
                    },
                    {
                      key: 'domain',
                      name: this.context.intl.formatMessage(messages.domain),
                      useAlias: 'domain',
                      filterName: 'description',
                      searchable: true,
                      sortable: true,
                      formatter: data => {
                        const domains = _.get(data, 'data.productDomains', []);

                        if (_.isEmpty(domains)) {
                          return '--';
                        }

                        return (
                          <ul
                            style={{
                              listStyle: 'none',
                              paddingInlineStart: 0,
                            }}>
                            {domains.map(domain => (
                              <li key={domain.id}>{domain.description}</li>
                            ))}
                          </ul>);
                      },
                      exportFormatter: data =>
                        _.get(data, 'data.productDomains', []).join(', '),
                    },
                    {
                      key: 'productSupplier',
                      name: this.context.intl.formatMessage(messages.supplier),
                      useAlias: 'productSupplier',
                      filterName: 'description',
                      searchable: true,
                      sortable: true,
                      formatter: data =>
                        _.get(data, 'data.productSupplier.description', '--'),
                      exportFormatter: data =>
                        _.get(data, 'data.productSupplier.description', '--'),
                    },
                    {
                      key: 'quantityAvailable',
                      name: this.context.intl.formatMessage(messages.quantityAvailable),
                      useAlias: 'productStock',
                      filterName: 'availableQuantity',
                      searchable: true,
                      sortable: true,
                      formatter: data =>
                        _.get(data, 'data.productStock.availableQuantity', '--'),
                      exportFormatter: data =>
                        _.get(data, 'data.productStock.availableQuantity', '--'),
                    },
                    {
                      key: 'maxDiscount',
                      name: this.context.intl.formatMessage(
                        messages.maxDiscount,
                      ),
                      searchable: true,
                      sortable: true,
                      formatter: data =>
                        _.get(data, 'data.maxDiscount', null)
                          ? `${_.get(data, 'data.maxDiscount', null)}%`
                          : '--',
                    },
                    {
                      key: 'productPrice',
                      width: 150,
                      name: this.context.intl.formatMessage(
                        messages.productPrice,
                      ),
                      searchable: false,
                      sortable: false,
                      formatter: ({ data }) => {
                        const { prices } = this.state;

                        const currentPrice = prices.find(
                          el => el.productId === data.id,
                        );

                        return !_.isEmpty(currentPrice) ? `${currentPrice.price}€` : '--';

                      },
                    },
                  ]}
                  defaultActions={{
                    visualize: PermissionManager.CanI('read', 'product'),
                    modify: PermissionManager.CanI('edit', 'product'),
                    delete: PermissionManager.CanI('remove', 'product'),
                    grouped: false,
                    moreActions: [],
                  }}
                />
              </ListWrapper>
            </>
          )}
        </Page>
      </>
    );
  }
}

Products.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'products', reducer });
const withSaga = injectSaga({ key: 'products', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Products);

Products.contextTypes = {
  intl: PropTypes.object.isRequired,
};
