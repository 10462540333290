/*
 * Order Messages
 *
 * This contains all the text for the Order container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Order';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Order container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Order',
  },
  orderNumber: {
    id: `${scope}.orderNumber`,
    defaultMessage: 'Order number',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  orderType: {
    id: `${scope}.orderType`,
    defaultMessage: 'Order Type',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage',
  },
  manage_order: {
    id: `${scope}.manage_order`,
    defaultMessage: 'Manage order',
  },
  manage_order_articles: {
    id: `${scope}.manage_order_articles`,
    defaultMessage: 'Manage order Articles',
  },
  new_order: {
    id: `${scope}.new_order`,
    defaultMessage: 'New order',
  },
  edit_order: {
    id: `${scope}.edit_order`,
    defaultMessage: 'Edit order',
  },
  add_product: {
    id: `${scope}.add_product`,
    defaultMessage: 'Add product',
  },
  change_customer: {
    id: `${scope}.change_customer`,
    defaultMessage: 'Change customer',
  },
  verifyGoodsDiscount: {
    id: `${scope}.verifyGoodsDiscount`,
    defaultMessage: 'Verify SM',
  },
  deleteOrder: {
    id: `${scope}.deleteOrder`,
    defaultMessage: 'Delete order',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Date from',
  },
  end: {
    id: `${scope}.end`,
    defaultMessage: 'Date to',
  },
  duplicate: {
    id: `${scope}.duplicate`,
    defaultMessage: 'Duplicate',
  },
  subject_removed_success: {
    id: `${scope}.subject_removed_success`,
    defaultMessage: 'Order removed success!',
  },
  state_update_success: {
    id: `${scope}.state_update_success`,
    defaultMessage: 'Order state updated succesfully!',
  },
  order_update_success: {
    id: `${scope}.order_update_success`,
    defaultMessage: 'Order updated succesfully!',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  FIXED: {
    id: `${scope}.FIXED`,
    defaultMessage: 'Fixed',
  },
  FLAT: {
    id: `${scope}.FLAT`,
    defaultMessage: 'Flat',
  },
  PERCENTAGE: {
    id: `${scope}.PERCENTAGE`,
    defaultMessage: 'Percentage',
  },
  MARKUP: {
    id: `${scope}.MARKUP`,
    defaultMessage: 'Markup',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  non_compliant_average_discount: {
    id: `${scope}.non_compliant_average_discount`,
    defaultMessage: 'The average discount is above the expected threshold',
  },
  non_compliant_product_discount: {
    id: `${scope}.non_compliant_product_discount`,
    defaultMessage: 'product discount above the expected threshold',
  },
  non_compliant_product_markup: {
    id: `${scope}.non_compliant_product_markup`,
    defaultMessage: 'product markup above the expected threshold',
  },
  non_compliant_insolvent: {
    id: `${scope}.non_compliant_insolvent`,
    defaultMessage: 'The customer has due payments',
  },
  attention: {
    id: `${scope}.attention`,
    defaultMessage: 'Attention',
  },
  action_approve: {
    id: `${scope}.action_approve`,
    defaultMessage: 'Approve',
  },
  action_not_approve: {
    id: `${scope}.action_not_approve`,
    defaultMessage: 'Not approve',
  },
  popup_imported: {
    id: `${scope}.popup_imported`,
    defaultMessage:
      'This order has been imported from the accounting management system',
  },
  total_order: {
    id: `${scope}.total_order`,
    defaultMessage: 'Total order: ',
  },
  avarageDiscount: {
    id: `${scope}.avarageDiscount`,
    defaultMessage: 'Avarage Discount:',
  },
  averageDiscount: {
    id: `${scope}.averageDiscount`,
    defaultMessage: 'Discount',
  },
  avarageMarkup: {
    id: `${scope}.avarageMarkup`,
    defaultMessage: 'Avarage Markup:',
  },
  referenceDate: {
    id: `${scope}.referenceDate`,
    defaultMessage: 'Reference date',
  },
  productCode: {
    id: `${scope}.productCode`,
    defaultMessage: 'Product',
  },
  productTitle: {
    id: `${scope}.productTitle`,
    defaultMessage: 'Description',
  },
  productNote: {
    id: `${scope}.productNote`,
    defaultMessage: 'Note',
  },
  productPriceMaster: {
    id: `${scope}.productPriceMaster`,
    defaultMessage: 'Product price master',
  },
  productPriceCustomer: {
    id: `${scope}.productPriceCustomer`,
    defaultMessage: 'Product price customer',
  },
  priceOffer: {
    id: `${scope}.priceOffer`,
    defaultMessage: 'Price offer',
  },
  netPrice: {
    id: `${scope}.netPrice`,
    defaultMessage: 'Price',
  },
  markup: {
    id: `${scope}.markup`,
    defaultMessage: 'Markup',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Quantity',
  },
  qt: {
    id: `${scope}.qt`,
    defaultMessage: 'Qt.',
  },
  discountValue: {
    id: `${scope}.discountValue`,
    defaultMessage: 'Discount value',
  },
  extraDiscount: {
    id: `${scope}.extraDiscount`,
    defaultMessage: 'Extra Discount',
  },
  priceAmount: {
    id: `${scope}.priceAmount`,
    defaultMessage: 'Price amount',
  },
  goodsDiscount: {
    id: `${scope}.goodsDiscount`,
    defaultMessage: 'SM',
  },
  loan: {
    id: `${scope}.loan`,
    defaultMessage: 'CM',
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions',
  },
  deliveryDate: {
    id: `${scope}.deliveryDate`,
    defaultMessage: 'Delivery',
  },
  sendToAhr: {
    id: `${scope}.sendToAhr`,
    defaultMessage: 'Send to AHR',
  },
  errors: {
    id: `${scope}.errors`,
    defaultMessage: 'Errors',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  totalPriceAmount: {
    id: `${scope}.totalPriceAmount`,
    defaultMessage: 'Total',
  },
  maxDiscount: {
    id: `${scope}.maxDiscount`,
    defaultMessage: 'Max',
  },
  destinationDelivery: {
    id: `${scope}.destinationDelivery`,
    defaultMessage: 'Delivery:',
  },
  orderNote: {
    id: `${scope}.orderNote`,
    defaultMessage: 'Note:',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Businessname',
  },
  orderDate: {
    id: `${scope}.orderDate`,
    defaultMessage: 'Order date',
  },
  net_price_edited: {
    id: `${scope}.net_price_edited`,
    defaultMessage: 'Invalid price',
  },
  net_price_edited_message: {
    id: `${scope}.net_price_edited_message`,
    defaultMessage: 'Discount visible in invoice',
  },
});
