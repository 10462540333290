import React from 'react';
import PropTypes from 'prop-types';
import SimpleFormikField from 'novigo-simple-formik-field';
import { injectIntl, intlShape } from 'react-intl';
// import { FormattedMessage } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import messages from '../messages';
import FormikTelephone from '../../../FormikTelephone';

function CommunicationPart(props) {
  return (
    <div>
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikTelephone
              {...props}
              name="phoneNumber"
              label={props.intl.formatMessage(messages.phone_number)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikTelephone {...props} name="fax" label="Fax" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="email"
              label="Email"
              type="email"
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="emailPec"
              label="PEC"
              type="email"
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="homepage"
              label={props.intl.formatMessage(messages.web_site)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

CommunicationPart.propTypes = {
  intl: intlShape.isRequired,
  entity: PropTypes.string,
};

export default injectIntl(CommunicationPart);
