export function getExpireDate(arr) {
  const obj = arr
    .map(item => {
      if (item.field === 'expireDate') {
        return item;
      }
      return undefined;
    })
    .filter(o => o);
  if (Array.isArray(obj) && obj.length > 1) {
    const last = obj.slice(-1);
    return last ? last[0].value : null;
  }

  if (obj.length > 0) return obj ? obj[0].value : null;

  return undefined;
}

export function getValueByField(arr, field) {
  const obj = arr.find(item => item.field === field);
  return obj ? obj.value : null;
}

export function getValueByType(arr, field) {
  const obj = arr.find(item => item.type === field);
  return obj ? obj.value : null;
}

export function getValueByAlias(arr, field) {
  const obj = arr.find(item => item.alias === field);
  return obj ? obj.value : null;
}

export function getExecutedAt(arr) {
  const obj = arr.find(item => item.field === 'executedAt');
  if (obj === undefined) return undefined;
  if (obj.type === 'isnull') return false;
  if (obj.type === 'isnotnull') return true;
  return true;
}

export function getToday(arr) {
  const obj = arr.find(item => item.field === 'expireDate');
  if (obj === undefined) return false;
  if (obj.type === 'eq') return true;

  return false;
}
