import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'semantic-ui-react';

export const SwitchMode = props => {
  const showButton = props.showAuto && props.showManual;

  return (
    <div>
      {showButton && (
        <Button.Group
          basic
          style={{ float: 'right', position: 'relative', top: -2 }}
        >
          <Button
            size="small"
            disabled={props.disabled}
            active={props.mode === 'auto'}
            onClick={() => props.switch('auto')}
            style={{ fontSize: 12 }}
            type="button"
            content={props.labels.autoBtn}
          />
          <Button
            size="small"
            disabled={props.disabled}
            active={props.mode === 'manual'}
            onClick={() => props.switch('manual')}
            style={{ fontSize: 12 }}
            type="button"
            content={props.labels.manualBtn}
          />
        </Button.Group>
      )}
    </div>
  );
};

SwitchMode.propTypes = {
  switch: PropTypes.func,
  mode: PropTypes.string,
  showAuto: PropTypes.bool,
  showManual: PropTypes.bool,
  labels: PropTypes.shape({
    autoBtn: PropTypes.string,
    manualBtn: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

export default SwitchMode;
