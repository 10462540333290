import { OFFER_STATE_LOSS } from '../../../../containers/Offer/constants';

function Validation(values) {
  const errors = {};
  if (!values.state) {
    errors.state = 'Field required!';
  }
  if (!values.destination.value) {
    errors.destination = 'Field required!';
  }
  if (values.state === OFFER_STATE_LOSS && !values.internalNote) {
    errors.internalNote = 'Field required!';
  }
  return errors;
}

export default Validation;
