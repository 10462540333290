import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_DISCOUNT,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_DISCOUNT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_DISCOUNT}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (destinationId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_DISCOUNT}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'destination',
          value: destinationId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const DestinationDiscountService = {
  getByOwner,
  create,
  update,
  remove,
};

export default DestinationDiscountService;
