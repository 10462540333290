import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.PROFILE,
    method: 'GET',
  });

const searchCommercialDirectorByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          where: 'and',
          field: 'displayName',
          value: `%${searchText}%`,
        },
        // {
        //   type: 'innerjoin',
        //   alias: 'role',
        //   field: 'staffRoleId',
        //   value: 'role',
        // },
        // {
        //   type: 'eq',
        //   alias: 'role',
        //   field: 'name',
        //   value: 'Direttore commerciale',
        // },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getCommercialDirectorOptions = (inputValue, callback) => {
  searchCommercialDirectorByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.staff', []).map(profile => ({
        value: profile.id,
        label: profile.displayName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchAreaManagerByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          where: 'and',
          field: 'displayName',
          value: `%${searchText}%`,
        },
        // {
        //   type: 'innerjoin',
        //   alias: 'role',
        //   field: 'staffRoleId',
        //   value: 'role',
        // },
        // {
        //   type: 'eq',
        //   alias: 'role',
        //   field: 'name',
        //   value: 'area manager',
        // },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAreaManagerOptions = (inputValue, callback) => {
  searchAreaManagerByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.staff', []).map(profile => ({
        value: profile.id,
        label: profile.displayName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchCommercialBackofficeByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          where: 'and',
          field: 'displayName',
          value: `%${searchText}%`,
        },
        // {
        //   type: 'leftjoin',
        //   field: 'user',
        //   alias: 'user',
        // },
        // {
        //   type: 'leftjoin',
        //   field: 'role',
        //   parentAlias: 'user',
        //   alias: 'role',
        // },
        // {
        //   type: 'eq',
        //   alias: 'role',
        //   field: 'roleId',
        //   value: 'ROLE_BACKOFFICE',
        // },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getCommercialBackofficeOptions = (inputValue, callback) => {
  searchCommercialBackofficeByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.staff', []).map(profile => ({
        value: profile.id,
        label: profile.displayName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchAgentByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          where: 'or',
          type: 'like',
          field: 'businessName',
          value: `%${searchText}%`,
        },
        searchText && {
          where: 'or',
          type: 'like',
          field: 'displayName',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAgentOptions = (inputValue, callback) => {
  searchAgentByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.agent', []).map(profile => ({
        value: profile.id,
        label: profile.businessName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchAgentByUserId = id =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'user',
          value: `${id}`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAgentOptionsByUserId = (inputValue, callback) => {
  searchAgentByUserId(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.agent', []).map(profile => ({
        value: profile.id,
        label: profile.displayName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.PROFILE,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PROFILE}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PROFILE}/${id}`,
    method: 'DELETE',
  });

const ProfileService = {
  create,
  update,
  remove,
  getAll,
  getCommercialDirectorOptions,
  getAreaManagerOptions,
  getCommercialBackofficeOptions,
  getAgentOptions,
  getAgentOptionsByUserId,
};

export default ProfileService;
