/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */

import { fromJS } from 'immutable';

import { SET_TOKEN, LOG_OUT, SET_ACL, SET_ERROR } from './constants';

// The initial state of the App
const initialState = fromJS({
  loading: false,
  error: false,
  agenda: null,
  user: {
    data: localStorage.getItem('access_token') ? true : null,
    auth: {
      access_token: null,
      refresh_token: null,
      scope: null,
      token_type: null,
    },
  },
  userIdentity: null,
});

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return state
        .setIn(['user', 'data'], 'valid')
        .setIn(['user', 'auth', 'access_token'], action.tokens.access_token)
        .setIn(['user', 'auth', 'refresh_token'], action.tokens.refresh_token)
        .setIn(['user', 'auth', 'scope'], action.tokens.scope)
        .setIn(['user', 'auth', 'provider'], action.tokens.provider)
        .setIn(['user', 'auth', 'token_type'], action.tokens.token_type);
    case LOG_OUT:
      return state.setIn(['user', 'data'], null).set('userIdentity', null);
    case SET_ACL:
      return state.set('userIdentity', action.data);
    case SET_ERROR:
      return state.set('error', action.error);
    case 'VERSION_LOADING':
      return state.set('version', action.version);
    default:
      return state;
  }
}

export default appReducer;
