/*
 * ProductPriceCustomer Messages
 *
 * This contains all the text for the ProductPriceCustomer container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ProductPriceCustomer';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the ProductPriceCustomer container!',
  },
  new_price: {
    id: `${scope}.new_price`,
    defaultMessage: 'New price',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Supplier',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Date from',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'Date to',
  },
  buyPrice: {
    id: `${scope}.buyPrice`,
    defaultMessage: 'Buy price',
  },
  markup: {
    id: `${scope}.markup`,
    defaultMessage: 'Markup',
  },
  grossPrice: {
    id: `${scope}.grossPrice`,
    defaultMessage: 'Gross price',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  priceTo: {
    id: `${scope}.priceTo`,
    defaultMessage: 'Price to',
  },
  priceList: {
    id: `${scope}.priceList`,
    defaultMessage: 'Price list',
  },
  subcategory: {
    id: `${scope}.subcategory`,
    defaultMessage: 'Sub-category',
  },
  priceFrom: {
    id: `${scope}.priceFrom`,
    defaultMessage: 'Price from',
  },
  buyPriceFrom: {
    id: `${scope}.buyPriceFrom`,
    defaultMessage: 'Buy price from',
  },
  buyPriceTo: {
    id: `${scope}.buyPriceTo`,
    defaultMessage: 'Buy price to',
  },
  coverImage: {
    id: `${scope}.coverImage`,
    defaultMessage: 'Cover',
  },
  product_stock: {
    id: `${scope}.product_stock`,
    defaultMessage: 'Quantity in stock',
  },
  crmCategory: {
    id: `${scope}.crmCategory`,
    defaultMessage: 'CRM category',
  },
  discountValue: {
    id: `${scope}.discountValue`,
    defaultMessage: 'Discount value',
  },
});
