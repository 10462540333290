/*
 * Supplier Messages
 *
 * This contains all the text for the Supplier container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Supplier';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Supplier container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Suppliers',
  },
  nominative: {
    id: `${scope}.nominative`,
    defaultMessage: 'Nominative',
  },
  fiscalcode: {
    id: `${scope}.fiscalcode`,
    defaultMessage: 'Fiscal code',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  telephone: {
    id: `${scope}.telephone`,
    defaultMessage: 'Phone',
  },
  new_supplier: {
    id: `${scope}.new_supplier`,
    defaultMessage: 'New supplier',
  },
});
