import { defineMessages } from 'react-intl';

export const scope = 'app.components.Offer.SearchBar';

export default defineMessages({
  destinationCode: {
    id: `${scope}.destinationCode`,
    defaultMessage: 'Code',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  new_offer: {
    id: `${scope}.new_offer`,
    defaultMessage: 'New Offer',
  },
  offer_number: {
    id: `${scope}.offer_number`,
    defaultMessage: 'Offer Number',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  offer_type: {
    id: `${scope}.offer_type`,
    defaultMessage: 'Offer Type',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  expirationDate: {
    id: `${scope}.expirationDate`,
    defaultMessage: 'Expiration',
  },
  insert_date: {
    id: `${scope}.insert_date`,
    defaultMessage: 'Date',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
});
