import React, { Component } from 'react';
import {
  Grid,
  Button,
  Icon,
  Input,
  Form,
  TextArea,
  Divider,
  Table,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { success } from 'react-notification-system-redux';
import { store } from '../../configureStore';
import QA from './qa';
import Modal from '../Modal';
import messages from './messages';

const StyledButton = Styled(Button)`
  &:hover {
    color: ${props => props.styles.SECOND_COLOR} !important;
  }
`;

/* Simpler, improved hack to keep node refs
*  https://github.com/Semantic-Org/Semantic-UI-React/issues/3786#issuecomment-532175831
*/
const WrappedButton = props => <StyledButton {...props} />;

WrappedButton.propTypes = {
  styles: PropTypes.object,
};

const initialState = {
  modalOpened: false,
  files: [],
  file: false,
  summary: null,
  description: null,
  id: null,
  jiraKey: null,
  QAmode: true,
};

const notificationOpts = (title, message, isError, action) => ({
  // uid: 'once-please', // you can specify your own uid if required
  title,
  message,
  position: 'tr',
  autoDismiss: isError ? 0 : 1,
  action,
});

export default class Ticket extends Component {
  static propTypes = {
    // url: PropTypes.string,
    // token: PropTypes.string,
    projectId: PropTypes.string,
    ticketType: PropTypes.string,
    user: PropTypes.object,
    styles: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      files: [],
      file: false,
      summary: null,
      description: null,
      id: null,
      jiraKey: null,
      QAmode: true,
    };
  }

  onUploadChange = e => {
    const files = _.cloneDeep(this.state.files); //eslint-disable-line
    files.push(e.target.files[0]);
    this.setState({ files }, () => this.uploadFile());
  };

  resetState = () => this.setState({ ...initialState });

  removeFile = index => {
    const files = _.cloneDeep(this.state.files); //eslint-disable-line
    files.splice(index, 1);
    this.setState({ files });
  };

  createIssue = () => {
    const data = {
      fields: {
        project: {
          id: this.props.projectId,
        },
        issuetype: {
          id: this.props.ticketType,
        },
        summary: this.state.summary,
        description: this.state.description,
        customfield_10301: _.get(
          this.props.user,
          'username',
          'unknown username',
        ), // username
        customfield_10302: _.get(this.props.user, 'email', 'unknown email'), // email
        customfield_10303: navigator.appVersion, // browser info
        customfield_10300: window.location.href, // url
      },
    };
    const url = this.state.id ? `issue/${this.state.id}` : `issue`;
    // const hash = btoa(token);
    // const Basic = `Basic ${hash}`;

    const payload = _.cloneDeep(data);
    /*
    * Hardcoded contidion: switches between test and prod env
    * TODO: Manage Jira env / Move config to BE
    */
    if (true) {
      payload.fields.customfield_10603 = payload.fields.customfield_10301;
      payload.fields.customfield_10601 = payload.fields.customfield_10302;
      payload.fields.customfield_10600 = payload.fields.customfield_10303;
      payload.fields.customfield_10602 = payload.fields.customfield_10300;
      payload.fields.customfield_10700 = payload.fields.customfield_10501;

      delete payload.fields.customfield_10301;
      delete payload.fields.customfield_10302;
      delete payload.fields.customfield_10303;
      delete payload.fields.customfield_10300;
      delete payload.fields.customfield_10501;
    }

    return this.props.service.createIssue({
      route: url,
      payload,
    });
    /* return axios({
      method: this.state.id ? 'PUT' : 'POST',
      url,
      withCredentials: true,
      headers: {
        Authorization: Basic,
        'Content-Type': 'application/json',
      },
      data,
    }); */
  };

  newIssue = () => {
    this.setState({ isLoading: true });
    this.createIssue()
      .then(res => {
        this.setState(
          { id: res.data.id, jiraKey: res.data.key, isLoading: false },
          this.startUpload,
        );
        store.dispatch(
          success(
            notificationOpts(
              `Operazione eseguita con successo`,
              `Ticket ${res.data.key} inserito con successo.`,
              false,
              null,
            ),
          ),
        );
        if (!this.state.file) {
          this.resetState();
          this.closeModal();
        }
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  startUpload = () => {
    if (!this.state.file) return;
    this.uploadFiles()
      .then(() => {
        store.dispatch(
          success(
            notificationOpts(
              `Operazione eseguita con successo`,
              `Allegato del ticket
              ${this.state.jiraKey} inserito con successo.`,
              false,
              null,
            ),
          ),
        );
        this.closeModal();
        this.resetState();
      })
      .catch();
  };

  uploadFiles = () => {
    const { file } = this.state;
    // const hash = btoa('m.raguzzini:bcpwd2006');
    // const Basic = `Basic ${hash}`;
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('method', 'POST');
    data.append('route', `issue/${this.state.jiraKey}/attachments`);
    data.append('params', '{}');
    data.append('microserviceName', 'jira');
    data.append('headers', '{"X-Atlassian-Token":"no-check"}');

    return this.props.service.uploadAttachment(data);
    /* data.append('file', file, file.name);
    return axios.post(
      `${this.props.url}/issue/${this.state.jiraKey}/attachments`,
      data,
      {
        headers: {
          'X-Atlassian-Token': 'no-check',
          'Content-Type': 'multipart/form-data',
          Authorization: Basic,
        },
      },
    ); */
  };

  prePareUploadFile = e => {
    const file = e.target.files[0];
    this.setState({ file });
    return false;
  };

  openModal = () => this.setState({ modalOpened: true });

  closeModal = () => this.setState({ modalOpened: false });

  render() {
    return (
      <div>
        <Modal
          onClose={() => {
            this.setState({ QAmode: true });
            this.closeModal();
          }}
          title={
            <div>
              <Icon name="ticket" />
              {this.state.jiraKey
                ? `Ticket assistenza ${this.state.jiraKey}`
                : "Hai bisogno d'aiuto ?"}
            </div>
          }
          trigger={
            <WrappedButton
              fluid
              inverted
              onClick={this.openModal}
              styles={this.props.styles}
            >
              <Icon name="ticket" />
              <FormattedMessage {...messages.need_help} />
            </WrappedButton>
          }
          size="mini"
          style={{ width: 650 }}
          open={this.state.modalOpened}
          scrolling
        >
          {!this.state.QAmode ? (
            <Form>
              <Grid>
                <Grid.Column width={16}>
                  <div>
                    <b>Titolo</b>
                  </div>
                  <Input
                    onBlur={e => this.setState({ summary: e.target.value })}
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <div>
                    <b>Descrizione</b>
                  </div>
                  <TextArea
                    placeholder="Descrivi il tuo problema"
                    onBlur={e => this.setState({ description: e.target.value })}
                  />
                </Grid.Column>
                <Input
                  id="file"
                  type="file"
                  onChange={this.prePareUploadFile}
                />
                <Grid.Column width={16} style={{ display: 'none' }}>
                  {this.state.files.length > 0 && (
                    <Table celled>
                      <Table.Body>
                        {this.state.files.map((file, index) => (
                          <Table.Row>
                            <Table.Cell>{file.name}</Table.Cell>
                            <Table.Cell style={{ width: 10 }}>
                              <Button
                                circular
                                negative
                                style={{
                                  padding: '10px 5px 12px 14px',
                                  borderRadius: '100%',
                                }}
                                onClick={() => this.removeFile(index)}
                              >
                                <Icon name="remove" />
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  )}
                </Grid.Column>
                <Grid.Column width={16}>
                  <Divider style={{ marginLeft: -22, marginRight: -22 }} />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button
                    icon="remove"
                    labelPosition="left"
                    type="button"
                    negative
                    fluid
                    content="Annulla"
                    onClick={() => {
                      this.setState({ QAmode: true });
                      this.closeModal();
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button
                    icon="check"
                    labelPosition="right"
                    type="button"
                    positive
                    fluid
                    loading={this.state.isLoading}
                    disabled={this.state.isLoading}
                    content="Invia"
                    onClick={() => {
                      this.newIssue();
                    }}
                  />
                </Grid.Column>
              </Grid>
            </Form>
          ) : (
            <QA
              styles={this.props.styles}
              changeMode={() => this.setState({ QAmode: false })}
            />
          )}
        </Modal>
      </div>
    );
  }
}
