/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import { Formik } from 'formik';
import find from 'lodash/find';
import forOwn from 'lodash/forOwn';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import random from 'lodash/random';
import get from 'lodash/get';
import unset from 'lodash/unset';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import wq from 'with-query';
import Actions from './Actions';
import CustomSearch from './CustomSearch';
import DefaultFooter from './DefaultFooter';
import FilterBox from './FilterBox';
import MenuActions from './MenuActions';
import client from './request';
import { GridShape } from './Shapes';
import DeterchimicaTableGrid from '../../DeterchimicaTableGrid';
/**
 *
 * ### formally called NovigoEntityGrid
 *
 * A general component that manage CRUD of an entity in the Zend HAL-JSON format
 *
 * Based on Novigo SemanticTableGrid and built using Semantic-React as UI Kit
 *
 * __built on SemanticTableGrid it means you can use all the STG props cause these are
 * argumented and passed to the original STG element, check that config  __

## Documentation

[Styleguidist generated documentations](./styleguide/index.html) (running in the browser)

## Coverage

[Jest coverage report](./coverage/lcov-report/index.html) (running in the browser)

## Build package report

[Webpack package report](./build/report.html) (running in the browser)

## Quality check

[Plato report](./report/index.html)

#bug

- When you change filter, you need to start from page 1 or if you're in a number page that after the filter doesn't exist anymore, 409 error is triggered

- If you select something and then filter, you cannot deselect anymore. We can create anither button to deselect all otherwise the behaviour of deselect should be changed to all (now deselect only the element that are visible, work well for precise selection, not so good for group selection )

- exportName seems Ignored
 *
 */
class EntityGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: false,
      pageRequest: 1,
      sort: null,
      recordsPerPage: this.getRecordsPerPageDefault(),
      customSearchIsOpen: false,
      // eslint-disable-next-line react/destructuring-assignment
      filter: [],
      // eslint-disable-next-line react/destructuring-assignment
      customerSearchFilter: [],
      // eslint-disable-next-line react/destructuring-assignment
      filterLabels: [],
      //
      selectedRows: [],
      id: `${random(0, 999)}-${random(0, 999)}-${random(0, 999)}-${random(
        0,
        999,
      )}-entityTable`,
      // eslint-disable-next-line react/destructuring-assignment
      sessionID:
        this.props.sessionKey &&
        !isEmpty(this.props.sessionKey) &&
        typeof this.props.sessionKey === 'string'
          ? `${this.props.sessionKey}-entityTable`
          : null,
      values: {},
      searchedValue: '',
    };
  }

  /**
   * On mount first check if there are saved filters in session storage and apply accordingly.
   * If no session storage then continue normal data load.
   * Also applies an eventlistener on beforeunload to save the data on refresh.
   */
  componentDidMount() {
    const { sessionID } = this.state;
    if (sessionID) {
      const savedData = JSON.parse(sessionStorage.getItem(sessionID));

      if (!isEmpty(savedData) && savedData !== null && savedData) {
        this.setState({ ...savedData }, () => this.loadEntities());
      } else if (!this.props.disableInitialLoad) this.loadEntities();
    } else if (!this.props.disableInitialLoad) this.loadEntities();
  }

  /**
   * If the componenet is dependant to reloadIndex, control it.
   * If index changed, reload
   *
   */
  componentDidUpdate(prevProps) {
    const { reloadIndex = null } = this.props;
    if (reloadIndex && prevProps.reloadIndex !== reloadIndex) {
      this.onDeselectAllRows();
      this.loadEntities();
    }
  }

  /**
   * On unmount to save the state, filters and search options and
   * to cleanup the beforeunload event listener.
   */
  componentWillUnmount() {
    const { sessionID } = this.state;
    if (sessionID) {
      this.saveData();
    }
  }

  /**
   * Function that fires when the component is unmounted or window is refreshed
   * to save the current state, filters and search options.
   */
  saveData = () => {
    const { sessionID } = this.state;
    if (sessionID) {
      const savingData = { ...this.state };
      sessionStorage.setItem(sessionID, JSON.stringify(savingData));
    }
  };

  /**
   * Create the params used by the client
   * based on doctrine query filter
   */
  getParams = () => {
    const {
      sort,
      filter,
      customerSearchFilter,
      pageRequest: page,
      recordsPerPage,
    } = this.state;

    const {
      aliases = [],
      persistentFilter = [],
      defaultOrder = [],
    } = this.props;

    return {
      page: page || 1,
      pageSize: recordsPerPage,
      filter: [
        ...filter,
        ...customerSearchFilter,
        ...persistentFilter,
        ...aliases,
      ],
      'order-by': sort || [...defaultOrder],
    };
  };

  /**
   * CLIENT FETCHING
   */
  loadEntities = actions =>
    this.setState({ isLoading: true }, () => this.loadRequest(actions));

  /**
   * Launch the request using that passed client
   * @param {*} actions
   */
  loadRequest = actions => {
    const { basePath, entityName, afterLoad = null } = this.props;
    this.request({
      url: wq(`${basePath}/${entityName}`, this.getParams()),
      method: 'GET',
    }).then(res => {
      this.setState(
        { data: res, isLoading: false },
        () => (afterLoad ? afterLoad(res) : null),
      );

      if (actions) {
        actions.setSubmitting(false);
      }
    });
  };

  /**
   * This happen when you try to change page
   * @param {number} gotoPage
   */
  changePage = gotoPage =>
    this.setState({ pageRequest: gotoPage, isLoading: true }, () => {
      const { autoScroll = true } = this.props;

      this.loadEntities();
      if (autoScroll) {
        this.scrollToTop();
      }
    });

  /**
   * This happen when you try to change records per page
   * @param {number} recordsPerPage
   */
  changeRecordsPerPage = recordsPerPage =>
    this.setState({ recordsPerPage, isLoading: true }, () =>
      this.loadEntities(),
    );

  /**
   * Get the default records per page
   */
  getRecordsPerPageDefault = () => {
    const { pageSize = 10, pageSizeSelection } = this.props;
    return pageSizeSelection ? 10 : pageSize;
  };

  /**
   * Triggered when clicked to change sort.
   * Key is the key name of the column whos clicked to change th esort.
   * Sort is the direction or 'none', request on the column.
   * If none, you should to pristine the original order.
   *
   * @param {string} key
   * @param {string} order
   */
  changeSort = (key, order) => {
    let sort = false;

    if (order !== 'none') {
      const searchedColumns = find(this.getColumns(), { key });

      sort = {
        type: searchedColumns.customSortType || 'field',
        field: searchedColumns.filterName || searchedColumns.originalKey,
        direction: order,
      };

      if (searchedColumns.useAlias) {
        sort.alias = searchedColumns.useAlias;
      }
    }

    this.setState(
      {
        sort: sort ? [sort] : null,
        isLoading: true,
      },
      () => {
        const { handleSort = null } = this.props;
        if (handleSort && typeof handleSort === 'function' && sort) {
          handleSort(sort);
        }
        this.loadEntities();
      },
    );
  };

  /**
   * Return default column if you have not
   */
  getDefaultColumns = defaultActions => {
    const {
      modify = true,
      visualize = true,
      delete: deletes = true,
      grouped = false,
      moreActions = null,
      invertedOrder = false,
    } = defaultActions;

    const {
      entityName,
      routerName,
      router = null,
      translations = {},
    } = this.props;

    return {
      key: 'actions',
      name: '',
      width: grouped ? 60 : 140,
      formatter: ({ value, data }) => (
        <MenuActions
          entityName={entityName}
          value={value}
          data={data}
          visualize={visualize}
          modify={modify}
          delete={deletes}
          actions={moreActions}
          grouped={grouped}
          router={router}
          invertedOrder={invertedOrder}
          routerName={routerName}
          translations={translations}
        />
      ),
      sortable: false,
      searchable: false,
    };
  };

  /**
   * Get the columns of ID, its prepared cause it's added by default
   */
  getEntityDefaultColumns = () => ({
    key: 'id',
    name: 'ID',
    sortable: true,
    searchable: true,
  });

  /**
   * Return columns based on props and defaults
   */
  getColumns = () => {
    const { columns: requestColumns = [], defaultActions = false } = this.props;

    const columns = [
      ...requestColumns.map(el => {
        const {
          useAlias = null,
          key = '',
          formatter = null,
          useHyperLink = null,
        } = el;

        /**
         * The default column content being returned and applied.
         * Assign a composite key if columns use an alias
         */
        const defaultElement = {
          ...el,
          key: useAlias ? `${useAlias}|${key}` : key,
          originalKey: key,
        };

        // First check if there is a hyperlink and is active and continue accordingly.
        if (useHyperLink && useHyperLink.active === true) {
          // If hyperlink is active continue to customize the formatters
          // in order to return the <a> tag with the supplied routes and name.

          const { entityName } = this.props;
          /**
           * The hyperlink routeName defaults to the current 'entityName' supplied to the props.
           * The route key defaults to 'id' since its the usual one we need.
           * Route action can be view, modify or delete, defaults to view
           */
          const {
            routeName = entityName,
            routeKey = 'id',
            routeAction = 'view',
          } = useHyperLink;

          // If no formatter function has been supplied to the column properties
          // then create an own formatter based on the key only.
          if (!formatter) {
            return {
              ...defaultElement,
              /**
               * Creating a formatter to return the data based on the KEY from the
               * column data only plus the hyperlink.
               * @param {object} data The data for the current column.
               * @returns The default data by key and the hyperlink.
               */
              formatter: ({ data }) => {
                const redirectKey = get(data, routeKey, null);
                return redirectKey ? (
                  <Link
                    to={`${routeName}/${redirectKey}${
                      routeAction ? `/${routeAction}` : ''
                    }`}
                    style={{ textDecoration: 'underline' }}
                  >
                    {get(data, key, '--')}
                  </Link>
                ) : (
                  get(data, key, '--')
                );
              },
            };
            // If a formatter function has been supplied,
            // take it and customize it to be hyperlink data.
          }
          if (formatter && typeof formatter === 'function') {
            /**
             * The custom format function that replaces the original supplied one.
             * @param {object} data The data for the current column .
             * @returns Customized formatter function with the data made as a hyperlink or
             * just the formatted data.
             */
            const newFormatter = ({ data }) => {
              /**
               * If nothing is found then the hyperlink won't be shown.
               * But instead just the formatted data.
               * @param routeKey Can be nested '_embedded.something.id'.
               */
              const redirectKey = get(data, routeKey, null);
              return redirectKey ? (
                <Link
                  to={`${routeName}/${redirectKey}${
                    routeAction ? `/${routeAction}` : ''
                  }`}
                  style={{ textDecoration: 'underline' }}
                >
                  {formatter({ data })}
                </Link>
              ) : (
                formatter({ data })
              );
            };

            // Return the element with the replaced formatter.
            return {
              ...defaultElement,
              formatter: newFormatter,
            };
          }
        }
        // Else returns the element as it was provided in the column options.
        return defaultElement;
      }),
    ];

    /**
     * if there are not columns, set columns by defaults
     */
    if (!columns.length) {
      columns.push(this.getEntityDefaultColumns());
    }

    /**
     * if defult Action requested, create one
     */
    if (defaultActions) {
      columns.push(this.getDefaultColumns(defaultActions));
    }

    return columns;
  };

  /**
   * Return the list of columns searchable
   */
  getSearchableColumns = () =>
    this.getColumns().filter(
      el => el.searchable === undefined || el.searchable,
    );

  /**
   * Add filters in the filter array, then reload GET
   */
  addSearchParam = searchedValue => {
    let filter = [];
    if (searchedValue && searchedValue.trim()) {
      const filterableColumns = this.getSearchableColumns();

      this.setState({ searchedValue });

      filter = filterableColumns.map(el => {
        let value = `%${searchedValue}%`;

        if (typeof el.searchValueFormatter === 'function') {
          value = el.searchValueFormatter(searchedValue);
        } else {
          if (el.matchStringFromEnd) {
            value = `%${searchedValue}`;
          }

          if (el.matchStringFromStart) {
            value = `${searchedValue}%`;
          }
        }

        const singleFilter = {
          type: 'like',
          where: 'or',
          field: el.filterName || el.originalKey,
          value,
        };

        if (el.useAlias) {
          singleFilter.alias = el.useAlias;
        }

        return singleFilter;
      });
    }

    this.setState({ filter, pageRequest: 1, isLoading: true }, () => {
      const { onDefaultSearch = null } = this.props;
      if (onDefaultSearch && typeof onDefaultSearch === 'function') {
        onDefaultSearch(searchedValue, filter);
      }
      this.loadEntities();
    });
  };

  /**
   * Add filter is search is custom
   */
  addSearchParamCustomSearch = (searchedValues, actions) => {
    const filter = [];
    const filterLabels = [];

    forOwn(searchedValues, (value, key) => {
      if (!value.trim()) {
        return null;
      }

      /**
       * Search the column Options to get the information about filters
       */
      const columnOptions = this.getColumnsOption(key);

      let formattedValue = `%${value}%`;

      if (columnOptions.matchStringFromEnd) {
        formattedValue = `%${value}`;
      }

      if (columnOptions.matchStringFromStart) {
        formattedValue = `${value}%`;
      }

      const singleFilter = {
        type: 'like',
        where: 'and',
        originalName: columnOptions.originalName,
        field: columnOptions ? columnOptions.originalKey : key,
        value: formattedValue,
      };

      if (columnOptions && columnOptions.useAlias) {
        singleFilter.alias = columnOptions.useAlias;
      }

      /**
       * prepare map
       */
      filterLabels.push({
        key,
        name: columnOptions.name,
        value,
        filterField: key,
      });

      return filter.push(singleFilter);
    });

    this.setState(
      {
        customerSearchFilter: filter,
        filterLabels,
        pageRequest: 1,
        isLoading: true,
      },
      () => this.loadEntities(actions),
    );
  };

  /**
   * this function is used to be passed to the external form if you want
   * to use one custome form isntead the autogenerated one.
   */
  applyfilter = (filter, filterLabels, actions) => {
    this.setState(
      {
        customerSearchFilter: filter,
        filterLabels,
        pageRequest: 1,
        isLoading: true,
      },
      () => this.loadEntities(actions),
    );
  };

  handleResetFilters = () => {
    this.setState(
      () => ({
        customerSearchFilter: [],
        filterLabels: [],
        values: [],
      }),
      () => {
        const { onResetFilters = null } = this.props;
        if (onResetFilters && typeof onResetFilters === 'function') {
          onResetFilters();
        }
        this.loadEntities();
      },
    );
  };

  /**
   *
   * @param {string} filterName The name of the filter to remove
   * Checks if type is orx and goes deep in conditions to filter accordingly.
   * Filters all other elements with the same filterField and filterName.
   */
  removeFilter = filterName => {
    const { values, customerSearchFilter, filterLabels } = this.state;
    unset(values, filterName);

    const filteredCustomerSearchFilter = customerSearchFilter.filter(el => {
      if (el.type === 'orx') {
        if (
          el.conditions.length &&
          el.conditions.some(x => x.field === filterName)
        ) {
          return null;
        }
      }

      if (el.field !== filterName) {
        return el;
      }

      return null;
    });

    const filteredLabels = filterLabels.filter(
      el => el.filterField !== filterName,
    );

    this.setState(
      () => ({
        customerSearchFilter: filteredCustomerSearchFilter,
        filterLabels: filteredLabels,
        values,
      }),
      () => {
        const { onRemoveFilter = null } = this.props;
        if (onRemoveFilter && typeof onRemoveFilter === 'function') {
          onRemoveFilter(filterName);
        }
        this.loadEntities();
      },
    );
  };

  getColumnsOption = key =>
    this.getColumns().find(el => el.key === key || el.filterName === key);

  /**
   * If the elemnt if in the array selected IDs, then apply the custom property
   */
  reconciliation = (Ids, elements) =>
    elements.map(elemento =>
      Object.assign({}, elemento, {
        'entitygrid-selected': Boolean(includes(Ids, elemento.id)),
      }),
    );

  /**
   * Elaborate elment returned by api
   */
  getElements = () => {
    const { data, selectedRows } = this.state;
    const { entityName } = this.props;
    const elements = this.reconciliation(
      selectedRows,
      get(data, `_embedded.${entityName}`, []),
    );

    return elements;
  };

  /**
   * Return the total number of elements passed by api
   */
  getElementsNumbers = () => {
    const { data } = this.state;
    return get(data, 'total_items', 0);
  };

  /**
   * Manage the click on 'Create' button.
   * This operation need the routing as dependance, so if isn't a console warning is throw
   */
  onCreate = () => {
    const { router = null, entityName, routerName, canCreate } = this.props;
    const { action: customAction } = canCreate;
    const routerPath = routerName || entityName;

    if (customAction) {
      customAction();
    } else if (get(router, 'history')) {
      router.history.push(`/${routerPath}/new`);
    } else {
      // eslint-disable-next-line no-console
      console.warn('This operation is routing dependant but props is empty');
    }
  };

  /**
   * DATA SELECTION
   */

  /**
   * If the row is selected, push it's id in the row
   * @param {*} row
   */
  onSelectedRow = row => {
    this.setState(prev => ({ selectedRows: [...prev.selectedRows, row.id] }));

    const { canSelect = null } = this.props;
    const { handleSelectRow = null } = canSelect;
    if (handleSelectRow && typeof handleSelectRow === 'function') {
      handleSelectRow(row);
    }
  };

  /**
   * If the row is deselected, remove that id from the list
   */
  onDeselectedRow = row => {
    this.setState(prev => ({
      selectedRows: prev.selectedRows.filter(id => id !== row.id),
    }));

    const { canSelect = null } = this.props;
    const { handleDeselectRow = null } = canSelect;
    if (handleDeselectRow && typeof handleDeselectRow === 'function') {
      handleDeselectRow(row);
    }
  };

  /**
   * When selectAll is pressed,
   * you need to select all the element
   * in the actual list?
   */
  onSelectAllRows = () => {
    /**
     * get the data, and use that for select all the ids?
     * TODO test it.
     */
    const { data, selectedRows } = this.state;
    const { entityName } = this.props;
    const visibleElements = get(data, `_embedded.${entityName}`, []);

    /**
     * Apply for all the visible element
     */
    const selectedVisibleIds = visibleElements.map(el => el.id);

    this.setState(
      prev => ({
        selectedRows: [
          ...new Set([...prev.selectedRows, ...selectedVisibleIds]),
        ],
      }),
      () => {
        const { canSelect = null } = this.props;
        const { handleSelectAll = null } = canSelect;
        if (handleSelectAll && typeof handleSelectAll === 'function') {
          handleSelectAll({ selectedRows, allSelected: true });
        }
      },
    );
  };

  /**
   * Deselect all the items
   */
  onDeselectAllRows = () => {
    const { data, selectedRows } = this.state;
    const { entityName } = this.props;
    const visibleElements = get(data, `_embedded.${entityName}`, []);

    /**
     * Apply for all the visible element
     */
    const selectedVisibleIds = visibleElements.map(el => el.id);

    /**
     * remove the ids if is in the selectedRows
     */
    const cleanIds = selectedRows.filter(
      id => !includes(selectedVisibleIds, id),
    );

    this.setState({ selectedRows: cleanIds });

    const { canSelect = null } = this.props;
    const { handleDeselectAll = null } = canSelect;
    if (handleDeselectAll && typeof handleDeselectAll === 'function') {
      handleDeselectAll();
    }
  };

  /**
   * Get the selection actions to put in the action area
   */
  getSelectionActions = () => {
    const { selectedRows } = this.state;
    const { canSelect } = this.props;
    if (canSelect && canSelect.actions) {
      return canSelect.actions.map(
        ({
          icon = null,
          label = '',
          color = null,
          availableOnSelection = false,
          onClick = null,
          isLoading = false,
          disabled = false,
        }) => (
          <Button
            type="button"
            loading={isLoading}
            icon={icon}
            labelPosition={icon ? 'left' : null}
            key={`selectable-action-${label}`}
            color={color}
            onClick={() => onClick(selectedRows)}
            disabled={
              isLoading ||
              disabled ||
              (availableOnSelection && selectedRows.length === 0)
            }
          >
            {icon && <Icon name={icon} />}
            {label}
          </Button>
        ),
      );
    }

    return [];
  };

  /**
   * The columns of table in export file
   * return array of string.
   *
   * Is het key, return key, otherwise the name of the column, but not 'actions' column
   */
  parsedColumns = isGetKey =>
    Array.from(
      this.getColumns(),
      item => (isGetKey ? item.key : item.exportName || item.name),
    ).filter(item => item !== 'actions');

  /**
   * Get value in row by key or formatter
   */
  getKeyOrFormatter = (data, key) => {
    // Get columns setup
    const columns = this.getColumns();
    // Get current column
    const currentColumn = columns.find(column => column.key === key);
    // Get export formatter
    const formatter = currentColumn.exportFormatter;
    /*
     * If formatter is present, return formatted data
     * otherwise, it returns the value, even if null,  or a placeholder
     */
    return formatter ? formatter({ data }) : get(data, [`${key}`], '--');
  };

  /**
   * The data of table in export file
   * return array of array
   */
  formatDataParams = serviceName => {
    const { data } = this.state;
    const { entityName } = this.props;
    const header = this.parsedColumns(true);
    const dataExport = get(data, `_embedded.${entityName}`);
    let dataBody = [];
    /**
     * If there is columns, and there is data create exporting data
     */
    if (header.length > 0 && dataExport) {
      dataBody = Array.from(dataExport, item =>
        Array.from(header, key => this.getKeyOrFormatter(item, key)),
      );
    }

    const headerClean = this.parsedColumns(false);

    return {
      header: headerClean,
      body: dataBody,
      format: serviceName,
      filename: 'export',
    };
  };

  /**
   * Enable reload page when wait a response from server
   * change state of isLoading
   * @param {*} params
   */
  handleExport = (params, extension) =>
    this.setState({ isLoading: true }, () =>
      this.callRequestExport(params, extension),
    );

  /**
   * Launch the export request using that passed client
   * If exportServiceEndpoint is not set, use the basepath instead
   * @param {*} params
   */
  callRequestExport = (params, extension) => {
    const { canExport, basePath } = this.props;
    const { exportServiceEndpoint = '' } = canExport;
    this.request({
      url: `${exportServiceEndpoint || basePath}/forward-request`,
      method: 'POST',
      responseType: 'blob',
      data: {
        microserviceName: 'excel',
        method: 'POST',
        route: 'export-excel',
        params,
      },
    })
      .then(blob => {
        /**
         * Downloading by 'a' simulation if you want to change file name
         */
        const element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', window.URL.createObjectURL(blob));
        element.setAttribute('download', `export.${extension}`);
        element.style.display = '';
        element.click();
        document.body.removeChild(element);
        /**
         * It's ok remove isLoading
         */
        this.setState({ isLoading: false });
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  /**
   * Scroll To top
   */
  scrollToTop = () => {
    try {
      const { id } = this.state;
      const myPosition = document
        .getElementById(`${id}-container`)
        .getBoundingClientRect();

      const verticalGutter = 0;

      /**
       * If you over to the start position, scroll
       */
      const limit = myPosition.top + window.scrollY - verticalGutter;

      if (window.scrollY > limit) {
        window.scroll({
          top: limit,
          left: 0,
          behavior: 'smooth',
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.warn('cannot scroll to top');
    }
  };

  /**
   * This happen when you try to search using custom search
   */
  onSearch = (values = {}, actions) => {
    const { canSearchCustom = {} } = this.props;
    const {
      onApplyFilter = null,
      active: formIsActive = false,
    } = canSearchCustom;

    /**
     * If is a custom form, use the custom, otherwise, use the base
     */
    this.setState(
      {
        values,
      },
      () => {
        if (formIsActive && onApplyFilter) {
          onApplyFilter(values, actions, this.applyfilter);
        } else {
          this.addSearchParamCustomSearch(values, actions);
        }
      },
    );
  };

  /**
   * Open and close the custom Search
   */
  handleCustomSearchTitleClick = () =>
    this.setState(prev => ({ customSearchIsOpen: !prev.customSearchIsOpen }));

  /**
   * Simulate a client
   * @param {*} pars
   */
  request(pars) {
    const { client: customClient = null } = this.props;
    return customClient ? customClient(pars) : client(pars);
  }

  /**
   * REACT render method,
   * keep this part simple and clean
   */
  render() {
    const {
      canSearch = true,
      searchPlaceholder = '',
      emptyResults = <div>No results</div>,
      canSearchCustom = {},
      canCreate = {},
      canSelect = {},
      canExport = {},
      pageSizeSelection = true,
      componentsPosition = 'bottom',
      // onResetFilters = null,
      intl,
      translations = {},
      customPaginator = null,
      counterLabels = {},
      searchColumnWidth = 6,
      actionsColumnWidth = 10,
    } = this.props;
    /**
     * Custom form properties
     */
    const {
      formStructure = false,
      active: formIsActive = true,
    } = canSearchCustom;

    /**
     * Creation properties
     */
    const {
      active: canCreateIsActive = true,
      isLoading: canCreateLoading = false,
      disabled: canCreateDisabled = false,
    } = canCreate;

    const { totalItems = true, selectedItems = true } = counterLabels;

    const {
      active: canSelectIsActive = false,
      selectAll: canSelectAllIsActive = false,
    } = canSelect;

    const { active: isExport = true } = canExport;

    const {
      customSearchIsOpen = false,
      filterLabels = [],
      isLoading = false,
      data,
      recordsPerPage,
      selectedRows,
      customerSearchFilter,
      id,
      searchedValue = '',
      values = {},
      pageRequest,
    } = this.state;

    return (
      <div id={`${id}-container`} style={{ scrollBehavior: 'smooth' }}>
        <Formik
          onSubmit={this.onSearch}
          initialValues={values}
          enableReinitialize
        >
          {formikProps => (
            <>
              {formIsActive && (
                <CustomSearch
                  searchableFields={this.getSearchableColumns()}
                  customSearchIsOpen={customSearchIsOpen}
                  customSearchFilterNumber={customerSearchFilter.length || 0}
                  customSearchHandleClick={this.handleCustomSearchTitleClick}
                  formStructure={formIsActive && formStructure}
                  onSearch={this.onSearch}
                  formikProps={formikProps}
                  onResetFilters={this.handleResetFilters}
                  intl={intl}
                  translations={translations}
                />
              )}
              {Boolean(filterLabels.length) && (
                <FilterBox
                  filterLabels={filterLabels}
                  removeFilter={this.removeFilter}
                  formikProps={formikProps}
                  intl={intl}
                />
              )}
            </>
          )}
        </Formik>

        <DeterchimicaTableGrid
          rawProps={{ id }}
          isLoading={isLoading}
          elements={this.getElements()}
          rowClassKey="class"
          rowUniqueKey="id"
          canSort={{
            active: true,
            onSort: this.changeSort,
          }}
          canPaginate={{
            active: true,
            componentsPosition,
            render: (
              <DefaultFooter
                pageSizeSelection={pageSizeSelection}
                data={data}
                recordsPerPage={recordsPerPage}
                changeRecordsPerPage={this.changeRecordsPerPage}
                changePage={this.changePage}
                formatDataParams={this.formatDataParams}
                isExport={isExport}
                exportParams={canExport}
                handleExport={this.handleExport}
                intl={intl}
                translations={translations}
                customPaginator={customPaginator}
                page={pageRequest}
              />
            ),
          }}
          canAction={{
            active: true,
            actions: [
              <Actions
                key="action-defaults"
                canSearch={canSearch}
                searchPlaceholder={searchPlaceholder}
                searchedValue={searchedValue}
                onSearch={this.addSearchParam}
                canCreate={canCreateIsActive}
                canCreateLoading={canCreateLoading}
                canCreateDisabled={canCreateDisabled}
                intl={intl}
                translations={translations}
                onCreate={this.onCreate}
                counter={{
                  active: totalItems,
                  number: this.getElementsNumbers(),
                }}
                selectedCounter={{
                  active: canSelectIsActive,
                  selectedNumber: selectedRows.length,
                  showSelected: selectedItems,
                }}
                /**
                 * These are Prepared button
                 */
                selectionActions={this.getSelectionActions()}
                columnWidthSearchbar={searchColumnWidth}
                columnWidthActions={actionsColumnWidth}
              />,
            ],
          }}
          hiddenHeaderIfEmpty
          emptyResults={emptyResults}
          {...this.props}
          canSelect={{
            active: canSelectIsActive,
            selectAll: canSelectAllIsActive,
            onSelect: row => this.onSelectedRow(row),
            onDeselect: row => this.onDeselectedRow(row),
            onSelectAll: () => this.onSelectAllRows(),
            onDeselectAll: () => this.onDeselectAllRows(),
            isSelectedProperty: 'entitygrid-selected',
          }}
          columns={this.getColumns()}
        />
      </div>
    );
  }
}

EntityGrid.defaultProps = {
  autoScroll: true,
  sessionKey: undefined,
  handleSort: null,
  canSearch: true,
  searchPlaceholder: '',
  emptyResults: <div>No results</div>,
  client: null,
  afterLoad: null,
  pageSize: 10,
  basePath: null,
  onResetFilters: null,
  routerName: null,
  canCreate: {
    active: true,
    action: null,
    disabled: false,
    isLoading: false,
  },
  onDefaultSearch: null,
  onRemoveFilter: null,
  canSearchCustom: {
    formStructure: false,
    active: true,
    onApplyFilter: null,
  },
  pageSizeSelection: true,
  canExport: {
    active: true,
    exportServiceEndpoint: null,
    excel: true,
    pdf: true,
    csv: true,
    exportLabelPrefix: null,
  },
  componentsPosition: 'bottom',
  defaultActions: null,
  persistentFilter: [],
  defaultOrder: [],
  reloadIndex: null,
  router: null,
  counterLabels: {},
  aliases: [],
  columns: [],
  canSelect: {
    active: false,
    selectAll: false,
    actions: [],
    handleSelectRow: null,
    handleDeselectRow: null,
    handleSelectAll: null,
    handleDeselectAll: null,
  },
  translations: {},
  customPaginator: null,
  forceSearch: 0,
  disableInitialLoad: false,
};

EntityGrid.propTypes = GridShape;

export default injectIntl(EntityGrid);
