/**
 *
 * SideBar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ScrollArea from 'react-scrollbar';
import { withRouter } from 'react-router';
import Menu from 'react-semantic-ui-accordion-menu';

const Sidebar = styled.div`
  position: fixed;
  top: 65px;
  width: ${props =>
    props.collapsed ? props.collapsedWidth : props.uncollapsedWidth}px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  background-color: ${props => props.mainColor} !important;
  padding-bottom: 65px;
  transition: width 0.1s;
  /* TODO: da riportare su accordion menu */
  & .active {
    position: relative;
    z-index: 999;
  }
  /* TODO: da riportare su accordion menu */
  & .content.active .accordion .title {
    border: 0px solid !important;
  }
`;

/* eslint-disable react/prefer-stateless-function */
class SideBar extends React.Component {
  render() {
    const { items = [], stylesSetting } = this.props;

    return (
      <Sidebar
        collapsed={this.props.collapsed}
        collapsedWidth={stylesSetting.SIDEBAR_COLLAPSED_WIDTH}
        uncollapsedWidth={stylesSetting.SIDEBAR_WIDTH}
        mainColor={stylesSetting.MAIN_COLOR}
      >
        <ScrollArea
          speed={0.8}
          className="area"
          contentClassName="content"
          horizontal={false}
          style={{ height: '100%' }}
          stopScrollPropagation
        >
          <Menu
            key={`${stylesSetting.MENU_ACTIVE_COLOR}${
              stylesSetting.SUBMENU_COLOR
            }${stylesSetting.MAIN_COLOR}`}
            tree={items}
            activeColor={stylesSetting.MENU_ACTIVE_COLOR} // Ricavabile da submenubackgroundcolor
            collapsed={this.props.collapsed}
            submenuBackgroundColor={stylesSetting.SUBMENU_COLOR}
            submenuFontColor="#ffffff"
            separatorColor="rgba(255,255,255,.1)"
            collapsedWidth={stylesSetting.SIDEBAR_COLLAPSED_WIDTH}
            openMenu={this.props.openMenu}
            firstLevelBackgroundColor={stylesSetting.MAIN_COLOR}
          />
        </ScrollArea>
      </Sidebar>
    );
  }
}

SideBar.propTypes = {
  items: PropTypes.array,
  collapsed: PropTypes.bool,
  openMenu: PropTypes.func,
  stylesSetting: PropTypes.object,
};

export default withRouter(SideBar);
