/**
 *
 * Kibana
 *
 */

import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
// import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import makeSelectKibana from './selectors';
import reducer from './reducer';
import saga from './saga';
// import messages from './messages';

function Kibana() {
  const [height, setHeight] = useState(400);

  useEffect(() => initHeight(), []); // mount
  useEffect(() => () => clearHeightListener(), []); // unmount

  const newHeight = () =>
    setHeight(
      Math.max(document.documentElement.clientHeight, window.innerHeight || 0) -
        110,
    );

  const initHeight = () => {
    window.addEventListener('resize', newHeight, true);
    newHeight();
  };

  const clearHeightListener = () => {
    window.removeEventListener('resize', newHeight, true);
  };

  return (
    <div>
      <BreadcrumbsItem to="/Logs">Logs</BreadcrumbsItem>
      <iframe
        title="Kibana"
        src="https://openelk-uat.novigo-consulting.it"
        style={{ height, width: '100%', marginTop: 15 }}
        frameBorder="0"
      />
    </div>
  );
}

Kibana.propTypes = {
  // dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  kibana: makeSelectKibana(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'kibana', reducer });
const withSaga = injectSaga({ key: 'kibana', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Kibana);
