/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/**
 *
 * Prospect
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { compose } from 'redux';
import { Helmet } from 'react-helmet-async';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { Header, Icon, Label } from 'semantic-ui-react';
import ListManager from 'novigo-entity-grid';
// import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import makeSelectProspect from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import appMessages from '../App/messages';
import Modal from '../../components/Modal';
import ProductCategoryForm from '../../components/Forms/ProductCategory';
import categoryService from '../../shared/services/productcategory';

/*
 * Specific import
 */

import { API } from '../../global-constants';

import request from '../../shared/services/request';
import { addNotification } from '../../utils/notification';
import { ListWrapper, Page } from '../../components/Layout';

/* eslint-disable react/prefer-stateless-function */
export class Prospect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      reloadIndex: 0,
      category: {},
    };
  }

  componentDidMount() {
    // TODO: load anything ?
  }

  transformData = data => ({
    ...data,
  });

  reloadData = () =>
    this.setState(prevState => ({
      reloadIndex: prevState.reloadIndex + 1,
    }));

  closeModal = () => this.setState({ modalOpened: false, category: {} });

  render() {
    return (
      <>
        <Helmet>
          <title>{this.context.intl.formatMessage(messages.title)}</title>
          <meta
            name="description"
            content={this.context.intl.formatMessage(messages.title)}
          />
        </Helmet>
        <Page>
          <Header as="h2" dividing>
            <FormattedMessage {...messages.title} />
          </Header>
          <ListWrapper>
            <ListManager
              entityName="product-category"
              sessionKey="product-category"
              basePath={API.BASE_URL}
              permissions={this.props.user}
              router={this.props}
              canCreate={{ active: false }}
              canExport={{
                exportLabelPrefix: this.context.intl.formatMessage(
                  appMessages.export_to,
                ),
              }}
              locale={_.get(this.props, 'user.defaultLanguage', 'it')}
              client={pars => request(pars).then(res => res.data)}
              defaultOrder={[
                {
                  type: 'field',
                  field: 'createdAt',
                  direction: 'desc',
                },
              ]}
              reloadIndex={this.state.reloadIndex}
              columns={[
                {
                  key: 'code',
                  name: this.context.intl.formatMessage(messages.code),
                  searchable: true,
                  sortable: true,
                },
                {
                  key: 'description',
                  name: this.context.intl.formatMessage(messages.description),
                  searchable: true,
                  sortable: true,
                },
                {
                  key: 'subcategories',
                  name: this.context.intl.formatMessage(messages.subcategories),
                  searchable: false,
                  sortable: false,
                  formatter: ({ data }) =>
                    data.subcategories.map(sub => (
                      <Label key={sub.code}>
                        {sub.code} - {sub.description}
                      </Label>
                    )),
                },
              ]}
              defaultActions={{
                visualize: false,
                modify: false,
                delete: false,
                grouped: false,
                moreActions: [
                  {
                    icon: 'remove',
                    label: 'remove',
                    key: 'remove',
                    color: 'red',
                    action: data => {
                      // eslint-disable-next-line no-unused-expressions
                      confirm(
                        this.context.intl.formatMessage(
                          appMessages.are_you_sure,
                        ),
                      ) &&
                        categoryService
                          .remove(data.id)
                          .then(() => {
                            addNotification({
                              title: this.context.intl.formatMessage(
                                appMessages.success,
                              ),
                              message: this.context.intl.formatMessage(
                                messages.category_deleted,
                              ),
                              isError: false,
                            });
                            this.reloadData();
                          })
                          .catch(err =>
                            addNotification({
                              title: null,
                              message: err.data.detail,
                              isError: true,
                            }),
                          );
                    },
                  },
                  {
                    icon: 'edit',
                    label: 'plus',
                    color: 'blue',
                    action: category =>
                      this.setState({ category, modalOpened: true }),
                  },
                ],
              }}
              canSelect={{
                active: false,
                selectAll: false,
                actions: [
                  {
                    icon: 'plus',
                    color: 'blue',
                    key: 'plus',
                    label: this.context.intl.formatMessage(messages.new),
                    onClick: () => this.setState({ modalOpened: true }),
                  },
                ],
              }}
            />
          </ListWrapper>
          <Modal
            open={this.state.modalOpened}
            onClose={() => {
              this.closeModal();
            }}
            title={
              <h2>
                <Icon name="sitemap" />{' '}
                {this.context.intl.formatMessage(messages.new)}
              </h2>
            }
            size="mini"
            style={{ width: 650 }}
            scrolling
          >
            <ProductCategoryForm
              close={this.closeModal}
              initialValues={this.state.category}
              update={this.reloadData}
            />
          </Modal>
        </Page>
      </>
    );
  }
}

Prospect.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  prospect: makeSelectProspect(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'prospect', reducer });
const withSaga = injectSaga({ key: 'prospect', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Prospect);

Prospect.contextTypes = {
  intl: PropTypes.object.isRequired,
};
