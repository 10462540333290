import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { connect } from 'formik';
import SimpleFormikField from 'novigo-simple-formik-field';
import moment from 'moment';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import FormikDate from '../../components/FormikDate';
import messages from './messages';
import appMessages from '../App/messages';
import { PaddedColumn } from '../../components/Layout';
import CustomerService from '../../shared/services/customer';
import AgentService from '../../shared/services/agent';
import InvoiceTypologyService from '../../shared/services/invoicetypology';

const SearchBar = props => {
  const { intl } = props;
  const {
    filters,
    initialValues,
    formik: { setFieldValue },
    removedFilter,
    onRemoveFilter,
    hasCustomerFilter,
  } = props;

  useEffect(
    () => {
      if (
        Array.isArray(filters) &&
        filters.length > 0 &&
        Boolean(initialValues)
      ) {
        Object.keys(initialValues).forEach(item => {
          setFieldValue(item, initialValues[item]);
        });
      }
    },
    [filters],
  );

  useEffect(
    () => {
      if (initialValues) {
        Object.keys(initialValues).forEach(item => {
          setFieldValue(item, initialValues[item]);
        });
      }
    },
    [initialValues],
  );

  useEffect(
    () => {
      if (removedFilter) {
        setFieldValue(removedFilter, '');
        if (onRemoveFilter) onRemoveFilter();
      }
    },
    [removedFilter, onRemoveFilter],
  );

  return (
    <React.Fragment>
      <Grid.Column>
        <SimpleFormikField
          name="number"
          label={intl.formatMessage(messages.invoice_number)}
        />
      </Grid.Column>
      {hasCustomerFilter && (
        <Grid.Column>
          <FormikAsyncSelect
            name="customer"
            label={intl.formatMessage(messages.headquarter)}
            loadOptions={(searchText, callback) =>
              CustomerService.getOptions(searchText, callback)
            }
          />
        </Grid.Column>
      )}
      <Grid.Column width={2}>
        <FormikDate
          name="referenceDateFrom"
          label={intl.formatMessage(messages.referenceDateFrom)}
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <FormikDate
          name="referenceDateTo"
          label={intl.formatMessage(messages.referenceDateTo)}
        />
      </Grid.Column>

      <PaddedColumn>
        <FormikAsyncSelect
          name="agent"
          label={intl.formatMessage(messages.agent)}
          entityName="agent"
          loadOptions={(searchText, callback) =>
            AgentService.getOptions(searchText, callback)
          }
        />
      </PaddedColumn>
      <PaddedColumn>
        <FormikAsyncSelect
          name="typology"
          label={intl.formatMessage(messages.type)}
          entityName="invoice_typology"
          loadOptions={InvoiceTypologyService.getOptions}
        />
      </PaddedColumn>
    </React.Fragment>
  );
};

SearchBar.propTypes = {
  filters: PropTypes.array,
  initialValues: PropTypes.object,
  formik: PropTypes.object,
  removedFilter: PropTypes.string,
  onRemoveFilter: PropTypes.func,
  hasCustomerFilter: PropTypes.bool,
  intl: IntlShape,
};

const ConnectedSearchBar = connect(SearchBar);

const ApplyFilter = props => {
  // eslint-disable-next-line no-unused-vars
  const {
    values,
    formActionsBag,
    applyFilterFunction,
    intl,
    onSearch,
    hasCustomerFilter,
  } = props;

  const filters = [];
  const filterLabels = [];

  if (values.number) {
    filterLabels.push({
      key: 'number',
      name: intl.formatMessage(messages.invoice_number),
      value: values.number,
      filterField: 'documentNumber',
    });
    filters.push({
      type: 'like',
      where: 'and',
      field: 'documentNumber',
      value: `%${values.number}%`,
    });
  }

  if (values.destination) {
    filterLabels.push({
      key: 'destination',
      name: intl.formatMessage(messages.destination),
      value: values.destination.label,
      filterField: 'id',
    });
    filters.push({
      type: 'like',
      where: 'and',
      alias: 'destination',
      field: 'id',
      value: `%${values.destination.value}%`,
    });
  }

  if (values.referenceDateFrom) {
    filterLabels.push({
      key: 'referenceDateFrom',
      name: intl.formatMessage(messages.referenceDateFrom),
      value: `${moment(values.referenceDateFrom).format('YYYY-MM-DD')} `,
      filterField: 'referenceDate',
    });
    filters.push({
      type: 'gte',
      where: 'and',
      field: 'referenceDate',
      value: `${moment(values.referenceDateFrom).format(
        'YYYY-MM-DD',
      )} 00:00:00`,
      format: 'Y-m-d H:i:s',
    });
  }

  if (values.referenceDateTo) {
    filterLabels.push({
      key: 'referenceDateTo',
      name: intl.formatMessage(messages.referenceDateTo),
      value: `${moment(values.referenceDateTo).format('YYYY-MM-DD')}`,
      filterField: 'referenceDate',
    });
    filters.push({
      type: 'lte',
      where: 'and',
      field: 'referenceDate',
      value: `${moment(values.referenceDateTo).format('YYYY-MM-DD')} 23:59:59`,
      format: 'Y-m-d H:i:s',
    });
  }

  if (hasCustomerFilter && values.customer) {
    filterLabels.push({
      key: 'customer',
      name: intl.formatMessage(messages.customer),
      value: values.customer.label,
      filterField: 'customer',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'id',
      alias: 'customer',
      value: `${values.customer.value}`,
    });
  }

  if (values.agent) {
    filterLabels.push({
      key: 'agent',
      name: intl.formatMessage(messages.agent),
      value: values.agent.label,
      filterField: 'agent',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'agent',
      alias: 'agent',
      value: `${values.agent.value}`,
    });
  }

  if (values.typology) {
    filterLabels.push({
      key: 'typology',
      name: intl.formatMessage(messages.type),
      value: values.typology.label,
      filterField: 'typology',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'id',
      alias: 'typology',
      value: `${values.typology.value}`,
    });
  }

  onSearch(filters, values, filterLabels).then(() => {
    applyFilterFunction(filters, filterLabels, formActionsBag);
  });
};

const InvoiceFilters = ({
  intl,
  initialSearch,
  filters,
  initialValues,
  onSearch,
  onRemoveFilter,
  removedFilter,
  hasCustomerFilter,
  preselectCurrentYear,
}) => ({
  formStructure: (
    <ConnectedSearchBar
      intl={intl}
      initialSearch={initialSearch}
      filters={filters}
      initialValues={initialValues}
      onRemoveFilter={onRemoveFilter}
      removedFilter={removedFilter}
      hasCustomerFilter={hasCustomerFilter}
      preselectCurrentYear={preselectCurrentYear}
    />
  ),
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  onApplyFilter: (values, formActionsBag, applyFilterFunction) => {
    ApplyFilter({
      values,
      formActionsBag,
      applyFilterFunction,
      intl,
      onSearch,
      hasCustomerFilter,
    });
  },
});

InvoiceFilters.propTypes = {};

export default InvoiceFilters;
