import qs from 'qs';
import moment from 'moment';
import request from '../request';
import { API } from '../../../global-constants';

const getByCustomer = (id, onlyOverdue) =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_PAYMENT_DEADLINES,
    method: 'GET',
    params: {
      filter: [
        id && {
          type: 'innerjoin',
          field: 'customer',
          alias: 'customer',
        },
        id && {
          type: 'eq',
          field: 'id',
          value: id,
          alias: 'customer',
          where: 'and',
        },
        onlyOverdue && {
          type: 'lt',
          field: 'dueDate',
          value: `${moment().format('YYYY-MM-DD')} 00:00:00`,
          where: 'and',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const DestinationPaymentDeadlines = {
  getByCustomer,
};

export default DestinationPaymentDeadlines;
