import React from 'react';
import { intlShape, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FormManager from 'novigo-form-manager';
import Modal from '../Modal';
import { API } from '../../global-constants';
import { addNotification } from '../../utils/notification';
import appMessages from '../../containers/App/messages';

const FormManagerModal = props => {
  const {
    open,
    onClose,
    modalTitle,
    modalSize,
    modalProps,
    reloadData,
    formManagerProps,
  } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      size={modalSize}
      title={modalTitle}
      {...modalProps}
    >
      <FormManager
        basePath={API.BASE_URL}
        afterUpdate={() => reloadData()}
        afterDelete={() => reloadData()}
        {...formManagerProps}
        onError={data =>
          addNotification({
            title: props.intl.formatMessage(appMessages.error),
            message: _.get(
              data,
              'detail',
              props.intl.formatMessage(appMessages.an_error_has_occurred),
            ),
            isError: true,
          })
        }
        onSuccess={() =>
          addNotification({
            title: props.intl.formatMessage(appMessages.success),
            message: props.intl.formatMessage(
              appMessages.operation_performed_successfully,
            ),
            isError: false,
          })
        }
      />
    </Modal>
  );
};

FormManagerModal.propsType = {
  intl: intlShape.isRequired,
  open: PropTypes.bool,
};

FormManagerModal.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(FormManagerModal);
