import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IntlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { connect } from 'formik';
import SimpleFormikField from 'novigo-simple-formik-field';
import moment from 'moment';
import { OFFER_STATE_ALL } from './constants';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import FormikDate from '../../components/FormikDate';
import DestinationService from '../../shared/services/destination';
import OfferService from '../../shared/services/offer';
import OfferTypologyService from '../../shared/services/offertypology';
import ProductService from '../../shared/services/product';
import messages from './messages';
import appMessages from '../App/messages';

const SearchBar = props => {
  const { intl } = props;
  const {
    filters,
    initialValues,
    formik: { setFieldValue },
    removedFilter,
    onRemoveFilter,
    preSelectState,
  } = props;

  const loadTypes = (searchText, callback) =>
    OfferTypologyService.getOptions(searchText, callback);

  const loadProducts = (searchText, callback) =>
    ProductService.getOptions(searchText, callback);

  useEffect(() => {
    if (preSelectState) {
      setFieldValue('state', {
        value: OFFER_STATE_ALL,
        label: OFFER_STATE_ALL,
      });
    }
  }, []);

  useEffect(
    () => {
      if (
        Array.isArray(filters) &&
        filters.length > 0 &&
        Boolean(initialValues)
      ) {
        Object.keys(initialValues).forEach(item => {
          setFieldValue(item, initialValues[item]);
        });
      }
    },
    [filters],
  );

  useEffect(
    () => {
      if (!initialValues && preSelectState) {
        setFieldValue('state', {
          value: OFFER_STATE_ALL,
          label: OFFER_STATE_ALL,
        });
      }
    },
    [initialValues],
  );

  useEffect(
    () => {
      if (removedFilter) {
        setFieldValue(removedFilter, '');
        if (onRemoveFilter) onRemoveFilter();
      }
    },
    [removedFilter, onRemoveFilter],
  );

  return (
    <React.Fragment>
      <Grid.Column width={4}>
        <SimpleFormikField
          name="number"
          label={intl.formatMessage(messages.offer_number)}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="destination"
          label={intl.formatMessage(messages.customer)}
          loadOptions={(searchText, callback) =>
            DestinationService.getOptions(searchText, callback, true)
          }
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <FormikDate
          name="createdAt"
          label={intl.formatMessage(messages.insert_date)}
        />
      </Grid.Column>
      <Grid.Column width={3}>
        <FormikDate
          name="expirationDate"
          label={intl.formatMessage(messages.expirationDate)}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="state"
          label={intl.formatMessage(messages.status)}
          loadOptions={(searchText, callback) =>
            OfferService.getStateOptions(callback)
          }
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="type"
          label={intl.formatMessage(messages.offer_type)}
          loadOptions={loadTypes}
        />
      </Grid.Column>
      <Grid.Column width={4}>
        <FormikAsyncSelect
          name="product"
          label={intl.formatMessage(messages.product)}
          loadOptions={loadProducts}
        />
      </Grid.Column>
    </React.Fragment>
  );
};

SearchBar.propTypes = {
  filters: PropTypes.array,
  initialValues: PropTypes.object,
  formik: PropTypes.object,
  removedFilter: PropTypes.string,
  onRemoveFilter: PropTypes.func,
  preSelectState: PropTypes.bool,
  intl: IntlShape,
};

const ConnectedSearchBar = connect(SearchBar);

const ApplyFilter = props => {
  // eslint-disable-next-line no-unused-vars
  const { values, formActionsBag, applyFilterFunction, intl, onSearch } = props;

  const filters = [];
  const filterLabels = [];

  // push expiration offer number in custom search
  if (values.number) {
    filters.push({
      type: 'like',
      field: 'number',
      where: 'and',
      value: `%${values.number}%`,
    });

    filterLabels.push({
      key: 'number',
      name: intl.formatMessage(messages.offer_number),
      value: values.number,
      filterField: 'number',
    });
  }

  // push expiration customer in custom search
  if (values.destination) {
    filters.push({
      type: 'eq',
      field: 'id',
      where: 'and',
      alias: 'destination',
      value: values.destination.value,
    });

    filterLabels.push({
      key: 'businessName',
      name: intl.formatMessage(messages.customer),
      value: values.destination.label,
      filterField: 'id',
    });
  }

  // push expiration date from in custom search
  if (values.createdAt) {
    const createdAt = moment(values.createdAt)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .format('YYYY-MM-DD HH:mm:ss');
    filters.push({
      type: 'gte',
      where: 'and',
      field: 'createdAt',
      value: `${createdAt}`,
      format: 'Y-m-d H:i:s',
    });

    filterLabels.push({
      key: 'createdAt',
      name: intl.formatMessage(messages.insert_date),
      value: moment(values.createdAt).format('DD/MM/YYYY'),
      filterField: 'createdAt',
    });
  }

  // push expiration date to in custom search
  if (values.expirationDate) {
    filters.push({
      type: 'lte',
      where: 'and',
      field: 'expirationDate',
      value: `${moment(values.expirationDate).format('YYYY-MM-DD')}`,
    });

    filterLabels.push({
      key: 'expirationDate',
      name: intl.formatMessage(messages.expirationDate),
      value: moment(values.expirationDate).format('DD/MM/YYYY'),
      filterField: 'expirationDate',
    });
  }

  // push offer state in custom search
  if (values.state) {
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'state',
      value: values.state.value,
    });

    filterLabels.push({
      key: 'state',
      name: intl.formatMessage(messages.status),
      value: values.state.label,
      filterField: 'state',
    });
  }
  if (values.type) {
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'id',
      alias: 'typology',
      value: values.type.value,
    });

    filterLabels.push({
      key: 'type',
      name: intl.formatMessage(messages.offer_type),
      value: values.type.label,
      filterField: 'type',
    });
  }

  if (values.product) {
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'product',
      alias: 'offerrow',
      value: values.product.value,
    });

    filterLabels.push({
      key: 'product',
      name: intl.formatMessage(messages.product),
      value: values.product.label,
      filterField: 'product',
    });
  }

  onSearch(filters, values, filterLabels).then(() => {
    applyFilterFunction(filters, filterLabels, formActionsBag);
  });
};

const OfferSearch = ({
  intl,
  initialSearch,
  filters,
  initialValues,
  onSearch,
  onRemoveFilter,
  removedFilter,
  preSelectState,
}) => ({
  formStructure: (
    <ConnectedSearchBar
      intl={intl}
      initialSearch={initialSearch}
      filters={filters}
      initialValues={initialValues}
      onRemoveFilter={onRemoveFilter}
      removedFilter={removedFilter}
      preSelectState={preSelectState}
    />
  ),
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  onApplyFilter: (values, formActionsBag, applyFilterFunction) => {
    ApplyFilter({
      values,
      formActionsBag,
      applyFilterFunction,
      intl,
      onSearch,
    });
  },
});

OfferSearch.propTypes = {};

export default OfferSearch;
