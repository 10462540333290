/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import BaseForm from './form';
import ExcludeForm from './excludeForm';
import { addNotification } from '../../../utils/notification';
import { ModalFakeActions } from '../../Layout';
import appMessages from '../../../containers/App/messages';
import getError from '../../../utils/getError';
import messages from './messages';
import validation from './validation';
import ExcludeValidation from './excludeValidation';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  startDate: _.get(newValues, 'startDate.date', null)
    ? moment(newValues.startDate.date)
    : null,
  endDate: _.get(newValues, 'endDate.date', null)
    ? moment(newValues.endDate.date)
    : null,
  fromPercentage: _.get(newValues, 'fromPercentage', 0),
  toPercentage: _.get(newValues, 'toPercentage', 0),
  fromAmount: _.get(newValues, 'fromAmount', 0),
  toAmount: _.get(newValues, 'toAmount', 0),
  commission: _.get(newValues, 'commission', 0),
  flatUnit: _.get(newValues, 'flatUnit', 0),
  refund: _.get(newValues, 'refund', 0),
  includedInBonus: _.get(newValues, 'includedInBonus', true),
  agent: _.get(newValues, 'agent.id')
    ? { value: newValues.agent.id, label: newValues.agent.displayName }
    : newValues.agent,
  product: {
    value: _.get(newValues, '_embedded.product.id', null),
    label: `${_.get(newValues, '_embedded.product.productCode', '')} ${_.get(
      newValues,
      '_embedded.product.title',
      '',
    )}`,
  },
});

export default class ProductCommission extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    commissionId: PropTypes.string,
    commissionService: PropTypes.object,
    entity: PropTypes.string,
    isExclude: PropTypes.bool,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const { isExclude } = this.props;

    const fromPercentage = _.get(values, 'fromPercentage', 0);
    const toPercentage = _.get(values, 'toPercentage', 0);
    const fromAmount = _.get(values, 'fromAmount', 0);
    const toAmount = _.get(values, 'toAmount', 0);
    const includedInBonus = _.get(values, 'includedInBonus') === true ? 1 : 0;
    const agent = _.get(values, 'agent.value', null);

    let payload = {
      id: values.id || null,
      startDate: _.get(values, 'startDate.date', null)
        ? values.startDate.format('YYYY-MM-DD')
        : null,
      endDate: _.get(values, 'endDate.date', null)
        ? values.endDate.format('YYYY-MM-DD')
        : null,

      commission: _.get(values, 'commission', 0),
      refund: _.get(values, 'refund', 0),
      [this.props.entity]: this.props.entityId,
    };

    if (this.props.isAmount) {
      payload = { ...payload, fromAmount, toAmount, agent };
    } else {
      payload = {
        ...payload,
        fromPercentage,
        toPercentage,
        includedInBonus,
        agent,
      };
    }

    if (isExclude) {
      payload = {
        id: values.id || null,
        startDate: _.get(values, 'startDate.date', null)
          ? values.startDate.format('YYYY-MM-DD')
          : null,
        endDate: _.get(values, 'endDate.date', null)
          ? values.endDate.format('YYYY-MM-DD')
          : null,

        commission: _.get(values, 'commission', 0),
        refund: _.get(values, 'refund', 0),
        flatUnit: _.get(values, 'flatUnit', 0),
        product: _.get(values, 'product.value', null),
        includedInBonus,
        fromPercentage,
        toPercentage,
        [this.props.entity]: this.props.entityId,
      };
    }
    let method = payload.id
      ? this.props.commissionService.update
      : this.props.commissionService.create;

    if (isExclude) {
      method = payload.id
        ? this.props.commissionService.updateException
        : this.props.commissionService.createException;
    }

    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: null,
          message: this.context.intl.formatMessage(messages.commission_saved),
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setSubmitting(false);
        setErrors(getError(err));
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    const { isExclude } = this.props;
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={initialValues(this.props.initialValues || {})}
            onSubmit={this.onSubmit}
            validate={isExclude ? ExcludeValidation : validation}
            render={props =>
              isExclude ? (
                <ExcludeForm {...props} entityId={this.props.entityId} />
              ) : (
                <BaseForm
                  {...props}
                  entityId={this.props.entityId}
                  isAmount={this.props.isAmount}
                />
              )
            }
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.close}
            >
              <Icon name="remove" />
              {this.context.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Button
              type="button"
              icon
              labelPosition="left"
              color="green"
              onClick={this.submit}
            >
              <Icon name="checkmark" />
              {this.context.intl.formatMessage(appMessages.save)}
            </Button>
          </div>
        </ModalFakeActions>
      </Grid>
    );
  }
}

ProductCommission.contextTypes = {
  intl: PropTypes.object.isRequired,
};
