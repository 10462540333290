import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the priceListCustomer state domain
 */

const selectPriceListCustomerDomain = state =>
  state.get('priceListCustomer', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by PriceListCustomer
 */

const makeSelectPriceListCustomer = () =>
  createSelector(selectPriceListCustomerDomain, substate => substate.toJS());

export default makeSelectPriceListCustomer;
export { selectPriceListCustomerDomain };
