/*
 * NotesHub Messages
 *
 * This contains all the text for the NotesHub container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.NotesHub.Form';

export default defineMessages({
  description: {
    id: `${scope}.description`,
    defaultMessage: 'description',
  },
});
