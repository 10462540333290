/*
 * Product-Category Messages
 *
 * This contains all the text for the Product-Category container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ProductCategory';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Product categories management',
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Code',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  subcategories: {
    id: `${scope}.subcategories`,
    defaultMessage: 'Subcategories',
  },
  new: {
    id: `${scope}.new`,
    defaultMessage: 'New category',
  },
  category_deleted: {
    id: `${scope}.category_deleted`,
    defaultMessage: 'Category deleted successfully.',
  },
  category_saved: {
    id: `${scope}.category_saved`,
    defaultMessage: 'Category saved successfully.',
  },
});
