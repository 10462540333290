import SimpleFormikField from 'novigo-simple-formik-field';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { intlShape } from 'react-intl';
import FormikToggle from '../../../FormikToggle';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import messages from './messages';
import AgentService from '../../../../shared/services/agent';
import DestinationAreaService from '../../../../shared/services/destinationarea';
import DestinationStateService from '../../../../shared/services/destinationstate';
import DestinationProvincesService from '../../../../shared/services/destinationprovinces';

const SharedSearchBar = ({
  intl,
  isDestination = false,
  isCustomer = false,
  isProspect = false,
  isUserAgent = false,
  isHeadQuarter = false,
  submitCount = 0,
  ...formik
}) => {
  const [citiesDefaultOptions, setCitiesDefaultOptions] = useState(null);

  useEffect(
    () => {
      const filters = {
        ...formik.formik.values,
        isProspect,
      };

      DestinationProvincesService.getOptionsDestinationProvinces({
        filters,
      }).then(res => setCitiesDefaultOptions(res));
    },
    [submitCount],
  );

  return (
    <Grid>
      {/* First row */}
      <Grid.Row columns={isDestination ? 7 : 5}>
        {/* Code */}
        <Grid.Column>
          <SimpleFormikField
            {...formik}
            name="code"
            label={intl.formatMessage(
              messages[
                // eslint-disable-next-line no-nested-ternary
                isCustomer || isHeadQuarter
                  ? 'customerCode'
                  : isProspect
                    ? 'prospectCode'
                    : 'destinationCode'
              ],
            )}
          />
        </Grid.Column>
        {/* BusinessName */}
        <Grid.Column>
          <SimpleFormikField
            {...formik}
            name="businessName"
            label={intl.formatMessage(messages.businessName)}
          />
        </Grid.Column>
        {/* BusinessAddressTitle */}
        <Grid.Column>
          <SimpleFormikField
            {...formik}
            name="location"
            label={intl.formatMessage(messages.location)}
          />
        </Grid.Column>
        {/* BusinessCity */}
        <Grid.Column>
          <FormikAsyncSelect
            {...formik}
            name="businessCity"
            label={intl.formatMessage(messages.city)}
            defaultOptions={citiesDefaultOptions}
            loadOptions={(searchText, callback) => {
              const filters = {
                ...formik.formik.values,
                isProspect,
              };

              return DestinationProvincesService.getAsyncOptionsDestinationProvinces(
                searchText,
                filters,
                callback,
              );
            }}
          />
        </Grid.Column>
        {/* Agent */}
        {!isUserAgent && (
          <Grid.Column>
            <FormikAsyncSelect
              {...formik}
              name="agent"
              entityName="agent"
              className="basic-multi-select"
              classNamePrefix="select"
              asyncSelectProps={{
                isMulti: true,
              }}
              loadOptions={(input, callback) =>
                AgentService.getOptions(input, callback)
              }
              label={intl.formatMessage(messages.agent)}
            />
          </Grid.Column>
        )}
        {/* Customer and prospect flags */}
        {isDestination && (
          <>
            <Grid.Column>
              <FormikToggle
                {...formik}
                name="isClient"
                label={intl.formatMessage(messages.client)}
              />
            </Grid.Column>
            <Grid.Column>
              <FormikToggle
                {...formik}
                name="isProspect"
                label={intl.formatMessage(messages.prospect)}
              />
            </Grid.Column>
          </>
        )}
        {/* Only mine flag (agent only) */}
        {isUserAgent && (
          <>
            <Grid.Column width={1}>
              <FormikToggle
                {...formik}
                name="isOnlyMine"
                label={intl.formatMessage(messages.onlyMine)}
              />
            </Grid.Column>
          </>
        )}
      </Grid.Row>

      {/* Second row */}
      <Grid.Row columns={isCustomer ? 6 : 7}>
        {/* Email */}
        <Grid.Column>
          <SimpleFormikField
            {...formik}
            name="email"
            label={intl.formatMessage(messages.email)}
          />
        </Grid.Column>
        {/* Website */}
        <Grid.Column>
          <SimpleFormikField
            {...formik}
            name="website"
            label={intl.formatMessage(messages.website)}
          />
        </Grid.Column>
        {/* Area (customer only) */}
        {!isCustomer && (
          <Grid.Column>
            <FormikAsyncSelect
              {...formik}
              name="area"
              entityName="agent"
              loadOptions={(input, callback) =>
                DestinationAreaService.getOptions(input, callback)
              }
              label={intl.formatMessage(messages.area)}
            />
          </Grid.Column>
        )}
        {/* Phone */}
        <Grid.Column>
          <SimpleFormikField
            {...formik}
            name="phone"
            label={intl.formatMessage(messages.phone)}
          />
        </Grid.Column>
        {/* DestinationState */}
        <Grid.Column>
          <FormikAsyncSelect
            {...formik}
            name="destinationState"
            label={intl.formatMessage(messages.destinationState)}
            loadOptions={DestinationStateService.getOptions}
          />
        </Grid.Column>
        {/* Loan flag */}
        <Grid.Column>
          <FormikToggle
            {...formik}
            name="hasLoan"
            label={intl.formatMessage(messages.hasLoan)}
          />
        </Grid.Column>
        {/* Invoice flag */}
        <Grid.Column>
          <FormikToggle
            {...formik}
            name="hasInvoice"
            label={intl.formatMessage(messages.hasInvoice)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

SharedSearchBar.defaultProps = {
  isDestination: false,
};

SharedSearchBar.propTypes = {
  isDestination: PropTypes.bool,
};

SharedSearchBar.contextTypes = {
  intl: intlShape,
};

export { SharedSearchBar };
