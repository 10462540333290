import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the commission state domain
 */

const selectCommissionDomain = state => state.get('commission', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Commission
 */

const makeSelectCommission = () =>
  createSelector(selectCommissionDomain, substate => substate.toJS());

export default makeSelectCommission;
export { selectCommissionDomain };
