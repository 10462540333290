import _ from 'lodash';
import moment from 'moment';
import FormManager from 'novigo-form-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import appMessages from '../../../../containers/App/messages';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { addNotification } from '../../../../utils/notification';
import ManageForm from './baseForm';
import validation from './validation';

class OfferManageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  transformOfferStates = data =>
    data.map(item => ({
      label: item,
      value: item,
    }));

  transformData = data => {
    const expirationDate = _.get(data, 'expirationDate', null);
    const transformedData = {
      ...data,
      expirationDate: moment(expirationDate),
      destination: {
        // eslint-disable-next-line prettier/prettier
        label: `${_.get(data, '_embedded.destination.code', '')} ${_.get(
          data,
          '_embedded.destination.businessName',
          '',
        )}`,
        value: _.get(data, '_embedded.destination.id', null),
        customer: _.get(data, '_embedded.destination.customer.id', null),
      },
      customer: {
        label: _.get(data, '_embedded.customer.displayName', null),
        value: _.get(data, '_embedded.customer.id', null),
      },
      typology: {
        label: _.get(data, 'typology.description', null),
        value: _.get(data, 'typology.id', null),
      },
      internalNote: _.get(data, 'note', null),
    };
    return transformedData;
  };

  onCleanData = data => ({
    state: _.get(data, 'state', data.state),
    destination: _.get(data, 'destination.value', null),
    finalized: 1,
    typology: _.get(data, 'typology.value', null),
    attention: _.get(data, 'attention', null),
    subject: _.get(data, 'subject', null),
    payment: _.get(data, 'payment', null),
    delivery: _.get(data, 'delivery', null),
    port: _.get(data, 'port', null),
    requestNumber: _.get(data, 'requestNumber', null),
    expirationDate:
      _.get(data, 'expirationDate', null) &&
        moment(data.expirationDate).isValid()
        ? moment(data.expirationDate).format('YYYY-MM-DD')
        : null,
    note: _.get(data, 'note', null),
    deliveryNote: _.get(data, 'deliveryNote', null),
  });

  render() {
    const offerStates = this.props.offerStates
      ? this.transformOfferStates(this.props.offerStates)
      : [];

    return (
      <FormManager
        key={this.props.match.params.action} // necessary to reload the current view upon redirect
        router={this.props} // necessary to use the internal routing
        client={pars => request(pars).then(res => this.transformData(res.data))}
        validation={values => validation(values, this.context.intl)}
        mask={fmProps => (
          <ManageForm
            {...this.props}
            {...fmProps}
            intl={this.context.intl}
            offerStates={offerStates}
            offerData={this.props.offerData}
          />
        )}
        afterUpdate={() => {
          // this.props.history.push(`${API.OFFER}`);
          this.props.history.goBack();
          this.props.updateOffer();
        }}
        onCleanData={this.onCleanData}
        entityName="offer"
        basePath={API.BASE_URL}
        permissions={this.props.user}
        onError={data =>
          addNotification({
            title: this.props.intl.formatMessage(appMessages.error),
            message: _.get(
              data,
              'detail',
              this.props.intl.formatMessage(appMessages.an_error_has_occurred),
            ),
            isError: true,
          })
        }
        onSuccess={() =>
          addNotification({
            title: this.props.intl.formatMessage(appMessages.success),
            message: this.props.intl.formatMessage(
              appMessages.operation_performed_successfully,
            ),
            isError: false,
          })
        }
      />
    );
  }
}

OfferManageForm.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(OfferManageForm);
