/*
 * ProductPriceMaster Messages
 *
 * This contains all the text for the ProductPriceMaster container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ProductPriceMaster';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the ProductPriceMaster container!',
  },
  new_price: {
    id: `${scope}.new_price`,
    defaultMessage: 'new_price',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Supplier',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'startDate',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'endDate',
  },
  buyPrice: {
    id: `${scope}.buyPrice`,
    defaultMessage: 'buyPrice',
  },
  markup: {
    id: `${scope}.markup`,
    defaultMessage: 'markup',
  },
  grossPrice: {
    id: `${scope}.grossPrice`,
    defaultMessage: 'grossPrice',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'category',
  },
  priceTo: {
    id: `${scope}.priceTo`,
    defaultMessage: 'priceTo',
  },
  priceList: {
    id: `${scope}.priceList`,
    defaultMessage: 'priceList',
  },
  subcategory: {
    id: `${scope}.subcategory`,
    defaultMessage: 'subcategory',
  },
  priceFrom: {
    id: `${scope}.priceFrom`,
    defaultMessage: 'priceFrom',
  },
  buyPriceFrom: {
    id: `${scope}.buyPriceFrom`,
    defaultMessage: 'buyPriceFrom',
  },
  buyPriceTo: {
    id: `${scope}.buyPriceTo`,
    defaultMessage: 'buyPriceTo',
  },
  coverImage: {
    id: `${scope}.coverImage`,
    defaultMessage: 'Product image',
  },
  product_code: {
    id: `${scope}.product_code`,
    defaultMessage: 'Product code',
  },
  product_stock: {
    id: `${scope}.product_stock`,
    defaultMessage: 'Quantity in stock',
  },
  crmCategory: {
    id: `${scope}.crmCategory`,
    defaultMessage: 'CRM category',
  },
  maxDiscount: {
    id: `${scope}.maxDiscount`,
    defaultMessage: 'Max discount',
  },
});
