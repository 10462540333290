/*
 * Products Messages
 *
 * This contains all the text for the Products container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Products';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Products container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Products',
  },
  new_product: {
    id: `${scope}.new_product`,
    defaultMessage: 'New product',
  },
  productCode: {
    id: `${scope}.productCode`,
    defaultMessage: 'Code',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  kit: {
    id: `${scope}.kit`,
    defaultMessage: 'KIT',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Product line',
  },
  subcategory: {
    id: `${scope}.subcategory`,
    defaultMessage: 'Product subline',
  },
  domain: {
    id: `${scope}.domain`,
    defaultMessage: 'Field of use',
  },
  coverImage: {
    id: `${scope}.coverImage`,
    defaultMessage: 'Cover',
  },
  crmCategory: {
    id: `${scope}.crmCategory`,
    defaultMessage: 'Crm category',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Supplier',
  },
  maxDiscount: {
    id: `${scope}.maxDiscount`,
    defaultMessage: 'Max discount',
  },
  isActive: {
    id: `${scope}.isActive`,
    defaultMessage: 'Is active',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  productPrice: {
    id: `${scope}.productPrice`,
    defaultMessage: 'Price',
  },
  quantityInStock: {
    id: `${scope}.quantityInStock`,
    defaultMessage: 'Quantity in stock',
  },
  quantityAvailable: {
    id: `${scope}.quantityAvailable`,
    defaultMessage: 'Quantity available',
  },
});
