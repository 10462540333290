import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import { get } from 'lodash';

import FormikDate from '../../../FormikDate';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import FormikSelect from '../../../FormikSelect';
import { PaddedColumn } from '../../../Layout';
import CustomerService from '../../../../shared/services/customer';
import messages from '../../../../containers/Invoices/messages';
import CurrencyFormatter from '../../../CurrencyFormatter';

function HeadingPart(props) {
  const [totalAmountNet, setTotalAmountNet] = useState(0);

  useEffect(
    () => {
      const total =
        get(props.values, 'totalAmount', 0) -
        get(props.values, 'transportCosts', 0) -
        get(props.values, 'stampCosts', 0) -
        get(props.values, 'receiptCosts', 0);

      setTotalAmountNet(total);
    },
    [props.values],
  );

  return (
    <div>
      <Grid>
        <Grid.Row>
          <PaddedColumn width={2}>
            <SimpleFormikField
              {...props}
              name="documentNumber"
              label={props.intl.formatMessage(messages.invoice_number)}
              readOnly
            />
          </PaddedColumn>
          <PaddedColumn width={2}>
            <FormikDate
              {...props}
              name="referenceDate"
              label={props.intl.formatMessage(messages.referenceDate)}
              readOnly
            />
          </PaddedColumn>
          <PaddedColumn width={4}>
            <FormikAsyncSelect
              name="customer"
              label={props.intl.formatMessage(messages.headquarter)}
              loadOptions={(searchText, callback) =>
                CustomerService.getOptions(searchText, callback, true)
              }
              asyncSelectProps={{
                isDisabled: true,
              }}
            />
          </PaddedColumn>
          <PaddedColumn width={4}>
            <FormikAsyncSelect
              name="destination"
              label={props.intl.formatMessage(messages.customer)}
              loadOptions={(searchText, callback) =>
                CustomerService.getOptions(searchText, callback, true)
              }
              asyncSelectProps={{
                isDisabled: true,
              }}
            />
          </PaddedColumn>
          <PaddedColumn width={4}>
            <CurrencyFormatter
              {...props}
              readOnly
              name="totalAmount"
              initialValue={totalAmountNet}
              label={props.intl.formatMessage(messages.totalAmount)}
            />
          </PaddedColumn>
          <PaddedColumn width={4} />
          <PaddedColumn width={4}>
            <FormikAsyncSelect
              name="typology"
              label={props.intl.formatMessage(messages.type)}
              entityName="invoice-typology"
              asyncSelectProps={{
                isDisabled: true,
              }}
            />
          </PaddedColumn>
          <PaddedColumn width={4}>
            <FormikSelect
              {...props}
              name="agents"
              label={props.intl.formatMessage(messages.agents)}
              isMulti
              isDisabled
              value={get(props, 'values.agents', [])}
            />
          </PaddedColumn>
          <PaddedColumn width={4} />
          <PaddedColumn width={4} />
          <PaddedColumn width={8}>
            <SimpleFormikField
              {...props}
              name="destination.address"
              readOnly
              label={props.intl.formatMessage(messages.address)}
              value={get(props, 'values.destination.address', '--')}
            />
          </PaddedColumn>
        </Grid.Row>
      </Grid>
    </div>
  );
}

HeadingPart.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(HeadingPart);
