/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import messages from './messages';

export const getFilterData = ({
  values,
  intl,
  isCustomer = false,
  isProspect = false,
  isHeadQuarter = false,
  // userAgentId = '',
}) => {
  const filters = [];
  const filterLabels = [];

  const code = _.get(values, 'code', null);
  const businessName = _.get(values, 'businessName', null);
  const location = _.get(values, 'location', null);
  const businessCity = _.get(values, 'businessCity', null);
  const agent = _.get(values, 'agent', null);
  const email = _.get(values, 'email', null);
  const website = _.get(values, 'website', null);
  const area = _.get(values, 'area', null);
  const isCustomerToggle = !!values.isClient;
  const isProspectToggle = !!values.isProspect;
  const hasLoan = !!values.hasLoan;
  const hasInvoice = !!values.hasInvoice;
  const destinationState = _.get(values, 'destinationState', null);

  if (code) {
    /** Code field now covers all the scenarios */
    filters.push({
      type: 'orx',
      where: 'and',
      conditions: [
        {
          type: 'like',
          where: 'or',
          field: 'code',
          value: `%${code}%`,
        },
      ].filter(o => o),
    });

    // Display the correct code label
    if (isProspect) {
      filterLabels.push({
        key: 'code',
        name: intl.formatMessage(messages.prospectCode),
        value: code,
        filterField: 'code',
      });
    } else {
      filterLabels.push({
        key: 'code',
        name: intl.formatMessage(
          messages[isCustomer ? 'customerCode' : 'destinationCode'],
        ),
        value: code,
        filterField: 'code',
      });
    }
  }

  if (businessName) {
    filterLabels.push({
      key: 'businessName',
      name: intl.formatMessage(messages.businessName),
      value: businessName,
      filterField: 'businessName',
    });
    filters.push({
      type: 'like',
      where: 'and',
      field: 'businessName',
      value: `%${businessName}%`,
    });
  }

  if (location) {
    filterLabels.push({
      key: 'businessAddressTitle',
      name: intl.formatMessage(messages.location),
      value: location,
      filterField: 'businessAddressTitle',
    });
    filters.push({
      type: 'like',
      where: 'and',
      field: 'businessAddressTitle',
      value: `%${location}%`,
    });
  }

  if (businessCity) {
    filterLabels.push({
      key: 'businessCity',
      name: intl.formatMessage(messages.city),
      value: businessCity.label,
      filterField: 'businessCity',
    });
    filters.push({
      type: 'like',
      where: 'and',
      field: 'businessCity',
      value: `%${businessCity.value}%`,
    });
  }

  if (agent && agent.length > 0) {
    const ids = agent.map(el => el.value);
    const labels = agent.map(el => el.label);

    filterLabels.push({
      key: 'agent',
      name: intl.formatMessage(messages.agent),
      value: labels.join(),
      filterField: 'agent1',
    });

    filters.push({
      type: 'orx',
      conditions: [
        {
          type: 'in',
          where: 'or',
          field: 'agent1',
          values: ids,
          [isHeadQuarter ? 'alias' : '']: 'destination',
        },
        {
          type: 'in',
          where: 'or',
          field: 'agent2',
          values: ids,
          [isHeadQuarter ? 'alias' : '']: 'destination',
        },
        !isHeadQuarter
          ? {
              type: 'in',
              where: 'or',
              field: 'subagent',
              values: ids,
            }
          : {},
      ],
      where: 'and',
    });
  }

  if (email) {
    filterLabels.push({
      key: 'email',
      name: intl.formatMessage(messages.email),
      value: email,
      filterField: 'email',
    });
    filters.push({
      type: 'like',
      where: 'and',
      field: 'email',
      value: `%${email}%`,
    });
  }

  if (website) {
    filterLabels.push({
      key: 'website',
      name: intl.formatMessage(messages.website),
      value: website,
      filterField: 'homepage',
    });

    filters.push({
      type: 'like',
      where: 'and',
      field: 'homepage',
      value: `%${website}%`,
    });
  }

  if (values.phone) {
    const { phone } = values;
    filterLabels.push({
      key: 'phoneNumber',
      name: intl.formatMessage(messages.phone),
      value: phone,
      filterField: 'phoneNumber',
    });

    filters.push({
      type: 'leftjoin',
      field: 'contacts',
      alias: 'destinationContact',
    });

    filters.push({
      type: 'orx',
      conditions: [
        {
          type: 'like',
          field: 'phoneNumber',
          alias: 'destinationContact',
          value: `%${phone}%`,
        },
        {
          type: 'like',
          field: 'mobileNumber',
          alias: 'destinationContact',
          value: `%${phone}%`,
        },
        {
          type: 'like',
          field: 'phoneNumber',
          value: `%${phone}%`,
        },
        {
          type: 'like',
          field: 'mobileNumber',
          value: `%${phone}%`,
        },
      ],
      where: 'and',
    });
  }

  if (area) {
    filterLabels.push({
      key: 'area',
      name: intl.formatMessage(messages.area),
      value: area.label,
      filterField: 'id',
    });

    filters.push({
      type: 'eq',
      where: 'and',
      field: 'id',
      alias: 'area',
      value: area.value,
    });
  }

  if (isCustomerToggle && !isProspectToggle) {
    filterLabels.push({
      key: 'client',
      name: intl.formatMessage(messages.client),
      value: intl.formatMessage(messages.yes),
      filterField: 'customer',
    });

    filters.push({
      type: 'innerjoin',
      field: 'customer',
      alias: 'customer',
    });
  }

  if (isProspectToggle && !isCustomerToggle) {
    filterLabels.push({
      key: 'customer',
      name: intl.formatMessage(messages.prospect),
      value: intl.formatMessage(messages.yes),
      filterField: 'customer',
    });

    filters.filter(el => el.field !== 'customer' || el.alias !== 'customer');

    filters.push({
      type: 'isnull',
      field: 'customer',
    });
  }

  if (isProspectToggle && isCustomerToggle) {
    filters.push({
      type: 'leftjoin',
      field: 'customer',
      alias: 'customer',
    });

    filterLabels.push({
      key: 'customer',
      name: intl.formatMessage(messages.isBoth),
      value: intl.formatMessage(messages.yes),
      filterField: 'customer',
    });
  }

  if (hasLoan) {
    filterLabels.push({
      key: 'hasLoan',
      name: intl.formatMessage(messages.hasLoan),
      value: intl.formatMessage(messages.yes),
      filterField: 'hasLoan',
    });
    filters.push({
      type: 'has_loan_filter',
      where: 'and',
      field: isHeadQuarter ? 'customer' : 'destination',
      value: 1,
    });
  }

  if (hasInvoice) {
    filterLabels.push({
      key: 'hasInvoice',
      name: intl.formatMessage(messages.hasInvoice),
      value: intl.formatMessage(messages.yes),
      filterField: 'hasInvoice',
    });
    filters.push({
      type: 'has_invoice_filter',
      where: 'and',
      field: isHeadQuarter ? 'customer' : 'destination',
      value: 1,
    });
  }

  if (values.isOnlyMine) {
    filterLabels.push({
      key: 'isOnlyMine',
      name: intl.formatMessage(messages.onlyMine),
      value: intl.formatMessage(messages.yes),
      filterField: 'isOnlyMine',
    });
    filters.push({
      where: 'and',
      type: 'search_agent_destination',
    });
  }

  if (destinationState) {
    filterLabels.push({
      key: 'destinationState',
      name: intl.formatMessage(messages.destinationState),
      value: destinationState.label,
      filterField: 'id',
    });

    filters.push({
      type: 'eq',
      where: 'and',
      field: 'id',
      alias: 'destinationState',
      value: destinationState.value,
    });
  }

  return {
    filters,
    filterLabels,
  };
};
