/*
 * TopBar Messages
 *
 * This contains all the text for the TopBar component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.TopBar';

export default defineMessages({
  user_profile: {
    id: `${scope}.user_profile`,
    defaultMessage: 'User profile',
  },
  search_destination: {
    id: `${scope}.search_destination`,
    defaultMessage: 'Search destination',
  },
  search_destination_placeholder: {
    id: `${scope}.search_destination_placeholder`,
    defaultMessage: 'Search...',
  },
});
