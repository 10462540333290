import qs from 'qs';

import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.CREATE_COMMISSION,
    method: 'POST',
    data: content,
  });

const getTotals = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.GET_AMOUNT_OF_INVOICES,
    method: 'POST',
    data: content,
  });

const update = ({ id, ...content }) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.COMMISSION}/${id}`,
    method: 'PATCH',
    data: content,
  });

const get = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.COMMISSION}/${id}`,
    method: 'GET',
  });

const getCommissionRows = (commission, page) =>
  request({
    baseURL: API.BASE_URL,
    url: API.COMMISSION_ROW,
    method: 'GET',
    params: {
      page,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'commission',
          value: commission,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getCommissionInvoices = (commission, page) =>
  request({
    baseURL: API.BASE_URL,
    url: API.COMMISSION_INVOICE,
    method: 'GET',
    params: {
      page,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'commission',
          value: commission,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.COMMISSION}/${id}`,
    method: 'DELETE',
  });

const removeBulk = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.COMMISSION}`,
    method: 'DELETE',
    data: content,
  });

const removeAll = filters =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.COMMISSION_DELETE}`,
    method: 'POST',
    data: {
      method: 'delete_all_commissions',
      params: {
        ...filters,
      },
    },
  });

const setStateAll = filters =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.COMMISSION_SET_STATE}`,
    method: 'POST',
    data: {
      method: 'set_state_all_commissions',
      params: {
        ...filters,
      },
    },
  });

const setStateBulk = ids =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.COMMISSION_SET_STATE}`,
    method: 'POST',
    data: {
      method: 'set_state_bulk_commissions',
      params: {
        ...ids,
      },
    },
  });

const CommissionService = {
  create,
  remove,
  removeBulk,
  removeAll,
  get,
  update,
  getCommissionRows,
  getCommissionInvoices,
  getTotals,
  setStateAll,
  setStateBulk,
};

export default CommissionService;
