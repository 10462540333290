import _ from 'lodash';

const defaultPermission = {
  customer: {
    list: false,
    view: false,
    edit: false,
    delete: false,
    create: false,
  },
};

const userPermissions = {
  ROLE_USER: {
    ...defaultPermission,
  },
  ROLE_ADMIN: {
    ...defaultPermission,
    customer: {
      list: true,
      view: true,
      edit: true,
      delete: true,
      create: true,
    },
  },
};

const getUserPermission = userRole => _.get(userPermissions, userRole, []);

export default getUserPermission;
