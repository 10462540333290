/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, Icon } from 'semantic-ui-react';

import get from 'lodash/get';
import xorBy from 'lodash/xorBy';

import { injectIntl } from 'react-intl';
import IconButton from './IconButton';
import ButtonDropdownCollapse from './ButtonDropdownCollapse';

import { IntlShape } from './Shapes';
import messages from './messages/MenuActionsMessages';

const MenuActions = props => {
  const {
    entityName,
    routerName,
    data,
    actions = [],
    grouped = false,
    visualize = false,
    modify = false,
    delete: isDelete = false,
    invertedOrder = false,
    intl,
    translations,
  } = props;

  /**
   * Return the function for manage the router
   * @param {} action
   */
  const manageAction = action => () => {
    const ruoterManager = get(props, 'router.history');
    const routerPath = routerName || entityName;
    if (ruoterManager) {
      ruoterManager.push(`/${routerPath}/${data.id}/${action}`);
    } else {
      console.warn(
        `This operation (${action}) is routing dependant but props is empty`,
      );
    }
  };

  const { actionsDefault = {} } = translations;

  const {
    viewText = intl.formatMessage(messages.view),
    modifyText = intl.formatMessage(messages.modify),
    deleteText = intl.formatMessage(messages.delete),
  } = actionsDefault;

  const defaultActions = [
    visualize && {
      icon: 'eye',
      key: 'visualize',
      label: viewText,
      action: manageAction('view'),
    },
    modify && {
      icon: 'edit',
      key: 'modify',
      label: modifyText,
      action: manageAction('modify'),
    },
    isDelete && {
      icon: 'trash',
      label: deleteText,
      key: 'delete',
      color: 'red',
      action: manageAction('delete'),
    },
  ].filter(o => o);

  /**
   * Add an properties isOptional to check if default or more actions
   */
  const optionalsActions = actions.map(action => ({
    ...action,
    isOptional: true,
  }));

  /**
   * Merge and distinct 2 action arrays by key
   * unique keys for array children
   */
  const allActions = invertedOrder
    ? xorBy(optionalsActions, defaultActions, 'key')
    : xorBy(defaultActions, optionalsActions, 'key');
  const firstLevelActions = [];
  const groupedActions = [];

  /**
   * For each action, check if you need a button a Dropdown or both
   */
  allActions.forEach((action, idx) => {
    /**
     * less than 3 actions just need push the icon button on the line
     */
    if (!grouped && (allActions.length === 3 || idx < 2)) {
      firstLevelActions.push(
        <IconButton
          key={`action-${action.key || action.label}`}
          data={data}
          action={action}
        />,
      );

      /**
       * Further actions can be optionally shown using the condition prop.
       * It evaluates it's visibility using the current object data.
       */
    } else if (!action.condition || action.condition(data)) {
      /**
       * Here you need to add a dropDown element, cause
       *  - If is grouped but is an element of a series longer than 2
       *    (but not 3 cause is showed in a one line)
       */

      /**
       * Add a divider if you need, before this object
       */
      if (
        idx > 0 &&
        !allActions[idx - 1].isOptional &&
        allActions[idx].isOptional
      ) {
        groupedActions.push(<Dropdown.Divider key="divider" />);
      }

      /**
       * More than 3 actions
       * have to grouped all the items from the third to the end
       */
      groupedActions.push(
        <Dropdown.Item
          style={{ color: action.color }}
          key={action.label}
          onClick={() => action.action(data)}
        >
          <Icon name={action.icon} />
          {action.label}
        </Dropdown.Item>,
      );
    }
  });
  /**
   * if the array have more than 3 actions OR is Grouped
   * then need the collapse button with
   */
  if (grouped || allActions.length > 3) {
    firstLevelActions.push(
      <ButtonDropdownCollapse
        key="collapse-button"
        dropdownItems={groupedActions}
        isGrouped={grouped}
      />,
    );
  }
  return firstLevelActions;
};

MenuActions.defaultProps = {
  visualize: false,
  modify: false,
  delete: false,
  grouped: false,
  router: null,
  actions: [],
};

MenuActions.propTypes = {
  /**
   * The entity name.
   * It helps with the default action (edit, delete, modify).
   */
  entityName: PropTypes.string.isRequired,

  /**
   * The router name.
   */
  routerName: PropTypes.string,

  data: PropTypes.any.isRequired,
  /**
   * Flag  to enable the visualize action.
   */
  visualize: PropTypes.bool,

  /**
   * Flag to enable the modify action.
   */
  modify: PropTypes.bool,

  /**
   * Flag to enable the delete action.
   */
  delete: PropTypes.bool,
  grouped: PropTypes.bool,
  router: PropTypes.object,
  /**
   * Additional actions.
   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * The action key.
       */
      key: PropTypes.key,

      /**
       * The color style property.
       */
      color: PropTypes.string,

      /**
       * The action icon.
       */
      icon: PropTypes.icon,

      /**
       * The action text.
       */
      label: PropTypes.string,

      /**
       * The function that it's triggered when the action button is clicked.
       */
      action: PropTypes.func,

      /*
     *
    */
      isOptional: PropTypes.bool,

      /**
       * The function that checks if the action should be shown or not.
       */
      condition: PropTypes.func,
    }),
  ),

  intl: IntlShape,
};

export default injectIntl(MenuActions);
