import messages from './messages';

export const getFilterData = ({ values, intl }) => {
  const filters = [];
  const filterLabels = [];

  if (values.productCode) {
    filterLabels.push({
      key: 'productCode',
      name: intl.formatMessage(messages.productCode),
      value: values.productCode,
      filterField: 'productCode',
    });
    filters.push({
      type: 'like',
      where: 'and',
      field: 'productCode',
      value: `%${values.productCode}%`,
    });
  }

  if (values.title) {
    filterLabels.push({
      key: 'title',
      name: intl.formatMessage(messages.description),
      value: values.title,
      filterField: 'title',
    });
    filters.push({
      type: 'like',
      where: 'and',
      field: 'title',
      value: `%${values.title}%`,
    });
  }

  if (values.category) {
    filterLabels.push({
      key: 'category',
      name: intl.formatMessage(messages.category),
      value: values.category.label,
      filterField: 'category',
    });
    filters.push({
      type: 'orx',
      where: 'and',
      conditions: [
        {
          type: 'eq',
          where: 'and',
          field: 'category',
          value: `${values.category.value}`,
        },
        {
          type: 'eq',
          where: 'and',
          alias: 'subcategory',
          field: 'category',
          value: `${values.category.value}`,
        },
      ],
    });
  }

  if (values.subcategory) {
    filterLabels.push({
      key: 'subcategory',
      name: intl.formatMessage(messages.subcategory),
      value: values.subcategory.label,
      filterField: 'subcategory',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'subcategory',
      value: `${values.subcategory.value}`,
    });
  }

  if (values.crmCategory) {
    filterLabels.push({
      key: 'crmCategory',
      name: intl.formatMessage(messages.crmCategory),
      value: values.crmCategory.label,
      filterField: 'crmCategory',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'crmCategory',
      value: `${values.crmCategory.value}`,
    });
  }

  if (values.domain) {
    filterLabels.push({
      key: 'domain',
      name: intl.formatMessage(messages.domain),
      value: values.domain.label,
      filterField: 'domain',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'id',
      alias: 'productDomains',
      value: `${values.domain.value}`,
    });
  }

  if (values.productSupplier) {
    filterLabels.push({
      key: 'productSupplier',
      name: intl.formatMessage(messages.supplier),
      value: values.productSupplier.label,
      filterField: 'productSupplier',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'id',
      alias: 'productSupplier',
      value: `${values.productSupplier.value}`,
    });
  }

  if (values.isActive === true || values.isActive === false) {
    filterLabels.push({
      key: 'isActive',
      name: intl.formatMessage(messages.active),
      value: intl.formatMessage(
        messages[values.isActive ? 'onlyActive' : 'onlyNotActive'],
      ),
      filterField: 'isActive',
    });
    filters.push({
      type: 'eq',
      where: 'and',
      field: 'isActive',
      value: values.isActive ? 1 : 0,
    });
  }

  // push the executed flag

  return {
    filters,
    filterLabels,
  };
};
