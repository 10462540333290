/*
 * Product Messages
 *
 * This contains all the text for the Product container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.Product';

export default defineMessages({
  /**
   * General data
   */
  tab_general_data: {
    id: `${scope}.tab_general_data`,
    defaultMessage: 'General data',
  },
  productCode: {
    id: `${scope}.productCode`,
    defaultMessage: 'Product Code',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Title',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  extended_description: {
    id: `${scope}.extended_description`,
    defaultMessage: 'Extended description',
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Supplier',
  },
  kit: {
    id: `${scope}.kit`,
    defaultMessage: 'kit',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'category',
  },
  subcategory: {
    id: `${scope}.subcategory`,
    defaultMessage: 'subcategory',
  },
  productDomain: {
    id: `${scope}.productDomain`,
    defaultMessage: 'productDomain',
  },
  unitMeasure: {
    id: `${scope}.unitMeasure`,
    defaultMessage: 'unitMeasure',
  },
  relatedProducts: {
    id: `${scope}.relatedProducts`,
    defaultMessage: 'relatedProducts',
  },
  sellAgent: {
    id: `${scope}.sellAgent`,
    defaultMessage: 'sellAgent',
  },
  sellOnline: {
    id: `${scope}.sellOnline`,
    defaultMessage: 'sellOnline',
  },
  sellLoan: {
    id: `${scope}.sellLoan`,
    defaultMessage: 'sellLoan',
  },
  productBlocked: {
    id: `${scope}.productBlocked`,
    defaultMessage: 'productBlocked',
  },
  productState: {
    id: `${scope}.productState`,
    defaultMessage: 'productState',
  },
  includedInBonus: {
    id: `${scope}.includedInBonus`,
    defaultMessage: 'Included in bonus',
  },
  createdBy: {
    id: `${scope}.createdBy`,
    defaultMessage: 'createdBy',
  },
  quantityInStock: {
    id: `${scope}.quantityInStock`,
    defaultMessage: 'quantityInStock',
  },
  quantityAvailable: {
    id: `${scope}.quantityAvailable`,
    defaultMessage: 'Quantity available',
  },
  quantityReserved: {
    id: `${scope}.quantityReserved`,
    defaultMessage: 'Reserved quantity',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  relatedProduct: {
    id: `${scope}.relatedProduct`,
    defaultMessage: 'Related Product',
  },
  replacementProduct: {
    id: `${scope}.replacementProduct`,
    defaultMessage: 'Replacement Product',
  },
  replacementProducts: {
    id: `${scope}.replacementProducts`,
    defaultMessage: 'Replacement Products',
  },
  groupProduct: {
    id: `${scope}.groupProduct`,
    defaultMessage: 'Group Product',
  },

  /**
   * Attachments
   */
  tab_attachments: {
    id: `${scope}.tab_attachments`,
    defaultMessage: 'Attachments',
  },

  /**
   * Logistics
   */
  tab_logistics: {
    id: `${scope}.tab_logistics`,
    defaultMessage: 'Logistics',
  },
  defaultWarehouse: {
    id: `${scope}.defaultWarehouse`,
    defaultMessage: 'defaultWarehouse',
  },
  bin: {
    id: `${scope}.bin`,
    defaultMessage: 'bin',
  },

  /**
   * Billing
   */
  tab_billing: {
    id: `${scope}.tab_billing`,
    defaultMessage: 'Billing',
  },
  buyPrice: {
    id: `${scope}.buyPrice`,
    defaultMessage: 'Buy price',
  },
  productWarehouseCategory: {
    id: `${scope}.productWarehouseCategory`,
    defaultMessage: 'productWarehouseCategory',
  },
  taxCode: {
    id: `${scope}.taxCode`,
    defaultMessage: 'taxCode',
  },
  maxDiscount: {
    id: `${scope}.maxDiscount`,
    defaultMessage: 'maxDiscount',
  },
  maxDiscountPerQuantity: {
    id: `${scope}.maxDiscountPerQuantity`,
    defaultMessage: 'maxDiscountPerQuantity',
  },
  minQuantity: {
    id: `${scope}.minQuantity`,
    defaultMessage: 'minQuantity',
  },

  /**
   * Dimension and packaging
   */
  tab_packaging: {
    id: `${scope}.tab_packaging`,
    defaultMessage: 'Packaging',
  },
  numBoxPerPackage: {
    id: `${scope}.numBoxPerPackage`,
    defaultMessage: 'numBoxPerPackage',
  },
  numItemsPerBox: {
    id: `${scope}.numItemsPerBox`,
    defaultMessage: 'numItemsPerBox',
  },
  numPackagePerPallet: {
    id: `${scope}.numPackagePerPallet`,
    defaultMessage: 'numPackagePerPallet',
  },
  packagingType: {
    id: `${scope}.packagingType`,
    defaultMessage: 'packagingType',
  },
  packagingDimension: {
    id: `${scope}.packagingDimension`,
    defaultMessage: 'packagingDimension',
  },
  volumeUnit: {
    id: `${scope}.volumeUnit`,
    defaultMessage: 'volumeUnit',
  },
  grossWeight: {
    id: `${scope}.grossWeight`,
    defaultMessage: 'grossWeight',
  },
  netWeight: {
    id: `${scope}.netWeight`,
    defaultMessage: 'netWeight',
  },
  ean: {
    id: `${scope}.ean`,
    defaultMessage: 'ean',
  },
  dtm: {
    id: `${scope}.dtm`,
    defaultMessage: 'dtm',
  },

  /**
   * External trade
   */
  tab_external_trade: {
    id: `${scope}.tab_external_trade`,
    defaultMessage: 'External Trade',
  },
  combinedNomenclature: {
    id: `${scope}.combinedNomenclature`,
    defaultMessage: 'combinedNomenclature',
  },
  countryOrigin: {
    id: `${scope}.countryOrigin`,
    defaultMessage: 'countryOrigin',
  },

  /**
   * Commissions
   */
  tab_commissions: {
    id: `${scope}.tab_commissions`,
    defaultMessage: 'Commissions',
  },

  valid_from: {
    id: `${scope}.valid_from`,
    defaultMessage: 'Valid from',
  },

  valid_to: {
    id: `${scope}.valid_to`,
    defaultMessage: 'Valid to',
  },

  flat_unit: {
    id: `${scope}.flat_unit`,
    defaultMessage: 'Flat unit',
  },
  commission: {
    id: `${scope}.commission`,
    defaultMessage: 'Commission',
  },
  refund: {
    id: `${scope}.refund`,
    defaultMessage: 'Refund',
  },
  commission_removed_success: {
    id: `${scope}.commission_removed_success`,
    defaultMessage: 'Commission removed succesfully',
  },
  crm_category: {
    id: `${scope}.crm_category`,
    defaultMessage: 'Crm category',
  },
  loan_category: {
    id: `${scope}.loan_category`,
    defaultMessage: 'Loan category',
  },
  packaging_product: {
    id: `${scope}.packaging_product`,
    defaultMessage: 'Packaging product',
  },
  photoProduct: {
    id: `${scope}.photoProduct`,
    defaultMessage: 'Photo of the product',
  },
  techDetails: {
    id: `${scope}.techDetails`,
    defaultMessage: 'Technical details',
  },
  securityCard: {
    id: `${scope}.securityCard`,
    defaultMessage: 'Security card',
  },
  productMasterPrice: {
    id: `${scope}.productMasterPrice`,
    defaultMessage: 'Price master',
  },
  note_offer: {
    id: `${scope}.note_offer`,
    defaultMessage: 'Note offer',
  },
  isActive: {
    id: `${scope}.isActive`,
    defaultMessage: 'Active',
  },
  isStock: {
    id: `${scope}.isStock`,
    defaultMessage: 'In stock',
  },
  information: {
    id: `${scope}.information`,
    defaultMessage: 'Information',
  },
});
