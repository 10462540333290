import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Form, Grid, TextArea } from 'semantic-ui-react';
import _ from 'lodash';
import { PaddedColumn } from '../../../Layout';
import FormikField from '../../../FormikField';
import messages from '../messages';
import TabIndicators from '../formParts/tabIndicators';
import { prepareDefaultTabs } from '../constants';
import { usePrevious } from '../../../../utils/functions';
import StaffWorkOrderTabService from '../../../../shared/services/staffworkordertab';
import {
  formatIndicatorsResponse,
  Loading,
  updateTab,
} from '../../../../containers/TechnicalIntervention/formHelper';
import { addNotification } from '../../../../utils/notification';

const IndicatorsTab = props => {
  /** from properties */
  const { values, setFieldValue, onChange, tabIdentifier } = props;

  /** state management */
  const [indicators, setIndicators] = useState([]);
  const [tab, setTab] = useState(null);
  const [isLoadingTab, setIsLoadingTab] = useState(false);

  /** current state values */
  const currentTabIdentifier = usePrevious(tabIdentifier);
  const currentTabs = usePrevious(_.get(values, 'tabs', []));

  useEffect(
    () => {
      if (currentTabIdentifier !== tabIdentifier) {
        loadTabData(_.get(values, 'tabs', []));
      }
    },
    [tabIdentifier],
  );

  useEffect(
    () => {
      const tabs = _.sortBy(_.get(values, 'tabs', []));
      if (!_.isEqual(_.sortBy(currentTabs), tabs)) {
        loadTabData(tabs);
      }
    },
    [_.get(values, 'tabs', [])],
  );

  const loadTabData = tabs => {
    if (Array.isArray(tabs)) {
      const currentTab = tabs.find(
        item => item.tabIdentifier === tabIdentifier,
      );

      setTab(currentTab);

      if (currentTab && Array.isArray(currentTab.indicators)) {
        setIndicators(currentTab.indicators);
      } else if (
        currentTab &&
        typeof currentTab.id !== 'undefined' &&
        currentTab.id != null
      ) {
        loadTabDataFromTabId(currentTab.id);
      }
    } else {
      const defaultTabs = prepareDefaultTabs(values.id);

      const currentTab = defaultTabs.find(
        item => item.tabIdentifier === tabIdentifier,
      );

      setTab(currentTab);

      if (currentTab && Array.isArray(currentTab.indicators)) {
        setIndicators(currentTab.indicators);
        setFieldValue('tabs', defaultTabs);
      }
    }
  };

  const loadTabDataFromTabId = tabId => {
    // put tab on load
    setIsLoadingTab(true);
    StaffWorkOrderTabService.getById(tabId)
      .then(res => {
        setIsLoadingTab(false);
        // handle response data
        // setIndicators(_.get(res.data, '_embedded.indicators', []));
        // make format indicators
        const newIndicators = formatIndicatorsResponse(
          _.get(res.data, '_embedded.indicators', []),
        );
        // eslint-disable-next-line no-shadow
        const { id, note, tabIdentifier } = res.data;

        const currentTab = {
          ...{ id, note, tabIdentifier },
          ...{ indicators: newIndicators },
        };

        setTab(currentTab);
        updateTab({ tab: currentTab, values, setFieldValue });
      })
      .catch(error => {
        setIsLoadingTab(false);
        addNotification({
          title: null,
          message: error.data.detail,
          isError: true,
        });
      });
  };

  const handleChange = newIndicators => {
    setIndicators(newIndicators);
    if (typeof onChange === 'function') {
      onChange(newIndicators);
    }

    let updatedTabs = _.get(values, 'tabs', []);

    if (typeof updatedTabs.length === 'undefined' || updatedTabs.length <= 0) {
      updatedTabs = prepareDefaultTabs(values.id);
    }

    updatedTabs = updatedTabs.map(item => {
      if (item.tabIdentifier === tabIdentifier) {
        return { ...item, ...{ indicators: newIndicators } };
      }
      return item;
    });

    setFieldValue('tabs', updatedTabs);
  };

  const handleFieldChange = ({ tabId, name, value }) => {
    let updatedTabs = _.get(values, 'tabs', []);

    if (typeof updatedTabs.length === 'undefined' || updatedTabs.length <= 0) {
      updatedTabs = prepareDefaultTabs(values.id);
    }

    const updateValue = {};

    updatedTabs = updatedTabs.map(item => {
      if (item.tabIdentifier === tabId) {
        updateValue[`${name}`] = value;
        return { ...item, ...updateValue };
      }
      return item;
    });

    setFieldValue('tabs', updatedTabs);
  };

  return (
    <Form>
      {isLoadingTab ? (
        <Loading />
      ) : (
        <Grid>
          <TabIndicators
            {...props}
            indicators={indicators}
            onChange={data => handleChange(data)}
          />
          <Grid.Row>
            <PaddedColumn>
              <FormikField
                {...props}
                name="note"
                label={props.intl.formatMessage(messages.note)}
              >
                <TextArea
                  onChange={(e, data) =>
                    handleFieldChange({
                      tabId: props.tabIdentifier,
                      name: 'note',
                      value: data.value,
                    })
                  }
                  defaultValue={_.get(tab, 'note', '')}
                  value={_.get(tab, 'note', '')}
                  placeholder={props.intl.formatMessage(
                    messages.note_placeholder,
                  )}
                />
              </FormikField>
            </PaddedColumn>
          </Grid.Row>
        </Grid>
      )}
    </Form>
  );
};

IndicatorsTab.propTypes = {
  intl: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object,
  tabIdentifier: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  showToolsField: PropTypes.bool,
  showSpeciesField: PropTypes.bool,
};

export default injectIntl(IndicatorsTab);
