import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the productPriceCustomer state domain
 */

const selectProductPriceCustomerDomain = state =>
  state.get('productPriceCustomer', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProductPriceCustomer
 */

const makeSelectProductPriceCustomer = () =>
  createSelector(selectProductPriceCustomerDomain, substate => substate.toJS());

export default makeSelectProductPriceCustomer;
export { selectProductPriceCustomerDomain };
