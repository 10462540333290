import React from 'react';
import _, { get } from 'lodash';
import PropTypes from 'prop-types';
// import { Icon } from 'semantic-ui-react';
import ListManager from 'novigo-entity-grid';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch } from '../../../Layout';
import messages from '../../../../containers/Loan/messages';
import currencyFormatter from '../../../../utils/currencyFormatter';
import { convertUTCToLocal } from '../../../../utils/functions';

const SamplingTab = props => {
  const columns = () => [
    {
      key: 'displayName',
      name: props.intl.formatMessage(messages.customer),
      useAlias: 'customer',
      searchable: false,
      sortable: true,
      formatter: data =>
        `${_.get(data, 'data._embedded.customer.displayName', '')}`,
      exportformatter: data =>
        `${_.get(data, 'data._embedded.customer.displayName', '')}`,
    },
    {
      key: 'title',
      name: props.intl.formatMessage(messages.product),
      useAlias: 'product',
      searchable: true,
      sortable: true,
      formatter: data =>
        `${_.get(data, 'data._embedded.product.productCode', '')} -
        ${_.get(data, 'data._embedded.product.title', '')}`,
    },
    {
      key: 'createdAt',
      name: props.intl.formatMessage(messages.insert_date),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => {
        const { createdAt } = data;
        const localCreatedAt = createdAt
          ? convertUTCToLocal(createdAt.date)
          : '--';
        return localCreatedAt;
      },
      exportFormatter: ({ data }) => {
        if (_.get(data, 'createdAt', false)) {
          return moment(_.get(data, 'createdAt.date')).format('DD/MM/YYYY');
        }
        return '--';
      },
    },
    {
      key: 'quantity',
      name: props.intl.formatMessage(messages.number_installed),
      searchable: true,
      sortable: true,
      formatter: ({ data }) => _.get(data, 'quantity', '--'),
      exportFormatter: ({ data }) => _.get(data, 'quantity', '--'),
    },
    {
      key: 'totalPriceAmount',
      name: props.intl.formatMessage(messages.total_price_amount),
      searchable: true,
      sortable: true,
      formatter: ({ data }) =>
        currencyFormatter.format(_.get(data, 'totalPriceAmount', '--')),
    },
  ];

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <ListManager
          entityName="product-sampling"
          basePath={API.BASE_URL}
          locale={_.get(props, 'user.defaultLanguage', 'it')}
          permissions={props.user}
          router={props}
          client={pars => request(pars).then(res => res.data)}
          aliases={[
            {
              type: 'leftjoin',
              field: 'product',
              alias: 'product',
            },
            {
              type: 'leftjoin',
              field: 'customer',
              alias: 'customer',
            },
          ]}
          columns={columns()}
          canCreate={{
            active: false,
          }}
          canExport={{
            active: false,
          }}
          defaultActions={{
            visualize: false,
            modify: false,
            delete: false,
            grouped: false,
            moreActions: [],
          }}
          persistentFilter={[
            {
              type: 'eq',
              field: 'customer',
              value: get(props, 'values.customer.value', ''),
            },
          ].filter(o => o)}
        />
      </TableGlobalSearch>
    </ListWrapper>
  );
};

SamplingTab.propTypes = {
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(SamplingTab);
