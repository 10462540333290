import { defineMessages } from 'react-intl';

export const scope = 'app.components.Anagrafica.searchbar';

export default defineMessages({
  destinationCode: {
    id: `${scope}.destinationCode`,
    defaultMessage: 'Code',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Business name',
  },
  location: {
    id: `${scope}.location`,
    defaultMessage: 'Location',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  client: {
    id: `${scope}.client`,
    defaultMessage: 'Client',
  },
  prospect: {
    id: `${scope}.prospect`,
    defaultMessage: 'Prospect',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  area: {
    id: `${scope}.area`,
    defaultMessage: 'Area',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Phone number',
  },
  hasLoan: {
    id: `${scope}.hasLoan`,
    defaultMessage: 'Has loan?',
  },
  isActive: {
    id: `${scope}.isActive`,
    defaultMessage: 'Is active?',
  },
  hasInvoice: {
    id: `${scope}.hasInvoice`,
    defaultMessage: 'Has Invoice?',
  },
  website: {
    id: `${scope}.website`,
    defaultMessage: 'Web Site',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  isBoth: {
    id: `${scope}.isBoth`,
    defaultMessage: 'Clients and Prospects',
  },
  prospectCode: {
    id: `${scope}.prospectCode`,
    defaultMessage: 'Prospect Code',
  },
  customerCode: {
    id: `${scope}.customerCode`,
    defaultMessage: 'Customer Code',
  },
  onlyMine: {
    id: `${scope}.onlyMine`,
    defaultMessage: 'Only mine',
  },
  destinationState: {
    id: `${scope}.destinationState`,
    defaultMessage: 'Status',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
});
