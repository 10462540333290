import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the priceListMaster state domain
 */

const selectPriceListMasterDomain = state =>
  state.get('priceListMaster', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by PriceListMaster
 */

const makeSelectPriceListMaster = () =>
  createSelector(selectPriceListMasterDomain, substate => substate.toJS());

export default makeSelectPriceListMaster;
export { selectPriceListMasterDomain };
