/**
 *
 * RelatedData
 *
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import { injectIntl } from 'react-intl';

import _ from 'lodash';
import { Icon } from 'semantic-ui-react';

import ListManager from 'novigo-entity-grid';
import FormManager from 'novigo-form-manager';
import request from '../../shared/services/request';

import { addNotification } from '../../utils/notification';
import appMessages from '../../containers/App/messages';

import Modal from '../Modal';

function RelatedData(props) {
  const {
    listManagerProps,
    formManagerProps,
    modalProps,
    entityName,
    basePath,
    readOnly = false,
  } = props;

  /**
   * if form pass a client, use that, ore use the default one
   */
  const getInternalClient = () =>
    _.get(formManagerProps, 'client', pars => request(pars));

  /**
   * manage the state of the modal 'new'|false|Object
   */
  const [noteModalIsOpen, setnoteModalIsOpen] = useState(false);
  const [noteKey, setNoteKey] = useState(new Date());

  /**
   * Get a single element, using the client if passed one or the internal one.
   *
   * @param {*} content
   */
  const getSingleElement = content =>
    getInternalClient()({
      baseURL: basePath,
      url: `/${entityName}/${content.id}`,
      method: 'GET',
      params: {},
    });

  const deleteSingleElement = content =>
    request({
      baseURL: basePath,
      url: `/${entityName}/${content.id}`,
      method: 'DELETE',
    });

  const manageAction = [
    {
      icon: 'edit',
      label: 'edit element',
      key: 'editRelatedData',
      action: data => {
        getSingleElement(data).then(res => {
          setnoteModalIsOpen(res.data);
        });
      },
    },
    {
      icon: 'trash',
      label: 'delete element',
      key: 'deleteRelatedData',
      color: 'red',
      action: data => {
        deleteSingleElement(data).then(() => {
          setnoteModalIsOpen(false);
          setNoteKey(new Date());
        });
      },
    },
  ];

  const readOnlyAction = [
    {
      icon: 'eye',
      label: 'view element',
      key: 'viewRelatedData',
      action: data => {
        getSingleElement(data).then(res => {
          setnoteModalIsOpen(res.data);
        });
      },
    },
  ];

  return (
    <>
      <ListManager
        key={noteKey}
        locale={_.get(props, 'user.defaultLanguage', 'it')}
        entityName={entityName}
        basePath={basePath}
        client={pars => request(pars).then(res => res.data)}
        canExport={{
          active: false,
        }}
        canSearchCustom={{
          active: false,
        }}
        canCreate={{
          active: !readOnly,
          action: () => setnoteModalIsOpen('new'),
        }}
        defaultActions={{
          visualize: false,
          modify: false,
          delete: false,
          grouped: false,
          moreActions: readOnly ? readOnlyAction : manageAction,
        }}
        {...listManagerProps}
      />
      <Modal
        open={!!noteModalIsOpen}
        onClose={() => setnoteModalIsOpen(false)}
        title={
          <h2>
            <Icon name="edit outline" /> New element
          </h2>
        }
        size="mini"
        style={{ width: 400 }}
        scrolling
        {...modalProps}
      >
        <FormManager
          // client={pars => request(pars).then(res => res.data)}
          entityName={entityName}
          basePath={basePath}
          readOnly={readOnly}
          isNew={noteModalIsOpen === 'new'}
          isChild
          data={_.isObject(noteModalIsOpen) ? noteModalIsOpen : null}
          afterCreate={() => {
            setnoteModalIsOpen(false);
            setNoteKey(new Date());
          }}
          afterUpdate={() => {
            setnoteModalIsOpen(false);
            setNoteKey(new Date());
          }}
          onError={data =>
            addNotification({
              title: props.intl.formatMessage(appMessages.error),
              message: _.get(
                data,
                'detail',
                props.intl.formatMessage(appMessages.an_error_has_occurred),
              ),
              isError: true,
            })
          }
          onSuccess={() =>
            addNotification({
              title: props.intl.formatMessage(appMessages.success),
              message: props.intl.formatMessage(
                appMessages.operation_performed_successfully,
              ),
              isError: false,
            })
          }
          {...formManagerProps}
          /**
           * This don't ovverride but rich the original function
           */
          onCleanData={data =>
            formManagerProps.onCleanData(data, noteModalIsOpen)
          }
        />
      </Modal>
    </>
  );
}

RelatedData.propTypes = {};

RelatedData.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(RelatedData);
