/* eslint-disable indent */
import moment from 'moment';
import _ from 'lodash';

export const getRibbonColor = state => {
  switch (state) {
    case 'ACTIVE':
      return 'green';
    case 'INACTIVE':
      return 'red';
    default:
      return 'grey';
  }
};

const closingDaysToArray = val => {
  let parsed;
  try {
    parsed = JSON.parse(val);
  } catch (e) {
    return [];
  }
  if (Array.isArray(parsed)) {
    return parsed.map(day => ({ label: day, value: day }));
  }
  return [];
};

const getProfileFromRole = (data, role) => {
  // eslint-disable-next-line object-shorthand
  const roleObj = _.find(data.profiles, { role: role });

  if (roleObj) {
    return {
      value: _.get(roleObj, 'profile'),
      label: _.get(roleObj, 'displayName'),
    };
  }
  return null;
};

export const transformData = data => ({
  ...data,
  closingDays: _.get(data, 'closingDays')
    ? closingDaysToArray(_.get(data, 'closingDays'))
    : [],
  revenue: _.get(data, 'customer.revenue', ''),
  customer: _.get(data, 'customer.id', null) && {
    value: _.get(data, 'customer.id'),
    label: `${_.get(data, 'customer.code')} -
    ${_.get(data, 'customer.displayName')}`,
    code: _.get(data, 'customer.customerCode', ''),
    vatNumber: _.get(data, 'customer.vatNumber', ''),
    businessName: _.get(data, 'customer.businessName'),
  },
  agent1: _.get(data, 'agent1.id', null) && {
    value: _.get(data, 'agent1.id'),
    label: `${_.get(data, 'agent1.code', '')} - ${_.get(
      data,
      'agent1.displayName',
      '',
    )}`,
    userId: _.get(data, 'agent1.userId'),
  },
  signaller: _.get(data, 'signaller.id', null) && {
    value: _.get(data, 'signaller.id'),
    label: _.get(data, 'signaller.displayName'),
  },
  agent2: _.get(data, 'agent2.id', null) && {
    value: _.get(data, 'agent2.id'),
    label: `${_.get(data, 'agent2.code', '')} - ${_.get(
      data,
      'agent2.displayName',
      '',
    )}`,
  },
  subagent: getProfileFromRole(data, 'subagent'),
  commercialDirector: _.get(data, 'commercialDirector.id', null) && {
    value: _.get(data, 'commercialDirector.id'),
    label: `${_.get(data, 'commercialDirector.displayName', '')}`,
  },
  areaManager: getProfileFromRole(data, 'areaManager'),
  commercialBackoffice: getProfileFromRole(data, 'commercialBackoffice'),
  destinationTypology: _.get(data, 'destinationTypology.id', null) && {
    value: _.get(data, 'destinationTypology.id'),
    label: `${_.get(data, 'destinationTypology.code')} - ${_.get(
      data,
      'destinationTypology.description',
    )}`,
  },
  destinationState: _.get(data, 'destinationState.id', null) && {
    value: _.get(data, 'destinationState.id'),
    label: _.get(data, 'destinationState.description'),
  },
  destinationArea: _.get(data, 'destinationArea.id', null) && {
    value: _.get(data, 'destinationArea.id'),
    label: `${_.get(data, 'destinationArea.code')} - ${_.get(
      data,
      'destinationArea.description',
    )}`,
  },
  destinationSubArea: _.get(data, 'destinationSubArea.id', null) && {
    value: _.get(data, 'destinationSubArea.id'),
    label: `${_.get(data, 'destinationSubArea.code')} - ${_.get(
      data,
      'destinationSubArea.description',
    )}`,
  },
  destinationLogisticArea: _.get(data, 'destinationLogisticArea.id', null) && {
    value: _.get(data, 'destinationLogisticArea.id'),
    label: `${_.get(data, 'destinationLogisticArea.code')} - ${_.get(
      data,
      'destinationLogisticArea.description',
    )}`,
  },
  destinationRange: _.get(data, 'destinationRange.id', null) && {
    value: _.get(data, 'destinationRange.id'),
    label: _.get(data, 'destinationRange.description'),
  },
  destinationPaymentTerm: _.get(data, 'destinationPaymentTerm.id', null) && {
    value: _.get(data, 'destinationPaymentTerm.id'),
    label: `${_.get(data, 'destinationPaymentTerm.code')} - ${_.get(
      data,
      'destinationPaymentTerm.description',
    )}`,
  },
  destinationBank: _.get(data, '_embedded.destinationBank.id', null) && {
    value: _.get(data, '_embedded.destinationBank.id'),
    label: `${_.get(data, '_embedded.destinationBank.description')}`,
  },
  statisticalCustomer: _.get(
    data,
    '_embedded.statisticalCustomer.id',
    null,
  ) && {
    value: _.get(data, '_embedded.statisticalCustomer.id'),
    label: `${_.get(data, '_embedded.statisticalCustomer.displayName')}`,
  },
  buyingGroup: _.get(data, '_embedded.buyingGroup.id', null) && {
    value: _.get(data, '_embedded.buyingGroup.id'),
    label: `${_.get(data, '_embedded.buyingGroup.displayName')}`,
  },
  destinationShippingMethod: _.get(
    data,
    'destinationShippingMethod.id',
    null,
  ) && {
    value: _.get(data, 'destinationShippingMethod.id'),
    label: `${_.get(data, 'destinationShippingMethod.code')} - ${_.get(
      data,
      'destinationShippingMethod.description',
    )}`,
  },
  destinationCarrier: _.get(data, 'destinationCarrier.id', null) && {
    value: _.get(data, 'destinationCarrier.id'),
    label: `${_.get(data, 'destinationCarrier.code')} - ${_.get(
      data,
      'destinationCarrier.description',
    )}`,
  },
  destinationGroup: _.get(data, 'destinationGroup.id', null) && {
    value: _.get(data, 'destinationGroup.id'),
    label: _.get(data, 'destinationGroup.description'),
  },
  birthDate: _.get(data, 'birthDate')
    ? moment(data.birthDate, 'YYYY-MM-DD')
    : null,

  seasonClosingFrom: _.get(data, 'seasonClosingFrom')
    ? moment(data.seasonClosingFrom.date, 'YYYY-MM-DD')
    : null,
  seasonClosingTo: _.get(data, 'seasonClosingTo.date')
    ? moment(data.seasonClosingTo.date, 'YYYY-MM-DD')
    : null,
  shippingTimeFrom: _.get(data, 'shippingTimeFrom.date')
    ? moment(data.shippingTimeFrom.date)
    : null,
  shippingTimeTo: _.get(data, 'shippingTimeTo.date')
    ? moment(data.shippingTimeTo.date)
    : null,
  interventionTimeFrom: _.get(data, 'interventionTimeFrom.date')
    ? moment(data.interventionTimeFrom.date)
    : null,
  interventionTimeTo: _.get(data, 'interventionTimeTo.date')
    ? moment(data.interventionTimeTo.date)
    : null,
});

export const handleCleanData = data => {
  const cleanData = _.cloneDeep(data);

  _.unset(cleanData, 'id');
  _.unset(cleanData, '_links');
  _.unset(cleanData, '_embedded');
  _.unset(cleanData, 'displayName');
  _.unset(cleanData, 'lastAgentAppointment');
  _.unset(cleanData, 'synchronizedAt');
  _.unset(cleanData, 'invoiceCommissions');
  _.unset(cleanData, 'turnoverYear');
  _.unset(cleanData, 'lastInvoiceDate');
  _.unset(cleanData, 'prospectCode');
  _.unset(cleanData, 'destinationCode');
  _.unset(cleanData, 'code');
  _.unset(cleanData, 'revenue');
  _.unset(cleanData, 'updatedAt');
  _.unset(cleanData, 'createdAt');
  _.unset(cleanData, 'deletedAt');
  _.unset(cleanData, 'readAt');
  _.unset(cleanData, 'writeAt');
  _.unset(cleanData, 'lastAction');
  _.unset(cleanData, 'loans');
  _.unset(cleanData, 'invoiceDetail');

  cleanData.excludedFromPrize = cleanData.excludedFromPrize ? true : 0;

  cleanData.blocked = cleanData.blocked ? true : 0;

  cleanData.customer = _.get(cleanData, 'customer.value', cleanData.customer);
  cleanData.agent1 = _.get(cleanData, 'agent1.value', cleanData.agent1);
  cleanData.agent2 = _.get(cleanData, 'agent2.value', cleanData.agent2);
  cleanData.signaller = _.get(
    cleanData,
    'signaller.value',
    cleanData.signaller,
  );

  cleanData.destinationState = _.get(
    cleanData,
    'destinationState.value',
    cleanData.destinationState,
  );

  cleanData.destinationTypology = _.get(
    cleanData,
    'destinationTypology.value',
    cleanData.destinationTypology,
  );

  _.unset(cleanData, 'subagent');
  _.unset(cleanData, 'commercialDirector');
  _.unset(cleanData, 'areaManager');
  _.unset(cleanData, 'commercialBackoffice');

  cleanData.destinationArea = _.get(
    cleanData,
    'destinationArea.value',
    cleanData.destinationArea,
  );

  cleanData.destinationSubArea = _.get(
    cleanData,
    'destinationSubArea.value',
    cleanData.destinationSubArea,
  );

  cleanData.destinationLogisticArea = _.get(
    cleanData,
    'destinationLogisticArea.value',
    cleanData.destinationLogisticArea,
  );

  cleanData.destinationRange = _.get(
    cleanData,
    'destinationRange.value',
    cleanData.destinationRange,
  );

  cleanData.closingDays =
    _.get(cleanData, 'closingDays') &&
    Array.isArray(_.get(cleanData, 'closingDays')) &&
    _.get(cleanData, 'closingDays').length > 0
      ? JSON.stringify(cleanData.closingDays.map(day => day.value))
      : '';

  cleanData.seasonClosingFrom = cleanData.seasonClosingFrom
    ? cleanData.seasonClosingFrom.format('YYYY-MM-DD')
    : null;

  cleanData.seasonClosingTo = cleanData.seasonClosingTo
    ? cleanData.seasonClosingTo.format('YYYY-MM-DD')
    : null;

  cleanData.interventionTimeFrom = cleanData.interventionTimeFrom
    ? cleanData.interventionTimeFrom.format('YYYY-MM-DD HH:mm:ss')
    : null;

  cleanData.interventionTimeTo = cleanData.interventionTimeTo
    ? cleanData.interventionTimeTo.format('YYYY-MM-DD HH:mm:ss')
    : null;

  cleanData.shippingTimeFrom = cleanData.shippingTimeFrom
    ? cleanData.shippingTimeFrom.format('YYYY-MM-DD HH:mm:ss')
    : null;

  cleanData.shippingTimeTo = cleanData.shippingTimeTo
    ? cleanData.shippingTimeTo.format('YYYY-MM-DD HH:mm:ss')
    : null;

  cleanData.destinationPaymentTerm = _.get(
    cleanData,
    'destinationPaymentTerm.value',
    cleanData.destinationPaymentTerm,
  );

  cleanData.destinationBank = _.get(
    cleanData,
    'destinationBank.value',
    cleanData.destinationBank,
  );

  cleanData.statisticalCustomer = _.get(
    cleanData,
    'statisticalCustomer.value',
    cleanData.statisticalCustomer,
  );

  cleanData.buyingGroup = _.get(
    cleanData,
    'buyingGroup.value',
    cleanData.buyingGroup,
  );

  cleanData.destinationShippingMethod = _.get(
    cleanData,
    'destinationShippingMethod.value',
    cleanData.destinationShippingMethod,
  );

  cleanData.destinationCarrier = _.get(
    cleanData,
    'destinationCarrier.value',
    cleanData.destinationCarrier,
  );

  cleanData.destinationGroup = _.get(
    cleanData,
    'destinationGroup.value',
    cleanData.destinationGroup,
  );

  cleanData.splitPayment = cleanData.splitPayment || 0;

  cleanData.oneDDTperOrder = cleanData.oneDDTperOrder || 0;
  cleanData.oneInvoicePerOrder = cleanData.oneInvoicePerOrder || 0;
  cleanData.onePickOrderPerOrder = cleanData.onePickOrderPerOrder || 0;
  cleanData.cumulativeInvoicesPerDestination =
    cleanData.cumulativeInvoicesPerDestination || 0;
  cleanData.cumulativeInvoices = cleanData.cumulativeInvoices || 0;

  _.unset(cleanData, 'lastNoteDate');
  _.unset(cleanData, 'lastOrderDate');

  return cleanData;
};
