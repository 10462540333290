/* eslint-disable no-console */
/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support

import '@babel/polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import 'sanitize.css/sanitize.css';

// Import helmet provider
import { HelmetProvider } from 'react-helmet-async';

// Import root app
import App from 'containers/App';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Import through-provider in support of react-breadcrumbs-dynamic
import { ThroughProvider } from 'react-through';

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico';
import 'file-loader?name=.htaccess!./.htaccess';
import '!file-loader?name=[name].[ext]!./config.json';
/* eslint-enable import/no-unresolved, import/extensions */

import { info } from 'react-notification-system-redux';
import { FormattedMessage } from 'react-intl';
import { store, history } from './configureStore';

import { setAPI, isLoaded } from './global-constants';

// Import i18n messages
import { translationMessages } from './i18n';

import { dispatchNotification } from './utils/notification';

import appMessages from './containers/App/messages';

const MOUNT_NODE = document.getElementById('app');

/**
 * If config was aside the index is ok or then start with default values
 */
const request = async () => {
  try {
    const response = await fetch(`${window.location.origin}/config.json`);
    const json = await response.json();
    return json;
  } catch (e) {
    console.log(e);
    return request();
  }
};

const render = async messages => {
  if (!isLoaded()) {
    const result = await request();
    setAPI(result);
  }

  ReactDOM.render(
    <Provider store={store}>
      <HelmetProvider>
        <LanguageProvider messages={messages}>
          <ConnectedRouter history={history}>
            <ThroughProvider>
              <App />
            </ThroughProvider>
          </ConnectedRouter>
        </LanguageProvider>
      </HelmetProvider>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

const updateNotificationSystem = () =>
  dispatchNotification(
    info({
      title: <FormattedMessage {...appMessages.newAppTitle} />,
      message: <FormattedMessage {...appMessages.newAppMessage} />,
      position: 'tr',
      autoDismiss: 0,
      action: {
        label: <FormattedMessage {...appMessages.newAppAction} />,
        callback: () => window.location.reload(),
      },
    }),
  );

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  // require('offline-plugin/runtime').install(); // eslint-disable-line global-require
  const runtime = require('offline-plugin/runtime'); // eslint-disable-line global-require
  console.log('installing Offline require');
  runtime.install({
    onInstalled: () => {
      console.log('SW Event:', 'Installed');
    },
    onUpdating: () => {
      console.log('SW Event:', 'onUpdating');
    },
    onUpdateReady: () => {
      console.log('SW Event:', 'onUpdateReady');
      // Tells to new SW to take control immediately
      runtime.applyUpdate();
      updateNotificationSystem();
    },
    onUpdated: () => {
      console.log('SW Event:', 'onUpdated');
      // Reload the webpage to load into the new version
    },
    onUpdateFailed: () => {
      console.log('SW Event:', 'onUpdateFailed');
    },
  });
} else {
  updateNotificationSystem();
  console.log('not production mode ENV set');
}
