import React from 'react';
import PropTypes from 'prop-types';
import { Popup, Grid, Icon, Button } from 'semantic-ui-react';
import './css/styles.css';

export class EditorPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isReload: false,
      open: false,
      showHoverInfo: true,
      currentAction: null,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentWillMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  onOpenPopup = event => {
    if (event.type === 'click') {
      this.setState({
        open: true,
        showHoverInfo: false,
        currentAction: event.type,
      });
    } else {
      this.setState({
        open: true,
        showHoverInfo: true,
        currentAction: event.type,
      });
    }
  };

  onCloseItem = event => {
    if (event.type === 'click' && this.state.currentAction === 'click') {
      this.setState({
        open: false,
      });
    } else {
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        open: !this.state.showHoverInfo,
      });
    }
  };

  handleScroll() {
    if (this.state.open) {
      this.setState({ open: false });
    }
  }

  render() {
    const {
      customActions,
      isActiveHover,
      hoverData,
      hasCustom,
      hasEdit,
      hasRemove,
      hasView,
      actionEdit,
      actionRemove,
      actionView,
      triggerItem,
      position,
      displayAction,
    } = this.props;
    const { showHoverInfo } = this.state;
    const arr = [];
    let actionDisplay = [];
    const positionText = `${position} center`;
    if (customActions) {
      customActions.forEach(element => {
        const column = (
          <Grid.Column width={16} onClick={element.action} key={element.name}>
            <Icon name={element.icon} size="large" className="popup-icon" />
            <span>{element.name}</span>
          </Grid.Column>
        );
        arr.push(column);
      });
    }
    if (displayAction) {
      actionDisplay = displayAction;
    } else {
      actionDisplay = ['click'];
    }
    return this.props.isNotToggle ? (
      <div style={{ textAlign: 'center' }}>
        {hasView && (
          <Button circular color="blue" icon="eye" onClick={actionView} />
        )}
        {hasEdit && (
          <Button circular color="blue" icon="edit" onClick={actionEdit} />
        )}
        {hasRemove && (
          <Button circular color="red" icon="delete" onClick={actionRemove} />
        )}
      </div>
    ) : (
      <Popup
        hoverable
        wide
        trigger={triggerItem}
        onClose={this.onCloseItem}
        open={this.state.open}
        onOpen={this.onOpenPopup}
        on={actionDisplay}
        position={positionText}
        className="popup-container"
      >
        {showHoverInfo && isActiveHover ? (
          <Popup.Content>
            <Grid style={this.props.styles}>
              {hasCustom ? hoverData : null}
            </Grid>
          </Popup.Content>
        ) : (
          <Popup.Content onClick={() => this.setState({ open: false })}>
            <Grid>
              {hasEdit ? (
                <Grid.Column width={16} onClick={actionEdit}>
                  <Icon name="edit" size="large" className="popup-icon" />
                  <span>Edit</span>
                </Grid.Column>
              ) : null}
              {hasView ? (
                <Grid.Column width={16} onClick={actionView}>
                  <Icon name="eye" size="large" className="popup-icon" />
                  <span>View</span>
                </Grid.Column>
              ) : null}
              {hasCustom ? arr : null}
              {hasRemove ? (
                <Grid.Column width={16} onClick={actionRemove}>
                  <Icon name="remove" size="large" className="popup-icon" />
                  <span>Delete</span>
                </Grid.Column>
              ) : null}
            </Grid>
          </Popup.Content>
        )}
      </Popup>
    );
  }
}

EditorPopup.propTypes = {
  triggerItem: PropTypes.any,
  hasView: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasRemove: PropTypes.bool,
  actionEdit: PropTypes.func,
  actionView: PropTypes.func,
  actionRemove: PropTypes.func,
  position: PropTypes.string,
  customActions: PropTypes.array,
  hasCustom: PropTypes.bool,
  isActiveHover: PropTypes.bool,
  hoverData: PropTypes.any,
  styles: PropTypes.any,
  displayAction: PropTypes.any,
  isNotToggle: PropTypes.bool,
};

export default EditorPopup;
