import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import BaseForm from './form';
import { addNotification } from '../../../utils/notification';
import { ModalFakeActions } from '../../Layout';
import appMessages from '../../../containers/App/messages';
import messages from './messages';
import getError from '../../../utils/getError';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  firstName: _.get(newValues, 'firstName', ''),
  lastName: _.get(newValues, 'lastName', ''),
  phoneNumber: _.get(newValues, 'phoneNumber', ''),
  mobileNumber: _.get(newValues, 'mobileNumber', ''),
  email: _.get(newValues, 'email', ''),
  emailPec: _.get(newValues, 'emailPec', ''),
  contactTypeId: {
    value: _.get(newValues, '_embedded.contactTypeId.id', ''),
    label: _.get(newValues, '_embedded.contactTypeId.description', ''),
  },
});

export default class Contact extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    entity: PropTypes.string,
    contactId: PropTypes.string,
    contactTypes: PropTypes.array,
    contactService: PropTypes.object,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      firstName: _.get(values, 'firstName', ''),
      lastName: _.get(values, 'lastName', ''),
      phoneNumber: _.get(values, 'phoneNumber', ''),
      mobileNumber: _.get(values, 'mobileNumber', ''),
      email: _.get(values, 'email', ''),
      emailPec: _.get(values, 'emailPec', ''),
      contactTypeId: _.get(values, 'contactTypeId.value', ''),
      [this.props.entity]: this.props.entityId,
    };

    const method = payload.id
      ? this.props.contactService.update
      : this.props.contactService.create;

    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: this.context.intl.formatMessage(messages.contact_saved),
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setErrors(getError(err));
        setSubmitting(false);
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={initialValues(this.props.initialValues || {})}
            onSubmit={this.onSubmit}
            render={props => (
              <BaseForm {...props} contactTypes={this.props.contactTypes} />
            )}
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.close}
            >
              <Icon name="remove" />
              {this.context.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Button
              type="button"
              icon
              labelPosition="left"
              color="green"
              onClick={this.submit}
            >
              <Icon name="checkmark" />
              {this.context.intl.formatMessage(appMessages.save)}
            </Button>
          </div>
        </ModalFakeActions>
      </Grid>
    );
  }
}

Contact.contextTypes = {
  intl: PropTypes.object.isRequired,
};
