import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { TextArea } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import FormikSelect from '../../../FormikSelect';
import FormikField from '../../../FormikField';
import { PaddedColumn } from '../../../Layout';
import messages from '../messages';

// Services
import agentService from '../../../../shared/services/agent';
import StaffWorkOrderStateService from '../../../../shared/services/staffworkorderstate';

const AppointmentForm = props => {
  const { setFieldValue, values } = props;
  const [states, setStates] = useState([]);
  useEffect(
    () => {
      StaffWorkOrderStateService.getAvailableStates(
        values.state ? values.state.value : null,
      ).then(response => {
        const responseStates = response.map(item => ({
          label: item,
          value: item,
        }));
        setStates(_.union([values.state], responseStates));
      });
    },
    [values.state],
  );

  useEffect(() => {
    if (!values.customer.value && !values.destination.value) {
      setFieldValue('state', { value: 'Pianificato', label: 'Pianificato' });
    }
  }, []);
  return (
    <>
      <PaddedColumn width={16}>
        <FormikSelect
          {...props}
          name="state"
          required
          label={props.intl.formatMessage(messages.state)}
          options={states}
          defaultValue={values.state}
          value={values.state}
          onChange={target => {
            setFieldValue('state', {
              value: target.value,
              label: target.value,
            });
          }}
          asyncSelectProps={{
            isRequired: true,
          }}
        />
      </PaddedColumn>
      <PaddedColumn width={16}>
        <FormikAsyncSelect
          {...props}
          name="agent"
          required
          label={props.intl.formatMessage(messages.agent)}
          loadOptions={(searchText, callback) =>
            agentService.getOptions(searchText, callback)
          }
          asyncSelectProps={{
            isRequired: true,
          }}
        />
      </PaddedColumn>
      <PaddedColumn width={16}>
        <FormikField
          {...props}
          name="subject"
          required
          label={props.intl.formatMessage(messages.subject)}
        >
          <TextArea
            onChange={(e, data) => props.setFieldValue('subject', data.value)}
            value={_.get(props, 'values.subject', '')}
            placeholder={props.intl.formatMessage(
              messages.description_placeholder,
            )}
          />
        </FormikField>
      </PaddedColumn>
    </>
  );
};

AppointmentForm.propsType = {
  intl: intlShape.isRequired,
};

export default injectIntl(AppointmentForm);
