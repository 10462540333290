import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the prospect state domain
 */

const selectProspectDomain = state => state.get('prospect', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Prospect
 */

const makeSelectProspect = () =>
  createSelector(selectProspectDomain, substate => substate.toJS());

export default makeSelectProspect;
export { selectProspectDomain };
