import { defineMessages } from 'react-intl';

export const scope = 'novigo.entity.grid.exportManager';

export default defineMessages({
  buttonLabel: {
    id: `${scope}.buttonLabel`,
    defaultMessage: 'Export Elements',
  },
  exportPrefix: {
    id: `${scope}.exportPrefix`,
    defaultMessage: 'Export to',
  },
});
