// Moved in App/selectors
/* export const STYLES = {
  MAIN_COLOR: '#003a81',
  SECOND_COLOR: '#2083cd',
  BG_COLOR: '#E0E0E0', // Neutral color, does not need to be set by the user
  get SUBMENU_COLOR() {
    return Color(STYLES.MAIN_COLOR)
      .brighten(0.15)
      .desaturate(0.5)
      .set('hsl.h', '-11')
      .hex();
  },
  get MENU_ACTIVE_COLOR() {
    return Color(STYLES.SUBMENU_COLOR)
      .brighten(2.3)
      .set('hsl.h', '+351')
      .desaturate(0.13)
      .hex();
  },
  GREY: '#666666',
  get DARK_GREY() {
    return darkenBy(Color(STYLES.GREY), 0.2).hex();
  },
  get LIGHT_GREY() {
    return lightenBy(Color(STYLES.GREY), 0.2).hex();
  },
  SEMANTIC_UI_ACTION_GREY: '#f8f9fa', // UI framework related, does not need to be set by the user
  SIDEBAR_WIDTH: 250,
  SIDEBAR_COLLAPSED_WIDTH: 39,
  TOPBAR_HEIGHT: 65,
  STANDARD_PADDING: 15,
}; */

/*
// ASYNC STORE LOAD
import('./configureStore').then(({ store, history }) => {
  let currentValue = null;
  // Retrieves userIdentity from store
  function select(immutableState) {
    const state = immutableState.toJS();
    return _.get(state, 'user.userIdentity');
  }
  // Listener's callback
  function handleChange() {
    const previousValue = currentValue;
    currentValue = select(store.getState())
    // Check if userIdentity has changed
    if (previousValue !== currentValue) {
      STYLES.MAIN_COLOR = currentValue.color1;
      STYLES.SECOND_COLOR = currentValue.color2;
      console.log(STYLES);
      console.log(
        'Some deep nested property changed from',
        previousValue,
        'to',
        currentValue
      )
    }
  }
  // Store subscription
  store.subscribe(handleChange);
}); */

import _ from 'lodash';

/*
* API constants & ENV
*/
// const URL_TEST = 'http://localhost:8888';
// eslint-disable-next-line no-unused-vars
const URL_LOCAL = 'http://localhost:8888';
const URL_TEST = 'https://deterchimica.test.novigo-consulting.it/api';
const URL_STAGING = 'https://deterchimica-staging.novigo-consulting.it/api';
const URL_PROD = 'https://ar.deterchimica.it/api';
let baseUrl;
let clientToken;

switch (process.env.APP_URLS) {
  case 'production': {
    baseUrl = URL_PROD;
    clientToken = 'Basic ZnJvbnRlbmQ6ZnJvbnRlbmQ=';
    break;
  }
  case 'staging': {
    baseUrl = URL_STAGING;
    clientToken = 'Basic ZnJvbnRlbmQ6ZnJvbnRlbmQ=';
    break;
  }
  case 'test': {
    baseUrl = URL_TEST;
    clientToken = 'Basic ZnJvbnRlbmQ6ZnJvbnRlbmQ=';
    break;
  }
  default: {
    baseUrl = URL_TEST;
    clientToken = 'Basic ZnJvbnRlbmQ6ZnJvbnRlbmQ=';
    break;
  }
}

export const CONFIG = {
  GOOGLE_LOGIN_ACTIVE: false,
  RECAPTCHA: {
    active: false,
    key: '6LeUUb4UAAAAAJqM6bJan2zHTp78O7dhqfSRMQ1v',
  },
};

export const defaultAPI = {
  BASE_URL: baseUrl,
  OAUTH_GRANT_PASSWORD: '/oauth',
  // TODO: Not an url move to different domain
  FIRST_AUTH_TOKEN: clientToken,
  // for hybrid authetication
  OAUTH_HYBRID: '/hybridauth',
  OAUTH_GOOGLE_POPUP: '/hybridauth?provider=Google',
  MYSELF: '/myself',
  USER_MANAGER: '/user-manager',
  PASSWORD_RECOVERY: '/password-recovery',
  ROLES: '/role',
  PERMISSIONS: '/permission',
  RESOURCES: '/resource',
  USERS: '/user',
  FAQ: '/faq',
  PROXY: '/forward-request',

  /**
   * Services.
   */
  AGENT: '/agent',
  AGENT_ATTACHMENT: '/agent-attachment',
  AGENT_COMMISSION_CUSTOM: '/agent-commission-elaboration-custom/get-order',
  AGENT_COMMISSION_ELABORATION: '/agent-commission-elaboration',
  AGENT_CONTACT: '/agent-contact',
  AGENT_NOTE: '/agent-note',
  AGENT_PLANNED_SALES: '/agent-planned-sale',
  AGENT_RATE: '/agent-rate',
  AGENT_TYPE: '/agent-type',
  APPOINTMENT: '/appointment',
  APPOINTMENT_TYPOLOGY: '/appointment-type',
  ATTACHMENTS: '/attachment',
  ATTACHMENT_TYPE: '/attachment-type',
  CONTACT_TYPE: '/contact-type',
  CONTRACT: '/contract',
  CONTRACT_AGENT: '/contract-agent',
  CONTRACT_ATTACHMENT: '/contract-attachment',
  CONTRACT_COMMISSION: '/contract-commission',
  CONTRACT_CUSTOMER: '/contract-customer',
  CONTRACT_NOTE: '/contract-note',
  CRIBIS_MANAGER: '/cribis-manager',
  COMMISSION: '/commission',
  CREATE_COMMISSION: '/create-commission',
  COMMISSION_ROW: '/commission-row',
  COMMISSION_INVOICE: '/commission-invoice',
  COMMISSION_DELETE: '/commission-remove',
  COMMISSION_SET_STATE: '/commission-set-state',
  COMMISSION_GROWTH_BONUS: '/commission-growth-bonus',
  CUSTOMER: '/customer',
  CUSTOMER_ATTACHMENT: '/customer-attachment',
  CUSTOMER_CONTACT: '/customer-contact',
  CUSTOMER_NOTE: '/customer-note',
  DESTINATION: '/destination',
  DESTINATION_AREA: '/destination-area',
  DESTINATION_ATTACHMENT: '/destination-attachment',
  DESTINATION_BANK: '/destination-bank',
  DESTINATION_CARRIER: '/destination-carrier',
  DESTINATION_CONTACT: '/destination-contact',
  DESTINATION_DISCOUNT: '/destination-percentage-commission',
  DESTINATION_EXCEPTION_COMMISSION: '/destination-exception-commission',
  DESTINATION_GROUP: '/destination-group',
  DESTINATION_LOAN: '/destination-loan',
  DESTINATION_LOGISTIC_AREA: '/destination-logistic-area',
  DESTINATION_NOTE: '/destination-note',
  TODAY: '/today',
  DESTINATION_ORDER_AMOUNT: '/destination-order-amount-commission',
  DESTINATION_INVOICE_AMOUNT: '/destination-invoice-amount-commission',
  DESTINATION_PAYMENT_TERM: '/destination-payment-term',
  DESTINATION_PAYMENT_DEADLINES: '/destination-payment-deadlines',
  DESTINATION_PRODUCT_SAMPLING: '/destination-product-sampling',
  DESTINATION_RANGE: '/destination-range',
  DESTINATION_SHIPPING_METHOD: '/destination-shipping-method',
  DESTINATION_STATE: '/destination-state',
  DESTINATION_SUB_AREA: '/destination-sub-area',
  DESTINATION_TYPOLOGY: '/destination-typology',
  DESTINATION_PDF: '/destination-pdf',
  DESTINATION_PROVINCES: '/destination-provinces',
  DESTINATION_HISTORIC: '/destination-historic',
  DISCOUNT_TYPE: '/discount-type',
  EXPORT_EXCEL: 'export-excel',
  GET_AMOUNT_OF_INVOICES: '/get-amount-of-invoices',
  IMPORT_EXCEL: 'import-excel',
  INVOICE: '/invoice',
  INVOICE_ROW: '/invoice-row',
  INVOICE_TYPOLOGY: '/invoice-typology',
  INVOICING_MANAGER: '/invoicing-manager',
  NOTE_MANAGER: '/note-manager',
  NOTE_REASON: '/note-reason',
  NOTES: '/note',
  NOTE_TYPE: '/note-type',
  OFFER: '/offer',
  OFFER_CSV: '/export-offer-csv',
  OFFER_CUSTOM: '/offer-custom',
  OFFER_LOGS: '/offer-logs',
  OFFER_PAYMENT_CONDITION: '/offer-payment-condition',
  OFFER_ROW: '/offer-row',
  OFFER_TYPOLOGY: '/offer-typology',
  OFFER_ORIGIN: '/offer-origin',
  ORDER: '/order',
  ORDER_CUSTOM: '/order-custom',
  ORDER_LOGS: '/order-logs',
  ORDER_ROW_LOGS: '/order-row-logs',
  ORDER_PAYMENT_CONDITION: '/order-payment-condition',
  ORDER_ROW: '/order-row',
  ORDER_TYPOLOGY: '/order-type',
  PRICE_LIST_CUSTOMER: '/price-list-customer',
  PRICE_LIST_MASTER: '/price-list-master',
  PRODUCT: '/product',
  PRODUCT_ATTACHMENT: '/product-attachment',
  PRODUCT_BIN: '/product-bin',
  PRODUCT_CATEGORY: '/product-category',
  PRODUCT_COMMISSION: '/product-commission',
  AGENT_COMMISSION: '/agent-product-commission',
  PRODUCT_COUNTRY: '/product-country',
  PRODUCT_CRM: '/product-crm-category',
  PRODUCT_DOMAIN: '/product-domain',
  PRODUCT_KIT: '/product-kit',
  PRODUCT_LOAN: '/product-loan-category',
  PRODUCT_MANAGER: '/product-manager',
  PRODUCT_PACKAGING_DIMENSION: '/product-packaging-dimension',
  PRODUCT_PACKAGING_TYPE: '/product-packaging-type',
  PRODUCT_PRICE_CUSTOMER: '/product-price-customer',
  PRODUCT_PRICE_MASTER: '/product-price-master',
  PRODUCT_PROVIDER: '/product-provider',
  PRODUCT_STATE: '/product-state',
  PRODUCT_SUB_CATEGORY: '/product-sub-category',
  PRODUCT_SUPPLIER: '/product-supplier',
  PRODUCT_TAX_RATE: '/product-tax-rate',
  PRODUCT_UNIT_MEASURE: '/product-unit-measure',
  PRODUCT_WAREHOUSE: '/product-warehouse',
  PRODUCT_WAREHOUSE_CATEGORY: '/product-warehouse-category',
  PROFILE: '/profile',
  PROFILE_AGENT: '/profile-agent',
  PROFILE_STAFF: '/profile-staff',
  PROMOTION: '/promotion',
  PROSPECT_TO_CUSTOMER: '/prospect-to-customer',
  PROSPECT: '/prospect',
  STAFF: '/staff',
  STAFF_DEPARTMENT: '/staff-department',
  STAFF_NOTE: '/staff-note',
  STAFF_ROLE: '/staff-role',
  STAFF_WORK_ORDER: '/staff-workorder',
  STAFF_WORK_ORDER_ATTACHMENT: '/staff-workorder-attachment',
  STAFF_WORK_ORDER_ATTACHMENT_CUSTOM: '/staff-workorder-attachment-custom',
  STAFF_WORK_ORDER_INTERVENTION: '/staff-workorder-intervention',
  STAFF_WORK_ORDER_LOGS: '/staff-workorder-logs',
  STAFF_WORK_ORDER_PLAN_CUSTOM: '/staff-workorder-plan-custom',
  STAFF_WORK_ORDER_TYPE: '/staff-workorder-type',
  STATE_MACHINE: '/state-machine',
  STATIC_DOCUMENTS: '/static-document',
  STATIC_DOCUMENTS_CATEGORY: '/document-category',
  SUPPLIER: '/supplier',
  SUPPLIER_ATTACHMENT: '/supplier-attachment',
  SUPPLIER_CONTACT: '/supplier-contact',
  SUPPLIER_NOTE: '/supplier-note',
  WATCHER: '/watcher',
  ORDER_DASHBOARD: '/order-dashboard',
  AGENT_STATISTICS_TOP_AGENTS: '/agent-statistics',
  EVENTS_STATISTICS_LAST_EVENTS: '/staff-events-statistics',
  ORDERS_APPROVED_IMPORT_STATISTICS: '/order-approved-import-statistics',
  IMPORT_TYPE: '/import-type',
  EXPORT_TYPE: '/export-type',
  CONTRACT_TYPE: '/contract-type',
  CONTRACT_ROW: '/contract-row',
  CONTRACT_BILLING_TYPE: '/contract-billing-type',
  CONTRACT_PAYMENT_TERM: '/contract-payment-term',
  OFFER_ROW_FREQUENCY: '/offer-row-frequency',
  OFFER_WORD: '/offer-word',
  STAFF_WORKORDER_PDF: '/staff-workorder-pdf',
  BANK: '/bank',
  DESTINATION_CUSTOMER: '/destination-customer',
  AGENT_COMMISSION_EXPORT_PDF: '/agent-commission-pdf',
  VERIFY_GOODS_DISCOUNT: '/verify-goods-discount',
  DOCUMENT: '/document',
  STAFF_WORK_ORDER_TAB: '/staff-work-order-tab',
  CONTRACT_ORDER_FREQUENCY: '/contract-order-frequency',
  FILES: '/files',
  CUSTOMER_PRODUCT_PURCHASE: '/customer-product-purchase',
  GET_INSPECTOR_LIST: '/get-inspector-list',
};

// eslint-disable-next-line import/no-mutable-exports
export let API = {};

let loaded = false;

/**
 * This function expose a setter in order to config the application in the very early phase.
 * (after change it the application in auto-restarted)
 *
 * @param {*} api
 */
export const setAPI = api => {
  API = _.merge(defaultAPI, api);
  loaded = true;
};

export const isLoaded = () => loaded;

export const getFullAPIUrl = API_STRING => `${API.BASE_URL}${API[API_STRING]}`;
