/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import Login from 'containers/Login/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import injectReducer from 'utils/injectReducer';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import {
  makeSelectUser,
  makeSelectNotifications,
  makeSelectStyles,
} from './selectors';
import reducer from './reducer';
import AuthRoutes from './authroutes';

import GlobalStyle from '../../global-styles';
import { userIsAuthenticated, userIsNotAuthenticated } from './auth';
import { notificationsStyle } from '../../components/Layout';

const isNotAuth = userIsNotAuthenticated(Login);
const isAuth = userIsAuthenticated(AuthRoutes);

export function App(props) {
  return (
    <>
      <Notifications
        notifications={props.notifications}
        style={notificationsStyle}
      />
      <Switch>
        <Route exact path="/login" component={isNotAuth} />
        <Route path="/" component={isAuth} />
        <Route path="" component={NotFoundPage} />
      </Switch>
      <GlobalStyle styles={props.styles} />
    </>
  );
}

function mapDispatchToProps() {
  return {
    // logout: () => dispatch(logout()),
    // loadAcl: () => dispatch(loadAcl()),
  };
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  notifications: makeSelectNotifications(),
  styles: makeSelectStyles(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'user', reducer });

export default compose(
  withReducer,
  withConnect,
  injectIntl,
)(App);
