import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form, TextArea } from 'semantic-ui-react';
import Select from 'react-select';
import SimpleFormikField from 'novigo-simple-formik-field';
import { PaddedColumn } from '../../../Layout';
import FormikField from '../../../FormikField';
import messages from '../messages';

export const BaseForm = props => {
  /**
   * The current note reasons.
   */
  const [noteReasons, setNoteReasons] = useState(props.noteReasons);

  /**
   * When the note type changes, the list of available reasons can be different.
   */
  useEffect(
    () => {
      if (_.get(props, 'values.noteType.label') === 'Richiesta Intervento') {
        setNoteReasons(noteReasons.filter(item => item.technical));

        // Reset current note reason because it's not technical.
        if (!_.get(props, 'values.noteReason.technical', false)) {
          props.setFieldValue('noteReason', null);
        }

        return;
      }

      setNoteReasons(props.noteReasons);
    },
    [_.get(props, 'values.noteType')],
  );

  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting}
    >
      <Grid columns="equal">
        <Grid.Row>
          <PaddedColumn width={8}>
            <SimpleFormikField
              {...props}
              name="noteNumber"
              label={props.intl.formatMessage(messages.noteNumber)}
              placeholder={props.intl.formatMessage(messages.noteNumber)}
              disabled={props.isReadOnly}
            />
          </PaddedColumn>
          <PaddedColumn width={8}>
            <FormikField
              name="noteType"
              label={props.intl.formatMessage(messages.note_type)}
              {...props}
            >
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={props.values.noteType}
                name="noteType"
                options={props.noteTypes}
                onChange={data => props.setFieldValue('noteType', data)}
              />
            </FormikField>
          </PaddedColumn>
          <PaddedColumn width={8}>
            <FormikField
              name="noteReason"
              label={props.intl.formatMessage(messages.note_motivation)}
              {...props}
            >
              <Select
                className="basic-single"
                classNamePrefix="select"
                value={props.values.noteReason}
                options={noteReasons}
                name="noteReason"
                onChange={data => props.setFieldValue('noteReason', data)}
              />
            </FormikField>
          </PaddedColumn>
          <PaddedColumn width={8}>
            <SimpleFormikField
              {...props}
              name="talkedTo"
              label={props.intl.formatMessage(messages.talked_to)}
              placeholder={props.intl.formatMessage(messages.talked_to)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <Grid.Column>
              <PaddedColumn>
                <Grid.Column>
                  <FormikField
                    name="mailItems"
                    label={props.intl.formatMessage(messages.send_mail_to)}
                    {...props}
                  >
                    <Select
                      isMulti
                      className="basic-single"
                      classNamePrefix="select"
                      value={props.values.mailItems}
                      name="mailItems"
                      options={props.usersList}
                      onChange={data => props.setFieldValue('mailItems', data)}
                    />
                  </FormikField>
                </Grid.Column>
              </PaddedColumn>
              <PaddedColumn />
            </Grid.Column>
          </PaddedColumn>
          <PaddedColumn width={16}>
            <FormikField
              name="noteText"
              label={props.intl.formatMessage(messages.note)}
              {...props}
            >
              <TextArea
                onChange={(e, data) =>
                  props.setFieldValue('noteText', data.value)
                }
                value={_.get(props, 'values.noteText', '')}
                placeholder={props.intl.formatMessage(
                  messages.note_placeholder,
                )}
              />
            </FormikField>
          </PaddedColumn>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BaseForm);
