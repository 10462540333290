import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_LOAN,
    method: 'GET',
    params: {
      page: 1,
      pageSize: 8,
      'order-by': [
        {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getTotalLoanAmount = (customer, destination) =>
  request({
    baseURL: API.BASE_URL,
    url: 'aggregate',
    method: 'POST',
    data: {
      entityName: 'destination-loan',
      field: 'totalGrossPriceAmount',
      aggregate: 'SUM',
      filter: [
        customer && {
          type: 'eq',
          field: 'customer',
          value: customer,
        },
        destination && {
          type: 'eq',
          field: 'destination',
          value: destination,
        },
      ].filter(o => o),
    },
  });

const DestinationLoanService = {
  getAll,
  getTotalLoanAmount,
};

export default DestinationLoanService;
