/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
// import Styled from 'styled-components';
import BaseForm from './baseForm';
import { addNotification } from '../../../utils/notification';
import { ModalFakeActions } from '../../Layout';
import appMessages from '../../../containers/App/messages';
import getError from '../../../utils/getError';
import DestinationNoteService from '../../../shared/services/destinationnote';
import CustomerNoteService from '../../../shared/services/customernote';

export default class Note extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    noteId: PropTypes.string,
    noteService: PropTypes.object,
    entity: PropTypes.string,
    isReadOnly: PropTypes.bool,
  };

  state = {
    initialValues: {},
    isLoading: false,
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    const method =
      this.props.data.type === 'DestinationNote'
        ? DestinationNoteService
        : CustomerNoteService;
    method.getById(this.props.data.id).then(response => {
      const data = _.get(response, 'data', null);
      const initialValues = {
        id: _.get(data, 'id', null),
        noteText: _.get(data, 'noteText', ''),
        noteNumber: _.get(data, 'noteNumber', ''),
        noteType: {
          value: _.get(data, 'noteType.id', ''),
          label: _.get(data, 'noteType.description', ''),
        },
        noteReason: {
          value: _.get(data, 'noteReason.id', ''),
          label: _.get(data, 'noteReason.description', ''),
          isPestControl: _.get(data, 'noteReason.isPestControl', ''),
        },
        staff: {
          value: _.get(data, 'staff.id', ''),
          label: _.get(data, '.staff.displayName', ''),
        },
        assignee: {
          value: _.get(data, 'assignee.id', ''),
          label: `${_.get(data, 'assignee.firstname', '')} ${_.get(
            data,
            'assignee.lastname',
            '',
          )}`,
        },
        expireDate: _.get(data, 'expireDate.date', ''),
        users: _.get(data, 'users', []).map(el => ({
          value: el.id,
          label: `${el.firstName} ${el.lastName}`,
        })),
      };
      this.setState({ initialValues, isLoading: false });
    });
  }

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      noteText: _.get(values, 'noteText', ''),
      noteNumber: _.get(values, 'noteNumber', ''),
      assignee: _.get(values, 'assignee.value', ''),
      noteType: _.get(values, 'noteType.value', ''),
      noteReason: _.get(values, 'noteReason.value', ''),
      staff: _.get(values, 'staff.value', ''),
      expireDate: values.expireDate
        ? moment(values.expireDate).format('YYYY-MM-DD')
        : null,
      users:
        _.get(values, 'users', []).length > 0
          ? _.get(values, 'users').map(user => user.value)
          : [],
      [this.props.entity]: this.props.entityId,
    };
    const noteService =
      this.props.data.type === 'DestinationNote'
        ? DestinationNoteService
        : CustomerNoteService;
    const method = payload.id ? noteService.update : noteService.create;

    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: null,
          message: 'Nota salvata con successo',
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setSubmitting(false);
        setErrors(getError(err));
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={this.state.initialValues || {}}
            onSubmit={this.onSubmit}
            enableReinitialize
            render={props => (
              <BaseForm
                {...props}
                isLoading={this.state.isLoading}
                isReadOnly={this.props.data && this.props.data.isReadOnly}
                noteTypes={this.props.noteTypes}
                noteReasons={this.props.noteReasons}
              />
            )}
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.close}
            >
              <Icon name="remove" />
              {this.context.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          {this.props.data &&
            !this.props.data.isReadOnly && (
              <div
                style={{
                  display: 'inline-block',
                  width: '50%',
                  textAlign: 'right',
                }}
              >
                <Button
                  type="button"
                  icon
                  labelPosition="left"
                  color="green"
                  onClick={this.submit}
                >
                  <Icon name="checkmark" />
                  {this.context.intl.formatMessage(appMessages.save)}
                </Button>
              </div>
            )}
        </ModalFakeActions>
      </Grid>
    );
  }
}

Note.contextTypes = {
  intl: PropTypes.object.isRequired,
};
