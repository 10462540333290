import _ from 'lodash';
import qs from 'qs';
import { API } from '../../../global-constants';
import request from '../request';
import {
  getExecutedAt,
  getValueByField,
  getValueByAlias,
  getValueByType,
  getToday,
} from './functions';

const getAllNotes = params => {
  const { filters = [], pagination = {} } = params;

  return request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_NOTE,
    method: 'GET',
    params: {
      ...pagination,
      'order-by': [
        {
          type: 'field',
          field: 'plannedDate',
          direction: 'DESC',
        },
      ],
      filter: [
        {
          type: 'leftjoin',
          field: 'noteType',
          alias: 'noteType',
        },
        {
          type: 'leftjoin',
          field: 'noteReason',
          alias: 'noteReason',
        },
        {
          type: 'leftjoin',
          field: 'assignee',
          alias: 'assignee',
        },
        ...filters,
      ].filter(o => o),
      notesHub: 1,
    },
    paramsSerializer: p => qs.stringify(p),
  });
};

const getTodayRPC = params => {
  const { filters = [], pagination = {} } = params;
  console.log(filters, pagination);

  // TODO: Parameterize the query with all filters
  return request({
    baseURL: API.BASE_URL,
    url: API.TODAY,
    method: 'POST',
    data: {
      page: pagination.page,
      pageSize: pagination.pageSize,
      executed: getExecutedAt(filters),
      createdBy: getValueByField(filters, 'createdBy'),
      note_type_id: getValueByAlias(filters, 'noteType'),
      destination: getValueByField(filters, 'destination'),
      type: getValueByField(filters, 'type'),
      note_reason_id: getValueByAlias(filters, 'noteReason'),
      assignee_id: getValueByField(filters, 'assignee'),
      agent_id: getValueByType(filters, 'search_note_agent'),
      createdAt: getValueByField(filters, 'createdAt'),
      today: getToday(filters),
    },
    paramsSerializer: p => qs.stringify(p),
  });
};

const getNoteTypes = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.NOTE_TYPE,
    method: 'GET',
    params: {
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getNoteTypeOptions = () =>
  getNoteTypes().then(res =>
    _.get(res, 'data._embedded.note-type', []).map(type => ({
      label: type.description,
      value: type.id,
    })),
  );

const getReasonTypes = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.NOTE_REASON,
    method: 'GET',
    params: {},
  });

const getReasonTypeOptions = () =>
  getReasonTypes().then(res =>
    _.get(res, 'data._embedded.note-reason', []).map(
      ({ id, description, technical }) => ({
        value: id,
        label: description,
        technical,
      }),
    ),
  );

const searchNoteTypeByDescription = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.NOTE_TYPE,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAsyncNoteTypeOptions = (inputValue, callback) => {
  searchNoteTypeByDescription(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.note-type', []).map(noteType => ({
        value: noteType.id,
        label: noteType.description,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchNoteReasonByDescription = (searchText, isTechnical) =>
  request({
    baseURL: API.BASE_URL,
    url: API.NOTE_REASON,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
        isTechnical && {
          type: 'eq',
          field: 'technical',
          value: true,
        },
        {
          type: 'orx',
          conditions: [
            {
              type: 'neq',
              field: 'hidden',
              value: true,
              where: 'or',
            },
            {
              type: 'isnull',
              field: 'hidden',
              where: 'or',
            },
          ],
          where: 'and',
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAsyncNoteReasonOptions = (inputValue, callback, isTechnical) => {
  searchNoteReasonByDescription(inputValue, isTechnical).then(results => {
    callback(
      _.get(results, 'data._embedded.note-reason', []).map(noteType => ({
        value: noteType.id,
        label: noteType.description,
        isPestControl: noteType.isPestControl,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.NOTE}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const exportNotes = ({ format = 'Xlsx' }, body = ['']) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PROXY}`,
    method: 'POST',
    data: {
      method: 'POST',
      microserviceName: 'excel',
      route: `export-excel`,
      params: {
        filename: 'export',
        format,
        body,
        header: [
          'Note Type',
          'Expire date',
          'Note Reason',
          'Client',
          'Destination',
          'Note',
          'Actions',
        ],
      },
    },
  });

const NoteService = {
  getAllNotes,
  getTodayRPC,
  getNoteTypeOptions,
  getReasonTypeOptions,
  getAsyncNoteTypeOptions,
  getAsyncNoteReasonOptions,
  update,
  exportNotes,
};

export default NoteService;
