/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/**
 *
 * ManageRolePermissions
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Label, Modal, Form, Grid } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import _ from 'lodash';
import Select from 'react-select';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import makeSelectManageRolePermissions from './selectors';
import { makeSelectStyles } from '../App/selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import roleMessages from '../GestioneAcl/messages';

import PermissionsService from '../../shared/services/permissions';
import ResourceService from '../../shared/services/resources';
import RoleService from '../../shared/services/roles';
import { EditBtn, BackBtn } from '../../components/Buttons';
import PermissionForm from '../../components/Forms/Permission';

import { Wrapper, H2 } from '../../components/Layout';

/* eslint-disable react/prefer-stateless-function */
export class ManageRolePermissions extends React.Component {
  state = {
    isLoading: true,
    permissions: [],
    resources: [],
    computedResources: [],
    roleName: '',
    filterResType: '',
    filterPrivilege: '',
    privilegeOptions: [],
    resTypeOptions: [],
  };

  componentDidMount() {
    this.setState({ isMounted: true }, this.loadData);
  }

  onClose = () => this.setState({ modalOpened: false });

  onPrivilegeTypeChange = opt =>
    this.setState({ filterPrivilege: _.get(opt, 'value') || '' });

  onResTypeChange = opt =>
    this.setState({ filterResType: _.get(opt, 'value') || '' });

  createResourceWithPermissions = () => {
    const { resources, permissions } = this.state;
    this.setState(
      {
        computedResources: resources.map(res => ({
          ...res,
          permission: permissions.filter(
            permission => permission.resource.id === res.id,
          )[0],
        })),
      },
      () => {},
    );
  };

  loadData = () => {
    const { roleid } = this.props.match.params;
    this.setState({ isLoading: true });
    Promise.all([
      ResourceService.getResources(),
      PermissionsService.getByRole(roleid),
      RoleService.getById(roleid),
    ])
      .then(([ResourceRes, PermissionRes, RoleRes]) => {
        if (this.state.isMounted) {
          this.setState(
            {
              resources: ResourceRes.data._embedded.resource,
              permissions: PermissionRes.data._embedded.permission,
              roleName: RoleRes.data.roleId,
              isLoading: false,
            },
            () => {
              this.createResourceWithPermissions();
              this.createResourcePrivilegeOptions();
            },
          );
        }
      })
      .catch(() => this.setState({ isLoading: false }));
  };

  managePermissions = data => {
    const payload = {
      id: data.id || null,
      privilege: data.privilege || [],
      resource: data.resource,
      role: data.role,
    };
    const save = data.id ? PermissionsService.put : PermissionsService.create;
    save(payload)
      .then(() => {
        this.loadData();
        this.onClose();
      })
      .catch();
  };

  createResourcePrivilegeOptions = () => {
    let privilegeType = [];
    let resType = [];
    this.state.resources.forEach(element => {
      privilegeType = [...new Set([...privilegeType, ...element.privilegies])];
      resType = [...new Set([...resType, ...[element.type]])];
    });
    this.setState({
      privilegeOptions: privilegeType.map(res => ({ label: res, value: res })),
      resTypeOptions: resType.map(res => ({ label: res, value: res })),
    });
  };

  columns = () => [
    {
      key: 'Descrizione',
      name: 'Description',
      formatter: ({ data }) => data.description,
    },
    {
      key: 'a',
      name: 'Resource',
      formatter: ({ data }) => data.name,
    },
    {
      key: 'p',
      name: 'Privileges',
      formatter: ({ data }) =>
        _.get(data, 'permission.privilege')
          ? data.permission.privilege.map(privilege => (
            <Label key={privilege}>{privilege}</Label> // eslint-disable-line
          )) // eslint-disable-line
          : '--',
    },
    {
      key: 'actions',
      name: 'Actions',
      formatter: ({ data }) => (
        <div style={{ width: 40 }}>
          <EditBtn
            action={() =>
              this.setState({ currentPermission: data, modalOpened: true })
            }
          />
        </div>
      ),
      width: '40px',
    },
  ];

  render() {
    const {
      computedResources,
      isLoading,
      filterPrivilege,
      filterResType,
    } = this.state;
    return (
      <>
        <BreadcrumbsItem to="/gestione-acl">
          {this.context.intl.formatMessage(roleMessages.title)}
        </BreadcrumbsItem>
        <BreadcrumbsItem to="/gestione-acl/edit">
          {this.context.intl.formatMessage(messages.title)}
        </BreadcrumbsItem>
        <Wrapper style={{ margin: '1rem 0' }}>
          <H2>
            <BackBtn action={() => this.props.history.goBack()} /> Privileges
            for {this.state.roleName}
          </H2>
          <Grid>
            <Grid.Column width="4">
              <Form>
                <Form.Field>
                  <label htmlFor="parent">Privilege type</label>
                  <Select
                    id="parent"
                    value={{ label: filterPrivilege, value: filterPrivilege }}
                    className="basic-single"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    isClearable
                    onChange={this.onPrivilegeTypeChange}
                    isSearchable
                    name="color"
                    options={this.state.privilegeOptions}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width="4">
              <Form>
                <Form.Field>
                  <label htmlFor="parent">Resource type</label>
                  <Select
                    id="parent"
                    value={{ label: filterResType, value: filterResType }}
                    className="basic-single"
                    classNamePrefix="select"
                    isLoading={isLoading}
                    isClearable
                    onChange={this.onResTypeChange}
                    isSearchable
                    name="color"
                    options={this.state.resTypeOptions}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid>
          <div>
            <Table
              isLoading={this.state.isLoading}
              elements={computedResources
                .filter(
                  res =>
                    filterPrivilege
                      ? _.get(res, 'permission.privilege')
                        ? _.get(res, 'permission.privilege').includes(
                          filterPrivilege, // eslint-disable-line
                        ) // eslint-disable-line
                        : false
                      : true,
                )
                .filter(
                  res => (filterResType ? res.type === filterResType : true),
                )}
              rowClassKey="role-row-class"
              columns={this.columns()}
              canSort={{
                active: true,
              }}
              canPaginate={{
                active: false,
                pageCount: 10,
                pageSize: 10,
                totalItems: 100,
                page: 1,
                pageMax: 5,
                render: <div>This ia a custom pagination Object</div>,
              }}
              hiddenHeaderIfEmpty
              emptyResults={<div>No results!</div>}
            />
            <Modal
              open={this.state.modalOpened}
              onClose={this.onClose}
              header="Role management"
              content={
                <PermissionForm
                  onSubmit={this.managePermissions}
                  currentPermission={this.state.currentPermission}
                  roleId={this.props.match.params.roleid}
                  styles={this.props.styles}
                />
              }
              size="tiny"
            />
          </div>
        </Wrapper>
      </>
    );
  }
}

ManageRolePermissions.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  styles: PropTypes.object,
};

ManageRolePermissions.contextTypes = {
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  manageRolePermissions: makeSelectManageRolePermissions(),
  styles: makeSelectStyles(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'manageRolePermissions', reducer });
const withSaga = injectSaga({ key: 'manageRolePermissions', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ManageRolePermissions);
