/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/**
 *
 * Customer Totally REST manager
 *
 */
/* eslint-disable indent */

import _, { get } from 'lodash';
// import ListManager from 'novigo-entity-grid';
// import Select from 'react-select';
import FormManager from 'novigo-form-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Header, Label } from 'semantic-ui-react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import moment from 'moment';
import ListManager from '../../components/DeterchimicaEntityGrid/index';
import DestinationForm, {
  validation,
} from '../../components/Forms/DestinationForm';
import { ListWrapper, Page } from '../../components/Layout';
import PermissionManager from '../../components/Permission';
import AnagraficaSearchBar from '../../components/SearchBars/shared/Anagrafica/SearchBar';
/*
 * Specific import
 */
import { API } from '../../global-constants';
import destinationService from '../../shared/services/destination';
import MunicipalityService from '../../shared/services/municipality';
import request from '../../shared/services/request';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import { addNotification } from '../../utils/notification';
import appMessages from '../App/messages';
import { getRibbonColor, handleCleanData, transformData } from './functions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import makeSelectCustomer from './selectors';
import { CanManage, GetCurrentRoles } from '../Can/utils';
import ProfileService from '../../shared/services/profile';
import { getEntityError } from '../../utils/getError';

/* eslint-disable react/prefer-stateless-function */
export class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      headerTitle: '',
      aliases: [
        {
          type: 'isnotnull',
          field: 'customer',
        },
        {
          type: 'leftjoin',
          field: 'customer',
          alias: 'customer',
        },
        {
          type: 'leftjoin',
          field: 'destinationState',
          alias: 'destinationState',
        },
        {
          type: 'leftjoin',
          field: 'destinationArea',
          alias: 'area',
        },
        {
          type: 'leftjoin',
          field: 'agent1',
          alias: 'agent',
        },
        {
          type: 'groupBy',
          field: 'id',
        },
      ],
      filters: [],
      searchFilter: null,
      filterLabels: [],
      initialValues: {},
      searchedValue: '',
      removedFilter: '',
      reloadIndex: 0,
      isUserAgent: false,
    };
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'destination',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  handleInitialFilters() {
    const { /* aliases, */ filters, initialValues, filterLabels } = this.state;

    // filters.push({
    //   type: 'eq',
    //   where: 'and',
    //   field: 'isActive',
    //   value: 1,
    // });

    // filterLabels.push({
    //   key: 'isActive',
    //   name: this.context.intl.formatMessage(messages.isActive),
    //   value: this.context.intl.formatMessage(messages.yes),
    //   filterField: 'isActive',
    // });

    const roles = GetCurrentRoles();
    if (
      _.intersection(roles, [
        'ROLE_AGENTE',
        'ROLE_RESPONSABILE_AGENTI',
        'ROLE_RESPONSABILE_AREA_COMMERCIALE',
      ]).length > 0
    ) {
      filterLabels.push({
        key: 'isOnlyMine',
        name: this.context.intl.formatMessage(messages.onlyMine),
        value: this.context.intl.formatMessage(messages.yes),
        filterField: 'isOnlyMine',
      });

      filters.push({
        where: 'and',
        type: 'search_agent_destination',
      });

      initialValues.isOnlyMine = true;

      this.setState(
        {
          filters,
          initialValues,
          filterLabels,
          isUserAgent: true,
          // userAgentId: res[0].value,
        },
        () => {
          // this.setState(prev => ({ reloadIndex: prev.reloadIndex + 1 }));
        },
      );
    }

    // if (this.props.user) {
    //   ProfileService.getAgentOptionsByUserId(this.props.user.id, res => {
    //     if (res && res.length > 0) {
    //       filterLabels.push({
    //         key: 'isOnlyMine',
    //         name: this.context.intl.formatMessage(messages.onlyMine),
    //         value: this.context.intl.formatMessage(messages.yes),
    //         filterField: 'isOnlyMine',
    //       });
    //       aliases.push({
    //         type: 'orx',
    //         conditions: [
    //           {
    //             type: 'eq',
    //             where: 'or',
    //             field: 'agent1',
    //             value: res[0].value,
    //           },
    //           {
    //             type: 'eq',
    //             where: 'or',
    //             field: 'agent2',
    //             value: res[0].value,
    //           },
    //           {
    //             type: 'eq',
    //             where: 'or',
    //             field: 'subagent',
    //             value: res[0].value,
    //           },
    //         ],
    //         where: 'and',
    //       });
    //       this.setState(
    //         {
    //           aliases,
    //           initialValues,
    //           filterLabels,
    //           isUserAgent: true,
    //           userAgentId: res[0].value,
    //         },
    //         () => {
    //           this.setState(prev => ({ reloadIndex: prev.reloadIndex + 1 }));
    //         },
    //       );
    //     }
    //   });
    // }
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'view' ||
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'modify' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showForm: true });
    } else {
      this.setState({
        showForm: false,
        headerTitle: this.context.intl.formatMessage(messages.title),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.checkPermission();
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.checkPermission();
    this.evaluteAction();
    this.handleInitialFilters();
    this.setState({
      headerTitle: this.context.intl.formatMessage(messages.title),
    });
  }

  handleResetFilter = () => {
    this.setState(
      {
        filterLabels: [],
        filters: [],
        searchedValue: '',
      },
      () => {
        this.setState(prev => ({ reloadIndex: prev.reloadIndex + 1 }));
      },
    );
  };

  onDefaultSearch = (val, filters) => {
    this.setState({ searchedValue: val, searchFilter: filters });
  };

  handleSearch = (filters, values, labels) => {
    const { aliases } = this.state;

    const updatedAliases = aliases.map((alias, i) => {
      // If the filters already contain this field, return filters value
      // otherwise return the predefined alias
      const filterData = filters.find(
        item => item.field === alias.field && item.type === alias.type,
      );
      if (filterData) {
        return filterData;
      }
      return alias;
    });

    return new Promise(resolve => {
      this.setState({
        filters,
        // initialValues: values,
        aliases: updatedAliases,
        filterLabels: labels,
      });
      resolve(true);
    });
  };

  handleRemovedFilter = name => {
    const { initialValues } = this.state;
    initialValues[name] = null;
    this.setState({
      initialValues,
      removedFilter: '',
    });
  };

  exportPDF = () => {
    destinationService
      .exportPDF()
      .then(response => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .replace(/"/g, '')
          .trim();
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', url);
        element.setAttribute('download', filename);
        element.style.display = '';
        element.click();
        document.body.removeChild(element);
      })
      .catch(err => {
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  onRemoveFilter = val => {
    const { initialValues } = this.state;

    const filterLabels = this.state.filterLabels.filter(
      el => el.filterField !== val,
    );
    const filters = this.state.filters.filter(el => el.field !== val);

    _.unset(initialValues, val);

    this.setState(prev => ({
      removedFilter: prev.removedFilter ? '' : val,
      filterLabels,
      filters,
      initialValues,
    }));
  };

  handleTitle = title => {
    if (!title) {
      this.setState({
        headerTitle: this.context.intl.formatMessage(messages.title),
      });
    } else {
      this.setState({ headerTitle: title });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Departments</title>
          <meta
            name="description"
            content={this.context.intl.formatMessage(messages.title)}
          />
        </Helmet>
        <BreadcrumbsItem to="/customer">
          {this.context.intl.formatMessage(messages.title)}
        </BreadcrumbsItem>
        <Page>
          <Header as="h2" dividing>
            {this.state.headerTitle}
          </Header>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/customer/edit">
                {actionToBreadcrumb({
                  action: this.props.match.params.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                key={`${this.props.match.params.action}-${
                  this.props.match.params.id
                }`} // necessary to reload the current view upon redirect
                router={this.props} // necessary to use the internal routing
                client={pars =>
                  request(pars)
                    .then(res => transformData(res.data))
                    .catch(err => {
                      // If 404, navigate back to the previous page
                      if (err.status === 404) {
                        addNotification({
                          title: this.context.intl.formatMessage(
                            appMessages.error,
                          ),
                          message: getEntityError(err, this.context.intl),
                          isError: true,
                        });
                        window.history.back();
                      } else {
                        throw err;
                      }
                    })
                }
                mask={props => (
                  <DestinationForm
                    {...props}
                    id={this.props.match.params.id}
                    municipalityService={MunicipalityService}
                    editRoute={`/customer/${this.props.match.params.id}/modify`}
                    history={this.props.history}
                    mode="customer"
                    handleTitle={this.handleTitle}
                    user={this.props.user}
                  />
                )}
                data={
                  this.props.match.params.id === 'new' && {
                    registryType: 'L',
                    gender: 'M',
                  }
                }
                afterUpdate={() => {}}
                afterDelete={() => this.props.history.push(`${API.CUSTOMER}`)}
                validation={values =>
                  validation(values, this.context.intl, false)
                }
                onCleanData={handleCleanData}
                entityName="destination"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                onError={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  })
                }
                onSuccess={() =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
              />
            </>
          ) : (
            <ListWrapper>
              <ListManager
                ref={list => {
                  this.list = list;
                }}
                locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                translations={{
                  createButton: {
                    label: this.context.intl.formatMessage(
                      messages.new_customer,
                    ),
                  },
                }}
                sessionKey="customer-destination"
                reloadIndex={this.state.reloadIndex}
                entityName="destination"
                routerName="customer"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                router={this.props}
                aliases={this.state.aliases}
                customSearchFilters={this.state.filters}
                filterLabels={this.state.filterLabels}
                searchedValue={this.state.searchedValue}
                searchFiler={this.state.searchFilter}
                onRemoveFilter={this.onRemoveFilter}
                onDefaultSearch={this.onDefaultSearch}
                client={pars => request(pars).then(res => res.data)}
                defaultOrder={[
                  {
                    type: 'field',
                    field: 'createdAt',
                    direction: 'desc',
                  },
                ]}
                canExport={{
                  exportLabelPrefix: this.context.intl.formatMessage(
                    appMessages.export_to,
                  ),
                  pdf: false,
                }}
                canCreate={{
                  active: false,
                }}
                canSelect={{
                  active: false,
                  selectAll: false,
                  actions: [],
                }}
                canSearchCustom={AnagraficaSearchBar({
                  intl: this.context.intl,
                  initialValues: this.state.initialValues,
                  filters: this.state.filters,
                  removedFilter: this.state.removedFilter,
                  isCustomer: true,
                  preSelectActive: true,
                  isUserAgent: this.state.isUserAgent,
                  userAgentId: this.state.userAgentId,
                  preSelectOnlyMine: this.state.isUserAgent,
                  handleRemovedFilter: this.handleRemovedFilter,
                  onRemoveFilter: this.onRemoveFilter,
                  onSearch: (filters, values, labels) =>
                    this.handleSearch(filters, values, labels),
                })}
                columns={[
                  {
                    key: 'code',
                    name: this.context.intl.formatMessage(
                      messages.customerCode,
                    ),
                    useAlias: 'customer',
                    searchable: true,
                    sortable: true,
                    formatter: ({ data }) => {
                      const { code } = data;
                      const state = _.get(
                        data,
                        'destinationState.description',
                        null,
                      );

                      return (
                        <>
                          {state && (
                            <Label ribbon color={getRibbonColor(state)}>
                              {this.context.intl.formatMessage(messages[state])}
                              <br />
                            </Label>
                          )}
                          <div style={{ marginTop: state ? '5px' : '0' }}>
                            {code}
                          </div>
                        </>
                      );
                    },
                    width: '10%',
                  },
                  {
                    key: 'businessName',
                    name: (
                      <span>
                        {this.context.intl.formatMessage(messages.businessName)}
                        <br />
                        {this.context.intl.formatMessage(messages.shopSign)}
                      </span>
                    ),
                    searchable: true,
                    sortable: true,
                    width: 200,
                    formatter: ({ data }) => {
                      const businessName = _.get(
                        data,
                        'customer.businessName',
                        '--',
                      );
                      const shopSign = _.get(data, 'businessName', '--');

                      return (
                        <span>
                          {businessName}
                          <br />
                          {shopSign}
                        </span>
                      );
                    },
                    exportFormatter: ({ data }) =>
                      _.get(data, 'businessName', '--'),
                  },
                  {
                    key: 'agent',
                    useAlias: 'agent',
                    filterName: 'displayName',
                    name: this.context.intl.formatMessage(messages.agent),
                    searchable: false,
                    sortable: true,
                    formatter: ({ data }) =>
                      _.get(data, 'agent1.displayName', '--'),
                    exportFormatter: ({ data }) =>
                      _.get(data, 'agent1.displayName', '--'),
                  },
                  {
                    key: 'businessAddressTitle',
                    name: this.context.intl.formatMessage(messages.address),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'phoneNumber',
                    name: this.context.intl.formatMessage(messages.phone),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'lastAction',
                    name: this.context.intl.formatMessage(messages.lastAction),
                    searchable: true,
                    sortable: true,
                  },
                ]}
                defaultActions={{
                  visualize: PermissionManager.CanI('read', 'destination'),
                  modify: PermissionManager.CanI('edit', 'destination'),
                  delete: CanManage(this.props.user),
                  grouped: false,
                  moreActions: [],
                }}
                onResetFilters={this.handleResetFilter}
                disableInitialLoad={this.state.isUserAgent}
              />
            </ListWrapper>
          )}
        </Page>
      </>
    );
  }
}

Customer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  customer: makeSelectCustomer(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'customer', reducer });
const withSaga = injectSaga({ key: 'customer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Customer);

Customer.contextTypes = {
  intl: PropTypes.object.isRequired,
};
