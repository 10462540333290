import _ from 'lodash';
import FormManager from 'novigo-form-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Icon } from 'semantic-ui-react';
import Modal from '../../../Modal';
import appMessages from '../../../../containers/App/messages';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { addNotification } from '../../../../utils/notification';
import ManageForm from './baseForm';
import validation from './validation';
import messages from '../messages';
import DestinationMandatoryOrderFields from '../../DestinationMandatoryOrderFields';

class OrderManageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reloadIndex: 0,
      showCustomerDataModal: false,
      destinationId: '',
    };
  }

  /**
   * Transform data after fetch request.
   * @param {*} data
   * @returns
   */
  transformData = data => ({
    ...data,
    destination: _.get(data, '_embedded.destination.id', null) && {
      // eslint-disable-next-line no-underscore-dangle
      label: `${data._embedded.destination.code} ${
        // eslint-disable-next-line no-underscore-dangle
        data._embedded.destination.businessName
      }`,
      value: _.get(data, '_embedded.destination.id', null),
      delivery: _.get(data, '_embedded.destination.delivery', null),
    },
    orderType: {
      label: `${_.get(data, 'orderType.code')} -
      ${_.get(data, 'orderType.description')}`,
      value: _.get(data, 'orderType.id'),
    },
  });

  /**
   * Clean data before update request.
   * @param {*} data
   * @returns
   */
  onCleanData = data => ({
    state: _.get(data, 'state.value', _.get(data, 'state', null)),
    destination: _.get(data, 'destination.value', null),
    orderType: _.get(data, 'orderType.value', null),
    delivery: _.get(data, 'delivery', null),
    internalNote: _.get(data, 'internalNote', null),
    deliveryNote: _.get(data, 'deliveryNote', null),
    cig: _.get(data, 'cig', null),
    customerOrderNumber: _.get(data, 'customerOrderNumber', null),
    finalized: 1,
  });

  closeCustomerDataModal = () => {
    this.setState({ showCustomerDataModal: false });
  };

  reloadData = () => {
    this.setState(prevState => ({
      reloadIndex: prevState.reloadIndex + 1,
    }));
  };

  render() {
    const { showCustomerDataModal } = this.state;

    return (
      <>
        <div>
          <FormManager
            key={this.props.match.params.action} // necessary to reload the current view upon redirect
            router={this.props} // necessary to use the internal routing
            client={pars =>
              request(pars).then(res => {
                this.setState({
                  destinationId: _.get(
                    res.data,
                    '_embedded.destination.id',
                    null,
                  ),
                });
                return this.transformData(res.data);
              })
            }
            mask={fmProps => (
              <ManageForm
                {...this.props}
                {...fmProps}
                intl={this.context.intl}
                reloadIndex={this.state.reloadIndex}
              />
            )}
            afterUpdate={() => {
              this.props.history.goBack();
            }}
            validation={values => validation(values, this.context.intl)}
            onCleanData={data => {
              this.setState({
                destinationId: _.get(data, 'destination.value', null),
              });
              return this.onCleanData(data);
            }}
            entityName="order"
            basePath={API.BASE_URL}
            permissions={this.props.user}
            onError={data => {
              let message = _.get(
                data,
                'detail',
                this.props.intl.formatMessage(
                  appMessages.an_error_has_occurred,
                ),
              );
              const destinationErrors = _.get(
                data,
                'validation_messages.destination',
                null,
              );

              if (destinationErrors) {
                message = (
                  <>
                    <FormattedMessage {...messages.destination_errors} />
                    <ul>
                      {Object.entries(destinationErrors).map(([key, value]) => (
                        <li key={key}>{value}</li>
                      ))}
                    </ul>
                  </>
                );

                this.setState({
                  showCustomerDataModal: true,
                });
              }

              addNotification({
                title: this.props.intl.formatMessage(appMessages.error),
                message,
                isError: true,
              });
            }}
            onSuccess={() =>
              addNotification({
                title: this.props.intl.formatMessage(appMessages.success),
                message: this.props.intl.formatMessage(
                  appMessages.operation_performed_successfully,
                ),
                isError: false,
              })
            }
          />
          {showCustomerDataModal && (
            <Modal
              open={showCustomerDataModal}
              onClose={() => {
                this.closeCustomerDataModal();
              }}
              title={
                <h2>
                  <Icon name="exclamation" />{' '}
                  {this.props.intl.formatMessage(
                    messages.destination_edit_missing_fields,
                  )}
                </h2>
              }
              size="mini"
              style={{ width: 650 }}
              scrolling
            >
              <DestinationMandatoryOrderFields
                destinationId={this.state.destinationId}
                close={this.closeCustomerDataModal}
                intl={this.context.intl}
                update={this.reloadData}
              />
            </Modal>
          )}
        </div>
      </>
    );
  }
}

OrderManageForm.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(OrderManageForm);
