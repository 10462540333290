import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getBetween = (startDate, endDate) =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF_WORK_ORDER,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'between',
          field: 'startDate',
          from: startDate,
          to: endDate,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAll = ({ page = 1, pageSize = 5, order = [], filters = [] }) =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF_WORK_ORDER,
    method: 'GET',
    params: {
      page,
      pageSize,
      filter: filters.filter(f => f),
      'order-by': order.filter(o => o),
    },
    paramsSerializer: qs.stringify,
  });

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF_WORK_ORDER,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const patch = (id, content) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER}/${id}`,
    method: 'PATCH',
    data: content,
  });

const updateWorkorderPlan = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF_WORK_ORDER_PLAN_CUSTOM,
    method: 'POST',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (entityId, entity, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: entity,
          value: entityId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getById = (id) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER}`,
    method: 'GET',
    params: {
      page: 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'id',
          value: id,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getWorkorderFilter = (
  noteReason,
  destination,
  staff,
  state,
  sortField,
  sortDirection,
  page,
) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER}`,
    method: 'GET',
    params: {
      page,
      pageSize: 5,
      filter: [
        destination && {
          type: 'eq',
          field: 'destination',
          value: destination,
        },
        noteReason && {
          type: 'eq',
          field: 'noteReason',
          value: noteReason,
        },
        staff && {
          type: 'eq',
          field: 'staff',
          value: staff,
        },
        state && {
          type: 'eq',
          field: 'state',
          value: state,
        },
      ].filter(f => f),
      'order-by': [
        {
          type: 'field',
          field: sortField,
          direction: sortDirection,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const exportPdf = id =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF_WORKORDER_PDF,
    method: 'POST',
    responseType: 'blob',
    data: { staffWorkorderId: id },
  });

const sendPdf = id =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF_WORKORDER_PDF,
    method: 'POST',
    data: { staffWorkorderId: id, sendEmail: true },
  });

const StaffWorkorderService = {
  getAll,
  getBetween,
  getByOwner,
  create,
  update,
  patch,
  remove,
  exportPdf,
  sendPdf,
  getWorkorderFilter,
  updateWorkorderPlan,
  getById,
};

export default StaffWorkorderService;
