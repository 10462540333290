/*
 * Customer note Messages
 *
 * This contains all the text for the Customer note container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.DestinationMandatoryOrderFields';

export default defineMessages({
  destination_saved: {
    id: `${scope}.destination_saved`,
    defaultMessage: 'Destination successfully saved',
  },
});
