// import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER_CUSTOM}/get-product`,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER_ROW}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const updateMulti = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER_CUSTOM}/update-product`,
    method: 'POST',
    data: content,
  });

const updateRows = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER_CUSTOM}/update-rows`,
    method: 'POST',
    data: content,
  });

const remove = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.ORDER_ROW}`,
    method: 'DELETE',
    data: content,
  });

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.ORDER_ROW,
    method: 'GET',
    params: {
      page: 1,
      pageSize: 8,
      'order-by': [
        {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const OrderRowService = {
  getAll,
  create,
  update,
  remove,
  updateMulti,
  updateRows,
};

export default OrderRowService;
