/* eslint-disable indent */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ListManager from 'novigo-entity-grid';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';

import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch } from '../../../Layout';
import currencyFormatter from '../../../../utils/currencyFormatter';
import { InvoiceFilters } from '../../../../containers/Invoices/searchOld';
import { IsUser } from '../../../../containers/Can/utils';
import PermissionManager from '../../../Permission';
import messages from '../../../../containers/Invoices/messages';

const InvoiceTab = props => {
  const canReadInvoice =
    (_.get(props.values, 'agent1.userId', false) &&
      IsUser(props.values.agent1.userId)) ||
    PermissionManager.CanI('editInvoice', 'custom');

  const columns = () => [
    {
      key: 'documentNumber',
      name: props.intl.formatMessage(messages.invoice_number),
      searchable: true,
      sortable: true,
      width: '10%',
    },
    {
      key: 'referenceDate',
      name: props.intl.formatMessage(messages.referenceDate),
      searchable: true,
      sortable: true,
      width: '10%',
      formatter: ({ data }) => {
        if (data.referenceDate) {
          return moment(data.referenceDate.date).format('DD-MM-YYYY');
        }
        return '--';
      },
      exportFormatter: ({ data }) => {
        if (data.referenceDate) {
          return moment(data.referenceDate.date).format('DD-MM-YYYY');
        }
        return '--';
      },
    },
    {
      key: 'agent',
      name: props.intl.formatMessage(messages.agent),
      searchable: false,
      sortable: false,
      formatter: ({ data }) =>
        _.get(data, 'agents', [])
          .map(agent => agent && agent.displayName)
          .filter(agent => agent)
          .join(','),
      exportFormatter: ({ data }) =>
        _.get(data, 'agents', [])
          .map(agent => agent && agent.displayName)
          .filter(agent => agent)
          .join(','),
      width: '20%',
    },
    {
      key: 'typology',
      name: props.intl.formatMessage(messages.type),
      searchable: false,
      sortable: false,
      formatter: ({ data }) => _.get(data, 'typology.description', '--'),
      exportFormatter: ({ data }) => _.get(data, 'typology.description', '--'),
    },
    {
      key: 'totalAmount',
      name: props.intl.formatMessage(messages.totalAmount),
      searchable: false,
      sortable: false,
      formatter: ({ data }) => currencyFormatter.format(data.totalAmount || 0),
      exportFormatter: ({ data }) =>
        currencyFormatter.format(data.totalAmount || 0),
    },
  ];

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <ListManager
          entityName="invoice"
          basePath={API.BASE_URL}
          locale={_.get(props, 'user.defaultLanguage', 'it')}
          permissions={props.user}
          router={props}
          client={pars => request(pars).then(res => res.data)}
          canSearchCustom={InvoiceFilters(props.intl, false)}
          aliases={[
            {
              type: 'leftjoin',
              field: 'destination',
              alias: 'destination',
            },
            {
              type: 'leftjoin',
              field: 'agents',
              alias: 'agent',
            },
            {
              type: 'leftjoin',
              field: 'rows',
              alias: 'invoiceRow',
            },
            {
              type: 'leftjoin',
              field: 'product',
              alias: 'product',
              parentAlias: 'invoiceRow',
            },
            {
              type: 'leftjoin',
              field: 'typology',
              alias: 'typology',
            },
          ]}
          defaultOrder={[
            {
              type: 'field',
              field: 'referenceDate',
              direction: 'DESC',
            },
          ]}
          columns={columns()}
          canCreate={{
            active: false,
          }}
          canExport={{
            active: false,
          }}
          defaultActions={{
            visualize: false,
            modify: false,
            delete: false,
            grouped: false,
            moreActions: [
              canReadInvoice
                ? {
                    icon: 'eye',
                    key: 'view',
                    color: 'blue',
                    action: item => {
                      props.history.push(`/invoice/${item.id}/modify`);
                    },
                  }
                : null,
            ].filter(a => a),
          }}
          persistentFilter={[
            {
              type: 'eq',
              field: 'destination',
              value: props.values.id,
            },
            {
              type: 'eq',
              where: 'and',
              field: 'isActive',
              value: '1',
            },
          ].filter(o => o)}
        />
      </TableGlobalSearch>
    </ListWrapper>
  );
};

InvoiceTab.defaultProps = {
  values: {},
};

InvoiceTab.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(InvoiceTab);
