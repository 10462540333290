import request from '../request';
import { API } from '../../../global-constants';

const getAll = ({ route }) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PROXY,
    method: 'POST',
    data: {
      method: 'GET',
      route,
      microserviceName: 'jira',
      params: {},
      headers: {},
    },
  });

const createIssue = ({ route, payload }) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PROXY,
    method: 'POST',
    data: {
      method: 'POST',
      route,
      microserviceName: 'jira',
      params: {
        ...payload,
      },
      headers: {},
    },
  });

const uploadAttachment = data =>
  request({
    baseURL: API.BASE_URL,
    url: API.PROXY,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  });

const JiraService = {
  getAll,
  createIssue,
  uploadAttachment,
};

export default JiraService;
