/**
 *
 * Modal
 *
 */
import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const FullModal = ({
  actions,
  title,
  children,
  open,
  onClose,
  size,
  basic,
  trigger,
  hiddenHeader,
  style,
}) => (
  <Modal
    closeOnEscape={false}
    open={open}
    basic={basic}
    onClose={onClose}
    trigger={trigger}
    closeIcon
    size={size}
    style={style}
  >
    {!hiddenHeader && <Modal.Header>{title}</Modal.Header>}
    <Modal.Content>
      <Modal.Description>{children}</Modal.Description>
    </Modal.Content>
    {actions && (
      <Modal.Actions style={{ textAlign: 'left' }}>
        <Button type="button" icon labelPosition="left" onClick={onClose}>
          Cancel <Icon name="remove" />
        </Button>
      </Modal.Actions>
    )}
  </Modal>
);

FullModal.propTypes = {
  /** Modal content */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** Modal title */
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  /** Modal is opened */
  open: PropTypes.bool,
  /** Close function */
  onClose: PropTypes.func,
  /** Modal size */
  size: PropTypes.string,
  /** Action footer */
  actions: PropTypes.bool,
  /** Basic form */
  basic: PropTypes.bool,
  /** Trigger button */
  trigger: PropTypes.object,
  /** Hidden header */
  hiddenHeader: PropTypes.bool,
  style: PropTypes.object,
};

export default FullModal;
