import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Modal, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';
import messages from './messages';
import appMessages from '../../../containers/App/messages';
import UserForm from '../User';
import UsersService from '../../../shared/services/users';
import { addNotification } from '../../../utils/notification';

// eslint-disable-next-line react/prefer-stateless-function
export default class MyselfModal extends Component {
  static propTypes = {
    notification: PropTypes.func,
    currentUser: PropTypes.object,
    updateMyself: PropTypes.func,
    styles: PropTypes.object,
  };

  state = {
    modalOpened: false,
    logoImg: null,
  };

  patchUser = data => {
    const payload = {
      id: data.id,
      email: data.email,
      color1: data.color1,
      color2: data.color2,
      logo: data.logo,
      logoSmall: data.logoSmall,
      defaultLanguage:
        _.get(data, 'defaultLanguage.value') || data.defaultLanguage || '',
    };
    UsersService.patch(payload)
      .then(() => {
        this.props.updateMyself();
        this.setState({ modalOpened: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: this.context.intl.formatMessage(
            appMessages.operation_performed_successfully,
          ),
          isError: false,
        });
      })
      .catch(error =>
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      );
  };

  render() {
    const { modalOpened } = this.state;
    return (
      <FormattedMessage {...messages.editProfile}>
        {msg => (
          <Modal
            closeIcon
            open={modalOpened}
            onClose={() => this.setState({ modalOpened: false })}
            trigger={
              <FormattedMessage {...messages.myProfile}>
                {myProfile => (
                  <Dropdown.Item
                    onClick={() => this.setState({ modalOpened: true })}
                    key="profilo"
                    text={myProfile}
                    icon="user"
                  />
                )}
              </FormattedMessage>
            }
            header={msg}
            content={
              <UserForm
                isMyself
                patchUser={this.patchUser}
                uploadFile={this.uploadFile}
                initialValues={this.props.currentUser}
                logoImg={this.state.logoImg}
                styles={this.props.styles}
              />
            }
            size="tiny"
          />
        )}
      </FormattedMessage>
    );
  }
}

MyselfModal.contextTypes = {
  intl: PropTypes.object.isRequired,
};
