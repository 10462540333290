import qs from 'qs';
import _ from 'lodash';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.CONTRACT,
    method: 'GET',
  });

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.CONTRACT,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'number',
          where: 'or',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.contract', []).map(customer => ({
        value: customer.id,
        label: `${customer.number} - ${_.get(customer, 'note', '')}`,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.CONTRACT,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT}/${id}`,
    method: 'DELETE',
  });

const createCustomer = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.CONTRACT_CUSTOMER,
    method: 'POST',
    data: content,
  });

const updateCustomer = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT_CUSTOMER}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const removeCustomer = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT_CUSTOMER}/${id}`,
    method: 'DELETE',
  });

const createAgent = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.CONTRACT_AGENT,
    method: 'POST',
    data: content,
  });

const updateAgent = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT_AGENT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const removeAgent = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT_AGENT}/${id}`,
    method: 'DELETE',
  });

const createCommission = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.CONTRACT_COMMISSION,
    method: 'POST',
    data: content,
  });

const updateCommission = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT_COMMISSION}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const removeCommission = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT_COMMISSION}/${id}`,
    method: 'DELETE',
  });

const getCustomersByContract = (contractId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT_CUSTOMER}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'contract',
          value: contractId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getCommissionsByContract = (commissionType, contractId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT_COMMISSION}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'contract',
          value: contractId,
          where: 'and',
        },
        {
          type: 'eq',
          field: 'commissionType',
          value: commissionType,
          where: 'and',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAgentsByContract = (contractId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT_AGENT}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'contract',
          value: contractId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getStates = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.STATE_MACHINE,
    method: 'POST',
    data: {
      method: 'get-config',
      params: {
        module: 'contract',
      },
    },
  });
// param currentState
const getAvailableStates = () =>
  getStates().then(res => {
    const transitions = _.get(res, 'data.contract-state.transitions');
    // const availableTransitions = [];
    // if (currentState) {
    //   Object.keys(transitions).forEach(key => {
    //     if (transitions[key].from.includes(currentState)) {
    //       availableTransitions.push(key);
    //     }
    //   });
    // } else {
    //   Object.keys(transitions).forEach(key => {
    //     availableTransitions.push(key);
    //   });
    // }
    return Object.keys(transitions);
  });

const getByOwner = (entityId, entity, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CONTRACT}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: entity,
          value: entityId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const ContractService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
  getCustomersByContract,
  getAgentsByContract,
  getCommissionsByContract,
  removeCustomer,
  removeCommission,
  removeAgent,
  createCustomer,
  createAgent,
  createCommission,
  updateCustomer,
  updateAgent,
  updateCommission,
  getStates,
  getAvailableStates,
  getByOwner,
};

export default ContractService;
