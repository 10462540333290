import React from 'react';
import PropTypes from 'prop-types';
import SideBarRight from '../../../components/SideBarRight';

const SideBarOrder = props => {
  const { items, stylesSetting } = props;

  return (
    <SideBarRight
      collapsed={false}
      items={items}
      openMenu
      stylesSetting={stylesSetting}
    />
  );
};

SideBarOrder.propTypes = {
  items: PropTypes.object,
  stylesSetting: PropTypes.object,
};

export default SideBarOrder;
