/* eslint-disable indent */
import React, { useEffect, useRef, useState } from 'react';
import propTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import Table from 'semantic-table-grid';
import { Button, Icon, Grid } from 'semantic-ui-react';
import { intlShape } from 'react-intl';
import OfferLogsService from '../../../../../shared/services/offerlogs';
import OrderService from '../../../../../shared/services/order';
import { addNotification } from '../../../../../utils/notification';
import appMessages from '../../../../../containers/App/messages';
import { ModalFakeActions } from '../../../../Layout';
import Modal from '../../../../Modal';
import messages from '../../messages';

const paginationDefault = {
  page: 1,
  page_count: 1,
  page_size: 25,
  total_items: 0,
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const OfferStateHistoryModal = props => {
  const { open, onClose } = props;

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={<h2>{props.intl.formatMessage(messages.state_history)}</h2>}
      size="mini"
      style={{ width: 650 }}
      scrolling
    >
      <OfferStateTable {...props} />
    </Modal>
  );
};

const OfferStateTable = props => {
  const {
    values: { id },
    isOrder = false,
  } = props;

  const [isLoading, setLoading] = useState(false);
  const [historyStates, setHistoryStates] = useState([]);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const prevPagination = usePrevious(paginationData);

  const columns = [
    {
      key: 'loggedAt',
      name: props.intl.formatMessage(messages.date),
      formatter: ({ data }) =>
        _.get(data, 'loggedAt.date', '---')
          ? moment(data.loggedAt.date).format('DD/MM/YYYY')
          : '---',
    },
    {
      key: 'username',
      name: props.intl.formatMessage(messages.username),
    },
    {
      key: 'state',
      name: props.intl.formatMessage(messages.state),
      formatter: ({ data }) => _.get(data, 'data.state', '---'),
    },
  ];

  useEffect(() => {
    setLoading(true);
    loadData(id);
  }, []);

  useEffect(
    () => {
      if (
        typeof prevPagination !== 'undefined' &&
        typeof paginationData !== 'undefined' &&
        prevPagination.page !== paginationData.page
      ) {
        loadData(id);
      }
    },
    [paginationData.page],
  );

  const loadData = itemID => {
    const method = isOrder
      ? OrderService.getOrderLogs(itemID, paginationData.page || 1)
      : OfferLogsService.getAllByOfferId(itemID, {
          page: paginationData.page || 1,
          page_size: paginationData.page_size,
        });
    method
      .then(response => {
        setHistoryStates(
          _.get(
            response,
            `data._embedded.${isOrder ? 'order' : 'offer'}-logs`,
            [],
          ),
        );
        setPaginationData({
          page: _.get(response, 'data.page', 1),
          page_size: _.get(response, 'data.page_size'),
          page_count: _.get(response, 'data.page_count'),
          total_items: _.get(response, 'data.total_items'),
        });
        setLoading(false);
      })
      .catch(error => {
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: error.data.detail,
          isError: true,
        });
      });
  };

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column>
          <Table
            elements={historyStates}
            rowClassKey="role-row-class"
            columns={columns}
            canSort={{
              active: false,
            }}
            isLoading={isLoading}
            canPaginate={{
              active: true,
              pageCount: paginationData.page_count,
              pageSize: paginationData.page_size,
              totalItems: paginationData.total_items,
              page: paginationData.page || 1,
              onSelect: page => {
                setPaginationData({
                  ...paginationData,
                  page,
                });
              },
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <ModalFakeActions>
        <div style={{ display: 'inline-block', width: '50%' }}>
          <Button
            type="button"
            icon
            labelPosition="left"
            onClick={props.onClose}
          >
            <Icon name="remove" />
            {props.intl.formatMessage(appMessages.cancel)}
          </Button>
        </div>
      </ModalFakeActions>
    </Grid>
  );
};

OfferStateHistoryModal.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  isOrder: propTypes.bool,
};

OfferStateTable.propTypes = {
  onClose: propTypes.func.isRequired,
  values: propTypes.object.isRequired,
  isOrder: propTypes.bool,
  intl: intlShape.isRequired,
};

export default OfferStateHistoryModal;
