// import '@babel/polyfill';
import axios from 'axios';

const getClient = () => {
  const client = axios.create();

  const request = options =>
    client(options)
      .then(response => (options.raw ? response : response.data))
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Internal client Error', error.response || error.message);
        return Promise.reject(error.response || error.message);
      });

  return request;
};

export default getClient();
