/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { intlShape } from 'react-intl';
import SimpleFormikField from 'novigo-simple-formik-field';
import { Grid } from 'semantic-ui-react';

import FormikAsyncSelect from '../../FormikAsyncSelect';
import messages from './messages';
import ProductCategoryService from '../../../shared/services/productcategory';
import ProductDomainService from '../../../shared/services/productdomain';
import ProductSupplierService from '../../../shared/services/productsupplier';
import ProductCrmService from '../../../shared/services/productcrm';
import FormikThreeState from '../../FormikThreeState';
import { THREE_STATE } from '../../FormikThreeState/constant';

const activeMessages = {
  [THREE_STATE.INDETERMINATE]: 'all',
  [THREE_STATE.POSITIVE]: 'onlyActive',
  [THREE_STATE.NEGATIVE]: 'onlyNotActive',
};

const SharedSearchBar = ({ intl, ...formik }) => {
  const [category, setCategory] = useState(formik.formik.values.category);

  useEffect(() => {
    setCategory(formik.formik.values.category);
  }, [formik.formik.values.category]);

  return (
    <>
      <Grid.Column>
        <SimpleFormikField
          {...formik}
          name="productCode"
          label={intl.formatMessage(messages.productCode)}
        />
      </Grid.Column>
      <Grid.Column>
        <SimpleFormikField
          {...formik}
          name="title"
          label={intl.formatMessage(messages.description)}
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          {...formik}
          name="crmCategory"
          label={intl.formatMessage(messages.crmCategory)}
          loadOptions={(searchText, callback) =>
            ProductCrmService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          {...formik}
          name="category"
          label={intl.formatMessage(messages.category)}
          loadOptions={(searchText, callback) =>
            ProductCategoryService.getAsyncCategoryOptions(searchText, callback)
          }
        />
      </Grid.Column>
      {/* NOTE: Kinda convoluted but works! xoxo */}
      {category === formik.formik.values.category && (
        <Grid.Column>
          <FormikAsyncSelect
            {...formik}
            name="subcategory"
            readOnly={formik.formik.values.category === null}
            label={intl.formatMessage(messages.subcategory)}
            loadOptions={(searchText, callback) =>
              ProductCategoryService.getAsyncSubByCategoryOptions(
                searchText,
                callback,
                category,
              )
            }
          />
        </Grid.Column>
      )}
      <Grid.Column>
        <FormikAsyncSelect
          {...formik}
          name="domain"
          label={intl.formatMessage(messages.domain)}
          loadOptions={(searchText, callback) =>
            ProductDomainService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="productSupplier"
          label={intl.formatMessage(messages.supplier)}
          loadOptions={(searchText, callback) =>
            ProductSupplierService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column width={2}>
        <FormikThreeState
          name="isActive"
          label={intl.formatMessage(messages.active)}
          message={({ value }) =>
            intl.formatMessage(
              messages[
                activeMessages[
                  Object.values(THREE_STATE).includes(value) ? value : null
                ]
              ],
            )
          }
        />
      </Grid.Column>
    </>
  )
};

SharedSearchBar.contextTypes = {
  intl: intlShape,
};

export { SharedSearchBar };
