import React from 'react';
import SimpleFormikField from 'novigo-simple-formik-field';
import _ from 'lodash';
import { Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function FormikToggle(props) {
  return (
    <SimpleFormikField name={props.name} label={props.label} {...props}>
      {({
        // field, // { name, value, onChange, onBlur }
        form: { setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <Checkbox
          toggle
          value={
            props.initialValue !== undefined
              ? props.initialValue
              : _.get(values, props.name, null)
          }
          checked={
            props.initialValue !== undefined
              ? props.initialValue
              : _.get(values, props.name, null)
          }
          onChange={
            props.onChange ||
            ((value, data) => setFieldValue(props.name, data.checked))
          }
          disabled={props.disabled}
          readOnly={props.readOnly}
        />
      )}
    </SimpleFormikField>
  );
}

FormikToggle.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  readOnly: PropTypes.bool,
};

export default FormikToggle;
