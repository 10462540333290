import { Field } from 'formik';
import _ from 'lodash';
import SimpleFormikField from 'novigo-simple-formik-field';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Form, Grid } from 'semantic-ui-react';
// import appMessages from '../../../containers/App/messages';
import orderMessages from '../../../containers/Order/messages';
// Services
import BankService from '../../../shared/services/bank';
import CustomerService from '../../../shared/services/customer';
import CustomerDestinationService from '../../../shared/services/customerdestination';
import DestinationBankService from '../../../shared/services/destinationbank';
import DestinationPaymentTermService from '../../../shared/services/destinationpaymentterm';
import OrderService from '../../../shared/services/order';
import OrderTypologyService from '../../../shared/services/ordertypology';
import ProfileService from '../../../shared/services/profile';
// import WatcherService from '../../../shared/services/watcher';
import { addNotification } from '../../../utils/notification';
import FormActionsBar from '../../FormActionsBar';
import FormActionsModal from '../../FormActionsModal';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import messages from './messages';
import validation from './validation';

const OrderForm = props => {
  const {
    handleSubmit,
    children,
    setFieldValue,
    setValues,
    modalSubmit,
    values,
    errors,
    ...rest
  } = props;
  const [isDuplicating, setIsDuplicating] = useState(false);
  const { isNew } = values;

  const setValuesForDestination = vals => {
    const destinationProfiles = vals[0] ? vals[0] : vals;
    setValues({
      ...values,
      destination: {
        label: destinationProfiles.rawLabel,
        value: destinationProfiles.value,
      },
      commercialDirector: destinationProfiles.commercialDirector,
      areaManager: destinationProfiles.areaManager,
      agent1: destinationProfiles.agent1,
      agent2: destinationProfiles.agent2,
      subagent: destinationProfiles.subagent,
      buyingGroup: destinationProfiles.buyingGroup,
      signaller: destinationProfiles.signaller,
    });
  };

  const duplicateOrder = () => {
    setIsDuplicating(true);
    const data = props.onCleanData(values, props);
    OrderService.create(data)
      .then(res => {
        setIsDuplicating(false);
        props.history.goBack();
        props.reloadData();
        addNotification({
          title: null,
          message: res.data.detail,
          isError: false,
        });
      })
      .catch(err => {
        setIsDuplicating(false);
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Field
          name="transportedBy"
          render={({ field }) => (
            <Form.Group widths="equal" style={{ paddingLeft: 5 }}>
              <Button.Group>
                <Button
                  type="button"
                  disabled={props.readOnly}
                  primary={field.value === 'Franco Partenza'}
                  checked={field.value === 'Franco Partenza'}
                  onClick={() =>
                    setFieldValue('transportedBy', 'Franco Partenza')
                  }
                >
                  <FormattedMessage {...messages.vettore_dtm} />
                </Button>
                <Button
                  type="button"
                  disabled={props.readOnly}
                  primary={field.value === 'Franco Cliente'}
                  checked={field.value === 'Franco Cliente'}
                  onClick={() =>
                    setFieldValue('transportedBy', 'Franco Cliente')
                  }
                >
                  <FormattedMessage {...messages.vettore__cliente} />
                </Button>
              </Button.Group>
            </Form.Group>
          )}
        />
      </div>
      <Grid>
        <Grid.Row columns={3} padded>
          <Grid.Column>
            <SimpleFormikField
              {...rest}
              name="number"
              readOnly
              label={props.intl.formatMessage(orderMessages.orderNumber)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikDate
              {...rest}
              name="referenceDate"
              required
              label={props.intl.formatMessage(orderMessages.date)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="orderType"
              required
              label={props.intl.formatMessage(orderMessages.orderType)}
              loadOptions={(searchText, callback) =>
                OrderTypologyService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} padded>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="customer"
              required
              label={props.intl.formatMessage(orderMessages.customer)}
              loadOptions={(searchText, callback) =>
                CustomerService.getOptions(searchText, callback)
              }
              onChange={data => {
                setValues({
                  ...values,
                  customer: data,
                  destination: null,
                  commercialDirector: null,
                  areaManager: null,
                  agent1: null,
                  agent2: null,
                  subagent: null,
                  buyingGroup: null,
                  signaller: null,
                });
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              key={`customer-${_.get(values, 'customer.value', null)}`}
              name="destination"
              required
              label={props.intl.formatMessage(messages.destination)}
              value={values.destination}
              loadOptions={(searchText, callback) =>
                CustomerDestinationService.getOwnerOptionsExtendedData(
                  searchText,
                  _.get(values, 'customer.value', null),
                  callback,
                  isNew && values.destination && setValuesForDestination,
                )
              }
              asyncSelectProps={{
                isDisabled: !_.get(values, 'customer.value', null),
              }}
              onChange={res => {
                setValuesForDestination(res);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="destinationPaymentTerm"
              label={props.intl.formatMessage(messages.payment_condition)}
              loadOptions={(searchText, callback) =>
                DestinationPaymentTermService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} padded>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="commercialDirector"
              label={props.intl.formatMessage(messages.commercial_director)}
              loadOptions={(searchText, callback) =>
                ProfileService.getCommercialDirectorOptions(
                  searchText,
                  callback,
                )
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="areaManager"
              label={props.intl.formatMessage(messages.area_manager)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAreaManagerOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="buyingGroup"
              label={props.intl.formatMessage(messages.buyingGroup)}
              loadOptions={(searchText, callback) =>
                CustomerService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} padded>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="agent1"
              label={props.intl.formatMessage(messages.agent_1)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAgentOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="agent2"
              label={props.intl.formatMessage(messages.agent_2)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAgentOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="subagent"
              label={props.intl.formatMessage(messages.sub_agent)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAgentOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} padded>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="signaller"
              label={props.intl.formatMessage(messages.signaller)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAgentOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="deterchimicaBank"
              label={props.intl.formatMessage(messages.dtm_bank)}
              loadOptions={(searchText, callback) =>
                BankService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="destinationBank"
              label={props.intl.formatMessage(messages.customer_bank)}
              loadOptions={(searchText, callback) =>
                DestinationBankService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3} padded>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="state"
              required
              label={props.intl.formatMessage(messages.state)}
              loadOptions={(searchText, callback) =>
                OrderService.defaultStates(callback)
              }
              asyncSelectProps={{
                isDisabled: !isNew,
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={4} padded>
          <Grid.Column>
            <FormikDate
              {...props}
              name="deliveryRequestDate"
              label={props.intl.formatMessage(messages.requested_delivery)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikDate
              {...props}
              name="deliveryPromiseDate"
              label={props.intl.formatMessage(messages.promissed_delivery)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikDate
              {...props}
              name="deliveryPlanedDate"
              label={props.intl.formatMessage(messages.scheduled_delivery)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikDate
              {...props}
              name="shippedStoreDate"
              label={props.intl.formatMessage(messages.shipping_warehouse)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row />
      </Grid>
      {modalSubmit ? (
        <FormActionsModal {...props} onSaveClick={props.submit} />
      ) : (
        <FormActionsBar
          {...props}
          isDuplicating={isDuplicating}
          onDuplicate={duplicateOrder}
          showDuplicateButton={props.isDuplicate}
        />
      )}
    </Form>
  );
};

OrderForm.propsType = {
  intl: intlShape.isRequired,
};

export default injectIntl(OrderForm);
export { validation };
