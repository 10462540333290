/* eslint-disable jsx-a11y/label-has-for */
/**
 *
 * Dropdown
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { injectIntl } from 'react-intl';
import { IntlShape, TranslationsShape } from './Shapes';

import messages from './messages/CustomDropdownMessages';
/* eslint-disable react/prefer-stateless-function */

/**
 * The custom dropdown to change value of records per page
 */
class CustomDropdown extends React.PureComponent {
  /**
   * Return an array defaul options of records per page dropdown
   */
  optionsRecordsPerpageOptions = () => [
    { key: 10, value: 10, text: 10 },
    { key: 25, value: 25, text: 25 },
    { key: 50, value: 50, text: 50 },
    { key: 100, value: 100, text: 100 },
  ];

  render() {
    const {
      intl,
      active = false,
      onDropdownChange = null,
      value = 10,
      translations = {},
    } = this.props;

    const { dropdown = {} } = translations;

    const { label = intl.formatMessage(messages.labelDropdown) } = dropdown;

    if (!active) {
      return null;
    }

    return (
      <>
        <label htmlFor={label} style={{ paddingRight: 10 }}>
          {label}
        </label>
        <Dropdown
          selection
          search
          compact
          onFocus={e => e.target.setAttribute('autocomplete', 'off')}
          onChange={(_value, data) => onDropdownChange(data.value)}
          options={this.optionsRecordsPerpageOptions()}
          value={value}
        />
      </>
    );
  }
}

CustomDropdown.defaultProps = {
  active: false,
  onDropdownChange: null,
  labelDropdown: '',
  value: 10,
  intl: {},
};

CustomDropdown.propTypes = {
  /**
   * Enabled dropdown.
   */
  active: PropTypes.bool,
  /**
   * Function change dropdown value
   * */
  onDropdownChange: PropTypes.func,
  /**
   * String label of dropdown - default is 'Records per page'
   * is used only for a label purpose.
   */
  // eslint-disable-next-line react/no-unused-prop-types
  labelDropdown: PropTypes.string,
  /**
   * Value decided amount of items visible per page
   */
  value: PropTypes.number,

  intl: IntlShape,
  // eslint-disable-next-line react/require-default-props
  translations: TranslationsShape,
};

export default injectIntl(CustomDropdown);
