import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the agent state domain
 */

const selectAgentDomain = state => state.get('agent', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Agent
 */

const makeSelectAgent = () =>
  createSelector(selectAgentDomain, substate => substate.toJS());

export default makeSelectAgent;
export { selectAgentDomain };
