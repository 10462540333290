/* eslint-disable prettier/prettier */
/**
 *
 * ProductPriceMaster
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import SimpleFormikField from 'novigo-simple-formik-field';
import _ from 'lodash';
import ListManager from 'novigo-entity-grid';
import { Grid, Placeholder } from 'semantic-ui-react';
import { FormBackBtn } from '../../components/Buttons';
import { PaddedColumn, ListWrapper } from '../../components/Layout';
import FormikAsyncSelect from '../../components/FormikAsyncSelect';
import FormikDate from '../../components/FormikDate';
import PermissionManager from '../../components/Permission';
import makeSelectProductPriceMaster from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import priceListMessages from '../PriceListMaster/messages';
import ProductPriceFormModal from '../../components/FormManagerModal';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import { currencyFormatter } from '../../utils/currencyFormatter';
import imagePlaceholder from '../../images/image-placeholder.png';

// Specific import
import { API } from '../../global-constants';
import request from '../../shared/services/request';
import appMessages from '../App/messages';
import ManageFormHeader from './manageFormHeader';
import ProductPriceMasterForm, {
  validation,
} from '../../components/Forms/ProductPriceMaster';

// Services
import priceListMasterService from '../../shared/services/pricelistmaster';
import productService from '../../shared/services/product';
import categoryService from '../../shared/services/productcategory';
import supplierService from '../../shared/services/supplier';
import ProductAttachment from '../../shared/services/productattachment';
import { addNotification } from '../../utils/notification';
import AttachmentService from '../../shared/services/attachments';
import ProductCrmService from '../../shared/services/productcrm';

export class ProductPriceMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      reloadIndex: 0,
      priceList: null,
      action: null,
      rowId: null,
      data: null,
      attachments: [],
      isReadOnly: false,
      loadingAttachments: true,
    };
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'productPriceMaster',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  componentDidMount() {
    this.checkPermission();
    priceListMasterService
      .read(this.props.match.params.id)
      .then(res => this.setState({ priceList: _.get(res, 'data') }));
    const isReadOnly = this.props.match.params.action === 'view';
    this.setState({
      isReadOnly,
    });
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  reloadData = () => {
    this.closeModal();
    this.setState(prevState => ({
      reloadIndex: prevState.reloadIndex + 1,
    }));
  };

  cleanData = data => {
    const cleanData = _.cloneDeep(data);

    _.unset(cleanData, 'id');
    _.unset(cleanData, '_links');
    _.unset(cleanData, '_embedded');
    _.unset(cleanData, 'createdAt');
    _.unset(cleanData, 'updatedAt');
    _.unset(cleanData, 'deletedAt');
    _.unset(cleanData, 'createdBy');
    _.unset(cleanData, 'rowNumber');
    _.unset(cleanData, 'readAt');
    _.unset(cleanData, 'writeAt');

    cleanData.startDate =
      _.get(cleanData, 'startDate') &&
      moment(cleanData.startDate).format('YYYY-MM-DD');

    cleanData.endDate =
      _.get(cleanData, 'endDate') &&
      moment(cleanData.endDate).format('YYYY-MM-DD');

    cleanData.supplier = _.get(cleanData, 'supplier.value');
    cleanData.product = _.get(cleanData, 'product.value');
    cleanData.priceList = _.get(this.props, 'match.params.id');

    return cleanData;
  };

  transformData = data => ({
    ...data,
    startDate: data.startDate ? moment(data.startDate) : null,
    endDate: data.endDate ? moment(data.endDate) : null,
    productSupplier: {
      value: _.get(data, 'product.productSupplier.id'),
      label: _.get(data, 'product.productSupplier.description'),
    },
    product: _.get(data, 'product') && {
      availableQuantity: _.get(data, 'product.productStock.availableQuantity', '--'),
      value: _.get(data, 'product.id'),
      label: `${_.get(data, 'product.productCode')} - ${_.get(
        data,
        'product.title',
      )}`,
    },
  });

  afterLoad = res => {
    const priceList = _.get(res, '_embedded.product-price-master', []);
    const ids = priceList.map(el => _.get(el, 'product.id', null));
    if (ids && ids.length > 0) {
      this.setState({ loadingAttachments: true });
      ProductAttachment.getAttachmentsForId(ids)
        .then(({ data }) => {
          const productData = _.get(data, '_embedded.product-attachment', [])
            .map(el => {
              const item = _.get(el, '_embedded');
              if (item.attachmentTypeId.description === 'Immagine App') {
                return {
                  productId: item.product.id,
                  attachmentId: item.attachmentId.id,
                };
              }
              return null;
            })
            .filter(o => o);

          const attachmentIds = productData.map(el => el.attachmentId);

          AttachmentService.downloadMultiple(attachmentIds).then(att => {
            const attachmentsArray = _.toArray(att.data).map(el => ({
              id: el.id,
              image: `data:image/jpeg;base64,${el.image}`,
            }));

            const attData = [];
            productData.forEach(prod => {
              attachmentsArray.forEach(attachment => {
                if (attachment.id === prod.attachmentId) {
                  const item = {
                    ...attachment,
                    productId: prod.productId,
                  };
                  attData.push(item);
                }
              });
            });

            this.setState({
              attachments: attData,
              loadingAttachments: false,
            });
          }).catch(() => {
            this.setState({ loadingAttachments: false });
          });
        })
        .catch(err => {
          this.setState({ loadingAttachments: false });
          addNotification({
            title: this.context.intl.formatMessage(appMessages.error),
            message: _.get(
              err,
              'detail',
              this.context.intl.formatMessage(appMessages.an_error_has_occurred),
            ),
            isError: true,
          });
        });
    }
  };

  moreActions = () => {
    const { isReadOnly } = this.state;
    const actions = [];

    if (PermissionManager.CanI('read', 'productPriceMaster')) {
      actions.push({
        icon: 'eye',
        label: 'view element',
        key: 'viewRelatedData',
        action: data => {
          this.setState({ action: 'view', rowId: data.id });
          this.openModal();
        },
      });
    }

    if (!isReadOnly && PermissionManager.CanI('edit', 'productPriceMaster')) {
      actions.push({
        icon: 'edit',
        label: 'edit element',
        key: 'editRelatedData',
        action: data => {
          this.setState({ action: 'modify', rowId: data.id });
          this.openModal();
        },
      });
    }

    if (!isReadOnly && PermissionManager.CanI('remove', 'productPriceMaster')) {
      actions.push({
        icon: 'trash',
        label: 'delete element',
        key: 'deleteRelatedData',
        color: 'red',
        action: data => {
          this.setState({ action: 'delete', rowId: data.id });
          this.openModal();
        },
      });
    }

    return actions;
  };

  render() {
    const { priceList, action, rowId, isReadOnly } = this.state;
    return (
      <>
        <ManageFormHeader
          data={[
            {
              label: this.context.intl.formatMessage(priceListMessages.code),
              value: _.get(priceList, 'code', ''),
            },
            {
              label: this.context.intl.formatMessage(
                priceListMessages.startDate,
              ),
              value: _.get(priceList, 'startDate', '')
                ? moment(priceList.startDate).format('DD-MM-YYYY')
                : null,
            },
            {
              label: this.context.intl.formatMessage(
                priceListMessages.description,
              ),
              value: _.get(priceList, 'description', ''),
            },
            {
              label: this.context.intl.formatMessage(priceListMessages.endDate),
              value: _.get(priceList, 'endDate', '')
                ? moment(priceList.endDate).format('DD-MM-YYYY')
                : null,
            },
          ]}
        />
        <ProductPriceFormModal
          {...this.props}
          modalSize="small"
          open={this.state.showModal}
          onClose={() => this.closeModal()}
          reloadData={this.reloadData}
          modalProps={{ style: { width: 600 } }}
          modalTitle={actionToBreadcrumb({
            action,
            intl: this.context.intl,
          })}
          formManagerProps={{
            key: this.props.match.params.action,
            router: { match: { params: { id: rowId } } },
            isNew: action === 'new',
            entityName: 'product-price-master',
            permissions: this.props.user,
            onCleanData: this.cleanData,
            afterCreate: () => this.reloadData(),
            client: pars =>
              request(pars).then(res => this.transformData(res.data)),
            validation: values => validation(values, this.context.intl),
            mask: fmProps => (
              <ProductPriceMasterForm
                {...fmProps}
                data={this.state.data}
                readOnly={action === 'view'}
                editRoute={action === 'view'}
                showDeleteButton={action === 'delete'}
                history={false}
              />
            ),
          }}
        />
        <ListWrapper>
          <ListManager
            entityName="product-price-master"
            sessionKey={
              _.has(this.props, 'match.params.id')
                ? `product-price-master-${this.props.match.params.id} `
                : 'product-price-master'
            }
            afterLoad={this.afterLoad}
            basePath={API.BASE_URL}
            permissions={this.props.user}
            client={pars => request(pars).then(res => res.data)}
            defaultOrder={[
              {
                type: 'field',
                field: 'startDate',
                direction: 'ASC',
              },
            ]}
            reloadIndex={this.state.reloadIndex}
            canExport={{
              exportLabelPrefix: this.context.intl.formatMessage(
                appMessages.export_to,
              ),
            }}
            canCreate={{
              active: !isReadOnly,
              action: () => {
                this.openModal();
                this.setState({
                  action: 'new',
                  data: {
                    startDate: _.get(priceList, 'startDate', '')
                      ? moment(priceList.startDate)
                      : null,
                    endDate: _.get(priceList, 'endDate', '')
                      ? moment(priceList.endDate)
                      : null,
                  },
                });
              },
            }}
            persistentFilter={[
              {
                type: 'eq',
                where: 'and',
                field: 'priceList',
                value: this.props.match.params.id,
              },
            ]}
            columns={[
              {
                key: 'coverImage',
                width: 150,
                name: this.context.intl.formatMessage(messages.coverImage),
                searchable: false,
                sortable: false,
                formatter: ({ data }) => {
                  const { attachments, loadingAttachments } = this.state;
                  const productId = _.get(data, 'product.id', '');

                  if (loadingAttachments) {
                    return (
                      <Placeholder>
                        <Placeholder.Image square />
                      </Placeholder>
                    );
                  }

                  const currentProductImage =
                    attachments &&
                    attachments.find(el => el.productId === productId);

                  if (!_.isEmpty(currentProductImage)) {
                    return (
                      <div
                        style={{
                          width: '100%',
                          height: 120,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginRight: 0,
                        }}
                      >
                        <img
                          src={currentProductImage.image}
                          style={{
                            objectFit: 'contain',
                            height: '100%',
                            width: '100%',
                            marginRight: 0,
                          }}
                          alt=""
                        />
                      </div>
                    );
                  }
                  return (
                    <img
                      src={imagePlaceholder}
                      style={{
                        objectFit: 'contain',
                        height: 120,
                        width: '100%',
                        marginRight: 0,
                      }}
                      alt=""
                    />
                  );
                },
              },
              {
                key: 'productCode',
                name: this.context.intl.formatMessage(messages.product_code),
                useAlias: 'product',
                searchable: true,
                sortable: true,
                width: '0px',
                maxWidth: '0px',
                formatter: data => (
                  <Link
                    style={{ textDecoration: 'underline' }}
                    to={`/product/${_.get(
                      data,
                      'data.product.id',
                      '',
                    )}/view`}
                  >
                    {_.get(data, 'data.product.productCode', '')}
                  </Link>
                ),
              },
              {
                key: 'title',
                name: this.context.intl.formatMessage(messages.product),
                useAlias: 'product',
                searchable: true,
                sortable: true,
                formatter: data =>
                  _.get(data, 'data.product.title', ''),
              },
              {
                key: 'productSupplier',
                filterName: 'description',
                useAlias: 'productSupplier',
                name: this.context.intl.formatMessage(messages.supplier),
                searchable: true,
                sortable: true,
                formatter: data =>
                  _.get(data, 'data.product.productSupplier.description', '--'),
                exportFormatter: data =>
                  _.get(data, 'data.product.productSupplier.description', '--'),
              },
              {
                key: 'startDate',
                name: this.context.intl.formatMessage(messages.startDate),
                searchable: true,
                sortable: true,
                formatter: ({ data }) =>
                  data.startDate && moment(data.startDate).format('DD/MM/YYYY'),
              },
              {
                key: 'endDate',
                name: this.context.intl.formatMessage(messages.endDate),
                searchable: true,
                sortable: true,
                formatter: ({ data }) =>
                  data.endDate && moment(data.endDate).format('DD/MM/YYYY'),
              },
              {
                key: 'grossPrice',
                name: this.context.intl.formatMessage(messages.grossPrice),
                searchable: true,
                sortable: true,
                formatter: ({ data }) =>
                  data.grossPrice
                    ? currencyFormatter.format(_.get(data, 'grossPrice', ''))
                    : '-',
              },
              {
                key: 'productStock.availableQuantity',
                name: this.context.intl.formatMessage(messages.product_stock),
                searchable: false,
                sortable: false,
                useAlias: 'product',
                formatter: data =>
                  _.get(
                    data,
                    'data.product.productStock.availableQuantity',
                    '-',
                  ),
              },
              {
                key: 'maxDiscount',
                name: this.context.intl.formatMessage(messages.maxDiscount),
                searchable: true,
                sortable: true,
                useAlias: 'product',
                formatter: data =>
                  _.get(data, 'data.product.maxDiscount', null)
                    ? `${_.get(data, 'data.product.maxDiscount', null)}%`
                    : '--',
              },
            ]}
            aliases={[
              {
                type: 'leftjoin',
                field: 'product',
                alias: 'product',
              },
              {
                type: 'leftjoin',
                field: 'priceList',
                alias: 'priceList',
              },
              {
                type: 'leftjoin',
                field: 'productSupplier',
                alias: 'productSupplier',
                parentAlias: 'product',
              },
            ]}
            canSearchCustom={{
              active: true,
              title: this.context.intl.formatMessage(appMessages.search),
              buttonLabel: this.context.intl.formatMessage(appMessages.search),
              formStructure: (
                <Grid>
                  <Grid.Row columns={4}>
                    <PaddedColumn>
                      <FormikAsyncSelect
                        name="product"
                        label={this.context.intl.formatMessage(
                          messages.product,
                        )}
                        loadOptions={(searchText, callback) =>
                          productService.getOptions(searchText, callback)
                        }
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <FormikAsyncSelect
                        name="priceList"
                        label={this.context.intl.formatMessage(
                          messages.priceList,
                        )}
                        loadOptions={(searchText, callback) =>
                          priceListMasterService.getOptions(
                            searchText,
                            callback,
                          )
                        }
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <FormikAsyncSelect
                        name="category"
                        label={this.context.intl.formatMessage(
                          messages.category,
                        )}
                        loadOptions={(searchText, callback) =>
                          categoryService.getAsyncCategoryOptions(
                            searchText,
                            callback,
                          )
                        }
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <FormikAsyncSelect
                        name="subcategory"
                        entityName="product-sub-category"
                        label={this.context.intl.formatMessage(
                          messages.subcategory,
                        )}
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <SimpleFormikField
                        name="priceFrom"
                        label={this.context.intl.formatMessage(
                          messages.priceFrom,
                        )}
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <SimpleFormikField
                        name="priceTo"
                        label={this.context.intl.formatMessage(
                          messages.priceTo,
                        )}
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <FormikDate
                        name="startDate"
                        label={this.context.intl.formatMessage(
                          messages.startDate,
                        )}
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <FormikDate
                        name="endDate"
                        label={this.context.intl.formatMessage(
                          messages.endDate,
                        )}
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <FormikAsyncSelect
                        name="productSupplier"
                        label={this.context.intl.formatMessage(
                          messages.supplier,
                        )}
                        loadOptions={(searchText, callback) =>
                          supplierService.getOptions(searchText, callback)
                        }
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <SimpleFormikField
                        name="buyPriceFrom"
                        label={this.context.intl.formatMessage(
                          messages.buyPriceFrom,
                        )}
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <SimpleFormikField
                        name="buyPriceTo"
                        label={this.context.intl.formatMessage(
                          messages.buyPriceTo,
                        )}
                      />
                    </PaddedColumn>
                    <PaddedColumn>
                      <FormikAsyncSelect
                        name="crmCategory"
                        label={this.context.intl.formatMessage(
                          messages.crmCategory,
                        )}
                        loadOptions={(searchText, callback) =>
                          ProductCrmService.getOptions(searchText, callback)
                        }
                      />
                    </PaddedColumn>
                  </Grid.Row>
                </Grid>
              ),
              onApplyFilter: (
                values,
                formikActionsBag,
                applyFilterFunction,
              ) => {
                const filters = [];
                const filterLabels = [];

                if (values.product) {
                  filterLabels.push({
                    key: 'product',
                    name: this.context.intl.formatMessage(messages.product),
                    value: values.product.label,
                    filterField: 'product',
                  });
                  filters.push({
                    type: 'eq',
                    where: 'and',
                    alias: 'product',
                    field: 'id',
                    value: `${values.product.value}`,
                  });
                }
                if (values.priceList) {
                  filterLabels.push({
                    key: 'priceList',
                    name: this.context.intl.formatMessage(messages.priceList),
                    value: values.priceList.label,
                    filterField: 'priceList',
                  });
                  filters.push({
                    type: 'eq',
                    where: 'and',
                    alias: 'priceList',
                    field: 'id',
                    value: `${values.priceList.value}`,
                  });
                }
                if (values.category) {
                  filterLabels.push({
                    key: 'category',
                    name: this.context.intl.formatMessage(messages.category),
                    value: values.category.label,
                    filterField: 'category',
                  });
                  filters.push({
                    type: 'eq',
                    where: 'and',
                    alias: 'product',
                    field: 'category',
                    value: `${values.category.value}`,
                  });
                }
                if (values.subcategory) {
                  filterLabels.push({
                    key: 'subcategory',
                    name: this.context.intl.formatMessage(messages.subcategory),
                    value: values.subcategory.label,
                    filterField: 'subcategory',
                  });
                  filters.push({
                    type: 'eq',
                    where: 'and',
                    alias: 'product',
                    field: 'subcategory',
                    value: `${values.subcategory.value}`,
                  });
                }
                if (values.priceFrom) {
                  filterLabels.push({
                    key: 'priceFrom',
                    name: this.context.intl.formatMessage(messages.priceFrom),
                    value: values.priceFrom,
                    filterField: 'grossPrice',
                  });
                  filters.push({
                    type: 'gte',
                    where: 'and',
                    field: 'grossPrice',
                    value: `${values.priceFrom}`,
                  });
                }
                if (values.priceTo) {
                  filterLabels.push({
                    key: 'priceTo',
                    name: this.context.intl.formatMessage(messages.priceTo),
                    value: values.priceTo,
                    filterField: 'grossPrice',
                  });
                  filters.push({
                    type: 'lte',
                    where: 'and',
                    field: 'grossPrice',
                    value: `${values.priceTo}`,
                  });
                }
                if (values.startDate) {
                  filterLabels.push({
                    key: 'startDate',
                    name: this.context.intl.formatMessage(messages.startDate),
                    value: moment(values.startDate).format('YYYY-MM-DD'),
                    filterField: 'startDate',
                  });
                  filters.push({
                    type: 'gte',
                    where: 'and',
                    field: 'startDate',
                    value: `${moment(values.startDate).format('YYYY-MM-DD')}`,
                  });
                }
                if (values.endDate) {
                  filterLabels.push({
                    key: 'endDate',
                    name: this.context.intl.formatMessage(messages.endDate),
                    value: moment(values.endDate).format('YYYY-MM-DD'),
                    filterField: 'endDate',
                  });
                  filters.push({
                    type: 'lte',
                    where: 'and',
                    field: 'endDate',
                    value: `${moment(values.endDate).format('YYYY-MM-DD')}`,
                  });
                }
                if (values.productSupplier) {
                  filterLabels.push({
                    key: 'productSupplier',
                    name: this.context.intl.formatMessage(messages.supplier),
                    value: values.supplier.label,
                    filterField: 'productSupplier',
                  });
                  filters.push({
                    type: 'eq',
                    where: 'and',
                    alias: 'productSupplier',
                    field: 'code',
                    value: `${values.supplier.value}`,
                  });
                }
                if (values.buyPriceFrom) {
                  filterLabels.push({
                    key: 'buyPriceFrom',
                    name: this.context.intl.formatMessage(
                      messages.buyPriceFrom,
                    ),
                    value: values.buyPriceFrom,
                    filterField: 'buyPrice',
                  });
                  filters.push({
                    type: 'gte',
                    where: 'and',
                    field: 'buyPrice',
                    value: `${values.buyPriceFrom}`,
                  });
                }
                if (values.buyPriceTo) {
                  filterLabels.push({
                    key: 'buyPriceTo',
                    name: this.context.intl.formatMessage(messages.buyPriceTo),
                    value: values.buyPriceTo,
                    filterField: 'buyPrice',
                  });
                  filters.push({
                    type: 'lte',
                    where: 'and',
                    field: 'buyPrice',
                    value: `${values.buyPriceTo}`,
                  });
                }
                if (values.crmCategory) {
                  filterLabels.push({
                    key: 'crmCategory',
                    name: this.context.intl.formatMessage(messages.crmCategory),
                    value: values.crmCategory.label,
                    filterField: 'crmCategory',
                  });
                  filters.push({
                    type: 'eq',
                    where: 'and',
                    alias: 'product',
                    field: 'crmCategory',
                    value: `${values.crmCategory.value}`,
                  });
                }

                applyFilterFunction(filters, filterLabels, formikActionsBag);
              },
            }}
            defaultActions={{
              visualize: false,
              modify: false,
              delete: false,
              grouped: false,
              moreActions: this.moreActions(),
            }}
          />
          <Grid padded>
            <Grid.Row>
              <FormBackBtn />
            </Grid.Row>
          </Grid>
        </ListWrapper>
      </>
    );
  }
}

ProductPriceMaster.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  values: PropTypes.object,
  entity: PropTypes.string,
  history: PropTypes.array,
  priceList: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  productPriceMaster: makeSelectProductPriceMaster(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'productPriceMaster', reducer });
const withSaga = injectSaga({ key: 'productPriceMaster', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ProductPriceMaster);

ProductPriceMaster.contextTypes = {
  intl: PropTypes.object.isRequired,
};
