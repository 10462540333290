import messages from './messages';

function Validation(values, intl) {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = intl.formatMessage(messages.firstName_required);
  }
  if (!values.lastName) {
    errors.lastName = intl.formatMessage(messages.lastName_required);
  }
  if (!values.username) {
    errors.username = intl.formatMessage(messages.username_required);
  }
  if (!values.email) {
    errors.email = intl.formatMessage(messages.email_required);
  }
  if (!values.id && !values.plainPassword) {
    errors.plainPassword = intl.formatMessage(messages.password_required);
  }
  return errors;
}

export default Validation;
