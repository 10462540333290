/* eslint-disable react/no-did-update-set-state */
/**
 *
 * TechnicalIntervention
 *
 */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Grid, TextArea, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikField from '../../../FormikField';
import { PaddedColumn } from '../../../Layout';
import CurrencyFormatter from '../../../CurrencyFormatter';
import FormikDateTime from '../../../FormikDateTime';
import FormikDate from '../../../FormikDate';
import appMessages from '../../../../containers/App/messages';
import messages from '../messages';
import validation from '../validation';
import Accordion from '../../../Accordion';
import { addNotification } from '../../../../utils/notification';
import FormikToggle from '../../../FormikToggle';
import { onCleanData } from '../../../../containers/TechnicalIntervention/formHelper';

// Services
import StaffWorkorderService from '../../../../shared/services/staffworkorder';
// import { CanI } from '../../../../containers/Can/utils';
// import NoteService from '../../../../shared/services/note';

export class TaskOrder extends React.Component {
  state = {
    isExportPdfProcessing: false,
    isSendPdfProcessing: false,
  };

  componentDidMount() {
    if (this.props.values.startDate && this.props.values.endDate) {
      const ms = this.props.values.endDate.diff(this.props.values.startDate);
      const d = moment.duration(ms);
      this.props.setFieldValue('duration', `${d.days()}d ${d.hours()}h`);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.values.startDate !== prevProps.values.startDate ||
      this.props.values.endDate !== prevProps.values.endDate
    ) {
      if (this.props.values.startDate && this.props.values.endDate) {
        const ms = this.props.values.endDate.diff(this.props.values.startDate);
        const d = moment.duration(ms);
        this.props.setFieldValue('duration', `${d.days()}d ${d.hours()}h`);
      }
    }
  }

  exportPdf = id => {
    this.setState({ isExportPdfProcessing: true });
    StaffWorkorderService.exportPdf(id)
      .then(response => {
        this.setState({ isExportPdfProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: _.get(
            response,
            'data.message',
            this.context.intl.formatMessage(appMessages.success),
          ),
          isError: false,
        });
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .replace(/"/g, '')
          .trim();
        const url = window.URL.createObjectURL(response.data);
        const element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', url);
        element.setAttribute('download', filename);
        element.style.display = '';
        element.click();
        document.body.removeChild(element);
      })
      .catch(err => {
        this.setState({ isExportPdfProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  saveExportPdf = id => {
    this.setState({ isExportPdfProcessing: true });
    StaffWorkorderService.patch(id, onCleanData(this.props.values))
      .then(() => {
        this.exportPdf(id);
      })
      .catch(err => {
        this.setState({ isExportPdfProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  sendPdf = id => {
    this.setState({ isSendPdfProcessing: true });
    StaffWorkorderService.sendPdf(id)
      .then(response => {
        this.setState({ isSendPdfProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: _.get(
            response,
            'data.message',
            this.context.intl.formatMessage(appMessages.success),
          ),
          isError: false,
        });
      })
      .catch(err => {
        this.setState({ isSendPdfProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  saveSendPdf = id => {
    this.setState({ isSendPdfProcessing: true });
    StaffWorkorderService.patch(id, onCleanData(this.props.values))
      .then(() => {
        this.sendPdf(id);
      })
      .catch(err => {
        this.setState({ isSendPdfProcessing: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  cleanData = data => {
    const cleanData = _.cloneDeep(data);

    _.unset(cleanData, 'id');
    _.unset(cleanData, '_links');
    _.unset(cleanData, '_embedded');
    _.unset(cleanData, 'createdAt');
    _.unset(cleanData, 'updatedAt');
    _.unset(cleanData, 'deletedAt');
    _.unset(cleanData, 'duration');
    _.unset(cleanData, 'workorderType'); // temporary disabled

    cleanData.number = _.get(cleanData, 'number.value', null);
    cleanData.customer = _.get(cleanData, 'customer.value', null);
    // cleanData.staff = _.get(cleanData, 'staff.value', cleanData.staff);
    cleanData.destination = _.get(cleanData, 'destination.value', null);
    cleanData.destinationContact = _.get(
      cleanData,
      'destinationContact.value',
      null,
    );
    cleanData.contract = _.get(cleanData, 'contract.value', null);
    cleanData.note = _.get(cleanData, 'note', null);
    cleanData.plannedDate = cleanData.plannedDate
      ? cleanData.plannedDate.format('YYYY-MM-DD')
      : null;
    cleanData.startDate = cleanData.startDate
      ? cleanData.startDate.format('YYYY-MM-DD HH:mm:ss')
      : null;
    cleanData.endDate = cleanData.endDate
      ? cleanData.endDate.format('YYYY-MM-DD HH:mm:ss')
      : null;
    cleanData.noteReason = _.get(
      cleanData,
      'noteReason.value',
      cleanData.noteReason,
    );

    cleanData.state = _.get(cleanData, 'state.value', cleanData.state);
    cleanData.disinfectedAreaNote = _.get(
      cleanData,
      'disinfectedAreaNote',
      null,
    );

    /** new fields */
    let disinfectedAreaCockroachG = _.get(
      cleanData,
      'disinfectedAreaCockroachG',
      false,
    );

    let disinfectedAreaCockroachO = _.get(
      cleanData,
      'disinfectedAreaCockroachO',
      false,
    );

    let disinfectedAreaMosquito = _.get(
      cleanData,
      'disinfectedAreaMosquito',
      false,
    );

    let disinfectedAreaFlies = _.get(cleanData, 'disinfectedAreaFlies', false);

    let disinfectedAreaSpider = _.get(
      cleanData,
      'disinfectedAreaSpider',
      false,
    );

    disinfectedAreaCockroachG = !disinfectedAreaCockroachG ? '0' : '1';
    disinfectedAreaCockroachO = !disinfectedAreaCockroachO ? '0' : '1';
    disinfectedAreaMosquito = !disinfectedAreaMosquito ? '0' : '1';
    disinfectedAreaFlies = !disinfectedAreaFlies ? '0' : '1';
    disinfectedAreaSpider = !disinfectedAreaSpider ? '0' : '1';
    cleanData.disinfectedAreaCockroachG = disinfectedAreaCockroachG;
    cleanData.disinfectedAreaCockroachO = disinfectedAreaCockroachO;
    cleanData.disinfectedAreaMosquito = disinfectedAreaMosquito;
    cleanData.disinfectedAreaFlies = disinfectedAreaFlies;
    cleanData.disinfectedAreaSpider = disinfectedAreaSpider;
    cleanData.disinfectedAreaOther = _.get(
      cleanData,
      'disinfectedAreaOther',
      null,
    );

    return cleanData;
  };

  render() {
    const {
      handleSubmit,
      setFieldValue,
      values,
      showInsectsSection,
    } = this.props;

    return (
      <>
        <Form onSubmit={handleSubmit}>
          <Grid style={{ paddingBottom: 15 }}>
            <Grid.Row>
              <PaddedColumn width={2}>
                <Grid.Column>
                  <FormikDateTime
                    name="startDate"
                    label={this.context.intl.formatMessage(messages.start)}
                    format="dd/MM/yyyy HH:mm"
                  />
                </Grid.Column>
              </PaddedColumn>
              <PaddedColumn width={2}>
                <FormikDateTime
                  name="endDate"
                  label={this.context.intl.formatMessage(messages.end)}
                  format="dd/MM/yyyy HH:mm"
                />
              </PaddedColumn>
              <PaddedColumn width={4}>
                <Grid.Column>
                  <SimpleFormikField
                    {...this.props}
                    readOnly
                    name="duration"
                    label={this.context.intl.formatMessage(messages.duration)}
                    placeholder={this.context.intl.formatMessage(
                      messages.duration,
                    )}
                  />
                </Grid.Column>
              </PaddedColumn>
              <PaddedColumn width={2}>
                <FormikDate
                  name="evaluationExpirationDate"
                  label={this.context.intl.formatMessage(
                    messages.evaluation_expiration_date,
                  )}
                />
              </PaddedColumn>
            </Grid.Row>
            <Grid.Row>
              <PaddedColumn width={4}>
                <Grid.Column>
                  <SimpleFormikField
                    {...this.props}
                    name="referent"
                    label={this.context.intl.formatMessage(messages.referent)}
                    placeholder={this.context.intl.formatMessage(
                      messages.referent,
                    )}
                  />
                </Grid.Column>
              </PaddedColumn>
            </Grid.Row>
            <Grid.Row>
              <PaddedColumn width={4}>
                <FormikField
                  {...this.props}
                  name="interventionReason"
                  label={this.context.intl.formatMessage(
                    messages.description_reason,
                  )}
                >
                  <TextArea
                    onChange={(e, data) =>
                      setFieldValue('interventionReason', data.value)
                    }
                    value={_.get(values, 'interventionReason', '')}
                    placeholder={this.context.intl.formatMessage(
                      messages.description_reason,
                    )}
                  />
                </FormikField>
              </PaddedColumn>
              <PaddedColumn width={4}>
                <FormikField
                  {...this.props}
                  name="interventionDescription"
                  label={this.context.intl.formatMessage(messages.description)}
                >
                  <TextArea
                    onChange={(e, data) =>
                      setFieldValue('interventionDescription', data.value)
                    }
                    value={_.get(values, 'interventionDescription', '')}
                    placeholder={this.context.intl.formatMessage(
                      messages.description,
                    )}
                  />
                </FormikField>
              </PaddedColumn>
              {/* <PaddedColumn width={4}>
                <FormikField
                  {...this.props}
                  name="internalNote"
                  readOnly={!CanI('editWorkOrder', 'custom')}
                  label={this.context.intl.formatMessage(
                    messages.internal_note,
                  )}
                >
                  <TextArea
                    onChange={(e, data) =>
                      setFieldValue('internalNote', data.value)
                    }
                    value={_.get(values, 'internalNote', '')}
                    placeholder={this.context.intl.formatMessage(
                      messages.internal_note,
                    )}
                  />
                </FormikField>
              </PaddedColumn> */}
            </Grid.Row>
            <Grid.Row columns={1}>
              {showInsectsSection && (
                <PaddedColumn>
                  <Grid padded={false}>
                    <Grid.Row columns={1}>
                      <Grid.Column width={16}>
                        <FormikField
                          {...this.props}
                          name="disinfectedAreaNote"
                          label={this.context.intl.formatMessage(
                            messages.disinfectedArea,
                          )}
                        >
                          <TextArea
                            onChange={(e, data) =>
                              setFieldValue('disinfectedAreaNote', data.value)
                            }
                            value={_.get(values, 'disinfectedAreaNote', '')}
                            placeholder={this.context.intl.formatMessage(
                              messages.disinfectedAreaDescription,
                            )}
                          />
                        </FormikField>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <div className="field">
                          <label htmlFor="disinfectedAreaNote">
                            {this.context.intl.formatMessage(
                              messages.targetInsects,
                            )}
                          </label>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                      columns="equal"
                      style={{ paddingTop: 0 }}
                      centered
                      verticalAlign="middle"
                    >
                      <Grid.Column>
                        <FormikToggle
                          {...this.props}
                          name="disinfectedAreaCockroachG"
                          label={this.context.intl.formatMessage(
                            messages.disinfectedAreaCockroachG,
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <FormikToggle
                          {...this.props}
                          name="disinfectedAreaCockroachO"
                          label={this.context.intl.formatMessage(
                            messages.disinfectedAreaCockroachO,
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <FormikToggle
                          {...this.props}
                          name="disinfectedAreaMosquito"
                          label={this.context.intl.formatMessage(
                            messages.disinfectedAreaFlies,
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <FormikToggle
                          {...this.props}
                          name="disinfectedAreaFlies"
                          label={this.context.intl.formatMessage(
                            messages.disinfectedAreaMosquito,
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <FormikToggle
                          {...this.props}
                          name="disinfectedAreaSpider"
                          label={this.context.intl.formatMessage(
                            messages.disinfectedAreaSpider,
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SimpleFormikField
                          {...this.props}
                          name="disinfectedAreaOther"
                          label={this.context.intl.formatMessage(
                            messages.other,
                          )}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </PaddedColumn>
              )}
              <PaddedColumn>
                <FormikField
                  {...this.props}
                  name="interventionCorrectiveActions"
                  label={this.context.intl.formatMessage(
                    messages.corrective_action,
                  )}
                >
                  <TextArea
                    onChange={(e, data) =>
                      setFieldValue('interventionCorrectiveActions', data.value)
                    }
                    value={_.get(values, 'interventionCorrectiveActions', '')}
                    placeholder={this.context.intl.formatMessage(
                      messages.corrective_action,
                    )}
                  />
                </FormikField>
              </PaddedColumn>
            </Grid.Row>
            <Grid.Row>
              <PaddedColumn>
                <Accordion
                  title={this.context.intl.formatMessage(
                    messages.raport_service,
                  )}
                >
                  <Grid>
                    <Grid.Row columns={3}>
                      <PaddedColumn>
                        <Grid.Column>
                          <SimpleFormikField
                            {...this.props}
                            name="reportSigner"
                            label={this.context.intl.formatMessage(
                              messages.signatory,
                            )}
                            placeholder={this.context.intl.formatMessage(
                              messages.signatory,
                            )}
                          />
                        </Grid.Column>
                      </PaddedColumn>
                      {/* <PaddedColumn>
                        <Grid.Column>
                          <SimpleFormikField
                            {...this.props}
                            name="reportEmail"
                            type="email"
                            label={this.context.intl.formatMessage(
                              messages.email,
                            )}
                            placeholder={this.context.intl.formatMessage(
                              messages.email,
                            )}
                          />
                        </Grid.Column>
                      </PaddedColumn> */}
                      <PaddedColumn>
                        <Grid.Column>
                          <CurrencyFormatter
                            {...this.props}
                            name="reportInvoiceAmount"
                            type="number"
                            allowNegative={false}
                            label={this.context.intl.formatMessage(
                              messages.amount,
                            )}
                            placeholder={this.context.intl.formatMessage(
                              messages.amount,
                            )}
                          />
                        </Grid.Column>
                      </PaddedColumn>
                    </Grid.Row>
                    {!this.props.isNew && (
                      <Grid.Row>
                        {this.props.readOnly && (
                          <>
                            <PaddedColumn width={12} />
                            <PaddedColumn textAlign="right" width={2}>
                              <Button
                                loading={this.state.isExportPdfProcessing}
                                icon
                                labelPosition="left"
                                positive
                                type="button"
                                onClick={() => this.exportPdf(values.id)}
                              >
                                {this.context.intl.formatMessage(
                                  messages.get_report,
                                )}
                                <Icon name="download" />
                              </Button>
                            </PaddedColumn>
                            <PaddedColumn textAlign="right" width={2}>
                              <Button
                                loading={this.state.isSendPdfProcessing}
                                icon
                                labelPosition="left"
                                positive
                                type="button"
                                onClick={() => this.sendPdf(values.id)}
                              >
                                {this.context.intl.formatMessage(
                                  messages.send_report,
                                )}
                                <Icon name="send" />
                              </Button>
                            </PaddedColumn>
                          </>
                        )}

                        {!this.props.readOnly && (
                          <>
                            <PaddedColumn width={12} />
                            <PaddedColumn textAlign="right" width={2}>
                              <Button
                                loading={this.state.isExportPdfProcessing}
                                icon
                                labelPosition="left"
                                positive
                                type="button"
                                onClick={() => this.saveExportPdf(values.id)}
                              >
                                {this.context.intl.formatMessage(
                                  messages.get_save_report,
                                )}
                                <Icon name="download" />
                              </Button>
                            </PaddedColumn>
                            <PaddedColumn textAlign="right" width={2}>
                              <Button
                                loading={this.state.isSendPdfProcessing}
                                icon
                                labelPosition="left"
                                positive
                                type="button"
                                onClick={() => this.saveSendPdf(values.id)}
                              >
                                {this.context.intl.formatMessage(
                                  messages.send_save_report,
                                )}
                                <Icon name="send" />
                              </Button>
                            </PaddedColumn>
                          </>
                        )}
                      </Grid.Row>
                    )}
                  </Grid>
                </Accordion>
              </PaddedColumn>
            </Grid.Row>
          </Grid>
        </Form>
      </>
    );
  }
}

TaskOrder.defaultProps = {
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

TaskOrder.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  history: PropTypes.object,
  editRoute: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

TaskOrder.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default TaskOrder;

export { validation };
