import React from 'react';
import PropTypes from 'prop-types';
import { Input, Grid, Form, Icon } from 'semantic-ui-react';

import _ from 'lodash';

function getErrorMessages(errorMessages) {
  return errorMessages.map(error => (
    <span style={{ color: 'red', marginRight: 3 }}>
      <Icon disabled name="warning sign" />
      {error}
    </span>
  ));
}

const MyInput = props => {
  const { errors = {}, touched = {}, name } = props;
  const fieldError = errors[name] || '';
  const errorMessages = [];
  /**
   * if is composite get Error or is a String from FE
   */
  if (typeof fieldError !== 'string') {
    Object.keys(fieldError).forEach(key => {
      errorMessages.push(fieldError[key]);
    });
  } else {
    errorMessages.push(fieldError);
  }

  return (
    <Grid.Column width={props.width}>
      <Form.Field
        style={props.style}
        error={touched[name] && Boolean(fieldError)}
      >
        <label htmlFor={props.name}>
          {props.label}{' '}
          {props.required && <span style={{ color: 'red' }}>*</span>}
        </label>
        <Input
          readOnly={props.disabled}
          onChange={props.onChange}
          onBlur={props.onBlur}
          defaultValue={props[props.name]}
          name={props.name}
          fluid
        />
        {touched[props.name] &&
          Boolean(fieldError) &&
          getErrorMessages(errorMessages)}
      </Form.Field>
    </Grid.Column>
  );
};

MyInput.propTypes = {
  errors: PropTypes.array,
  touched: PropTypes.object,
  width: PropTypes.number,
  style: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
export class GeoInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      city: '',
      province: '',
      cap: '',
      address: '',
      country: '',
      title: '',
    };
  }

  componentDidMount() {
    this.getIndirizzoInputs(this.props.element);
  }

  /**
   * If elements is changed, reload the state
   */
  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.element, this.props.element)) {
      this.getIndirizzoInputs(this.props.element);
    }
  }

  /**
   * Handle the blur.
   */
  onBlur = e => {
    this.props.handleBlur(e.target.name, e.target.value);
    this.props.handleChange({ ...this.state });
  };

  getIndirizzoInputs = element => {
    const city = element && element.city ? element.city : '';
    const province = element && element.province ? element.province : '';
    const cap = element && element.cap ? element.cap : '';
    const address = element && element.address ? element.address : '';
    const country = element && element.country ? element.country : '';

    this.setState({
      city,
      province,
      cap,
      address,
      country,
    });
  };

  /**
   * The render method.
   */
  render() {
    const { labels, disabled = false, required, errors, touched } = this.props;

    return (
      <Grid style={{ marginBottom: 8, marginTop: 0 }}>
        <MyInput
          {...this.state}
          disabled={disabled}
          onBlur={e => this.onBlur(e)}
          onChange={(e, data) => this.setState({ city: data.value })}
          width={8}
          name="city"
          label={labels.city}
          required={required.city}
          errors={errors}
          touched={touched}
        />
        <MyInput
          {...this.state}
          disabled={disabled}
          onBlur={e => this.onBlur(e)}
          onChange={(e, data) => this.setState({ province: data.value })}
          width={2}
          name="province"
          label={labels.province}
          required={required.province}
          errors={errors}
          touched={touched}
        />
        <MyInput
          {...this.state}
          disabled={disabled}
          onBlur={e => this.onBlur(e)}
          width={6}
          onChange={(e, data) => this.setState({ country: data.value })}
          name="country"
          label={labels.state}
          required={required.state}
          errors={errors}
          touched={touched}
        />
        <MyInput
          {...this.state}
          disabled={disabled}
          onBlur={e => this.onBlur(e)}
          width={3}
          onChange={(e, data) => this.setState({ cap: data.value })}
          name="cap"
          label={labels.postalCode}
          required={required.postalCode}
          errors={errors}
          touched={touched}
        />
        <MyInput
          {...this.state}
          disabled={disabled}
          onBlur={e => this.onBlur(e)}
          width={13}
          onChange={(e, data) => this.setState({ address: data.value })}
          name="address"
          label={labels.address}
          required={required.address}
          errors={errors}
          touched={touched}
        />
      </Grid>
    );
  }
}

/**
 * The property types.
 */
GeoInput.propTypes = {
  element: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  labels: PropTypes.shape({
    city: PropTypes.string,
    province: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    address: PropTypes.string,
  }),
  required: PropTypes.shape({
    city: PropTypes.bool,
    province: PropTypes.bool,
    state: PropTypes.bool,
    postalCode: PropTypes.bool,
    address: PropTypes.bool,
  }),
  touched: PropTypes.bool,
  errors: PropTypes.object,
};

/**
 * The property default values.
 */
GeoInput.defaultProps = {
  required: {
    city: false,
    province: false,
    state: false,
    postalCode: false,
    address: false,
  },
};

export default GeoInput;
