// eslint-disable-next-line no-unused-vars
function Validation(values) {
  const errors = {};

  if (!values.expirationDate) {
    errors.expirationDate = 'Field required!';
  }
  if (!values.typology) {
    errors.typology = 'Field required!';
  }
  if (!values.customer) {
    errors.customer = 'Field required!';
  }
  if (!values.destination) {
    errors.destination = 'Field required!';
  }
  return errors;
}

export default Validation;
