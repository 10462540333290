/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Label, Icon, Segment } from 'semantic-ui-react';

const FilterBox = props => {
  const { filterLabels, removeFilter } = props;

  return (
    <Segment style={{ padding: 0 }} basic>
      {filterLabels.map(filterLabel => (
        <Label
          key={`filterbox-${filterLabel.name}`}
          style={{
            backgroundColor: '#2185d0',
            color: 'white',
            fontWeight: 'normal',
            position: 'relative',
          }}
        >
          <div style={{ marginRight: 20 }}>{filterLabel.name}</div>
          <div style={{ marginTop: 5 }}>
            <span style={{ fontWeight: 'bold' }}>{filterLabel.value}</span>
          </div>
          <Icon
            name="delete"
            style={{
              position: 'absolute',
              top: 5,
              right: 5,
              fontSize: '1.2em',
            }}
            onClick={() => removeFilter(filterLabel.filterField)}
          />
        </Label>
      ))}
    </Segment>
  );
};

FilterBox.defaultProps = {
  filterLabels: [],
  removeFilter: null,
};

FilterBox.propTypes = {
  filterLabels: PropTypes.array,
  removeFilter: PropTypes.func,
};

export default FilterBox;
