/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import appMessages from '../../../containers/App/messages';
import agendaService from '../../../shared/services/agenda';
import workorderService from '../../../shared/services/staffworkorder';
import getError from '../../../utils/getError';
import { addNotification } from '../../../utils/notification';
import BaseForm from './form';
import messages from './messages';
import { validation } from './validation';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  startDate: _.get(newValues, 'startDate', null)
    ? moment(newValues.startDate)
    : moment(new Date()),
  endDate: _.get(newValues, 'endDate', null)
    ? moment(newValues.endDate)
    : moment(new Date()),
  plannedDate: _.get(newValues, 'plannedDate', null)
    ? moment(newValues.plannedDate)
    : moment(new Date()),
  customer: {
    value: _.get(newValues, 'customer.id', ''),
    label: _.get(newValues, 'customer.displayName', ''),
  },
  agent: {
    value: _.get(newValues, 'agent.id', ''),
    label: _.get(newValues, 'agent.displayName', ''),
  },
  workorderType: {
    value: _.get(newValues, 'workorderType.id', ''),
    label: _.get(newValues, 'workorderType.description', ''),
  },
  staff: {
    value: _.get(newValues, 'staff.id', ''),
    label: _.get(newValues, 'staff.displayName', ''),
  },
  destination: {
    value: _.get(newValues, 'destination.id', ''),
    label: _.get(newValues, 'destination.businessName', ''),
  },
  destinationContact: {
    value: _.get(newValues, 'destinationContact.id', ''),
    label: `${_.get(newValues, 'destinationContact.firstName', '')} ${_.get(
      newValues,
      'destinationContact.lastName',
      '',
    )}`,
  },
  state: {
    value: _.get(newValues, 'state'),
    label: _.get(newValues, 'state'),
  },
  note: _.get(newValues, 'note', ''),
  subject: _.get(newValues, 'title', ''),
  amount: _.get(newValues, 'amount', ''),
  number: _.get(newValues, 'number', ''),
  eventType: _.get(newValues, 'eventType', ''),
});

export default class ProductCategory extends Component {
  static propTypes = {
    category: PropTypes.object,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload =
      values.eventType === 'appointment'
        ? {
            agent: _.get(values, 'agent.value', null),
            customer: _.get(values, 'customer.value', null),
            note: _.get(values, 'note', null),
            destination: _.get(values, 'destination.value', null),
            state: _.get(values, 'state.value', null),
            startDate: _.get(values, 'startDate')
              ? values.startDate.format('YYYY-MM-DD HH:mm')
              : null,
            endDate: _.get(values, 'endDate')
              ? values.endDate.format('YYYY-MM-DD HH:mm')
              : null,
            subject: _.get(values, 'subject', null),
            id: values.id || null,
          }
        : {
            customer: _.get(values, 'customer.value', null),
            workorderType: _.get(values, 'workorderType.value', null),
            staff: _.get(values, 'staff.value', null),
            destination: _.get(values, 'destination.value', null),
            destinationContact: _.get(values, 'destinationContact.value', null),
            state: _.get(values, 'state.value', null),
            startDate: _.get(values, 'startDate')
              ? values.startDate.format('YYYY-MM-DD HH:mm')
              : null,
            endDate: _.get(values, 'endDate')
              ? values.endDate.format('YYYY-MM-DD HH:mm')
              : null,
            plannedDate: _.get(values, 'plannedDate')
              ? values.plannedDate.format('YYYY-MM-DD HH:mm')
              : null,
            note: _.get(values, 'note', null),
            number: _.get(values, 'number', null),
            amount:
              _.get(values, 'amount') !== '' ? _.get(values, 'amount') : null,
            id: values.id || null,
          };

    const service =
      values.eventType === 'appointment' ? agendaService : workorderService;
    const method = payload.id ? service.update : service.create;
    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: this.context.intl.formatMessage(messages.event_saved),
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setErrors(getError(err));
        setSubmitting(false);
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={initialValues(this.props.initialValues || {})}
            onSubmit={this.onSubmit}
            validateOnBlur
            validateOnChange
            validate={validation}
            render={props => (
              <BaseForm
                {...props}
                contactTypes={this.props.contactTypes}
                onClose={this.props.close}
              />
            )}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

ProductCategory.contextTypes = {
  intl: PropTypes.object.isRequired,
};
