import React, { Component } from 'react';
import _ from 'lodash';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import { addNotification } from '../../../utils/notification';
import UsersService from '../../../shared/services/users';
import getError from '../../../utils/getError';
import validation from './validation';
import BaseForm from './baseForm';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  active: _.get(newValues, 'active', false),
  username: _.get(newValues, 'username', ''),
  plainPassword: '',
  email: _.get(newValues, 'email', ''),
  country: _.get(newValues, 'country', ''),
  phoneNumber: _.get(newValues, 'phoneNumber', ''),
  firstName: _.get(newValues, 'firstName', ''),
  lastName: _.get(newValues, 'lastName', ''),
  defaultLanguage: getDefaultLanguage(_.get(newValues, 'defaultLanguage', '')),
  role: _.get(newValues, 'role')
    ? newValues.role.map(userrole => ({
      label: userrole.roleId, // eslint-disable-line
      value: userrole.id, // eslint-disable-line
     })) // eslint-disable-line
    : [],
});

const getDefaultLanguage = language =>
  ({
    it: { label: 'Italiano', value: 'it' },
    en: { label: 'English', value: 'en' },
  }[language]);

class DiscountForm extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    entity: PropTypes.string,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      active: _.get(values, 'active') === true ? 1 : 0,
      firstName:
        _.get(values, 'firstName') === '' ? null : _.get(values, 'firstName'),
      lastName:
        _.get(values, 'lastName') === ''
          ? null
          : _.get(values, 'lastName', null),
      email:
        _.get(values, 'email') === '' ? null : _.get(values, 'email', null),
      phoneNumber:
        _.get(values, 'phoneNumber') === ''
          ? null
          : _.get(values, 'phoneNumber', null),
      country:
        _.get(values, 'country') === '' ? null : _.get(values, 'country', null),
      username:
        _.get(values, 'username') === ''
          ? null
          : _.get(values, 'username', null),
      plainPassword:
        _.get(values, 'plainPassword') === ''
          ? null
          : _.get(values, 'plainPassword', null),
      role: _.get(values, 'role')
        ? values.role.map(roleObj => roleObj.value)
        : [],
      defaultLanguage: _.get(values, 'defaultLanguage.value', ''),
    };

    if (this.props.isMyself) {
      this.props.patchUser(values);
    } else {
      const method = values.id ? UsersService.put : UsersService.create;

      method(payload)
        .then(() => {
          setSubmitting(false);
          addNotification({
            title: null,
            message: 'Success',
            isError: false,
          });
          this.props.update();
          this.props.close();
        })
        .catch(err => {
          setSubmitting(false);
          setErrors(getError(err));
          addNotification({
            title: null,
            message: err.data.detail,
            isError: true,
          });
        });
    }
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            validate={values => validation(values, this.props.intl)}
            validateOnChange
            onSubmit={this.onSubmit}
            initialValues={initialValues(this.props.initialValues || {})}
            render={props => (
              <BaseForm {...props} fields={this.props.fields} {...this.props} />
            )}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

DiscountForm.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(DiscountForm);
