import React, { useState } from 'react';
import { Button, Modal, Icon, Label } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import PropTypes from 'prop-types';

import { FormattedMessage, intlShape } from 'react-intl';
import { get } from 'lodash';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import headquarterMessages from '../../../../containers/Headquarter/messages';
import ProspectToCustomerService from '../../../../shared/services/prospecttocustomer';
import { addNotification } from '../../../../utils/notification';
import PermissionManager from '../../../Permission';

export const TransformProspect = (
  { values, handleCleanData, history },
  { intl },
) => {
  /**
   * Flag to open the modal.
   */
  const [open, setOpen] = useState(false);

  /**
   * Flag to keep loading he "transform to client" button.
   */
  const [loading, setLoading] = useState(false);

  /**
   * The errors array.
   */
  const [errors, setErrors] = useState([]);

  /**
   * To open the modal.
   */
  const onOpen = () => setOpen(true);

  /**
   * To close the modal.
   */
  const onClose = () => {
    setOpen(false);
    setLoading(false);
  };

  /**
   * Transform a prospect to a customer, generating both customer and destination entities.
   * @returns
   */
  const onTransform = (force = false) => {
    const { id } = values;
    const cleanData = handleCleanData(values);

    setLoading(true);

    /** Save the current prospect in order to avoid losing changes */
    ProspectToCustomerService.saveProspect(id, cleanData)
      .then(() => {
        // The paylod for the trasform RPC.
        const transformPayload = {
          id,
          force,
        };
        /** Transform this prospect into a customer */
        ProspectToCustomerService.transformProspectToCustomer(transformPayload)
          .then(() => {
            addNotification({
              title: null,
              message: intl.formatMessage(
                messages.transform_to_customer_success,
              ),
              isError: false,
            });
            setLoading(false);
            history.push(`/customer`);
          })
          .catch(error => {
            setLoading(false);
            const duplicateErrors = get(
              error,
              'data.validation_messages.force',
              [],
            );

            if (duplicateErrors.length !== 0) {
              setErrors(duplicateErrors);
              setOpen(true);
            } else {
              const validationMessages = get(
                error,
                'data.validation_messages',
                false,
              );
              if (validationMessages) {
                const valMessages = [];
                Object.keys(validationMessages).forEach(key => {
                  Object.keys(validationMessages[key]).forEach(msg => {
                    const translatedKey = intl.formatMessage(
                      messages[`${key}`],
                    );
                    valMessages.push(
                      <span>
                        {translatedKey}: {validationMessages[key][msg]}
                        <br />
                      </span>,
                    );
                  });
                });

                const message = <span>{valMessages}</span>;

                addNotification({
                  title: null,
                  message,
                  isError: true,
                });
              } else {
                addNotification({
                  title: null,
                  message: error.data.detail,
                  isError: true,
                });
              }
            }
          });
      })
      .catch(error => {
        setLoading(false);
        addNotification({
          title: null,
          message: error.data.detail,
          isError: true,
        });
      });
  };

  /**
   * To force transform a prosepect into a customer.
   */
  const onForceTransforming = () => onTransform(true);

  /**
   * Gets the ribbon color from the record type.
   * @param {*} type
   * @returns
   */
  const getRibbonColor = type => {
    switch (type) {
      case 'H':
        return 'green';
      case 'C':
        return 'yellow';
      default:
        return 'grey';
    }
  };

  return (
    <>
      <Button
        loading={loading}
        style={{ marginRight: '15px' }}
        type="button"
        icon
        onClick={() => onTransform()}
        labelPosition="left"
        color="blue"
      >
        <Icon name="sync alternate" />
        <FormattedMessage {...messages.transform_to_customer} />
      </Button>
      <Modal open={open} onClose={onClose} onOpen={onOpen}>
        <Modal.Header>
          <FormattedMessage {...messages.transform_to_customer} />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <FormattedMessage {...messages.forceProspectText} />
          </Modal.Description>
          <Table
            elements={errors || []}
            columns={[
              {
                key: 'code',
                name: intl.formatMessage(headquarterMessages.code),
                searchable: true,
                sortable: true,
                formatter: ({ data }) => {
                  const type = get(data, 'type', '');
                  const label =
                    type === 'H'
                      ? intl.formatMessage(messages.headquarter)
                      : intl.formatMessage(messages.customer);

                  return (
                    <>
                      <Label ribbon color={getRibbonColor(type)}>
                        {label}
                        <br />
                      </Label>
                      {data.code}
                    </>
                  );
                },
              },
              {
                key: 'displayName',
                name: intl.formatMessage(headquarterMessages.nominative),
                searchable: true,
                sortable: true,
              },
              {
                key: 'address',
                name: intl.formatMessage(headquarterMessages.address),
                searchable: true,
                sortable: true,
              },
              {
                key: 'email',
                name: intl.formatMessage(headquarterMessages.email),
                searchable: true,
                sortable: true,
              },
              {
                key: 'phoneNumber',
                name: intl.formatMessage(headquarterMessages.telephone),
                searchable: true,
                sortable: false,
              },
            ]}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" icon labelPosition="left" onClick={onClose}>
            <FormattedMessage {...appMessages.back} />
            <Icon name="remove" />
          </Button>
          {PermissionManager.CanI('createDuplicatedDestination', 'custom') && (
            <Button
              type="button"
              icon
              labelPosition="left"
              color="green"
              onClick={onForceTransforming}
            >
              <FormattedMessage {...messages.forceSave} />
              <Icon name="check" />
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
};

TransformProspect.propTypes = {
  values: PropTypes.object,
  handleCleanData: PropTypes.func,
  history: PropTypes.any,
};

TransformProspect.contextTypes = {
  intl: intlShape,
};
