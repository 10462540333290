/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import Table from 'semantic-table-grid';
import { RemoveBtn, EditBtn, ModalNewBtn } from '../../../Buttons';
import { WrapperTool } from '../../../Layout';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import { addNotification } from '../../../../utils/notification';

// import messages from './messages';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function DestinationTab(props) {
  const [items, setItems] = useState([]);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);
  const { customerService, entity, mainEntity } = props;

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      props.values.id && loadData();
    },
    [props.values.id, paginationData.page],
  );

  const loadData = page => {
    setLoading(true);
    customerService
      .getByOwner(props.values.id, page || paginationData.page)
      .then(res => {
        const data = _.get(res, 'data', { ...paginationDefault });
        setPaginationData({
          page: data.page,
          page_count: data.page_count,
          page_size: data.page_size,
          total_items: data.total_items,
        });
        setLoading(false);
        setItems(_.get(res, `data._embedded.${entity}`), []);
      })
      .catch(error => {
        setLoading(false);
        addNotification({
          title: null,
          message: error.data.detail,
          isError: true,
        });
      });
  };

  const columns = () =>
    [
      {
        key: 'code',
        name: props.intl.formatMessage(messages.customerCode),
        formatter: ({ data }) => _.get(data, 'customer.code'),
      },
      {
        key: 'description',
        name: props.intl.formatMessage(messages.destinationState),
        formatter: ({ data }) => _.get(data, 'destinationState.description'),
      },
      {
        key: 'destinationCode',
        name: props.intl.formatMessage(messages.destinationCode),
      },
      {
        key: 'businessName',
        name: props.intl.formatMessage(messages.destinationArea),
        sortable: true,
      },
      {
        key: 'businessName',
        name: props.intl.formatMessage(messages.businessName),
        sortable: true,
      },
      {
        key: 'businessCity',
        name: props.intl.formatMessage(messages.birthCity),
        sortable: true,
        formatter: ({ data }) =>
          _.get(data, 'businessCity', _.get(data, 'residenceCity', '')),
      },
      {
        key: 'description',
        name: props.intl.formatMessage(messages.destinationLogisticArea),
        sortable: true,
        formatter: ({ data }) =>
          _.get(data, 'destinationLogisticArea.description'),
      },
      !props.readOnly && {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 80 }}>
            <EditBtn
              action={() => {
                props.history.push(`/destination/${data.id}/modify`);
              }}
            />
            <RemoveBtn
              action={() => {
                props.history.push(`/destination/${data.id}/delete`);
              }}
            />
          </div>
        ),
        width: '80px',
      },
    ].filter(o => o);
  return (
    <div>
      <Table
        elements={items}
        rowClassKey="destination-row-class"
        columns={columns()}
        canSort={{
          active: true,
          onSort: () => null,
        }}
        isLoading={loading}
        canPaginate={{
          active: paginationData.total_items > 0,
          pageCount: paginationData.page_count,
          pageSize: paginationData.page_size,
          totalItems: paginationData.total_items,
          page: paginationData.page,
          onSelect: page => {
            setPaginationData({
              ...paginationData,
              page,
            });
          },
        }}
        canAction={{
          active: !props.readOnly,
          actions: [
            <WrapperTool key="wrapper">
              <ModalNewBtn
                onClick={() =>
                  props.history.push({
                    pathname: `/destination/new`,
                    isCustomer: mainEntity === 'customer',
                    customer: {
                      value: props.values.id,
                      label: props.values.displayName,
                    },
                  })
                }
              >
                {props.intl.formatMessage(messages.new_customer)}
              </ModalNewBtn>
            </WrapperTool>,
          ],
        }}
        hiddenHeaderIfEmpty
        emptyResults={
          <div>{props.intl.formatMessage(appMessages.no_results)}</div>
        }
      />
    </div>
  );
}

DestinationTab.defaultProps = {
  values: {},
  readOnly: false,
};

DestinationTab.propTypes = {
  values: PropTypes.object,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  customerService: PropTypes.object,
  entity: PropTypes.string,
  mainEntity: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(DestinationTab);
