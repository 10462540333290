import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { PaddedColumn } from '../../../Layout';
import messages from '../messages';
import FiscalCodeField from './fiscalCodeField';
import VatNumberField from './vatNumberField';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import DestinationStateService from '../../../../shared/services/destinationstate';

function LegalPart(props) {
  const { values, mode } = props;

  return (
    <div>
      <Grid>
        <Grid.Row columns={3}>
          <PaddedColumn>
            <VatNumberField
              {...props}
              name="vatNumber"
              required={
                mode !== 'prospect' &&
                (!values.businessFiscalCode ||
                  (values.businessFiscalCode && values.vatNumber))
              }
            />
          </PaddedColumn>
          <PaddedColumn>
            <FiscalCodeField
              {...props}
              name="businessFiscalCode"
              required={
                mode !== 'prospect' &&
                (!values.vatNumber ||
                  (values.businessFiscalCode && values.vatNumber))
              }
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikAsyncSelect
              {...props}
              name="destinationState"
              label={props.intl.formatMessage(messages.destinationState)}
              loadOptions={(searchText, callback) =>
                DestinationStateService.getOptions(searchText, callback)
              }
            />
          </PaddedColumn>
        </Grid.Row>

        {/* <Grid.Row columns={1}>
          <Grid.Column>
            <Form.Field>
              <SearchAddressWrapper>
                <AddressPicker
                  readOnly={
                    readOnly ||
                    (mode !== 'prospect' &&
                      !PermissionManager.CanI(
                        'editDestinationAddress',
                        'custom',
                      ))
                  }
                  required={{
                    auto: true,
                    city: true,
                    province: true,
                    state: false, // TODO set to true after data sanitation
                    postalCode: true,
                    address: false, // TODO set to true after data sanitation
                  }}
                  name="business_address"
                  search={values.businessAddressSelectionType || 'auto'}
                  showManual={false}
                  labels={{
                    autoBtn: props.intl.formatMessage(appMessages.automatic),
                    manualBtn: props.intl.formatMessage(appMessages.manual),
                    city: props.intl.formatMessage(appMessages.city),
                    province: props.intl.formatMessage(appMessages.province),
                    state: props.intl.formatMessage(appMessages.state),
                    postalCode: props.intl.formatMessage(
                      appMessages.postal_code,
                    ),
                    address: props.intl.formatMessage(appMessages.address),
                    autoPlaceholder: props.intl.formatMessage(
                      appMessages.search_an_address,
                    ),
                    auto: props.intl.formatMessage(appMessages.search),
                  }}
                  element={{
                    address: values.businessStreet,
                    title: values.businessAddressTitle,
                    city: values.businessCity,
                    province: values.businessProvinceShort,
                    cap: values.businessPostalCode,
                    country: values.businessCountry,
                    latitude: values.businessLatitude,
                    longitude: values.businessLongitude,
                  }}
                  onChange={(name, value) => {
                    const [latitude, longitude] = get(value, 'coords', [
                      null,
                      null,
                    ]);
                    props.setFieldValue(
                      'businessStreet',
                      value.address || value.city,
                    );
                    props.setFieldValue('businessCity', value.city);
                    props.setFieldValue(
                      'businessProvinceShort',
                      value.province,
                    );
                    props.setFieldValue(
                      'businessPostalCode',
                      value.cap || value.city,
                    );
                    props.setFieldValue('businessCountry', value.country);
                    props.setFieldValue('businessAddressTitle', value.title);
                    props.setFieldValue('businessLatitude', latitude);
                    props.setFieldValue('businessLongitude', longitude);
                  }}
                  onSwitch={el =>
                    props.setFieldValue('businessAddressSelectionType', el)
                  }
                  // eslint-disable-next-line no-unused-vars
                  onBlur={(name, value) => null}
                />
              </SearchAddressWrapper>
            </Form.Field>
          </Grid.Column>
        </Grid.Row> */}
      </Grid>
    </div>
  );
}

LegalPart.defaultProps = {
  values: {},
  readOnly: false,
};

LegalPart.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default injectIntl(LegalPart);
