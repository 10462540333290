import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the supplier state domain
 */

const selectSupplierDomain = state => state.get('supplier', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Supplier
 */

const makeSelectSupplier = () =>
  createSelector(selectSupplierDomain, substate => substate.toJS());

export default makeSelectSupplier;
export { selectSupplierDomain };
