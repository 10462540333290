/*
 * Commission Messages
 *
 * This contains all the text for the Commission container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Commission';

export default defineMessages({
  commission: {
    id: `${scope}.commission`,
    defaultMessage: 'Commission',
  },
  commissions: {
    id: `${scope}.commissions`,
    defaultMessage: 'Commissions',
  },
  newCommission: {
    id: `${scope}.newCommission`,
    defaultMessage: 'New Commission',
  },
  removeCommission: {
    id: `${scope}.removeCommission`,
    defaultMessage: 'Are you sure to remove the commission?',
  },
  confirmAllCommission: {
    id: `${scope}.confirmAllCommission`,
    defaultMessage: 'Are you sure to confirm all the commissions?',
  },
  confirmSelectedCommission: {
    id: `${scope}.confirmSelectedCommission`,
    defaultMessage: 'Are you sure to confirm the selected commissions?',
  },
  exportCommission: {
    id: `${scope}.exportCommission`,
    defaultMessage: 'Export Commission',
  },
  exportPdf: {
    id: `${scope}.exportPdf`,
    defaultMessage: 'Export PDF',
  },
  exportExcel: {
    id: `${scope}.exportExcel`,
    defaultMessage: 'Export Excel',
  },
  toCommission: {
    id: `${scope}.toCommission`,
    defaultMessage: 'To Commission',
  },
  headquarter: {
    id: `${scope}.headquarter`,
    defaultMessage: 'Headquarter',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  referenceDate: {
    id: `${scope}.referenceDate`,
    defaultMessage: 'Reference Date',
  },
  referenceDateFrom: {
    id: `${scope}.referenceDateFrom`,
    defaultMessage: 'Reference date from',
  },
  referenceDateTo: {
    id: `${scope}.referenceDateTo`,
    defaultMessage: 'Reference date to',
  },
  totalAmount: {
    id: `${scope}.totalAmount`,
    defaultMessage: 'Total Amount',
  },
  invoiceDateFrom: {
    id: `${scope}.invoiceDateFrom`,
    defaultMessage: 'Invoice date from',
  },
  invoiceDateTo: {
    id: `${scope}.invoiceDateTo`,
    defaultMessage: 'Invoice date to',
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Code',
  },
  totalCommissionAmount: {
    id: `${scope}.totalCommissionAmount`,
    defaultMessage: 'Total commission',
  },
  totalRefundAmount: {
    id: `${scope}.totalRefundAmount`,
    defaultMessage: 'Total refund',
  },
  totalBonusAmount: {
    id: `${scope}.totalBonusAmount`,
    defaultMessage: 'Total invoiced',
  },
  totalYearBonusAmount: {
    id: `${scope}.totalYearBonusAmount`,
    defaultMessage: 'Total bonus',
  },
  totalYearInvoiceAmount: {
    id: `${scope}.totalYearInvoiceAmount`,
    defaultMessage: 'Total invoice',
  },
  totalYearPlannedSaleAmount: {
    id: `${scope}.totalYearPlannedSaleAmount`,
    defaultMessage: "Total agent's planned sales",
  },
  yearToDate: {
    id: `${scope}.yearToDate`,
    defaultMessage: '(year-to-date)',
  },
  removeSelected: {
    id: `${scope}.removeSelected`,
    defaultMessage: 'Remove selected',
  },
  removeAll: {
    id: `${scope}.removeAll`,
    defaultMessage: 'Remove all',
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove',
  },
  growthBonus: {
    id: `${scope}.growthBonus`,
    defaultMessage: 'Growth bonus',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  confirmAll: {
    id: `${scope}.confirmAll`,
    defaultMessage: 'Confirm all',
  },
  confirmSelected: {
    id: `${scope}.confirmSelected`,
    defaultMessage: 'Confirm selected',
  },
  cancelAll: {
    id: `${scope}.cancelAll`,
    defaultMessage: 'Cancel all',
  },
  cancelSelected: {
    id: `${scope}.cancelSelected`,
    defaultMessage: 'Cancel selected',
  },
});
