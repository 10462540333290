import { Formik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import getError from '../../../utils/getError';
import { addNotification } from '../../../utils/notification';
import BaseForm from './form';
import messages from './messages';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  startDate: _.get(newValues, 'startDate.date', null)
    ? moment(newValues.startDate.date)
    : null,
  endDate: _.get(newValues, 'endDate.date', null)
    ? moment(newValues.endDate.date)
    : null,
  flatUnit: _.get(newValues, 'flatUnit', 0),
  commission: _.get(newValues, 'commission', 0),
  refund: _.get(newValues, 'refund', 0),
  fromPercentage: _.get(newValues, 'fromPercentage', null),
  toPercentage: _.get(newValues, 'toPercentage', null),
  crmCategory: {
    value: _.get(
      newValues,
      '_embedded.crmCategory.id',
      _.get(newValues, 'crmCategory.id', ''),
    ),
    label: _.get(
      newValues,
      '_embedded.crmCategory.description',
      _.get(newValues, 'crmCategory.description', ''),
    ),
  },
  subCategory: {
    value: _.get(
      newValues,
      '_embedded.subCategory.id',
      _.get(newValues, 'subCategory.id', null),
    ),
    label: `${_.get(
      newValues,
      '_embedded.subCategory.code',
      _.get(newValues, 'subCategory.code', ''),
    )} ${_.get(
      newValues,
      '_embedded.subCategory.description',
      _.get(newValues, 'subCategory.description', ''),
    )}`,
  },
  isIncludedInBonus: newValues.includedInBonus !== false,
});

export default class ProductCommission extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    commissionId: PropTypes.string,
    commissionService: PropTypes.object,
    entity: PropTypes.string,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      startDate: _.get(values, 'startDate.date', null)
        ? values.startDate.format('YYYY-MM-DD')
        : null,
      endDate: _.get(values, 'endDate.date', null)
        ? values.endDate.format('YYYY-MM-DD')
        : null,
      flatUnit: _.get(values, 'flatUnit', 0),
      commission: _.get(values, 'commission', 0),
      refund: _.get(values, 'refund', 0),
      [this.props.entity]: this.props.entityId,
    };

    if (this.props.entity === 'agent') {
      payload.crmCategory = _.get(values, 'crmCategory.value', null);
      payload.subCategory = _.get(values, 'subCategory.value', null);
    }

    if (this.props.entity === 'product') {
      payload.fromPercentage = _.get(values, 'fromPercentage', null);
      payload.toPercentage = _.get(values, 'toPercentage', null);
      payload.includedInBonus = values.isIncludedInBonus ? 1 : 0;
    }

    const method = payload.id
      ? this.props.commissionService.update
      : this.props.commissionService.create;

    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: null,
          message: this.context.intl.formatMessage(messages.commission_saved),
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setSubmitting(false);
        setErrors(getError(err));
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={initialValues(this.props.initialValues || {})}
            onSubmit={this.onSubmit}
            render={props => (
              <BaseForm
                {...props}
                entityId={this.props.entityId}
                entity={this.props.entity}
                close={this.props.close}
                submit={this.submit}
              />
            )}
          />
        </Grid.Column>
      </Grid>
    );
  }
}

ProductCommission.contextTypes = {
  intl: PropTypes.object.isRequired,
};
