/* eslint-disable no-underscore-dangle */
/* eslint-disable spaced-comment */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { intlShape, injectIntl } from 'react-intl';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { Formik } from 'formik';
import getError from '../../../../../../utils/getError';
import { addNotification } from '../../../../../../utils/notification';
import { ModalFakeActions } from '../../../../../Layout';
import appMessages from '../../../../../../containers/App/messages';
import OrderRowService from '../../../../../../shared/services/orderrow';
import messages from '../../../messages';
import BaseForm from './baseForm';
import DiscountTypeService from '../../../../../../shared/services/discountType';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  discountType: {
    id: _.get(newValues, '_embedded.discountType.id', ''),
    code: _.get(newValues, '_embedded.discountType.code', ''),
    description: _.get(newValues, '_embedded.discountType.description', ''),
  },
  quantity: _.get(newValues, 'minQuantity', 1),
  discountValue: _.get(newValues, 'discountValue', '0'),
  discountExtra: _.get(newValues, 'extraDiscount', '0'),
  markup: _.get(newValues, 'markup', '0'),
  netPrice: _.get(newValues, 'netPrice', '0'),
  flatValue: _.get(newValues, 'flatValue', '0'),
});

class DiscountForm extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    entity: PropTypes.string,
  };

  // eslint-disable-next-line consistent-return
  onSubmit = async (values, { setSubmitting, setErrors }) => {
    const { selectedRows } = this.props;

    if (this.props.isNewDiscountItem && !this.props.destination) {
      addNotification({
        title: null,
        message: this.props.intl.formatMessage(messages.requiredDestination),
        isError: true,
      });
      setSubmitting(false);
      return false;
    }

    const discountType = await DiscountTypeService.searchByName('Percentage');

    let payload = {
      pricelistProducts: true,
      order: this.props.entityId || null,
      products: values.id ? null : selectedRows,
      destination: this.props.destination,
      discountType: discountType.data._embedded['discount-type'][0].id,
    };

    // PERCENTAGE
    if (values.discountType.description === 'Percentage') {
      payload = {
        ...payload,
        extraDiscount:
          _.get(values, 'discountExtra') === ''
            ? null
            : _.get(values, 'discountExtra', null),
        discountValue:
          _.get(values, 'discountValue') === ''
            ? null
            : _.get(values, 'discountValue', null),
      };
    }
    if (values.discountType.description === 'Markup') {
      payload = {
        ...payload,
        markup:
          _.get(values, 'markup') === '' ? null : _.get(values, 'markup', null),
      };
    }

    if (!this.props.isNewDiscountItem) {
      _.unset(payload, 'pricelistProducts');
      // _.unset(payload, 'destination');
      _.unset(payload, 'products');

      payload.id = _.get(values, 'id', null);
      payload.orderRows = values.id ? null : selectedRows;
    }

    const method = this.props.isNewDiscountItem
      ? OrderRowService.create
      : values.id
        ? OrderRowService.update
        : OrderRowService.updateMulti;

    method(payload)
      .then(response => {
        setSubmitting(false);
        addNotification({
          title: null,
          message: 'Success',
          isError: false,
        });
        // Get the offer from the response
        const offerRows = _.get(response, 'data._embedded.items');
        // Update compliant
        if (offerRows.length > 0) {
          const { offer } = offerRows[0];
          this.props.updateCompliant(offer);
        }
        // Update rows
        this.props.update();
        // Close modal
        this.props.close();
      })
      .catch(err => {
        setSubmitting(false);
        setErrors(getError(err));
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  validate = values => {
    const errors = {};
    if (
      values.discountType.description === 'Percentage' &&
      ((values.discountValue && values.discountValue === '') ||
        values.discountValue === null)
    ) {
      errors.discountValue = this.props.intl.formatMessage(
        messages.discountValue_required,
      );
    }
    if (
      values.discountType.description === 'Flat' &&
      ((values.flatValue && values.flatValue === '') ||
        values.flatValue === null)
    ) {
      errors.flatValue = this.props.intl.formatMessage(
        messages.flatValue_required,
      );
    }
    if (
      values.discountType.description === 'Fixed' &&
      ((values.netPrice && values.netPrice === '') || values.netPrice === null)
    ) {
      errors.netPrice = this.props.intl.formatMessage(
        messages.netPrice_required,
      );
    }
    if (
      values.discountType.description === 'Markup' &&
      ((values.markup && values.markup === '') || values.markup === null)
    ) {
      errors.markup = this.props.intl.formatMessage(messages.markup_required);
    }
    return errors;
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            validate={this.validate}
            validateOnBlur
            validateOnChange
            onSubmit={this.onSubmit}
            initialValues={initialValues(this.props.initialValues || {})}
            render={props => <BaseForm {...props} fields={this.props.fields} />}
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.close}
            >
              <Icon name="remove" />
              {this.props.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Button
              type="button"
              icon
              labelPosition="left"
              color="green"
              loading={this.props.isSubmitting}
              onClick={() => this.submit()}
            >
              <Icon name="checkmark" />
              {this.props.intl.formatMessage(appMessages.save)}
            </Button>
          </div>
        </ModalFakeActions>
      </Grid>
    );
  }
}

DiscountForm.propTypes = {
  intl: intlShape.isRequired,
  isNew: PropTypes.string,
};

export default injectIntl(DiscountForm);
