import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form } from 'semantic-ui-react';
import { Creatable } from 'react-select';
import { PaddedColumn } from '../../Layout';
import FormikTextArea from '../../FormikTextArea';
import messages from './messages';

export const BaseForm = props => {
  const subOptions = props.values.subcategories.map(subcategory => ({
    value: subcategory.id,
    label: subcategory.description,
  }));

  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting}
    >
      <Grid columns="equal">
        <Grid.Row>
          <PaddedColumn width={16}>
            <FormikTextArea
              {...props}
              name="description"
              label={props.intl.formatMessage(messages.description)}
              placeholder={props.intl.formatMessage(
                messages.description_placeholder,
              )}
            />
          </PaddedColumn>
          <PaddedColumn width={16}>
            <Creatable
              isMulti
              name="colors"
              value={subOptions}
              options={subOptions}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={vals => {
                const newValues = vals.map(
                  opt =>
                    // eslint-disable-next-line no-underscore-dangle
                    !opt.__isNew__
                      ? { id: opt.value, description: opt.label }
                      : { description: opt.label },
                );
                props.setFieldValue('subcategories', newValues);
              }}
            />
          </PaddedColumn>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BaseForm);
