import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import Select from 'react-select';
import SimpleFormikField from 'novigo-simple-formik-field';
import Accordion from '../../../Accordion';
import { PaddedColumn } from '../../../Layout';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import FormikToggle from '../../../FormikToggle';
import messages from '../messages';
import DestinationAreaService from '../../../../shared/services/destinationarea';
import DestinationLogisticAreaService from '../../../../shared/services/destinationlogisticarea';
import ProfileService from '../../../../shared/services/profile';
import { CanI } from '../../../../containers/Can/utils';

function CommercialTab(props) {
  const isProspect = props.mode === 'prospect';

  const [areas, setAreas] = useState([]);
  const [areaLoading, setAreaLoading] = useState(true);
  const [subAreas, setSubAreas] = useState([]);

  useEffect(() => {
    DestinationAreaService.getAll()
      .then(res =>
        _.get(res, 'data._embedded.destination-area', []).map(area => ({
          value: area.id,
          label: `${area.code} - ${area.description}`,
          subAreas: area.subAreas,
        })),
      )
      .then(res => {
        setAreas(res);
        setAreaLoading(false);
      });
  }, []);

  useEffect(
    () => {
      setSubAreas(
        _.get(props, 'values.destinationArea.subAreas', []).map(
          ({ id, description }) => ({
            value: id,
            label: description,
          }),
        ),
      );
    },
    [_.get(props, 'values.destinationArea.subAreas[0]', null)],
  );

  return (
    <div>
      <Accordion title={props.intl.formatMessage(messages.tab_commercial_data)}>
        <Grid>
          <Grid.Row columns={3}>
            <PaddedColumn>
              <SimpleFormikField
                {...props}
                name="destinationArea"
                label={props.intl.formatMessage(messages.destination_area)}
              >
                {({
                  form: {
                    setFieldValue,
                    values,
                    values: { destinationSubArea },
                  },
                }) => (
                  <Select
                    isDisabled={!isProspect}
                    className="basic-single"
                    classNamePrefix="select"
                    value={values.destinationArea}
                    isLoading={areaLoading}
                    name="destinationArea"
                    options={areas}
                    onChange={value => {
                      if (
                        destinationSubArea &&
                        destinationSubArea.value !== value.value
                      ) {
                        setFieldValue('destinationSubArea', null);
                      }
                      setFieldValue('destinationArea', value);
                      setSubAreas(
                        _.get(value, 'subAreas').map(({ id, description }) => ({
                          value: id,
                          label: description,
                        })),
                      );
                    }}
                  />
                )}
              </SimpleFormikField>
            </PaddedColumn>
            <PaddedColumn>
              <SimpleFormikField
                {...props}
                name="destinationSubArea"
                label={props.intl.formatMessage(messages.destination_sub_area)}
              >
                {({ form: { setFieldValue, values } }) => (
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={values.destinationSubArea}
                    name="destinationSubArea"
                    options={subAreas}
                    isDisabled={!isProspect || !subAreas.length}
                    onChange={value => {
                      setFieldValue('destinationSubArea', value);
                    }}
                  />
                )}
              </SimpleFormikField>
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                asyncSelectProps={{
                  isDisabled: !isProspect,
                }}
                {...props}
                name="destinationLogisticArea"
                label={props.intl.formatMessage(
                  messages.destination_logistic_area,
                )}
                loadOptions={(searchText, callback) =>
                  DestinationLogisticAreaService.getOptions(
                    searchText,
                    callback,
                  )
                }
              />
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                asyncSelectProps={{
                  isDisabled:
                    (isProspect &&
                      !CanI('editDestinationProspectAgent', 'custom')) ||
                    !isProspect,
                }}
                {...props}
                name="agent1"
                label={props.intl.formatMessage(messages.agent1)}
                loadOptions={(searchText, callback) =>
                  ProfileService.getAgentOptions(searchText, callback)
                }
              />
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                asyncSelectProps={{
                  isDisabled:
                    !isProspect && !CanI('editDestinationAgent', 'custom'),
                }}
                {...props}
                name="agent2"
                label={props.intl.formatMessage(messages.agent2)}
                loadOptions={(searchText, callback) =>
                  ProfileService.getAgentOptions(searchText, callback)
                }
              />
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                asyncSelectProps={{
                  isDisabled:
                    !isProspect && !CanI('editDestinationAgent', 'custom'),
                }}
                {...props}
                name="subagent"
                label={props.intl.formatMessage(messages.subagent)}
                loadOptions={(searchText, callback) =>
                  ProfileService.getAgentOptions(searchText, callback)
                }
              />
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                asyncSelectProps={{
                  isDisabled: !isProspect,
                }}
                {...props}
                name="commercialDirector"
                label={props.intl.formatMessage(messages.commercial_director)}
                loadOptions={(searchText, callback) =>
                  ProfileService.getCommercialDirectorOptions(
                    searchText,
                    callback,
                  )
                }
              />
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                asyncSelectProps={{
                  isDisabled: !isProspect,
                }}
                {...props}
                name="areaManager"
                label={props.intl.formatMessage(messages.area_manager)}
                loadOptions={(searchText, callback) =>
                  ProfileService.getAreaManagerOptions(searchText, callback)
                }
              />
            </PaddedColumn>
            <PaddedColumn>
              <FormikAsyncSelect
                asyncSelectProps={{
                  isDisabled: !isProspect,
                }}
                {...props}
                name="commercialBackoffice"
                label={props.intl.formatMessage(messages.commercial_backoffice)}
                loadOptions={(searchText, callback) =>
                  ProfileService.getCommercialBackofficeOptions(
                    searchText,
                    callback,
                  )
                }
              />
            </PaddedColumn>
            <PaddedColumn>
              <Grid>
                <Grid.Row columns={2}>
                  <PaddedColumn>
                    <FormikToggle
                      disabled={isProspect}
                      {...props}
                      name="excludedFromPrize"
                      label={props.intl.formatMessage(
                        messages.excluded_from_prize,
                      )}
                    />
                  </PaddedColumn>
                  <PaddedColumn>
                    <FormikToggle
                      {...props}
                      name="free"
                      label={props.intl.formatMessage(messages.free)}
                    />
                  </PaddedColumn>
                </Grid.Row>
              </Grid>
            </PaddedColumn>
            <PaddedColumn>
              <FormikToggle
                disabled={!isProspect}
                {...props}
                name="blocked"
                label={props.intl.formatMessage(messages.blocked)}
              />
            </PaddedColumn>
            <PaddedColumn>
              <SimpleFormikField
                {...props}
                readOnly={!isProspect || !props.values.blocked}
                name="blockReason"
                label={props.intl.formatMessage(messages.block_reason)}
              />
            </PaddedColumn>
          </Grid.Row>
        </Grid>
      </Accordion>
    </div>
  );
}

CommercialTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CommercialTab);
