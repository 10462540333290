/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from 'semantic-ui-react';

import SearchBar from './SearchBar';
import CreateButton from './CreateButton';
import CounterLabels from './CounterLabels';
import { TranslationsShape } from './Shapes';

const Actions = props => {
  const {
    canSearch = true,
    canCreate = true,
    onSearch = null,
    searchPlaceholder = '',
    counter = {},
    selectedCounter = {},
    selectionActions = [],
    searchedValue = '',
    translations = {},
    columnWidthSearchbar = 6,
    columnWidthActions = 10,
  } = props;

  return (
    <Grid stackable style={{ marginBottom: 0 }}>
      <Grid.Row>
        <Grid.Column width={columnWidthSearchbar}>
          {canSearch && (
            <SearchBar
              active
              onSearch={onSearch}
              searchPlaceholder={searchPlaceholder}
              searchedValue={searchedValue}
              translations={translations}
            />
          )}
          <CounterLabels
            counter={counter}
            selectedCounter={selectedCounter}
            translations={translations}
          />
        </Grid.Column>
        <Grid.Column style={{ textAlign: 'right' }} width={columnWidthActions}>
          {selectionActions}
          {canCreate && <CreateButton {...props} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

Actions.defaultProps = {
  canCreate: true,
  canSearch: true,
  searchPlaceholder: '',
  counter: {},
  selectedCounter: {},
  selectionActions: [],
  searchedValue: '',
  canCreateLoading: false,
  canCreateDisabled: false,
};

Actions.propTypes = {
  canCreate: PropTypes.bool,
  canSearch: PropTypes.bool,
  onCreate: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  canCreateLoading: PropTypes.bool,
  canCreateDisabled: PropTypes.bool,
  /**
   * The placeholder of searchbox
   * Default is empty
   */
  searchPlaceholder: PropTypes.string,
  /**
   * The default value of the searchbox
   * Default is empty, but filled if is searched and re-mounted
   */
  searchedValue: PropTypes.string,
  counter: PropTypes.object,
  selectedCounter: PropTypes.object,
  /**
   * A series of actions relate to
   * selection in the tables
   */
  selectionActions: PropTypes.arrayOf(PropTypes.element),
  // eslint-disable-next-line react/require-default-props
  translations: TranslationsShape,
};

export default Actions;
