import React, { useState } from 'react';
import { Formik } from 'formik';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { get } from 'lodash';
import BaseForm from './form';
import { addNotification } from '../../../utils/notification';
import { ModalFakeActions } from '../../Layout';
import appMessages from '../../../containers/App/messages';
import getError from '../../../utils/getError';
import { validation } from './validation';
import messages from './messages';
import DestinationService from '../../../shared/services/destination';

export default function DestinationMandatoryOrderFields(props) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: '',
    phoneNumber: '',
    delivery: '',
  });
  let baseForm = React.createRef();

  /**
   * Handles the destination id change.
   */
  useState(
    () => {
      DestinationService.get(props.destinationId).then(res => {
        setInitialValues({
          email: get(res.data, 'email', ''),
          phoneNumber: get(res.data, 'phoneNumber', ''),
          delivery: get(res.data, 'delivery', ''),
        });
      });
    },
    [props.destinationId],
  );

  const submit = () => {
    baseForm.submitForm();
  };

  /**
   * Handles the form submit.
   * @param {*} values
   * @param {*} param1
   */
  const onSubmit = (values, { setErrors }) => {
    setIsUpdating(true);

    const payload = {
      id: props.destinationId,
      email: values.email,
      phoneNumber: values.phoneNumber,
      delivery: values.delivery,
    };
    DestinationService.patch(payload)
      .then(() => {
        setIsUpdating(false);

        addNotification({
          title: null,
          message: props.intl.formatMessage(messages.destination_saved),
          isError: false,
        });
        props.update();
        props.close();
      })
      .catch(err => {
        setIsUpdating(false);
        addNotification({
          title: null,
          message: err.message,
          isError: true,
        });
        setErrors(getError(err));
      });
  };

  return (
    <Grid>
      <Grid.Column width={16}>
        <Formik
          ref={c => {
            baseForm = c;
          }}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnBlur
          validateOnChange
          validate={validation}
          enableReinitialize
          render={formik => <BaseForm {...formik} />}
        />
      </Grid.Column>
      <ModalFakeActions>
        <div style={{ display: 'inline-block', width: '50%' }}>
          <Button type="button" icon labelPosition="left" onClick={props.close}>
            <Icon name="remove" />
            {props.intl.formatMessage(appMessages.cancel)}
          </Button>
        </div>
        <div
          style={{
            display: 'inline-block',
            width: '50%',
            textAlign: 'right',
          }}
        >
          <Button
            type="button"
            icon
            labelPosition="left"
            color="green"
            onClick={submit}
            loading={isUpdating}
          >
            <Icon name="checkmark" />
            {props.intl.formatMessage(appMessages.save)}
          </Button>
        </div>
      </ModalFakeActions>
    </Grid>
  );
}
