/*
 *
 * TechnicalIntervention constants
 *
 */

import React from 'react';
import { Grid, Label } from 'semantic-ui-react';
import { get } from 'lodash';
import moment from 'moment';
import appMessages from '../../../../containers/App/messages';
import messages from '../messages';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import { EditorPopup } from '../../../Popup';

// Services
import destinationService from '../../../../shared/services/destination';
import StaffService from '../../../../shared/services/staff';
import NoteService from '../../../../shared/services/note';

export const RELATED_TABLES = [
  {
    type: 'leftjoin',
    field: 'customer',
    alias: 'customer',
  },
  {
    type: 'leftjoin',
    field: 'destination',
    alias: 'destination',
  },
  {
    type: 'leftjoin',
    field: 'staff',
    alias: 'staff',
  },
  {
    type: 'leftjoin',
    field: 'workorderType',
    alias: 'workorderType',
  },
  {
    type: 'leftjoin',
    field: 'noteReason',
    alias: 'noteReason',
  },
];

export const WorkorderUnplannedFilters = (intl, onSearch) => ({
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  customSearchIsOpen: true,
  initialOpen: true,
  formStructure: (
    <>
      <Grid.Column>
        <FormikAsyncSelect
          name="noteReason"
          label={intl.formatMessage(messages.typeODL)}
          loadOptions={(searchText, callback) =>
            NoteService.getAsyncNoteReasonOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="destination"
          label={intl.formatMessage(messages.customer)}
          loadOptions={(searchText, callback) =>
            destinationService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];
    if (values.noteReason) {
      filterLabels.push({
        key: 'noteReason',
        name: intl.formatMessage(messages.typeODL),
        value: values.noteReason.label,
        filterField: 'noteReason',
      });
      filters.push({
        type: 'eq',
        field: 'noteReason',
        value: `${values.noteReason.value}`,
      });
    }
    if (values.destination) {
      filterLabels.push({
        key: 'destination',
        name: intl.formatMessage(messages.destination),
        value: values.destination.label,
        filterField: 'destination',
      });
      filters.push({
        type: 'eq',
        field: 'destination',
        value: `${values.destination.value}`,
      });
    }
    applyfilterFunction(filters, filterLabels, formikActionsBag);
    onSearch();
  },
});

export const WorkorderPlannedFilters = (setStaffFilter, intl) => ({
  active: true,
  title: intl.formatMessage(appMessages.search),
  buttonLabel: intl.formatMessage(appMessages.search),
  initialOpen: true,
  formStructure: (
    <>
      <Grid.Column>
        <FormikAsyncSelect
          name="noteReason"
          label={intl.formatMessage(messages.typeODL)}
          loadOptions={(searchText, callback) =>
            NoteService.getAsyncNoteReasonOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="destination"
          label={intl.formatMessage(messages.customer)}
          loadOptions={(searchText, callback) =>
            destinationService.getOptions(searchText, callback)
          }
        />
      </Grid.Column>
      <Grid.Column>
        <FormikAsyncSelect
          name="staff"
          label={intl.formatMessage(messages.technical)}
          loadOptions={(searchText, callback) =>
            StaffService.getOptions(searchText, callback)
          }
          required
        />
      </Grid.Column>
    </>
  ),
  onApplyFilter: (values, formikActionsBag, applyfilterFunction) => {
    const filters = [];
    const filterLabels = [];
    if (values.noteReason) {
      filterLabels.push({
        key: 'noteReason',
        name: intl.formatMessage(messages.typeODL),
        value: values.noteReason.label,
        filterField: 'noteReason',
      });
      filters.push({
        type: 'eq',
        field: 'noteReason',
        value: values.noteReason.value,
      });
    }
    if (values.destination) {
      filterLabels.push({
        key: 'destination',
        name: intl.formatMessage(messages.destination),
        value: values.destination.label,
        filterField: 'destination',
      });
      filters.push({
        type: 'eq',
        field: 'destination',
        value: values.destination.value,
      });
    }
    if (values.staff) {
      // Notify the staff filter change outside
      setStaffFilter(values.staff);
      filterLabels.push({
        key: 'staff',
        name: intl.formatMessage(messages.staff),
        value: values.staff.label,
        filterField: 'staff',
      });
      filters.push({
        type: 'eq',
        field: 'staff',
        value: values.staff.value,
      });
    }
    applyfilterFunction(filters, filterLabels, formikActionsBag);
  },
});

export const WorkOrderUnplannedColumns = intl => [
  {
    width: 60,
    key: 'number',
    name: intl.formatMessage(messages.numberODL),
    searchable: true,
    sortable: true,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const dataHover = [
        {
          key: 'Cliente',
          value: get(data, 'destination.businessName', '--') || '--',
          style: { fontWeight: 'bold' },
        },
        {
          key: 'Indirizzo',
          value: get(data, 'destination.businessAddressTitle', '--') || '--',
        },
        {
          key: 'Contatto',
          value: get(data, 'referent', '--') || '--',
        },
        {
          key: 'Telefono',
          value: get(data, 'destination.phoneNumber', '--') || '--',
        },
        {
          key: 'Consegne',
          value: get(data, 'destination.delivery', '--') || '--',
        },
        {
          key: 'Chiusura',
          value: get(data, 'destination.closingDays', '--') || '--',
        },
        {
          key: 'Intervento',
          value: get(data, 'interventionDescription', '--') || '--',
        },
      ];
      return (
        <EditorPopup
          isActiveHover
          displayAction="hover"
          hoverData={
            <div className="col">
              {dataHover.map(row => (
                <div style={row.style ? row.style : {}}>
                  {row.key}: {row.value}
                </div>
              ))}
            </div>
          }
          hasCustom
          position="bottom"
          triggerItem={
            <div>
              <div style={{ marginBottom: 5 }}>
                <Label>{data.state}</Label>{' '}
              </div>
              {data.number}
            </div>
          }
          styles={{
            width: '450px',
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        />
      );
    },
  },
  {
    key: 'description',
    name: intl.formatMessage(messages.typeODL),
    useAlias: 'noteReason',
    formatter: ({ data }) => get(data, 'noteReason.description', null),
    searchable: true,
    sortable: true,
  },
  {
    key: 'note',
    name: intl.formatMessage(messages.note),
    formatter: ({ data }) => get(data, 'note', null),
    searchable: true,
    sortable: true,
  },
  {
    key: 'businessName',
    name: intl.formatMessage(messages.customer),
    useAlias: 'destination',
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const businessName = get(data, 'destination.businessName', null);
      const customerCode = get(data, 'customer.code', null);
      const businessAddress = get(
        data,
        'destination.businessAddressTitle',
        null,
      );

      return (
        <span>
          {customerCode} - {businessName}
          <br />
          {businessAddress}
        </span>
      );
    },
    searchable: true,
    sortable: true,
  },
  {
    key: 'createdAt',
    name: intl.formatMessage(messages.creation_date),
    formatter: ({ data }) =>
      get(data, 'createdAt.date', null)
        ? moment(data.createdAt.date).format('DD/MM/YYYY')
        : null,
    searchable: true,
    sortable: true,
  },
];

export const WorkorderPlannedColumns = intl => [
  {
    width: 60,
    key: 'number',
    name: intl.formatMessage(messages.numberODL),
    searchable: true,
    sortable: true,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const dataHover = [
        {
          key: 'Cliente',
          value: get(data, 'destination.businessName', '--') || '--',
          style: { fontWeight: 'bold' },
        },
        {
          key: 'Indirizzo',
          value: get(data, 'destination.businessAddressTitle', '--') || '--',
        },
        {
          key: 'Contatto',
          value: get(data, 'referent', '--') || '--',
        },
        {
          key: 'Telefono',
          value: get(data, 'destination.phoneNumber', '--') || '--',
        },
        {
          key: 'Consegne',
          value: get(data, 'destination.delivery', '--') || '--',
        },
        {
          key: 'Chiusura',
          value: get(data, 'destination.closingDays', '--') || '--',
        },
        {
          key: 'Intervento',
          value: get(data, 'interventionDescription', '--') || '--',
        },
      ];
      return (
        <EditorPopup
          isActiveHover
          displayAction="hover"
          hoverData={
            <div className="col">
              {dataHover.map(row => (
                <div style={row.style ? row.style : {}}>
                  {row.key}: {row.value}
                </div>
              ))}
            </div>
          }
          hasCustom
          position="bottom"
          triggerItem={
            <div>
              <div style={{ marginBottom: 5 }}>
                <Label>{data.state}</Label>{' '}
              </div>
              {data.number}
            </div>
          }
          styles={{
            width: '450px',
            paddingTop: '5px',
            paddingBottom: '5px',
          }}
        />
      );
    },
  },
  {
    key: 'description',
    name: intl.formatMessage(messages.typeODL),
    formatter: ({ data }) => get(data, 'noteReason.description', null),
    searchable: true,
    sortable: true,
  },
  {
    key: 'note',
    name: intl.formatMessage(messages.note),
    formatter: ({ data }) => get(data, 'note', null),
    searchable: true,
    sortable: true,
  },
  {
    key: 'businessName',
    name: intl.formatMessage(messages.customer),
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const businessName = get(data, 'destination.businessName', null);
      const customerCode = get(data, 'customer.code', null);
      const businessAddress = get(
        data,
        'destination.businessAddressTitle',
        null,
      );

      return (
        <span>
          {customerCode} - {businessName}
          <br />
          {businessAddress}
        </span>
      );
    },
    searchable: true,
    sortable: true,
  },
  {
    key: 'displayName',
    name: intl.formatMessage(messages.technical),
    formatter: ({ data }) => get(data, 'staff.displayName', null),
    searchable: true,
    sortable: true,
  },
  {
    key: 'createdAt',
    name: (
      <span>
        {intl.formatMessage(messages.creation_date)}
        <br />
        {intl.formatMessage(messages.planned_date)}
      </span>
    ),
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => {
      const createdAt = get(data, 'createdAt.date', null)
        ? moment(data.createdAt.date).format('DD/MM/YYYY')
        : null;

      const plannedDate = get(data, 'plannedDate.date', null)
        ? moment(data.plannedDate.date).format('DD/MM/YYYY')
        : null;

      return (
        <span>
          {createdAt}
          <br />
          {plannedDate}
        </span>
      );
    },
    searchable: true,
    sortable: true,
  },
];
