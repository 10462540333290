import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import ListManager from 'novigo-entity-grid';
import Modal from '../../../Modal';
import { API } from '../../../../global-constants';
import request from '../../../../shared/services/request';
import { ListWrapper, TableGlobalSearch, WrapperTool } from '../../../Layout';
import messages from '../../../../containers/Offer/messages';
import appMessages from '../../../../containers/App/messages';
import { addNotification } from '../../../../utils/notification';
import OfferService from '../../../../shared/services/offer';
import OfferTypologyService from '../../../../shared/services/offertypology';
import OfferFormModal from '../formParts/offerPart';
import OfferSearch from '../../../../containers/Offer/search';
import { columns } from '../../../../containers/Offer/listColumns';
// import { OFFER_STATE_TO_BE_ACCEPTED } from '../../../../containers/Offer/constants';

function OfferTab(props) {
  const [title, setTitle] = useState(null);
  const [baseEntityData, setBaseEntityData] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [reloadIndex, setReloadIndex] = useState(0);
  const [typology, setTypology] = useState(null);
  const [isCreatingNewOffer, setIsCreatingNewOffer] = useState(false);

  const [filters, setFilters] = useState([]);
  const [initialValues, setInitialValues] = useState([]);
  const [filterLabels, setFilterLabels] = useState([]);
  const [removedFilter, setRemovedFilter] = useState(null);
  const [searchFilter, setSearchFilter] = useState(null);
  const [searchedValue, setSearchedValue] = useState(null);
  const [aliases, setAliases] = useState([
    {
      type: 'leftjoin',
      field: 'destination',
      alias: 'destination',
    },
    {
      type: 'leftjoin',
      field: 'typology',
      alias: 'typology',
    },
    {
      type: 'leftjoin',
      field: 'rows',
      alias: 'offerrow',
    },
    { type: 'eq', field: 'finalized', value: 1 },
  ]);

  const router = useHistory();

  useEffect(
    () => {
      setBaseEntityData({
        _embedded: {
          customer: _.get(props, 'values.customer', {}),
          destination: {
            id: _.get(props, 'values.id', ''),
            businessName: _.get(props, 'values.businessName', ''),
          },
        },
      });
    },
    [props.values.id],
  );

  useEffect(() => {
    getProdottiTypology();
  }, []);

  const closeModal = () => {
    setSelectedItem(null);
    setModalOpened(false);
    setTitle(null);
  };

  const getProdottiTypology = () => {
    OfferTypologyService.searchByName('Prodotti').then(res => {
      const prodottiTypology = _.get(
        res,
        'data._embedded.offer-typology[0].id',
        null,
      );
      setTypology(prodottiTypology);
    });
  };

  const onCreateOffer = () => {
    setIsCreatingNewOffer(true);
    OfferService.create({
      typology,
      customer: _.get(baseEntityData, '_embedded.customer.value', null),
      destination: _.get(baseEntityData, '_embedded.destination.id', null),
      state: 'Inserita',
    })
      .then(res => {
        props.history.push(`${API.OFFER}/${res.data.id}/new`);
      })
      .catch(err =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      )
      .finally(() => {
        setIsCreatingNewOffer(false);
      });
  };

  const onRemoveFilter = val => {
    const newFilterLabels = filterLabels.filter(el => el.filterField !== val);
    const newFilters = filters.filter(el => el.field !== val);
    if (val === 'outcome') {
      newFilters.filter(o => o.type !== 'orx');
    }

    _.unset(initialValues, val);

    setFilterLabels(newFilterLabels);
    setFilters(newFilters);
    setRemovedFilter(prevRemovedFilter => (prevRemovedFilter ? '' : val));
  };

  const onDefaultSearch = (val, searchFilters) => {
    setSearchedValue(val);
    setSearchFilter(searchFilters);
  };

  /**
   * Refactred to correctly update the aliases so other states can be shown
   * @param {*} filters
   * @param {*} values
   */
  const handleSearch = (searchFilters, values, labels) => {
    const filterData = searchFilters.find(item => item.field === 'state');

    const updatedAliases = aliases.map((alias, i) => {
      if (i === aliases.length - 1) {
        return filterData;
      }
      return alias;
    });

    // handle filters normally
    if (searchFilters.length) {
      return new Promise(resolve => {
        setFilters(searchFilters);
        setInitialValues(values);
        setAliases(updatedAliases);
        setFilterLabels(labels);
        resolve(true);
      });
    }

    // handle filters if reset
    return new Promise(resolve => {
      // const resetAliases = aliases.map(el => {
      //   if (el.field === 'state') {
      //     return {
      //       type: 'eq',
      //       field: 'state',
      //       value: OFFER_STATE_TO_BE_ACCEPTED,
      //     };
      //   }
      //   return el;
      // });

      // setAliases(resetAliases);
      // setFilters([
      //   {
      //     type: 'eq',
      //     field: 'state',
      //     value: OFFER_STATE_TO_BE_ACCEPTED,
      //   },
      // ]);
      // setInitialValues({
      //   state: {
      //     value: OFFER_STATE_TO_BE_ACCEPTED,
      //     label: OFFER_STATE_TO_BE_ACCEPTED,
      //   },
      // });
      // setFilterLabels([
      //   {
      //     filterField: 'state',
      //     key: 'state',
      //     name: 'State',
      //     value: OFFER_STATE_TO_BE_ACCEPTED,
      //   },
      // ]);
      resolve(true);
    });
  };

  return (
    <ListWrapper>
      <TableGlobalSearch>
        <ListManager
          entityName="offer"
          locale={_.get(props, 'user.defaultLanguage', 'it')}
          basePath={API.BASE_URL}
          permissions={props.user}
          router={props}
          reloadIndex={reloadIndex}
          aliases={aliases}
          client={pars => request(pars).then(res => res.data)}
          customSearchFilters={filters}
          filterLabels={filterLabels}
          onRemoveFilter={onRemoveFilter}
          searchFilter={searchFilter}
          searchedValue={searchedValue}
          onDefaultSearch={onDefaultSearch}
          canSearchCustom={OfferSearch({
            intl: props.intl,
            filters,
            initialValues,
            removedFilter,
            onRemoveFilter,
            onSearch: (filter, values, labels) =>
              handleSearch(filter, values, labels),
            preSelectState: false,
          })}
          columns={columns({ intl: props.intl })}
          canCreate={{
            active: false,
          }}
          canExport={{
            active: false,
          }}
          defaultActions={{
            visualize: false,
            modify: false,
            delete: false,
            grouped: false,
            moreActions: [
              {
                icon: 'eye',
                label: 'view element',
                key: 'viewRelatedData',
                action: data => {
                  router.push(`/offer/${data.id}/view`);
                },
              },
              {
                icon: 'edit',
                label: 'edit element',
                key: 'editRelatedData',
                action: data => {
                  router.push(`/offer/${data.id}/modify`);
                },
              },
              {
                icon: 'trash',
                label: 'delete element',
                key: 'deleteRelatedData',
                color: 'red',
                action: data => {
                  OfferService.remove(data.id)
                    .then(() => {
                      setReloadIndex(reloadIndex + 1);
                      addNotification({
                        title: null,
                        message: props.intl.formatMessage(
                          messages.subject_removed_success,
                        ),
                        isError: false,
                      });
                    })
                    .catch(error =>
                      addNotification({
                        title: null,
                        message: error.data.detail,
                        isError: true,
                      }),
                    );
                },
              },
            ],
          }}
          persistentFilter={[
            {
              type: 'eq',
              field: 'destination',
              value: props.values.id,
            },
            {
              type: 'groupby',
              field: 'id',
            },
          ]}
          canSelect={{
            active: false,
            selectAll: false,
            actions: [
              {
                icon: 'plus',
                color: 'blue',
                label: props.intl.formatMessage(messages.new_offer),
                onClick: onCreateOffer,
                isLoading: isCreatingNewOffer,
              },
            ],
          }}
        />
      </TableGlobalSearch>
      <WrapperTool key="wrapper">
        <Modal
          open={modalOpened}
          onClose={() => {
            closeModal();
          }}
          title={
            <h2>
              <Icon name="calendar check outline" />{' '}
              {props.intl.formatMessage(
                title === 'modify' ? messages.edit_offer : messages.new_offer,
              )}
            </h2>
          }
          size="small"
          scrolling
        >
          <OfferFormModal
            entityId={props.values.id}
            entity={props.entity}
            close={closeModal}
            intl={props.intl}
            initialValues={selectedItem || baseEntityData}
            service={OfferService}
            update={() => setReloadIndex(reloadIndex + 1)}
          />
        </Modal>
      </WrapperTool>
    </ListWrapper>
  );
}

OfferTab.defaultProps = {
  values: {},
};

OfferTab.propTypes = {
  values: PropTypes.object,
  user: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  entity: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default injectIntl(OfferTab);
