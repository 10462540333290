/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'semantic-ui-react';

const IconButton = ({ action: actionObject, data }) => {
  const {
    icon = 'external alternate',
    color = 'blue',
    action = null,
  } = actionObject;
  return (
    <Icon
      style={{ cursor: 'pointer' }}
      circular
      inverted
      name={icon}
      color={color}
      onClick={() => action(data)}
    />
  );
};

IconButton.defaultProps = {
  data: null,
};

IconButton.propTypes = {
  action: PropTypes.object.isRequired,
  data: PropTypes.object,
};

export default IconButton;
