import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import Select from 'react-select';
import SimpleFormikField from 'novigo-simple-formik-field';
import { Grid, Form } from 'semantic-ui-react';
import { PaddedColumn } from '../../Layout';
import messages from '../Anagrafica/messages';
// import FormikSelect from '../../FormikSelect';
import FormikField from '../../FormikField';
import FormikTelephone from '../../FormikTelephone';

export const BaseForm = props => (
  <Form
    autoComplete="off"
    onSubmit={props.handleSubmit}
    loading={props.isSubmitting}
  >
    <Grid columns="equal">
      <Grid.Row>
        <PaddedColumn width={8}>
          {/* <FormikSelect
            label={props.intl.formatMessage(messages.contact_type)}
            defaultValue={{
              value: _.get(props, 'values.contactTypeId.id', ''),
              label: _.get(props, 'values.contactTypeId.description', ''),
            }}
            options={props.contactTypes}
            name="contactTypeId"
          /> */}
          <FormikField
            name="contactTypeId"
            label={props.intl.formatMessage(messages.contact_type)}
            {...props}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              value={props.values.contactTypeId}
              name="contactTypeId"
              options={props.contactTypes}
              onChange={data => props.setFieldValue('contactTypeId', data)}
            />
          </FormikField>
        </PaddedColumn>
        <PaddedColumn width={8} />
        <PaddedColumn width={8}>
          <SimpleFormikField
            {...props}
            name="firstName"
            label={props.intl.formatMessage(messages.name)}
          />
        </PaddedColumn>
        <PaddedColumn width={8}>
          <SimpleFormikField
            {...props}
            name="lastName"
            label={props.intl.formatMessage(messages.surname)}
          />
        </PaddedColumn>
        <PaddedColumn width={8}>
          <FormikTelephone
            {...props}
            name="phoneNumber"
            label={props.intl.formatMessage(messages.phone)}
          />
        </PaddedColumn>
        <PaddedColumn width={8}>
          <FormikTelephone
            {...props}
            name="mobileNumber"
            label={props.intl.formatMessage(messages.mobile)}
          />
        </PaddedColumn>
        <PaddedColumn width={8}>
          <SimpleFormikField {...props} name="email" label="Email" />
        </PaddedColumn>
        <PaddedColumn width={8}>
          <SimpleFormikField {...props} name="emailPec" label="EmailPec" />
        </PaddedColumn>
      </Grid.Row>
    </Grid>
  </Form>
);

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BaseForm);
