import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAllByCustomerId = entityId =>
  request({
    baseURL: API.BASE_URL,
    url: API.CUSTOMER_ATTACHMENT,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'entityId',
          value: entityId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const upload = ({ entityType, entityId, entityName, file }) => {
  const bodyFormData = new FormData();
  bodyFormData.set('params[entity_type]', entityType);
  bodyFormData.set('params[entity_id]', entityId);
  bodyFormData.set('params[entity_name]', entityName);
  bodyFormData.set('file', file);
  bodyFormData.set('method', 'upload');
  return request({
    baseURL: API.BASE_URL,
    url: API.ATTACHMENTS,
    method: 'POST',
    data: bodyFormData,
  });
};

const uploadMultiple = ({ entityType, entityId, entityName, files }) => {
  const bodyFormData = new FormData();
  bodyFormData.set('params[entity_type]', entityType);
  bodyFormData.set('params[entity_id]', entityId);
  bodyFormData.set('params[entity_name]', entityName);
  files.map(file => bodyFormData.append('file[]', file));
  // bodyFormData.append('file[]', files);
  bodyFormData.set('method', 'upload');
  bodyFormData.set('params[isMultiple]', 1);
  return request({
    baseURL: API.BASE_URL,
    url: API.ATTACHMENTS,
    method: 'POST',
    data: bodyFormData,
  });
};

const download = id => {
  const bodyFormData = new FormData();
  bodyFormData.set('params[id]', id);
  bodyFormData.set('method', 'download');

  return request({
    baseURL: API.BASE_URL,
    url: API.ATTACHMENTS,
    method: 'POST',
    responseType: 'blob',
    data: bodyFormData,
  });
};

const downloadMultiple = data => {
  const bodyFormData = new FormData();
  bodyFormData.set('params', data);
  bodyFormData.set('method', 'download');

  return request({
    baseURL: API.BASE_URL,
    url: API.ATTACHMENTS,
    method: 'POST',
    // responseType: 'blob',
    data: {
      method: 'download-multiple',
      params: data,
    },
  });
};

const remove = id => {
  const bodyFormData = new FormData();
  bodyFormData.set('params[id]', id);
  bodyFormData.set('method', 'delete');

  request({
    baseURL: API.BASE_URL,
    url: `${API.ATTACHMENTS}`,
    method: 'POST',
    data: bodyFormData,
  });
};
const AttachmentService = {
  getAllByCustomerId,
  upload,
  download,
  uploadMultiple,
  downloadMultiple,
  remove,
};

export default AttachmentService;
