import React, { useState } from 'react';
import SimpleFormikField from 'novigo-simple-formik-field';
import CurrencyFormat from 'react-currency-format';
import _ from 'lodash';
import PropTypes from 'prop-types';

function CurrencyFormatter(props) {
  const [currentValue, setCurrentValue] = useState('');
  const [touched, setTouched] = useState(false);

  /**
   * clean the value before send to the component
   * @param {*} value
   */
  const getInternalValue = value => {
    setTouched(true);
    if (_.isNaN(value) || value === null) {
      return '';
    }
    return value;
  };

  const getExternalValue = field => {
    if (_.get(field, 'value') !== undefined && _.get(field, 'value') !== null) {
      setTouched(true);
      setCurrentValue(+_.get(field, 'value'));
      return +_.get(field, 'value');
    }
    return '';
  };

  const onBlurAction = setFieldValue =>
    currentValue || currentValue === 0
      ? setFieldValue(props.name, currentValue)
      : setFieldValue(props.name, null);
  return (
    <SimpleFormikField name={props.name} label={props.label} {...props}>
      {({ field, form: { setFieldValue } }) => (
        <CurrencyFormat
          name={props.name}
          value={
            touched
              ? getInternalValue(currentValue)
              : getExternalValue(props.initialValue || field)
          }
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          prefix="% "
          allowNegative={props.allowNegative}
          onValueChange={valuesObj => {
            if (props.onChange) props.onChange(valuesObj);
            setTouched(true);
            const { floatValue } = valuesObj; // also formattedValue, value..
            setCurrentValue(floatValue);
          }}
          onBlur={() =>
            props.onChange
              ? props.onChange(currentValue || 0)
              : onBlurAction(setFieldValue)
          }
        />
      )}
    </SimpleFormikField>
  );
}

CurrencyFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default CurrencyFormatter;
