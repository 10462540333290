/* eslint-disable prettier/prettier */
import _ from 'lodash';
import qs from 'qs';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import messages from '../../../../OfferForm/messages';
// import NoteSection from './formParts/NoteSection';
import OfferStateSection from '../../../../OfferForm/manageForm/formParts/offerState';
import { Wrapper } from '../../../../../Layout';
import request from '../../../../../../shared/services/request';
import currencyFormatter, {
  numberFormatter,
} from '../../../../../../utils/currencyFormatter';
import percentageFormatter from '../../../../../../utils/percentageFormatter';
import {
  isSellServicesOffer,
} from '../../../../../../containers/Offer/constants';

const BaseForm = props => {
  const [offerRows, setOfferRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { setValues, values } = props;
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
    pageSize: 5,
    totalItems: 0,
  });

  useEffect(() => {
    loadData(1);
  }, []);

  const loadData = page => {
    setLoading(true);
    request({
      url: _.get(values, '_embedded.rows._links.self.href'),
      method: 'GET',
      params: {
        page,
        pageSize: 5,
        'order-by': [
          {
            type: 'field',
            field: 'createdAt',
            direction: 'asc',
          },
        ],
      },
      paramsSerializer: params => qs.stringify(params),
    }).then(response => {
      setLoading(false);
      setPagination({
        page:
          _.get(response, 'data.page') === 0 ? 1 : _.get(response, 'data.page'),
        pageCount: _.get(response, 'data.page_count', 1),
        pageSize: _.get(response, 'data.page_size', 0),
        totalItems: _.get(response, 'data.total_items', 0),
      });
      const offer = _.get(response, 'data._embedded.offer-row[0].offer');
      setValues({
        ...values,
        avarageDiscount: _.get(offer, 'avarageDiscount', null),
        avarageMarkup: _.get(offer, 'avarageMarkup', null),
        totalPriceAmount: _.get(offer, 'totalPriceAmount', null),
      });
      setOfferRows(_.get(response, `data._embedded.offer-row`), []);
    });
  };

  const getElements = () => offerRows;

  const columns = () => {
    const offerRowColumns = [
      {
        key: 'name',
        width: '200px',
        name: props.intl.formatMessage(messages.article),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div>
            <div>
              {_.get(data, 'product.productCode', '')}
              {data.product &&
                data.product.productCode &&
                data.product.title &&
                ' - '}
              {_.get(data, 'product.title', '')}
            </div>
            <div>
              {`${_.get(data, 'product.category.description', '')}`}
              {data.product && data.product.subcategory.description && ' > '}
              {_.get(data, 'product.subcategory.description', '')}
              {data.product && data.product.domain.description && ' > '}
              {_.get(data, 'product.domain.description', '')}
            </div>
          </div>
        ),
      },
      {
        key: 'competitorNote',
        width: '150px',
        name: props.intl.formatMessage(messages.competitorNote),
        formatter: ({ data }) => _.get(data, 'competitorNote', ''),
      },
      {
        key: 'buyPrice',
        name: props.intl.formatMessage(messages.buying_price),
        formatter: ({ data }) =>
          data.buyPrice
            ? currencyFormatter.format(_.get(data, 'buyPrice', ''))
            : '--',
      },
      {
        key: 'competitorPrice',
        name: props.intl.formatMessage(messages.competitor_price),
        formatter: ({ data }) =>
          data.competitorPrice
            ? currencyFormatter.format(_.get(data, 'competitorPrice', ''))
            : '--',
      },
      {
        key: 'grossPrice',
        name: props.intl.formatMessage(messages.unit_gross_price),
        formatter: ({ data }) =>
          data.grossPrice
            ? currencyFormatter.format(_.get(data, 'grossPrice', ''))
            : '--',
      },
      {
        key: 'productCustomerPrice',
        name: props.intl.formatMessage(messages.productCustomerPrice),
        formatter: ({ data }) =>
          data.productCustomerPrice
            ? currencyFormatter.format(_.get(data, 'productCustomerPrice', ''))
            : '--',
      },
      {
        key: 'discountType',
        width: '200px',
        name: props.intl.formatMessage(messages.discountTypeTable),
        formatter: ({ data }) => {
          const discountType = _.get(data, '_embedded.discountType.code') ? props.intl.formatMessage(messages[_.get(data, '_embedded.discountType.code', '')]) : '--';
          return discountType;
        },
      },
      {
        key: 'discount',
        width: '150px',
        name: props.intl.formatMessage(messages.discount),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div>
            {data.discountValue ? percentageFormatter.format(_.get(data, 'discountValue', '') / 100,) : ''}
            {data.discountValue && data.extraDiscount && ' + '}
            {data.extraDiscount ? percentageFormatter.format(_.get(data, 'extraDiscount', '') / 100) : ''}
          </div>
        ),
      },
      {
        key: 'netPrice',
        name: props.intl.formatMessage(messages.unit_net_price),
        formatter: ({ data }) =>
          data.netPrice
            ? currencyFormatter.format(_.get(data, 'netPrice', ''))
            : '--',
      },
      {
        key: 'minQuantity',
        width: '150px',
        name: props.intl.formatMessage(messages.min_quantity),
        formatter: ({ data }) =>
          data.minQuantity ? (`${numberFormatter.format(_.get(data, 'minQuantity'))} pz`) : ('--'),
      },
      {
        key: 'priceAmount',
        name: props.intl.formatMessage(messages.priceAmount),
        formatter: ({ data }) =>
          data.priceAmount
            ? currencyFormatter.format(_.get(data, 'priceAmount', ''))
            : '--',
      },
      {
        key: 'markup',
        name: props.intl.formatMessage(messages.markup),
        formatter: ({ data }) =>
          data.markup
            ? percentageFormatter.format(_.get(data, 'markup', '') / 100)
            : '--',
      },
      {
        key: 'competitorMarkup',
        name: props.intl.formatMessage(messages.competitorMarkup),
        formatter: ({ data }) =>
          data.competitorMarkup
            ? percentageFormatter.format(
              _.get(data, 'competitorMarkup', '') / 100,
            )
            : '--',
      },
      {
        key: 'difference',
        name: props.intl.formatMessage(messages.difference),
        formatter: ({ data }) =>
          data.difference
            ? percentageFormatter.format(_.get(data, 'difference', '') / 100)
            : '--',
      },
      {
        key: 'goodsDiscount',
        name: props.intl.formatMessage(messages.goods_discount),
        formatter: ({ data }) =>
          data.goodsDiscount ? (
            props.intl.formatMessage(messages.yes)
          ) : (
            props.intl.formatMessage(messages.no)
          ),
      },
      {
        key: 'loan',
        name: props.intl.formatMessage(messages.loan),
        formatter: ({ data }) =>
          data.loan ? (
            props.intl.formatMessage(messages.yes)
          ) : (
            props.intl.formatMessage(messages.no)
          ),
      },
    ];

    // new fields when offer sells only services
    if (isSellServicesOffer(_.get(values, '_embedded.typology.name'))) {
      offerRowColumns.push({
        key: 'frequency',
        name: props.intl.formatMessage(messages.frequency),
        formatter: ({ data }) =>
          _.get(data, '_embedded.frequency', null)
            ? _.get(data, '_embedded.frequency.description')
            : '--',
      });

      offerRowColumns.push({
        key: 'amount',
        name: props.intl.formatMessage(messages.amount),
        formatter: ({ data }) =>
          data.amount
            ? currencyFormatter.format(_.get(data, 'amount', ''))
            : '--',
      });
    }

    return offerRowColumns;
  };

  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting}
    >
      <OfferStateSection {...props} isDisabled />
      <Wrapper style={{ margin: '1rem 0' }}>
        <div style={{ width: '100%', display: 'block', overflowX: 'scroll' }}>
          <Table
            isLoading={isLoading}
            elements={getElements()}
            rowClassKey="role-row-class"
            columns={columns()}
            canSort={{
              active: true,
            }}
            canSelect={{
              active: false,
            }}
            canPaginate={{
              active: true,
              pageCount: pagination.pageCount,
              pageSize: pagination.pageSize,
              totalItems: pagination.totalItems,
              page: pagination.page,
              onSelect: pageNumber => {
                setPagination({ ...pagination, page: pageNumber });
                loadData(pageNumber);
              },
              pageMax: pagination.pageCount,
            }}
            canAction={{
              active: true,
              actions: [
                <Grid stackable style={{ marginBottom: 0 }}>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ marginLeft: 10 }}>
                          {`${props.intl.formatMessage(messages.total_offer,)} € ${values.totalPriceAmount? parseFloat(values.totalPriceAmount).toFixed(2): 0} -
                              ${props.intl.formatMessage(messages.avarageDiscount,)} % ${values.avarageDiscount? parseFloat(values.avarageDiscount).toFixed(2): 0} -
                              ${props.intl.formatMessage(messages.avarageMarkup,)} % ${values.avarageMarkup? parseFloat(values.avarageMarkup).toFixed(2): 0}`}
                        </span>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>,
              ],
            }}
            hiddenHeaderIfEmpty
            emptyResults={<div>No results!</div>}
          />
        </div>
      </Wrapper>
    </Form>
  );
};
BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BaseForm);
