import styled from 'styled-components';

export const TableWrap = styled.div`
  .transparent {
    background-color: transparent !important;
    color: transparent !important;
    padding: 0;

    .buttons {
      flex-direction: row-reverse;

      .button {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    }

    .ui.buttons > .ui.dropdown:last-child .menu,
    .ui.menu .right.dropdown.item .menu,
    .ui.menu .right.menu .dropdown:last-child .menu {
      left: auto;
      right: auto;
    }
  }
`;
