import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form } from 'semantic-ui-react';
import { PaddedColumn } from '../../Layout';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import CurrencyFormatter from '../../CurrencyFormatter';
import PercentageFormatter from '../../PercentageFormatter';
import messages from './messages';
import FormikToggle from '../../FormikToggle';
import ProfileService from '../../../shared/services/profile';

export const BaseForm = props => (
  <Form
    autoComplete="off"
    onSubmit={props.handleSubmit}
    loading={props.isSubmitting}
  >
    <Grid columns="equal">
      <Grid.Row columns={3}>
        <PaddedColumn>
          <FormikDate
            required
            {...props}
            name="startDate"
            label={props.intl.formatMessage(messages.commissionStartDate)}
          />
        </PaddedColumn>
        <PaddedColumn>
          <FormikDate
            required
            {...props}
            name="endDate"
            label={props.intl.formatMessage(messages.commissionEndDate)}
          />
        </PaddedColumn>
        <PaddedColumn>
          <FormikAsyncSelect
            required
            {...props}
            name="agent"
            label={props.intl.formatMessage(messages.agent)}
            loadOptions={(searchText, callback) =>
              ProfileService.getAgentOptions(searchText, callback)
            }
          />
        </PaddedColumn>
      </Grid.Row>
      <Grid.Row columns={3}>
        <PaddedColumn>
          {props.isAmount ? (
            <CurrencyFormatter
              {...props}
              name="fromAmount"
              label={props.intl.formatMessage(messages.discountFrom)}
            />
          ) : (
            <PercentageFormatter
              {...props}
              name="fromPercentage"
              label={props.intl.formatMessage(messages.discountFrom)}
            />
          )}
        </PaddedColumn>
        <PaddedColumn>
          {props.isAmount ? (
            <CurrencyFormatter
              {...props}
              name="toAmount"
              label={props.intl.formatMessage(messages.discountTo)}
            />
          ) : (
            <PercentageFormatter
              {...props}
              name="toPercentage"
              label={props.intl.formatMessage(messages.discountTo)}
            />
          )}
        </PaddedColumn>
        <PaddedColumn>
          <PercentageFormatter
            {...props}
            name="commission"
            label={props.intl.formatMessage(messages.commission)}
          />
        </PaddedColumn>
        <PaddedColumn>
          <PercentageFormatter
            {...props}
            name="refund"
            label={props.intl.formatMessage(messages.refund)}
          />
        </PaddedColumn>
        {!props.isAmount && (
          <PaddedColumn>
            <FormikToggle
              {...props}
              name="includedInBonus"
              label={props.intl.formatMessage(messages.award)}
            />
          </PaddedColumn>
        )}
      </Grid.Row>
    </Grid>
  </Form>
);

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BaseForm);
