function Validation(values) {
  const errors = {};

  /**
   * The customer Type should be selected
   */
  if (!values.registryType) {
    errors.registryType = {
      wrongTipology: 'Al fornitore deve essere attribuita una tipologia',
    };
  }

  /**
   * Is a legal person, so the name of the business MUST TO BE NOT MEPTY
   */
  if (values.registryType === 'L' && !values.businessName) {
    errors.businessName = { required: 'Required' };
  }

  /**
   * If is an individual person, the name and the lastname of customer MUST NOT TO BE EMPTY
   */
  if (values.registryType === 'I' && !values.lastName) {
    errors.lastName = { required: 'Required' };
  }

  return errors;
}

export default Validation;
