import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { Modal, Button, Icon, Checkbox, Message } from 'semantic-ui-react';
import SemanticTableGrid from 'semantic-table-grid';
import _ from 'lodash';
import moment from 'moment';
import { usePrevious } from '../../../../shared/lib/common';
import appMessages from '../../../../containers/App/messages';
import messages from '../messages';

const isChecked = (data, selectedValue) => {
  if (
    data &&
    selectedValue &&
    Object.keys(data).length > 0 &&
    Object.keys(selectedValue).length > 0
  ) {
    if (`${data.CrifNumber}` === selectedValue) return true;
  }
  return false;
};

const csTableColumns = props => [
  {
    key: 'check',
    name: <FormattedMessage {...appMessages.actions} />,
    sortable: false,
    // eslint-disable-next-line react/prop-types
    formatter: ({ data }) => (
      <Checkbox
        name={`${data.CrifNumber}`}
        // eslint-disable-next-line react/prop-types
        onChange={() => props.handleSelect(data)}
        // eslint-disable-next-line react/prop-types
        checked={isChecked(data, props.selectedValue)}
        disabled={false}
        style={{ marginLeft: 'calc(50% - 9px)' }}
      />
    ),
  },
  {
    key: 'REA',
    name: <FormattedMessage {...messages.rea_code} />,
    sortable: false,
  },
  {
    key: 'CompanyName',
    name: <FormattedMessage {...messages.company_name} />,
    sortable: false,
    formatter: ({ data }) => _.get(data, 'CompanyName', '--'),
  },
  {
    key: 'CompanyAddress',
    name: <FormattedMessage {...appMessages.address} />,
    sortable: false,
    formatter: ({ data }) => (
      <Message info>
        <Message.Content style={{ marginTop: '5px' }}>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              flexBasis: 'auto',
              justifyContent: 'space-between',
            }}
          >
            <span>
              <strong>
                <FormattedMessage {...appMessages.city} />:{' '}
              </strong>
              <br />
              {_.get(data, 'Town')}
            </span>
            <span>
              <strong>
                <FormattedMessage {...appMessages.province} />:{' '}
              </strong>
              <br /> {_.get(data, 'ProvinceDescription')}
            </span>
            <span>
              <strong>
                <FormattedMessage {...appMessages.postal_code} />:{' '}
              </strong>
              <br /> {_.get(data, 'Zipcode')}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              flexBasis: 'auto',
              justifyContent: 'space-between',
              marginTop: '3px',
            }}
          >
            <span>
              <strong>
                <FormattedMessage {...appMessages.address} />:{' '}
              </strong>{' '}
              {_.get(data, 'Address')}
            </span>
          </div>
        </Message.Content>
      </Message>
    ),
  },
  {
    key: 'CompanyStatusDescription',
    name: <FormattedMessage {...appMessages.state} />,
    sortable: false,
  },
  {
    key: 'LegalFormDescription',
    name: <FormattedMessage {...messages.activity_status} />,
    sortable: false,
  },
  {
    key: 'UnitTypeDescription',
    name: <FormattedMessage {...messages.office_type} />,
    sortable: false,
  },
  {
    key: 'LastBalanceDate',
    name: <FormattedMessage {...messages.last_update} />,
    sortable: false,
    formatter: ({ data }) =>
      moment(_.get(data, 'LastBalanceDate')).format('DD/MM/YYYY hh:mm:ss'),
  },
];

const CribisCompaniesTable = ({ listData, onSelectChange, setOptionData }) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const prevSelectedValue = usePrevious(selectedValue);

  const handleSelect = data => {
    setSelectedValue(`${data.CrifNumber}`);
    setOptionData(data);
  };

  useEffect(
    () => {
      if (prevSelectedValue !== selectedValue) {
        onSelectChange(selectedValue);
      }
    },
    [selectedValue],
  );

  return (
    <SemanticTableGrid
      isLoading={false}
      elements={listData}
      rowClassKey="rowClassKey"
      columns={csTableColumns({ handleSelect, selectedValue })}
      canSort={{
        active: false,
      }}
      canSelect={false}
      canPaginate={{
        active: false,
      }}
      canAction={{
        active: false,
      }}
      emptyResults={
        <div>
          <FormattedMessage {...appMessages.no_results} />
        </div>
      }
    />
  );
};

const CribisCompaniesModal = props => {
  const { open, companies, onClose, onSave } = props;
  const [value, setValue] = useState(null);
  const [optionData, setOptionData] = useState([]);
  return (
    <Modal size="large" open={open}>
      <Modal.Header>
        <h3>
          <FormattedMessage {...messages.company_selection} />
        </h3>
      </Modal.Header>
      <Modal.Content>
        <CribisCompaniesTable
          listData={companies}
          onSelectChange={data => setValue(data)}
          onSelectSetData={setOptionData}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()} icon labelPosition="left">
          <Icon name="remove" />
          <FormattedMessage {...appMessages.close} />
        </Button>
        <Button
          onClick={() => onSave(value, optionData)}
          icon
          labelPosition="left"
          type="button"
          primary
        >
          <Icon name="save" />
          <FormattedMessage {...appMessages.save} />
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

CribisCompaniesModal.propTypes = {
  intl: intlShape.isRequired,
  open: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default injectIntl(CribisCompaniesModal);
