import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectStyles } from '../../containers/App/selectors';

export const EmptyMessage = ({ children, styles }) => {
  const style = {
    color: styles.LIGHT_GREY,
    textAlign: 'center',
    fontStyle: 'italic',
  };

  return <div style={style}>{children}</div>;
};

const mapStateToProps = createStructuredSelector({
  styles: makeSelectStyles(),
});

const withConnect = connect(mapStateToProps);

EmptyMessage.propTypes = {
  children: PropTypes.string,
  styles: PropTypes.object,
};

export default compose(withConnect)(EmptyMessage);
