/* eslint-disable no-underscore-dangle */
/* eslint-disable import/order */
/**
 *
 * NotesHub
 *
 */

/*
 * Specific import
 */
import _ from 'lodash';
import moment from 'moment';
import FormManager from 'novigo-form-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Header, Icon, Dimmer, Loader } from 'semantic-ui-react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import BaseSearch from '../../components/BaseSearch';
import FilterBox from '../../components/FilterBox';
import NoteForm from '../../components/Forms/CustomerNote';
import NotesHubForm, {
  validation,
} from '../../components/Forms/NotesHubForm/NotesHub';
import { Page, WrapperTool } from '../../components/Layout';
import Modal from '../../components/Modal';
import ConfirmModal from '../../components/Modal/confirmModal';
import PermissionManager from '../../components/Permission';
import { SharedSearchBar } from '../../components/SearchBars/NoteHub';
import { getFilterData } from '../../components/SearchBars/NoteHub/functions';
import { API } from '../../global-constants';
import NoteService from '../../shared/services/note';
import DestinationNoteService from '../../shared/services/destinationnote';
import request from '../../shared/services/request';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import { addNotification } from '../../utils/notification';
import appMessages from '../App/messages';
import { getNoteService } from './constants';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import makeSelectNotesHub from './selectors';
import NoteTable from './_partial/Table';
import { downloadFileFromAjaxResponse } from '../../utils/functions';
import AnagraficaMessages from '../../components/Forms/Anagrafica/messages';
import { store } from '../../configureStore';
import NoteExecutionForm from '../../components/Forms/CustomerExecutionNote';
import StaffWorkorderService from '../../shared/services/staffworkorder';

/* eslint-disable react/prefer-stateless-function */
export class NotesHub extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      reloadIndex: 0,
      selectedEntity: {},
      openNotesModal: false,
      openConfirmModal: false,
      openExecutionNoteModal: false,
      confirmIsProcessing: false,
      noteModalInReadOnly: false,
      noteTypes: [],
      noteReasons: [],
      loading: false,
      filters: [],
      filterLabels: [],
      initialValues: {},
      pagination: {
        page: 1,
        pageSize: 10,
      },
      data: {},
      closureNoteOptions: [],
      isExecutingNote: false,
    };
    this.userObj = {};
    this.defaultFilters = [];
    this.defaultLabels = [];
    this.defaultValues = {};
  }

  /**
   * Evaluate if you need a form or a List
   */
  evaluateAction() {
    const { action, id } = _.get(this.props, 'match.params');
    const needForm =
      _.indexOf(['view', 'delete', 'modify'], action) !== -1 || id === 'new';
    this.setState({ showForm: needForm });
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'NotesHub',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  /**
   * If you change location revalutate all the View
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.key !== this.props.location.key) {
      this.evaluateAction();
    }

    if (prevState.reloadIndex !== this.state.reloadIndex) {
      this.loadData(this.state.filters, this.state.pagination);
    }
  }

  /**
   * Evaluate which component you need
   */
  componentDidMount() {
    this.evaluateAction();
    this.loadDefaultFilters();

    NoteService.getNoteTypeOptions().then(res => {
      this.setState({
        noteTypes: res,
      });
    });
    NoteService.getReasonTypeOptions().then(res => {
      this.setState({
        noteReasons: res,
      });
    });
    DestinationNoteService.getClosureNoteOptions().then(res => {
      this.setState({
        closureNoteOptions: res,
      });
    });
  }

  loadDefaultFilters() {
    const { userIdentity: user } = store
      .getState()
      .get('user')
      .toJS();

    const userLabel = [user.firstName, user.lastName].filter(o => o).join(' ');
    this.userObj = {
      value: user.id,
      label: userLabel,
    };

    this.defaultLabels = [
      {
        key: 'executedAt',
        name: this.context.intl.formatMessage(AnagraficaMessages.noteExecuted),
        value: this.context.intl.formatMessage(AnagraficaMessages.not_done),
        filterField: 'executedAt',
      },
      {
        key: 'assignee',
        name: this.context.intl.formatMessage(AnagraficaMessages.assignee),
        value: userLabel,
        filterField: 'assignee',
      },
      {
        key: 'expireDate',
        name: this.context.intl.formatMessage(AnagraficaMessages.expireToday),
        value: this.context.intl.formatMessage(appMessages.yes),
        filterField: 'expireDate',
      },
    ].filter(f => f);
    this.defaultFilters = [
      {
        type: 'isnull',
        field: 'executedAt',
      },
      {
        type: 'search_note_assignee',
        field: 'assignee',
        value: user.id,
      },
      {
        type: 'eq',
        field: 'expireDate',
        value: moment().format('YYYY-MM-DD'),
      },
    ].filter(f => f);

    /** Gets the stored note type filter */
    const storedNoteTypeFilter = sessionStorage.getItem(
      'notesHubNoteTypeFilter',
    );
    if (storedNoteTypeFilter) {
      const filter = JSON.parse(storedNoteTypeFilter);

      this.defaultFilters.push({
        type: 'eq',
        field: 'id',
        value: filter.value,
        alias: 'noteType',
      });
      this.defaultLabels.push({
        key: 'noteType',
        name: this.context.intl.formatMessage(messages.noteType),
        value: filter.label,
        filterField: 'noteType',
      });
    }

    this.defaultValues = {
      executed: false,
      assignee: this.userObj,
      expireToday: true,
    };

    this.setState(
      {
        filterLabels: this.defaultLabels,
        filters: this.defaultFilters,
        initialValues: this.defaultValues,
      },
      () => {
        this.loadData();
      },
    );
  }

  loadData = () => {
    const { filters, filterLabels, pagination } = this.state;

    const params = { filters, pagination };

    /** Store noteType filter if any */
    const filterNoteType = {};
    filters.forEach(filter => {
      if (filter.alias === 'noteType') {
        filterNoteType.value = filter.value;
      }
    });
    filterLabels.forEach(label => {
      if (label.key === 'noteType') {
        filterNoteType.label = label.value;
      }
    });
    if (filterNoteType.value && filterNoteType.label) {
      sessionStorage.setItem(
        'notesHubNoteTypeFilter',
        JSON.stringify(filterNoteType),
      );
    } else {
      sessionStorage.removeItem('notesHubNoteTypeFilter');
    }

    this.setState({ loading: true });
    // NoteService.getAllNotes(params)
    NoteService.getTodayRPC(params)
      .then(({ data }) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.setState({ data });
      })
      .catch(data =>
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            data,
            'detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      )
      .finally(() => this.setState({ loading: false }));
  };

  handleReset = ({ values, setFieldValue }) => {
    this.setState({ filterLabels: [] });

    _.forOwn(values, (val, key) => {
      if (key === 'executed') {
        setFieldValue('executed', false);
      } else if (key === 'assignee') {
        setFieldValue('assignee', this.userObj);
      } else {
        setFieldValue(key, null);
      }
    });

    this.loadDefaultFilters();
  };

  handleSearch = (values, { setSubmitting }) => {
    const { intl } = this.context;
    const { filterLabels, filters } = getFilterData({ values, intl });

    this.setState(
      {
        filterLabels,
        filters,
        pagination: {
          page: 1,
          pageSize: 10,
        },
      },
      () => {
        setSubmitting(false);
        this.loadData();
      },
    );
  };

  onChangePageSize = (pageSize = 10) => {
    this.setState(
      prev => ({
        pagination: {
          ...prev.pagination,
          pageSize,
          page: 1,
        },
      }),
      () => {
        this.loadData();
      },
    );
  };

  onChangePage = (page = 1) => {
    this.setState(
      prev => ({
        pagination: {
          ...prev.pagination,
          page,
        },
      }),
      () => {
        this.loadData();
      },
    );
  };

  onExport = (format = 'Xlsx', type = 'xlsx', body) => {
    NoteService.exportNotes({ format, type }, body)
      .then(res => {
        downloadFileFromAjaxResponse(res);
      })
      .catch(data =>
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            data,
            'detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      );
  };

  reloadGrid = () => {
    const { reloadIndex } = this.state;
    this.setState({
      reloadIndex: reloadIndex + 1,
    });
  };

  setNotesModalInReadOnly = isReadOnly => {
    this.setState({ noteModalInReadOnly: isReadOnly });
  };

  setSelectedEntity = data =>
    this.setState({ selectedEntity: this.transformData(data) });

  emptySelectedEntity = () => this.setState({ selectedEntity: null });

  openNotesModal = () => this.setState({ openNotesModal: true });

  closeNotesModal = () =>
    this.setState({ openNotesModal: false }, () => this.emptySelectedEntity());

  openExecutionNoteModal = () =>
    this.setState({ openExecutionNoteModal: true });

  closeExecutionNoteModal = () =>
    this.setState({ openExecutionNoteModal: false }, () =>
      this.emptySelectedEntity(),
    );

  openConfirmModal = () => this.setState({ openConfirmModal: true });

  closeConfirmModal = () =>
    this.setState({ openConfirmModal: false, confirmIsProcessing: false }, () =>
      this.emptySelectedEntity(),
    );

  openStaffWorkorder = async data => {
    const item = await StaffWorkorderService.getById(data.staffWorkorder.id);
    const workorder = item.data._embedded['staff-workorder'][0];
    if (workorder.state !== 'Chiuso') {
      this.props.history.push(
        `/staff-workorder/${data.staffWorkorder.id}/modify`,
      );
    } else {
      this.props.history.push(
        `/staff-workorder/${data.staffWorkorder.id}/view`,
      );
    }
  };

  handleDelete = () => {
    const data = this.state.selectedEntity;
    const { intl } = this.context;

    this.setState({
      confirmIsProcessing: true,
    });

    getNoteService(data.customer, data.destination)
      .remove(data.id)
      .then(() => {
        this.reloadGrid();
        this.closeConfirmModal();
        addNotification({
          title: null,
          message: intl.formatMessage(messages.subject_removed_success),
          isError: false,
        });
      })
      .catch(error => {
        this.closeConfirmModal();
        addNotification({
          title: null,
          message: error.data.detail,
          isError: true,
        });
      });
  };

  transformData = data => ({
    ...data,
    expireDate: _.get(data, 'expireDate.date', null)
      ? moment(data.expireDate.date, 'DD-MM-YYYY')
      : null,
    users: _.get(data, 'users', []).map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })),
    assignee: _.get(data, 'assignee.id', null) && {
      value: data.assignee.id,
      label: data.assignee.label,
    },
  });

  /**
   * Sets the given note to be executed.
   * @param {*} data
   */
  toCancelExecutionNote = data => {
    this.setState({ isExecutingNote: true });
    const payload = {
      id: data.id || null,
      executedAt: null,
    };
    getNoteService(data.customer, data.destination)
      .patch(payload)
      .then(() => {
        this.setState({ isExecutingNote: false });
        this.reloadGrid();
      })
      .catch(error => {
        this.setState({ isExecutingNote: false });
        addNotification({
          title: null,
          message: _.get(
            error,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  /**
   * Sets the given note as executed.
   * @param {*} data
   */
  toExecuteNote = data => {
    this.setState({ isExecutingNote: true });
    const config = this.state.closureNoteOptions.find(
      el => el.noteType === data.noteType.description,
    );

    /**
     * If the closure note is enabled for this note type, we display the modal to insert the closure note text
     * otherwise we directly set the selected note as executed
     *
     * The following conditions trigger the closure note flow:
     * - note type (must be enabled into closureNoteOptions)
     * - assignee (must be set)
     * - expire date (must be set)
     */
    if (config.isEnabled && data.assignee && data.expireDate) {
      this.setState({ isExecutingNote: false });
      this.openExecutionNoteModal();
    } else {
      DestinationNoteService.executeNote(data.id, '')
        .then(() => {
          this.setState({ isExecutingNote: false });
          this.reloadGrid();
          addNotification({
            title: null,
            message: this.context.intl.formatMessage(
              messages.note_execute_success,
            ),
            isError: false,
          });
        })
        .catch(error => {
          this.setState({ isExecutingNote: false });
          addNotification({
            title: null,
            message: error.data.detail,
            isError: true,
          });
        });
    }
  };

  onRemoveFilter = filterName => {
    this.setState(
      prev => ({
        filters:
          prev.filters.filter(el => el.field !== filterName) || prev.filters,
        filterLabels:
          prev.filterLabels.filter(el => el.filterField !== filterName) ||
          prev.filterLabels,
        removedFilter: filterName,
      }),
      () => this.reloadGrid(),
    );
  };

  render() {
    const routeParams = _.get(this.props, 'match.params');
    const {
      noteTypes,
      noteReasons,
      openNotesModal,
      openExecutionNoteModal,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>{this.context.intl.formatMessage(messages.NotesHub)}</title>
          <meta
            name="description"
            content={this.context.intl.formatMessage(messages.NotesHub)}
          />
        </Helmet>
        <BreadcrumbsItem to="/NotesHub">
          {this.context.intl.formatMessage(messages.NotesHub)}
        </BreadcrumbsItem>
        <Page>
          <Header as="h2" dividing>
            {this.context.intl.formatMessage(messages.NotesHub)}
          </Header>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/NotesHub/modify">
                {actionToBreadcrumb({
                  action: routeParams.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                key={routeParams.action}
                router={this.props}
                client={pars => request(pars).then(res => res.data)}
                mask={props => (
                  <NotesHubForm
                    {...props}
                    editRoute={`/NotesHub/${routeParams.id}/modify`}
                    history={this.props.history}
                  />
                )}
                validation={validation}
                onCleanData={data => {
                  const cleanData = _.cloneDeep(data);

                  _.unset(cleanData, 'id');
                  _.unset(cleanData, '_links');
                  _.unset(cleanData, '_embedded');

                  _.unset(cleanData, 'createdAt');
                  _.unset(cleanData, 'deletedAt');
                  _.unset(cleanData, 'updatedAt');

                  return cleanData;
                }}
                entityName="NotesHub"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                onError={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  })
                }
                onSuccess={() =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
              />
            </>
          ) : (
            <React.Fragment>
              {openNotesModal && (
                <WrapperTool key="wrapper">
                  <Modal
                    open={this.state.openNotesModal}
                    onClose={() => this.closeNotesModal()}
                    title={
                      <h2>
                        <Icon name="sticky note" />{' '}
                        {this.context.intl.formatMessage(messages.note)}
                      </h2>
                    }
                    size="mini"
                    style={{ width: 650 }}
                    scrolling
                  >
                    <NoteForm
                      entityId={this.state.selectedEntity.id}
                      entity={this.state.selectedEntity.type}
                      noteService={getNoteService(
                        this.state.selectedEntity.customer,
                        this.state.selectedEntity.destination,
                      )}
                      noteTypes={noteTypes}
                      noteReasons={noteReasons}
                      close={this.closeNotesModal}
                      initialValues={this.state.selectedEntity}
                      update={this.reloadGrid}
                      isReadOnly={this.state.noteModalInReadOnly}
                    />
                  </Modal>
                </WrapperTool>
              )}
              {openExecutionNoteModal && (
                <WrapperTool key="wrapper">
                  <Modal
                    open={this.state.openExecutionNoteModal}
                    onClose={() => {
                      this.closeExecutionNoteModal();
                    }}
                    title={
                      <h2>
                        <Icon name="sticky note" />{' '}
                        {this.context.intl.formatMessage(
                          messages.execution_note_text,
                        )}
                      </h2>
                    }
                    size="mini"
                    style={{ width: 650 }}
                    scrolling
                  >
                    <NoteExecutionForm
                      entityId={this.state.selectedEntity.id}
                      entity={this.state.selectedEntity.type}
                      noteService={DestinationNoteService}
                      close={this.closeExecutionNoteModal}
                      initialValues={this.state.selectedEntity}
                      update={() => this.reloadGrid()}
                    />
                  </Modal>
                </WrapperTool>
              )}

              <Grid>
                {this.state.loading && (
                  <Dimmer active={this.state.loading}>
                    <Loader size="big" />
                  </Dimmer>
                )}
                <Grid.Row>
                  <Grid.Column>
                    <BaseSearch
                      initialValues={this.state.initialValues}
                      onSearch={this.handleSearch}
                      onReset={this.handleReset}
                      customSearchFilterNumber={this.state.filters.length || 0}
                      formStructure={formik => (
                        <SharedSearchBar intl={this.context.intl} {...formik} />
                      )}
                    />
                  </Grid.Column>
                </Grid.Row>
                {this.state.filterLabels.length > 0 && (
                  <Grid.Row>
                    <Grid.Column>
                      <FilterBox
                        filterLabels={this.state.filterLabels}
                        removeFilter={this.onRemoveFilter}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row>
                  <Grid.Column>
                    <NoteTable
                      isExecutingNote={this.state.isExecutingNote}
                      data={this.state.data}
                      setSelectedEntity={this.setSelectedEntity}
                      openNotesModal={this.openNotesModal}
                      setReadOnlyMode={this.setNotesModalInReadOnly}
                      openConfirmModal={this.openConfirmModal}
                      executeNote={(item, isRedo) => {
                        if (isRedo) {
                          this.toCancelExecutionNote(item);
                        } else {
                          this.toExecuteNote(item);
                        }
                      }}
                      onExport={this.onExport}
                      onChangePage={this.onChangePage}
                      onChangePageSize={this.onChangePageSize}
                      openStaffWorkorder={this.openStaffWorkorder}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <ConfirmModal
                title={this.context.intl.formatMessage(
                  messages.confirmNoteDelete,
                )}
                content={this.context.intl.formatMessage(
                  messages.confirmDeleteContent,
                )}
                isLoading={this.state.confirmIsProcessing}
                onConfirm={() => this.handleDelete()}
                onCancel={() => this.closeConfirmModal()}
                open={this.state.openConfirmModal}
              />
            </React.Fragment>
          )}
        </Page>
      </>
    );
  }
}

NotesHub.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  notesHub: makeSelectNotesHub(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'notesHub', reducer });
const withSaga = injectSaga({ key: 'notesHub', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(NotesHub);

NotesHub.contextTypes = {
  intl: PropTypes.object.isRequired,
};
