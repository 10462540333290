/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import { RemoveBtn, EditBtn, ModalNewBtn, DownloadBtn } from '../../../Buttons';
import { WrapperTool } from '../../../Layout';
import Modal from '../../../Modal';
import DocumentForm from '../../CustomerDocument';
import AttachmentService from '../../../../shared/services/attachments';
import { addNotification } from '../../../../utils/notification';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';

// import messages from './messages';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function DocumentsPart(props) {
  const [documents, setDocuments] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      props.values.id && loadData();
    },
    [props.values.id],
  );

  const downloadDocument = id => {
    AttachmentService.download(id).then(res => {
      const contentDisposition = res.headers['content-disposition'];
      const filename = contentDisposition.match(`filename="(.*)"`)[1];
      const newBlob = new Blob([res.data], { type: res.data.type });
      const fileUrl = window.URL.createObjectURL(newBlob);
      const tempLink = document.createElement('a');
      tempLink.href = fileUrl;
      tempLink.setAttribute('download', filename);
      tempLink.click();
    });
  };

  const loadData = page => {
    setLoading(true);
    props.documentService
      .getByOwner(props.values.id, page || paginationData.page)
      .then(res => {
        const data = _.get(res, 'data', { ...paginationDefault });
        setPaginationData({
          page: data.page,
          page_count: data.page_count,
          page_size: data.page_size,
          total_items: data.total_items,
        });
        setLoading(false);
        setDocuments(
          _.get(res, `data._embedded.${props.entity}-attachment`),
          [],
        );
      });
  };

  useEffect(() => {
    props.documentService.getAttachmentTypeOptions().then(res => {
      setDocumentTypes(res);
    });
  }, []);

  const closeModal = () => {
    setSelectedDocument(null);
    setModalOpened(false);
  };

  const openModal = () => {
    setSelectedDocument({
      expireDate: '',
      releaseDate: '',
    });
    setModalOpened(true);
  };

  const columns = () =>
    [
      {
        key: 'type',
        name: props.intl.formatMessage(messages.document_type),
        formatter: ({ data }) =>
          _.get(data, '_embedded.attachmentTypeId.description'),
      },
      {
        key: 'id',
        name: props.intl.formatMessage(messages.description),
        formatter: ({ data }) => _.get(data, 'description', ''),
      },
      {
        key: 'releaseDate',
        name: props.intl.formatMessage(messages.release_date),
        formatter: ({ data }) =>
          _.get(data, 'releaseDate.date', null)
            ? moment(data.releaseDate.date).format('DD-MM-YYYY')
            : '--',
      },
      {
        key: 'expireDate',
        name: props.intl.formatMessage(messages.expire_date),
        formatter: ({ data }) =>
          _.get(data, 'expireDate.date', null)
            ? moment(data.expireDate.date).format('DD-MM-YYYY')
            : '--',
      },
      {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 120 }}>
            <DownloadBtn
              action={() => {
                downloadDocument(_.get(data, '_embedded.attachmentId.id'));
              }}
              disabled={!_.get(data, '_embedded.attachmentId.id')}
            />
            {!props.readOnly && (
              <EditBtn
                action={() => {
                  setSelectedDocument(data);
                  setModalOpened(true);
                }}
              />
            )}
            {!props.readOnly && (
              <RemoveBtn
                action={() => {
                  props.documentService
                    .remove(data.id)
                    .then(() => {
                      loadData();
                      addNotification({
                        title: null,
                        message: props.intl.formatMessage(
                          appMessages.operation_performed_successfully,
                        ),
                        isError: false,
                      });
                    })
                    .catch(error =>
                      addNotification({
                        title: props.intl.formatMessage(appMessages.error),
                        message: _.get(
                          error,
                          'data.detail',
                          props.intl.formatMessage(
                            appMessages.an_error_has_occurred,
                          ),
                        ),
                        isError: true,
                      }),
                    );
                }}
              />
            )}
          </div>
        ),
        width: '120px',
      },
    ].filter(o => o);

  return (
    <div>
      <Table
        elements={documents}
        rowClassKey="role-row-class"
        columns={columns()}
        canSort={{
          active: true,
        }}
        isLoading={loading}
        canPaginate={{
          active: paginationData.total_items > 0,
          pageCount: paginationData.page_count,
          pageSize: paginationData.page_size,
          totalItems: paginationData.total_items,
          page: paginationData.page,
          onSelect: page => {
            loadData(page);
            setPaginationData({
              ...paginationData,
              page,
            });
          },
        }}
        canAction={{
          active: !props.readOnly,
          actions: [
            <WrapperTool key="wrapper">
              <Modal
                open={modalOpened}
                onClose={() => {
                  closeModal();
                }}
                title={
                  <h2>
                    <Icon name="attach" />{' '}
                    {props.intl.formatMessage(messages.new_document)}
                  </h2>
                }
                trigger={
                  <ModalNewBtn onClick={openModal}>
                    {props.intl.formatMessage(messages.new_document)}
                  </ModalNewBtn>
                }
                size="mini"
                style={{ width: 400 }}
                scrolling
              >
                <DocumentForm
                  entityId={props.values.id}
                  entity={props.entity}
                  documentService={props.documentService}
                  documentTypes={documentTypes}
                  close={closeModal}
                  initialValues={selectedDocument}
                  update={loadData}
                />
              </Modal>
            </WrapperTool>,
          ],
        }}
        hiddenHeaderIfEmpty
        emptyResults={
          <div>{props.intl.formatMessage(appMessages.no_results)}</div>
        }
      />
    </div>
  );
}

DocumentsPart.defaultProps = {
  values: {},
  // readOnly: false,
};

DocumentsPart.propTypes = {
  values: PropTypes.object,
  // setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  documentService: PropTypes.object,
  entity: PropTypes.string,
};

export default injectIntl(DocumentsPart);
