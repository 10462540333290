import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Segment } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import BaseForm from '../../OrderForm';
import { addNotification } from '../../../../utils/notification';
import validation from '../../OrderForm/validation';
import getError from '../../../../utils/getError';
import { ORDER_STATE_INSERTED } from '../../../../containers/Order/constants';

const defaultDate = id => {
  if (typeof id === 'undefined' || !id) {
    return moment();
  }

  return null;
};

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  transportedBy: _.get(newValues, 'id', null)
    ? _.get(newValues, 'transportedBy', '')
    : _.get(newValues, 'transportedBy', 'Franco Partenza'),
  number: _.get(newValues, 'number', ''),
  deliveryRequestDate: newValues.deliveryRequestDate
    ? moment(newValues.deliveryRequestDate)
    : null,
  deliveryPromiseDate: newValues.deliveryPromiseDate
    ? moment(newValues.deliveryPromiseDate)
    : null,
  deliveryPlanedDate: newValues.deliveryPlanedDate
    ? moment(newValues.deliveryPlanedDate)
    : null,
  shippedStoreDate: newValues.shippedStoreDate
    ? moment(newValues.shippedStoreDate)
    : null,
  referenceDate: newValues.referenceDate
    ? moment(newValues.referenceDate)
    : defaultDate(newValues.id),
  orderType: {
    value: _.get(newValues, '_embedded.orderType.id'),
    label: _.get(newValues, '_embedded.orderType.description'),
  },
  customer: {
    value: _.get(newValues, 'customer.id'),
    label: _.get(newValues, 'customer.displayName'),
  },
  commercialDirector: {
    value: _.get(newValues, 'destination.commercialDirector.id'),
    label: _.get(newValues, 'destination.commercialDirector.displayName'),
  },
  areaManager: {
    value: _.get(newValues, 'destination.areaManager.id'),
    label: _.get(newValues, 'destination.areaManager.displayName'),
  },
  agent1: {
    value: _.get(newValues, 'destination.agent1.id'),
    label: _.get(newValues, 'destination.agent1.displayName'),
  },
  agent2: {
    value: _.get(newValues, 'destination.agent2.id'),
    label: _.get(newValues, 'destination.agent2.displayName'),
  },
  subagent: {
    value: _.get(newValues, 'destination.subagent.id'),
    label: _.get(newValues, 'destination.subagent.displayName'),
  },
  buyingGroup: {
    value: _.get(newValues, 'destination.buyingGroup.id'),
    label: _.get(newValues, 'destination.buyingGroup.displayName'),
  },
  destination: {
    value: _.get(newValues, 'destination.id'),
    label: _.get(newValues, 'destination.businessName'),
  },
  deterchimicaBank: {
    value: _.get(newValues, '_embedded.deterchimicaBank.id'),
    label: _.get(newValues, '_embedded.deterchimicaBank.name'),
  },
  destinationBank: {
    value: _.get(newValues, 'destination.destinationBank.id'),
    label: _.get(newValues, 'destination.destinationBank.description'),
  },
  destinationPaymentTerm: {
    value: _.get(newValues, 'destination.destinationPaymentTerm.id'),
    label: _.get(newValues, 'destination.destinationPaymentTerm.description'),
  },
  state: newValues.id ? _.get(newValues, 'state', null) : ORDER_STATE_INSERTED,
});

export default class ProductCommission extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    commissionId: PropTypes.string,
    commissionService: PropTypes.object,
    entity: PropTypes.string,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      state: _.get(values, 'state') || null,
      transportedBy: _.get(values, 'transportedBy', ''),
      number: _.get(values, 'number', ''),
      deliveryRequestDate: _.get(values, 'deliveryRequestDate.date', null)
        ? values.deliveryRequestDate.format('YYYY-MM-DD')
        : null,
      deliveryPromiseDate: _.get(values, 'deliveryPromiseDate.date', null)
        ? values.deliveryPromiseDate.format('YYYY-MM-DD')
        : null,
      deliveryPlanedDate: _.get(values, 'deliveryPlanedDate.date', null)
        ? values.deliveryPlanedDate.format('YYYY-MM-DD')
        : null,
      shippedStoreDate: _.get(values, 'shippedStoreDate.date', null)
        ? values.shippedStoreDate.format('YYYY-MM-DD')
        : null,
      referenceDate: _.get(values, 'referenceDate.date', null)
        ? values.referenceDate.format('YYYY-MM-DD')
        : null,
      orderType: _.get(values, 'orderType.value', ''),
      customer: _.get(values, 'customer.value', ''),
      profiles: [
        _.get(values, 'agent1.value', null) && {
          profile: _.get(values, 'agent1.value', ''),
          role: 'agent1',
        },
        _.get(values, 'agent2.value', null) && {
          profile: _.get(values, 'agent2.value', ''),
          role: 'agent2',
        },
        _.get(values, 'subagent.value', null) && {
          profile: _.get(values, 'subagent.value', ''),
          role: 'subagent',
        },
        _.get(values, 'signaller.value', null) && {
          profile: _.get(values, 'signaller.value', ''),
          role: 'signaller',
        },
        _.get(values, 'commercialDirector.value', null) && {
          profile: _.get(values, 'commercialDirector.value', ''),
          role: 'commercialDirector',
        },
        _.get(values, 'areaManager.value', null) && {
          profile: _.get(values, 'areaManager.value', ''),
          role: 'areaManager',
        },
      ].filter(o => o),
      buyingGroup: _.get(values, 'buyingGroup.value', ''),
      destination: _.get(values, 'destination.value', ''),
      destinationPaymentTerm: _.get(values, 'destinationPaymentTerm.value', ''),
      deterchimicaBank: _.get(values, 'deterchimicaBank.value', ''),
      destinationBank: _.get(values, 'destinationBank.value', ''),
      [this.props.entity]: this.props.entityId,
    };

    const method = payload.id
      ? this.props.commissionService.update
      : this.props.commissionService.create;

    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: null,
          // message: this.context.intl.formatMessage(messages.commission_saved),
          message: 'Saved',
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setSubmitting(false);
        setErrors(getError(err));
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Segment basic>
        <Formik
          ref={c => {
            this.baseForm = c;
          }}
          initialValues={initialValues(this.props.initialValues || {})}
          validate={values => validation(values, this.props.intl)}
          enableReinitialize
          onSubmit={this.onSubmit}
          render={props => (
            <BaseForm
              {...this.props}
              {...props}
              submit={this.submit}
              modalSubmit
              entityId={this.props.entityId}
            />
          )}
        />
      </Segment>
    );
  }
}

ProductCommission.contextTypes = {
  intl: PropTypes.object.isRequired,
};
