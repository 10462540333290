import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import appMessages from '../../../containers/App/messages';
import ProductCrmService from '../../../shared/services/productcrm';
import CurrencyFormatter from '../../CurrencyFormatter';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikToggle from '../../FormikToggle';
import FormikDate from '../../FormikDate';
import { ModalFakeActions, PaddedColumn } from '../../Layout';
import PercentageFormatter from '../../PercentageFormatter';
import messages from './messages';
import ProductCategoryService from '../../../shared/services/productcategory';
export const BaseForm = props => {
  const isProduct = props.entity === 'product';

  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting}
    >
      <Grid columns="equal">
        <Grid.Row columns={isProduct ? 5 : 3}>
          <PaddedColumn>
            <FormikDate
              {...props}
              name="startDate"
              required
              label={props.intl.formatMessage(messages.start_date)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikDate
              {...props}
              name="endDate"
              label={props.intl.formatMessage(messages.end_date)}
              required
            />
          </PaddedColumn>
          {!isProduct && (
            <PaddedColumn>
              <FormikAsyncSelect
                {...props}
                name="crmCategory"
                label={props.intl.formatMessage(messages.crmCategory)}
                loadOptions={(searchText, callback) =>
                  ProductCrmService.getOptions(searchText, callback)
                }
              />
            </PaddedColumn>
          )}
          {props.entity === 'agent' && (
            <PaddedColumn>
              <FormikAsyncSelect
                {...props}
                name="subCategory"
                label={props.intl.formatMessage(messages.subCategory)}
                loadOptions={(searchText, callback) =>
                  ProductCategoryService.getAsyncSubByCategoryOptions(
                    searchText,
                    callback,
                    true,
                    true,
                  )
                }
              />
            </PaddedColumn>
          )}

          {isProduct && (
            <>
              <PaddedColumn>
                <PercentageFormatter
                  {...props}
                  name="fromPercentage"
                  label={props.intl.formatMessage(messages.fromPercentage)}
                />
              </PaddedColumn>
              <PaddedColumn>
                <PercentageFormatter
                  {...props}
                  name="toPercentage"
                  label={props.intl.formatMessage(messages.toPercentage)}
                />
              </PaddedColumn>
            </>
          )}
        </Grid.Row>
        <Grid.Row columns={isProduct ? 4 : 3}>
          <PaddedColumn>
            <CurrencyFormatter
              {...props}
              name="flatUnit"
              type="number"
              label={props.intl.formatMessage(messages.flat_unit)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <PercentageFormatter
              {...props}
              name="commission"
              type="number"
              label={props.intl.formatMessage(messages.commission)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <PercentageFormatter
              {...props}
              name="refund"
              label={props.intl.formatMessage(messages.refund)}
            />
          </PaddedColumn>
          {isProduct && (
            <PaddedColumn>
              <FormikToggle
                name="isIncludedInBonus"
                label={props.intl.formatMessage(messages.isIncludedInBonus)}
              />
            </PaddedColumn>
          )}
        </Grid.Row>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={props.close}
            >
              <Icon name="remove" />
              {props.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Button
              type="button"
              icon
              labelPosition="left"
              color="green"
              disabled={!props.values.startDate || !props.values.endDate}
              onClick={props.submit}
            >
              <Icon name="checkmark" />
              {props.intl.formatMessage(appMessages.save)}
            </Button>
          </div>
        </ModalFakeActions>
      </Grid>
    </Form>
  );
};

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  submit: PropTypes.func,
  close: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BaseForm);
