/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// import { FormattedMessage } from 'react-intl';
import { Form } from 'semantic-ui-react';
import validation from './validation';
import { FormTab } from '../../Layout';
import FormActionsBar from '../../FormActionsBar';
import messages from './messages';
import GeneralTab from './tabs/generalTab';
import contractService from '../../../shared/services/contract';
import NotesTab from './tabs/notesTab';

export class StaffMask extends React.Component {
  state = {
    activeIndex: 0,
    allowedStates: [],
    allowedStatesLoading: true,
  };

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  componentDidUpdate() {
    if (
      this.state.allowedStates.length === 0 &&
      _.get(this.props, 'values.state')
    ) {
      contractService
        .getAvailableStates(this.props.values.state)
        .then(res =>
          this.setState({ allowedStates: res, allowedStatesLoading: false }),
        )
        .catch(() =>
          this.setState({ allowedStates: [null], allowedStatesLoading: false }),
        );
    }
  }

  render() {
    const {
      handleSubmit,
      // setFieldValue,
      // values,
      readOnly = false,
      isSubmitting = false,
      isValid = false,
      onDelete = false,
      isDeleting = false,
      showDeleteButton = false,
    } = this.props;

    const panes = [
      {
        menuItem: this.context.intl.formatMessage(messages.generaldata),
        render: () => (
          <FormTab.Pane>
            <GeneralTab
              {...this.props}
              allowedStates={this.state.allowedStates}
              allowedStatesLoading={this.state.allowedStatesLoading}
            />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.context.intl.formatMessage(messages.generalnotes),
        render: () => (
          <FormTab.Pane>
            <NotesTab
              {...this.props}
              allowedStates={this.state.allowedStates}
              allowedStatesLoading={this.state.allowedStatesLoading}
              entity="staff"
            />
          </FormTab.Pane>
        ),
      },
    ];

    return (
      <Form onSubmit={handleSubmit}>
        <FormTab
          panes={panes}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
          className="formTabs"
        />
        <FormActionsBar
          showDeleteButton={showDeleteButton}
          readOnly={readOnly}
          isValid={isValid}
          isDeleting={isDeleting}
          isSubmitting={isSubmitting}
          editRoute={this.props.editRoute}
          onDelete={onDelete}
          history={this.props.history}
        />
      </Form>
    );
  }
}

StaffMask.defaultProps = {
  errors: [],
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

StaffMask.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

StaffMask.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default StaffMask;

export { validation };
