/* eslint-disable max-len */
import PropTypes from 'prop-types';

/**
 * The Formik shape.
 */
const FormikShape = {
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  status: PropTypes.any,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
};

/**
 * The ReactIntl shape.
 */
const IntlShape = PropTypes.shape({
  formatMessage: PropTypes.func,
});

/**
 * Translations for texts given from props shape
 */
const TranslationsShape = PropTypes.shape({
  createButton: PropTypes.shape({
    label: PropTypes.string,
  }),
  dropdown: PropTypes.shape({
    label: PropTypes.string,
  }),
  searchBar: PropTypes.shape({
    label: PropTypes.string,
    reset: PropTypes.string,
    search: PropTypes.string,
  }),
  exportElements: PropTypes.shape({
    label: PropTypes.string,
    prefix: PropTypes.string,
  }),
  actionsDefault: PropTypes.shape({
    viewText: PropTypes.string,
    editText: PropTypes.string,
    deleteText: PropTypes.string,
  }),
  simpleSearchBar: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  counters: PropTypes.shape({
    elementName: PropTypes.string,
    selectedLabel: PropTypes.string,
  }),
});

const GridShape = {
  /**
   * The components current locale to manage the translations of the textss
   */
  locale: PropTypes.oneOf(['en', 'it']),
  /**
   * If active, scoll to Top after a page change
   * (only if Y position is over the start position Y of the object)
   */
  autoScroll: PropTypes.bool,
  /**
   * The start basepath ex: http://www.google.com
   */
  basePath: PropTypes.string,
  /**
   * The key to use for session storage to remember the filters!
   * MUST BE UNIQUE
   */
  sessionKey: PropTypes.string,
  /**
   * The name of the REST entity
   */
  entityName: PropTypes.string.isRequired,

  /**
   * The router address.
   */
  routerName: PropTypes.string,

  /**
   * Client used to fetch info.
   * If null, the library will use an internal axios Client
   */
  client: PropTypes.func,
  /**
   * The react-router object for managing the change location
   */
  router: PropTypes.any,
  /**
   * An array of Object columns that abstract how to visualize and manage the series of data
   * see example for more info
   */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * Column key, needed for finding correct values and properties
       */
      key: PropTypes.string,
      /**
       * Needed for the table header
       */
      name:
        PropTypes.string /**
      /**
       *  Custom name for the filtering and the filter box removal
       */,
      filterName: PropTypes.string,
      /**
       * If the column can be sorted
       */
      sortable: PropTypes.bool,
      /**
       * If the column based on `key` can be searched
       */
      searchable: PropTypes.bool,
      /**
       * The hyperlink to be added after the content in a column
       */
      useHyperLink: PropTypes.shape({
        /**
         * Controller for hyperlink
         */
        active: PropTypes.bool,
        /**
         * The name of the route where to redirect, default to entityName
         */
        routeName: PropTypes.string,
        /**
         * The key of the redirect, defaults to 'id'
         */
        routeKey: PropTypes.string,
        /**
         * In what type of action to open a form
         */
        routeAction: PropTypes.string,
      }),
      /**
       * Function that can customize what the contents inside the columns are
       */
      formatter: PropTypes.func,
      /**
       * Function that can customize what the contents inside the export columns are
       */
      exportFormatter: PropTypes.func,
    }),
  ),
  /**
   * WARNING: don't use this properties if you don't understand how this works.
   * Thi property is used for checking as internal state and force the component to relaod
   */
  reloadIndex: PropTypes.any,
  /**
   * The number of reqeusted elements.
   * Zend allow -1 to remove pagesize and get alll the data.
   */
  pageSize: PropTypes.number,
  /**
   * YOu can use aliases for preparing data with the alias zf-doctrine-querybuilder
   */
  aliases: PropTypes.arrayOf(PropTypes.object),
  /**
   * A series if valid zf-doctrine-querybuilder filters applied for each request
   */
  persistentFilter: PropTypes.arrayOf(PropTypes.object),
  /**
   * A series if valid zf-doctrine-querybuilder orderBy applied for each request
   */
  defaultOrder: PropTypes.arrayOf(PropTypes.object),
  /**
   * Function called at the end of a elements loading. Usefull to sync more than one loading component
   */
  afterLoad: PropTypes.func,
  /**
   * Function called after sorting a column, if available, to save the sort if you need it
   */
  handleSort: PropTypes.func,
  /**
   * Enable Searching on visible fields
   */
  canSearch: PropTypes.bool,
  /**
   * Optional function to call after removing a filter manually
   * that returns the filter name to clear labels
   */
  onRemoveFilter: PropTypes.func,
  /**
   * Optional function to call after using the default searchbar
   * that returns the value used and applied filters so that they
   * can be saved and re-used.
   */
  onDefaultSearch: PropTypes.func,
  /**
   * Search bar placeholder
   */
  searchPlaceholder: PropTypes.string,
  /**
   * Enable the CustomSearch.
   * Custom Search is not a custom search as the name induce to think.
   * Is a complex search that can be managed as totally custom or made with some base componenets.
   * Read the shape of the project
   */
  canSearchCustom: PropTypes.shape({
    /**
     * you can active of shut it down from here
     */
    active: PropTypes.bool,
    /**
     * Title of the search section
     */
    title: PropTypes.string,
    /**
     * Label of the search button
     */
    buttonLabel: PropTypes.string,
    /**
     * The form structure. EntityGrid use internally formik, so you need to pass a true formik Field compoenent to manage this correctly.
     * If you not pass this element the grid create a static form with Standard input fields for each Columns with searchable: true with standard searches.
     */
    formStructure: PropTypes.node,
    /**
     * You can use this function for manage the result of the CustomSearch Form submission.
     * Inside at this function some functions are injected in order to help you in the form management [values, formikActionsBag, applyfilterFunction]
     *
     * * values: is the array of inputs values (a formik values array)
     * * formikActionsBag: Is the bag of the formik field (check the Formik documentation to understand more)
     * * applyfilterFunction([filters:ArrayOf(filters),filters:BoxesArrayOf(filtersBoxes),formikActionsBag:formikActionsBag):
     *
     * Is the function you should to trigger at the end of your custome management passing:
     *
     * - A series of filters, check the example for the shape
     * - A series of filterBoxed, check the example for the shape
     * - The same formik bag that the function inject before.
     *
     *
     */
    onApplyFilter: PropTypes.func,
  }),
  /**
   * Enable create button
   */
  canCreate: PropTypes.shape({
    /**
     * Activate the button
     */
    active: PropTypes.bool,
    /**
     * The label of the creation button
     */
    label: PropTypes.string,
    /**
     * Action ovverrides the default behaviour (go to new page)
     */
    action: PropTypes.func,
    /**
     * Handle the loading state of the button
     */
    isLoading: PropTypes.bool,
    /**
     * Handle the disabled state of the button
     */
    disabled: PropTypes.bool,
  }),
  /**
   * Function called after the on reset filters is completed
   */
  onResetFilters: PropTypes.func,
  /**
   * The message shown on no results
   */
  emptyResults: PropTypes.string,
  /**
   * The name of the entity, is used only for a label purpose.
   * In the counter beaside the search for example
   */
  elementName: PropTypes.string,
  /**
   * The label showing item selected, is used only for a label purpose.
   * In the selected counter beaside the counter for example
   */
  selectedLabel: PropTypes.string,
  /**
   * The label of records per page, is used only for a label purpose.
   */
  pageSizeLabel: PropTypes.string,
  /**
   * Enable dropdown select pageSize
   */
  pageSizeSelection: PropTypes.bool,
  /**
   * Enable export excel button
   */
  canExport: PropTypes.shape({
    /**
     * Activate the export excel
     */
    active: PropTypes.bool,
    /**
     * The label of button export, is used only for a label purpose.
     */
    labelButton: PropTypes.string,
    /**
     * The prefix for Export Dropdown options: Default 'Export to'
     */
    exportLabelPrefix: PropTypes.string,
    /**
     * The export excel url ex: https://msproxy.novigo-consulting.it
     * If is not setted, the defautl basePath is used
     */
    exportServiceEndpoint: PropTypes.string,
    /**
     * if true, excel export service is active, Default true
     */
    excel: PropTypes.bool,
    /**
     * if true, PDF export service is active, Default true
     */
    pdf: PropTypes.bool,
    /**
     * if true, CSV export service is active, Default true
     */
    csv: PropTypes.bool,
  }),
  /**
   * Determine the position of paginator
   */
  componentsPosition: PropTypes.oneOf(['bottom', 'top', 'both']),
  /**
   * If default action is active, the column 'Actions' is added to the columns array.
   * You can specify a true/false map for each action.
   */
  defaultActions: PropTypes.shape({
    /**
     * Active the modify default action
     * If cliked the elment redirect to the page  entityName/id/modify
     */
    modify: PropTypes.bool,
    /**
     * Active the view default action
     * If cliked the elment redirect to the page  entityName/id/view
     */
    visualize: PropTypes.bool,
    /**
     * Active the delete default action
     * If cliked the elment redirect to the page  entityName/id/delete
     */
    delete: PropTypes.bool,
    /**
     * If active, push all the actin in a unique dropdown element instead of a grou of icons
     *
     */
    grouped: PropTypes.bool,
    /**
     * If active, invert the position of custom Action, plased BEFORE default actions
     */
    invertedOrder: PropTypes.bool,
    /**
     * A series of valid structure called action to manage an action around a line
     */
    moreActions: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * The name of the icon
         */
        icon: PropTypes.string,
        /**
         * The label shown if the elemnt is grouped
         */
        label: PropTypes.string,
        /**
         * A unique key
         */
        key: PropTypes.string,
        /**
         * Name of # of the color. Blue, and red sued by default
         */
        color: PropTypes.string,
        /**
         * THe funciton when the action in clicked
         */
        action: PropTypes.func,
      }),
    ),
  }),
  counterLabels: PropTypes.shape({
    totalItems: PropTypes.bool,
    selectedItems: PropTypes.bool,
  }),
  canSelect: PropTypes.shape({
    /**
     * Activate the selection mode
     */
    active: PropTypes.bool,
    /**
     * Activete the select All function
     */
    selectAll: PropTypes.bool,
    /**
     * Optional function that returns selected row to call on selected row to handle in state
     */
    handleSelectRow: null,
    /**
     * Optional function that returns selected row to call on deselected row to handle in state
     */
    handleDeselectRow: null,
    /**
     * Optional function that returns all selected rows
     */
    handleSelectAll: null,
    /**
     * Optional function that called on deselect all rows
     */
    handleDeselectAll: null,
    /**
     * A series of actions for built series of button
     * with label and onClick action
     */
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        /**
         * Label of the button
         */
        label: PropTypes.string,
        /**
         * The onclick action, in the funct Ids of selected are passed
         */
        onClick: PropTypes.func,
        /**
         * If availableOnSelection is passed = true, action only enable when table have any line be selected
         * Else default value of availableOnSelection = false, action always enable.
         */
        availableOnSelection: PropTypes.bool,
        /**
         * Handle the loading state of the button
         */
        isLoading: PropTypes.bool,
        /**
         * Handle the disabled state of the button
         */
        disabled: PropTypes.bool,
      }),
    ),
  }),
  intl: IntlShape,
  translations: TranslationsShape,
  customPaginator: PropTypes.node,
};

export { FormikShape, IntlShape, TranslationsShape, GridShape };
