/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { injectIntl, intlShape } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import { RemoveBtn, EditBtn, ModalNewBtn } from '../../../Buttons';
import { WrapperTool } from '../../../Layout';
import Modal from '../../../Modal';
import NoteForm from '../../CustomerNote';
import { addNotification } from '../../../../utils/notification';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import CustomerNoteService from '../../../../shared/services/customernote';
import NoteService from '../../../../shared/services/note';
import { convertUTCToLocal } from '../../../../utils/functions';

// import messages from './messages';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function NotesPart(props) {
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [noteTypes, setNoteTypes] = useState([]);
  const [noteReasons, setNoteReasons] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [loading, setLoading] = useState(true);
  const { entity, noteService } = props;

  useEffect(
    () => {
      // eslint-disable-next-line no-unused-expressions
      props.values.id && loadData();
    },
    [props.values.id, paginationData.page],
  );

  useEffect(() => {
    NoteService.getNoteTypeOptions().then(res => {
      setNoteTypes(res);
    });
    NoteService.getReasonTypeOptions().then(res => {
      setNoteReasons(res);
    });
  }, []);

  const loadData = page => {
    setLoading(true);
    noteService
      .getByOwner(props.values.id, page || paginationData.page)
      .then(res => {
        const data = _.get(res, 'data', { ...paginationDefault });
        setPaginationData({
          page: data.page,
          page_count: data.page_count,
          page_size: data.page_size,
          total_items: data.total_items,
        });
        setLoading(false);
        setItems(_.get(res, `data._embedded.${entity}-note`), []);
      });
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalOpened(false);
  };

  const openModal = () => {
    setSelectedItem({});
    setModalOpened(true);
  };

  const columns = () =>
    [
      {
        key: 'noteNumber',
        name: props.intl.formatMessage(messages.noteNumber),
      },
      {
        key: 'date',
        name: props.intl.formatMessage(messages.date),
        formatter: ({ data }) => {
          const { createdAt } = data;
          const localCreatedAt = createdAt
            ? convertUTCToLocal(createdAt.date)
            : '--';
          return localCreatedAt;
        },
      },
      {
        key: 'user',
        name: props.intl.formatMessage(messages.user),
        formatter: ({ data }) =>
          `${_.get(data, 'createdBy.firstname', '')} ${_.get(
            data,
            'createdBy.lastname',
            '',
          )}`,
      },
      {
        key: 'noteText',
        name: props.intl.formatMessage(messages.note),
      },
      !props.readOnly && {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 80 }}>
            <EditBtn
              action={() => {
                setSelectedItem(data);
                setModalOpened(true);
              }}
            />
            <RemoveBtn
              action={() => {
                CustomerNoteService.remove(data.id)
                  .then(() => {
                    loadData();
                    addNotification({
                      title: null,
                      message: props.intl.formatMessage(
                        messages.note_removed_success,
                      ),
                      isError: false,
                    });
                  })
                  .catch(error =>
                    addNotification({
                      title: null,
                      message: error.data.detail,
                      isError: true,
                    }),
                  );
              }}
            />
          </div>
        ),
        width: '80px',
      },
    ].filter(o => o);

  return (
    <div>
      <Table
        elements={items}
        rowClassKey="role-row-class"
        columns={columns()}
        canSort={{
          active: true,
        }}
        isLoading={loading}
        canPaginate={{
          active: paginationData.total_items > 0,
          pageCount: paginationData.page_count,
          pageSize: paginationData.page_size,
          totalItems: paginationData.total_items,
          page: paginationData.page,
          onSelect: page => {
            setPaginationData({
              ...paginationData,
              page,
            });
          },
        }}
        canAction={{
          active: !props.readOnly,
          actions: [
            <WrapperTool key="wrapper">
              <Modal
                open={modalOpened}
                onClose={() => {
                  closeModal();
                }}
                title={
                  <h2>
                    <Icon name="sticky note" />{' '}
                    {props.intl.formatMessage(messages.new_note)}
                  </h2>
                }
                trigger={
                  <ModalNewBtn onClick={openModal}>
                    {props.intl.formatMessage(messages.new_note)}
                  </ModalNewBtn>
                }
                size="mini"
                style={{ width: 650 }}
                scrolling
              >
                <NoteForm
                  entityId={props.values.id}
                  entity={props.entity}
                  noteService={noteService}
                  noteTypes={noteTypes}
                  noteReasons={noteReasons}
                  close={closeModal}
                  initialValues={selectedItem}
                  update={() => loadData(1)}
                />
              </Modal>
            </WrapperTool>,
          ],
        }}
        hiddenHeaderIfEmpty
        emptyResults={
          <div>{props.intl.formatMessage(appMessages.no_results)}</div>
        }
      />
    </div>
  );
}

NotesPart.defaultProps = {
  values: {},
  readOnly: false,
};

NotesPart.propTypes = {
  values: PropTypes.object,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  entity: PropTypes.string,
  noteService: PropTypes.shape({}),
};

export default injectIntl(NotesPart);
