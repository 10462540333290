function Validation(values) {
  const errors = {};

  if (!values.product) {
    errors.product = { required: 'Required' };
  }
  // Validation range is required
  if (!values.startDate) {
    errors.startDate = { required: 'Required' };
  }
  if (!values.endDate) {
    errors.endDate = { required: 'Required' };
  }

  return errors;
}

export default Validation;
