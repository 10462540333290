import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.CUSTOMER,
    method: 'GET',
  });

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.CUSTOMER,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'displayName',
          where: 'or',
          value: `%${searchText}%`,
        },
        searchText && {
          type: 'like',
          field: 'customerCode',
          where: 'or',
          value: `%${searchText}%`,
        },
        {
          type: 'eq',
          field: 'isActive',
          value: true,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback, codeField = false) => {
  searchByName(inputValue).then(results =>
    callback(
      _.get(results, 'data._embedded.customer', []).map(customer => {
        const labelParts = [
          codeField && customer.customerCode,
          customer.displayName,
        ].filter(l => l);

        return {
          value: customer.id,
          label: labelParts.join(' - '),
        };
      }),
    ),
  );
  // eslint-disable-next-line no-useless-return
  return;
};

const searchByNameClientProspect = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.CUSTOMER,
    method: 'GET',
    params: {
      getAll: 1,
      filter: [
        searchText && {
          type: 'like',
          field: 'businessName',
          where: 'or',
          value: `%${searchText}%`,
        },
        searchText && {
          type: 'like',
          field: 'displayName',
          where: 'or',
          value: `%${searchText}%`,
        },
        searchText && {
          type: 'like',
          field: 'customerCode',
          where: 'or',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptionsClientProspect = (inputValue, callback) => {
  searchByNameClientProspect(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.customer', []).map(customer => ({
        value: customer.id,
        label: customer.displayName || customer.businessName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const findById = id =>
  request({
    baseURL: API.BASE_URL,
    url: API.CUSTOMER,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'id',
          value: id,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.CUSTOMER,
    method: 'POST',
    data: content,
  });

const read = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CUSTOMER}/${id}`,
    method: 'GET',
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CUSTOMER}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.CUSTOMER}/${id}`,
    method: 'DELETE',
  });

const CustomerService = {
  create,
  read,
  update,
  remove,
  getAll,
  getOptions,
  findById,
  getOptionsClientProspect,
};

export default CustomerService;
