/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import CommissionsPart from '../../Anagrafica/formParts/commissionsPart';
import agentCommissionService from '../../../../shared/services/agentcommission';

function CommissionsTab(props) {
  return (
    <>
      <CommissionsPart
        {...props}
        commissionService={agentCommissionService}
        entity="agent"
        entityName="agent-product"
      />
    </>
  );
}

CommissionsTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CommissionsTab);
