/*
 * RequestPasswordReset Messages
 *
 * This contains all the text for the RequestPasswordReset component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Forms.ChangePassword';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Password change',
  },
  password_change: {
    id: `${scope}.password_change`,
    defaultMessage: 'Password change',
  },
  success: {
    id: `${scope}.success`,
    defaultMessage: 'Edit your password',
  },
});
