import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextArea, Grid, Form, Button, Icon, Label } from 'semantic-ui-react';
import UploadButton from 'react-upload-button-v2';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import Comment from './comment';

const StyledDownload = styled.span`
  color: rgba(255, 255, 255, 0.8);
  &:hover {
    color: rgba(255, 255, 255, 1);
  }
  cursor: pointer;
`;

const descriptionStyle = {
  color: '#fff',
  marginBottom: 22,
};

const usernameStyle = {
  marginTop: -15,
  marginBottom: 22,
  fontSize: 12,
  color: '#fff',
  fontWeight: 'bold',
  opacity: 0.8,
};

const dateStyle = {
  fontSize: 10,
  color: '#fff',
  fontStyle: 'italic',
  opacity: 0.7,
};

export default class ticketDetail extends Component {
  static propTypes = {
    ticket: PropTypes.object,
    getToken: PropTypes.func,
    styles: PropTypes.object,
    service: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      id: '', // eslint-disable-line
      ticket: null,
      comments: [],
      newComment: false,
      description: '',
    };
  }

  issueStyle = {
    backgroundColor: this.props.styles.SECOND_COLOR,
    marginLeft: -22,
    marginRight: -22,
    marginTop: -22,
    padding: 22,
    paddingTop: 11,
  };

  componentDidMount() {
    this.loadData();
  }

  getTicket = () =>
    axios.all([
      this.props.service.getAll({ route: `issue/${this.props.ticket.key}` }),
      this.props.service.getAll({
        route: `issue/${this.props.ticket.key}/comment`,
      }),
    ]);

  getAttachment = (url, name) => {
    axios({
      method: 'get',
      url,
      responseType: 'blob',
      headers: {
        Authorization: this.props.getToken(),
        'Content-Type': 'application/json',
      },
    })
      .then(res => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch();
  };

  addFile = e => {
    const file = e.files[0];
    // const hash = btoa('m.raguzzini:bcpwd2006');
    // const Basic = `Basic ${hash}`;
    const data = new FormData();
    data.append('file', file, file.name);
    data.append('method', 'POST');
    data.append('route', `issue/${this.state.ticket.key}/attachments`);
    data.append('params', '{}');
    data.append('microserviceName', 'jira');
    data.append('headers', '{"X-Atlassian-Token":"no-check"}');

    return this.props.service
      .uploadAttachment(data)
      .then(() => {
        this.loadData();
      })
      .catch();
  };

  showFile = res => {
    const fileURL = URL.createObjectURL(res);
    window.open(fileURL);
  };

  createIssue = () => {
    const payload = {
      body: this.state.description,
    };

    const route = `issue/${this.props.ticket.key}/comment`;
    return this.props.service.createIssue({ route, payload });

    /*
    const url = `${this.props.url}/issue/${this.props.ticket.key}/comment`;

    return axios({
      method: 'POST',
      url,
      headers: {
        Authorization: this.props.getToken(),
        'Content-Type': 'application/json',
      },
      data,
    }); */
  };

  handleSendTicket = () => {
    this.createIssue()
      .then(() => {
        this.setState({ description: '', newComment: false });
        this.loadData();
      })
      .catch(() => alert('Si è verificato un errore'));
  };

  loadData = () => {
    this.getTicket().then(
      axios.spread((ticket, comments) => {
        this.setState({
          ticket: ticket.data,
          comments: comments.data.comments,
        });
      }),
    );
  };

  render() {
    return _.get(this.state.ticket, 'id') ? (
      <div>
        <div style={this.issueStyle}>
          <h2 style={{ color: '#FFF' }}>
            {_.get(this.state.ticket, 'fields.summary') || '--'}
          </h2>
          <div style={usernameStyle}>
            {_.get(this.state.ticket, 'fields.customfield_10301') || '--'}{' '}
            <span style={dateStyle}>
              {moment(new Date(this.state.ticket.fields.created)).format(
                'DD/MM/YYYY',
              )}{' '}
              <span style={{ fontSize: 9 }}>
                {moment(new Date(this.state.ticket.fields.created)).format(
                  'HH:MM',
                )}
              </span>
            </span>
          </div>

          <div style={descriptionStyle}>
            {_.get(this.state.ticket, 'fields.description') || '--'}
          </div>
          <div>
            {_.get(this.state.ticket, 'fields.attachment[0]') &&
              Array.isArray(_.get(this.state.ticket, 'fields.attachment')) &&
              this.state.ticket.fields.attachment.map(item => (
                <div style={{ marginBottom: 6 }} key={item.id}>
                  <StyledDownload
                    onClick={() =>
                      this.getAttachment(item.content, item.filename)
                    }
                  >
                    <Icon name="download" /> {item.filename}
                  </StyledDownload>
                </div>
              ))}
            <div style={{ paddingTop: 11, display: 'inline-block' }}>
              <UploadButton onChange={this.addFile}>
                <Label
                  type="button"
                  style={{
                    padding: 9,
                    display: 'inline-block',
                    borderRadius: 5,
                  }}
                  color="google plus"
                  size="mini"
                  content="Aggiungi allegato"
                  icon="upload"
                  labelPosition="left"
                />
              </UploadButton>
            </div>
          </div>
        </div>
        {this.state.comments.map(comment => (
          <Comment comment={comment} styles={this.props.styles} />
        ))}
        <Grid>
          {!this.state.newComment && (
            <Grid.Column
              width={16}
              textAlign="center"
              style={{ marginTop: 22 }}
            >
              <Button
                primary
                icon
                onClick={() => this.setState({ newComment: true })}
              >
                <Icon name="comment" /> Commenta</Button> { /* eslint-disable-line */ }
            </Grid.Column>
          )}
          {this.state.newComment && (
            <Grid.Column width={16} style={{ marginTop: 22 }}>
              <Form>
                <TextArea
                  placeholder="Descrivi il tuo problema"
                  onBlur={e => this.setState({ description: e.target.value })}
                />
              </Form>
              <div style={{ textAlign: 'right', marginTop: 11 }}>
                <Button.Group>
                  <Button
                    basic
                    icon="delete"
                    color="grey"
                    onClick={() => this.setState({ newComment: false })}
                  />
                  <Button
                    basic
                    positive
                    icon="check"
                    onClick={this.handleSendTicket}
                  />
                </Button.Group>
              </div>
            </Grid.Column>
          )}
        </Grid>
      </div>
    ) : (
      <div />
    );
  }
}
