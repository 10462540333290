/*
 * TechnicalIntervention Messages
 *
 * This contains all the text for the TechnicalIntervention container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.TechnicalIntervention';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Technical Intervention',
  },
  numberODL: {
    id: `${scope}.numberODL`,
    defaultMessage: 'ODL Number',
  },
  typeODL: {
    id: `${scope}.typeODL`,
    defaultMessage: 'ODL Type',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  headquarter: {
    id: `${scope}.headquarter`,
    defaultMessage: 'Headquarter',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  technical: {
    id: `${scope}.technical`,
    defaultMessage: 'Technical',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  plannedDate: {
    id: `${scope}.plannedDate`,
    defaultMessage: 'Planned Date',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  new_work_order: {
    id: `${scope}.new_work_order`,
    defaultMessage: 'New Workorder',
  },
  export_pdf: {
    id: `${scope}.export_pdf`,
    defaultMessage: 'Export PDF',
  },
  assign_workorder: {
    id: `${scope}.assign_workorder`,
    defaultMessage: 'Assign workorder',
  },
  send_pdf: {
    id: `${scope}.send_pdf`,
    defaultMessage: 'Send PDF',
  },
  customerCode: {
    id: `${scope}.customerCode`,
    defaultMessage: 'Customer code',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'From',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'To',
  },
  interventionHoursFrom: {
    id: `${scope}.interventionHoursFrom`,
    defaultMessage: 'Intervention Hours From',
  },
  interventionHoursTo: {
    id: `${scope}.interventionHoursTo`,
    defaultMessage: 'Intervention Hours Trom',
  },
});
