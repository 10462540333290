import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import SimpleFormikField from 'novigo-simple-formik-field';
import { Form, Grid, Button, Progress, Icon, Input } from 'semantic-ui-react';
import messages from '../messages';
import FormikSelect from '../../../FormikSelect';
import { AREA_OPTIONS, PRINCIPLE_OPTIONS, TOOL_OPTIONS } from '../constants';
import { BarWrapper } from './Bar';
const Indicator = props => {
  const {
    data,
    increment,
    addIndicator,
    removeIndicator,
    readOnly,
    showToolsField = true,
    showSpeciesField = true,
    rowColumns,
  } = props;

  return (
    <Grid.Row columns={rowColumns + 1}>
      <Grid.Column width={1} textAlign="center" verticalAlign="middle">
        <span>{data.name}</span>
      </Grid.Column>
      {showToolsField && (
        <Grid.Column width={2}>
          <FormikSelect
            required
            {...props}
            isLoading={false}
            options={TOOL_OPTIONS}
            defaultValue={data.tool}
            value={data.tool}
            onChange={target => {
              props.setFieldValue({
                key: data.key,
                name: 'tool',
                value: target,
              });
            }}
          />
        </Grid.Column>
      )}
      <Grid.Column width={2}>
        <FormikSelect
          required
          {...props}
          isLoading={false}
          options={AREA_OPTIONS}
          defaultValue={data.area}
          value={data.area}
          onChange={target => {
            props.setFieldValue({ key: data.key, name: 'area', value: target });
          }}
        />
      </Grid.Column>
      <Grid.Column width={2} textAlign="center" verticalAlign="middle">
        <Button
          type="button"
          disabled={readOnly}
          onClick={() => increment(data.key)}
        >
          {props.intl.formatMessage(messages.increment)}
        </Button>
      </Grid.Column>
      <Grid.Column width={3} textAlign="center" verticalAlign="middle">
        {/* custom styling */}
        <BarWrapper percent={data.percent}>
          <Progress percent={data.percent} progress indicating />
        </BarWrapper>
      </Grid.Column>
      <Grid.Column width={1} textAlign="center" verticalAlign="middle">
        <FormikSelect
          required
          {...props}
          isLoading={false}
          options={PRINCIPLE_OPTIONS}
          defaultValue={data.principle}
          value={data.principle}
          onChange={target => {
            props.setFieldValue({
              key: data.key,
              name: 'principle',
              value: target,
            });
          }}
        />
      </Grid.Column>
      {showSpeciesField && (
        <Grid.Column width={2} textAlign="center" verticalAlign="middle">
          <SimpleFormikField {...props} name="specie" label=" " control={Input}>
            {/* eslint-disable-next-line no-unused-vars */}
            {({ field, form, meta }) => (
              <Input
                defaultValue={data.specie}
                onChange={(e, { value }) => {
                  props.setFieldValue({
                    key: data.key,
                    name: field.name,
                    value,
                  });
                }}
              />
            )}
          </SimpleFormikField>
        </Grid.Column>
      )}
      <Grid.Column width={2} textAlign="center" verticalAlign="middle">
        <SimpleFormikField
          {...props}
          name="description"
          label=" "
          control={Input}
        >
          {/* eslint-disable-next-line no-unused-vars */}
          {({ field, form, meta }) => (
            <Input
              defaultValue={data.description}
              onChange={(e, { value }) => {
                props.setFieldValue({ key: data.key, name: field.name, value });
              }}
            />
          )}
        </SimpleFormikField>
      </Grid.Column>
      <Grid.Column width={1} textAlign="center" verticalAlign="middle">
        <Field
          name="transportedBy"
          render={() => (
            <Form.Group
              widths="equal"
              style={{ display: 'inline-block', margin: 0 }}
            >
              <Button.Group>
                <Button
                  icon
                  type="button"
                  disabled={readOnly}
                  onClick={() => removeIndicator(data.key)}
                >
                  <Icon name="minus" />
                </Button>
                <Button
                  icon
                  type="button"
                  disabled={readOnly}
                  onClick={() => addIndicator()}
                >
                  <Icon name="plus" />
                </Button>
              </Button.Group>
            </Form.Group>
          )}
        />
      </Grid.Column>
    </Grid.Row>
  );
};

Indicator.propTypes = {
  intl: intlShape.isRequired,
  setFieldValue: PropTypes.func,
  showToolsField: PropTypes.bool,
  showSpeciesField: PropTypes.bool,
};

export default injectIntl(Indicator);
