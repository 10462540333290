/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { Grid, Form, Label, Button } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import { Field } from 'formik';
import messages from '../../../messages';
import CurrencyFormatter from '../../../../../CurrencyFormatter';
import PercentageFormatter from '../../../../../PercentageFormatter';
import DiscountTypeService from '../../../../../../shared/services/discountType';

const BaseForm = props => {
  const [discountTypes, setdiscountTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    DiscountTypeService.getAll().then(response => {
      const types = _.get(response, 'data._embedded.discount-type', []).map(
        discountType => ({
          id: discountType.id,
          code: discountType.code,
          description: discountType.description,
        }),
      );
      const value = types.find(o => o.code === 'PERCENTAGE');
      setFieldValue('discountType', value);
      setdiscountTypes(types);
      setLoading(false);
    });
  }, []);

  const setDiscountTypeValue = type => {
    const value = discountTypes.find(o => o.code === type);
    setFieldValue('discountType', value);
  };
  const { setFieldValue, readOnly } = props;
  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting || loading}
    >
      <Grid columns={1} padded>
        <Grid.Row>
          <Grid.Column>
            <Label
              basic
              color="white"
              style={{
                fontSize: '1rem',
                padding: '8px 0 5px 0',
                border: 'none',
              }}
            >
              {props.intl.formatMessage(messages.discountType)}
            </Label>
          </Grid.Column>
          <Grid.Column>
            <Field
              name="discountType"
              render={({ field }) => (
                <Form.Group widths="equal" style={{ paddingLeft: 5 }}>
                  <Button.Group>
                    <Button
                      type="button"
                      disabled={readOnly}
                      primary={field.value.code === 'PERCENTAGE'}
                      checked={field.value.code === 'PERCENTAGE'}
                      onClick={() => {
                        setDiscountTypeValue('PERCENTAGE');
                      }}
                    >
                      <FormattedMessage {...messages.percentage} />
                    </Button>
                    <Button
                      type="button"
                      disabled={readOnly}
                      primary={field.value.code === 'MARKUP'}
                      checked={field.value.code === 'MARKUP'}
                      onClick={() => {
                        setDiscountTypeValue('MARKUP');
                      }}
                    >
                      <FormattedMessage {...messages.markup} />
                    </Button>
                  </Button.Group>
                </Form.Group>
              )}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          {props.values.discountType.code === 'PERCENTAGE' && (
            <Grid.Column>
              <PercentageFormatter
                {...props}
                required
                name="discountValue"
                label={props.intl.formatMessage(messages.discountValue)}
              />
            </Grid.Column>
          )}
          {props.values.discountType.code === 'MARKUP' && (
            <Grid.Column>
              <PercentageFormatter
                {...props}
                required
                name="markup"
                label={props.intl.formatMessage(messages.markup)}
              />
            </Grid.Column>
          )}
          {props.values.discountType.code === 'FLAT' && (
            <Grid.Column>
              <CurrencyFormatter
                {...props}
                required
                name="flatValue"
                label={props.intl.formatMessage(messages.discountValue)}
              />
            </Grid.Column>
          )}
          {props.values.discountType.code === 'PERCENTAGE' && (
            <Grid.Column>
              <PercentageFormatter
                {...props}
                name="discountExtra"
                label={props.intl.formatMessage(messages.extraDiscount)}
              />
            </Grid.Column>
          )}
          {props.values.discountType.code === 'FIXED' && (
            <Grid.Column>
              <CurrencyFormatter
                {...props}
                required
                name="netPrice"
                label={props.intl.formatMessage(messages.net_price_modal)}
              />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    </Form>
  );
};

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BaseForm);
