/* eslint-disable indent */
import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION,
    method: 'GET',
  });

const searchByName = (
  searchText,
  searchProspects = false,
  searchOwnAgent = false,
) =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'businessName',
          value: `%${searchText}%`,
          where: 'or',
        },
        {
          type: 'like',
          where: 'or',
          field: 'code',
          value: `%${searchText}%`,
        },
        searchProspects &&
        searchText && {
          type: 'like',
          field: 'prospectCode',
          where: 'or',
          value: `%${searchText}%`,
        },
        !searchProspects && {
          type: 'isnotnull',
          field: 'customer',
        },
        searchOwnAgent && {
          type: 'search_agent_destination',
        },
        {
          type: 'eq',
          field: 'isActive',
          value: 1,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'code',
          direction: 'asc',
        },
      ].filter(o => o),
    },

    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (
  inputValue,
  callback,
  searchProspects = false,
  searchOwnAgent = false,
) =>
  searchByName(inputValue, searchProspects, searchOwnAgent).then(results =>
    callback(
      _.get(results, 'data._embedded.destination', []).map(item => ({
        value: item.id,
        label: `${item.code} ${item.businessName}`,
        ...item,
      })),
    ),
  );

const searchByNameAndCustomerId = (id, searchText) =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'businessName',
          value: `%${searchText}%`,
        },
        searchText && {
          type: 'like',
          where: 'or',
          field: 'destinationCode',
          value: `%${searchText}%`,
        },
        {
          type: 'innerjoin',
          field: 'customer',
          alias: 'c',
        },
        {
          type: 'eq',
          field: 'id',
          value: id,
          alias: 'c',
          where: 'and',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptionsByCustomerId = async (id, inputValue) => {
  const results = await searchByNameAndCustomerId(id, inputValue);
  return _.get(results, 'data._embedded.destination', []).map(destination => ({
    value: destination.id,
    label: `${destination.destinationCode} - ${destination.businessName}`,
  }));
};

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION,
    method: 'POST',
    data: content,
  });

const get = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION}/${id}`,
    method: 'GET',
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const patch = ({ id, ...content }) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION}/${id}`,
    method: 'PATCH',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION}/${id}`,
    method: 'DELETE',
  });

const exportPDF = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_PDF,
    method: 'GET',
    responseType: 'blob',
  });

const getLinkedDestinations = (idCustomer, idDestinationExcluded) =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'innerjoin',
          field: 'customer',
          alias: 'customer',
        },
        {
          type: 'eq',
          field: 'customer',
          value: idCustomer,
        },
        {
          type: 'neq',
          field: 'id',
          value: idDestinationExcluded,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const DestinationService = {
  patch,
  create,
  update,
  remove,
  get,
  getAll,
  exportPDF,
  getOptions,
  getOptionsByCustomerId,
  searchByNameAndCustomerId,
  getLinkedDestinations,
};

export default DestinationService;
