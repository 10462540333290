import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import { Modal, Header, Icon, Button } from 'semantic-ui-react';
import appMessages from '../../containers/App/messages';

const ConfirmModal = props => {
  const { title, content, onConfirm, onCancel } = props;
  const [open, setOpen] = useState(false);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(
    () => {
      setOpen(props.open);
    },
    [props.open],
  );

  useEffect(
    () => {
      setProcessing(props.isLoading);
    },
    [props.isLoading],
  );

  return (
    <Modal
      closeIcon
      open={open}
      onClose={() => {
        setOpen(false);
        props.onCancel();
      }}
      onOpen={() => setOpen(true)}
    >
      <Header icon="question circle outline" content={title} color="red" />
      <Modal.Content>
        <p>{content}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={() => onCancel()} loading={isProcessing}>
          <Icon name="remove">{props.intl.formatMessage(appMessages.no)}</Icon>
        </Button>
        <Button
          color="green"
          onClick={() => onConfirm()}
          loading={isProcessing}
        >
          <Icon name="checkmark">
            {props.intl.formatMessage(appMessages.yes)}
          </Icon>
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default injectIntl(ConfirmModal);
