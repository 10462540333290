/*
 * ProductCategoryForm Messages
 *
 * This contains all the text for ProductCategoryForm.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.ProductCategory';

export default defineMessages({
  category_saved: {
    id: `${scope}.category_saved`,
    defaultMessage: 'Category successfully saved',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Category description',
  },
  description_placeholder: {
    id: `${scope}.description_placeholder`,
    defaultMessage: 'Insert the category description',
  },
});
