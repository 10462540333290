import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { injectIntl, intlShape } from 'react-intl';
import styled from 'styled-components';
import CustomerDestinationService from '../../shared/services/customerdestination';
import FormikAsyncSelect from '../FormikAsyncSelect';
import messages from '../TopBar/messages';

const SearchWrapper = styled.div`
  & > div.field {
    width: 350px;
    & > label {
      color: #fff;
      position: relative;
      display: none;
      margin-top: -3px;
      margin-bottom: 3px;
    }
  }
`;

export const SearchDestination = props => {
  const ref = useRef();

  return (
    <Formik initialValues={{ destination: '' }} onSubmit={() => null}>
      {({ setFieldValue }) => (
        <SearchWrapper>
          <FormikAsyncSelect
            cacheOptions={false}
            name="destination"
            placeholder={props.intl.formatMessage(
              messages.search_destination_placeholder,
            )}
            onChange={data => {
              props.history.push(`/${data.type}/${data.value}/view`);
              setFieldValue('destination', '');
            }}
            loadOptions={(searchText, callback) =>
              CustomerDestinationService.getJoinedOptions(searchText, callback)
            }
            asyncSelectProps={{
              ref,
              onKeyDown: event => {
                const { keyCode } = event;
                const { loadedOptions } = ref.current.state;

                // Auto-select the destination when press the key enter.
                if (keyCode === 13) {
                  const [value] = loadedOptions;
                  setFieldValue('destination', value);
                }
              },
            }}
          />
        </SearchWrapper>
      )}
    </Formik>
  );
};

SearchDestination.propTypes = {
  intl: intlShape.isRequired,
  history: PropTypes.object,
};

export default injectIntl(SearchDestination);
