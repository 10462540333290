import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.SUPPLIER,
    method: 'GET',
  });

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.SUPPLIER,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'displayName',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByName(inputValue).then(results =>
    callback(
      _.get(results, 'data._embedded.supplier', []).map(supplier => ({
        value: supplier.id,
        label: supplier.displayName,
      })),
    ),
  );
  // eslint-disable-next-line no-useless-return
  return;
};

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.SUPPLIER,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.SUPPLIER}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.SUPPLIER}/${id}`,
    method: 'DELETE',
  });

const SupplierService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
};

export default SupplierService;
