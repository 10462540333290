import _ from 'lodash';
import moment from 'moment';
import { getProfileFromRole } from '../../components/Forms/OfferForm/utils/formHelperFunctions';
import { ORDER_STATE_INSERTED } from './constants';
import { getNetPrice } from '../../components/Forms/OrderForm/manageForm/functions';

const handleProfile = (data, name) => {
  if (!_.isEmpty(data[name])) {
    return {
      profile: data[name].value,
      role: name,
    };
  }
  return null;
};

export const cleanData = (data, props) => {
  const clonedData = _.cloneDeep(data);
  const isDuplicate = props && props.match.params.action === 'duplicate';
  if (isDuplicate) {
    clonedData.orderId = _.get(clonedData, 'id', null);
    clonedData.isDuplicate = 1;
    _.unset(clonedData, 'profiles');
  }
  _.unset(clonedData, 'id');
  _.unset(clonedData, '_links');
  _.unset(clonedData, '_embedded');
  _.unset(clonedData, 'name');
  _.unset(clonedData, 'createdAt');
  _.unset(clonedData, 'updatedAt');
  _.unset(clonedData, 'deletedAt');
  _.unset(clonedData, 'createdBy');
  _.unset(clonedData, 'updatedBy');
  _.unset(clonedData, 'portoResa');
  _.unset(cleanData, 'readAt');
  _.unset(cleanData, 'writeAt');

  clonedData.number = _.get(clonedData, 'number', null);
  clonedData.referenceDate = clonedData.referenceDate
    ? clonedData.referenceDate.format('YYYY-MM-DD')
    : null;

  clonedData.orderType = _.get(clonedData, 'orderType.value', null);
  clonedData.customer = _.get(clonedData, 'customer.value', null);
  clonedData.profiles = [];

  const agent1 = handleProfile(clonedData, 'agent1');
  const agent2 = handleProfile(clonedData, 'agent2');
  const subagent = handleProfile(clonedData, 'subagent');
  const signaller = handleProfile(clonedData, 'signaller');
  const commercialDirector = handleProfile(clonedData, 'commercialDirector');
  const areaManager = handleProfile(clonedData, 'areaManager');

  if (agent1) clonedData.profiles.push(agent1);
  if (agent2) clonedData.profiles.push(agent2);
  if (subagent) clonedData.profiles.push(subagent);
  if (signaller) clonedData.profiles.push(signaller);
  if (areaManager) clonedData.profiles.push(areaManager);
  if (commercialDirector) clonedData.profiles.push(commercialDirector);

  _.unset(clonedData, 'agent1');
  _.unset(clonedData, 'agent2');
  _.unset(clonedData, 'subagent');
  _.unset(clonedData, 'signaller');
  _.unset(clonedData, 'commercialDirector');
  _.unset(clonedData, 'areaManager');
  clonedData.buyingGroup = _.get(clonedData, 'buyingGroup.value', null);
  clonedData.destination = _.get(clonedData, 'destination.value', null);
  clonedData.destinationPaymentTerm = _.get(
    clonedData,
    'destinationPaymentTerm.value',
    null,
  );
  clonedData.deterchimicaBank = _.get(
    clonedData,
    'deterchimicaBank.value',
    null,
  );
  clonedData.destinationBank = _.get(clonedData, 'destinationBank.value', null);
  clonedData.state = _.get(clonedData, 'state.value', clonedData.state);
  clonedData.deliveryRequestDate = clonedData.deliveryRequestDate
    ? clonedData.deliveryRequestDate.format('YYYY-MM-DD')
    : null;
  clonedData.deliveryPromiseDate = clonedData.deliveryPromiseDate
    ? clonedData.deliveryPromiseDate.format('YYYY-MM-DD')
    : null;
  clonedData.deliveryPlanedDate = clonedData.deliveryPlanedDate
    ? clonedData.deliveryPlanedDate.format('YYYY-MM-DD')
    : null;
  clonedData.shippedStoreDate = clonedData.shippedStoreDate
    ? clonedData.shippedStoreDate.format('YYYY-MM-DD')
    : null;

  clonedData.watchers = _.get(clonedData, 'watchers', []).map(
    watcher => watcher.value,
  );

  return clonedData;
};

export const transformData = (data, props) => {
  const isDuplicate = props && props.match.params.action === 'duplicate';
  const state =
    props && props.match.params.action === 'new'
      ? 'Inserita'
      : _.get(data, 'state');
  return {
    ...data,
    deliveryRequestDate: data.deliveryRequestDate
      ? moment(data.deliveryRequestDate)
      : null,
    deliveryPromiseDate: data.deliveryPromiseDate
      ? moment(data.deliveryPromiseDate)
      : null,
    deliveryPlanedDate: data.deliveryPlanedDate
      ? moment(data.deliveryPlanedDate)
      : null,
    shippedStoreDate: data.shippedStoreDate
      ? moment(data.shippedStoreDate)
      : null,
    referenceDate: data.referenceDate ? moment(data.referenceDate) : null,
    number: !isDuplicate ? _.get(data, 'number', '') : '',
    orderType: {
      value: _.get(data, 'orderType.id'),
      label: _.get(data, 'orderType.description'),
    },
    customer: {
      value: _.get(data, '_embedded.customer.id'),
      label: _.get(data, '_embedded.customer.displayName'),
    },
    agent1: getProfileFromRole(data, 'agent1', true),
    agent2: getProfileFromRole(data, 'agent2', true),
    subagent: getProfileFromRole(data, 'subagent', true),
    signaller: getProfileFromRole(data, 'signaller', true),
    commercialDirector: getProfileFromRole(data, 'commercialDirector', true),
    areaManager: getProfileFromRole(data, 'areaManager', true),
    buyingGroup: {
      value: _.get(data, '_embedded.buyingGroup.id'),
      label: _.get(data, '_embedded.buyingGroup.displayName'),
    },
    destination: {
      value: _.get(data, '_embedded.destination.id'),
      label: _.get(data, '_embedded.destination.businessName'),
    },
    deterchimicaBank: {
      value: _.get(data, '_embedded.deterchimicaBank.id'),
      label: _.get(data, '_embedded.deterchimicaBank.name'),
    },
    destinationBank: {
      value: _.get(data, '_embedded.destinationBank.id'),
      label: _.get(data, '_embedded.destinationBank.description'),
    },
    destinationPaymentTerm: {
      value: _.get(data, '_embedded.destinationPaymentTerm.id'),
      label: _.get(data, '_embedded.destinationPaymentTerm.description'),
    },
    state: {
      value: state,
      label: state,
    },
    watchers: _.get(data, 'watchers', []).map(
      watcher =>
        _.get(watcher, 'id') && {
          value: _.get(watcher, 'id'),
          label: _.get(watcher, 'displayName'),
        },
    ),
  };
};

const defaultDate = id => {
  if (typeof id === 'undefined' || !id) {
    return moment();
  }

  return null;
};

export const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  transportedBy: _.get(newValues, 'id', null)
    ? _.get(newValues, 'transportedBy', '')
    : _.get(newValues, 'transportedBy', 'Vettore DTM'),
  number: _.get(newValues, 'number', ''),
  deliveryRequestDate: newValues.deliveryRequestDate
    ? moment(newValues.deliveryRequestDate)
    : null,
  deliveryPromiseDate: newValues.deliveryPromiseDate
    ? moment(newValues.deliveryPromiseDate)
    : null,
  deliveryPlanedDate: newValues.deliveryPlanedDate
    ? moment(newValues.deliveryPlanedDate)
    : null,
  shippedStoreDate: newValues.shippedStoreDate
    ? moment(newValues.shippedStoreDate)
    : null,
  referenceDate: newValues.referenceDate
    ? moment(newValues.referenceDate)
    : defaultDate(newValues.id),
  orderType: {
    value: _.get(newValues, 'orderType.id'),
    label: _.get(newValues, 'orderType.description'),
  },
  customer: {
    value: _.get(newValues, 'customer.value'),
    label: _.get(newValues, 'customer.label'),
  },
  agent1: getProfileFromRole(newValues, 'agent1', true),
  agent2: getProfileFromRole(newValues, 'agent2', true),
  subagent: getProfileFromRole(newValues, 'subagent', true),
  signaller: getProfileFromRole(newValues, 'signaller', true),
  commercialDirector: getProfileFromRole(newValues, 'commercialDirector', true),
  areaManager: getProfileFromRole(newValues, 'areaManager', true),
  buyingGroup: {
    value: _.get(newValues, '_embedded.buyingGroup.id'),
    label: _.get(newValues, '_embedded.buyingGroup.displayName'),
  },
  destination: {
    value: _.get(newValues, 'destination.value'),
    label: _.get(newValues, 'destination.label'),
  },
  deterchimicaBank: {
    value: _.get(newValues, '_embedded.deterchimicaBank.id'),
    label: _.get(newValues, '_embedded.deterchimicaBank.name'),
  },
  destinationBank: {
    value: _.get(newValues, '_embedded.destinationBank.id'),
    label: _.get(newValues, '_embedded.destinationBank.description'),
  },
  destinationPaymentTerm: {
    value: _.get(newValues, '_embedded.destinationPaymentTerm.id'),
    label: _.get(newValues, '_embedded.destinationPaymentTerm.description'),
  },
  state: {
    value: ORDER_STATE_INSERTED,
    label: ORDER_STATE_INSERTED,
  },
  isNew: _.get(newValues, 'isNew', false),
});

export const applyMerchDiscount = (checked, data, onChange) => {
  if (checked) {
    onChange(true, 'discountDisabled', data.id);
    onChange(true, 'discountExtraDisabled', data.id);
    onChange(true, 'netPriceDisabled', data.id);
    onChange(100, 'discountValue', data.id);
    onChange('0', 'discountExtra', data.id);
    onChange('0', 'netPrice', data.id);
  }
  if (!checked) {
    onChange(false, 'discountDisabled', data.id);
    onChange(false, 'discountExtraDisabled', data.id);
    onChange(false, 'netPriceDisabled', data.id);
    onChange('0', 'discountValue', data.id);
    onChange('0', 'discountExtra', data.id);

    const netPrice = getNetPrice(data);

    if (data.discountValue + data.discountExtra < 100)
      if (data.discountValue !== '') {
        const percentage = 100 - (data.discountValue + data.discountExtra);
        onChange(
          parseFloat((netPrice / 100) * percentage).toFixed(2),
          'netPrice',
          data.id,
        );
      }
  }

  if (!checked)
    onChange(data.netPrice * parseFloat(data.quantity, 10), 'amount', data.id);
  if (checked) onChange(0, 'amount', data.id);
};
