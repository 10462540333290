// import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getById = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.STAFF_WORK_ORDER_TAB}/${id}`,
    method: 'GET',
  });

const StaffWorkOrderTabService = {
  getById,
};

export default StaffWorkOrderTabService;
