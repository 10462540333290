import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_INVOICE_AMOUNT,
    method: 'POST',
    data: content,
  });

const createException = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_EXCEPTION_COMMISSION,
    method: 'POST',
    data: content,
  });

const updateException = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_EXCEPTION_COMMISSION}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const removeException = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_EXCEPTION_COMMISSION}/${id}`,
    method: 'DELETE',
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_INVOICE_AMOUNT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_INVOICE_AMOUNT}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (destinationId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_INVOICE_AMOUNT}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'destination',
          value: destinationId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOwnerExceptionCommissions = (destinationId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_EXCEPTION_COMMISSION}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'destination',
          value: destinationId,
        },
      ],
      'order-by': [
        {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const DestinationInvoiceAmountService = {
  getByOwner,
  create,
  update,
  createException,
  updateException,
  remove,
  getOwnerExceptionCommissions,
  removeException,
};

export default DestinationInvoiceAmountService;
