import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import NovigoAccordion from 'novigo-accordion-divider';
import styled from 'styled-components';
import { makeSelectStyles } from '../../containers/App/selectors';

const PaddedContent = styled.div`
  padding-top: 10px;
  padding-bottom: 16px;
`;

export function Accordion(props) {
  const styleBorder = `1px solid ${props.styles.SECOND_COLOR}`;
  return (
    <NovigoAccordion
      {...props}
      title={props.title.toUpperCase()}
      hrStyleBorder={styleBorder}
      iconStyle={{ color: props.styles.SECOND_COLOR }}
    >
      <PaddedContent>{props.children}</PaddedContent>
    </NovigoAccordion>
  );
}

const mapStateToProps = createStructuredSelector({
  styles: makeSelectStyles(),
});

const withConnect = connect(mapStateToProps);

Accordion.defaultProps = {};

Accordion.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default compose(withConnect)(Accordion);
