export const currencyFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});

export const numberFormatter = new Intl.NumberFormat('it-IT');

const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

export const abbreviateNumber = number => {
  // what tier? (determines SI symbol)
  const tier = Math.log10(number) / 3 | 0; // eslint-disable-line

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3); // eslint-disable-line

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
};

export default currencyFormatter;
