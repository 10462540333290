/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Icon } from 'semantic-ui-react';
import validation from './validation';
import { FormTab } from '../../Layout';
import FormActionsBar from '../../FormActionsBar';
import messages from './messages';
import GeneralTab from './tabs/generalTab';
import DestinationsTab from './tabs/destinationsTab';
import appMessages from '../../../containers/App/messages';
import { addNotification } from '../../../utils/notification';
import CustomerService from '../../../shared/services/customerdestination';
import ProspectToCustomerService from '../../../shared/services/prospecttocustomer';

export class CustomerMask extends React.Component {
  state = {
    transforming: false,
    activeIndex: 0,
    allowedStates: [],
    allowedStatesLoading: true,
  };

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  transformToCustomer = () => {
    this.setState({ transforming: true });
    ProspectToCustomerService.transformToCustomer(this.props.values.id)
      .then(() => {
        this.props.history.push(`/headquarter/${this.props.values.id}/modify`);
        this.setState({ transforming: false });
        addNotification({
          title: null,
          message: this.context.intl.formatMessage(
            messages.to_customer_success,
          ),
          isError: false,
        });
      })
      .catch(error => {
        this.setState({ transforming: false });
        addNotification({
          title: null,
          message: error.data.detail,
          isError: true,
        });
      });
  };

  render() {
    const {
      handleSubmit,
      isNew,
      readOnly = false,
      isSubmitting = false,
      onDelete = false,
      isDeleting = false,
      showDeleteButton = false,
    } = this.props;

    const panes = [
      {
        menuItem: this.context.intl.formatMessage(messages.tab_general_data),
        render: () => (
          <FormTab.Pane>
            <GeneralTab
              {...this.props}
              allowedStates={this.state.allowedStates}
              allowedStatesLoading={this.state.allowedStatesLoading}
            />
          </FormTab.Pane>
        ),
      },
      !isNew && {
        menuItem: this.context.intl.formatMessage(messages.tab_customers),
        render: () => (
          <FormTab.Pane>
            <DestinationsTab
              customerService={CustomerService}
              {...this.props}
              entity="destination"
              allowedStates={this.state.allowedStates}
              allowedStatesLoading={this.state.allowedStatesLoading}
            />
          </FormTab.Pane>
        ),
      },
    ];
    const showTransformButton =
      !this.props.isNew && this.props.mainEntity === 'prospect';
    return (
      <Form onSubmit={handleSubmit}>
        <FormTab
          panes={panes}
          activeIndex={this.state.activeIndex}
          onTabChange={this.handleTabChange}
          className="formTabs"
        />
        <FormActionsBar
          showDeleteButton={showDeleteButton}
          readOnly={readOnly}
          isValid
          isDeleting={isDeleting}
          isSubmitting={isSubmitting}
          editRoute={this.props.editRoute}
          backRoute={!isNew && `/headquarter`}
          onDelete={onDelete}
          history={this.props.history}
          moreButtons={
            showTransformButton && (
              <Button
                loading={this.state.transforming}
                style={{ marginRight: '15px' }}
                type="button"
                icon
                onClick={() => this.transformToCustomer()}
                labelPosition="left"
                color="blue"
              >
                <Icon name="sync alternate" />
                {this.context.intl.formatMessage(appMessages.to_customer)}
              </Button>
            )
          }
        />
      </Form>
    );
  }
}

CustomerMask.defaultProps = {
  errors: [],
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

CustomerMask.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

CustomerMask.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default CustomerMask;

export { validation };
