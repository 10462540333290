import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { isEqual, sortBy, cloneDeep } from 'lodash';
import Indicator from './indicator';
import { DEFAULT_INDICATOR } from '../constants';

const Indicators = props => {
  const {
    indicators,
    onChange,
    showToolsField = true,
    showSpeciesField = true,
  } = props;

  const [localIndicators, setLocalIndicators] = useState([]);

  useEffect(
    () => {
      if (!isEqual(sortBy(localIndicators), sortBy(indicators))) {
        setLocalIndicators(indicators);
      }
    },
    [indicators],
  );

  const increment = id => {
    const newList = localIndicators.map(item => {
      let percent;
      if (item.key === id) {
        if (item.percent >= 0) {
          percent = item.percent + 25;
        }
        if (item.percent > 99) {
          percent = 0;
        }
        return { ...item, percent };
      }
      return item;
    });

    onChange(newList);
  };

  const addIndicator = () => {
    const newIndicator = {
      id: null,
      key: localIndicators.length + 1,
      name: `P${localIndicators.length + 1}`,
      tool: { key: '', label: '', value: null },
      area: { key: '', label: '', value: null },
      principle: { key: '', label: '', value: null },
      percent: 0,
      description: '',
      specie: '',
    };

    const currentIndicators = cloneDeep(localIndicators);
    currentIndicators.push(newIndicator);
    onChange(currentIndicators);
  };

  const removeIndicator = id => {
    const filteredList = localIndicators.filter(item => item.key !== id);
    onChange(filteredList);
  };

  const setFieldValue = ({ key, name, value }) => {
    const mergeObject = {};
    mergeObject[`${name}`] = value;
    const newList = localIndicators.map(
      item => (item.key === key ? { ...item, ...mergeObject } : item),
    );

    setLocalIndicators(newList);
    onChange(newList);
  };

  const columnsNumber = () => {
    let columnsNr = 8;

    if (showToolsField === false) {
      columnsNr -= 1;
    }

    if (showSpeciesField === false) {
      columnsNr -= 1;
    }

    return columnsNr;
  };

  return (
    <React.Fragment>
      <Grid.Row columns={columnsNumber()}>
        <Grid.Column width={1} textAlign="center" verticalAlign="middle">
          <h3>P</h3>
        </Grid.Column>

        {showToolsField && (
          <Grid.Column width={2} textAlign="left" verticalAlign="middle">
            <h3>Disp</h3>
          </Grid.Column>
        )}

        <Grid.Column width={2} textAlign="left" verticalAlign="middle">
          <h3>Area</h3>
        </Grid.Column>

        <Grid.Column width={5} textAlign="center" verticalAlign="middle">
          <h3>Traccie Infestanti</h3>
        </Grid.Column>

        <Grid.Column width={1} textAlign="left" verticalAlign="middle">
          <h3>Principi</h3>
        </Grid.Column>

        {showSpeciesField && (
          <Grid.Column width={2} textAlign="left" verticalAlign="middle">
            <h3>Specie</h3>
          </Grid.Column>
        )}

        <Grid.Column width={2} textAlign="left" verticalAlign="middle">
          <h3>Note</h3>
        </Grid.Column>

        <Grid.Column width={1} textAlign="center" verticalAlign="middle">
          <h3>Azioni</h3>
        </Grid.Column>
      </Grid.Row>
      {localIndicators.map(item => (
        <Indicator
          {...props}
          key={item.id}
          data={item}
          increment={increment}
          addIndicator={addIndicator}
          removeIndicator={removeIndicator}
          setFieldValue={setFieldValue}
          rowColumns={columnsNumber()}
        />
      ))}
    </React.Fragment>
  );
};

Indicators.propTypes = {
  indicators: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  showToolsField: PropTypes.bool,
  showSpeciesField: PropTypes.bool,
};

const TabIndicators = props => {
  const { readOnly, indicators, showSpeciesField, showToolsField } = props;

  const [localIndicators, setLocalIndicators] = useState([]);

  useEffect(
    () => {
      if (
        Array.isArray(indicators) &&
        !isEqual(sortBy(localIndicators), sortBy(indicators))
      ) {
        setLocalIndicators(indicators);
      }
    },
    [indicators],
  );

  return (
    <React.Fragment>
      {localIndicators.length > 0 ? (
        <Indicators
          showSpeciesField={showSpeciesField}
          showToolsField={showToolsField}
          indicators={localIndicators}
          onChange={newIndicators => {
            props.onChange(newIndicators);
          }}
        />
      ) : (
        <Grid.Row>
          <Grid.Column width={16} textAlign="center" verticalAlign="middle">
            <Button
              icon
              type="button"
              disabled={readOnly}
              onClick={() => {
                setLocalIndicators([DEFAULT_INDICATOR]);
                props.onChange([DEFAULT_INDICATOR]);
              }}
            >
              <Icon name="plus" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      )}
    </React.Fragment>
  );
};

TabIndicators.propTypes = {
  // values: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  showToolsField: PropTypes.bool,
  showSpeciesField: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  indicators: PropTypes.array,
};

export default TabIndicators;
