import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import DocumentPart from '../../Anagrafica/formParts/documentsPart';

import ProductAttachmentService from '../../../../shared/services/productattachment';

function AttachmentsTab(props) {
  return (
    <DocumentPart
      {...props}
      entity="product"
      documentService={ProductAttachmentService}
    />
  );
}

AttachmentsTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AttachmentsTab);
