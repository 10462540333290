import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Modal, Dropdown } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import PasswordForm from '../Password';
import MyselfService from '../../../shared/services/myself';
import messages from './messages';
import appMessages from '../../../containers/App/messages';
import { addNotification } from '../../../utils/notification';

// eslint-disable-next-line react/prefer-stateless-function
export default class ChangePassword extends Component {
  static propTypes = {
    notification: PropTypes.func,
    styles: PropTypes.object,
  };

  state = {
    modalOpened: false,
  };

  changePassword = data => {
    MyselfService.changePassword(data)
      .then(() => {
        this.setState({ modalOpened: false });
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: this.context.intl.formatMessage(
            appMessages.operation_performed_successfully,
          ),
          isError: false,
        });
      })
      .catch(error =>
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      );
  };

  render() {
    const { modalOpened } = this.state;
    return (
      <Modal
        closeIcon
        onClose={() => this.setState({ modalOpened: false })}
        open={modalOpened}
        trigger={
          <FormattedMessage {...messages.password_change}>
            {passwordChange => (
              <Dropdown.Item
                onClick={() => this.setState({ modalOpened: true })}
                key="cambiopassword"
                text={passwordChange}
                icon="key"
              />
            )}
          </FormattedMessage>
        }
        header={
          <div className="header">
            <FormattedMessage {...messages.header} />
          </div>
        }
        content={
          <PasswordForm
            styles={this.props.styles}
            onSubmit={this.changePassword}
          />
        }
        size="tiny"
      />
    );
  }
}

ChangePassword.contextTypes = {
  intl: PropTypes.object.isRequired,
};
