import React from 'react';
import PropTypes from 'prop-types';

import { Pagination } from 'semantic-ui-react';

import get from 'lodash/get';

const Paginator = ({ data, changePage }) => (
  <Pagination
    activePage={get(data, 'page', 0)}
    onPageChange={(e, { activePage }) => changePage(activePage)}
    totalPages={get(data, 'page_count', 0)}
  />
);

Paginator.defaultProps = {
  data: null,
};

Paginator.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  changePage: PropTypes.func.isRequired,
};

export default Paginator;
