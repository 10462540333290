import React from 'react';
import Styled from 'styled-components';
import { Grid, Segment, Tab } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectStyles } from '../../containers/App/selectors';
import bg from '../../images/bg-login.jpg';

const mapStateToProps = createStructuredSelector({
  styles: makeSelectStyles(),
});

const withConnect = connect(mapStateToProps);

export const BreadcrumbWrapper = compose(withConnect)(Styled.div`
  font-size: 12px;
  position: absolute;
  top: 6px;
  left: 16px;
  & a {
    color: ${props => props.styles.SECOND_COLOR};
  }
`);

export const GreyBackground = Styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #eeeeee;
  z-index: 0;
`;

export const Wrapper = Styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  padding: 11px;
`;

export const H2 = Styled.h2`
  display: block;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 7px;
`;

export const SearchAddressWrapper = Styled.div`
margin-top: -38px;
padding-bottom: 20px;
z-index: 3;
position: relative;

& .ui.basic.buttons {
  position: absolute !important;
  right: 14px !important;
  top: -4px !important;
  z-index: 9;
}

& > .ui.basic.segment {
  padding: 0px !important;
  & > .field {
    & > .ui.grid {
      & > .row:nth-of-type(1) {
        background-color: red;
        padding: 0px!important;
      }
      & > .row:nth-of-type(2) {
        & > .column {
          & > .grid {
            & > .column {
              padding-bottom: 0px!important;
            }
          }
        }
      }
    }
  }
}
`;

export const PaddedColumn = Styled(Grid.Column)`
  padding-bottom: 20px;
`;

export const WrapperTool = Styled.div`
  text-align: right;
`;

export const ModalFakeActions = Styled.div`
  background: #f9fafb;
  padding: 1rem 1rem;
  border-top: ${props =>
    props.noBorder ? 'none' : ' 1px solid rgba(34,36,38,.15)'};
  margin-left: -7px;
  margin-right: -7px;
  margin-bottom: -7px;
  display: block!important;
  box-sizing: content-box;
  width: 100%;
`;

export const FormActions = Styled.div`
    background: #f9fafb;
    padding: 1rem 1rem;
    border-top: 1px solid rgba(34,36,38,.15);
    display: block!important;
    /*box-sizing: content-box;*/
    min-width: 100%;
    margin-bottom: -28px;
    margin-left: -28px;
    margin-right: -28px;
    margin-top: 28px;
`;

export const ListWrapper = compose(withConnect)(Styled.div`
/* TODO: Da riportare su entity grid come config */
.ui.table tbody > tr > td i.icon
{
  /* TODO: Da riportare su entity grid come pulsanti */
  font-size: 14px !important;
  width: 36px!important;
  height: 36px!important;
  padding-top: .8em!important;
}
/* More actions button */
.ui.table tbody > tr > td > div.ui.circular.primary.button {
  font-size: 14px !important;
  width: 36px!important;
  height: 36px!important;
}

.ui.table tbody > tr > td .menu.transition.visible .item {
  padding: 3px 14px 3px 3px!important;
}

.ui.table tbody > tr > td .menu.transition.visible .divider {
  margin: 0px!important;
}

.ui.table tbody > tr > td > div.ui.circular.primary.button > div > .ui.dropdown {
  padding: 0px 0px !important;
  left: 0px !important;
  top: 0px;
}

.input.search > .ui.icon.button.label {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.table td:last-child {
  width: 160px !important;
}

.title {
  background-color: ${props => props.styles.SECOND_COLOR} !important;
  font-size: 12px !important;
  height: 20px !important;
  padding: 1px !important;
  & .ui.label {
    background-color: #fff !important;
    color: ${props => props.styles.SECOND_COLOR}  !important;
    margin-top: 2px;
    margin-right: 2px;
    font-size: 7px;
  }
}
`);

export const Page = Styled(Segment)`
  min-height: 88vh;
  background-color: red;
  min-width: 1040px;
  .geosuggest__input {
    width: 100% !important;
}
`;

export const TableGlobalSearch = Styled.div`
.ui.table thead > tr > th > div div > div > div > input {
  width: auto !important;
}
`;

const BackgroundContainer = Styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 25%;
  z-index: 0;
`;

const BackgroundImage = Styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  opacity: 1;
`;

export const FormTab = Styled(Tab)`
  margin-left: -28px;
  margin-right: -28px;
  & .ui.attached.tabular.menu {
    padding-left: 30px;
    padding-right: 30px;
  }

  & .ui.attached.tabular.menu {
    display: flex;
    flex-wrap: wrap;
  }

  & .ui.tabular.menu .item {
    max-width: 120px;
    text-align: center;
    padding-left: 12px;
    padding-right: 12px;
  }

  & .ui.bottom.attached.segment.active.tab {
    border-left: 0px solid;
    border-bottom: 0px solid;
    border-radius: 0px;
  }
`;

// eslint-disable-next-line react/prefer-stateless-function
export const LoginBackground = () => (
  <BackgroundContainer>
    <BackgroundImage className="fadeInImg" />
  </BackgroundContainer>
);

export const AuthenticatedWrapper = compose(withConnect)(Styled.div`
  padding-top: ${props => props.styles.TOPBAR_HEIGHT}px;
  transition: padding-left 0.1s;
  padding-left: ${props =>
    props.collapsed
      ? props.styles.SIDEBAR_COLLAPSED_WIDTH
      : props.styles.SIDEBAR_WIDTH}px;
`);

export const notificationsStyle = {
  NotificationItem: {
    DefaultStyle: {
      margin: '10px 5px 2px 1px',
    },
  },
};
