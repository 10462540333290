import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import BaseForm from './form';
import { addNotification } from '../../../utils/notification';
import { ModalFakeActions } from '../../Layout';
import appMessages from '../../../containers/App/messages';
import getError from '../../../utils/getError';
import messages from './messages';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  description: _.get(newValues, 'description', ''),
  releaseDate: _.get(newValues, 'releaseDate.date', null)
    ? moment(newValues.releaseDate.date) // TODO: Sistemare e definire formato unico per le date
    : null,
  expireDate: _.get(newValues, 'expireDate.date', null)
    ? moment(newValues.expireDate.date) // TODO: Sistemare e definire formato unico per le date
    : null,
  attachmentId: _.get(newValues, '_embedded.attachmentId', ''),
  attachmentTypeId: _.get(newValues, '_embedded.attachmentTypeId', ''),
});

export default class CustomerDocument extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    entity: PropTypes.string,
    contactId: PropTypes.string,
    documentTypes: PropTypes.array,
    documentService: PropTypes.object,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      [this.props.entity]: this.props.entityId,
      attachmentTypeId:
        _.get(values, 'attachmentTypeId.id') ||
        _.get(values, 'attachmentTypeId'),
      description: _.get(values, 'description', ''),
      releaseDate: _.get(values, 'releaseDate', null)
        ? values.releaseDate.format('YYYY-MM-DD')
        : null,
      expireDate: _.get(values, 'expireDate', '')
        ? values.expireDate.format('YYYY-MM-DD')
        : null,
      attachmentId: _.get(values, 'attachmentId', ''),
    };

    const method = payload.id
      ? this.props.documentService.update
      : this.props.documentService.create;

    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: this.context.intl.formatMessage(messages.document_saved),
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setErrors(getError(err));
        setSubmitting(false);
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={initialValues(this.props.initialValues || {})}
            onSubmit={this.onSubmit}
            render={props => (
              <BaseForm
                {...props}
                entityId={this.props.entityId}
                documentTypes={this.props.documentTypes}
              />
            )}
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.close}
            >
              <Icon name="remove" />
              {this.context.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Button
              type="button"
              icon
              labelPosition="left"
              color="green"
              onClick={this.submit}
            >
              <Icon name="checkmark" />
              {this.context.intl.formatMessage(appMessages.save)}
            </Button>
          </div>
        </ModalFakeActions>
      </Grid>
    );
  }
}

CustomerDocument.contextTypes = {
  intl: PropTypes.object.isRequired,
};
