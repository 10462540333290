/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/forbid-prop-types */

import PropTypes from 'prop-types';
import React from 'react';
// import { FormattedMessage } from 'react-intl';
import { Form } from 'semantic-ui-react';
import messages from '../../../containers/Invoices/messages';
import Accordion from '../../Accordion';
import { FormBackBtn } from '../../Buttons';
import { ModalFakeActions } from '../../Layout';
import HeadingPart from './formParts/headingPart';
import RowsPart from './formParts/rowsPart';
import validation from './validation';

export class InvoiceMask extends React.Component {
  render() {
    const { handleSubmit } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Accordion
          title={this.context.intl.formatMessage(messages.invoice_header)}
        >
          <HeadingPart {...this.props} />
        </Accordion>
        {this.props.values.id && (
          <Accordion
            title={this.context.intl.formatMessage(messages.invoice_rows)}
          >
            <RowsPart {...this.props} />
          </Accordion>
        )}
        <ModalFakeActions>
          <FormBackBtn history={this.props.history} />
        </ModalFakeActions>
      </Form>
    );
  }
}

InvoiceMask.defaultProps = {
  errors: [],
  registryType: 'L',
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

InvoiceMask.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  registryType: PropTypes.string,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

InvoiceMask.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default InvoiceMask;

export { validation };
