export function selectNetPrice(req) {
  let res = 0;
  const discountValue = parseFloat(req.discountValue);
  const discountExtra = parseFloat(req.extraDiscount);

  const totalDiscount = discountValue + discountExtra;
  if (totalDiscount === 0) {
    if (req.productCustomerPrice !== null) res = req.productCustomerPrice;
    else if (req.offerPrice !== null) res = req.offerPrice;
    else res = req.productMasterPrice;
  } else {
    res = req.productMasterPrice;
  }

  return res;
}

export function getNetPrice(req) {
  let res = 0;
  if (req.productCustomerPrice !== null) res = req.productCustomerPrice;
  else if (req.offerPrice !== null) res = req.offerPrice;
  else res = req.productMasterPrice;

  return res;
}
