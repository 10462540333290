import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = ({ page = 1, pageSize = 5, order = [], filters = [] }) =>
  request({
    baseURL: API.BASE_URL,
    url: API.CUSTOMER_PRODUCT_PURCHASE,
    method: 'GET',
    params: {
      page,
      pageSize,
      filter: filters.filter(f => f),
      'order-by': order.filter(o => o),
    },
    paramsSerializer: qs.stringify,
  });

const CustomerProductPurchaseService = {
  getAll,
};

export default CustomerProductPurchaseService;
