/**
 *
 * Staff
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import _ from 'lodash';
import moment from 'moment';
import SimpleFormikField from 'novigo-simple-formik-field';
import Select from 'react-select';
import FormManager from 'novigo-form-manager';
import { Header, Grid } from 'semantic-ui-react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import ListManager from 'novigo-entity-grid';
import makeSelectStaff from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import appMessages from '../App/messages';
import { addNotification } from '../../utils/notification';
import { ListWrapper, Page } from '../../components/Layout';
import MunicipalityService from '../../shared/services/municipality';
import StaffService from '../../shared/services/staff';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import PermissionManager from '../../components/Permission';

/*
 * Specific import
 */

import { API } from '../../global-constants';

import request from '../../shared/services/request';

import StaffMask, { validation } from '../../components/Forms/StaffForm';

/* eslint-disable react/prefer-stateless-function */
export class Staff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      roleOptions: [],
    };
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'staff',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'view' ||
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'modify' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showForm: true });
    } else {
      this.setState({ showForm: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.checkPermission();
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.checkPermission();
    this.evaluteAction();
    this.getRoles();
  }

  getRoles = () =>
    StaffService.getStaffRolesOptions().then(res =>
      this.setState({ roleOptions: [{ value: null, label: 'Tutti' }, ...res] }),
    );

  getUserName = data => {
    const FirstName = _.get(
      data,
      'user.firstname',
      _.get(data, '_embedded.user.firstname', ''),
    );
    const LastName = _.get(
      data,
      'user.lastname',
      _.get(data, '_embedded.user.lastname', ''),
    );

    return `${FirstName} ${LastName}`;
  };

  transformData = data => {
    const user = {
      value: _.get(data, 'user.id', _.get(data, '_embedded.user.id', '')),
      label: this.getUserName(data),
    };

    return {
      ...data,
      user,
      birthDate: data.birthDate ? moment(data.birthDate) : null,
      staffDepartmentId: _.get(data, '_embedded.staffDepartmentId')
        ? {
            label: _.get(data, '_embedded.staffDepartmentId.name'), // eslint-disable-line
            value: _.get(data, '_embedded.staffDepartmentId.id'), // eslint-disable-line
          } // eslint-disable-line
        : '',
      staffRoleId: _.get(data, '_embedded.staffRoleId')
        ? {
            label: _.get(data, '_embedded.staffRoleId.name'), // eslint-disable-line
            value: _.get(data, '_embedded.staffRoleId.id'), // eslint-disable-line
          } // eslint-disable-line
        : '',
    };
  };

  render() {
    /**
    if (!_.get(this.props.user, ['permissions', Staff, 'list'], false)) {
      return null;
    }
    */
    return (
      <>
        <Helmet>
          <title>Staff</title>
          <meta name="description" content="Staff" />
        </Helmet>
        <BreadcrumbsItem to="/staff">
          {this.context.intl.formatMessage(messages.title)}
        </BreadcrumbsItem>
        <Page>
          <Header as="h2" dividing>
            <FormattedMessage {...messages.title} />
          </Header>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/staff/edit">
                {actionToBreadcrumb({
                  action: this.props.match.params.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                key={this.props.match.params.action} // necessary to reload the current view upon redirect
                router={this.props} // necessary to use the internal routing
                client={pars =>
                  request(pars).then(res => this.transformData(res.data))
                }
                mask={props => (
                  <StaffMask
                    {...props}
                    municipalityService={MunicipalityService}
                    staffService={StaffService}
                    editRoute={`/staff/${this.props.match.params.id}/modify`}
                    history={this.props.history}
                  />
                )}
                validation={values => validation(values, this.context.intl)}
                onCleanData={data => {
                  const cleanData = _.cloneDeep(data);

                  _.unset(cleanData, 'id');
                  _.unset(cleanData, '_links');
                  _.unset(cleanData, '_embedded');
                  _.unset(cleanData, 'displayName');
                  _.unset(cleanData, 'staffWorkorders');
                  _.unset(cleanData, 'isActive');
                  _.unset(cleanData, 'code');

                  cleanData.user = _.get(cleanData, 'user.value', null);

                  cleanData.staffDepartmentId = _.get(
                    cleanData,
                    'staffDepartmentId.value',
                  )
                    ? cleanData.staffDepartmentId.value
                    : cleanData.staffDepartmentId;

                  cleanData.staffRoleId = _.get(cleanData, 'staffRoleId.value')
                    ? cleanData.staffRoleId.value
                    : cleanData.staffRoleId;

                  cleanData.birthDate = cleanData.birthDate
                    ? cleanData.birthDate.format('YYYY-MM-DD')
                    : null;

                  return cleanData;
                }}
                entityName="staff"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                onError={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  })
                }
                onSuccess={() =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
              />
            </>
          ) : (
            <ListWrapper>
              <ListManager
                entityName="staff"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                router={this.props}
                client={pars => request(pars).then(res => res.data)}
                locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                translations={{
                  createButton: {
                    label: this.context.intl.formatMessage(messages.new_staff),
                  },
                }}
                sessionKey="dipendenti"
                defaultOrder={[
                  {
                    type: 'field',
                    field: 'createdAt',
                    direction: 'desc',
                  },
                ]}
                canCreate={{
                  active: PermissionManager.CanI('create', 'staff'),
                }}
                aliases={[
                  {
                    type: 'leftjoin',
                    field: 'staffRoleId',
                    alias: 'role',
                  },
                  {
                    type: 'leftjoin',
                    field: 'staffDepartmentId',
                    alias: 'department',
                  },
                ]}
                canSearchCustom={{
                  active: true,
                  title: this.context.intl.formatMessage(appMessages.search),
                  buttonLabel: this.context.intl.formatMessage(
                    appMessages.search,
                  ),
                  formStructure: (
                    <>
                      <Grid.Column>
                        <SimpleFormikField
                          name="registrationNumber"
                          label={this.context.intl.formatMessage(
                            messages.registrationNumber,
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SimpleFormikField
                          name="firstName"
                          label={this.context.intl.formatMessage(
                            messages.firstName,
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SimpleFormikField
                          name="lastName"
                          label={this.context.intl.formatMessage(
                            messages.lastName,
                          )}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SimpleFormikField name="role" label="Ruolo">
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          }) => (
                            <Select
                              className="basic-single"
                              classNamePrefix="select"
                              name={field.name}
                              options={this.state.roleOptions}
                              value={
                                this.state.roleOptions.find(
                                  opt => opt.label === values.role,
                                ) || this.state.roleOptions[0]
                              }
                              onChange={data =>
                                setFieldValue('role', data.label)
                              }
                            />
                          )}
                        </SimpleFormikField>
                      </Grid.Column>
                    </>
                  ),
                  onApplyFilter: (
                    values,
                    formikActionsBag,
                    applyfilterFunction,
                  ) => {
                    const filters = [];
                    const filterLabels = [];

                    if (values.registrationNumber) {
                      filterLabels.push({
                        key: 'registrationNumber',
                        name: this.context.intl.formatMessage(
                          messages.registrationNumber,
                        ),
                        value: values.registrationNumber,
                        filterField: 'registrationNumber',
                      });
                      filters.push({
                        type: 'like',
                        where: 'and',
                        field: 'registrationNumber',
                        value: `%${values.registrationNumber}%`,
                      });
                    }
                    if (values.firstName) {
                      filterLabels.push({
                        key: 'firstName',
                        name: this.context.intl.formatMessage(
                          messages.firstName,
                        ),
                        value: values.firstName,
                        filterField: 'firstName',
                      });
                      filters.push({
                        type: 'like',
                        where: 'and',
                        field: 'firstName',
                        value: `%${values.firstName}%`,
                      });
                    }
                    if (values.lastName) {
                      filterLabels.push({
                        key: 'lastName',
                        name: this.context.intl.formatMessage(
                          messages.lastName,
                        ),
                        value: values.lastName,
                        filterField: 'lastName',
                      });
                      filters.push({
                        type: 'like',
                        where: 'and',
                        field: 'lastName',
                        value: `%${values.lastName}%`,
                      });
                    }
                    if (
                      values.role &&
                      this.state.roleOptions.find(
                        opt => opt.label === values.role,
                      ).value
                    ) {
                      filterLabels.push({
                        key: 'role',
                        name: this.context.intl.formatMessage(messages.role),
                        value: values.role,
                        filterField: 'role',
                      });
                      filters.push({
                        type: 'eq',
                        alias: 'role',
                        field: 'name',
                        value: values.role,
                        // where: 'or',
                      });
                    }

                    applyfilterFunction(
                      filters,
                      filterLabels,
                      formikActionsBag,
                    );
                  },
                }}
                columns={[
                  {
                    key: 'registrationNumber',
                    name: this.context.intl.formatMessage(
                      messages.registrationNumber,
                    ),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'firstName',
                    name: this.context.intl.formatMessage(messages.firstName),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'lastName',
                    name: this.context.intl.formatMessage(messages.lastName),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'staffRoleId',
                    name: this.context.intl.formatMessage(messages.role),
                    searchable: false,
                    sortable: true,
                    formatter: ({ data }) =>
                      _.get(data, '_embedded.staffRoleId.name', '--'),
                    exportFormatter: ({ data }) =>
                      _.get(data, '_embedded.staffRoleId.name', '--'),
                  },
                ]}
                defaultActions={{
                  visualize: PermissionManager.CanI('read', 'staff'),
                  modify: PermissionManager.CanI('edit', 'staff'),
                  delete: PermissionManager.CanI('remove', 'staff'),
                  grouped: false,
                  moreActions: [],
                }}
              />
            </ListWrapper>
          )}
        </Page>
      </>
    );
  }
}

Staff.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  staff: makeSelectStaff(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'staff', reducer });
const withSaga = injectSaga({ key: 'staff', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Staff);

Staff.contextTypes = {
  intl: PropTypes.object.isRequired,
};
