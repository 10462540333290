import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the login state domain
 */

const selectLoginDomain = state => state.get('login', initialState);

const selectGlobal = state => state.get('global');

/**
 * Other specific selectors
 */

const makeSelectUsername = () =>
  createSelector(selectLoginDomain, loginState => loginState.get('username'));

const makeSelectPassword = () =>
  createSelector(selectLoginDomain, loginState => loginState.get('password'));

const makeSelectIsLogging = () =>
  createSelector(selectLoginDomain, loginState => loginState.get('isLogging'));

const makeSelectVersion = () =>
  createSelector(selectGlobal, globalState => globalState.get('version'));

const makeSelectError = () =>
  createSelector(selectGlobal, globalState => globalState.get('error'));

const makeSelectCaptcha = () =>
  createSelector(selectLoginDomain, loginState => loginState.get('captcha'));

const makeSelectPasswordExpired = () =>
  createSelector(selectLoginDomain, loginState =>
    loginState.get('passwordExpired'),
  );

// export default makeSelectLogin;
export {
  makeSelectUsername,
  makeSelectPassword,
  makeSelectIsLogging,
  makeSelectVersion,
  makeSelectError,
  makeSelectCaptcha,
  makeSelectPasswordExpired,
};
