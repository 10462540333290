/**
 *
 * Agent
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Helmet } from 'react-helmet-async';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import _ from 'lodash';
import ListManager from 'novigo-entity-grid';
import FormManager from 'novigo-form-manager';
import { Header } from 'semantic-ui-react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import makeSelectAgent from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import appMessages from '../App/messages';
import agentFormMessages from '../../components/Forms/AgentForm/messages';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';

/*
 * Specific import
 */

import { API } from '../../global-constants';

import request from '../../shared/services/request';

import AgentForm, { validation } from '../../components/Forms/AgentForm';
import MunicipalityService from '../../shared/services/municipality';
import { addNotification } from '../../utils/notification';
import { ListWrapper, Page } from '../../components/Layout';
// import FormikAsyncSelect from '../../components/FormikAsyncSelect';
// import AgentTypeService from '../../shared/services/agenttype';

// import for custom search
import CustomSearchForm, {
  onApplyFilter,
} from '../../components/Forms/AgentForm/formParts/customSearchForm';

// import helper functions for agent form
import { transformData } from '../../components/Forms/AgentForm/formHelperFunctions';
import { CanManage } from '../Can/utils';

/* eslint-disable react/prefer-stateless-function */
export class Agent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'view' ||
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'modify' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showForm: true });
    } else {
      this.setState({ showForm: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.evaluteAction();
  }

  render() {
    /**
     if (!_.get(this.props.user, ['permissions', Agent, 'list'], false)) {
      return null;
    }
     */

    return (
      <>
        <Helmet>
          <title>{this.context.intl.formatMessage(messages.title)}</title>
          <meta
            name="description"
            content={this.context.intl.formatMessage(messages.title)}
          />
        </Helmet>
        <Page>
          <Header as="h2" dividing>
            <FormattedMessage {...messages.title} />
          </Header>
          <BreadcrumbsItem to="/agent">
            {this.context.intl.formatMessage(messages.title)}
          </BreadcrumbsItem>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/agent/edit">
                {actionToBreadcrumb({
                  action: this.props.match.params.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                key={this.props.match.params.action} // necessary to reload the current view upon redirect
                router={this.props} // necessary to use the internal routing
                client={pars =>
                  request(pars).then(res => transformData(res.data))
                }
                mask={props => (
                  <AgentForm
                    {...props}
                    user={this.props.user}
                    municipalityService={MunicipalityService}
                    editRoute={`/agent/${this.props.match.params.id}/modify`}
                    history={this.props.history}
                  />
                )}
                validation={values => validation(values, this.context.intl)}
                onCleanData={data => {
                  const cleanData = _.cloneDeep(data);
                  _.unset(cleanData, 'id');
                  _.unset(cleanData, '_links');
                  _.unset(cleanData, '_embedded');
                  _.unset(cleanData, 'displayName');
                  _.unset(cleanData, 'readAt');
                  _.unset(cleanData, 'writeAt');
                  _.unset(cleanData, 'isActive');
                  _.unset(cleanData, 'imported');
                  _.unset(cleanData, 'sdi');

                  if (cleanData.inspector) {
                    const inspectorId = cleanData.inspector.value;
                    cleanData.inspector = inspectorId;
                  }

                  delete cleanData.agents;
                  cleanData.user = _.get(cleanData, 'user.value', null);
                  cleanData.fiscalCode = _.get(cleanData, 'fiscalCode', null);

                  cleanData.registryType = _.get(
                    cleanData,
                    'registryType.value',
                    null,
                  );

                  cleanData.ratesFraming = _.get(
                    cleanData,
                    'ratesFraming.value',
                    null,
                  );

                  return cleanData;
                }}
                // onCleanData={data => onCleanData(data)}
                data={
                  this.props.match.params.id === 'new' && {
                    registryType: '',
                    gender: 'M',
                    lastName: '',
                  }
                }
                entityName="agent"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                afterCreate={res =>
                  this.props.history.push(`${API.AGENT}/${res.id}/modify`)
                }
                onError={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  })
                }
                onSuccess={() =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
              />
            </>
          ) : (
            <ListWrapper>
              <ListManager
                entityName="agent"
                sessionKey="agent"
                basePath={API.BASE_URL}
                locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                translations={{
                  createButton: {
                    label: this.context.intl.formatMessage(messages.new_agent),
                  },
                }}
                permissions={this.props.user}
                router={this.props}
                persistentFilter={[]}
                client={pars => request(pars).then(res => res.data)}
                defaultOrder={[
                  {
                    type: 'field',
                    field: 'createdAt',
                    direction: 'desc',
                  },
                ]}
                canCreate={{
                  active: CanManage(this.props.user),
                }}
                canExport={{
                  exportLabelPrefix: this.context.intl.formatMessage(
                    appMessages.export_to,
                  ),
                }}
                aliases={[
                  {
                    type: 'leftjoin',
                    field: 'registryType',
                    alias: 'registryType',
                  },
                ]}
                columns={[
                  {
                    key: 'code',
                    name: this.context.intl.formatMessage(
                      agentFormMessages.code,
                    ),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'businessName',
                    name: 'Business name',
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'firstName',
                    name: 'Name',
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'lastName',
                    name: 'Surname',
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'type',
                    name: 'Type',
                    searchable: true,
                    sortable: true,
                    useAlias: 'registryType',
                    formatter: ({ data }) =>
                      _.get(data, '_embedded.registryType.type', '--'),
                  },
                ]}
                canSearchCustom={{
                  active: true,
                  formStructure: <CustomSearchForm intl={this.context.intl} />,
                  onApplyFilter: (
                    values,
                    formActionsBag,
                    applyFilterFunction,
                  ) => {
                    onApplyFilter({
                      values,
                      formActionsBag,
                      applyFilterFunction,
                      intl: this.context.intl,
                    });
                  },
                }}
                defaultActions={{
                  visualize: true,
                  modify: true,
                  delete: CanManage(this.props.user),
                  grouped: false,
                  moreActions: [],
                }}
              />
            </ListWrapper>
          )}
        </Page>
      </>
    );
  }
}

Agent.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  agent: makeSelectAgent(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'agent', reducer });
const withSaga = injectSaga({ key: 'agent', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Agent);

Agent.contextTypes = {
  intl: PropTypes.object.isRequired,
};
