/*
 * Commission Messages
 *
 * This contains all the text for the Commission container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.CommissionView';

export default defineMessages({
  totalAmount: {
    id: `${scope}.totalAmount`,
    defaultMessage: 'Total amount',
  },
  totalCommissionAmount: {
    id: `${scope}.totalCommissionAmount`,
    defaultMessage: 'Total commission amount',
  },
  totalRefundAmount: {
    id: `${scope}.totalRefundAmount`,
    defaultMessage: 'Total refund amount',
  },
  totalBonusAmount: {
    id: `${scope}.totalBonusAmount`,
    defaultMessage: 'Total bonus amount',
  },
  performed: {
    id: `${scope}.performed`,
    defaultMessage: 'Performed',
  },
  fixed: {
    id: `${scope}.fixed`,
    defaultMessage: 'Fixed',
  },
});
