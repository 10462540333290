import request from '../request';
import { API } from '../../../global-constants';

const getDestinationProvinces = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_PROVINCES,
    method: 'POST',
    data: {
      method: 'get-provinces',
      params: {
        filters: content,
      },
    },
  });

const getAsyncOptionsDestinationProvinces = (inputValue, filters, callback) => {
  const content = filters || {};
  content.searchText = inputValue;
  content.pageSize = 20;

  getDestinationProvinces(content).then(results => {
    const res = [];

    Object.values(results.data).forEach(province => {
      res.push({
        value: province,
        label: province,
      });
    });

    callback(res);
  });
};

const getOptionsDestinationProvinces = filters => {
  const content = filters || {};
  content.pageSize = 20;

  return getDestinationProvinces(content).then(results => {
    const res = [];

    Object.values(results.data).forEach(province => {
      res.push({
        value: province,
        label: province,
      });
    });

    return res;
  });
};

const DestinationProvincesService = {
  getDestinationProvinces,
  getAsyncOptionsDestinationProvinces,
  getOptionsDestinationProvinces,
};

export default DestinationProvincesService;
