import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getContactTypes = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.CONTACT_TYPE,
    method: 'GET',
    params: {},
  });

const getContactTypeOptions = () =>
  getContactTypes().then(res =>
    _.get(res, 'data._embedded.contact_type', []).map(type => ({
      label: type.description,
      value: type.id,
    })),
  );

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_CONTACT,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_CONTACT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_CONTACT}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (destinationId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_CONTACT}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'destination',
          value: destinationId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const searchByName = (searchText, destinationId) =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_CONTACT,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'destination',
          value: destinationId,
        },
        searchText && {
          type: 'like',
          field: 'firstName',
          where: 'and',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOwnerOptions = (inputValue, destinationId, callback) => {
  searchByName(inputValue, destinationId).then(results => {
    callback(
      _.get(results, 'data._embedded.destination-contact', []).map(contact => ({
        value: contact.id,
        label: `${contact.firstName} ${contact.lastName}`,
        email: contact.email,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const ContactService = {
  getContactTypes,
  getContactTypeOptions,
  getByOwner,
  create,
  update,
  remove,
  searchByName,
  getOwnerOptions,
};

export default ContactService;
