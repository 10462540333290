// import AddressPicker from 'novigo-address-picker';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';
import AddressPicker from '../../../DeterchimicaAddressPicker';
import appMessages from '../../../../containers/App/messages';
import PermissionManager from '../../../Permission';

const AddressPickerWrapper = styled.div`
  div.ui.segment {
    > div.ui.grid {
      > div.row:first-child {
        display: ${props => (props.hideSwitch ? 'inherit' : 'none')};
      }
    }
  }
`;

const AddressFields = props => {
  const { values, isRequired = false, touched, errors, readOnly } = props;

  const required = {
    auto: true,
    city: true,
    province: true,
    state: false, // TODO set to true after data sanitation
    postalCode: true,
    address: false, // TODO set to true after data sanitation
  };
  return (
    <Form.Field>
      <AddressPickerWrapper
        hideSwitch={PermissionManager.CanI(
          'useDestinationManualAddress',
          'custom',
        )}
      >
        <AddressPicker
          readOnly={readOnly}
          required={isRequired ? required : {}}
          name="business_address"
          search={values.businessAddressSelectionType || 'auto'}
          showManual={PermissionManager.CanI(
            'useDestinationManualAddress',
            'custom',
          )}
          labels={{
            autoBtn: props.intl.formatMessage(appMessages.automatic),
            manualBtn: props.intl.formatMessage(appMessages.manual),
            city: props.intl.formatMessage(appMessages.city),
            province: props.intl.formatMessage(appMessages.province),
            state: props.intl.formatMessage(appMessages.state),
            postalCode: props.intl.formatMessage(appMessages.postal_code),
            address: props.intl.formatMessage(appMessages.address),
            autoPlaceholder: props.intl.formatMessage(
              appMessages.search_an_address,
            ),
            auto: props.intl.formatMessage(appMessages.search),
          }}
          element={{
            address: values.businessStreet,
            title: values.businessAddressTitle,
            city: values.businessCity,
            province: values.businessProvinceShort,
            cap: values.businessPostalCode,
            country: values.businessCountry,
            latitude: values.businessLatitude,
            longitude: values.businessLongitude,
          }}
          onChange={(name, value) => {
            const [latitude, longitude] = value.coords || [];
            props.setFieldValue('businessStreet', value.address || value.city);
            props.setFieldValue('businessCity', value.city);
            props.setFieldValue('businessProvinceShort', value.province);
            props.setFieldValue('businessPostalCode', value.cap || value.city);
            props.setFieldValue('businessCountry', value.country);
            props.setFieldValue('businessAddressTitle', value.title);
            props.setFieldValue('businessLatitude', latitude);
            props.setFieldValue('businessLongitude', longitude);
            if (value.name) {
              props.setFieldValue('businessName', value.name);
            }
          }}
          onSwitch={el =>
            props.setFieldValue('businessAddressSelectionType', el)
          }
          // eslint-disable-next-line no-unused-vars
          onBlur={(name, value) => null}
          // onBlur={(name, value) => {
          //   handleValueFromBlur(name, value, setFieldValue);
          // }}
          touched={touched}
          errors={errors}
        />
      </AddressPickerWrapper>
    </Form.Field>
  );
};

AddressFields.defaultProps = {
  values: {},
  isRequired: false,
};

AddressFields.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
};

export default injectIntl(AddressFields);
