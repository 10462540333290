/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/forbid-prop-types */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Form, Button, Grid } from 'semantic-ui-react';
import { Field } from 'formik';
import SimpleFormikField from 'novigo-simple-formik-field';
import Accordion from '../../../Accordion';
import IndividualPart from '../formParts/individualPart';
import LegalPart from '../formParts/legalPart';
import CommunicationPart from '../formParts/communicationPart';
// import PaymentPart from './formParts/paymentPart';

import DocumentsPart from '../formParts/documentsPart';
import ContactsPart from '../formParts/contactsPart';
import NotesPart from '../formParts/notesPart';

import validation from '../validation';
// import FormActionsBar from '../../../FormActionsBar';
import messages from '../messages';
import ContactService from '../../../../shared/services/customercontacts';
import DocumentService from '../../../../shared/services/customerdocuments';
import NoteService from '../../../../shared/services/customernote';

export function GeneralTab(props, context) {
  const {
    handleSubmit,
    setFieldValue,
    values,
    readOnly = false,
    isNew = false,
  } = props;

  const { registryType } = values;
  const isProspect = get(values, 'isProspect', false);

  const showCodeAndName = useMemo(
    () => !isNew || (isNew && values.registryType === 'L'),
    [isNew, values.registryType],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        name="registryType"
        render={({ field }) => (
          <Form.Group widths="equal" style={{ paddingLeft: 5 }}>
            <Button.Group>
              <Button
                type="button"
                disabled={readOnly}
                primary={field.value === 'I'}
                checked={field.value === 'I'}
                onClick={() => setFieldValue('registryType', 'I')}
              >
                <FormattedMessage {...messages.natural_person} />
              </Button>
              <Button
                type="button"
                disabled={readOnly}
                primary={field.value === 'L'}
                checked={field.value === 'L'}
                onClick={() => setFieldValue('registryType', 'L')}
              >
                <FormattedMessage {...messages.legal_person} />
              </Button>
            </Button.Group>
          </Form.Group>
        )}
      />

      {showCodeAndName && (
        <Grid style={{ paddingBottom: 15 }}>
          <Grid.Row>
            {!isNew && (
              <Grid.Column width={4}>
                <SimpleFormikField
                  {...props}
                  readOnly
                  name="code"
                  label={context.intl.formatMessage(messages.customer_code)}
                />
              </Grid.Column>
            )}
            <Grid.Column width={4}>
              <SimpleFormikField
                {...props}
                disabled={readOnly}
                name="businessName"
                label={context.intl.formatMessage(messages.businessName)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {registryType === 'L' && (
        <Accordion title={context.intl.formatMessage(messages.company_data)}>
          <LegalPart {...props} />
        </Accordion>
      )}
      {registryType === 'I' && (
        <Accordion title={context.intl.formatMessage(messages.personal_data)}>
          <IndividualPart {...props} />
        </Accordion>
      )}
      {values.id &&
        (isProspect && (
          <Accordion title={context.intl.formatMessage(messages.notes)}>
            <NotesPart {...props} noteService={NoteService} entity="customer" />
          </Accordion>
        ))}
      <Accordion title={context.intl.formatMessage(messages.communications)}>
        <CommunicationPart {...props} />
      </Accordion>
      {/* <Accordion title={context.intl.formatMessage(messages.payment)}>
          <PaymentPart {...props} />
       </Accordion> */}
      {values.id && (
        <>
          <Accordion title={context.intl.formatMessage(messages.contacts)}>
            <ContactsPart
              {...props}
              contactService={ContactService}
              entity="customer"
            />
          </Accordion>
          <Accordion title={context.intl.formatMessage(messages.documents)}>
            <DocumentsPart
              {...props}
              entity="customer"
              documentService={DocumentService}
            />
          </Accordion>
          {!isProspect && (
            <Accordion title={context.intl.formatMessage(messages.notes)}>
              <NotesPart
                {...props}
                noteService={NoteService}
                entity="customer"
              />
            </Accordion>
          )}
        </>
      )}
    </Form>
  );
}

GeneralTab.defaultProps = {
  errors: [],
  registryType: 'L',
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

GeneralTab.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  registryType: PropTypes.string,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

GeneralTab.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default GeneralTab;

export { validation };
