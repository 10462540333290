/*
 *
 * NotesHub constants
 *
 */

import { get, has } from 'lodash';
import moment from 'moment';
import React from 'react';
import messages from '../../components/Forms/Anagrafica/messages';
import CustomerNoteService from '../../shared/services/customernote';
import DestinationNoteService from '../../shared/services/destinationnote';
import { convertUTCToLocal } from '../../utils/functions';
import appMessages from '../App/messages';
import ListActions from './_partial/ListActions';
export const DEFAULT_ACTION = 'app/NotesHub/DEFAULT_ACTION';

export const getNoteService = (customer, destination) => {
  if (customer && customer.id) {
    return CustomerNoteService;
  }
  if (destination && destination.id) {
    return DestinationNoteService;
  }

  return null;
};

export const formatSelectedNoteEntity = data => {
  const dataEntity = {};

  if (data.noteType) {
    dataEntity.noteType = {
      id: get(data, 'noteType.id', null),
      label: get(data, 'noteType.description', null),
    };
  }

  if (data.noteReason) {
    dataEntity.noteReason = {
      id: get(data, 'noteReason.id', null),
      label: get(data, 'noteReason.description', null),
    };
  }

  if (data.customer) {
    dataEntity.customer = {
      id: get(data, 'customer.id', null),
      label: get(data, 'customer.displayName', null),
    };
  }

  if (data.destination) {
    dataEntity.destination = {
      id: get(data, 'destination.id', null),
      label: get(data, 'destination.businessName', null),
    };
  }

  if (data.assignee) {
    dataEntity.assignee = {
      id: get(data, 'assignee.id', null),
      label: get(data, 'assignee.label', null),
    };
  }

  return data;
};

export const handleManage = ({ router, destination }) => {
  const { customer = null } = destination;

  const hasCustomer = has(destination, 'customer.id');
  const isDestination = destination.id && hasCustomer;

  if (hasCustomer && isDestination) {
    router.push(`/destination/${destination.id}/modify`, { activeIndex: 6 });
  } else if (hasCustomer) {
    router.push(`/headquarter/${customer.id}/modify`);
  } else {
    router.push(`/prospect/${destination.id}/modify`, { activeIndex: 6 });
  }
};

export const listManagerColumns = ({
  intl,
  // eslint-disable-next-line no-unused-vars
  loadData,
  setSelectedEntity,
  openNotesModal,
  openConfirmModal,
  setReadOnlyMode,
  readOnly = true,
}) =>
  [
    {
      key: 'noteType',
      name: intl.formatMessage(messages.noteType),
      formatter: ({ data }) => get(data, 'noteType.description', '--'),
      exportFormatter: ({ data }) => get(data, 'noteType.description', '--'),
    },
    {
      key: 'noteDate',
      name: intl.formatMessage(messages.date),
      formatter: ({ data }) => {
        const { createdAt } = data;
        const localCreatedAt = createdAt
          ? convertUTCToLocal(createdAt.date, 'DD-MM-YYYY HH:mm')
          : '--';
        return localCreatedAt;
      },
      exportFormatter: ({ data }) =>
        get(data, 'createdAt', '--')
          ? moment(data.createdAt.date).format('DD-MM-YYYY HH:mm')
          : '--',
    },
    {
      key: 'expireDate',
      name: intl.formatMessage(messages.expire_date),
      formatter: ({ data }) =>
        get(data, 'expireDate', '--')
          ? moment(data.expireDate.date).format('DD-MM-YYYY')
          : null,
    },
    {
      key: 'noteReason',
      name: intl.formatMessage(messages.noteReason),
      formatter: ({ data }) => get(data, 'noteReason.description', '--'),
      exportFormatter: ({ data }) => get(data, 'noteReason.description', '--'),
    },
    {
      key: 'customer',
      name: intl.formatMessage(messages.headquarter),
      formatter: ({ data }) => get(data, 'customer.displayName', '--'),
      exportFormatter: ({ data }) => get(data, 'customer.displayName', '--'),
    },
    {
      key: 'destination',
      name: intl.formatMessage(messages.customer),
      formatter: ({ data }) => get(data, 'destination.businessName', '--'),
      exportFormatter: ({ data }) =>
        get(data, 'destination.businessName', '--'),
    },
    {
      key: 'noteText',
      name: intl.formatMessage(messages.note),
      formatter: ({ data }) => get(data, 'noteText', ''),
      exportFormatter: ({ data }) => get(data, 'noteText', ''),
    },
    !readOnly && {
      key: 'actions',
      name: intl.formatMessage(appMessages.actions),
      exportFormatter: null,
      // eslint-disable-next-line react/prop-types,no-unused-vars
      formatter: ({ data }) => (
        <div style={{ width: '40px', display: 'flex' }}>
          <ListActions
            onView={() => {
              setReadOnlyMode(true);
              openNotesModal();
              setSelectedEntity(formatSelectedNoteEntity(data));
            }}
            onEdit={() => {
              setReadOnlyMode(false);
              openNotesModal();
              setSelectedEntity(formatSelectedNoteEntity(data));
            }}
            onDelete={() => {
              openConfirmModal(true);
              setSelectedEntity(formatSelectedNoteEntity(data));
            }}
            onManage={({ history }) => {
              handleManage({
                router: history,
                destination: data.destination,
              });
            }}
          />
        </div>
      ),
      width: '80px',
    },
  ].filter(o => o);
