import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.Offer';

export default defineMessages({
  new_offer: {
    id: `${scope}.new_offer`,
    defaultMessage: 'New Offer',
  },
  offer_number: {
    id: `${scope}.offer_number`,
    defaultMessage: 'Offer number',
  },
  commercial_director: {
    id: `${scope}.commercial_director`,
    defaultMessage: 'Commercial Director',
  },
  area_manager: {
    id: `${scope}.area_manager`,
    defaultMessage: 'Area manager',
  },
  agent_1: {
    id: `${scope}.agent_1`,
    defaultMessage: 'Agent 1',
  },
  agent_2: {
    id: `${scope}.agent_2`,
    defaultMessage: 'Agent 2',
  },
  offer_agent1: {
    id: `${scope}.offer_agent1`,
    defaultMessage: 'Agent 1',
  },
  offer_agent2: {
    id: `${scope}.offer_agent2`,
    defaultMessage: 'Agent 2',
  },
  sub_agent: {
    id: `${scope}.sub_agent`,
    defaultMessage: 'Sub agent',
  },
  signaller: {
    id: `${scope}.signaller`,
    defaultMessage: 'Signaller',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  offer_destination: {
    id: `${scope}.offer_destination`,
    defaultMessage: 'Destination',
  },
  payment_condition: {
    id: `${scope}.payment_condition`,
    defaultMessage: 'Payment condition',
  },
  offer_paymentCondition: {
    id: `${scope}.offer_paymentCondition`,
    defaultMessage: 'Payment condition',
  },
  cig: {
    id: `${scope}.`,
    defaultMessage: 'CIG',
  },
  cup: {
    id: `${scope}.cup`,
    defaultMessage: 'CUP',
  },
  expirationDate: {
    id: `${scope}.expirationDate`,
    defaultMessage: 'Offer date',
  },
  typology: {
    id: `${scope}.typology`,
    defaultMessage: 'Offer typology',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  customers: {
    id: `${scope}.customers`,
    defaultMessage: 'Customers',
  },
  dateFrom: {
    id: `${scope}.dateFrom`,
    defaultMessage: 'Date from',
  },
  dateTo: {
    id: `${scope}.dateTo`,
    defaultMessage: 'Date to',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  subcategory: {
    id: `${scope}.subcategory`,
    defaultMessage: 'Subcategory',
  },
  productDomain: {
    id: `${scope}.productDomain`,
    defaultMessage: 'Field of use',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
  insert: {
    id: `${scope}.insert`,
    defaultMessage: 'Insert',
  },
  insert_discount: {
    id: `${scope}.insert_discount`,
    defaultMessage: 'Insert discount',
  },
  discounts: {
    id: `${scope}.discounts`,
    defaultMessage: 'Discounts',
  },
  notes: {
    id: `${scope}.notes`,
    defaultMessage: 'Notes',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Username',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  discount: {
    id: `${scope}.discount`,
    defaultMessage: 'Discount',
  },
  discountType: {
    id: `${scope}.discountType`,
    defaultMessage: 'Discount Type',
  },
  discountTypeTable: {
    id: `${scope}.discountTypeTable`,
    defaultMessage: 'Type',
  },
  discountValue: {
    id: `${scope}.discountValue`,
    defaultMessage: 'Discount Value',
  },
  extraDiscount: {
    id: `${scope}.extraDiscount`,
    defaultMessage: 'Extra Discount',
  },
  article: {
    id: `${scope}.article`,
    defaultMessage: 'Article',
  },
  buying_price: {
    id: `${scope}.buying_price`,
    defaultMessage: 'Buy.',
  },
  unit_net_price: {
    id: `${scope}.unit_net_price`,
    defaultMessage: 'Net',
  },
  competitor_price: {
    id: `${scope}.competitor_price`,
    defaultMessage: 'Com.',
  },
  min_quantity: {
    id: `${scope}.min_quantity`,
    defaultMessage: 'Quantity',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  loan: {
    id: `${scope}.loan`,
    defaultMessage: 'Loan',
  },
  goods_discount: {
    id: `${scope}.goods_discount`,
    defaultMessage: 'GD',
  },
  percentage: {
    id: `${scope}.percentage`,
    defaultMessage: 'Percentage',
  },
  value_discount: {
    id: `${scope}.value_discount`,
    defaultMessage: 'Discount value',
  },
  net_price: {
    id: `${scope}.net_price`,
    defaultMessage: 'Net price',
  },
  subject_removed_success: {
    id: `${scope}.subject_removed_success`,
    defaultMessage: 'Offer row removed!',
  },
  state_history: {
    id: `${scope}.state_history`,
    defaultMessage: 'State history',
  },
  competitorPrice: {
    id: `${scope}.competitorPrice`,
    defaultMessage: 'Comp. price',
  },
  productCustomerPrice: {
    id: `${scope}.productCustomerPrice`,
    defaultMessage: 'Client',
  },
  unit_gross_price: {
    id: `${scope}.unit_gross_price`,
    defaultMessage: 'Gross',
  },
  discount_selected: {
    id: `${scope}.discount_selected`,
    defaultMessage: 'Discount selected',
  },
  remove_selected: {
    id: `${scope}.remove_selected`,
    defaultMessage: 'Remove selected',
  },
  min_quantity_modal: {
    id: `${scope}.min_quantity_modal`,
    defaultMessage: 'Min. quantity',
  },
  net_price_modal: {
    id: `${scope}.net_price_modal`,
    defaultMessage: 'Unit net price',
  },
  quantity_required: {
    id: `${scope}.quantity_required`,
    defaultMessage: 'Quantity field is required!',
  },
  discountValue_required: {
    id: `${scope}.discountValue_required`,
    defaultMessage: 'Discount value is requred!',
  },
  netPrice_required: {
    id: `${scope}.netPrice_required`,
    defaultMessage: 'Net price is required!',
  },
  markup_required: {
    id: `${scope}.markup_required`,
    defaultMessage: 'Markup is required!',
  },
  flatValue_required: {
    id: `${scope}.flatValue_required`,
    defaultMessage: 'Discount value is requred!',
  },
  promotion_exist: {
    id: `${scope}.promotion_exist`,
    defaultMessage: 'There is a promotion ',
  },
  promotion_exist_part: {
    id: `${scope}.promotion_exist_part`,
    defaultMessage: ' euro for this product',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply ?',
  },
  frequency: {
    id: `${scope}.frequency`,
    defaultMessage: 'Frequency',
  },
  export: {
    id: `${scope}.export`,
    defaultMessage: 'Export',
  },
  FIXED: {
    id: `${scope}.FIXED`,
    defaultMessage: 'Fixed',
  },
  FLAT: {
    id: `${scope}.FLAT`,
    defaultMessage: 'Flat',
  },
  PERCENTAGE: {
    id: `${scope}.PERCENTAGE`,
    defaultMessage: 'Percentage',
  },
  MARKUP: {
    id: `${scope}.MARKUP`,
    defaultMessage: 'Markup',
  },
  vettore_dtm: {
    id: `${scope}.vettore_dtm`,
    defaultMessage: 'DTM carrier',
  },
  vettore__cliente: {
    id: `${scope}.vettore__cliente`,
    defaultMessage: 'Customer carrier',
  },
  origin: {
    id: `${scope}.origin`,
    defaultMessage: 'Origin',
  },
  delivery: {
    id: `${scope}.delivery`,
    defaultMessage: 'Delivery',
  },
  requestNumber: {
    id: `${scope}.requestNumber`,
    defaultMessage: 'Request Number',
  },
  competitorNote: {
    id: `${scope}.competitorNote`,
    defaultMessage: 'C. note',
  },
  priceAmount: {
    id: `${scope}.priceAmount`,
    defaultMessage: ' Total',
  },
  markup: {
    id: `${scope}.markup`,
    defaultMessage: ' Markup',
  },
  competitorMarkup: {
    id: `${scope}.competitorMarkup`,
    defaultMessage: ' C. Markup',
  },
  difference: {
    id: `${scope}.difference`,
    defaultMessage: ' Diff.',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  total_offer: {
    id: `${scope}.total_offer`,
    defaultMessage: 'Total:',
  },
  avarageDiscount: {
    id: `${scope}.avarageDiscount`,
    defaultMessage: 'Avarage Discount:',
  },
  avarageMarkup: {
    id: `${scope}.avarageMarkup`,
    defaultMessage: 'Avarage Markup:',
  },
  insert_date: {
    id: `${scope}.insert_date`,
    defaultMessage: 'Date:',
  },
  customer_prospect: {
    id: `${scope}.customer_prospect`,
    defaultMessage: 'customer/prospect:',
  },
  history: {
    id: `${scope}.history`,
    defaultMessage: 'history',
  },
  productKit: {
    id: `${scope}.productKit`,
    defaultMessage: 'Product Kit',
  },
  insertArticle: {
    id: `${scope}.insertArticle`,
    defaultMessage: 'Insert Article',
  },
  duplicate: {
    id: `${scope}.duplicate`,
    defaultMessage: 'New version',
  },
  duplicateSuccess: {
    id: `${scope}.duplicateSuccess`,
    defaultMessage: 'Duplication Success',
  },
  duplication: {
    id: `${scope}.duplication`,
    defaultMessage: 'Do you want to duplicate this offer?',
  },
  notePart: {
    id: `${scope}.notePart`,
    defaultMessage: 'Additional Information',
  },
  requiredDestination: {
    id: `${scope}.requiredDestination`,
    defaultMessage: 'Client/Prospect is required!',
  },
  non_compliant_average_discount: {
    id: `${scope}.non_compliant_average_discount`,
    defaultMessage: 'The average discount is above the expected threshold',
  },
  non_compliant_product_discount: {
    id: `${scope}.non_compliant_product_discount`,
    defaultMessage: 'product discount above the expected threshold',
  },
  non_compliant_insolvent: {
    id: `${scope}.non_compliant_insolvent`,
    defaultMessage: 'The customer has due payments',
  },
  attention: {
    id: `${scope}.attention`,
    defaultMessage: 'Attention',
  },
  confirm_promotion: {
    id: `${scope}.confirm_promotion`,
    defaultMessage:
      'Are you sure you want to apply a discount to the selected articles?',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  productCrm: {
    id: `${scope}.productCrm`,
    defaultMessage: 'Product Crm',
  },
  action_approve: {
    id: `${scope}.action_approve`,
    defaultMessage: 'Approve',
  },
  action_not_approve: {
    id: `${scope}.action_not_approve`,
    defaultMessage: 'Not approve',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Quantity',
  },
  productOfferPrice: {
    id: `${scope}.productOfferPrice`,
    defaultMessage: 'Offer',
  },
  rowNumber: {
    id: `${scope}.rowNumber`,
    defaultMessage: 'Riga',
  },
  destination_saved: {
    id: `${scope}.destination_saved`,
    defaultMessage: 'Destination saved successfully',
  },
  destination_errors: {
    id: `${scope}.destination_errors`,
    defaultMessage: 'Destination errors:',
  },
  is_sample_modal: {
    id: `${scope}.is_sample_modal`,
    defaultMessage: 'Sample',
  },
  goods_discount_modal: {
    id: `${scope}.goods_discount_modal`,
    defaultMessage: 'Goods discount',
  },
  loan_modal: {
    id: `${scope}.loan_modal`,
    defaultMessage: 'Loan',
  },
});
