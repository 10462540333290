import messages from './messages';

function Validation(values, intl) {
  const errors = {};

  // Description is required
  if (!values.description) {
    errors.startDate = { required: 'Required' };
  }

  // Validation range is required
  if (!values.description) {
    errors.startDate = { required: 'Required' };
  }
  if (!values.endDate) {
    errors.endDate = { required: 'Required' };
  }

  // Destination is required
  if (!values.destination) {
    errors.destination = { required: 'Required' };
  }

  // startDate must be higher than endDate
  if (values.startDate && values.endDate) {
    if (values.startDate > values.endDate) {
      errors.startDate = intl.formatMessage(messages.start_lt_end_date);
    }
  }

  return errors;
}

export default Validation;
