/*
 *
 * Login actions
 *
 */

import {
  LOGIN_PASSWORD_GRANT,
  OAUTH_GRANT,
  CHANGE_USERNAME,
  CHANGE_PASSWORD,
  CHANGE_RECAPTCHA,
  STOP_LOADING,
  FORCE_PASSWORD_RESET,
  UNFORCE_PASSWORD_RESET,
} from './constants';

import { SET_TOKEN } from '../App/constants';

/**
 * Login the user with OAUTH PASSWORD GRANT TYPE
 *
 * @return {object} An action object with a type of LOAD_REPOS
 */
export function loginPasswordGrant() {
  return {
    type: LOGIN_PASSWORD_GRANT,
  };
}

export function oauthGrant(tokens) {
  return {
    type: OAUTH_GRANT,
    tokens,
  };
}

export function changeUsername(name) {
  return {
    type: CHANGE_USERNAME,
    name,
  };
}

export function changePassword(password) {
  return {
    type: CHANGE_PASSWORD,
    password,
  };
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    token,
  };
}

export function changeRecaptcha(captcha) {
  return {
    type: CHANGE_RECAPTCHA,
    captcha,
  };
}

export function stopLoading() {
  return {
    type: STOP_LOADING,
  };
}

export function forcePasswordReset() {
  return {
    type: FORCE_PASSWORD_RESET,
  };
}

export function unForcePasswordReset() {
  return {
    type: UNFORCE_PASSWORD_RESET,
  };
}
