/* eslint-disable no-console */

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikAsyncSelect from '../../../FormikAsyncSelect';

import messages from '../messages';

import ProductCountryService from '../../../../shared/services/productcountry';

function ExternalTradeTab(props) {
  return (
    <div>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="combinedNomenclature"
              label={props.intl.formatMessage(messages.combinedNomenclature)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="countryOrigin"
              label={props.intl.formatMessage(messages.countryOrigin)}
              loadOptions={(searchText, callback) =>
                ProductCountryService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

ExternalTradeTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(ExternalTradeTab);
