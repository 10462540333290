import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import Accordion from '../../../Accordion';
import FormikSelect from '../../../FormikSelect';
import CurrencyFormatter from '../../../CurrencyFormatter';
import FormikDate from '../../../FormikDate';
import FormikTime from '../../../FormikTime';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import DestinationRangeService from '../../../../shared/services/destinationrange';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
// import SimpleFormikField from 'novigo-simple-formik-field';
// import FormikRate from '../../../FormikRate';

function MarketingTab(props) {
  const weekDays = [
    props.intl.formatMessage(appMessages.monday),
    props.intl.formatMessage(appMessages.tuesday),
    props.intl.formatMessage(appMessages.wednesday),
    props.intl.formatMessage(appMessages.thursday),
    props.intl.formatMessage(appMessages.friday),
    props.intl.formatMessage(appMessages.saturday),
    props.intl.formatMessage(appMessages.sunday),
  ];

  const weekDaysOption = weekDays.map(day => ({ label: day, value: day }));

  return (
    <div>
      <Accordion title={props.intl.formatMessage(messages.tab_marketing)}>
        <Grid>
          {/* <Grid.Row columns={4}>
            <Grid.Column>
              <FormikRate
                {...props}
                name="starsNumber"
                label={props.intl.formatMessage(messages.stars_number)}
              />
            </Grid.Column>
          </Grid.Row> */}
          {/* <Grid.Row columns={4}>
            <Grid.Column>
              <SimpleFormikField
                {...props}
                name="surface"
                label={props.intl.formatMessage(messages.surface)}
              />
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row columns={4}>
            <Grid.Column>
              <FormikAsyncSelect
                {...props}
                name="destinationRange"
                label={props.intl.formatMessage(messages.destination_range)}
                loadOptions={(searchText, callback) =>
                  DestinationRangeService.getOptions(searchText, callback)
                }
              />
            </Grid.Column>
            <Grid.Column>
              <CurrencyFormatter
                {...props}
                name="potentialTurnover"
                label={props.intl.formatMessage(messages.potential_turnover)}
              />
            </Grid.Column>
          </Grid.Row>
          {/* <Grid.Row columns={4}>
            <Grid.Column>
              <SimpleFormikField
                {...props}
                name="employeesNumber"
                label={props.intl.formatMessage(messages.employees_number)}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleFormikField
                {...props}
                name="seatsNumber"
                label={props.intl.formatMessage(messages.seats_number)}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleFormikField
                {...props}
                name="roomsNumber"
                label={props.intl.formatMessage(messages.rooms_number)}
              />
            </Grid.Column>
            <Grid.Column>
              <SimpleFormikField
                {...props}
                name="guestsNumber"
                label={props.intl.formatMessage(messages.guests_number)}
              />
            </Grid.Column>
          </Grid.Row> */}
          <Grid.Row columns={4}>
            <Grid.Column>
              <FormikSelect
                {...props}
                options={weekDaysOption}
                name="closingDays"
                isMulti
                value={props.values.closingDays || []}
                label={props.intl.formatMessage(messages.closing_days)}
                onChange={val => props.setFieldValue('closingDays', val)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={8}>
            <Grid.Column>
              <FormikDate
                {...props}
                name="seasonClosingFrom"
                label={props.intl.formatMessage(messages.season_closing_from)}
              />
            </Grid.Column>
            <Grid.Column>
              <FormikDate
                {...props}
                name="seasonClosingTo"
                label={props.intl.formatMessage(messages.season_closing_to)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={8}>
            <Grid.Column>
              <FormikTime
                {...props}
                name="shippingTimeFrom"
                label={props.intl.formatMessage(messages.shipping_time_from)}
              />
            </Grid.Column>
            <Grid.Column>
              <FormikTime
                {...props}
                name="shippingTimeTo"
                label={props.intl.formatMessage(messages.shipping_time_to)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={8}>
            <Grid.Column>
              <FormikTime
                {...props}
                name="interventionTimeFrom"
                label={props.intl.formatMessage(
                  messages.intervention_time_from,
                )}
              />
            </Grid.Column>
            <Grid.Column>
              <FormikTime
                {...props}
                name="interventionTimeTo"
                label={props.intl.formatMessage(messages.intervention_time_to)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Accordion>
    </div>
  );
}

MarketingTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(MarketingTab);
