/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { Button, Dropdown } from 'semantic-ui-react';

/**
 * Static functions for obtainin the Style of Button
 * @param {*} isGrouped
 */
const styleButton = isGrouped =>
  isGrouped
    ? { verticalAlign: 'bottom', marginBottom: 1 }
    : { borderRadius: '100%' };

/**
 * Stati function for the style of dropdown
 * @param {*} isGrouped
 */
const styleDropdown = isGrouped =>
  isGrouped ? { padding: '10px 7px' } : { padding: '7px 4px' };

/**
 * Static function for the style of the icon
 * @param {*} isGrouped
 */
const styleIcon = isGrouped => (isGrouped ? 'setting' : 'ellipsis vertical');

/**
 * The button collapse actions when actions is more than 3
 * Or when groupped
 * Default is not groupped
 */
export const ButtonDropdownCollapse = ({
  dropdownItems,
  isGrouped = false,
}) => (
  <Button
    as="div"
    circular
    style={{ color: '#fff', padding: 0, ...styleButton(isGrouped) }}
    primary
  >
    <div style={{ position: 'relative' }}>
      <Dropdown
        icon={styleIcon(isGrouped)}
        style={{
          ...styleDropdown(isGrouped),
          left: 2,
          top: 0,
          position: 'relative',
        }}
      >
        <Dropdown.Menu style={{ right: 0, left: 'auto' }}>
          {dropdownItems}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  </Button>
);

ButtonDropdownCollapse.defaultProps = {
  isGrouped: false,
};

ButtonDropdownCollapse.propTypes = {
  /**
   * Array of React element
   * contains the list of dropdown items
   */
  dropdownItems: PropTypes.arrayOf(PropTypes.element).isRequired,
  /**
   * Check grouped button
   */
  isGrouped: PropTypes.bool,
};

export default ButtonDropdownCollapse;
