import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getByOwner = (filter, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_HISTORIC}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter,
    },
    paramsSerializer: params => qs.stringify(params),
  });

const HistoricalService = {
  getByOwner,
};

export default HistoricalService;
