/*
 *
 * Login constants
 *
 */

export const LOGIN_PASSWORD_GRANT = 'app/Login/LOGIN_PASSWORD_GRANT';
export const OAUTH_GRANT = 'app/Login/OAUTH_GRANT';
export const CHANGE_USERNAME = 'app/Login/CHANGE_USERNAME';
export const CHANGE_PASSWORD = 'app/Login/CHANGE_PASSWORD';
export const CHANGE_RECAPTCHA = 'app/Login/CHANGE_RECAPTCHA';
export const STOP_LOADING = 'app/Login/STOP_LOADING';
export const FORCE_PASSWORD_RESET = 'app/Login/FORCE_PASSWORD_RESET';
export const UNFORCE_PASSWORD_RESET = 'app/Login/UNFORCE_PASSWORD_RESET';
