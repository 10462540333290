import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import BaseForm from './form';
import { addNotification } from '../../../utils/notification';
import { ModalFakeActions } from '../../Layout';
import appMessages from '../../../containers/App/messages';
import getError from '../../../utils/getError';
import { validation } from './validation';
import messages from './messages';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  noteExecutionText: _.get(newValues, 'noteExecutionText', ''),
  assignee: {
    value: _.get(newValues, 'assignee.value', ''),
    label: _.get(newValues, 'assignee.label', null),
  },
  expireDate: _.get(newValues, 'expireDate', ''),
  users: _.get(newValues, 'users', []),
});

export default class CustomerExecutionNote extends Component {
  state = {
    isUpdating: false,
  };

  static propTypes = {
    entityId: PropTypes.string,
    noteId: PropTypes.string,
    noteService: PropTypes.object,
    entity: PropTypes.string,
    isReadOnly: PropTypes.bool,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    this.setState({ isUpdating: true });
    const payload = {
      closureNote: _.get(values, 'noteExecutionText', ''),
      assignee: _.get(values, 'assignee.value', ''),
      expireDate: values.expireDate
        ? values.expireDate.format('YYYY-MM-DD')
        : null,
      users: _.get(values, 'users', []).map(u => u.value),
    };

    this.props.noteService
      .executeNote(values.id, payload)
      .then(() => {
        setSubmitting(false);
        this.setState({ isUpdating: false });
        addNotification({
          title: null,
          message: this.context.intl.formatMessage(
            messages.note_execute_success,
          ),
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        this.setState({ isUpdating: false });
        setSubmitting(false);
        addNotification({
          title: null,
          message: err.message,
          isError: true,
        });
        setErrors(getError(err));
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    const { isReadOnly = false } = this.props;

    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={initialValues(this.props.initialValues || {})}
            onSubmit={this.onSubmit}
            validateOnBlur
            validateOnChange
            validate={validation}
            render={props => (
              <BaseForm
                {...props}
                isReadOnly={isReadOnly}
                noteTypes={this.props.noteTypes}
                noteReasons={this.props.noteReasons}
              />
            )}
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.close}
            >
              <Icon name="remove" />
              {this.context.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          {!isReadOnly && (
            <div
              style={{
                display: 'inline-block',
                width: '50%',
                textAlign: 'right',
              }}
            >
              <Button
                type="button"
                icon
                labelPosition="left"
                color="green"
                onClick={this.submit}
                loading={this.state.isUpdating}
              >
                <Icon name="checkmark" />
                {this.context.intl.formatMessage(appMessages.save)}
              </Button>
            </div>
          )}
        </ModalFakeActions>
      </Grid>
    );
  }
}

CustomerExecutionNote.contextTypes = {
  intl: PropTypes.object.isRequired,
};
