/*
 * Destination Messages
 *
 * This contains all the text for the Staff container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Prospect';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Prospect container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Prospects',
  },
  new_prospect: {
    id: `${scope}.new_prospect`,
    defaultMessage: 'New Prospect',
  },
  customerCode: {
    id: `${scope}.customerCode`,
    defaultMessage: 'Cust. Code',
  },
  prospectCode: {
    id: `${scope}.prospectCode`,
    defaultMessage: 'Prospect Code',
  },
  destinationState: {
    id: `${scope}.destinationState`,
    defaultMessage: 'Dest. State',
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Code',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Dest. Area',
  },
  destinationLogisticArea: {
    id: `${scope}.destinationLogisticArea`,
    defaultMessage: 'Customer Dest. Logistic Area',
  },
  businessCity: {
    id: `${scope}.businessCity`,
    defaultMessage: 'City',
  },
  businessProvince: {
    id: `${scope}.businessProvince`,
    defaultMessage: 'Province',
  },
  businessCountry: {
    id: `${scope}.businessCountry`,
    defaultMessage: 'Country',
  },
  nominative: {
    id: `${scope}.nominative`,
    defaultMessage: 'Nominative',
  },
  export_pdf: {
    id: `${scope}.export_pdf`,
    defaultMessage: 'Export PDF',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  prospect: {
    id: `${scope}.prospect`,
    defaultMessage: 'Prospect',
  },
  customer: {
    id: `${scope}.client`,
    defaultMessage: 'Customer',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'phone',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  onlyMine: {
    id: `${scope}.onlyMine`,
    defaultMessage: 'Only mine',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  ACTIVE: {
    id: `${scope}.active`,
    defaultMessage: 'Active',
  },
  INACTIVE: {
    id: `${scope}.inactive`,
    defaultMessage: 'Inactive',
  },
  lastAction: {
    id: `${scope}.lastAction`,
    defaultMessage: 'Last action',
  },
  shopSign: {
    id: `${scope}.shopSign`,
    defaultMessage: 'Shop sign',
  },
});
