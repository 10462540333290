import AddressPicker from 'novigo-address-picker';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Form } from 'semantic-ui-react';
import appMessages from '../../../../containers/App/messages';
import { handleValueFromBlur } from '../formHelperFunctions';

const AddressFields = props => {
  const {
    values,
    setFieldValue,
    isRequired = false,
    touched,
    errors,
    canModify,
  } = props;

  const required = {
    auto: true,
    city: true,
    province: true,
    state: true,
    postalCode: true,
    address: true,
  };
  return (
    <Form.Field>
      <AddressPicker
        readOnly={canModify}
        required={isRequired ? required : {}}
        name="residence_address"
        search={values.residenceAddressSelectionType || 'auto'}
        labels={{
          autoBtn: props.intl.formatMessage(appMessages.automatic),
          manualBtn: props.intl.formatMessage(appMessages.manual),
          city: props.intl.formatMessage(appMessages.city),
          province: props.intl.formatMessage(appMessages.province),
          state: props.intl.formatMessage(appMessages.state),
          postalCode: props.intl.formatMessage(appMessages.postal_code),
          address: props.intl.formatMessage(appMessages.address),
          autoPlaceholder: props.intl.formatMessage(
            appMessages.search_an_address,
          ),
          auto: props.intl.formatMessage(appMessages.residential_address),
        }}
        element={{
          address: values.residenceStreet,
          title: values.residenceAddressTitle,
          city: values.residenceCity,
          province: values.residenceProvinceShort,
          cap: values.residencePostalCode,
          country: values.residenceCountry,
          latitude: values.residenceLatitude,
          longitude: values.residenceLongitude,
        }}
        onChange={(name, value) => {
          const [latitude, longitude] = value.coords || [];
          setFieldValue('residenceStreet', value.address);
          setFieldValue('residenceCity', value.city);
          setFieldValue('residenceProvinceShort', value.province);
          setFieldValue('residencePostalCode', value.cap);
          setFieldValue('residenceCountry', value.country);
          setFieldValue('residenceAddressTitle', value.title);
          setFieldValue('residenceLatitude', latitude);
          setFieldValue('residenceLongitude', longitude);
        }}
        onSwitch={el => setFieldValue('residenceAddressSelectionType', el)}
        // eslint-disable-next-line no-unused-vars
        onBlur={(name, value) => {
          handleValueFromBlur(name, value, setFieldValue);
        }}
        touched={touched}
        errors={errors}
      />
    </Form.Field>
  );
};

AddressFields.defaultProps = {
  values: {},
  isRequired: false,
};

AddressFields.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  canModify: PropTypes.bool,
  intl: intlShape.isRequired,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({}),
};

export default injectIntl(AddressFields);
