/*
 * ChooseProductType Messages
 *
 * This contains all the text for the ChooseProductType component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.DestinationForm';

export default defineMessages({
  tab_general_data: {
    id: `${scope}.tab_general_data`,
    defaultMessage: 'General data',
  },
  customer_registry: {
    id: `${scope}.customer_registry`,
    defaultMessage: 'Customer registry',
  },
  tab_historical_data: {
    id: `${scope}.tab_historical_data`,
    defaultMessage: 'Historical data',
  },
  tab_contacts: {
    id: `${scope}.tab_contacts`,
    defaultMessage: 'Contacts',
  },
  tab_marketing: {
    id: `${scope}.tab_marketing`,
    defaultMessage: 'Closures',
  },
  tab_general_notes: {
    id: `${scope}.tab_general_notes`,
    defaultMessage: 'Notes',
  },
  tab_billing: {
    id: `${scope}.tab_billing`,
    defaultMessage: 'Billing',
  },
  tab_commercial_data: {
    id: `${scope}.tab_commercial_data`,
    defaultMessage: 'Commercial data',
  },
  tab_shipping: {
    id: `${scope}.tab_shipping`,
    defaultMessage: 'Shipping',
  },
  tab_contracts: {
    id: `${scope}.tab_contracts`,
    defaultMessage: 'Contracts',
  },
  tab_loan: {
    id: `${scope}.tab_loan`,
    defaultMessage: 'Loan',
  },
  tab_overdue: {
    id: `${scope}.tab_overdue`,
    defaultMessage: 'Overdue',
  },
  tab_sampling: {
    id: `${scope}.tab_sampling`,
    defaultMessage: 'Sampling',
  },
  description: {
    id: `${scope}.decription`,
    defaultMessage: 'Description',
  },
  destination_status: {
    id: `${scope}.destination_status`,
    defaultMessage: 'Destination status',
  },
  destination_code: {
    id: `${scope}.destination_code`,
    defaultMessage: 'Destination code',
  },
  destination_typology: {
    id: `${scope}.destination_typology`,
    defaultMessage: 'Destination typology',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Business name',
  },
  customer_area: {
    id: `${scope}.customer_area`,
    defaultMessage: 'Customer area',
  },
  customer_sub_area: {
    id: `${scope}.customer_sub_area`,
    defaultMessage: 'Customer sub area',
  },
  customer_logistic_area: {
    id: `${scope}.customer_logistic_area`,
    defaultMessage: 'Customer logistic area',
  },
  customer_typology: {
    id: `${scope}.customer_typology`,
    defaultMessage: 'Customer typology',
  },
  agent1: {
    id: `${scope}.agent1`,
    defaultMessage: 'Agent #1',
  },
  agent2: {
    id: `${scope}.agent2`,
    defaultMessage: 'Agent #2',
  },
  subagent: {
    id: `${scope}.subagent`,
    defaultMessage: 'Sub agent',
  },
  commercial_director: {
    id: `${scope}.commercial_director`,
    defaultMessage: 'Commercial director',
  },
  area_manager: {
    id: `${scope}.area_manager`,
    defaultMessage: 'Area manager',
  },
  commercial_backoffice: {
    id: `${scope}.commercial_backoffice`,
    defaultMessage: 'Commercial backoffice',
  },
  excluded_from_prize: {
    id: `${scope}.excluded_from_prize`,
    defaultMessage: 'Excluded from prize',
  },
  blocked: {
    id: `${scope}.blocked`,
    defaultMessage: 'Blocked',
  },
  block_reason: {
    id: `${scope}.block_reason`,
    defaultMessage: 'Block reason',
  },
  employees_number: {
    id: `${scope}.employees_number`,
    defaultMessage: 'Employees number',
  },
  seats_number: {
    id: `${scope}.seats_number`,
    defaultMessage: 'Seats number',
  },
  rooms_number: {
    id: `${scope}.rooms_number`,
    defaultMessage: 'Rooms number',
  },
  guests_number: {
    id: `${scope}.guests_number`,
    defaultMessage: 'Guests number',
  },
  stars_number: {
    id: `${scope}.stars_number`,
    defaultMessage: 'Stars number',
  },
  surface: {
    id: `${scope}.surface`,
    defaultMessage: 'Surface (mq)',
  },
  closing_days: {
    id: `${scope}.closing_days`,
    defaultMessage: 'Closing days',
  },
  season_closing_from: {
    id: `${scope}.season_closing_from`,
    defaultMessage: 'Season closing from',
  },
  season_closing_to: {
    id: `${scope}.season_closing_to`,
    defaultMessage: 'Season closing to',
  },
  shipping_time_from: {
    id: `${scope}.shipping_time_from`,
    defaultMessage: 'Shipping time from',
  },
  shipping_time_to: {
    id: `${scope}.shipping_time_to`,
    defaultMessage: 'Shipping time to',
  },
  intervention_time_from: {
    id: `${scope}.intervention_time_from`,
    defaultMessage: 'Intervention time from',
  },
  intervention_time_to: {
    id: `${scope}.intervention_time_to`,
    defaultMessage: 'Intervention time to',
  },
  sdi_code: {
    id: `${scope}.sdi_code`,
    defaultMessage: 'SDI code',
  },
  terms_of_payment: {
    id: `${scope}.terms_of_payment`,
    defaultMessage: 'Terms of payment',
  },
  vat_category: {
    id: `${scope}.vat_category`,
    defaultMessage: 'VAT category',
  },
  bank_charges: {
    id: `${scope}.bank_charges`,
    defaultMessage: 'Bank charges',
  },
  credit_limit: {
    id: `${scope}.credit_limit`,
    defaultMessage: 'Credit limit',
  },
  customer_bank: {
    id: `${scope}.customer_bank`,
    defaultMessage: 'Customer bank',
  },
  statistical_customer: {
    id: `${scope}.statistical_customer`,
    defaultMessage: 'Statistical customer',
  },
  buying_group: {
    id: `${scope}.buying_group`,
    defaultMessage: 'Buying group',
  },
  split_payment: {
    id: `${scope}.split_payment`,
    defaultMessage: 'Split payment',
  },
  one_invoice_per_order: {
    id: `${scope}.one_invoice_per_order`,
    defaultMessage: 'One invoice per order',
  },
  cumulative_invoices: {
    id: `${scope}.cumulative_invoices`,
    defaultMessage: 'Cumulative invoices',
  },
  cumulative_invoices_per_destination: {
    id: `${scope}.cumulative_invoices_per_destination`,
    defaultMessage: 'Cumulative invoices per destination',
  },
  customer_range: {
    id: `${scope}.customer_range`,
    defaultMessage: 'Customer range',
  },
  customer_classification: {
    id: `${scope}.customer_classification`,
    defaultMessage: 'Customer classification',
  },
  potential_turnover: {
    id: `${scope}.potential_turnover`,
    defaultMessage: 'Potential turnover',
  },
  shipping_method: {
    id: `${scope}.shipping_method`,
    defaultMessage: 'Shipping method',
  },
  carrier: {
    id: `${scope}.carrier`,
    defaultMessage: 'Carrier',
  },
  one_ddt_per_order: {
    id: `${scope}.one_ddt_per_order`,
    defaultMessage: 'One DDT per order',
  },
  one_pick_order_per_order: {
    id: `${scope}.one_pick_order_per_order`,
    defaultMessage: 'One pick order per order',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  destination_state: {
    id: `${scope}.destination_state`,
    defaultMessage: 'Destination State',
  },
  destination_area: {
    id: `${scope}.destination_area`,
    defaultMessage: 'Destination Area',
  },
  destination_sub_area: {
    id: `${scope}.destination_sub_area`,
    defaultMessage: 'Destination Sub Area',
  },
  destination_logistic_area: {
    id: `${scope}.destination_logistic_area`,
    defaultMessage: 'Destination Logistic Area',
  },
  destination_range: {
    id: `${scope}.destination_range`,
    defaultMessage: 'Destination Range',
  },
  destination_payment_term: {
    id: `${scope}.destination_payment_term`,
    defaultMessage: 'Destination Payment Term',
  },
  destination_bank: {
    id: `${scope}.destination_bank`,
    defaultMessage: 'Destination Bank',
  },
  destination_shipping_method: {
    id: `${scope}.destination_shipping_method`,
    defaultMessage: 'Destination Shipping Method',
  },
  destination_carrier: {
    id: `${scope}.destination_carrier_carrier`,
    defaultMessage: 'Destination Carrier',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: 'User',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  new_note: {
    id: `${scope}.new_note`,
    defaultMessage: 'New note',
  },
  edit_note: {
    id: `${scope}.edit_note`,
    defaultMessage: 'Edit note',
  },
  view_note: {
    id: `${scope}.view_note`,
    defaultMessage: 'View note',
  },
  note_removed_success: {
    id: `${scope}.note_removed_success`,
    defaultMessage: 'Note successfully removed.',
  },
  note_execute_success: {
    id: `${scope}.note_execute_success`,
    defaultMessage: 'Note successfully executed.',
  },
  tab_commisions: {
    id: `${scope}.tab_commisions`,
    defaultMessage: 'Commissions',
  },
  discount_part: {
    id: `${scope}.discount_part`,
    defaultMessage: 'Discount',
  },
  order_amount_part: {
    id: `${scope}.order_amount_part`,
    defaultMessage: 'Order Amount',
  },
  invoice_amount_part: {
    id: `${scope}.invoice_amount_part`,
    defaultMessage: 'Invoice Amount',
  },
  add_new: {
    id: `${scope}.add_new`,
    defaultMessage: 'Add new',
  },
  add_new_discount: {
    id: `${scope}.add_new_discount`,
    defaultMessage: 'Add new discount',
  },
  commissionStartDate: {
    id: `${scope}.commissionStartDate`,
    defaultMessage: 'Valid from',
  },
  commissionEndDate: {
    id: `${scope}.commissionEndDate`,
    defaultMessage: 'Valid to',
  },
  commissionFrom: {
    id: `${scope}.commissionFrom`,
    defaultMessage: 'From',
  },
  commissionTo: {
    id: `${scope}.commissionTo`,
    defaultMessage: 'To',
  },
  commission: {
    id: `${scope}.commission`,
    defaultMessage: 'Commission',
  },
  refund: {
    id: `${scope}.refund`,
    defaultMessage: 'Refund',
  },
  award: {
    id: `${scope}.award`,
    defaultMessage: 'Included in bonus [calculation]',
  },
  isIncludedInBonus: {
    id: `${scope}.isIncludedInBonus`,
    defaultMessage: 'Included in bonus [calculation]',
  },
  award_true: {
    id: `${scope}.award_true`,
    defaultMessage: 'Yes',
  },
  award_false: {
    id: `${scope}.award_false`,
    defaultMessage: 'No',
  },
  destination_data: {
    id: `${scope}.destination_data`,
    defaultMessage: 'Destination data',
  },
  tab_agent_appointments: {
    id: `${scope}.tab_agent_appointments`,
    defaultMessage: 'Agent Appointments',
  },
  tab_technical_interventions: {
    id: `${scope}.tab_technical_interventions`,
    defaultMessage: 'Technical Interventions',
  },
  tab_orders: {
    id: `${scope}.tab_orders`,
    defaultMessage: 'Orders',
  },
  tab_offers: {
    id: `${scope}.tab_offers`,
    defaultMessage: 'Offers',
  },
  ultima_fattura: {
    id: `${scope}.ultima_fattura`,
    defaultMessage: 'Ultima Fattura',
  },
  ultimo_intervento: {
    id: `${scope}.ultimo_intervento`,
    defaultMessage: 'Ultimo Intervento',
  },
  ultima_offerta: {
    id: `${scope}.ultima_offerta`,
    defaultMessage: 'Ultima Offerta',
  },
  ultima_nota: {
    id: `${scope}.ultima_nota`,
    defaultMessage: 'Ultima Nota',
  },
  reference_agent: {
    id: `${scope}.reference_agent`,
    defaultMessage: 'Reference agent',
  },
  dashboard: {
    id: `${scope}.dashboard`,
    defaultMessage: 'Dashboard',
  },
  tab_attachments: {
    id: `${scope}.tab_attachments`,
    defaultMessage: 'Attachments',
  },
  destinationCode_required: {
    id: `${scope}.destinationCode_required`,
    defaultMessage: 'Destination code is required!',
  },
  signaller: {
    id: `${scope}.signaller`,
    defaultMessage: 'Signaller',
  },
  noteNumber: {
    id: `${scope}.noteNumber`,
    defaultMessage: 'Note number',
  },
  prospect: {
    id: `${scope}.prospect`,
    defaultMessage: 'Prospect',
  },
  display_name: {
    id: `${scope}.display_name`,
    defaultMessage: 'Display name',
  },
  phone_number: {
    id: `${scope}.phone_number`,
    defaultMessage: 'Phone number',
  },
  mobile_number: {
    id: `${scope}.mobile_number`,
    defaultMessage: 'Mobile number',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  fax: {
    id: `${scope}.fax`,
    defaultMessage: 'Fax',
  },
  web_site: {
    id: `${scope}.web_site`,
    defaultMessage: 'Web site',
  },
  visits: {
    id: `${scope}.visits`,
    defaultMessage: 'Visits',
  },
  deliveries: {
    id: `${scope}.deliveries`,
    defaultMessage: 'Deliveries',
  },
  last_invoice: {
    id: `${scope}.last_invoice`,
    defaultMessage: 'Last invoice',
  },
  turnover_year: {
    id: `${scope}.turnover_year`,
    defaultMessage: 'Year turnover',
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category',
  },
  contact_person: {
    id: `${scope}.contact_person`,
    defaultMessage: 'Contact person',
  },
  headquarter: {
    id: `${scope}.headquarter`,
    defaultMessage: 'Headquarter',
  },
  assign_to_customer: {
    id: `${scope}.assign_to_customer`,
    defaultMessage: 'Assign to customer',
  },
  assign_to_customer_success: {
    id: `${scope}.assign_to_customer_success`,
    defaultMessage: 'Prospect assigned successfully to customer!',
  },
  transform_to_customer: {
    id: `${scope}.transform_to_customer`,
    defaultMessage: 'Transform to customer',
  },
  transform_to_customer_success: {
    id: `${scope}.transform_to_customer_success`,
    defaultMessage: 'Prospect transformed successfully to customer!',
  },
  customer_required: {
    id: `${scope}.customer_required`,
    defaultMessage: 'Customer must be selected',
  },
  note_type: {
    id: `${scope}.note_type`,
    defaultMessage: 'Note type',
  },
  expire_date: {
    id: `${scope}.expire_date`,
    defaultMessage: 'Expire date',
  },
  execution_date: {
    id: `${scope}.execution_date`,
    defaultMessage: 'Execution date',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done',
  },
  to_be_done: {
    id: `${scope}.to_be_done`,
    defaultMessage: 'To be done',
  },
  fromPercentage: {
    id: `${scope}.fromPercentage`,
    defaultMessage: 'Percentage from',
  },
  toPercentage: {
    id: `${scope}.toPercentage`,
    defaultMessage: 'Percentage to',
  },
  flat_unit: {
    id: `${scope}.flat_unit`,
    defaultMessage: 'Flat unit',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
  exclude_part: {
    id: `${scope}.exclude_part`,
    defaultMessage: 'Exclude',
  },
  tab_linked_destinations: {
    id: `${scope}.tab_linked_destinations`,
    defaultMessage: 'Linked destinations',
  },
  tab_invoices: {
    id: `${scope}.tab_invoices`,
    defaultMessage: 'Invoices',
  },
  execution_note_text: {
    id: `${scope}.execution_note_text`,
    defaultMessage: 'Execution note',
  },
  closure: {
    id: `${scope}.closure`,
    defaultMessage: 'Closure',
  },
  forceSave: {
    id: `${scope}.forceSave`,
    defaultMessage: 'Force save',
  },
  forceProspectText: {
    id: `${scope}.forceProspectText`,
    defaultMessage:
      'During the process of transforming the prospect into client, some headquarters with similar data have been found. Fix their date or force the operation!',
  },
  businessFiscalCode: {
    id: `${scope}.businessFiscalCode`,
    defaultMessage: 'Fiscal code',
  },
  vatNumber: {
    id: `${scope}.vatNumber`,
    defaultMessage: 'Vat number',
  },
  contactPerson: {
    id: `${scope}.contactPerson`,
    defaultMessage: 'Contact person',
  },
  invoice_data: {
    id: `${scope}.invoice_data`,
    defaultMessage: 'Invoice data',
  },
  customer_is_legal_headquarter: {
    id: `${scope}.customer_is_legal_headquarter`,
    defaultMessage: 'This customer is the legal headquarter',
  },
  customer_has_linked_destinations: {
    id: `${scope}.customer_has_linked_destinations`,
    defaultMessage: 'This customer has linked destinations',
  },
  linked_destinations: {
    id: `${scope}.linked_destinations`,
    defaultMessage: 'Linked destinations',
  },
  use_assign_button: {
    id: `${scope}.use_assign_button`,
    defaultMessage:
      'If you want to transform the prospect to a customer, use the proper button',
  },
  required: {
    id: `${scope}.required`,
    defaultMessage: 'Required',
  },
  free: {
    id: `${scope}.free`,
    defaultMessage: 'Free',
  },
  tab_product_purchase: {
    id: `${scope}.tab_product_purchase`,
    defaultMessage: 'Products purchase',
  },
  netPrice: {
    id: `${scope}.netPrice`,
    defaultMessage: 'Net price',
  },
  grossPrice: {
    id: `${scope}.grossPrice`,
    defaultMessage: 'Gross price',
  },
  lastPurchase: {
    id: `${scope}.lastPurchase`,
    defaultMessage: 'Last purchase',
  },
  destinationGroup: {
    id: `${scope}.destinationGroup`,
    defaultMessage: 'Group',
  },
  destinationRange: {
    id: `${scope}.destinationRange`,
    defaultMessage: 'Range',
  },
  shopSign: {
    id: `${scope}.shopSign`,
    defaultMessage: 'Shop sign',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Company name',
  },
  businessPostalCode: {
    id: `${scope}.businessPostalCode`,
    defaultMessage: 'Postal code',
  },
  businessCity: {
    id: `${scope}.businessCity`,
    defaultMessage: 'City',
  },
  businessProvinceShort: {
    id: `${scope}.businessProvinceShort`,
    defaultMessage: 'Province',
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'Phone number',
  },
  discount: {
    id: `${scope}.discount`,
    defaultMessage: 'Discount',
  },
  quantity: {
    id: `${scope}.quantity`,
    defaultMessage: 'Quantity',
  },
});
