/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Styled from 'styled-components';
import appMessages from '../../containers/App/messages';
import { makeSelectStyles } from '../../containers/App/selectors';

const StyledIcon = Styled(Icon)`
  cursor: pointer;
  color: ${props => props.standarColor};
  &:hover {
    color: ${props => props.hoverColor};
  }
`;

export const RemoveBtn = injectIntl(
  ({ action, intl, size, style, disabled, icon = 'remove' }) => (
    <Button
      disabled={disabled}
      circular
      color="red"
      icon={icon}
      type="button"
      size={size}
      style={style || {}}
      onClick={(e, data) =>
        confirm(intl.formatMessage(appMessages.are_you_sure))
          ? action(e, data)
          : null
      }
    />
  ),
);

export const ExecuteBtn = injectIntl(
  ({ action, size, style, disabled, loading }) => (
    <Button
      disabled={disabled}
      loading={loading}
      circular
      color="green"
      icon="check"
      type="button"
      size={size}
      style={style || {}}
      onClick={() => action()}
    />
  ),
);

export const FormRemoveBtn = injectIntl(({ action, intl, loading, notice }) => (
  <Button
    loading={loading}
    type="button"
    icon
    color="yellow"
    labelPosition="left"
    onClick={() =>
      notice
        ? confirm(intl.formatMessage(appMessages.are_you_sure)) && action() // eslint-disable-line
        : action()
    }
  >
    <Icon name="trash" />
    {intl.formatMessage(appMessages.delete)}
  </Button>
));

export const FormBackBtn = injectIntl(
  ({ action, intl, customBackRoute, history }) => (
    <Button
      type="button"
      icon
      labelPosition="left"
      onClick={() =>
        customBackRoute && history
          ? history.push(customBackRoute)
          : action
            ? action()
            : window.history.back()
      }
    >
      <Icon name="arrow left" />
      {intl.formatMessage(appMessages.back)}
    </Button>
  ),
);

export const FormDuplicateBtn = injectIntl(
  ({ action, intl, disabled, loading, label }) => (
    <Button
      type="button"
      loading={loading}
      disabled={disabled}
      icon
      labelPosition="left"
      primary
      onClick={() => action()}
    >
      <Icon name="copy" />
      {label || intl.formatMessage(appMessages.duplicate)}
    </Button>
  ),
);

export const FormExportPdfBtn = injectIntl(
  ({ action, intl, disabled, loading }) => (
    <Button
      type="button"
      loading={loading}
      disabled={disabled}
      icon
      labelPosition="left"
      primary
      onClick={() => action()}
    >
      <Icon name="file pdf" />
      {intl.formatMessage(appMessages.exportPdf)}
    </Button>
  ),
);

export const FormEditBtn = injectIntl(({ action, intl }) => (
  <Button
    type="button"
    icon
    labelPosition="left"
    primary
    onClick={() => action()}
  >
    <Icon name="pencil" />
    {intl.formatMessage(appMessages.edit)}
  </Button>
));

export const EditBtn = ({ action }) => (
  <Button
    type="button"
    circular
    color="blue"
    icon="pencil alternate"
    onClick={action}
  />
);

export const ViewBtn = ({ action }) => (
  <Button type="button" circular color="blue" icon="eye" onClick={action} />
);

export const InfoBtn = ({ action, color, disabled }) => (
  <Button
    type="button"
    circular
    color={color}
    icon="info"
    onClick={action}
    disabled={disabled}
  />
);

export const DownloadBtn = ({ action, disabled }) => (
  <Button
    type="button"
    circular
    color="blue"
    icon="download"
    onClick={action}
    disabled={disabled}
  />
);

export const ManageACLBtn = ({ action }) => (
  <Button type="button" circular color="blue" icon="key" onClick={action} />
);

export const ParcelsBtn = ({ action }) => (
  <Button type="button" circular color="blue" icon="boxes" onClick={action} />
);

export const NewBtn = ({ action }) => (
  <Button type="button" icon labelPosition="left" onClick={action} color="blue">
    <Icon name="plus" />
    New
  </Button>
);

export const ModalNewBtn = props => (
  <Button {...props} type="button" icon labelPosition="left" color="blue">
    <Icon name="plus" />
    {props.children}
  </Button>
);

export const ModalCloseBtn = props => (
  <Button {...props} type="button" icon labelPosition="left">
    <Icon name="remove" />
    {props.children}
  </Button>
);

export const SearchBtn = props => (
  <Button {...props} type="button" icon labelPosition="left">
    <Icon name="search" />
    {props.children}
  </Button>
);

const BackBtnComponent = ({ action, styles }) => (
  <StyledIcon
    name="chevron left"
    onClick={action}
    style={{ cursor: 'pointer' }}
    standarColor={styles.GREY}
    hoverColor={styles.DARK_GREY}
  />
);

export const RemovePhotoBtn = ({ action }) => (
  <Button
    type="button"
    style={{
      position: 'absolute',
      width: '2px',
      background: 'transparent',
      top: '0',
      right: '0',
      height: '2px',
      paddingTop: '0',
      paddingRight: '12px',
    }}
    onClick={action}
  >
    x
  </Button>
);

export const SmallCircularActionBtn = ({
  color,
  icon,
  action,
  disabled,
  loading,
}) => (
  <Button
    type="button"
    disabled={disabled}
    loading={loading}
    is
    style={{
      padding: 0,
      width: '36px',
      height: '36px',
    }}
    circular
    color={color}
    icon={icon}
    onClick={action}
  />
);

const mapStateToProps = createStructuredSelector({
  styles: makeSelectStyles(),
});

const withConnect = connect(mapStateToProps);

export const BackBtn = compose(withConnect)(BackBtnComponent);

ParcelsBtn.propTypes = {
  action: PropTypes.func,
};

FormRemoveBtn.propTypes = {
  action: PropTypes.func,
  intl: intlShape.isRequired,
};

BackBtnComponent.propTypes = {
  action: PropTypes.func,
  styles: PropTypes.object,
};

ManageACLBtn.propTypes = {
  action: PropTypes.func,
};

RemoveBtn.propTypes = {
  action: PropTypes.func,
  intl: intlShape.isRequired,
};

EditBtn.propTypes = {
  action: PropTypes.func,
};

ViewBtn.propTypes = {
  action: PropTypes.func,
};

InfoBtn.propTypes = {
  action: PropTypes.func,
  color: PropTypes.string,
  disabled: PropTypes.bool,
};

NewBtn.propTypes = {
  action: PropTypes.func,
};

DownloadBtn.propTypes = {
  action: PropTypes.func,
  disabled: PropTypes.bool,
};

RemovePhotoBtn.propTypes = {
  action: PropTypes.func,
};

SmallCircularActionBtn.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  action: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};
