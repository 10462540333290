/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
// import { FormattedMessage } from 'react-intl';
import { Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
// import messages from './messages';
import SimpleFormikField from 'novigo-simple-formik-field';
import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import { addNotification } from '../../../../utils/notification';
import MunicipalityService from '../../../../shared/services/municipality';

const CFBtnStyle = {
  position: 'absolute',
  right: 11,
  top: 24,
  borderRadius: 0,
};

function FiscalCodeField(props) {
  const { values, setFieldValue, noCalc } = props;
  const [cfLoading, setCfLoading] = useState(false);

  const calculateCF = () => {
    if (
      !values.firstName ||
      !values.lastName ||
      !values.birthDate ||
      !values.gender ||
      !values.birthCity ||
      !values.birthProvinceShort
    ) {
      addNotification({
        title: props.intl.formatMessage(appMessages.error),
        message: props.intl.formatMessage(messages.cf_error),
        isError: true,
      });
      return;
    }
    const payload = {
      name: values.firstName,
      surname: values.lastName,
      birthDate: values.birthDate.format('YYYY-MM-DD'),
      gender: _.get(values, 'gender.value') || values.gender,
      municipality: values.birthCity,
      province: values.birthProvinceShort,
    };

    setCfLoading(true);
    MunicipalityService.calculateCF(payload)
      .then(res => {
        setCfLoading(false);
        setFieldValue('fiscalCode', res.fiscalCode);
      })
      .catch(() => {
        setCfLoading(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: props.intl.formatMessage(messages.cf_error),
          isError: true,
        });
      });
  };

  return (
    <>
      <SimpleFormikField
        {...props}
        name="fiscalCode"
        label={props.intl.formatMessage(messages.fiscal_code)}
        maxlength="16"
        required
        style={{ textTransform: 'uppercase' }}
      />
      {!noCalc && (
        <Button
          type="button"
          icon
          style={CFBtnStyle}
          onClick={calculateCF}
          disabled={props.readOnly}
          loading={cfLoading}
        >
          <Icon name="calculator" />
        </Button>
      )}
    </>
  );
}

FiscalCodeField.defaultProps = {
  values: {},
  readOnly: false,
};

FiscalCodeField.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default injectIntl(FiscalCodeField);
