import request from '../request';
import { API } from '../../../global-constants';

/**
 * Create a new customer and a new destination entity based on the given prospect.
 * @param {*} prospect Data of the prospect.
 * @returns
 */
const transformProspectToCustomer = data =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PROSPECT_TO_CUSTOMER}`,
    method: 'POST',
    data,
  });

/**
 * Update the given prospect.
 * @param {string} id ID of the destination.
 * @param {*} prospect Data of the prospect.
 * @returns
 */
const saveProspect = (id, prospect) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION}/${id}`,
    method: 'PUT',
    data: prospect,
  });

const ProspectToCustomerService = {
  saveProspect,
  transformProspectToCustomer,
};

export default ProspectToCustomerService;
