import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import DocumentPart from '../../Anagrafica/formParts/documentsPart';

import DestinationAttachmentService from '../../../../shared/services/destinationattachment';

function AttachmentsTab(props) {
  return (
    <DocumentPart
      {...props}
      entity="destination"
      documentService={DestinationAttachmentService}
    />
  );
}

AttachmentsTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AttachmentsTab);
