import _, { get, isNaN } from 'lodash';
import includes from 'lodash/includes';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import Table from 'semantic-table-grid';
import styled from 'styled-components';
import {
  Checkbox,
  Button,
  Form,
  Grid,
  Icon,
  ListItem,
  List,
  ListHeader,
  Popup,
} from 'semantic-ui-react';

import appMessages from '../../../../containers/App/messages';
import DiscountTypeService from '../../../../shared/services/discountType';
import OrderService from '../../../../shared/services/order';
import OrderRowService from '../../../../shared/services/orderrow';
import ProductService from '../../../../shared/services/product';
// Services
import request from '../../../../shared/services/request';
import currencyFormatter, {
  numberFormatter,
} from '../../../../utils/currencyFormatter';
import { addNotification } from '../../../../utils/notification';
import percentageFormatter from '../../../../utils/percentageFormatter';
import Accordion from '../../../Accordion';
import {
  FormDuplicateBtn,
  RemoveBtn,
  SmallCircularActionBtn,
} from '../../../Buttons';
import FormActionsBar from '../../../FormActionsBar';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import FormikInput from '../../../FormikInput';
import { ModalFakeActions, Wrapper } from '../../../Layout';
import Modal from '../../../Modal';
import PermissionManager from '../../../Permission';
import InsertNewPart from '../../OfferForm/manageForm/formParts/InsertNewPart';
import NoteSection from '../../OfferForm/manageForm/formParts/NoteSection';
import OrderStateSection from '../../OfferForm/manageForm/formParts/offerState';
import messages from '../messages';
import { switchPayload } from '../utils';
import DiscountConfirmModal from './modals/discountConfirmModal';
import DiscountForm from './modals/discountEditModal/discountForm';
import LastOfferForm from './modals/lastOfferModal/lastOfferForm';
import GoodsDiscountModal from './modals/goodsDiscountModal';
import StateHistoryModal from './modals/stateHistoryModal';
import OfferService from '../../../../shared/services/offer';
import { ORDER_STATE_INSERTED } from '../../../../containers/Order/constants';
import './css/styles.css';
import { API } from '../../../../global-constants';
import { getNetPrice } from './functions';

const NetPriceEdited = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const ManageForm = props => {
  const { handleSubmit, setFieldValue, values, setValues, readOnly } = props;

  const [article, setArticle] = useState({});
  const [orderRows, setOrderRows] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isInserting, setInserting] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [editableRows, setEditableRows] = useState([]);
  const [gdModalOpened, setGDModalOpened] = useState(false);
  const [promotionPrice, setPromotionPrice] = useState(null);
  const [editableRowsData, setEditableRowsData] = useState([]);
  const [offerRowUpdating, setOfferRowUpdating] = useState(false);
  const [stateModalOpened, setStateModalOpened] = useState(false);
  const [confirmModalState, setConfirmModalState] = useState(false);
  const [discountModalOpened, setDiscountModalOpened] = useState(false);
  const [lastOfferModalOpened, setLastOfferModalOpened] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [isNotesOpen, setIsNotesOpen] = useState(true);
  const [lastOffer, setLastOffer] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
    pageSize: 5,
    totalItems: 0,
  });

  const [activeType, setActiveType] = useState('Famiglia');

  const handleActiveType = name => {
    setActiveType(name);
  };

  const [isNewDiscountItem, setIsNewDiscountItem] = useState(false);
  const [discountTypes, setdiscountTypes] = useState([]);

  const handleNewDiscountItem = bool => {
    setIsNewDiscountItem(bool);
  };

  const handleCloseDiscountModal = () => {
    setDiscountModalOpened(false);
    handleNewDiscountItem(false);
    handleActiveType('Famiglia');
  };

  const handleUpdateCompliant = order => {
    setValues({
      ...values,
      avarageDiscount: _.get(order, 'avarageDiscount', null),
      avarageMarkup: _.get(order, 'avarageMarkup', null),
      totalPriceAmount: _.get(order, 'totalPriceAmount', null),
      nonCompliant: _.get(order, 'nonCompliant', null),
      nonCompliantInsolvent: _.get(order, 'nonCompliantInsolvent', null),
      nonCompliantAverageDiscount: _.get(
        order,
        'nonCompliantAverageDiscount',
        null,
      ),
      nonCompliantProductDiscount: _.get(
        order,
        'nonCompliantProductDiscount',
        null,
      ),
      nonCompliantProductMarkup: _.get(
        order,
        'nonCompliantProductMarkup',
        null,
      ),
      netPriceEdited: _.get(order, 'netPriceEdited', null),
      orderType: {
        label: `${_.get(order, 'orderType.code')} -
        ${_.get(order, 'orderType.description')}`,
        value: _.get(order, 'orderType.id'),
      },
    });
  };

  const handleCloseLastOFferModal = () => {
    setLastOfferModalOpened(false);
  };

  const handleDuplication = () => {
    setIsDuplicating(true);
    const duplicateData = {
      isDuplicate: true,
      orderType: _.get(values, 'orderType.value', null),
      orderId: values.id,
      destination: _.get(values, 'destination.value', null),
      totalBuyPriceAmount: _.get(values, 'totalBuyPriceAmount', 0),
      totalGrossPriceAmount: _.get(values, 'totalGrossPriceAmount', 0),
      totalPriceAmount: _.get(values, 'totalPriceAmount', 0),
      delivery: _.get(values, 'delivery', null),
      internalNote: _.get(values, 'internalNote', null),
      deliveryNote: _.get(values, 'deliveryNote', null),
      finalized: 1,
      referenceDate:
        values.referenceDate !== null
          ? moment(values.referenceDate).format('YYYY-MM-DD')
          : null,
      destinationOrderAmountCommissionPercentage: _.get(
        values,
        'destinationOrderAmountCommissionPercentage',
        null,
      ),
      destinationOrderAmountRefundPercentage: _.get(
        values,
        'destinationOrderAmountRefundPercentage',
        null,
      ),
      destinationOrderAmountCommission: _.get(
        values,
        'destinationOrderAmountCommission',
        null,
      ),
      destinationOrderAmountRefund: _.get(
        values,
        'destinationOrderAmountRefund',
        null,
      ),
      amountForEndYearBonus: _.get(values, 'amountForEndYearBonus', null),
      totalRowsMaster: _.get(values, 'totalRowsMaster', null),
      totalRowsCustomer: _.get(values, 'totalRowsCustomer', null),
      totalRowsFlatUnit: _.get(values, 'totalRowsFlatUnit', null),
      totalRowsCommissionAmount: _.get(
        values,
        'totalRowsCommissionAmount',
        null,
      ),
      totalRowsRefundAmount: _.get(values, 'totalRowsRefundAmount', null),
      state: ORDER_STATE_INSERTED,
      avarageDiscount: _.get(values, 'avarageDiscount', null),
      avarageMarkup: _.get(values, 'avarageMarkup', null),
      nonCompliant: _.get(values, 'nonCompliant', null),
      nonCompliantAverageDiscount: _.get(
        values,
        'nonCompliantAverageDiscount',
        null,
      ),
      nonCompliantProductDiscount: _.get(
        values,
        'nonCompliantProductDiscount',
        null,
      ),
      nonCompliantProductMarkup: _.get(
        values,
        'nonCompliantProductMarkup',
        null,
      ),
      maxDiscount: _.get(values, 'maxDiscount', null),
      priceAmount: _.get(values, 'priceAmount', null),
    };
    OrderService.duplicate(duplicateData)
      .then(() => {
        setOpenDuplicateModal(false);
        addNotification({
          title: null,
          message: props.intl.formatMessage(messages.duplicateSuccess),
          isError: false,
        });
      })
      .catch(err =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      )
      .finally(() => {
        setIsDuplicating(false);
      });
  };

  useEffect(() => {
    if (!values.discountType) {
      DiscountTypeService.getAllOptions(props.intl).then(res => {
        const defaultOption = res.filter(item => item.code === 'FIXED');
        setFieldValue('discountType', defaultOption[0]);
        setdiscountTypes(res);
      });
    }
  });

  useEffect(
    () => {
      loadData();
    },
    [values.id, _.get(values, '_embedded.rows._links.self.href', null)],
  );

  useEffect(
    () => {
      if (lastOffer) {
        setLastOfferModalOpened(true);
      }
    },
    [lastOffer],
  );

  useEffect(
    () => {
      loadData();
    },
    [props.reloadIndex],
  );

  /**
   * When the user changes the destination, update the offer rows as well ('cause there are different customer and offer prices).
   */
  useEffect(
    () => {
      if (orderRows.length > 0) {
        OrderService.patch({ id: values.id, destination: _.get(values, 'destination.value', null) }).then(loadData);
      }

    },
    [values.destination]
  );

  const transformData = data => {
    const expirationDate = _.get(
      data,
      'expirationDate',
      _.get(data, 'expireDate', null),
    );
    return {
      ...data,
      expirationDate: moment(expirationDate),
      destination: {
        label: _.get(data, '_embedded.destination.businessName', null),
        value: _.get(data, '_embedded.destination.id', null),
      },
      customer: {
        label: _.get(data, '_embedded.customer.displayName', null),
        value: _.get(data, '_embedded.customer.id', null),
      },
      typology: {
        label: _.get(data, '_embedded.typology.name', null),
        value: _.get(data, '_embedded.typology.id', null),
      },
    };
  };

  const columns = () =>
    [
      {
        key: 'name',
        name: props.intl.formatMessage(messages.article),
        formatter: ({ data }) =>
          editableRows.includes(data.id) ? (
            <>
              <FormikAsyncSelect
                {...props}
                name="product"
                label=" "
                initialValue={
                  editableRowsData.find(row => row.id === data.id)
                    ? editableRowsData.find(row => row.id === data.id).product
                    : null
                }
                onChange={product => {
                  handleOnArticleRowChange(product.value, data);
                }}
                asyncSelectProps={{
                  isClearable: true,
                }}
                loadOptions={(searchText, callback) =>
                  ProductService.getOptions(searchText, callback)
                }
              />
              <FormikInput
                {...props}
                label=" "
                name="note"
                initialValue={
                  editableRowsData.find(row => row.id === data.id)
                    ? editableRowsData.find(row => row.id === data.id).note
                    : null
                }
                onChange={e =>
                  modifyRowDataPayload(e.target.value, 'note', data.id)
                }
              />
            </>
          ) : (
            <>
              <div>
                {_.get(data, 'product.productCode', '')}
                {data.product &&
                  data.product.productCode &&
                  data.product.title &&
                  ' - '}
                {data.product.title || ''}
              </div>
              <div>{_.get(data, 'note', '')}</div>
            </>
          ),
      },
      {
        key: 'productMasterPrice',
        width: 10,
        name: (
          <span>{props.intl.formatMessage(messages.productMasterPrice)}</span>
        ),
        formatter: ({ data }) => {
          const { productMasterPrice } = data;
          return (
            <>
              {productMasterPrice
                ? currencyFormatter.format(productMasterPrice)
                : '--'}
            </>
          );
        },
      },
      {
        key: 'productCustomerPrice',
        width: 10,
        name: (
          <span>{props.intl.formatMessage(messages.productCustomerPrice)}</span>
        ),
        formatter: ({ data }) => {
          const { productCustomerPrice } = data;
          return (
            <>
              {productCustomerPrice
                ? currencyFormatter.format(productCustomerPrice)
                : '--'}
            </>
          );
        },
      },
      {
        key: 'productOfferPrice',
        width: 10,
        name: (
          <span>{props.intl.formatMessage(messages.productOfferPrice)}</span>
        ),
        formatter: ({ data }) => {
          const { offerPrice } = data;
          return (
            <>{offerPrice ? currencyFormatter.format(offerPrice) : '--'}</>
          );
        },
      },
      {
        key: 'netPrice',
        width: 90,
        name: props.intl.formatMessage(messages.unit_net_price),
        formatter: ({ data }) =>
          <NetPriceEdited>
            {editableRows.includes(data.id) && (
              <FormikInput
                {...props}
                name="netPrice"
                disabled={
                  editableRowsData.find(row => row.id === data.id)
                    .netPriceDisabled || false
                }
                label=" "
                initialValue={
                  editableRowsData.find(row => row.id === data.id).netPriceRow
                    .value
                }
                onChange={e => {
                  const np = getNetPrice(data);
                  const value = e.target.value.replace(',', '.');
                  if (
                    np !== null &&
                    parseFloat(np) !== 0 &&
                    data.productMasterPrice !== 0
                  )
                    modifyRowDataPayloadValue(
                      parseFloat(
                        100 - (value * 100) / data.productMasterPrice,
                      ).toFixed(2),
                      'discountValue',
                      data.id,
                    );
                  modifyRowDataPayloadValue('0', 'discountExtra', data.id);
                  modifyRowDataPayload(value, 'netPriceRow', data.id);
                  modifyRowDataPayload(true, 'netPriceEdited', data.id);

                  if (parseFloat(data.buyPrice) !== 0)
                    modifyRowDataPayload(
                      (
                        parseFloat(
                          (data.netPrice - data.buyPrice) / data.buyPrice,
                        ) * 100
                      ).toFixed(2),
                      'markup',
                      data.id,
                    );
                  updateTotal(data);
                }}
              />
            )}
            {!editableRows.includes(data.id) && (data.netPrice ? currencyFormatter.format(data.netPrice) : '--')}
            {data.netPriceEdited && <Popup trigger={<Icon name="info circle" />} content={props.intl.formatMessage(messages.net_price_edited_message)} size="small" />}
          </NetPriceEdited>,

      },
      {
        key: 'markup',
        name: 'Ricarico',
        width: 100,
        formatter: ({ data }) =>
          editableRows.includes(data.id) ? (
            <FormikInput
              {...props}
              name="markup"
              label=" "
              initialValue={
                editableRowsData.find(row => row.id === data.id).markup
              }
              onChange={e => {
                const value = e.target.value.replace(',', '.');
                const np =
                  (value / 100) * parseFloat(data.buyPrice) +
                  parseFloat(data.buyPrice);

                if (np !== null && parseFloat(np) !== 0 && data.buyPrice !== 0)
                  modifyRowDataPayload(value, 'markup', data.id);

                modifyRowDataPayloadValue(
                  parseFloat(np).toFixed(2),
                  'netPriceRow',
                  data.id,
                );
                modifyRowDataPayloadValue(
                  parseFloat(
                    100 - (np * 100) / data.productMasterPrice,
                  ).toFixed(2),
                  'discountValue',
                  data.id,
                );
                modifyRowDataPayloadValue('0', 'extraDiscount', data.id);
                modifyRowDataPayload(
                  e.target.value * parseFloat(data.quantity, 10),
                  'priceAmount',
                  data.id,
                );
              }}
            />
          ) : (
            data.markup
          ),
      },
      {
        key: 'quantity',
        name: props.intl.formatMessage(messages.quantity),
        width: 100,
        formatter: ({ data }) =>
          // eslint-disable-next-line no-nested-ternary
          editableRows.includes(data.id) ? (
            <FormikInput
              {...props}
              name="quantity"
              label=" "
              initialValue={
                editableRowsData.find(row => row.id === data.id).quantity
              }
              onChange={e => {
                modifyRowDataPayload(e.target.value, 'quantity', data.id);
                updateTotal(data);
              }}
            />
          ) : data.quantity ? (
            `${numberFormatter.format(_.get(data, 'quantity'))} pz`
          ) : (
            '--'
          ),
      },
      {
        key: 'discountValue',
        width: 80,
        name: props.intl.formatMessage(messages.discount),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) =>
          editableRows.includes(data.id) ? (
            discountValueInput(data)
          ) : (
            <div>
              {data.discountValue
                ? percentageFormatter.format(
                  _.get(data, 'discountValue', '') / 100,
                )
                : ''}
              {data.discountValue && data.extraDiscount && ' + '}
              {data.extraDiscount
                ? percentageFormatter.format(
                  _.get(data, 'extraDiscount', '') / 100,
                )
                : ''}
            </div>
          ),
      },
      {
        key: 'priceAmount',
        name: props.intl.formatMessage(messages.total),
        width: 90,
        formatter: ({ data }) => {
          let res = editableRowsData.find(row => row.id === data.id);
          res = res
            ? currencyFormatter.format(
              editableRowsData.find(row => row.id === data.id).amount,
            )
            : data.priceAmount;
          return res;
        },
      },
      // {
      //   key: 'isProcessed',
      //   name: <span>{props.intl.formatMessage(messages.isProcessed)}</span>,
      //   width: 40,
      //   formatter: ({ data }) =>
      //     // eslint-disable-next-line no-nested-ternary
      //     editableRows.includes(data.id) ? (
      //       <>
      //         <Checkbox
      //           {...props}
      //           name="isProcessed"
      //           label=" "
      //           initialValue={
      //             editableRowsData.find(row => row.id === data.id).isProcessed
      //           }
      //           onChange={(value, toggleData) =>
      //             modifyRowDataPayload(
      //               toggleData.checked,
      //               'isProcessed',
      //               data.id,
      //             )
      //           }
      //         />
      //       </>
      //     ) : (
      //       <>
      //         {data.isProcessed
      //           ? props.intl.formatMessage(messages.yes)
      //           : props.intl.formatMessage(messages.no)}
      //       </>
      //     ),
      // },
      {
        key: 'goodsDiscount',
        width: 40,
        name: <span>{props.intl.formatMessage(messages.goods_discount)}</span>,
        formatter: ({ data }) =>
          // eslint-disable-next-line no-nested-ternary
          editableRows.includes(data.id) ? (
            <>
              <Checkbox
                {...props}
                name="goodsDiscount"
                label=" "
                checked={
                  editableRowsData.find(row => row.id === data.id).goodsDiscount
                }
                onChange={(value, toggleData) => {
                  applyMerchDiscount(toggleData.checked, data);
                  modifyRowDataPayload(
                    toggleData.checked,
                    'goodsDiscount',
                    data.id,
                  );
                }}
              />
            </>
          ) : (
            <>
              {data.goodsDiscount
                ? props.intl.formatMessage(messages.yes)
                : props.intl.formatMessage(messages.no)}
            </>
          ),
      },
      {
        key: 'loan',
        width: 40,
        name: <span>{props.intl.formatMessage(messages.loan)}</span>,
        formatter: ({ data }) =>
          // eslint-disable-next-line no-nested-ternary
          editableRows.includes(data.id) ? (
            <>
              <Checkbox
                {...props}
                name="loan"
                label=" "
                checked={editableRowsData.find(row => row.id === data.id).loan}
                onChange={(value, toggleData) => {
                  applyMerchDiscount(toggleData.checked, data);
                  modifyRowDataPayload(toggleData.checked, 'loan', data.id);
                }}
              />
            </>
          ) : (
            <>
              {data.loan
                ? props.intl.formatMessage(messages.yes)
                : props.intl.formatMessage(messages.no)}
            </>
          ),
      },
      // {
      //   key: 'isSample',
      //   name: <span>{props.intl.formatMessage(messages.is_sample)}</span>,
      //   formatter: ({ data }) =>
      //     // eslint-disable-next-line no-nested-ternary
      //     editableRows.includes(data.id) ? (
      //       <>
      //         <FormikToggle
      //           {...props}
      //           name="isSample"
      //           label=" "
      //           initialValue={
      //             editableRowsData.find(row => row.id === data.id).isSample
      //           }
      //           onChange={(value, toggleData) =>
      //             modifyRowDataPayload(toggleData.checked, 'isSample', data.id)
      //           }
      //         />
      //       </>
      //     ) : (
      //       <>
      //         {data.isSample
      //           ? props.intl.formatMessage(messages.yes)
      //           : props.intl.formatMessage(messages.no)}
      //       </>
      //     ),
      // },
      !readOnly && {
        key: 'actions',
        name: <span>{props.intl.formatMessage(messages.actions)}</span>,
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 80 }}>
            <SmallCircularActionBtn
              loading={offerRowUpdating[data.id]}
              disabled={offerRowUpdating[data.id]}
              icon={
                editableRows.includes(data.id) ? 'check' : 'pencil alternate'
              }
              color={editableRows.includes(data.id) ? 'yellow' : 'blue'}
              action={() =>
                editableRows.includes(data.id)
                  ? saveRow(data.id)
                  : manageEditableRow(data)
              }
            />
            {editableRows.includes(data.id) ? (
              <SmallCircularActionBtn
                disabled={offerRowUpdating[data.id]}
                icon="remove"
                color="orange"
                action={() => cancelEditRow(data.id)}
              />
            ) : (
              <RemoveBtn action={() => removeArticle(data.id)} />
            )}
            {!editableRows.includes(data.id) && (
              <SmallCircularActionBtn
                loading={offerRowUpdating[data.id]}
                disabled={offerRowUpdating[data.id]}
                icon="info"
                color="blue"
                action={() => openLastOffer(data.product.id)}
              />
            )}
          </div>
        ),
        width: '80px',
      },
    ].filter(o => o);

  const loadData = () => {
    if (values.id) {
      setLoading(true);
      request({
        baseURL: API.BASE_URL,
        url: `${API.ORDER}/${values.id}`,
        method: 'GET',
        params: {},
        paramsSerializer: params => qs.stringify(params),
      }).then(response => {
        setLoading(false);
        const order = _.get(response, 'data');
        setValues({
          ...values,
          avarageDiscount: _.get(order, 'avarageDiscount', null),
          avarageMarkup: _.get(order, 'avarageMarkup', null),
          totalPriceAmount: _.get(order, 'totalPriceAmount', null),
          orderType: {
            label: `${_.get(order, 'orderType.code')} -
            ${_.get(order, 'orderType.description')}`,
            value: _.get(order, 'orderType.id'),
          },
          quantity: null,
          product: null,
          discountValue: null,
          extraDiscount: null,
          markup: null,
          flatValue: null,
          netPrice: null,
          category: null,
          subcategory: null,
          domain: null,
        });
        // Update compliants fields
        handleUpdateCompliant(order);

        let rows = _.get(response, `data._embedded.rows`, []);
        rows = rows.sort((a, b) => (a.rowNumber < b.rowNumber ? -1 : 1));
        if (rows.length === 0) {
          setLoading(false);
          setOrderRows([]);
        } else {
          /** Check if there are already ordered offer-rows */
          ProductService.getAlreadyCreated(
            false,
            values.destination.value,
          ).then(res => {
            setLoading(false);
            const ids = _.get(res, 'data._embedded.product', []).map(
              product => product.id,
            );
            rows.forEach((row, index) => {
              rows[index].isOrdered = ids.includes(row.product.id);
              rows[index].isBuyPriceModified =
                row.buyPrice !==
                _.get(row, 'product.productStock.buyPrice', null);

              let discount = 0;
              let discount1 = parseFloat(rows[index].discountValue, 10);
              let discount2 = parseFloat(rows[index].discountExtra, 10);

              if (!isNaN(discount1)) discount += discount1;
              else discount1 = 0;
              if (!isNaN(discount2)) discount += discount2;
              else discount2 = 0;

              if (discount > 0) {
                rows[index].netPrice = parseFloat(
                  (rows[index].netPrice / (100 - discount)) * 100,
                ).toFixed(2);
                rows[index].discountValue = parseFloat(
                  discount1.toString(),
                ).toFixed(2);
                rows[index].extraDiscount = parseFloat(
                  discount2.toString(),
                ).toFixed(2);
              }

              // Assign a class on each row based on the its compliancy.
              rows[index].rowClassKey = row.notCompliant
                ? 'row-not-compliant'
                : 'row-compliant';
            });
          });

          setOrderRows(rows);
          const editRows = manageEditableRows(rows);

          editRows.forEach(row => {
            if (row.loan || row.goodsDiscount) {
              // eslint-disable-next-line no-param-reassign
              row.discountDisabled = true;
              // eslint-disable-next-line no-param-reassign
              row.discountExtraDisabled = true;
              // eslint-disable-next-line no-param-reassign
              row.netPriceDisabled = true;
            }
          });
        }
      });
    }
  };

  const insertOrderRow = (/* flagPromotion = null */) => {
    setInserting(true);

    const payload = {
      destination: _.get(values, 'destination.value', null),
      order: _.get(values, 'id', null),
      products: _.get(values, 'product.value')
        ? [_.get(values, 'product.value')]
        : [],
      quantity: _.get(values, 'quantity', 1),
    };

    if (_.get(values, 'productKit.value')) {
      payload.productKit = _.get(values, 'productKit.value');
    }

    if (_.get(values, 'productCategory.value')) {
      payload.category = _.get(values, 'productCategory.value');
    }

    if (_.get(values, 'subcategory.value')) {
      payload.subcategory = _.get(values, 'subcategory.value');
    }

    OrderRowService.create(payload)
      .then(res => {
        setInserting(false);
        if (
          _.get(res, 'data.promotionStatus') &&
          _.get(res, 'data.promotionStatus') === 1
        ) {
          setConfirmModalState(true);
          setPromotionPrice(_.get(res, 'data.promotionPrice'));
        } else {
          setPromotionPrice(null);
          addNotification({
            title: props.intl.formatMessage(appMessages.success),
            message: _.get(
              res,
              'data.message',
              props.intl.formatMessage(appMessages.success),
            ),
            isError: false,
          });
        }
        loadData();
      })
      .catch(err => {
        setInserting(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  const removeArticle = id => {
    OrderRowService.remove([{ id }])
      .then(res => {
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: _.get(
            res,
            'data.message',
            props.intl.formatMessage(messages.subject_removed_success),
          ),
          isError: false,
        });
        loadData();
      })
      .catch(err =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      );
  };

  const removeSelectedArticles = () => {
    const data = selectedRows.map(row => ({ id: row }));
    OrderRowService.remove(data)
      .then(res => {
        setSelectedRows([]);
        loadData();
        addNotification({
          title: null,
          message: _.get(
            res,
            'data.message',
            props.intl.formatMessage(messages.subject_removed_success),
          ),
          isError: false,
        });
      })
      .catch(error =>
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: _.get(
            error,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        }),
      );
  };

  /**
   * Edit inline a single row.
   * @param {*} rowData The row data.
   */
  const manageEditableRow = rowData => {
    const discountType = _.get(
      rowData,
      '_embedded.discountType',
      discountTypes.find(item => item.code === 'PERCENTAGE'),
    );
    const rowPayload = {
      id: rowData.id,
      product: {
        label: `${_.get(rowData, 'product.productCode', null)} -
        ${_.get(rowData, 'product.title', null)}`,
        value: _.get(rowData, 'product.id', null),
      },
      note: _.get(rowData, 'note', null),
      discountType: {
        label: props.intl.formatMessage(messages[discountType.code]),
        value: discountType.value ? discountType.value : discountType.id,
        code: discountType.code,
      },
      quantity: _.get(rowData, 'quantity', 0),
      discountValue: { value: _.get(rowData, 'discountValue', null) },
      discountExtra: { value: _.get(rowData, 'extraDiscount', null) },
      markup: _.get(rowData, 'markup', null),
      flatValue: { value: _.get(rowData, 'flatValue', null) },
      netPriceRow: {
        value: parseFloat(_.get(rowData, 'netPrice', null)).toFixed(2),
      },
      loan: _.get(rowData, 'loan', ''),
      amount: _.get(rowData, 'priceAmount', ''),
      goodsDiscount: _.get(rowData, 'goodsDiscount', ''),
      isSample: _.get(rowData, 'isSample', ''),
      netPriceEdited: _.get(rowData, 'netPriceEdited', ''),
    };
    setEditableRowsData([...editableRowsData, rowPayload]);
    setEditableRows([...editableRows, rowData.id]);
  };

  /**
   * Edit a list of rows.
   * @param {*} rowsData The row data.
   */
  const manageEditableRows = rowsData => {
    const arrEditableData = [];
    const arrEditableRows = [];

    rowsData.forEach(rowData => {
      const discountType = _.get(
        rowData,
        '_embedded.discountType',
        discountTypes.find(item => item.code === 'PERCENTAGE'),
      );
      const rowPayload = {
        id: rowData.id,
        product: {
          label: `${_.get(rowData, 'product.productCode', null)} -
          ${_.get(rowData, 'product.title', null)}`,
          value: _.get(rowData, 'product.id', null),
        },
        note: _.get(rowData, 'note', null),
        discountType: {
          label: props.intl.formatMessage(messages[discountType.code]),
          value: discountType.value ? discountType.value : discountType.id,
          code: discountType.code,
        },
        amount: _.get(rowData, 'priceAmount', 1),
        quantity: _.get(rowData, 'quantity', 0),
        discountValue: { value: _.get(rowData, 'discountValue', null) },
        discountExtra: { value: _.get(rowData, 'extraDiscount', null) },
        markup: _.get(rowData, 'markup', null),
        flatValue: { value: _.get(rowData, 'flatValue', null) },
        netPriceRow: {
          value: parseFloat(_.get(rowData, 'netPrice', null)).toFixed(2),
        },
        loan: _.get(rowData, 'loan', ''),
        goodsDiscount: _.get(rowData, 'goodsDiscount', ''),
        isSample: _.get(rowData, 'isSample', ''),
        isProcessed: _.get(rowData, 'isProcessed', ''),
        netPriceEdited: _.get(rowData, 'netPriceEdited', ''),
      };

      arrEditableData.push(rowPayload);
      arrEditableRows.push(rowData.id);
    });
    setEditableRowsData(arrEditableData);
    setEditableRows(arrEditableRows);
    return arrEditableData;
  };

  const modifyRowDataPayload = (data, name, id) => {
    const dataToModify = [...editableRowsData];
    const row = dataToModify.find(item => item.id === id);
    if (name === 'netPriceRow') {
      row.netPriceRow = { value: data };
    } else {
      row[name] = data;
    }
    if (name === 'goodsDiscount' && data === true) {
      row.netPriceRow = { value: 0 };
    }
    const index = dataToModify.findIndex(item => item.id === id);
    dataToModify[index] = row;
    setEditableRowsData(dataToModify);
  };

  const applyMerchDiscount = (checked, data) => {
    if (checked) {
      modifyRowDataPayload(true, 'discountDisabled', data.id);
      modifyRowDataPayload(true, 'discountExtraDisabled', data.id);
      modifyRowDataPayload(true, 'netPriceDisabled', data.id);
      modifyRowDataPayloadValue(100, 'discountValue', data.id);
      modifyRowDataPayloadValue('0', 'discountExtra', data.id);
      modifyRowDataPayload('0', 'netPriceRow', data.id);
    }
    if (!checked) {
      modifyRowDataPayload(false, 'discountDisabled', data.id);
      modifyRowDataPayload(false, 'discountExtraDisabled', data.id);
      modifyRowDataPayload(false, 'netPriceDisabled', data.id);
      modifyRowDataPayloadValue('0', 'discountValue', data.id);
      modifyRowDataPayloadValue('0', 'discountExtra', data.id);

      const netPrice = getNetPrice(data);
      updateNetPrice(netPrice, data.id, 0, 0);
    }

    updateTotal(data);
  };

  const modifyRowDataPayloadValue = (data, name, id) => {
    const dataToModify = [...editableRowsData];
    const row = dataToModify.find(item => item.id === id);
    row[name].value = data;
    const index = dataToModify.findIndex(item => item.id === id);
    dataToModify[index] = row;
    setEditableRowsData(dataToModify);
  };

  const cancelEditRow = rowId => {
    setEditableRows(editableRows.filter(item => item !== rowId));
    setEditableRowsData(editableRowsData.filter(item => item.id !== rowId));
  };

  const discountValueInput = data => {
    const foundId = editableRowsData.find(row => row.id === data.id);
    const discountType = _.get(foundId, 'discountType', null);
    if (discountType.code === 'PERCENTAGE') {
      return (
        <>
          <FormikInput
            {...props}
            disabled={
              editableRowsData.find(row => row.id === data.id)
                .discountDisabled || false
            }
            name="discountValue"
            label=" "
            placeholder={props.intl.formatMessage(messages.discountValue)}
            initialValue={
              editableRowsData.find(row => row.id === data.id).discountValue
                .value
            }
            onChange={e => {
              let basePrice = getNetPrice(data);
              const row = editableRowsData.find(item => item.id === data.id);
              let discountExtra = row.extraDiscount;

              const totalDiscount =
                parseFloat(e.target.value) + parseFloat(discountExtra);
              if (totalDiscount !== 0) basePrice = data.productMasterPrice;

              const value = parseFloat(e.target.value.replace(',', '.'), 10);
              const discount = value || 0;
              discountExtra = parseFloat(discountExtra) || 0;
              if (discountExtra !== '') {
                const discountedBase = (basePrice / 100) * (100 - discount);
                modifyRowDataPayload(
                  parseFloat(
                    (discountedBase / 100) * (100 - discountExtra),
                  ).toFixed(2),
                  'netPriceRow',
                  data.id,
                );
              }

              modifyRowDataPayload(false, 'netPriceEdited', data.id);
              updateTotal(data);

              if (parseFloat(data.buyPrice) !== 0)
                modifyRowDataPayload(
                  (
                    parseFloat(
                      (row.netPriceRow.value - data.buyPrice) / data.buyPrice,
                    ) * 100
                  ).toFixed(2),
                  'markup',
                  data.id,
                );

              if (e.target.value !== '')
                modifyRowDataPayloadValue(
                  e.target.value,
                  'discountValue',
                  data.id,
                );
              else modifyRowDataPayloadValue('0.00', 'discountValue', data.id);
            }}
          />
          <FormikInput
            {...props}
            name="discountExtra"
            disabled={
              editableRowsData.find(row => row.id === data.id)
                .discountExtraDisabled || false
            }
            label=" "
            placeholder={props.intl.formatMessage(messages.extraDiscount)}
            initialValue={
              editableRowsData.find(row => row.id === data.id).discountExtra
                .value
            }
            onChange={e => {
              const row = editableRowsData.find(item => item.id === data.id);
              let basePrice = getNetPrice(data);
              let discount = row.discountValue.value;

              // NOTE: If any discount is applied use productMasterPrice as base
              if (parseFloat(discount) + parseFloat(e.target.value) !== 0)
                basePrice = data.productMasterPrice;

              const value = parseFloat(e.target.value, 10);
              const discountExtra = value || 0;
              discount = parseFloat(discount) || 0;
              if (discountExtra !== '') {
                const discountedBase = (basePrice / 100) * (100 - discount);
                modifyRowDataPayload(
                  parseFloat(
                    (discountedBase / 100) * (100 - discountExtra),
                  ).toFixed(2),
                  'netPriceRow',
                  data.id,
                );
              }

              if (parseFloat(data.buyPrice) !== 0)
                modifyRowDataPayload(
                  (
                    parseFloat(
                      (row.netPriceRow.value - data.buyPrice) / data.buyPrice,
                    ) * 100
                  ).toFixed(2),
                  'markup',
                  data.id,
                );

              if (e.target.value !== '')
                modifyRowDataPayloadValue(
                  e.target.value,
                  'discountExtra',
                  data.id,
                );
              else modifyRowDataPayloadValue('0.00', 'discountExtra', data.id);

              updateTotal(data);
            }}
          />
        </>
      );
    }
    return null;
  };

  const saveRow = id => {
    setOfferRowUpdating({ [id]: true });
    const row = editableRowsData.find(item => item.id === id);
    const payload = {
      ...row,
      product: _.get(row, 'product.value', null),
      discountType: _.get(row, 'discountType.value', null),
      loan: _.get(row, 'loan') === true ? 1 : 0,
      goodsDiscount: _.get(row, 'goodsDiscount') === true ? 1 : 0,
      isProcessed: _.get(row, 'isProcessed') === true ? 1 : 0,
      isSample: _.get(row, 'isSample') === true ? 1 : 0,
      discountValue: parseFloat(
        _.get(row, 'discountValue.value', null) ||
        _.get(row, 'discountValue', null),
      ).toFixed(2),
      extraDiscount: parseFloat(
        _.get(row, 'discountExtra.value', null) ||
        _.get(row, 'discountExtra', null),
      ).toFixed(2),
      markup: _.get(row, 'markup.value', null) || _.get(row, 'markup', null),
      flatValue:
        _.get(row, 'flatValue.value', null) || _.get(row, 'flatValue', null),
      netPrice: row.netPriceRow.value,
      grossPrice:
        _.get(row, 'grossPrice.value', null) || _.get(row, 'grossPrice', null),
    };
    delete payload.amount;
    delete payload.netPriceRow;

    const correctedPayload = switchPayload(
      _.get(row, 'discountType.code'),
      payload,
    );

    if (isNaN(payload.extraDiscount) || payload.extraDiscount === 'NaN')
      payload.extraDiscount = 0;
    if (isNaN(payload.discountValue) || payload.discountValue === 'NaN')
      payload.discountValue = 0;

    OrderRowService.update(correctedPayload)
      .then(response => {
        const dataToModify = [...orderRows];
        const index = dataToModify.findIndex(
          item => item.id === response.data.id,
        );
        dataToModify[index] = response.data;
        setOrderRows(dataToModify);
        cancelEditRow(response.data.id);
        setOfferRowUpdating({ [id]: false });
        addNotification({
          title: null,
          message: 'Success',
          isError: false,
        });
        loadData();
      })
      .catch(err => {
        setOfferRowUpdating(false);
        addNotification({
          title: null,
          message: get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  const saveRows = () =>
    new Promise((resolve, reject) => {
      const rows = [];
      editableRowsData.forEach(row => {
        let rowPayload = {
          ...row,
          product: _.get(row, 'product.value', null),
          discountType: _.get(row, 'discountType.value', null),
          loan: _.get(row, 'loan') === true ? 1 : 0,
          goodsDiscount: _.get(row, 'goodsDiscount') === true ? 1 : 0,
          isSample: _.get(row, 'isSample') === true ? 1 : 0,
          discountValue:
            _.get(row, 'discountValue.value', null) ||
            _.get(row, 'discountValue', null),
          extraDiscount:
            _.get(row, 'discountExtra.value', null) ||
            _.get(row, 'discountExtra', null),
          markup:
            _.get(row, 'markup.value', null) || _.get(row, 'markup', null),
          flatValue:
            _.get(row, 'flatValue.value', null) ||
            _.get(row, 'flatValue', null),
          netPrice:
            _.get(row, 'netPriceRow.value', null),
          netPriceEdited: !!_.get(row, 'netPriceEdited', ''),
        };
        rowPayload = switchPayload(_.get(row, 'discountType.code'), rowPayload);

        rows.push(rowPayload);
      });
      const payLoad = {
        orderRows: rows,
      };

      OrderRowService.updateRows(payLoad)
        .then(() => resolve())
        .catch(err => reject(err));
    });

  const handleOnArticleRowChange = (products, data) => {
    setLoading(true);
    OrderRowService.remove([{ id: data.id }]).then(() => {
      const row = editableRowsData.find(x => x.id === data.id);
      const payload = {
        destination: _.get(values, 'destination.value', null),
        order: values.id,
        products: [products],
        quantity: row.quantity,
        discountValue: row.discountValue.value,
        extraDiscount: row.discountExtra.value,
        loan: row.loan,
        goodsDiscount: row.goodsDiscount,
      };

      OrderRowService.create(payload)
        .then(() => {
          setLoading(false);
          loadData();
          OfferService.findOneById(values.id).then(offer => {
            if (_.get(offer, 'data.state', null)) {
              setFieldValue('state', _.get(offer, 'data.state', ''));
            }
          });
        })
        .catch(err => {
          setInserting(false);
          addNotification({
            title: props.intl.formatMessage(appMessages.error),

            message: _.get(
              err,
              'data.detail',
              props.intl.formatMessage(appMessages.an_error_has_occurred),
            ),
            isError: true,
          });
        });
    });
  };

  // Select table rows functions
  const reconciliation = (Ids, elements) =>
    elements.map(elemento =>
      Object.assign({}, elemento, {
        'entitygrid-selected': Boolean(includes(Ids, elemento.id)),
      }),
    );

  const getElements = () => reconciliation(selectedRows, orderRows);

  const onSelectedRow = row => {
    setSelectedRows([...selectedRows, row.id]);
  };

  const onDeselectedRow = row => {
    setSelectedRows(selectedRows.filter(id => id !== row.id));
  };

  const onSelectAllRows = () => {
    const selectedVisibleIds = orderRows.map(el => el.id);
    setSelectedRows([...new Set([...selectedRows, ...selectedVisibleIds])]);
  };

  const onDeselectAllRows = () => {
    const selectedVisibleIds = orderRows.map(el => el.id);
    const cleanIds = selectedRows.filter(
      id => !includes(selectedVisibleIds, id),
    );
    setSelectedRows(cleanIds);
  };

  const closeModal = () => {
    setFieldValue('articleType', 'KIT');
  };

  const openLastOffer = id => {
    const destinationId = _.get(values, 'destination.value', null);

    OfferService.getFirstByOwnerAndProduct(destinationId, id).then(res => {
      const offers = _.get(res, `data._embedded.offer`, []);

      if (!offers || offers.length === 0) {
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: props.intl.formatMessage(messages.last_offer_not_found),
          isError: true,
        });
      } else {
        setLastOffer(transformData(offers[0]));
      }
    });
  };

  const onSubmit = (event, data) => {
    // Save the rows
    saveRows()
      .then(() => {
        // Push the order submit to the parent
        handleSubmit(event, data);
      })
      .catch(err => {
        addNotification({
          title: null,
          message: get(
            err,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  const updateTotal = data => {
    const { netPriceRow, quantity } = editableRowsData.find(
      row => row.id === data.id,
    );
    modifyRowDataPayload(
      netPriceRow.value * parseFloat(quantity, 10),
      'amount',
      data.id,
    );
  };

  const updateNetPrice = (netPrice, id, discount1, discount2) => {
    if (discount1 + discount2 < 100)
      if (discount1 !== '') {
        const percentage = 100 - (discount1 + discount2);
        modifyRowDataPayload(
          parseFloat((netPrice / 100) * percentage).toFixed(2),
          'netPriceRow',
          id,
        );
      }

    return (netPrice / 100) * 100 - (discount1 + discount2);
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <br />
        <OrderStateSection
          {...props}
          isDisabled={!PermissionManager.CanI('statusUpdate', 'custom')}
          isOrder
        />
        <Accordion title={props.intl.formatMessage(messages.insertArticle)}>
          <Wrapper style={{ margin: '1rem 0' }}>
            <InsertNewPart
              {...props}
              activeType={activeType}
              handleActiveType={handleActiveType}
              isInserting={isInserting}
              closeModal={closeModal}
              insertOrderRow={insertOrderRow}
              setDiscountModalOpened={setDiscountModalOpened}
              handleNewDiscountItem={handleNewDiscountItem}
            />
          </Wrapper>
        </Accordion>
        {(props.values.nonCompliant || props.values.netPriceEdited) && (
          <Wrapper>
            <List>
              <ListHeader
                style={{
                  color: 'red',
                  fontStyle: 'bold',
                }}
              >
                {props.intl.formatMessage(messages.attention)}
              </ListHeader>
              <ListItem style={{ color: 'red' }}>
                {props.values.nonCompliantAverageDiscount &&
                  props.intl.formatMessage(
                    messages.non_compliant_average_discount,
                  )}
              </ListItem>
              <ListItem style={{ color: 'red' }}>
                {props.values.nonCompliantProductDiscount > 0 &&
                  `${props.intl.formatMessage(
                    messages.non_compliant_product_discount,
                    {
                      value: props.values.nonCompliantProductDiscount,
                    },
                  )}`}
              </ListItem>
              <ListItem style={{ color: 'red' }}>
                {props.values.nonCompliantProductMarkup > 0 &&
                  `${props.intl.formatMessage(
                    messages.non_compliant_product_markup,
                    {
                      value: props.values.nonCompliantProductMarkup,
                    },
                  )}`}
              </ListItem>
              <ListItem style={{ color: 'red' }}>
                {props.values.nonCompliantInsolvent &&
                  props.intl.formatMessage(messages.non_compliant_insolvent)}
              </ListItem>
              <ListItem style={{ color: 'orange' }}>
                {props.values.netPriceEdited > 0 &&
                  `${props.intl.formatMessage(
                    messages.net_price_edited,
                    {
                      value: props.values.netPriceEdited,
                    },
                  )}`}
              </ListItem>
            </List>
          </Wrapper>
        )}
        <Wrapper style={{ margin: '1rem 0' }}>
          <Table
            rowClassKey="rowClassKey"
            elements={getElements()}
            isLoading={isLoading}
            columns={columns()}
            canSort={{
              active: true,
            }}
            canSelect={{
              active: true,
              selectAll: true,
              onSelect: row => onSelectedRow(row),
              onDeselect: row => onDeselectedRow(row),
              onSelectAll: () => onSelectAllRows(),
              onDeselectAll: () => onDeselectAllRows(),
              isSelectedProperty: 'entitygrid-selected',
            }}
            canPaginate={{
              active: false,
              pageCount: pagination.pageCount,
              pageSize: pagination.pageSize,
              totalItems: pagination.totalItems,
              page: pagination.page,
              onSelect: pageNumber => {
                setPagination({ ...pagination, page: pageNumber });
                loadData();
              },
              pageMax: pagination.pageCount,
            }}
            canAction={{
              active: true,
              actions: [
                <Grid stackable style={{ marginBottom: 0 }}>
                  <Grid.Row>
                    <Grid.Column width={6}>
                      <div
                        style={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ marginLeft: 10 }}>
                          <FormattedMessage {...messages.total_order} />
                          {currencyFormatter.format(
                            values.totalPriceAmount || 0,
                          )}{' '}
                          - <FormattedMessage {...messages.avarageDiscount} />
                          {values.avarageDiscount
                            ? parseFloat(values.avarageDiscount).toFixed(2)
                            : 0}
                          % - <FormattedMessage {...messages.avarageMarkup} />
                          {values.avarageMarkup
                            ? parseFloat(values.avarageMarkup).toFixed(2)
                            : 0}
                          %
                        </span>
                      </div>
                    </Grid.Column>
                    {!readOnly && (
                      <Grid.Column style={{ textAlign: 'right' }} width={10}>
                        <Button
                          icon
                          color="green"
                          type="button"
                          labelPosition="left"
                          disabled={selectedRows.length === 0}
                          onClick={() => {
                            setDiscountModalOpened(true);
                            setArticle({});
                          }}
                        >
                          <Icon name="plus" />
                          {props.intl.formatMessage(messages.discount_selected)}
                        </Button>
                        <Button
                          icon
                          color="red"
                          type="button"
                          labelPosition="left"
                          disabled={selectedRows.length === 0}
                          onClick={() =>
                            // eslint-disable-next-line no-alert, no-restricted-globals
                            confirm(
                              props.intl.formatMessage(
                                appMessages.are_you_sure,
                              ),
                            )
                              ? removeSelectedArticles()
                              : null
                          }
                        >
                          <Icon name="trash" />
                          {props.intl.formatMessage(messages.remove_selected)}
                        </Button>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </Grid>,
              ],
            }}
            hiddenHeaderIfEmpty
            emptyResults={
              <div>{props.intl.formatMessage(appMessages.no_results)}</div>
            }
          />
        </Wrapper>

        {/* Modal discount */}
        <Modal
          open={discountModalOpened}
          onClose={handleCloseDiscountModal}
          title={isNewDiscountItem ? 'Nuovo' : 'Modifica'}
          size="small"
          scrolling
        >
          <DiscountForm
            entityId={props.values.id}
            entity="order"
            close={handleCloseDiscountModal}
            selectedRows={selectedRows}
            initialValues={article}
            update={() => loadData()}
            updateCompliant={order => handleUpdateCompliant(order)}
            isNewDiscountItem={isNewDiscountItem}
            destination={_.get(values, 'destination.value', null)}
          />
        </Modal>

        {/* Modal last offer */}
        <Modal
          open={lastOfferModalOpened}
          onClose={handleCloseLastOFferModal}
          title="Ultima offerta"
          size="fullscreen"
          scrolling
        >
          <LastOfferForm
            close={handleCloseDiscountModal}
            initialValues={lastOffer}
          />
        </Modal>

        <NoteSection
          {...props}
          isOrder
          isOpen={isNotesOpen}
          setIsNotesOpen={setIsNotesOpen}
        />
        <FormActionsBar
          {...props}
          showEditButton={!props.readOnly}
          editRoute={`/order/${values.id}/modify`}
          moreButtons={[
            <FormDuplicateBtn
              loading={false}
              action={() => setOpenDuplicateModal(true)}
            />,
          ]}
        />
      </Form>
      <StateHistoryModal
        entityId={props.values.id}
        entity="order"
        open={stateModalOpened}
        onClose={() => setStateModalOpened(false)}
      />
      <GoodsDiscountModal
        destinationId={_.get(values, '_embedded.destination.id', null)}
        open={gdModalOpened}
        onClose={() => setGDModalOpened(false)}
      />
      <DiscountConfirmModal
        {...props}
        confirmModalState={confirmModalState}
        promotionPrice={promotionPrice}
        setPromotionPrice={setPromotionPrice}
        setConfirmModalState={setConfirmModalState}
        insertOrderRow={insertOrderRow}
      />
      <Modal
        open={openDuplicateModal}
        onClose={() => {
          setOpenDuplicateModal(false);
          // setFieldValue('articleType', 'Famiglia');
        }}
        title={props.intl.formatMessage(messages.duplication)}
        size="mini"
        style={{ width: 400 }}
      >
        <ModalFakeActions noBorder>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={() => setOpenDuplicateModal(false)}
            >
              <Icon name="remove" />
              {props.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Button
              type="button"
              icon
              labelPosition="left"
              color="green"
              disabled={isDuplicating}
              loading={isDuplicating}
              onClick={handleDuplication}
            >
              <Icon name="checkmark" />
              {props.intl.formatMessage(appMessages.duplicate)}
            </Button>
          </div>
        </ModalFakeActions>
      </Modal>
    </>
  );
};

ManageForm.propTypes = {
  handleSubmit: PropTypes.func,
  data: PropTypes.shape().isRequired,
  intl: intlShape.isRequired,
};

export default ManageForm;
