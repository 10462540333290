import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_RANGE,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_RANGE}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.DESTINATION_RANGE}/${id}`,
    method: 'DELETE',
  });

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_RANGE,
    method: 'GET',
    params: {},
  });

const searchByDescription = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_RANGE,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByDescription(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.destination-range', []).map(range => ({
        value: range.id,
        label: `${range.code} - ${range.description}`,
      })),
    );
  });
};

const DestinationRangeService = {
  create,
  update,
  remove,
  getAll,
  getOptions,
};

export default DestinationRangeService;
