/* eslint-disable no-console */

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import messages from '../messages';
import ProductPackagingDimensionService from '../../../../shared/services/productpackagingdimension';
import ProductPackagingTypeService from '../../../../shared/services/productpackagingtype';

function PackagingTab(props) {
  return (
    <div>
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="numBoxesPerPackage"
              type="number"
              label={props.intl.formatMessage(messages.numBoxPerPackage)}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="numItemsPerBox"
              type="number"
              label={props.intl.formatMessage(messages.numItemsPerBox)}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="numPackagesPerPallet"
              type="number"
              label={props.intl.formatMessage(messages.numPackagePerPallet)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="packagingType"
              label={props.intl.formatMessage(messages.packagingType)}
              loadOptions={(searchText, callback) =>
                ProductPackagingTypeService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="packagingDimension"
              label={props.intl.formatMessage(messages.packagingDimension)}
              loadOptions={(searchText, callback) =>
                ProductPackagingDimensionService.getOptions(
                  searchText,
                  callback,
                )
              }
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="volumeUnit"
              type="number"
              label={props.intl.formatMessage(messages.volumeUnit)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="grossWeight"
              type="number"
              label={props.intl.formatMessage(messages.grossWeight)}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="netWeight"
              type="number"
              label={props.intl.formatMessage(messages.netWeight)}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="ean"
              label={props.intl.formatMessage(messages.ean)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="dtm"
              label={props.intl.formatMessage(messages.dtm)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

PackagingTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(PackagingTab);
