/* eslint-disable indent */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import ProductService from '../../../../../../shared/services/product';
import ProductCategoryService from '../../../../../../shared/services/productcrm';
import FormikAsyncSelect from '../../../../../FormikAsyncSelect';
import FormikInput from '../../../../../FormikInput';
import messages from '../../../messages';
import './styles.css';

export const BUTTON_TYPES = {
  famiglia: 'Famiglia',
  listino: 'Listino',
  ordinati: 'Ordinati',
  offertati: 'Offertati',
};

const InsertNewPart = props => {
  const {
    setFieldValue,
    values,
    insertOrderRow,
    isInserting,
    setDiscountModalOpened,
    activeType,
    handleActiveType,
    handleNewDiscountItem,
    isOffer = false,
    readOnly,
  } = props;

  const isFamiglia = activeType === BUTTON_TYPES.famiglia;

  const isOrderOrOffer =
    activeType === BUTTON_TYPES.offertati ||
    activeType === BUTTON_TYPES.ordinati;

  const isActive = type => {
    if (type === activeType) return true;
    return false;
  };

  const onButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();
    handleActiveType(event.currentTarget.id);
  };

  const handleOpenModal = e => {
    e.preventDefault();
    e.stopPropagation();
    handleActiveType(e.currentTarget.id);
    handleNewDiscountItem(true);
    setDiscountModalOpened(true);
  };

  const handleDisable = () => {
    if (isInserting) return true;
    return !_.has(values, 'product.value');
  };

  return (
    <>
      <div className="insert-part-container">
        <div className="btn-group">
          <div style={{ marginTop: 25, width: '100%' }}>
            <Button.Group>
              <Button
                content={BUTTON_TYPES.famiglia}
                type="button"
                id={BUTTON_TYPES.famiglia}
                primary={isActive(BUTTON_TYPES.famiglia)}
                disabled={readOnly}
                onClick={onButtonClick}
              />
              <Button
                content={BUTTON_TYPES[isOffer ? 'offertati' : 'ordinati']}
                id={BUTTON_TYPES[isOffer ? 'offertati' : 'ordinati']}
                disabled={readOnly}
                primary={isActive(
                  BUTTON_TYPES[isOffer ? 'offertati' : 'ordinati'],
                )}
                onClick={onButtonClick}
              />
              {!isOffer && (
                <Button
                  type="button"
                  content={BUTTON_TYPES.listino}
                  id={BUTTON_TYPES.listino}
                  disabled={readOnly}
                  primary={isActive(BUTTON_TYPES.listino)}
                  onClick={handleOpenModal}
                />
              )}
            </Button.Group>
          </div>
        </div>
        <div className="selects-cont">
          {/* FAMIGLIA SELECTS */}
          {isFamiglia &&
            !isOrderOrOffer && (
              <>
                <FormikAsyncSelect
                  name="productCrm"
                  label={props.intl.formatMessage(messages.productCrm)}
                  loadOptions={ProductCategoryService.getOptions}
                  onChange={data => {
                    setFieldValue('productCrm', data);
                    setFieldValue('product', null);
                  }}
                />
                <FormikAsyncSelect
                  key={`product-${_.get(values, 'productCrm.value', '')}--`}
                  name="product"
                  placeholder="Select..."
                  label={props.intl.formatMessage(messages.product)}
                  required
                  loadOptions={(input, callback) =>
                    ProductService.getOptionsByCrm(
                      input,
                      callback,
                      _.get(values, 'productCrm.value'),
                    )
                  }
                />
              </>
            )}
          {isOrderOrOffer && (
            <FormikAsyncSelect
              key={`product-${_.get(values, 'destination.value', '')}--`}
              name="product"
              label={props.intl.formatMessage(messages.product)}
              readOnly={readOnly}
              required
              placeholder="Select..."
              loadOptions={(input, callback) =>
                ProductService.getAlreadyCreatedOptions(
                  callback,
                  input,
                  isOffer,
                  _.get(values, 'destination.value'),
                )
              }
            />
          )}
          {(isFamiglia || isOrderOrOffer) && (
            <FormikInput
              name="quantity"
              label={props.intl.formatMessage(messages.quantity)}
              onChange={e => {
                setFieldValue('quantity', e.target.value);
              }}
              initialValue={_.get(values, 'quantity', 1)}
            />
          )}
        </div>

        {!readOnly && (
          <div className="submit-btn">
            <div style={{ marginTop: 25 }}>
              <Button
                disabled={handleDisable()}
                loading={isInserting}
                icon
                labelPosition="left"
                type="button"
                floated="right"
                color="green"
                onClick={() => insertOrderRow()}
              >
                <Icon name="add" />
                {props.intl.formatMessage(messages.insert)}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

InsertNewPart.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.shape({}),
  insertOrderRow: PropTypes.func,
  closeModal: PropTypes.func,
  isInserting: PropTypes.bool,
  setDiscountModalOpened: PropTypes.func,
  isOffer: PropTypes.bool,
};

export default InsertNewPart;
