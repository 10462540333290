import { isEmpty } from 'lodash';

function Validation(values) {
  const errors = {};
  if (isEmpty(values.transportedBy)) {
    errors.transportedBy = 'Field required!';
  }
  if (isEmpty(values.referenceDate)) {
    errors.referenceDate = 'Field required!';
  }
  if (
    isEmpty(values.orderType) ||
    typeof values.orderType.value === 'undefined'
  ) {
    errors.orderType = 'Field required!';
  }
  if (isEmpty(values.customer)) {
    errors.customer = 'Field required!';
  }
  if (isEmpty(values.destination)) {
    errors.destination = 'Field required!';
  }
  if (isEmpty(values.state.value)) {
    errors.state = 'Field required!';
  }
  return errors;
}

export default Validation;
