import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Header, Icon, Loader } from 'semantic-ui-react';
import DestinationService from '../../../../../shared/services/destination';
import OfferService from '../../../../../shared/services/offer';
import OrderService from '../../../../../shared/services/order';
import FormikAsyncSelect from '../../../../FormikAsyncSelect';
import FormikSelect from '../../../../FormikSelect';
import { Wrapper } from '../../../../Layout';
import messages from '../../messages';
import StateHistoryModal from './offerStateHistory';

const OfferStateSection = props => {
  const { values, setFieldValue, isOrder = false } = props;

  const [isStateLoading, setStateLoading] = useState(false);
  const [states, setStates] = useState([
    { label: values.state, value: values.state },
  ]);
  const [state, setState] = useState({});
  const [offerStateHistoryModalOpen, setOfferStateHistoryModalOpen] = useState(
    false,
  );

  /**
   * The title of the page (and its loading utility).
   */
  const [title, setTitle] = useState({
    loading: false,
    title: null,
    link: null,
  });

  useEffect(
    () => {
      setState({
        label: values.state,
        value: values.state,
      });
    },
    [values.state],
  );

  /**
   * Auto-set the delivery field from the selected destination.
   */
  useEffect(
    () => {
      const { destination } = values;

      if (destination && destination.value) {
        setTitle({ title: null, loading: true });
        DestinationService.get(destination.value).then(({ data }) => {
          const { code, businessName = '', businessAddressTitle = '' } = data;

          const formattedTitle = `${code} ${businessName} ${businessAddressTitle}`;

          // Set the section title with the customer's information.
          setTitle({
            loading: false,
            title: formattedTitle,
            link: `/destination/${destination.value}/view`,
          });
        });
      }
    },
    [values.destination],
  );

  useEffect(() => {
    setStateLoading(true);
    const method = isOrder
      ? OrderService.getStatesForOrder
      : OfferService.getStatesForOffer;
    method().then(response => {
      const responseStates = response.map(item => ({
        label: item,
        value: item,
      }));

      setStates(responseStates);
      setStateLoading(false);
    });
  }, []);

  function getDestinations() {
    return (searchText, callback) =>
      DestinationService.getOptions(searchText, callback, !isOrder, true);
  }

  return (
    <Wrapper style={{ marginBottom: 30 }}>
      <Grid columns={3} padded>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Loader active={title.loading} inline="centered" size="small" />
            <Header as="h3">
              <Link to={title.link}>{title.title}</Link>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              key="destination"
              required
              name="destination"
              label={props.intl.formatMessage(
                messages[isOrder ? 'customer' : 'customer_prospect'],
              )}
              loadOptions={getDestinations()}
              onChange={data => {
                setFieldValue('destination', data);
              }}
            />
          </Grid.Column>

          <Grid.Column>
            <FormikSelect
              {...props}
              label={props.intl.formatMessage(messages.state)}
              required
              isLoading={isStateLoading}
              isDisabled={props.isDisabled || props.readOnly}
              options={states}
              defaultValue={state}
              value={state}
              placeholder={state.label}
              name="state"
              onChange={target => {
                setState(target);
                setFieldValue('state', target.value);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              icon
              labelPosition="left"
              primary
              type="button"
              style={{ marginTop: '23px' }}
              onClick={() => setOfferStateHistoryModalOpen(true)}
            >
              <Icon name="list" />
              {props.intl.formatMessage(messages.history)}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <StateHistoryModal
        open={offerStateHistoryModalOpen}
        isOrder={isOrder}
        onClose={() =>
          setOfferStateHistoryModalOpen(!offerStateHistoryModalOpen)
        }
        {...props}
      />
    </Wrapper>
  );
};

export default OfferStateSection;
