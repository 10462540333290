/**
 *
 * Tickets
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import makeSelectTickets from './selectors';
import { makeSelectStyles } from '../App/selectors';
import { Wrapper } from '../../components/Layout';
import reducer from './reducer';
import saga from './saga';
import TicketList from '../../components/TicketList';
import messages from './messages';
import JiraService from '../../shared/services/tickets';

export class Tickets extends React.Component { // eslint-disable-line

  render() {
    return (
      <>
        <Wrapper style={{ margin: '1rem 0' }}>
          <BreadcrumbsItem to="/tickets">
            {this.context.intl.formatMessage(messages.title)}
          </BreadcrumbsItem>
          <TicketList
            service={JiraService}
            styles={this.props.styles}
            // url="https://pm.novigo-consulting.it/rest/api/2"
            token="helpdesk.novigo:HelpdeskNovigo2019!!"
            currentUser="helpdesk.novigo"
            user={this.props.user}
            projectId="HD"
            ticketType="10100"
            offset="0"
            limit="5"
          />
        </Wrapper>
      </>
    );
  }
}

Tickets.propTypes = {
  user: PropTypes.object,
  styles: PropTypes.object,
};

Tickets.contextTypes = {
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  tickets: makeSelectTickets(),
  styles: makeSelectStyles(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'tickets', reducer });
const withSaga = injectSaga({ key: 'tickets', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Tickets);
