/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */

import _ from 'lodash';
import SimpleFormikField from 'novigo-simple-formik-field';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Select from 'react-select';
import { Button, Grid, Icon, Image, Placeholder } from 'semantic-ui-react';
import appMessages from '../../../../containers/App/messages';
import AttachmentService from '../../../../shared/services/attachments';
import ProductService from '../../../../shared/services/product';
import ProductAttachmentService from '../../../../shared/services/productattachment';
import ProductCategoryService from '../../../../shared/services/productcategory';
import ProductCrmService from '../../../../shared/services/productcrm';
import ProductDomainService from '../../../../shared/services/productdomain';
import ProductKitService from '../../../../shared/services/productkit';
import ProductLoanService from '../../../../shared/services/productloan';
import ProductUnitMeasureService from '../../../../shared/services/productunitmeasure';
import ProductSupplierService from '../../../../shared/services/productsupplier';
import { addNotification } from '../../../../utils/notification';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import FormikSelect from '../../../FormikSelect';
import FormikTextArea from '../../../FormikTextArea';
import FormikToggle from '../../../FormikToggle';
import messages from '../messages';
import imagePlaceholder from '../../../../images/image-placeholder.png';

function GeneralTab(props) {
  const { isNew, values } = props;
  const [categories, setCategories] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [subcategories, setSubcategories] = useState([]);
  const [image, setImage] = useState(null);
  const [techDetails, setTechDetails] = useState(null);
  const [securityCard, setSecurityCard] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);

  useEffect(
    () => {
      ProductCategoryService.getCategoryOptions().then(res => {
        setCategories(res);
        setCategoriesLoading(false);
      });

      if (values.id) {
        ProductAttachmentService.getAttachmentsForId(values.id)
          .then(res => {
            const embedded = _.get(
              res,
              'data._embedded.product-attachment',
              [],
            );

            const data = embedded.map(el => {
              const item = _.get(el, '_embedded', {});
              return {
                type: item.attachmentTypeId.description,
                id: item.attachmentId.id,
              };
            });
            // check if Scheda Tecnica details exists and apply it to state if it does
            const technical = data.find(el => el.type === 'Scheda Tecnica');
            if (technical) {
              setTechDetails(technical.id);
            }
            const security = data.find(el => el.type === 'Scheda Sicurezza');
            if (security) {
              setSecurityCard(security.id);
            }

            // check if Immagine App  exists and download and apply it to state if it does
            const imgData = data.find(el => el.type === 'Immagine App');
            if (imgData) {
              setLoadingImage(true);
              AttachmentService.downloadMultiple([imgData.id])
                .then(att => {
                  const imageData = _.get(att, 'data[0].image', null);
                  if (imageData) {
                    const img = `data:image/jpeg;base64,${att.data[0].image}`;
                    setImage(img);
                  }
                  setLoadingImage(false);
                })
                .catch(() => {
                  setImage(imagePlaceholder);
                  setLoadingImage(false);
                  // addNotification({
                  //   title: props.intl.formatMessage(appMessages.error),
                  //   message: _.get(
                  //     err,
                  //     'detail',
                  //     props.intl.formatMessage(
                  //       appMessages.an_error_has_occurred,
                  //     ),
                  //   ),
                  //   isError: true,
                  // });
                });
            } else {
              setImage(imagePlaceholder);
              setLoadingImage(false);
            }
          })
          .catch(err =>
            addNotification({
              title: props.intl.formatMessage(appMessages.error),
              message: _.get(
                err,
                'detail',
                props.intl.formatMessage(appMessages.an_error_has_occurred),
              ),
              isError: true,
            }),
          );
      }
    },
    [values.id],
  );

  useEffect(
    () => {
      setSubcategories(
        _.get(props, 'values.category.subcategories', []).map(
          ({ id, description }) => ({
            value: id,
            label: description,
          }),
        ),
      );
    },
    [_.get(props, 'values.category.subcategories[0]', null)],
  );

  const downloadAttachment = id => {
    setDownloading(true);
    AttachmentService.download(id)
      .then(res => {
        const contentDisposition = res.headers['content-disposition'];
        const filename = contentDisposition.match(`filename="(.*)"`)[1];
        const newBlob = new Blob([res.data], { type: res.data.type });
        const fileUrl = window.URL.createObjectURL(newBlob);
        const tempLink = document.createElement('a');
        tempLink.href = fileUrl;
        tempLink.setAttribute('download', filename);
        tempLink.click();
        document.removeChild(tempLink);
        setDownloading(false);
      })
      .catch(err => {
        setDownloading(false);

        addNotification({
          title: props.formatMessage(appMessages.error),
          message: _.get(
            err,
            'detail',
            props.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  return (
    <div>
      <Grid>
        {!isNew && (
          <Grid.Row>
            <Grid.Column width={8}>
              <Grid.Row columns={1}>
                <Grid.Column width={2} style={{ marginTop: 15 }}>
                  {loadingImage ? (
                    <Placeholder style={{ width: 270, height: 270 }}>
                      <Placeholder.Image square />
                    </Placeholder>
                  ) : (
                    <Image
                      src={image || imagePlaceholder}
                      style={{ width: 270, height: 270, objectFit: 'contain' }}
                      alt=""
                    />
                  )}
                  {/*
                  </div> */}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{ marginTop: 25 }}>
                  <Button
                    color="blue"
                    icon
                    style={{ width: 200 }}
                    onClick={() => downloadAttachment(techDetails)}
                    type="button"
                    disabled={_.isEmpty(techDetails)}
                    loading={downloading}
                  >
                    <Icon name="file pdf outline" />
                    {props.intl.formatMessage(messages.techDetails)}
                  </Button>
                  <Button
                    color="blue"
                    icon
                    style={{ width: 200 }}
                    onClick={() => downloadAttachment(securityCard)}
                    type="button"
                    disabled={_.isEmpty(securityCard)}
                    loading={downloading}
                  >
                    <Icon name="file pdf outline" />
                    {props.intl.formatMessage(messages.securityCard)}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={3}>
          {!isNew && (
            <Grid.Column>
              <SimpleFormikField
                {...props}
                readOnly
                name="productCode"
                label={props.intl.formatMessage(messages.productCode)}
                required
              />
            </Grid.Column>
          )}
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="title"
              label={props.intl.formatMessage(messages.description)}
              required
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="productSupplier"
              label={props.intl.formatMessage(messages.supplier)}
              loadOptions={(searchText, callback) =>
                ProductSupplierService.getOptions(searchText, callback)
              }
              readOnly
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FormikTextArea
              {...props}
              name="description"
              label={props.intl.formatMessage(messages.extended_description)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FormikTextArea
              {...props}
              name="noteOffer"
              label={props.intl.formatMessage(messages.note_offer)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="crmCategory"
              label={props.intl.formatMessage(messages.crm_category)}
              loadOptions={(searchText, callback) =>
                ProductCrmService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="loanCategory"
              label={props.intl.formatMessage(messages.loan_category)}
              loadOptions={(searchText, callback) =>
                ProductLoanService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="packagingProduct"
              label={props.intl.formatMessage(messages.packaging_product)}
              loadOptions={(searchText, callback) =>
                ProductService.getOptionsFilteredNeqId(
                  searchText,
                  props.values.id,
                  callback,
                )
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="kit"
              label={props.intl.formatMessage(messages.kit)}
              loadOptions={(searchText, callback) =>
                ProductKitService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="replacementProducts"
              className="basic-multi-select"
              classNamePrefix="select"
              asyncSelectProps={{
                isMulti: true,
              }}
              label={props.intl.formatMessage(messages.replacementProducts)}
              loadOptions={(searchText, callback) =>
                ProductService.getOptionsFilteredNeqId(
                  searchText,
                  props.values.id,
                  callback,
                )
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="relatedProducts"
              className="basic-multi-select"
              classNamePrefix="select"
              asyncSelectProps={{
                isMulti: true,
              }}
              label={props.intl.formatMessage(messages.relatedProducts)}
              loadOptions={(searchText, callback) =>
                ProductService.getOptionsFilteredNeqId(
                  searchText,
                  props.values.id,
                  callback,
                )
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="groupProduct"
              label={props.intl.formatMessage(messages.groupProduct)}
              loadOptions={(searchText, callback) =>
                ProductService.getOptionsFilteredNeqId(
                  searchText,
                  props.values.id,
                  callback,
                )
              }
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              name="subCategory.category"
              label={props.intl.formatMessage(messages.category)}
              {...props}
            >
              {({ form: { setFieldValue } }) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={values.category}
                  name="category"
                  options={categories}
                  onChange={value => {
                    setFieldValue('category', value);
                    setFieldValue('subcategory', null);
                    setSubcategories(
                      _.get(value, 'subcategories').map(
                        ({ id, description }) => ({
                          value: id,
                          label: description,
                        }),
                      ),
                    );
                  }}
                />
              )}
            </SimpleFormikField>
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              name="subcategory"
              label={props.intl.formatMessage(messages.subcategory)}
              {...props}
            >
              {({ form: { setFieldValue } }) => (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={values.subcategory}
                  name="subcategory"
                  options={subcategories}
                  isDisabled={!subcategories.length}
                  onChange={value => {
                    setFieldValue('subcategory', value);
                  }}
                />
              )}
            </SimpleFormikField>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="productDomains"
              label={props.intl.formatMessage(messages.productDomain)}
              loadOptions={(searchText, callback) =>
                ProductDomainService.getOptions(searchText, callback)
              }
              asyncSelectProps={{
                isMulti: true,
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...props}
              name="unitMeasure"
              label={props.intl.formatMessage(messages.unitMeasure)}
              loadOptions={(searchText, callback) =>
                ProductUnitMeasureService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikToggle
              {...props}
              name="sellAgent"
              label={props.intl.formatMessage(messages.sellAgent)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikToggle
              {...props}
              name="sellOnline"
              label={props.intl.formatMessage(messages.sellOnline)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikToggle
              {...props}
              name="sellLoan"
              label={props.intl.formatMessage(messages.sellLoan)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikToggle
              {...props}
              name="productBlocked"
              label={props.intl.formatMessage(messages.productBlocked)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikToggle
              {...props}
              name="isActive"
              label={props.intl.formatMessage(messages.isActive)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikToggle
              {...props}
              name="includedInBonus"
              label={props.intl.formatMessage(messages.includedInBonus)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <FormikSelect
              {...props}
              isDisabled
              name="createdBy"
              label={props.intl.formatMessage(messages.createdBy)}
              value={props.values.createdBy}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikToggle
              {...props}
              name="isStock"
              label={props.intl.formatMessage(messages.isStock)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              name="productStock.availableQuantity"
              label={props.intl.formatMessage(messages.quantityAvailable)}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              readOnly
              name="productStock.stockQuantity"
              label={props.intl.formatMessage(messages.quantityInStock)}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...props}
              readOnly
              name="productStock.reservedQuantity"
              label={props.intl.formatMessage(messages.quantityReserved)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FormikTextArea
              {...props}
              name="note"
              label={props.intl.formatMessage(messages.note)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FormikTextArea
              {...props}
              name="information"
              readOnly
              label={props.intl.formatMessage(messages.information)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

GeneralTab.propTypes = {
  intl: intlShape.isRequired,
  isNew: PropTypes.bool.isRequired,
};

export default injectIntl(GeneralTab);
