import axios from 'axios';
import _ from 'lodash';
import { API } from '../../../global-constants';
import { store } from '../../../configureStore';
import { logout } from '../../../containers/App/actions';
import {
  stopLoading,
  forcePasswordReset,
} from '../../../containers/Login/actions';
import { addNotification } from '../../../utils/notification';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

/**
 * Update tokens in localStorage
 * @param {object} data - Auth object
 * @param {string} data.access_token - The user access token
 * @param {string} data.refresh_token - The user refresh token
 * @param {string} data.scope - The token scope
 * @param {number} data.expires_in - Seconds to token expiration
 * @param {string} data.token_type- The type of the token
 */
const updateToken = data => {
  localStorage.setItem('access_token', data.access_token);
  localStorage.setItem('refresh_token', data.refresh_token);
  localStorage.setItem('scope', data.scope);
  localStorage.setItem('expires_in', data.expires_in);
  localStorage.setItem('token_type', data.token_type);
};

/**
 * Determines headers for authenticated requests
 * @param {string} grant_type - The grant_type of the request
 */
const getAuth = grantType => {
  const accessToken = localStorage.getItem('access_token');
  if (grantType === 'password') {
    return API.FIRST_AUTH_TOKEN;
  }
  if (grantType === 'refresh_token') {
    return API.FIRST_AUTH_TOKEN;
  }
  return `Bearer ${accessToken}`;
};

/**
 * Refresh the access token with refresh token
 */
const refreshAccessToken = () =>
  client({
    baseURL: API.BASE_URL,
    url: API.OAUTH_GRANT_PASSWORD,
    method: 'POST',
    data: {
      grant_type: 'refresh_token',
      refresh_token: localStorage.getItem('refresh_token'),
    },
  });

/**
 * Create an Axios Client with defaults
 */
const client = axios.create();

/*
* Request interceptor
* Useful for refreshing token before to make a new request and get 401 responses
* TODO: implement preventive refreshing
*/
client.interceptors.request.use(
  config => {
    const grantType = _.get(config, 'data.grant_type');
    const originalRequest = _.cloneDeep(config);
    // Using _.set() we avoid undefined keys in path
    _.set(originalRequest, 'headers.Authorization', getAuth(grantType));
    return originalRequest;
  },
  err => Promise.reject(err),
);

/*
* Response interceptor
* Useful for refreshing token upon 401 response and logout upon 400 response
*/
client.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;

    // if (new URL(originalRequest.url).pathname === '/oauth') {
    /** Bad request (400) of type invalid_grant */
    if (
      error.response.status === 400 &&
      (error.response.statusText === 'invalid_grant' ||
        error.response.data.title === 'invalid_grant')
    ) {
      addNotification({
        title: null,
        message: error.response.data.detail,
        isError: true,
      });
      store.dispatch(stopLoading());
      store.dispatch(logout());
    }
    /** Bad request (400) of type invalid_request (no password) */
    if (
      error.response.status === 400 &&
      (error.response.statusText === 'invalid_request' ||
        error.response.data.title === 'invalid_request')
    ) {
      store.dispatch(stopLoading());
      addNotification({
        title: null,
        message: error.response.data.detail,
        isError: true,
      });
      return Promise.reject(error.response);
    }
    // TODO: verify if there is a better way to manage expired password data other than statusText or if it's legit
    /** Password expired */
    if (
      error.response.statusText === 'password_expired' ||
      error.response.data.title === 'password_expired' ||
      error.response.data.detail === 'Password scaduta'
    ) {
      store.dispatch(stopLoading());
      store.dispatch(forcePasswordReset());
      addNotification({
        title: null,
        message: _.get(error, 'response.data.detail'),
        isError: true,
      });
      return Promise.reject(error.response);
    }
    /** Authorization required (401) of type invalid_grant (wrong username or password) */
    if (
      error.response.status === 401 &&
      (error.response.statusText === 'invalid_grant' ||
        error.response.data.title === 'invalid_grant')
    ) {
      store.dispatch(stopLoading());
      addNotification({
        title: null,
        message: _.get(error, 'response.data.detail'),
        isError: true,
      });
      // return Promise.reject(error.response);
    }
    // }

    // If 401 and I'm non processing a queue
    // eslint-disable-next-line no-underscore-dangle
    if (error.response.status === 401 && !originalRequest._retry) {
      // eslint-disable-line
      if (isRefreshing) {
        // If I'm refreshing the token I send request to a queue
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            originalRequest.headers.Authorization = getAuth();
            return client(originalRequest);
          })
          .catch(err => err);
      }
      // If header of the request has changed, it means I've refreshed the token
      if (originalRequest.headers.Authorization !== getAuth()) {
        originalRequest.headers.Authorization = getAuth();
        return Promise.resolve(client(originalRequest));
      }
      originalRequest._retry = true; // eslint-disable-line
      isRefreshing = true;

      // If none of the above, refresh the token and process the queue
      return new Promise((resolve, reject) => {
        refreshAccessToken()
          .then(({ data }) => {
            updateToken(data);
            processQueue(null, data.token);
            resolve(client(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error.response);
  },
);

export default client;
