import { get } from 'lodash';
import appMessages from '../App/messages';
import { addNotification } from '../../utils/notification';
import messages from './messages';
import OrderService from '../../shared/services/order';
import { ORDER_STATE_INSERTED } from './constants';

/**
 * Creates a new order and navigates to order detail page.
 * @param {*} history
 * @param {*} prodottiTypology
 * @param {*} setLoading
 */
export const createOrder = (history, prodottiTypology, intl, setLoading) => {
  setLoading(true);
  OrderService.create({
    orderType: prodottiTypology,
    state: ORDER_STATE_INSERTED,
    maxDiscount: 0,
  })
    .then(res => {
      setLoading(false);
      history.push(`order/${res.data.id}/new`);
    })
    .catch(err => {
      setLoading(false);
      addNotification({
        title: intl.formatMessage(appMessages.error),
        message: get(
          err,
          'detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        ),
        isError: true,
      });
    });
};

/**
 * Navigates to the order edit page.
 * @param {*} history
 * @param {*} id
 */
export const modifyOrder = (history, id) => {
  history.push(`/order/${id}/modify`);
};

/**
 * Navigates to the order delete page.
 * @param {*} history
 * @param {*} id
 */
export const deleteOrder = (history, id) => {
  history.push(`/order/${id}/delete`);
};

/**
 * Navigates to the order view page.
 * @param {*} history
 * @param {*} id
 */
export const viewOrder = (history, id) => {
  history.push(`/order/${id}/view`);
};

/**
 * Updates an offer state.
 * @param {*} idOrder
 * @param {*} state
 * @param {*} intl
 * @param {*} reloadData
 * @param {*} setLoading
 */
export const setOrderState = (idOrder, state, intl, reloadData, setLoading) => {
  if (setLoading) setLoading(true);
  const payload = {
    id: idOrder,
    state,
  };
  OrderService.patch(payload)
    .then(() => {
      if (setLoading) setLoading(false);
      if (reloadData) reloadData();

      addNotification({
        title: intl.formatMessage(appMessages.success),
        message: intl.formatMessage(messages.state_update_success),
        isError: false,
      });
    })
    // eslint-disable-next-line no-unused-vars
    .catch(error => {
      if (setLoading) setLoading(false);
      addNotification({
        title: intl.formatMessage(appMessages.error),
        message: get(
          error,
          'data.detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        ),
        isError: true,
      });
    });
};

/**
 * Updates an order with the given payload.
 * @param {*} payload
 * @param {*} intl
 * @param {*} setLoading
 */
export const updateOrder = (payload, intl, setLoading) => {
  setLoading(true);

  OrderService.patch(payload)
    .then(() => {
      setLoading(false);
      addNotification({
        title: intl.formatMessage(appMessages.success),
        message: intl.formatMessage(messages.order_update_success),
        isError: false,
      });
    })
    .catch(error => {
      setLoading(false);
      addNotification({
        title: intl.formatMessage(appMessages.error),
        message: get(
          error,
          'data.detail',
          intl.formatMessage(appMessages.an_error_has_occurred),
        ),
        isError: true,
      });
    });
};

/**
 * TODO
 */
export const duplicateOrder = () => { };
