import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getStaffRoles = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF_ROLE,
    method: 'GET',
    params: {},
  });

const getStaffDepartment = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF_DEPARTMENT,
    method: 'GET',
    params: {},
  });

const getStaffRolesOptions = () =>
  getStaffRoles().then(res =>
    _.get(res, 'data._embedded.staff_role', []).map(role => ({
      label: role.name,
      value: role.id,
    })),
  );

const getStaffDepartmentOptions = () =>
  getStaffDepartment().then(res =>
    _.get(res, 'data._embedded.staff_department', []).map(department => ({
      label: department.name,
      value: department.id,
    })),
  );

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'displayName',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.staff', []).map(agent => ({
        value: agent.id,
        label: agent.displayName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchCommercialDirectorByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          where: 'and',
          field: 'displayName',
          value: `%${searchText}%`,
        },
        {
          type: 'eq',
          alias: 'role',
          field: 'name',
          value: 'Direttore commerciale',
        },
        {
          type: 'leftjoin',
          field: 'staffRoleId',
          alias: 'role',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getCommercialDirectorOptions = (inputValue, callback) => {
  searchCommercialDirectorByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.staff', []).map(agent => ({
        value: agent.id,
        label: agent.displayName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const searchTechnicianByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.STAFF,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          where: 'and',
          field: 'displayName',
          value: `%${searchText}%`,
        },
        {
          type: 'in',
          alias: 'role',
          field: 'name',
          values: ['Tecnico', 'Tecnico-Responsabile', 'Responsabile tecnico'],
        },
        {
          type: 'leftjoin',
          field: 'staffRoleId',
          alias: 'role',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getTechnicianOptions = (inputValue, callback) => {
  searchTechnicianByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.staff', []).map(agent => ({
        value: agent.id,
        label: agent.displayName,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const ContactService = {
  getStaffRolesOptions,
  getStaffDepartmentOptions,
  getCommercialDirectorOptions,
  getTechnicianOptions,
  getOptions,
};

export default ContactService;
