import _ from 'lodash';
import request from '../request';
import { API } from '../../../global-constants';

const getStates = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.STATE_MACHINE,
    method: 'POST',
    data: {
      method: 'get-config',
      params: {
        module: 'staff_workorder',
      },
    },
  });

const getAvailableStates = currentState =>
  getStates().then(res => {
    const transitions = _.get(res, 'data.staff_workorder-state.transitions');
    const availableTransitions = [];
    if (currentState) {
      Object.keys(transitions).forEach(key => {
        if (transitions[key].from.includes(currentState)) {
          availableTransitions.push(key);
        }
      });
    } else {
      Object.keys(transitions).forEach(key => {
        availableTransitions.push(key);
      });
    }
    return availableTransitions;
  });

const StaffWorkOrderStateService = {
  getAvailableStates,
};

export default StaffWorkOrderStateService;
