import moment from 'moment';

export const handleFilters = values => {
  const filters = {};

  if (values.referenceDateStart) {
    filters.referenceDateStart = moment(values.referenceDateStart).isValid()
      ? moment(values.referenceDateStart).format('YYYY-MM-DD')
      : null;
  }
  if (values.referenceDateEnd) {
    filters.referenceDateEnd = moment(values.referenceDateEnd).isValid()
      ? moment(values.referenceDateEnd).format('YYYY-MM-DD')
      : null;
  }

  if (values.invoiceReferenceDateStart) {
    filters.invoiceReferenceDateStart = moment(
      values.invoiceReferenceDateStart,
    ).isValid()
      ? moment(values.invoiceReferenceDateStart).format('YYYY-MM-DD')
      : null;
  }

  if (values.invoiceReferenceDateEnd) {
    filters.invoiceReferenceDateEnd = moment(
      values.invoiceReferenceDateEnd,
    ).isValid()
      ? moment(values.invoiceReferenceDateEnd).format('YYYY-MM-DD')
      : null;
  }

  if (values.agent) {
    filters.agent = values.agent.value || null;
  }

  if (values.customer) {
    filters.customer = values.customer.value || null;
  }

  if (values.destination) {
    filters.destination = values.destination.value || null;
  }

  return filters;
};
