/*
 * Commission Messages
 *
 * This contains all the text for the Commission container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.CommissionCreate';

export default defineMessages({
  referenceDate: {
    id: `${scope}.referenceDate`,
    defaultMessage: 'Reference date',
  },
  invoiceDateFrom: {
    id: `${scope}.invoiceDateFrom`,
    defaultMessage: 'Invoice date from',
  },
  invoiceDateTo: {
    id: `${scope}.invoiceDateTo`,
    defaultMessage: 'Invoice date to',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  headquarter: {
    id: `${scope}.headquarter`,
    defaultMessage: 'Headquarter',
  },
  agents: {
    id: `${scope}.agents`,
    defaultMessage: 'Agents',
  },
  selectHeadquarter: {
    id: `${scope}.selectHeadquarter`,
    defaultMessage: 'Select headquarter',
  },
  filterAll: {
    id: `${scope}.filterAll`,
    defaultMessage: 'All',
  },
  filterInclude: {
    id: `${scope}.filterInclude`,
    defaultMessage: 'Include',
  },
  filterExclude: {
    id: `${scope}.filterExclude`,
    defaultMessage: 'Exclude',
  },
  generate: {
    id: `${scope}.generate`,
    defaultMessage: 'Generate',
  },
  invoiceDate: {
    id: `${scope}.invoiceDate`,
    defaultMessage: 'Invoice date',
  },
  totalAmount: {
    id: `${scope}.totalAmount`,
    defaultMessage: 'Total amount',
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'Number',
  },
  selectedInvoices: {
    id: `${scope}.selectedInvoices`,
    defaultMessage: 'Selected invoices',
  },
  totalsOfSelected: {
    id: `${scope}.totalsOfSelected`,
    defaultMessage: '{total} Invoices / {value} €',
  },
});
