/*
 * Customer Messages
 *
 * This contains all the text for the Customer container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.Customer';

export default defineMessages({
  // Persona fisica
  natural_person: {
    id: `${scope}.natural_person`,
    defaultMessage: 'Natural person',
  },
  legal_person: {
    id: `${scope}.legal_person`,
    defaultMessage: 'Legal person',
  },
  personal_data: {
    id: `${scope}.personal_data`,
    defaultMessage: 'Personal data',
  },
  contacts: {
    id: `${scope}.contacts`,
    defaultMessage: 'Contacts',
  },
  not_done: {
    id: `${scope}.not_done`,
    defaultMessage: 'Not done',
  },
  documents: {
    id: `${scope}.documents`,
    defaultMessage: 'Documents',
  },
  notes: {
    id: `${scope}.notes`,
    defaultMessage: 'Notes',
  },
  company_data: {
    id: `${scope}.company_data`,
    defaultMessage: 'Company data',
  },
  phone_number: {
    id: `${scope}.phone_number`,
    defaultMessage: 'Phone number',
  },
  web_site: {
    id: `${scope}.web_site`,
    defaultMessage: 'Web site',
  },
  communications: {
    id: `${scope}.communications`,
    defaultMessage: 'Communications',
  },
  payment: {
    id: `${scope}.payment`,
    defaultMessage: 'Payment',
  },
  payment_codition: {
    id: `${scope}.payment_condition`,
    defaultMessage: 'Payment terms',
  },
  payment_charge: {
    id: `${scope}.payment_charge`,
    defaultMessage: 'Payment fees charge',
  },
  tax_rate: {
    id: `${scope}.tax_rate`,
    defaultMessage: 'Tax rate',
  },
  credit_limit: {
    id: `${scope}.credit_limit`,
    defaultMessage: 'Credit limit',
  },
  customer_bank: {
    id: `${scope}.customer_bank`,
    defaultMessage: 'Customer bank',
  },
  company_name: {
    id: `${scope}.company_name`,
    defaultMessage: 'Company name',
  },
  vat_number: {
    id: `${scope}.vat_number`,
    defaultMessage: 'Vat number',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  surname: {
    id: `${scope}.surname`,
    defaultMessage: 'Surname',
  },
  city_of_birth: {
    id: `${scope}.city_of_birth`,
    defaultMessage: 'City of birth',
  },
  date_of_birth: {
    id: `${scope}.date_of_birth`,
    defaultMessage: 'Date of birth',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'Gender',
  },
  citizenship: {
    id: `${scope}.citizenship`,
    defaultMessage: 'Citizenship',
  },
  fiscal_domicile: {
    id: `${scope}.fiscal_domicile`,
    defaultMessage: 'Fisacal domicile',
  },
  fiscal_code: {
    id: `${scope}.fiscal_code`,
    defaultMessage: 'Fiscal code',
  },
  cf_error: {
    id: `${scope}.cf_error`,
    defaultMessage:
      'Verify that you have entered: first name, last name, date of birth, city of birth and gender.',
  },
  resident: {
    id: `${scope}.resident`,
    defaultMessage: 'Resident',
  },
  not_resident: {
    id: `${scope}.not_resident`,
    defaultMessage: 'Non resident',
  },
  contact_type: {
    id: `${scope}.contact_type`,
    defaultMessage: 'Contact type',
  },
  nominative: {
    id: `${scope}.nominative`,
    defaultMessage: 'Nominative',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Phone',
  },
  mobile: {
    id: `${scope}.mobile`,
    defaultMessage: 'Mobile',
  },
  contact_removed_success: {
    id: `${scope}.contact_removed_success`,
    defaultMessage: 'Contact successfully removed.',
  },
  note_removed_success: {
    id: `${scope}.note_removed_success`,
    defaultMessage: 'Note successfully removed.',
  },
  new_contact: {
    id: `${scope}.new_contact`,
    defaultMessage: 'New contact',
  },
  document_type: {
    id: `${scope}.document_type`,
    defaultMessage: 'Document type',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  release_date: {
    id: `${scope}.release_date`,
    defaultMessage: 'Release date',
  },
  expire_date: {
    id: `${scope}.expire_date`,
    defaultMessage: 'Expire date',
  },
  createdAt: {
    id: `${scope}.createdAt`,
    defaultMessage: 'Created at',
  },
  total: {
    id: `${scope}.total`,
    defaultMessage: 'Total',
  },
  new_document: {
    id: `${scope}.new_document`,
    defaultMessage: 'New document',
  },
  upload: {
    id: `${scope}.upload`,
    defaultMessage: 'Upload',
  },
  document_description_placeholder: {
    id: `${scope}.document_description_placeholder`,
    defaultMessage: 'Document description....',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  new_note: {
    id: `${scope}.new_note`,
    defaultMessage: 'New note',
  },
  note_placeholder: {
    id: `${scope}.note_placeholder`,
    defaultMessage: 'Type your note....',
  },
  note_type: {
    id: `${scope}.note_type`,
    defaultMessage: 'Note Type',
  },
  note_motivation: {
    id: `${scope}.note_motivation`,
    defaultMessage: 'Motivation',
  },
  talked_to: {
    id: `${scope}.talked_to`,
    defaultMessage: 'Talked to',
  },
  registration_number: {
    id: `${scope}.registration_number`,
    defaultMessage: 'Registration number',
  },
  department: {
    id: `${scope}.department`,
    defaultMessage: 'Department',
  },
  role: {
    id: `${scope}.role`,
    defaultMessage: 'Role',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: 'User',
  },
  parent: {
    id: `${scope}.parent`,
    defaultMessage: 'Parent',
  },
  customer_code: {
    id: `${scope}.customer_code`,
    defaultMessage: 'Customer code',
  },
  commissions: {
    id: `${scope}.commissions`,
    defaultMessage: 'Commissions',
  },
  new_commission: {
    id: `${scope}.new_commission`,
    defaultMessage: 'New commission',
  },
  tab_general_data: {
    id: `${scope}.tab_general_data`,
    defaultMessage: 'General data',
  },
  tab_destinations: {
    id: `${scope}.tab_destination`,
    defaultMessage: 'Destinations',
  },
  tab_customers: {
    id: `${scope}.tab_customers`,
    defaultMessage: 'Customers',
  },
  customerCode: {
    id: `${scope}.customerCode`,
    defaultMessage: 'Cust. Code',
  },
  destinationState: {
    id: `${scope}.destinationState`,
    defaultMessage: 'Dest. State',
  },
  destinationCode: {
    id: `${scope}.destinationCode`,
    defaultMessage: 'Dest. Code',
  },
  destinationArea: {
    id: `${scope}.destinationArea`,
    defaultMessage: 'Dest. Area',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Shop Sign',
  },
  shopSign: {
    id: `${scope}.shopSign`,
    defaultMessage: 'Shop Sign',
  },
  destinationLogisticArea: {
    id: `${scope}.destinationLogisticArea`,
    defaultMessage: 'Customer Dest. Logistic Area',
  },
  birthCity: {
    id: `${scope}.birthCity`,
    defaultMessage: 'City of birth',
  },
  new_destination: {
    id: `${scope}.new_destination`,
    defaultMessage: 'New destination',
  },
  new_customer: {
    id: `${scope}.new_customer`,
    defaultMessage: 'New Customer',
  },
  to_customer: {
    id: `${scope}.to_customer`,
    defaultMessage: 'Transform to client',
  },
  send_mail_to: {
    id: `${scope}.send_mail_to`,
    defaultMessage: 'Send mail to',
  },
  percentage: {
    id: `${scope}.percentage`,
    defaultMessage: 'Percentage',
  },
  value_discount: {
    id: `${scope}.value_discount`,
    defaultMessage: 'Value discount',
  },
  net_price: {
    id: `${scope}.net_price`,
    defaultMessage: 'Net price',
  },
  to_customer_success: {
    id: `${scope}.to_customer_success`,
    defaultMessage: 'Prospect transformed successfully to customer!',
  },
  flat_unit: {
    id: `${scope}.flat_unit`,
    defaultMessage: 'Flat unit',
  },
  refund: {
    id: `${scope}.refund`,
    defaultMessage: 'Refund',
  },
  confirm_vat_message: {
    id: `${scope}.confirm_vat_message`,
    defaultMessage: 'Are you sure to autocomplete the form?',
  },
  confirm_vat_message_again: {
    id: `${scope}.confirm_vat_message_again`,
    defaultMessage:
      'The field are already filled. Are you sure to autocomplete the form again?',
  },
  vat_require_error: {
    id: `${scope}.vat_require_error`,
    defaultMessage: 'The VAT number is required.',
  },
  vat_response_error: {
    id: `${scope}.vat_response_error`,
    defaultMessage: 'There was some issues during the autocomplete process.',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  headquarter: {
    id: `${scope}.headquarter`,
    defaultMessage: 'Headquarter',
  },
  noteReason: {
    id: `${scope}.noteReason`,
    defaultMessage: 'Note Reason',
  },
  noteDate: {
    id: `${scope}.noteDate`,
    defaultMessage: 'Note Date',
  },
  noteExecuted: {
    id: `${scope}.noteExecuted`,
    defaultMessage: 'Executed',
  },
  noteType: {
    id: `${scope}.noteType`,
    defaultMessage: 'Note Type',
  },
  subject_removed_success: {
    id: `${scope}.subject_removed_success`,
    defaultMessage: 'Subject successfully removed.',
  },
  attachments: {
    id: `${scope}.attachments`,
    defaultMessage: 'Attachments',
  },
  noteNumber: {
    id: `${scope}.noteNumber`,
    defaultMessage: 'Note number',
  },
  tab_commissions: {
    id: `${scope}.tab_commissions`,
    defaultMessage: 'Commissions',
  },
  company_selection: {
    id: `${scope}.company_selection`,
    defaultMessage: 'Company selection',
  },
  last_update: {
    id: `${scope}.last_update`,
    defaultMessage: 'Last update',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  office_type: {
    id: `${scope}.office_type`,
    defaultMessage: 'Office type',
  },
  activity_status: {
    id: `${scope}.activity_status`,
    defaultMessage: 'Activity status',
  },
  rea_code: {
    id: `${scope}.rea_code`,
    defaultMessage: 'REA code',
  },
  productCategory: {
    id: `${scope}.productCategory`,
    defaultMessage: 'Category',
  },
  attention: {
    id: `${scope}.attention`,
    defaultMessage: 'Attention',
  },
  subject: {
    id: `${scope}.subject`,
    defaultMessage: 'Subject',
  },
  delivery: {
    id: `${scope}.delivery`,
    defaultMessage: 'Delivery',
  },
  port: {
    id: `${scope}.port`,
    defaultMessage: 'port',
  },
  requestNumber: {
    id: `${scope}.requestNumber`,
    defaultMessage: 'requestNumber',
  },
  noteInternal: {
    id: `${scope}.noteInternal`,
    defaultMessage: 'noteInternal',
  },
  notePayment: {
    id: `${scope}.notePayment`,
    defaultMessage: 'notePayment',
  },
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done',
  },
  to_be_done: {
    id: `${scope}.to_be_done`,
    defaultMessage: 'To be done',
  },
  assignee: {
    id: `${scope}.assignee`,
    defaultMessage: 'Assignee',
  },
  valid_from: {
    id: `${scope}.valid_from`,
    defaultMessage: 'Valid from',
  },
  valid_to: {
    id: `${scope}.valid_to`,
    defaultMessage: 'Valid to',
  },
  crmCategory: {
    id: `${scope}.crmCategory`,
    defaultMessage: 'Crm category',
  },
  subCategory: {
    id: `${scope}.subCategory`,
    defaultMessage: 'Subcategory',
  },
  fromPercentage: {
    id: `${scope}.fromPercentage`,
    defaultMessage: 'Percentage from',
  },
  toPercentage: {
    id: `${scope}.toPercentage`,
    defaultMessage: 'Percentage to',
  },
  isIncludedInBonus: {
    id: `${scope}.isIncludedInBonus`,
    defaultMessage: 'Include in bonus [calculate]',
  },
  lastOrderDate: {
    id: `${scope}.lastOrderDate`,
    defaultMessage: 'Data ultimo ordine',
  },
  expireToday: {
    id: `${scope}.expireToday`,
    defaultMessage: 'Only today notes',
  },
  today: {
    id: `${scope}.today`,
    defaultMessage: 'Today',
  },
  reporter: {
    id: `${scope}.reporter`,
    defaultMessage: 'Reporter',
  },
  closure: {
    id: `${scope}.closure`,
    defaultMessage: 'Closure',
  },
  customerOrderNumber: {
    id: `${scope}.customerOrderNumber`,
    defaultMessage: 'Customer order number',
  },
  cig: {
    id: `${scope}.cig`,
    defaultMessage: 'CIG',
  },
  sdi: {
    id: `${scope}.sdi`,
    defaultMessage: 'SDI',
  },
  emailPec: {
    id: `${scope}.emailPec`,
    defaultMessage: 'PEC',
  },
  address: {
    id: `${scope}.address`,
    defaultMessage: 'Address',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  planned_date: {
    id: `${scope}.planned_date`,
    defaultMessage: 'Planned date',
  },
  creation_date: {
    id: `${scope}.creation_date`,
    defaultMessage: 'Creation date',
  },
  expireWithinToday: {
    id: `${scope}.expireWithinToday`,
    defaultMessage: 'Expire within today',
  },
});
