import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getUsers = ({ page }) =>
  request({
    baseURL: API.BASE_URL,
    url: API.USERS,
    method: 'GET',
    params: {
      page: page || 1,
      'order-by': [
        {
          type: 'field',
          field: 'createdAt',
          direction: 'desc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getById = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.USERS}/${id}`,
    method: 'GET',
  });

const getOptions = (inputValue, callback) => {
  getById(inputValue).then(results => {
    callback(
      _.get(results, 'data', []).map(state => ({
        value: state.id,
        label: state.firstName,
      })),
    );
  });
};

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.USERS,
    method: 'POST',
    data: content,
  });

const put = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.USERS}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.USERS}/${id}`,
    method: 'DELETE',
  });

const patch = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.USERS}/${content.id}`,
    method: 'PATCH',
    data: {
      email: content.email,
      defaultLanguage: content.defaultLanguage,
      color1: content.color1,
      color2: content.color2,
      logo: content.logo,
      logosmall: content.logoSmall,
    },
  });

const getUserList = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.USERS,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'firstName',
          value: `%${searchText}%`,
          where: 'or',
        },
        searchText && {
          type: 'like',
          field: 'lastName',
          value: `%${searchText}%`,
          where: 'or',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getUserOptions = () =>
  getUserList().then(res =>
    _.get(res, 'data._embedded.user', []).map(user => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    })),
  );

const getAsyncUserOptions = (inputValue, callback) => {
  getUserList(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.user', []).map(user => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.id,
      })),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const UserService = {
  getUsers,
  getUserOptions,
  getAsyncUserOptions,
  create,
  put,
  getById,
  getOptions,
  remove,
  patch,
};

export default UserService;
