import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAttachmentTypes = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.ATTACHMENT_TYPE,
    method: 'GET',
    params: {},
  });

const getAttachmentTypeOptions = () =>
  getAttachmentTypes().then(res =>
    _.get(res, 'data._embedded.attachment_type', []).map(type => ({
      label: type.description,
      value: type.id,
    })),
  );

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.SUPPLIER_ATTACHMENT,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.SUPPLIER_ATTACHMENT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.SUPPLIER_ATTACHMENT}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (supplierId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.SUPPLIER_ATTACHMENT}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'supplier',
          value: supplierId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const ContactService = {
  getAttachmentTypes,
  getAttachmentTypeOptions,
  getByOwner,
  create,
  update,
  remove,
};

export default ContactService;
