import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the productPriceMaster state domain
 */

const selectProductPriceMasterDomain = state =>
  state.get('productPriceMaster', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by ProductPriceMaster
 */

const makeSelectProductPriceMaster = () =>
  createSelector(selectProductPriceMasterDomain, substate => substate.toJS());

export default makeSelectProductPriceMaster;
export { selectProductPriceMasterDomain };
