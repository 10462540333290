import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _, { get } from 'lodash';
import { Grid, Form, TextArea } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import uuid from 'uuid/v4';
import { PaddedColumn } from '../../Layout';
import FormikField from '../../FormikField';
import FormikDate from '../../FormikDate';
import AttachmentService from '../../../shared/services/attachments';
import messages from '../Anagrafica/messages';
import FormikSelect from '../../FormikSelect';
import { addNotification } from '../../../utils/notification';
import appMessages from '../../../containers/App/messages';

export const BaseForm = props => {
  const [docType, setDocType] = useState(
    _.get(props, 'values.attachmentTypeId.description', null),
  );
  const [loading, setLoading] = useState(false);
  const [attachmentTypeValue, setAttachmentTypeValue] = useState({
    label: _.get(props, 'values.attachmenTypeId.id', ''),
    value: _.get(props, 'values.attachmentTypeId.description', ''),
  });

  useEffect(() => {
    const { id, description } = _.get(props, 'values.attachmentTypeId', {
      id: '',
      description: '',
    });
    setAttachmentTypeValue({ label: description, value: id });
  }, []);

  const uploadAttachment = e => {
    setLoading(true);
    AttachmentService.upload({
      entityType: `${docType}_${uuid()}`, // TODO: verificare se si possono gestire spazi nel tipo di attachment e come legare documenti multipli dello stesso tipo
      entityId: _.get(props, 'entityId', null),
      entityName: 'Customer',
      file: e.target.files[0],
    })
      .then(res => {
        setLoading(false);
        props.setFieldValue('attachmentId', res.data.result);
      })
      .catch(error => {
        setLoading(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.error),
          message: get(
            error,
            'data.detail',
            props.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  return (
    <Form
      autoComplete="off"
      onSubmit={props.handleSubmit}
      loading={props.isSubmitting || loading}
    >
      <Grid columns="equal">
        <Grid.Row>
          <PaddedColumn width={8}>
            <FormikSelect
              name="attachmenTypeId"
              label={props.intl.formatMessage(messages.document_type)}
              defaultValue={{
                value: _.get(props, 'values.attachmenTypeId.id', ''),
                label: _.get(props, 'values.attachmentTypeId.description', ''),
              }}
              value={attachmentTypeValue}
              onChange={value => {
                props.setFieldValue('attachmentTypeId', value.value);
                setAttachmentTypeValue(value);
                setDocType(value.label);
              }}
              options={props.documentTypes}
            />
          </PaddedColumn>
          <PaddedColumn width={8}>
            {(docType || _.get(props, 'values.attachmentId', null)) && (
              <FormikField
                name="file"
                label={props.intl.formatMessage(messages.upload)}
                {...props}
              >
                <input type="file" name="file" onChange={uploadAttachment} />
              </FormikField>
            )}
          </PaddedColumn>
          <PaddedColumn width={8}>
            <FormikDate
              {...props}
              name="releaseDate"
              label={props.intl.formatMessage(messages.release_date)}
            />
          </PaddedColumn>
          <PaddedColumn width={8}>
            <FormikDate
              {...props}
              name="expireDate"
              label={props.intl.formatMessage(messages.expire_date)}
            />
          </PaddedColumn>
          <PaddedColumn width={16}>
            <FormikField
              name="description"
              label={props.intl.formatMessage(messages.description)}
              {...props}
            >
              <TextArea
                onChange={(e, data) =>
                  props.setFieldValue('description', data.value)
                }
                value={_.get(props, 'values.description', '')}
                placeholder={props.intl.formatMessage(
                  messages.document_description_placeholder,
                )}
              />
            </FormikField>
          </PaddedColumn>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

BaseForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(BaseForm);
