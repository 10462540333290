/**
 *
 * Supplier
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Helmet } from 'react-helmet-async';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';

import ListManager from 'novigo-entity-grid';
import FormManager from 'novigo-form-manager';
import { Header } from 'semantic-ui-react';
import moment from 'moment';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import makeSelectSupplier from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import appMessages from '../App/messages';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';

/*
 * Specific import
 */
import { API } from '../../global-constants';

import request from '../../shared/services/request';

import SupplierForm, {
  validation,
} from '../../components/Forms/SupplierForm/Supplier';
import { ListWrapper, Page } from '../../components/Layout';
import { addNotification } from '../../utils/notification';
import MunicipalityService from '../../shared/services/municipality';

/* eslint-disable react/prefer-stateless-function */
export class Supplier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
    };
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'view' ||
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'modify' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showForm: true });
    } else {
      this.setState({ showForm: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.evaluteAction();
  }

  transformData = data => ({
    ...data,
    birthDate: data.birthDate ? moment(data.birthDate) : null,
  });

  render() {
    /**
    if (!_.get(this.props.user, ['permissions', Supplier, 'list'], false)) {
      return null;
    }
    */

    return (
      <>
        <Helmet>
          <title>Fornitori</title>
          <meta name="description" content="Fornitori" />
        </Helmet>
        <BreadcrumbsItem to="/supplier">
          {this.context.intl.formatMessage(messages.title)}
        </BreadcrumbsItem>
        <Page>
          <Header as="h2" dividing>
            <FormattedMessage {...messages.title} />
          </Header>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/supplier/edit">
                {actionToBreadcrumb({
                  action: this.props.match.params.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                key={this.props.match.params.action} // necessary to reload the current view upon redirect
                router={this.props} // necessary to use the internal routing
                client={pars =>
                  request(pars).then(res => this.transformData(res.data))
                }
                mask={props => (
                  <SupplierForm
                    {...props}
                    municipalityService={MunicipalityService}
                    editRoute={`/supplier/${this.props.match.params.id}/modify`}
                    history={this.props.history}
                  />
                )}
                validation={validation}
                onCleanData={data => {
                  const cleanData = _.cloneDeep(data);

                  _.unset(cleanData, 'id');
                  _.unset(cleanData, '_links');
                  _.unset(cleanData, '_embedded');
                  _.unset(cleanData, 'displayName');
                  _.unset(cleanData, 'paymentCharge');
                  _.unset(cleanData, 'taxRate');
                  _.unset(cleanData, 'creditLimit');

                  cleanData.birthDate = cleanData.birthDate
                    ? cleanData.birthDate.format('YYYY-MM-DD')
                    : null;

                  return cleanData;
                }}
                data={
                  this.props.match.params.id === 'new' && {
                    registryType: 'L',
                    gender: 'M',
                  }
                }
                entityName="supplier"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                onError={data =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  })
                }
                onSuccess={() =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
              />
            </>
          ) : (
            <ListWrapper>
              <ListManager
                entityName="supplier"
                sessionKey="supplier"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                router={this.props}
                persistentFilter={[]}
                locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                translations={{
                  createButton: {
                    label: this.context.intl.formatMessage(
                      messages.new_supplier,
                    ),
                  },
                }}
                canCreate={{
                  active: true,
                  label: <FormattedMessage {...messages.new_supplier} />,
                }}
                canExport={{
                  exportLabelPrefix: this.context.intl.formatMessage(
                    appMessages.export_to,
                  ),
                }}
                /* canSearchCustom={{
                  active: true,
                  title: this.context.intl
                    .formatMessage(appMessages.search)
                    .toUpperCase(),
                }} */
                client={pars => request(pars).then(res => res.data)}
                defaultOrder={[
                  {
                    type: 'field',
                    field: 'createdAt',
                    direction: 'desc',
                  },
                ]}
                columns={[
                  {
                    key: 'displayName',
                    name: this.context.intl.formatMessage(messages.nominative),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'fiscalCode',
                    name: this.context.intl.formatMessage(messages.fiscalcode),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'email',
                    name: this.context.intl.formatMessage(messages.email),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'phoneNumber',
                    name: this.context.intl.formatMessage(messages.telephone),
                    searchable: true,
                    sortable: false,
                  },
                ]}
                defaultActions={{
                  visualize: true,
                  modify: true,
                  delete: true,
                  grouped: false,
                  moreActions: [],
                }}
              />
            </ListWrapper>
          )}
        </Page>
      </>
    );
  }
}

Supplier.propTypes = {
  // dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  supplier: makeSelectSupplier(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'supplier', reducer });
const withSaga = injectSaga({ key: 'supplier', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Supplier);

Supplier.contextTypes = {
  intl: PropTypes.object.isRequired,
};
