import { defineMessages } from 'react-intl';

export const scope = 'novigo.entity.grid.customSearch';

export default defineMessages({
  searchLabel: {
    id: `${scope}.searchLabel`,
    defaultMessage: 'SEARCH',
  },
  buttonLabel: {
    id: `${scope}.buttonLabel`,
    defaultMessage: 'Search',
  },
  resetBtn: {
    id: `${scope}.resetBtn`,
    defaultMessage: 'Reset',
  },
});
