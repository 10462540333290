// import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

// eslint-disable-next-line camelcase
const getAllByOfferId = (id, { page, page_size }) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.OFFER_LOGS}`,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'objectId',
          value: id,
        },
        {
          type: 'eq',
          field: 'objectClass',
          value: 'Sale\\Entity\\Offer',
        },
      ],
      'order-by': [
        {
          type: 'field',
          field: 'loggedAt',
          direction: 'desc',
        },
      ],
      page,
      page_size,
    },
    paramsSerializer: params => qs.stringify(params),
  });

// eslint-disable-next-line camelcase
const getOfferLogs = ({ customer, state }, { page, page_size }) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.OFFER_LOGS}`,
    method: 'GET',
    params: {
      filter: [
        customer && {
          type: 'eq',
          field: 'objectId',
          value: customer,
        },
        // state && {
        //   type: 'innerjoin',
        //   field: 'data',
        //   alias: 'state',
        // },
        state && {
          type: 'eq',
          field: 'state',
          value: state,
          // alias: 'stat`e',
        },
        {
          type: 'eq',
          field: 'objectClass',
          value: 'Sale\\Entity\\Offer',
        },
      ],
      'order-by': [
        {
          type: 'field',
          field: 'loggedAt',
          direction: 'desc',
        },
      ],
      page,
      page_size,
    },
    paramsSerializer: params => qs.stringify(params),
  });

const OfferLogsService = {
  getAllByOfferId,
  getOfferLogs,
};
export default OfferLogsService;
