import React, { useEffect, useState, useRef } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';
import { Formik } from 'formik';
import { ModalFakeActions, WrapperTool } from '../../../Layout';
import Modal from '../../../Modal';
import messages from '../messages';
import monthMessages from '../../../FormikMonthSelect/messages';
import { EditBtn, ModalNewBtn, RemoveBtn } from '../../../Buttons';
import appMessages from '../../../../containers/App/messages';
import { addNotification } from '../../../../utils/notification';
import AgentPlannedService from '../../../../shared/services/agentplannedsales';
import AgentPlannedForm from './agentPlannedForm';
import DatePickerYear from './DatePickerYear';
import currencyFormatter from '../../../../utils/currencyFormatter';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const PlannedSalesTable = props => {
  const [loading, setLoading] = useState(true);
  const [plannedSales, setPlannedSales] = useState([]);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });
  const [selectedPlannedSale, setSelectedPlannedSale] = useState(null);
  const [modalOpened, setModalOpened] = useState(false);
  const [year, setYear] = useState(moment().format('YYYY'));
  const prevYear = usePrevious(year);

  useEffect(() => loadData(), [props.values.id]);

  useEffect(
    () => {
      if (
        typeof prevYear !== 'undefined' &&
        typeof year !== 'undefined' &&
        prevYear &&
        year &&
        prevYear !== year
      ) {
        loadData();
      }
    },
    [year],
  );

  const loadData = () => {
    setLoading(true);
    AgentPlannedService.getByYear({ year, agent: props.values.id }).then(
      response => {
        setLoading(false);
        setPlannedSales(_.get(response, 'data._embedded.agent-planned-sale'));
      },
    );
  };

  const closeModal = () => {
    setSelectedPlannedSale(null);
    setModalOpened(false);
  };

  const openModal = () => {
    setSelectedPlannedSale({
      year,
      month: '',
      planned: '',
    });
    setModalOpened(true);
  };

  const columns = () =>
    [
      {
        key: 'month',
        name: props.intl.formatMessage(messages.month),
        formatter: ({ data }) => {
          const month = _.get(data, 'month');
          const formattedMonth =
            props.intl.formatMessage(monthMessages[`${month}`]) || '';
          return formattedMonth;
        },
      },
      {
        key: 'planned',
        name: props.intl.formatMessage(messages.planned),
        formatter: ({ data }) =>
          data.planned
            ? currencyFormatter.format(_.get(data, 'planned', ''))
            : '--',
      },
      {
        key: 'billed',
        name: props.intl.formatMessage(messages.billed),
        formatter: ({ data }) => _.get(data, 'billed', ''),
      },
      {
        key: 'deviation',
        name: props.intl.formatMessage(messages.deviation),
        formatter: ({ data }) => _.get(data, 'deviation', ''),
      },
      !props.readOnly && {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 120 }}>
            <EditBtn
              action={() => {
                setSelectedPlannedSale(data);
                setModalOpened(true);
              }}
            />
            <RemoveBtn
              action={() => {
                AgentPlannedService.remove(data.id)
                  .then(() => {
                    loadData();
                    addNotification({
                      title: null,
                      message: props.intl.formatMessage(
                        messages.document_removed_success,
                      ),
                      isError: false,
                    });
                  })
                  .catch(error =>
                    addNotification({
                      title: props.intl.formatMessage(appMessages.error),
                      message: error.data.detail,
                      isError: true,
                    }),
                  );
              }}
            />
          </div>
        ),
        width: '120px',
      },
    ].filter(o => o);

  let baseForm = React.createRef();

  const submit = () => {
    baseForm.submitForm();
  };

  // eslint-disable-next-line no-unused-vars
  const onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      agent: _.get(props, 'values.id'),
      year: _.get(values, 'year', null),
      month: _.get(values, 'month', null),
      planned: _.get(values, 'planned', null),
    };

    const requestMethod = payload.id
      ? AgentPlannedService.update
      : AgentPlannedService.create;

    requestMethod(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: props.intl.formatMessage(appMessages.success),
          message: props.intl.formatMessage(messages.planned_saved),
          isError: false,
        });
        loadData();
        closeModal();
      })
      .catch(error => {
        setSubmitting(false);

        const validationMessages = _.get(
          error,
          'data.validation_messages',
          false,
        );
        if (validationMessages) {
          setErrors(validationMessages);
        } else {
          addNotification({
            title: props.intl.formatMessage(appMessages.error),
            message: error.data.detail,
            isError: true,
          });
        }
      });
  };

  const onValidate = values => {
    const errors = {};

    if (!values.month) {
      errors.month = {
        required: props.intl.formatMessage(appMessages.required_field),
      };
    }

    if (!values.planned) {
      errors.planned = {
        required: props.intl.formatMessage(appMessages.required_field),
      };
    }

    return errors;
  };

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <DatePickerYear
              name="year"
              label="Year"
              value={year}
              onChange={value => setYear(value)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Table
              elements={plannedSales}
              rowClassKey="role-row-class"
              columns={columns()}
              canSort={{
                active: true,
                // eslint-disable-next-line no-unused-vars
                onSort: (key, order) => {},
              }}
              isLoading={loading}
              canPaginate={{
                active: paginationData.total_items > 0,
                pageCount: paginationData.page_count,
                pageSize: paginationData.page_size,
                totalItems: paginationData.total_items,
                page: paginationData.page,
                onSelect: page => {
                  setPaginationData({
                    ...paginationData,
                    page,
                  });
                },
              }}
              canAction={{
                active: !props.readOnly,
                actions: [
                  <WrapperTool key="wrapper">
                    <Modal
                      open={modalOpened}
                      onClose={() => {
                        closeModal();
                      }}
                      title={
                        <h2>
                          <Icon name="attach" />{' '}
                          {props.intl.formatMessage(messages.new_planned)}
                        </h2>
                      }
                      trigger={
                        <ModalNewBtn onClick={openModal}>
                          {props.intl.formatMessage(messages.new_planned)}
                        </ModalNewBtn>
                      }
                      size="mini"
                      style={{ width: 400 }}
                      scrolling
                    >
                      <Formik
                        {...props}
                        initialValues={selectedPlannedSale}
                        ref={c => {
                          baseForm = c;
                        }}
                        validate={onValidate}
                        onSubmit={onSubmit}
                      >
                        {fikProps => (
                          <AgentPlannedForm {...fikProps} intl={props.intl} />
                        )}
                      </Formik>
                      <ModalFakeActions>
                        <div style={{ display: 'inline-block', width: '50%' }}>
                          <Button
                            type="button"
                            icon
                            labelPosition="left"
                            onClick={closeModal}
                          >
                            <Icon name="remove" />
                            {props.intl.formatMessage(appMessages.cancel)}
                          </Button>
                        </div>
                        <div
                          style={{
                            display: 'inline-block',
                            width: '50%',
                            textAlign: 'right',
                          }}
                        >
                          <Button
                            type="button"
                            icon
                            labelPosition="left"
                            color="green"
                            onClick={submit}
                          >
                            <Icon name="checkmark" />
                            {props.intl.formatMessage(appMessages.save)}
                          </Button>
                        </div>
                      </ModalFakeActions>
                    </Modal>
                  </WrapperTool>,
                ],
              }}
              hiddenHeaderIfEmpty
              emptyResults={
                <div>{props.intl.formatMessage(appMessages.no_results)}</div>
              }
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

PlannedSalesTable.defaultProps = {
  values: {},
  // readOnly: false,
};

PlannedSalesTable.propTypes = {
  values: PropTypes.object,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
};

export default injectIntl(PlannedSalesTable);
