export const validation = values => {
  const errors = {};

  if (!values.startDate) {
    errors.startDate = 'Required';
  }
  if (!values.endDate) {
    errors.endDate = 'Required';
  }
  if (!values.customer.value) {
    errors.customer = 'Required';
  }
  if (!values.destination.value) {
    errors.destination = 'Required';
  }
  if (!values.state.value) {
    errors.state = 'Required';
  }

  if (values.eventType === 'appointment') {
    // site
    if (!values.agent.value) {
      errors.agent = 'Required';
    }
    if (!values.subject) {
      errors.subject = 'Required';
    }
  }

  if (values.eventType === 'workorder') {
    if (!values.plannedDate) {
      errors.plannedDate = 'Required';
    }
    if (!values.workorderType.value) {
      errors.workorderType = 'Required';
    }
  }

  return errors;
};
