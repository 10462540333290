/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Table from 'semantic-table-grid';
import { injectIntl, intlShape } from 'react-intl';
import { Button, Icon, Grid } from 'semantic-ui-react';
import Modal from '../../../../Modal';
import messages from '../../messages';
import { ModalFakeActions } from '../../../../Layout';
import OrderService from '../../../../../shared/services/order';
import appMessages from '../../../../../containers/App/messages';
import { numberFormatter } from '../../../../../utils/currencyFormatter';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

function GoodsDiscountModal(props) {
  const { open, onClose, destinationId } = props;

  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });

  useEffect(
    () => {
      if (open) {
        // eslint-disable-next-line no-unused-expressions
        loadData();
      }
    },
    [open, paginationData.page],
  );

  const loadData = page => {
    setLoading(true);
    const filter = {};
    filter.destination = destinationId;
    OrderService.getGoodDiscount(filter, page || paginationData.page).then(
      res => {
        const data = _.get(res, 'data', { ...paginationDefault });
        setPaginationData({
          page: data.page === 0 ? 1 : data.page,
          page_count: data.page_count,
          page_size: data.page_size,
          total_items: data.total_items,
        });
        setLoading(false);
        setItems(data, []);
      },
    );
  };

  const columns = () =>
    [
      {
        key: 'code',
        name: props.intl.formatMessage(messages.code),
      },
      {
        key: 'description',
        name: props.intl.formatMessage(messages.description),
      },
      {
        key: 'quantity',
        name: props.intl.formatMessage(messages.quantity),
        formatter: ({ data }) =>
          data.quantity
            ? `${numberFormatter.format(_.get(data, 'quantity'))} pz`
            : '--',
      },
    ].filter(o => o);

  return (
    <Modal
      open={open}
      onClose={() => onClose()}
      title={<h2>{props.intl.formatMessage(messages.verify_goodsDiscount)}</h2>}
      size="small"
      scrolling
    >
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Table
                elements={items}
                rowClassKey="role-row-class"
                columns={columns()}
                canSort={{
                  active: true,
                }}
                isLoading={loading}
                canPaginate={{
                  active: paginationData.total_items > 0,
                  pageCount: paginationData.page_count,
                  pageSize: paginationData.page_size,
                  totalItems: paginationData.total_items,
                  page: paginationData.page,
                  onSelect: page => {
                    setPaginationData({
                      ...paginationData,
                      page,
                    });
                  },
                }}
                hiddenHeaderIfEmpty
                emptyResults={
                  <div>{props.intl.formatMessage(appMessages.no_results)}</div>
                }
              />
            </Grid.Column>
          </Grid.Row>
          <ModalFakeActions>
            <div style={{ display: 'inline-block', width: '50%' }}>
              <Button type="button" icon labelPosition="left" onClick={onClose}>
                <Icon name="remove" />
                {props.intl.formatMessage(appMessages.cancel)}
              </Button>
            </div>
          </ModalFakeActions>
        </Grid>
      </div>
    </Modal>
  );
}

GoodsDiscountModal.propTypes = {
  onClose: PropTypes.func,
  intl: intlShape.isRequired,
  destinationId: PropTypes.string,
  open: PropTypes.bool,
};

export default injectIntl(GoodsDiscountModal);
