import _, { get, has } from 'lodash';
import moment from 'moment';
import SimpleFormikField from 'novigo-simple-formik-field';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import Table from 'semantic-table-grid';
import {
  Button,
  Form,
  Grid,
  Icon,
  Pagination,
  Placeholder,
} from 'semantic-ui-react';
import appMessages from '../../../containers/App/messages';
// Services
import AgentService from '../../../shared/services/agent';
import CommissionService from '../../../shared/services/commission';
import CustomerService from '../../../shared/services/customer';
import InvoiceService from '../../../shared/services/invoice';
import currencyFormatter from '../../../utils/currencyFormatter';
import { addNotification } from '../../../utils/notification';
import { ModalCloseBtn, SearchBtn } from '../../Buttons';
import FormActionsBar from '../../FormActionsBar';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import { handleAddParams } from './functions';
import messages from './messages';
export const CommissionCreate = (props, context) => {
  const [gridData, setGridData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [deselectedRows, setDeselectedRows] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const [loadingTotals, setLoadingTotals] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    pageCount: 1,
    pageSize: 5,
    totalItems: 0,
  });
  const [isLoading, setLoading] = useState(false);
  const [isGenerating, setGenerating] = useState(false);
  const {
    handleSubmit,
    children,
    setFieldValue,
    readOnly,
    values,
    ...rest
  } = props;

  useEffect(
    () => {
      if (props.isNew) {
        loadData(pagination.page);
      } else if (values.id) {
        loadData(pagination.page);
      }
    },
    [values.id, props.isNew],
  );

  const resetState = () => {
    setSelectedAmount(0);
    setTotalValue(0);
    setSelectedRows([]);
    setDeselectedRows([]);
    setAllSelected(false);
  };

  const loadData = page => {
    setLoading(true);
    const customer = get(values, 'customer.value', null);
    const invoiceDateFrom = get(values, 'invoiceDateFrom', null);
    const invoiceDateTo = get(values, 'invoiceDateTo', null);
    const agent = get(values, 'agent.value', null);
    const filterType = get(values, 'filter') === 'INCLUDE' ? 'eq' : 'neq';

    InvoiceService.getCommissionableInvoices(
      customer,
      invoiceDateFrom,
      invoiceDateTo,
      filterType,
      agent,
      page || 1,
    )
      .then(response => {
        setPagination({
          page: get(response, 'data.page', 1),
          pageCount: get(response, 'data.page_count', 1),
          pageSize: get(response, 'data.page_size', 0),
          totalItems: get(response, 'data.total_items', 0),
        });
        setLoading(false);
        const data = get(response, `data._embedded.invoice`, []);

        if (!page) {
          // if its a new search with new(different) filters (loadData(null)) then do full reset
          resetState();
        }
        if (page && allSelected) {
          const ids = data
            .map(el => el.id)
            .filter(id => !deselectedRows.includes(id) && id);
          _.uniq(ids);
          setSelectedRows(_.uniq(selectedRows.concat(ids)));
          data.map(el => {
            if (deselectedRows.includes(el.id)) {
              return { ...el, 'entitygrid-selected': false };
            }
            return { ...el, 'entitygrid-selected': true };
          });
        }

        if (!data.length) {
          // if the result is 0 invoices then reset state
          resetState();
        }

        setGridData(data);
      })
      .catch(error => {
        setLoading(false);
        addNotification({
          title: context.intl.formatMessage(appMessages.error),
          message: get(
            error,
            'detail',
            context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  const generateCommission = () => {
    const vals = { ...values, selectedRows, allSelected, deselectedRows };
    const payload = handleAddParams(vals);
    setGenerating(true);
    CommissionService.create(payload)
      .then(response => {
        setGenerating(false);
        addNotification({
          title: context.intl.formatMessage(appMessages.success),
          message: get(
            response,
            'data.message',
            context.intl.formatMessage(
              appMessages.operation_performed_successfully,
            ),
          ),
          isError: false,
        });
        props.reloadData();
      })
      .catch(error => {
        setGenerating(false);
        addNotification({
          title: context.intl.formatMessage(appMessages.error),
          message: get(
            error,
            'data.message',
            context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  const renderTotals = () => {
    if (loadingTotals) {
      return (
        <Placeholder
          style={{
            width: 190,
            height: 25,
            float: 'right',
            backgroundColor: 'transparent',
          }}
          fluid
        >
          <Placeholder.Line style={{ height: '100%', margin: 0 }} />
        </Placeholder>
      );
    }
    // if using select all function then show the amount received from BE else selected by hand
    const msg = context.intl.formatMessage(messages.totalsOfSelected, {
      total: selectedAmount,
      value: currencyFormatter.format(totalValue.toFixed(2)),
    });
    return msg;
  };

  /**
   * Manages the selection of an invoice row.
   * @param {*} row Selected row.
   */
  const onSelectedRow = row => {
    const addSelected = [...selectedRows, row.id];
    setSelectedRows(addSelected);
    // Add or substract the total rows amount to the calculated value, basing on the invoice type
    const value =
      parseFloat(totalValue) +
      (row.typology.isCreditNote
        ? -parseFloat(row.totalRowsAmount)
        : parseFloat(row.totalRowsAmount));
    // Set calculated value
    setTotalValue(value);

    const filteredDeslected = deselectedRows.filter(
      el => !addSelected.includes(el) && el,
    );
    setDeselectedRows(filteredDeslected);
    setSelectedAmount(prev => prev + 1);
  };

  /**
   * Manages the deselection of an invoice row.
   * @param {*} row Selected row.
   */
  const onDeselectedRow = row => {
    setSelectedRows(selectedRows.filter(id => id !== row.id));
    setDeselectedRows([...deselectedRows, row.id]);
    // Add or substract the total rows amount to the calculated value, basing on the invoice type
    const value =
      parseFloat(totalValue) +
      (row.typology.isCreditNote
        ? +parseFloat(row.totalRowsAmount)
        : -parseFloat(row.totalRowsAmount));
    // Set calculated value
    setTotalValue(value);
    setSelectedAmount(prev => prev - 1);
  };

  const onSelectAllRows = () => {
    const selectedVisibleIds = gridData.map(el => el.id);
    setSelectedRows([...new Set([...selectedRows, ...selectedVisibleIds])]);
    setDeselectedRows([]);

    setAllSelected(true);
    const vals = { ...values, selectedRows, allSelected, deselectedRows };
    const payload = handleAddParams(vals);
    payload.included = [];
    payload.excluded = [];
    delete payload.referenceDate;
    setLoadingTotals(true);
    CommissionService.getTotals(payload)
      .then(({ data }) => {
        const { invoices, totalAmount } = data;
        // eslint-disable-next-line radix
        setSelectedAmount(parseInt(invoices));
        setTotalValue(parseFloat(totalAmount));
      })
      .catch(err => {
        addNotification({
          title: context.intl.formatMessage(appMessages.error),
          message: get(
            err,
            'detail',
            context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      })
      .finally(() => setLoadingTotals(false));
  };

  const onDeselectAllRows = () => {
    const selectedVisibleIds = gridData.map(el => el.id);
    const cleanIds = selectedRows.filter(
      id => !_.includes(selectedVisibleIds, id),
    );
    setSelectedRows(cleanIds);
    setDeselectedRows(cleanIds);
    setAllSelected(false);
    setSelectedAmount(0);
    setTotalValue(0);
  };

  const reconciliation = (Ids, elements) =>
    elements.map(el =>
      Object.assign({}, el, {
        'entitygrid-selected': Boolean(_.includes(Ids, el.id)),
      }),
    );

  const getElements = () => reconciliation(selectedRows, gridData);

  const CodeNameFormatter = ({ code, displayName }) => {
    const textParts = [code, displayName].filter(t => t);

    return textParts ? textParts.join(' - ') : '--';
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={3}>
            <FormikDate
              {...rest}
              readOnly={readOnly}
              required
              name="referenceDate"
              label={context.intl.formatMessage(messages.referenceDate)}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <FormikDate
                    readOnly={readOnly}
                    name="invoiceDateFrom"
                    label={context.intl.formatMessage(messages.invoiceDateFrom)}
                  />
                </Grid.Column>
                <Grid.Column width={8}>
                  <FormikDate
                    readOnly={readOnly}
                    name="invoiceDateTo"
                    label={context.intl.formatMessage(messages.invoiceDateTo)}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
          <Grid.Column width={4}>
            <SimpleFormikField
              name="filter"
              label={context.intl.formatMessage(messages.selectHeadquarter)}
            >
              {({ field }) => (
                <Form.Group widths="equal">
                  <Button.Group>
                    <Button
                      type="button"
                      disabled={readOnly}
                      primary={field.value === 'ALL'}
                      checked={field.value === 'ALL'}
                      onClick={() => setFieldValue('filter', 'ALL')}
                    >
                      <FormattedMessage {...messages.filterAll} />
                    </Button>
                    <Button
                      type="button"
                      disabled={readOnly}
                      primary={field.value === 'INCLUDE'}
                      checked={field.value === 'INCLUDE'}
                      onClick={() => setFieldValue('filter', 'INCLUDE')}
                    >
                      <FormattedMessage {...messages.filterInclude} />
                    </Button>
                    <Button
                      type="button"
                      disabled={readOnly}
                      primary={field.value === 'EXCLUDE'}
                      checked={field.value === 'EXCLUDE'}
                      onClick={() => setFieldValue('filter', 'EXCLUDE')}
                    >
                      <FormattedMessage {...messages.filterExclude} />
                    </Button>
                  </Button.Group>
                </Form.Group>
              )}
            </SimpleFormikField>
          </Grid.Column>
          <Grid.Column width={4}>
            {values.filter !== 'ALL' && (
              <FormikAsyncSelect
                name="customer"
                label={context.intl.formatMessage(messages.headquarter)}
                loadOptions={(searchText, callback) =>
                  CustomerService.getOptions(searchText, callback, true)
                }
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <FormikAsyncSelect
              name="agent"
              label={context.intl.formatMessage(messages.agents)}
              loadOptions={(searchText, callback) =>
                AgentService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column floated="right" width={2}>
            <SearchBtn
              disabled={readOnly}
              color="blue"
              onClick={() => loadData(null)}
            >
              {context.intl.formatMessage(appMessages.search)}
            </SearchBtn>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Table
              rawProps={{
                attached: 'top',
              }}
              attached="top"
              rowClassKey="role-row-class"
              isLoading={isLoading}
              canAction={{
                active: true,
                actions: [
                  <Grid>
                    <Grid.Row columns={2}>
                      <Grid.Column textAlign="left">
                        {context.intl.formatMessage(messages.selectedInvoices)}
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        {renderTotals()}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>,
                ],
              }}
              elements={getElements()}
              columns={[
                {
                  key: 'number',
                  name: context.intl.formatMessage(messages.number),
                  formatter: ({ data }) => get(data, 'number', '--'),
                },
                {
                  key: 'customer',
                  name: context.intl.formatMessage(messages.headquarter),
                  formatter: ({ data }) => (
                    <CodeNameFormatter {...data.customer} />
                  ),
                },
                {
                  key: 'destination',
                  name: context.intl.formatMessage(messages.customer),
                  formatter: ({ data }) => (
                    <CodeNameFormatter {...data.destination} />
                  ),
                },
                {
                  key: 'agents',
                  name: context.intl.formatMessage(messages.agents),
                  formatter: ({ data }) =>
                    get(data, 'agents', [])
                      .map(agent => agent && agent.displayName)
                      .filter(agent => agent)
                      .join(','),
                },
                {
                  key: 'referenceDate',
                  name: context.intl.formatMessage(messages.referenceDate),
                  formatter: ({ data }) =>
                    has(data, 'referenceDate.date')
                      ? moment(data.referenceDate.date).format('DD/MM/YYYY')
                      : null,
                },
                {
                  key: 'totalAmount',
                  name: context.intl.formatMessage(messages.totalAmount),
                  formatter: ({ data }) =>
                    currencyFormatter.format(get(data, 'totalRowsAmount', 0)),
                },
              ]}
              canSelect={{
                active: !readOnly,
                selectAll: true,
                pageMax: 5,
                onSelect: row => onSelectedRow(row),
                onDeselect: row => onDeselectedRow(row),
                onSelectAll: () => onSelectAllRows(),
                onDeselectAll: () => onDeselectAllRows(),
                isSelectedProperty: 'entitygrid-selected',
              }}
              canPaginate={{
                active: true,
                // pageCount: pagination.pageCount,
                // pageSize: pagination.pageSize,
                // totalItems: pagination.totalItems,
                // page: pagination.page,
                // onSelect: pageNumber => {
                //   setPagination({ ...pagination, page: pageNumber });
                //   loadData(pageNumber);
                // },
                // pageMax: pagination.pageCount,
                render: (
                  <Pagination
                    activePage={pagination.page}
                    onPageChange={(e, { activePage }) => {
                      setPagination({ ...pagination, page: activePage });
                      loadData(activePage);
                    }}
                    size="mini"
                    totalPages={pagination.pageCount}
                  />
                ),
              }}
              hiddenHeaderIfEmpty
              emptyResults={<div>No results!</div>}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column floated="left" width={2}>
            <ModalCloseBtn onClick={props.onClose}>
              {context.intl.formatMessage(appMessages.cancel)}
            </ModalCloseBtn>
          </Grid.Column>
          <Grid.Column floated="right" width={2}>
            <Button
              loading={isGenerating}
              disabled={
                selectedRows.length === 0 || !values.referenceDate || readOnly
              }
              type="submit"
              icon
              color="green"
              labelPosition="left"
              onClick={() => generateCommission()}
            >
              <Icon name="check" />
              {context.intl.formatMessage(messages.generate)}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {!props.isNew && <FormActionsBar {...props} readOnly />}
    </Form>
  );
};

CommissionCreate.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

CommissionCreate.contextTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CommissionCreate);
