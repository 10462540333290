/* eslint-disable react/prefer-stateless-function */
/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { Form, Button } from 'semantic-ui-react';
import { Field } from 'formik';
import Accordion from '../../Accordion';
import IndividualPart from '../Anagrafica/formParts/individualPart';
import LegalPart from '../Anagrafica/formParts/legalPart';
import DocumentsPart from '../Anagrafica/formParts/documentsPart';
import ContactsPart from '../Anagrafica/formParts/contactsPart';
import NotesPart from '../Anagrafica/formParts/notesPart';

import validation from './validation';
import FormActionsBar from '../../FormActionsBar';
import messages from '../Anagrafica/messages';
import ContactService from '../../../shared/services/suppliercontacts';
import DocumentService from '../../../shared/services/supplierdocument';
import NoteService from '../../../shared/services/suppliernote';

export class SupplierMask extends React.Component {
  render() {
    const {
      handleSubmit,
      setFieldValue,
      values,
      readOnly = false,
      isSubmitting = false,
      isValid = false,
      onDelete = false,
      isDeleting = false,
      showDeleteButton = false,
    } = this.props;

    const { registryType } = values;

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          name="registryType"
          render={({ field }) => (
            <Form.Group widths="equal">
              <Button.Group>
                <Button
                  type="button"
                  disabled={readOnly}
                  primary={field.value === 'L'}
                  checked={field.value === 'L'}
                  onClick={() => setFieldValue('registryType', 'L')}
                >
                  <FormattedMessage {...messages.legal_person} />
                </Button>
                <Button
                  type="button"
                  disabled={readOnly}
                  primary={field.value === 'I'}
                  checked={field.value === 'I'}
                  onClick={() => setFieldValue('registryType', 'I')}
                >
                  <FormattedMessage {...messages.natural_person} />
                </Button>
              </Button.Group>
            </Form.Group>
          )}
        />

        {registryType === 'I' && (
          <Accordion
            title={this.context.intl.formatMessage(messages.personal_data)}
          >
            <IndividualPart {...this.props} />
          </Accordion>
        )}
        {registryType === 'L' && (
          <Accordion
            title={this.context.intl.formatMessage(messages.company_data)}
          >
            <LegalPart {...this.props} />
          </Accordion>
        )}
        {/* <Accordion title="Comunicazioni">
          <CommunicationPart {...this.props} />
        </Accordion>
        <Accordion title="Pagamento">
          <PaymentPart {...this.props} />
        </Accordion> */}
        {values.id && (
          <>
            <Accordion
              title={this.context.intl.formatMessage(messages.contacts)}
            >
              <ContactsPart
                {...this.props}
                contactService={ContactService}
                entity="supplier"
              />
            </Accordion>
            <Accordion
              title={this.context.intl.formatMessage(messages.documents)}
            >
              <DocumentsPart
                {...this.props}
                entity="supplier"
                documentService={DocumentService}
              />
            </Accordion>
            <Accordion title={this.context.intl.formatMessage(messages.notes)}>
              <NotesPart
                {...this.props}
                noteService={NoteService}
                entity="supplier"
              />
            </Accordion>
          </>
        )}
        <FormActionsBar
          showDeleteButton={showDeleteButton}
          readOnly={readOnly}
          isValid={isValid}
          isDeleting={isDeleting}
          isSubmitting={isSubmitting}
          editRoute={this.props.editRoute}
          onDelete={onDelete}
          history={this.props.history}
        />
      </Form>
    );
  }
}

SupplierMask.defaultProps = {
  errors: [],
  registryType: 'L',
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

SupplierMask.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  registryType: PropTypes.string,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

SupplierMask.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default SupplierMask;

export { validation };
