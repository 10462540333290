import request from 'shared/services/request';
import qs from 'qs';
import { API } from '../../../global-constants';

const getPermissions = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.PERMISSIONS,
    method: 'GET',
  });

const getByRole = role =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PERMISSIONS}`,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'role',
          value: role,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.PERMISSIONS,
    method: 'POST',
    data: content,
  });

const put = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PERMISSIONS}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const PermissionService = {
  getPermissions,
  getByRole,
  create,
  put,
};

export default PermissionService;
