/*
 * Customer Messages
 *
 * This contains all the text for the Customer container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.CustomerContact';

export default defineMessages({
  contact_saved: {
    id: `${scope}.contact_saved`,
    defaultMessage: 'Contact successfully saved',
  },
});
