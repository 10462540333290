/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import qs from 'qs';
import _ from 'lodash';
import request from '../request';
import { API } from '../../../global-constants';
import { OFFER_SERVICES_PRODUCT_CATEGORIES_ALLOWED } from '../../../containers/Offer/constants';

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_CATEGORY,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_CATEGORY}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_CATEGORY}/${id}`,
    method: 'DELETE',
  });

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_CATEGORY,
    method: 'GET',
    params: {
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getCategoryOptions = () =>
  getAll().then(res =>
    _.get(res, 'data._embedded.product-category', []).map(category => ({
      label: category.description,
      value: category.id,
      subcategories: category.subcategories,
    })),
  );

const getSubByCategory = categoryId =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_SUB_CATEGORY}`,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'eq',
          field: 'category',
          value: categoryId,
        },
      ],
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getSubCategoryOptions = categoryId =>
  getSubByCategory(categoryId).then(res =>
    _.get(res, 'data._embedded.product-sub-category', []).map(type => ({
      label: type.description,
      value: type.id,
    })),
  );

const searchCategoryByDescription = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_CATEGORY,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAsyncCategoryOptions = (inputValue, callback) => {
  searchCategoryByDescription(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.product-category', []).map(state => ({
        value: state.id,
        label: state.description,
      })),
    );
  });
};

const searchSubByCategoryByDescription = (
  searchText,
  showCode = false,
  active = false,
  category,
) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_SUB_CATEGORY,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'description',
          value: `%${searchText}%`,
          where: 'or',
        },
        searchText &&
          showCode && {
            type: 'like',
            field: 'code',
            where: 'or',
            value: `%${searchText}%`,
          },
        active && {
          type: 'eq',
          field: 'isActive',
          where: 'and',
          value: true,
        },
        category && {
          type: 'eq',
          field: 'category',
          where: 'and',
          value: category.value,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAsyncSubByCategoryOptions = (
  inputValue,
  callback,
  category,
  showCode = false,
  active = false,
) => {
  searchSubByCategoryByDescription(inputValue, showCode, active, category).then(
    results => {
      callback(
        _.get(results, 'data._embedded.product-sub-category', []).map(
          state => ({
            value: state.id,
            label: showCode
              ? `${state.code} - ${state.description}`
              : state.description,
          }),
        ),
      );
    },
  );
};

const getServicesCategory = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_CATEGORY,
    method: 'GET',
    params: {
      filter: [
        {
          type: 'in',
          field: 'description',
          values: OFFER_SERVICES_PRODUCT_CATEGORIES_ALLOWED,
        },
      ].filter(o => o),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const ProductCategoryService = {
  create,
  update,
  remove,
  getSubByCategory,
  getCategoryOptions,
  getSubCategoryOptions,
  getAsyncCategoryOptions,
  getAsyncSubByCategoryOptions,
  getServicesCategory,
};

export default ProductCategoryService;
