/**
 *
 * Permission
 *
 */

import _ from 'lodash';
import { CanI } from '../../containers/Can/utils';
import appMessages from '../../containers/App/messages';
import { addNotification } from '../../utils/notification';

/**
 * Check if you have the right permission to stay here
 * Used in the REST managers
 */
const checkRESTPermission = (entity, history, match, intl) => {
  const { action, id } = _.get(match, 'params');

  if (
    !CanI('list', entity) ||
    (action === 'view' && !CanI('read', entity)) ||
    (action === 'delete' && !CanI('remove', entity)) ||
    (action === 'modify' && !CanI('edit', entity)) ||
    (id === 'new' && !CanI('create', entity))
  ) {
    // add a forbidden notication then go back
    addNotification({
      title: intl.formatMessage(appMessages.error),
      message: intl.formatMessage(appMessages.forbidden),
      isError: true,
    });
    /**
     * Redirect or goBack
     */
    if (history.length > 2) {
      history.goBack();
    } else {
      history.push('/');
    }
  }
};

const Permission = {
  checkRESTPermission,
  CanI,
};

export default Permission;
