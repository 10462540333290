/* eslint-disable indent */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import { FormActions } from '../Layout';
import { FormRemoveBtn } from '../Buttons';
import appMessages from '../../containers/App/messages';

function FormActionsBar(props) {
  const {
    showDeleteButton,
    readOnly,
    intl,
    onDelete,
    isDeleting,
    isSubmitting,
    isValid,
    close,
    onSaveClick,
    moreButtons = null,
  } = props;

  return (
    <FormActions>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Button type="button" icon labelPosition="left" onClick={close}>
              <Icon name="remove" />
              {intl.formatMessage(appMessages.cancel)}
            </Button>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            {showDeleteButton && (
              <FormRemoveBtn loading={isDeleting} action={onDelete} notice />
            )}
            {moreButtons}
            {!readOnly && (
              <Button
                loading={isSubmitting}
                disabled={!isValid}
                type="button"
                icon
                color="green"
                labelPosition="left"
                onClick={onSaveClick}
              >
                <Icon name="check" />
                {props.customSubmitText || intl.formatMessage(appMessages.save)}
              </Button>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormActions>
  );
}

FormActionsBar.propTypes = {
  showDeleteButton: PropTypes.bool,
  readOnly: PropTypes.bool,
  isValid: PropTypes.bool,
  isDeleting: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  intl: intlShape.isRequired,
  onDelete: PropTypes.func,
  close: PropTypes.func,
  onSaveClick: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  moreButtons: PropTypes.any,
  customSubmitText: PropTypes.string,
};

export default injectIntl(FormActionsBar);
