/* eslint-disable react/no-did-update-set-state */
/**
 *
 * TechnicalIntervention
 *
 */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, Grid, TextArea, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import FormikSelect from '../../../FormikSelect';
import FormikField from '../../../FormikField';
import { PaddedColumn } from '../../../Layout';
// import staffService from '../../../../shared/services/staff';
import Modal from '../../../Modal';
import StateHistoryModal from '../formParts/stateHistoryModal';
// import FormikDateTime from '../../../FormikDateTime';
import { CanI } from '../../../../containers/Can/utils';

// Services
import contractService from '../../../../shared/services/contract';
import DestinationService from '../../../../shared/services/destination';
import StaffWorkOrderStateService from '../../../../shared/services/staffworkorderstate';
import NoteService from '../../../../shared/services/note';

import messages from '../messages';
import validation from '../validation';
import UploadPhotoSection from '../formParts/photoUpload';

export class WorkOrder extends React.Component {
  state = {
    modalOpened: false,
    states: [],
  };

  componentDidMount() {
    StaffWorkOrderStateService.getAvailableStates().then(response => {
      const responseStates = response.map(item => ({
        label: item,
        value: item,
      }));
      this.setState({
        states: responseStates,
      });
    });
    const { values } = this.props;
    if (!values.id) {
      values.state = {
        label: 'Pianificato',
        value: 'Pianificato',
      };
      // values.plannedDate = moment();
      values.startDate = moment();
      values.endDate = moment();
    }
  }

  componentDidUpdate(prevProps) {
    const { values } = this.props;
    if (values.state !== prevProps.values.state) {
      StaffWorkOrderStateService.getAvailableStates(values.state.value).then(
        response => {
          const responseStates = response.map(item => ({
            label: item,
            value: item,
          }));
          this.setState({
            states: _.union([values.state], responseStates),
          });
        },
      );
    }

    if (!values.destination && this.props.history.location.destination) {
      this.props.setFieldValue(
        'destination',
        this.props.history.location.destination,
      );
    }
    if (!values.contract && this.props.history.location.contract) {
      this.props.setFieldValue(
        'contract',
        this.props.history.location.contract,
      );
    }
    if (
      !values.destinationContact &&
      this.props.history.location.destinationContact
    ) {
      this.props.setFieldValue(
        'destinationContact',
        this.props.history.location.destinationContact,
      );
    }
  }

  canEdit = item => {
    // const isAdmin = CanManage(this.props.user);
    const isAdmin = false;
    const canEdit = CanI('editWorkOrder', 'custom');
    if (item.state === "Chiuso" && !isAdmin) return false;
    if (!canEdit) return false;
    return true;
  }

  render() {
    const { isNew, handleSubmit, setFieldValue, values } = this.props;
    const { states } = this.state;
    const canEdit = this.canEdit(values);

    const onCloseModal = () => {
      this.setState({ modalOpened: false });
    };

    return (
      <>
        <Form onSubmit={handleSubmit}>
          <Grid style={{ paddingBottom: 15 }}>
            <Grid.Row columns={4}>
              <PaddedColumn>
                <Grid.Column>
                  <FormikSelect
                    {...this.props}
                    name="state"
                    label={this.context.intl.formatMessage(messages.state)}
                    options={states}
                    readOnly={!canEdit}
                    defaultValue={values.state}
                    value={values.state}
                    onChange={target => {
                      setFieldValue('state', {
                        value: target.value,
                        label: target.value,
                      });
                    }}
                  />
                </Grid.Column>
              </PaddedColumn>
              <Grid.Column>
                <Button
                  {...this.props}
                  disabled={isNew || !canEdit}
                  icon
                  labelPosition="left"
                  primary
                  type="button"
                  style={{ marginTop: '23px' }}
                  onClick={() => this.setState({ modalOpened: true })}
                >
                  <Icon name="list" />
                  {this.context.intl.formatMessage(messages.stateHistory)}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              {!isNew && (
                <PaddedColumn>
                  <Grid.Column>
                    <SimpleFormikField
                      {...this.props}
                      name="number"
                      readOnly
                      label={this.context.intl.formatMessage(messages.number)}
                      placeholder={this.context.intl.formatMessage(
                        messages.number,
                      )}
                    />
                  </Grid.Column>
                </PaddedColumn>
              )}
              <PaddedColumn>
                <Grid.Column>
                  <FormikAsyncSelect
                    {...this.props}
                    name="noteReason"
                    readOnly={!canEdit}
                    label={this.context.intl.formatMessage(
                      messages.note_reason,
                    )}
                    loadOptions={(searchText, callback) =>
                      NoteService.getAsyncNoteReasonOptions(
                        searchText,
                        callback,
                        true,
                      )
                    }
                  />
                </Grid.Column>
              </PaddedColumn>
              <PaddedColumn>
                <Grid.Column>
                  <FormikAsyncSelect
                    name="destination"
                    required
                    readOnly
                    label={this.context.intl.formatMessage(messages.customer)}
                    loadOptions={(searchText, callback) =>
                      DestinationService.getOptions(
                        searchText,
                        callback,
                        false,
                        true,
                      )
                    }
                    onChange={data => {
                      setFieldValue('destination', {
                        label: data.label,
                        value: data.value,
                      });
                      setFieldValue('reportSigner', data.contactPerson);
                      setFieldValue('reportEmail', data.email);
                      setFieldValue('destinationContact', null);
                    }}
                  />
                </Grid.Column>
              </PaddedColumn>
              <PaddedColumn>
                <Grid.Column>
                  <SimpleFormikField
                    {...this.props}
                    name="referent"
                    readOnly={!canEdit}
                    label={this.context.intl.formatMessage(messages.referent)}
                    placeholder={this.context.intl.formatMessage(
                      messages.referent,
                    )}
                  />
                </Grid.Column>
              </PaddedColumn>
              <PaddedColumn>
                <Grid.Column>
                  <FormikAsyncSelect
                    {...this.props}
                    name="contract"
                    readOnly={!canEdit}
                    defaultValue={
                      this.props.history.location.contract
                        ? this.props.history.location.contract
                        : ''
                    }
                    label={this.context.intl.formatMessage(messages.contract)}
                    loadOptions={(searchText, callback) =>
                      contractService.getOptions(searchText, callback)
                    }
                  />
                </Grid.Column>
              </PaddedColumn>
            </Grid.Row>
            <Grid.Row>
              <PaddedColumn>
                <UploadPhotoSection
                  {...this.props}
                  entityId={values.id}
                  isNew={this.props.isNew}
                />
              </PaddedColumn>
            </Grid.Row>
            <Grid.Row columns={2}>
              <PaddedColumn>
                <Grid.Column>
                  <FormikField
                    {...this.props}
                    name="note"
                    readOnly={!canEdit}
                    label={this.context.intl.formatMessage(messages.note)}
                  >
                    <TextArea
                      onChange={(e, data) => setFieldValue('note', data.value)}
                      value={_.get(values, 'note', '')}
                      placeholder={this.context.intl.formatMessage(
                        messages.note_placeholder,
                      )}
                    />
                  </FormikField>
                </Grid.Column>
              </PaddedColumn>
              <PaddedColumn>
                <Grid.Column>
                  <FormikField
                    {...this.props}
                    name="internalNote"
                    readOnly={!canEdit}
                    label={this.context.intl.formatMessage(
                      messages.internal_note,
                    )}
                  >
                    <TextArea
                      onChange={(e, data) =>
                        setFieldValue('internalNote', data.value)
                      }
                      value={_.get(values, 'internalNote', '')}
                      placeholder={this.context.intl.formatMessage(
                        messages.internal_note,
                      )}
                    />
                  </FormikField>
                </Grid.Column>
              </PaddedColumn>
            </Grid.Row>
          </Grid>
        </Form>
        <Modal
          open={this.state.modalOpened}
          onClose={onCloseModal}
          title={
            <h2>{this.context.intl.formatMessage(messages.stateHistory)}</h2>
          }
          size="mini"
          style={{ width: 650 }}
          scrolling
        >
          <StateHistoryModal
            onClose={onCloseModal}
            entityId={this.props.values.id}
            entity="staff-workorder"
          />
        </Modal>
      </>
    );
  }
}

WorkOrder.defaultProps = {
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
};

WorkOrder.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  history: PropTypes.object,
  editRoute: PropTypes.string,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
};

WorkOrder.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default WorkOrder;

export { validation };
