/* eslint-disable arrow-body-style */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from 'react';
import SimpleFormikField from 'novigo-simple-formik-field';
import _ from 'lodash';
import { Async as AsyncSelect } from 'react-select';
import PropTypes from 'prop-types';
import qs from 'qs';
import request from '../../shared/services/request';
import { API } from '../../global-constants';

const searchByName = (searchText, entityName) =>
  request({
    baseURL: API.BASE_URL,
    url: `/${entityName}`,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'displayName',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = entityName => (inputValue, callback) =>
  searchByName(inputValue, entityName).then(results => {
    callback(
      _.get(results, `data._embedded.${entityName}`, []).map(provider => ({
        value: provider.id,
        label: provider.displayName,
      })),
    );
  });

const getLoader = (loadOptions, entityName) =>
  loadOptions || getOptions(entityName);

function FormikAsyncSelect(props) {
  const { loadOptions, entityName } = props;

  /**
   * if load optins is not set. Create on your own.
   * But in this case entityName should be passed
   */
  if (!loadOptions && !entityName) {
    // eslint-disable-next-line no-console
    console.error(
      'No loader and no entity name is setted, I cannot generate loader automatically',
    );
    return null;
  }


  let loader = getLoader(loadOptions, entityName);
  loader = React.useCallback(
    _.debounce((inputText, callback) => {
      loadOptions(inputText, callback);
    }, 1000),
    []
  );

  return (
    <SimpleFormikField name={props.name} label={props.label} {...props}>
      {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
        <AsyncSelect
          cacheOptions={props.cacheOptions}
          defaultOptions={_.get(props, 'defaultOptions', true)}
          isDisabled={props.readOnly}
          isClearable={props.isClearable}
          name={props.name}
          value={props.initialValue || value}
          placeholder={props.placeholder || false}
          onChange={(data => {
            if (props.onChange) props.onChange(data);
            setFieldValue(props.name, data)
          })}
          onBlur={props.onBlur || (() => setFieldTouched(props.name, true))}
          loadOptions={loader}
          styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 }),
          }}
          {...props.asyncSelectProps}
        />
      )}
    </SimpleFormikField>
  );
}

FormikAsyncSelect.propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  loadOptions: PropTypes.func,
  defaultOptions: PropTypes.array,
  asyncSelectProps: PropTypes.object,
  isClearable: PropTypes.bool,
  cacheOptions: PropTypes.bool,
};

FormikAsyncSelect.defaultProps = {
  cacheOptions: false,
};

export default FormikAsyncSelect;
