import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { intlShape } from 'react-intl';
import {
  Accordion,
  Button,
  Form,
  Grid,
  Icon,
  Label,
  Pagination,
} from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import { Formik, getIn } from 'formik';

import { WorkorderPlannedColumns } from './tableHelper';
import FormikAsyncSelect from '../../../FormikAsyncSelect';
import NoteService from '../../../../shared/services/note';
import DestinationService from '../../../../shared/services/destination';
import StaffService from '../../../../shared/services/staff';
import StaffWorkorderService from '../../../../shared/services/staffworkorder';
import { WorkorderAssignmentContext } from '../context';

import messages from '../messages';
import appMessages from '../../../../containers/App/messages';
import FilterBox from '../../../FilterBox';

const INITIAL_TABLE_VALUES = {
  data: [],
  loading: false,
  enable: true,
  page: 1,
  pageCount: 1,
  pageSize: 5,
  totalItems: 0,
  orderBy: 'plannedDate',
  orderDirection: 'asc',
  order: [
    {
      type: 'field',
      field: 'plannedDate',
      direction: 'asc',
    },
  ],
  filters: [
    {
      type: 'innerjoin',
      field: 'noteReason',
      alias: 'nr',
    },
    {
      type: 'innerjoin',
      field: 'destination',
      alias: 'd',
    },
    {
      type: 'innerjoin',
      field: 'staff',
      alias: 's',
    },
    {
      type: 'eq',
      field: 'state',
      value: 'Pianificato',
    },
  ],
  filterLabels: [],
};

/**
 * The component to handle the right part of the assign operator section.
 */
export const WorkorderPlannedSelectionPart = (props, context) => {
  const { intl } = context;
  const { reload, onStaffChanged } = props;

  /**
   * Get the info from the current workorder assignment context.
   */
  const {
    setSelectedStaff,
    selectedWorkorderPlanned,
    setSelectedWorkorderPlanned,
  } = useContext(WorkorderAssignmentContext);

  /**
   * That's a react-query like way to handle things.
   * Data, filters, pagination and order are manage in the same state.
   * It may be translated as a reducer, but I took it simple.
   */
  const [table, setTable] = useState(INITIAL_TABLE_VALUES);

  /**
   * That's the effect to trigger every time the table data needs to be refreshed.
   *
   */
  useEffect(
    () => {
      if (table.enable) {
        setTable({ ...table, loading: true });
        StaffWorkorderService.getAll(table)
          .then(({ data }) => {
            // Reconcile the remote data with the select attribute (used to mark a workorder as selected or not).
            const newData = _.get(data, '_embedded.staff-workorder', []).map(
              item => ({
                ...item,
                select: selectedWorkorderPlanned.includes(item.id),
              }),
            );

            setTable({
              ...table,
              data: newData,
              loading: false,
              page: data.page > 0 ? data.page : 1,
              pageCount: data.page_count,
              pageSize: data.page_size,
              totalItems: data.total_items,
            });
          })
          .catch(() => setTable({ ...table, loading: false }));
      }
    },
    [table.enable, table.page, table.order, table.filters],
  );

  /**
   * Every time the table data change, then update the list of selected workorders.
   * The upper context will take care of it.
   */
  useEffect(
    () => {
      setSelectedWorkorderPlanned(
        // Get ID the selected items.
        table.data.filter(item => item.select).map(item => item.id),
      );
    },
    [table.data],
  );

  /**
   * Select a row.
   * @param {Object} row the row
   */
  const onSelect = row => {
    const rowIndex = table.data.findIndex(i => i.id === row.id);
    const newData = [...table.data];
    newData[rowIndex] = { ...newData[rowIndex], select: true };

    setTable({ ...table, data: newData });
  };

  /**
   * Deselect a row.
   * @param {Object} row the row
   */
  const onDeselect = row => {
    const rowIndex = table.data.findIndex(i => i.id === row.id);
    const newData = [...table.data];
    newData[rowIndex] = { ...newData[rowIndex], select: false };

    setTable({ ...table, data: newData });
  };

  /**
   * Select all the items.
   */
  const onSelectAll = () => {
    const newData = [...table.data].map(item => ({
      ...item,
      select: true,
    }));
    setTable({ ...table, data: newData });
  };

  /**
   * Deselect all the items.
   */
  const onDeselectAll = () => {
    const newData = [...table.data].map(item => ({
      ...item,
      select: false,
    }));
    setTable({ ...table, data: newData });
  };

  /**
   * Sort the data when clicked on the column header.
   */
  const onSort = (field, direction) => {
    const order = [];

    if (direction === 'none') {
      setTable({
        ...table,
        order,
      });
      return;
    }

    if (field === 'description') {
      order.push({
        type: 'field',
        field: 'description',
        alias: 'nr',
        direction,
      });
    }

    if (field === 'businessName') {
      order.push({
        type: 'field',
        field: 'businessName',
        alias: 'd',
        direction,
      });
    }

    if (field === 'displayName') {
      order.push({
        type: 'field',
        field: 'displayName',
        alias: 's',
        direction,
      });
    }

    if (order.length === 0) {
      order.push({
        type: 'field',
        field,
        direction,
      });
    }

    setTable({
      ...table,
      order,
    });
  };

  /**
   * Method called when the search button is pressed.
   * It changes the internal filters and trigger a data refresh.
   */
  const onSearch = (values, actions, stopPropagate) => {
    const filterLabels = [];
    const filters = [
      {
        type: 'innerjoin',
        field: 'noteReason',
        alias: 'nr',
      },
      {
        type: 'innerjoin',
        field: 'destination',
        alias: 'd',
      },
      {
        type: 'innerjoin',
        field: 'staff',
        alias: 's',
      },
      {
        type: 'eq',
        field: 'state',
        value: 'Pianificato',
      },
    ];

    if (values.noteReason) {
      filterLabels.push({
        key: 'noteReason',
        name: intl.formatMessage(messages.typeODL),
        value: values.noteReason.label,
        filterField: 'noteReason',
      });
      filters.push({
        type: 'eq',
        field: 'noteReason',
        value: values.noteReason.value,
      });
    }
    if (values.destination) {
      filterLabels.push({
        key: 'destination',
        name: intl.formatMessage(messages.destination),
        value: values.destination.label,
        filterField: 'destination',
      });
      filters.push({
        type: 'eq',
        field: 'destination',
        value: values.destination.value,
      });
    }
    if (values.staff) {
      filterLabels.push({
        key: 'staff',
        name: intl.formatMessage(messages.staff),
        value: values.staff.label,
        filterField: 'staff',
      });

      setSelectedStaff(values.staff.value);

      filters.push({
        type: 'eq',
        field: 'staff',
        value: values.staff.value,
      });
    }

    if (!values.staff) {
      setSelectedStaff(null);
    }

    setTable({ ...table, page: 1, filters, filterLabels, enable: true });
    actions.setSubmitting(false);

    // Push the event to the parent
    if (!stopPropagate) {
      props.onSearch();
    }
  };

  const onRemoveFilter = fieldName => {
    const filters = [...table.filters].filter(f => f.field !== fieldName);
    const filterLabels = [...table.filterLabels].filter(
      f => f.filterField !== fieldName,
    );

    setTable({ ...table, page: 1, filters, filterLabels });
  };

  const onReset = () => {
    setTable(INITIAL_TABLE_VALUES);
    setSelectedWorkorderPlanned([]);
  };

  return (
    <Grid>
      <Grid.Row columns={1}>
        <Grid.Column width={16}>
          <WorkorderPlannedSearch
            active
            filterLabels={table.filterLabels}
            onSearch={onSearch}
            onReset={onReset}
            onRemoveFilter={onRemoveFilter}
            forceSearch={reload}
            onStaffChanged={onStaffChanged}
          />
          <div style={{ marginTop: '50px' }}>
            <Table
              elements={table.data}
              columns={WorkorderPlannedColumns(intl)}
              isLoading={table.loading}
              canSelect={{
                active: true,
                selectAll: true,
                onSelect,
                onDeselect,
                onSelectAll,
                onDeselectAll,
                isSelectedProperty: 'select',
              }}
              canSort={{
                active: true,
                onSort,
              }}
              canPaginate={{
                active: true,
                render: (
                  <Pagination
                    activePage={table.page}
                    onPageChange={(_event, { activePage }) => {
                      setTable({ ...table, page: activePage || 1 });
                    }}
                    size="mini"
                    totalPages={table.pageCount}
                  />
                ),
              }}
            />
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

WorkorderPlannedSelectionPart.propTypes = {
  /**
   * An attribute to trigger the reload.
   */
  reload: PropTypes.bool,

  /**
   * The search callback.
   */
  onSearch: PropTypes.func,

  /**
   * The callback for staff change.
   */
  onStaffChanged: PropTypes.func,
};

WorkorderPlannedSelectionPart.contextTypes = {
  intl: intlShape,
};

export default WorkorderPlannedSelectionPart;

/**
 * TODO: Check if it can be replaced with the BaseSearch component.
 */
const WorkorderPlannedSearch = (props, context) => {
  /**
   * The intl object.
   */
  const { intl } = context;

  /**
   * The flag to control the status of the accordion.
   */
  const [active, setActive] = useState(props.active);

  const [stopSearchPropagate, setStopSearchPropagate] = useState(false);

  const [formikRef, setFormikRef] = useState();

  /**
   * Active/disactive the accordion.
   * @returns
   */
  const toogleActive = () => setActive(!active);

  useEffect(
    () => {
      setStopSearchPropagate(true);
    },
    [props.forceSearch],
  );

  useEffect(
    () => {
      if (formikRef) {
        formikRef.submitForm();
      }
    },
    [props.forceSearch],
  );

  const onSearchSubmit = (values, actions, stopPropagate) => {
    props.onSearch(values, actions, stopPropagate);
    if (stopSearchPropagate) {
      setStopSearchPropagate(false);
    }
  };

  return (
    <>
      <Formik
        ref={ref => setFormikRef(ref)}
        initialValues={{ noteReason: null, destination: null, staff: null }}
        onSubmit={onSearchSubmit}
        onReset={(_values, formikBag) => {
          formikBag.setValues({
            noteReason: null,
            destination: null,
            staff: null,
          });
          props.onReset();
        }}
      >
        {({
          isSubmitting,
          values,
          initialValues,
          handleSubmit,
          handleReset,
          submitForm,
          setFieldValue,
        }) => (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Accordion fluid styled>
                  <Accordion.Title
                    onClick={toogleActive}
                    active={active}
                    style={{
                      backgroundColor: '#5ca34d',
                      color: 'white',
                      height: '20px',
                      padding: '1px',
                      fontSize: '12px',
                    }}
                  >
                    <Icon name="dropdown" />
                    {intl.formatMessage(appMessages.search)}
                    <Label
                      style={{
                        color: '#5ca34d',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        display: 'inline-block',
                        float: 'right',
                        marginTop: '2px',
                        marginRight: '2px',
                        fontSize: '7px',
                        textTransform: 'uppercase',
                      }}
                    >
                      {Object.values(values).filter(v => v).length}
                    </Label>
                  </Accordion.Title>
                  <Accordion.Content active={active}>
                    <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
                      <Grid>
                        <Grid.Row columns={4}>
                          <Grid.Column>
                            <FormikAsyncSelect
                              name="noteReason"
                              label={intl.formatMessage(messages.typeODL)}
                              loadOptions={
                                NoteService.getAsyncNoteReasonOptions
                              }
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <FormikAsyncSelect
                              name="destination"
                              label={intl.formatMessage(messages.customer)}
                              loadOptions={DestinationService.getOptions}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <FormikAsyncSelect
                              name="staff"
                              label={intl.formatMessage(messages.technical)}
                              loadOptions={StaffService.getTechnicianOptions}
                              required
                              onChange={data => {
                                if (props.onStaffChanged) {
                                  props.onStaffChanged();
                                }
                                setFieldValue('staff', data);
                              }}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <Grid.Row columns={4}>
                        <Grid>
                          <Grid.Row textAlign="right">
                            <Grid.Column>
                              <Button
                                type="button"
                                icon
                                labelPosition="left"
                                disabled={isSubmitting}
                                onClick={handleReset}
                              >
                                <Icon name="times" />
                                {intl.formatMessage(appMessages.reset)}
                              </Button>
                              <Button
                                loading={isSubmitting}
                                disabled={isSubmitting}
                                type="button"
                                onClick={submitForm}
                                icon
                                color="blue"
                                labelPosition="left"
                              >
                                <Icon name="search" />
                                {intl.formatMessage(appMessages.search)}
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Row>
                    </Form>
                  </Accordion.Content>
                </Accordion>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <FilterBox
                  filterLabels={props.filterLabels}
                  removeFilter={filterName => {
                    setFieldValue(
                      filterName,
                      getIn(initialValues, filterName),
                      true,
                    );
                    props.onRemoveFilter(filterName);
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Formik>
    </>
  );
};

WorkorderPlannedSearch.propTypes = {
  /**
   * The initial status of the search bar.
   */
  active: PropTypes.bool,

  /**
   * The search function.
   */
  onSearch: PropTypes.func,

  /**
   * The array of the active filters.
   */
  filterLabels: PropTypes.array,

  /**
   * A callback when a filter is removed.
   */
  onRemoveFilter: PropTypes.func,

  /**
   * A callback when the reset button is pressed.
   */
  onReset: PropTypes.func,
};

WorkorderPlannedSearch.contextTypes = {
  intl: intlShape,
};
