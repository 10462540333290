import React from 'react';
import PropTypes from 'prop-types';
import SideBarRight from '../../../components/SideBarRight';

const SideBarOffer = props => {
  const { stylesSetting, items } = props;

  return (
    <SideBarRight
      collapsed={false}
      items={items}
      openMenu
      stylesSetting={stylesSetting}
    />
  );
};

SideBarOffer.propTypes = {
  items: PropTypes.object,
  stylesSetting: PropTypes.object,
};

export default SideBarOffer;
