import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
// import Styled from 'styled-components';
import BaseForm from './baseForm';
import { addNotification } from '../../../../utils/notification';
import { ModalFakeActions } from '../../../Layout';
import appMessages from '../../../../containers/App/messages';
import getError from '../../../../utils/getError';

const initialValues = newValues => {
  const { _embedded } = newValues;
  return {
    id: _.get(newValues, 'id', null),
    noteText: _.get(newValues, 'noteText', ''),
    noteNumber: _.get(newValues, 'noteNumber', ''),
    noteType: {
      value: _.get(_embedded, 'noteType.id', ''),
      label: _.get(_embedded, 'noteType.description', ''),
    },
    noteReason: {
      value: _.get(_embedded, 'noteReason.id', ''),
      label: _.get(_embedded, 'noteReason.description', ''),
      isPestControl: _.get(_embedded, 'noteReason.isPestControl', ''),
    },
    mailItems: _.get(newValues, 'mailItems', []).map(mailItem => ({
      value: mailItem.id,
      label: mailItem.email,
    })),
    talkedTo: _.get(newValues, 'talkedTo', ''),
  };
};

export default class CustomerNote extends Component {
  static propTypes = {
    entityId: PropTypes.string,
    noteId: PropTypes.string,
    staffNoteService: PropTypes.object,
    entity: PropTypes.string,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      noteText: _.get(values, 'noteText', ''),
      noteNumber: _.get(values, 'noteNumber', ''),
      noteType: _.get(values, 'noteType.value', ''),
      noteReason: _.get(values, 'noteReason.value', ''),
      talkedTo: _.get(values, 'talkedTo', ''),
      mailItems: _.get(values, 'mailItems', []).map(mailItem => mailItem.value),
      [this.props.entity]: this.props.entityId,
    };

    const method = payload.id
      ? this.props.staffNoteService.update
      : this.props.staffNoteService.create;

    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: null,
          message: 'Nota salvata con successo',
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setSubmitting(false);
        setErrors(getError(err));
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={initialValues(this.props.initialValues || {})}
            onSubmit={this.onSubmit}
            render={props => (
              <BaseForm
                {...props}
                noteTypes={this.props.noteTypes}
                noteReasons={this.props.noteReasons}
                usersList={this.props.usersList}
              />
            )}
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.close}
            >
              <Icon name="remove" />
              {this.context.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Button
              type="button"
              icon
              labelPosition="left"
              color="green"
              onClick={this.submit}
            >
              <Icon name="checkmark" />
              {this.context.intl.formatMessage(appMessages.save)}
            </Button>
          </div>
        </ModalFakeActions>
      </Grid>
    );
  }
}

CustomerNote.contextTypes = {
  intl: PropTypes.object.isRequired,
};
