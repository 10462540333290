import qs from 'qs';
import _ from 'lodash';
import request from '../request';
import { API } from '../../../global-constants';

const getOptions = (value, callback) =>
  request({
    baseURL: API.BASE_URL,
    url: API.DESTINATION_GROUP,
    method: 'GET',
    params: {
      page: 1,
      pageSize: 8,
      filter: [
        value && {
          field: 'code',
          type: 'like',
          value: `%${value}%`,
          where: 'or',
        },
        value && {
          field: 'description',
          type: 'like',
          value: `%${value}%`,
          where: 'or',
        },
      ].filter(f => f),
      'order-by': [
        {
          type: 'field',
          field: 'description',
          direction: 'asc',
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  }).then(response =>
    callback(
      _.get(response, 'data._embedded.destination-group', []).map(
        ({ id, description }) => ({
          value: id,
          label: description,
        }),
      ),
    ),
  );

const DestinationGroupService = {
  getOptions,
};

export default DestinationGroupService;
