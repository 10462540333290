import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Form, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import validation from './validation';
import { FormTab } from '../../Layout';
import FormActionsBar from '../../FormActionsBar';
import messages from './messages';
import WorkOrderTab from './tabs/workOrder';
import TaskOrderTab from './tabs/taskOrder';
import IndicatorsTab from './tabs/indicatorsTab';
import { handleHeaderLink } from './function';

const TechnicalInterventionSubHeader = styled.div`
  width: fit-content;
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  padding-bottom: 5px;
  & > div {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    & > .date-label {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
    }
    & > .date-content {
      font-weight: bold;
      font-size: 13px;
    }
  }
`;

export class WorkOrderMask extends React.Component {
  state = {
    activeIndex: 0,
  };

  componentWillUnmount() {
    this.props.uploadImage(null);
  }

  handleTabChange = (e, { activeIndex }) => this.setState({ activeIndex });

  render() {
    const {
      handleSubmit,
      readOnly = false,
      isSubmitting = false,
      onDelete = false,
      isDeleting = false,
      showDeleteButton = false,
      showEditButton = false,
    } = this.props;

    const showMonitorTabs = _.get(
      this.props.values,
      'noteReason.isPestControl',
      null,
    );

    let panes = [
      {
        menuItem: this.context.intl.formatMessage(messages.tab_work_order),
        render: () => (
          <FormTab.Pane>
            <WorkOrderTab {...this.props} />
          </FormTab.Pane>
        ),
      },
      {
        menuItem: this.context.intl.formatMessage(messages.tab_task_order),
        render: () => (
          <FormTab.Pane>
            <TaskOrderTab
              showInsectsSection={showMonitorTabs}
              {...this.props}
            />
          </FormTab.Pane>
        ),
      },
    ];

    if (showMonitorTabs) {
      panes = _.concat(panes, [
        {
          menuItem: this.context.intl.formatMessage(messages.tab_deratization),
          render: () => (
            <FormTab.Pane>
              <IndicatorsTab
                {...this.props}
                key="tab_deratization"
                tabIdentifier="tab_deratization"
                showSpeciesField={false}
              />
            </FormTab.Pane>
          ),
        },
        {
          menuItem: this.context.intl.formatMessage(
            messages.tab_monitor_cockroach,
          ),
          render: () => (
            <FormTab.Pane>
              <IndicatorsTab
                {...this.props}
                key="tab_monitor_cockroach"
                tabIdentifier="tab_monitor_cockroach"
                showToolsField={false}
              />
            </FormTab.Pane>
          ),
        },
        {
          menuItem: this.context.intl.formatMessage(
            messages.tab_monitor_flying,
          ),
          render: () => (
            <FormTab.Pane>
              <IndicatorsTab
                {...this.props}
                key="tab_monitor_flying"
                tabIdentifier="tab_monitor_flying"
                showToolsField={false}
              />
            </FormTab.Pane>
          ),
        },
        {
          menuItem: this.context.intl.formatMessage(messages.tab_monitor_goods),
          render: () => (
            <FormTab.Pane>
              <IndicatorsTab
                {...this.props}
                key="tab_monitor_goods"
                tabIdentifier="tab_monitor_goods"
                showToolsField={false}
              />
            </FormTab.Pane>
          ),
        },
        {
          menuItem: this.context.intl.formatMessage(messages.tab_disinfection),
          render: () => (
            <FormTab.Pane>
              <IndicatorsTab
                {...this.props}
                key="tab_disinfection"
                tabIdentifier="tab_disinfection"
                showToolsField={false}
              />
            </FormTab.Pane>
          ),
        },
      ]);
    }

    return (
      <>
        <div>
          <Header />
          <TechnicalInterventionSubHeader>
            <div>
              <span className="date-content">
                {handleHeaderLink(this.props.values)}
              </span>
              <span className="date-label" />
            </div>
          </TechnicalInterventionSubHeader>
        </div>
        <Form onSubmit={handleSubmit}>
          <FormTab
            panes={panes}
            activeIndex={this.state.activeIndex}
            onTabChange={this.handleTabChange}
            className="formTabs"
          />
          <FormActionsBar
            showEditButton={showEditButton}
            showDeleteButton={showDeleteButton}
            readOnly={readOnly}
            isValid={
              this.props.values.destination &&
              this.props.values.destination.value
            }
            isDeleting={isDeleting}
            isSubmitting={isSubmitting}
            editRoute={this.props.editRoute}
            onDelete={onDelete}
            history={this.props.history}
          />
        </Form>
      </>
    );
  }
}

WorkOrderMask.defaultProps = {
  errors: [],
  isValid: false,
  values: {},
  onDelete: null,
  isDeleting: false,
  showDeleteButton: false,
  showEditButton: true,
};

WorkOrderMask.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
  values: PropTypes.object,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showEditButton: PropTypes.bool,
};

WorkOrderMask.contextTypes = {
  intl: PropTypes.object.isRequired,
};

export default WorkOrderMask;

export { validation };
