/* eslint-disable indent */
import moment from 'moment';
import _ from 'lodash';

const handleDate = date => {
  if (date) {
    return moment(date).format('YYYY-MM-DD');
  }
  return null;
};

export const handleAddParams = values => {
  const referenceDate = handleDate(values.referenceDate);
  const invoiceDateFrom = handleDate(values.invoiceDateFrom);
  const invoiceDateTo = handleDate(values.invoiceDateTo);

  const agent = _.get(values, 'agent.value', null);
  const customer = _.get(values, 'customer.value', null);

  const {
    allSelected,
    selectedRows,
    deselectedRows = [],
    filter = 'ALL',
  } = values;

  const payload = {
    included: allSelected ? [] : selectedRows,
    excluded: allSelected ? deselectedRows : [],
    referenceDate,
    invoiceDateFrom,
    invoiceDateTo,
    agent,
    customer,
    customerFilter: filter ? filter.toLowerCase() : 'all',
  };

  return payload;
};
