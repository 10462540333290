/*
 * TopBar Messages
 *
 * This contains all the text for the TopBar component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Ticket';

export default defineMessages({
  need_help: {
    id: `${scope}.need_help`,
    defaultMessage: 'Need help ?',
  },
});
