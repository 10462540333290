/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Table, Label } from 'semantic-ui-react';
import { formatSelectedNoteEntity, handleManage } from '../constants';
import '../css/notes.css';
import ListActions from './ListActions';
import TableFooter from './TableFooter';
import messages from '../../../components/Forms/Anagrafica/messages';
import PermissionManager from '../../../components/Permission';
const NoteTable = ({
  data,
  intl,
  setSelectedEntity,
  openNotesModal,
  setReadOnlyMode,
  openConfirmModal,
  onExport,
  onChangePage,
  onChangePageSize,
  executeNote,
  openStaffWorkorder,
  isExecutingNote,
}) => {
  const { page = 1, page_count = 1, page_size = 10 } = data;

  const notes = _.get(data, '_embedded.destination-note', []);
  const notesByDate = notes.map(el => {
    const plannedDate =
      !_.isEmpty(el.staffWorkorder) && !_.isEmpty(el.staffWorkorder.plannedDate)
        ? moment(el.staffWorkorder.plannedDate.date).format('DD-MM-YYYY')
        : null;
    const expireDate = !_.isEmpty(el.expireDate)
      ? moment(el.expireDate.date).format('DD-MM-YYYY')
      : null;
    const groupDate = plannedDate || expireDate;

    return {
      ...el,
      createdAt: {
        date: moment(el.createdAt.date).format('DD-MM-YYYY'),
      },
      plannedDate: {
        date: plannedDate,
      },
      expireDate: {
        date: expireDate,
      },
      groupDate: {
        date: groupDate,
      },
    };
  });

  // const groupedByDate = _.groupBy(notesByDate, 'createdAt.date');
  const groupedByDate = _.groupBy(notesByDate, 'groupDate.date');

  /**
   * Check if the current note has been executed.
   * If not then check if user has permission to execute it and if the creation date is today.
   * @param {object} item
   * @returns boolean
   */
  const handleCanExecute = item => {
    if (
      !item.executedAt &&
      !item.closedNote &&
      PermissionManager.CanI('edit', 'note')
    ) {
      return true;
    }
    return false;
  };

  const handleCanRedo = item => {
    if (
      item.executedAt &&
      !item.closedNote &&
      PermissionManager.CanI('edit', 'note')
    ) {
      return true;
    }
    return false;
  };

  const header = (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          {intl.formatMessage(messages.noteType)}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {intl.formatMessage(messages.expire_date)}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {intl.formatMessage(messages.creation_date)}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {intl.formatMessage(messages.noteReason)}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {intl.formatMessage(messages.customerCode)}
        </Table.HeaderCell>
        <Table.HeaderCell>
          {intl.formatMessage(messages.customer)}
        </Table.HeaderCell>
        <Table.HeaderCell>{intl.formatMessage(messages.note)}</Table.HeaderCell>
        <Table.HeaderCell>
          {intl.formatMessage(messages.assignee)}
        </Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
  );

  const renderBody = item => {
    const plannedDate = item.plannedDate.date;
    const expireDate = item.expireDate.date;
    const creationDate = item.createdAt.date;

    return (
      <Table.Row>
        <Table.Cell width={2}>
          <div>
            <Label
              ribbon
              style={{ marginBottom: 5 }}
              content={intl.formatMessage(
                messages[
                  item.closeNote !== null
                    ? 'closure'
                    : item.executedAt !== null
                      ? 'done'
                      : 'to_be_done'
                ],
              )}
              color={
                item.closeNote !== null
                  ? 'teal'
                  : item.executedAt !== null
                    ? 'green'
                    : 'red'
              }
            />
            <br />
            {_.get(item, 'noteType.description', '--')}
          </div>
        </Table.Cell>
        <Table.Cell width={2}>
          {plannedDate || expireDate || creationDate || '--'}
        </Table.Cell>
        <Table.Cell width={2}>{creationDate || '--'}</Table.Cell>
        <Table.Cell width={2}>
          {_.get(item, 'noteReason.description', '--')}
        </Table.Cell>
        <Table.Cell width={2}>
          {_.get(
            item,
            'destination.code',
            _.get(item, 'destination.customer.code', '--'),
          )}
        </Table.Cell>
        <Table.Cell width={2}>
          {_.get(item, 'destination.businessName', '--')}
        </Table.Cell>
        <Table.Cell width={2}>{_.get(item, 'noteText', '--')}</Table.Cell>
        <Table.Cell width={2}>{_.get(item, 'assignee.label', '--')}</Table.Cell>

        <Table.Cell width={2}>
          <div style={{ width: '100%', display: 'flex' }}>
            <ListActions
              isExecutingNote={isExecutingNote}
              canView={PermissionManager.CanI('read', 'note')}
              canEdit={PermissionManager.CanI('edit', 'note')}
              canEditWorkorder={
                PermissionManager.CanI('edit', 'staffWorkorder') &&
                item.staffWorkorder
              }
              canExecute={handleCanExecute(item)}
              isToDo={handleCanRedo(item)}
              canDelete={PermissionManager.CanI('remove', 'note')}
              canManage={PermissionManager.CanI('edit', 'note')}
              onExecute={isRedo => {
                setSelectedEntity(formatSelectedNoteEntity(item));
                executeNote(item, isRedo);
              }}
              onView={() => {
                setReadOnlyMode(true);
                openNotesModal();
                setSelectedEntity(formatSelectedNoteEntity(item));
              }}
              onEdit={() => {
                setReadOnlyMode(false);
                openNotesModal();
                setSelectedEntity(formatSelectedNoteEntity(item));
              }}
              onEditWorkorder={() => {
                openStaffWorkorder(item);
              }}
              onDelete={() => {
                openConfirmModal(true);
                setSelectedEntity(formatSelectedNoteEntity(item));
              }}
              onManage={({ history }) => {
                handleManage({
                  router: history,
                  destination: item.destination,
                });
              }}
            />
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const handleExport = (type, code) => {
    const body = [];
    Object.keys(groupedByDate).forEach(el => {
      const item = groupedByDate[el];
      item.forEach(element => {
        const exporting = [];
        exporting.push(_.get(element, 'noteType.description', '--'));
        exporting.push(
          !_.isEmpty(item.expireDate)
            ? moment(item.expireDate.date).format('DD-MM-YYYY')
            : '--',
        );
        exporting.push(_.get(element, 'noteReason.description', '--'));
        exporting.push(
          _.get(element, 'destination.customer.displayName', '--'),
        );
        exporting.push(_.get(element, 'destination.businessName', '--'));
        exporting.push(_.get(element, 'noteText', '--'));
        body.push(exporting);
      });
    });

    onExport(type, code, body);
  };

  /**
   * Function that maps over the grouped by date data to
   * render a Table with a dynamic header and the body based on the data.
   * @returns Table and rows
   */
  const renderTableNotes = () => {
    const renderables = [];

    Object.keys(groupedByDate).forEach((el, idx) => {
      const item = groupedByDate[el];
      renderables.push(
        <Table celled attached={idx === 0 ? 'top' : true}>
          {idx === 0 && header}
          <Table.Header fullWidth colSpan={16}>
            <Table.Row colSpan={16}>
              <Table.HeaderCell width={16} colSpan={16}>
                {intl.formatMessage(messages.expire_date)}: {el},{' '}
                {intl.formatMessage(messages.total)}: {item.length}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{item.map(element => renderBody(element))}</Table.Body>
        </Table>,
      );
    });

    return renderables.filter(o => o);
  };

  const attachedTables = (
    <div>
      {renderTableNotes()}
      {!Object.keys(groupedByDate).length && (
        <div style={{ height: 50 }}>No dati vuoti!</div>
      )}
      {/* The table footer that is attached to the main table to provide
      neccesary paddings, positionings and background colors */}
      {!!Object.keys(groupedByDate).length && (
        <TableFooter
          onExport={handleExport}
          pageCount={page_count}
          page={page}
          pageSize={page_size}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
        />
      )}
    </div>
  );

  return attachedTables;
};

NoteTable.propTypes = {
  data: PropTypes.array,
  intl: PropTypes.shape(intlShape),
  setSelectedEntity: PropTypes.func,
  openNotesModal: PropTypes.func,
  setReadOnlyMode: PropTypes.func,
  openConfirmModal: PropTypes.func,
  onExport: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangePageSize: PropTypes.func,
  executeNote: PropTypes.func,
  openStaffWorkorder: PropTypes.func,
};

export default injectIntl(NoteTable);
