import { Field } from 'formik';
import _ from 'lodash';
import SimpleFormikField from 'novigo-simple-formik-field';
import propTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Form, Grid } from 'semantic-ui-react';
import offerMessages from '../../../containers/Offer/messages';
import CustomerService from '../../../shared/services/customer';
import CustomerDestinationService from '../../../shared/services/customerdestination';
import DestinationPaymentTermService from '../../../shared/services/destinationpaymentterm';
import OfferOriginService from '../../../shared/services/offerorigin';
import OfferTypologyService from '../../../shared/services/offertypology';
// Services
import ProfileService from '../../../shared/services/profile';
import FormActionsBar from '../../FormActionsBar';
import FormActionsModal from '../../FormActionsModal';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import messages from './messages';

const OfferForm = props => {
  const {
    handleSubmit,
    setFieldValue,
    setValues,
    children,
    modalSubmit,
    values,
    ...rest
  } = props;

  useEffect(
    () => {
      const { destination } = props.values;
      if (props.isNew && destination) {
        setValues({
          ...props.values,
          commercialDirector: destination.commercialDirector,
          areaManager: destination.areaManager,
          agent1: destination.agent1,
          agent2: destination.agent2,
          subagent: destination.subagent,
          buyingGroup: destination.buyingGroup,
          signaller: destination.signaller,
        });
      }
    },
    [props.values.destination, props.isNew],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Field
          name="deliveryType"
          render={({ field }) => (
            <Form.Group widths="equal" style={{ paddingLeft: 5 }}>
              <Button.Group>
                <Button
                  type="button"
                  disabled={props.readOnly}
                  primary={field.value === 'Franco Partenza'}
                  checked={field.value === 'Franco Partenza'}
                  onClick={() =>
                    setFieldValue('deliveryType', 'Franco Partenza')
                  }
                >
                  <FormattedMessage {...messages.vettore_dtm} />
                </Button>
                <Button
                  type="button"
                  disabled={props.readOnly}
                  primary={field.value === 'Franco Cliente'}
                  checked={field.value === 'Franco Cliente'}
                  onClick={() =>
                    setFieldValue('deliveryType', 'Franco Cliente')
                  }
                >
                  <FormattedMessage {...messages.vettore__cliente} />
                </Button>
              </Button.Group>
            </Form.Group>
          )}
        />
      </div>
      <Grid columns={3} padded>
        <Grid.Row>
          <Grid.Column>
            <SimpleFormikField
              {...rest}
              name="number"
              label={props.intl.formatMessage(offerMessages.offer_number)}
              readOnly
            />
          </Grid.Column>
          <Grid.Column>
            <FormikDate
              {...rest}
              required
              name="startDate"
              label={props.intl.formatMessage(offerMessages.date)}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikDate
              {...rest}
              required
              name="expirationDate"
              label={props.intl.formatMessage(offerMessages.expiring_date)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              required
              name="typology"
              label={props.intl.formatMessage(offerMessages.offer_type)}
              loadOptions={(searchText, callback) =>
                OfferTypologyService.getOptions(searchText, callback)
              }
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              required
              name="customer"
              label={props.intl.formatMessage(offerMessages.offer_client)}
              loadOptions={(searchText, callback) =>
                CustomerService.getOptionsClientProspect(searchText, callback)
              }
              onChange={data => {
                setFieldValue('customer', data);
                setFieldValue('destination', null);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              key={`customer-${_.get(props.values, 'customer.value', null)}`}
              required
              name="destination"
              label={props.intl.formatMessage(messages.destination)}
              loadOptions={(searchText, callback) =>
                CustomerDestinationService.getOwnerOptionsExtendedData(
                  searchText,
                  _.get(props.values, 'customer.value', null),
                  callback,
                )
              }
              onChange={data => {
                setFieldValue('destination', data);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="commercialDirector"
              label={props.intl.formatMessage(messages.commercial_director)}
              loadOptions={(searchText, callback) =>
                ProfileService.getCommercialDirectorOptions(
                  searchText,
                  callback,
                )
              }
              onChange={data => {
                setFieldValue('commercialDirector', data);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="areaManager"
              label={props.intl.formatMessage(messages.area_manager)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAreaManagerOptions(searchText, callback)
              }
              onChange={data => {
                setFieldValue('areaManager', data);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="origin"
              label={props.intl.formatMessage(messages.origin)}
              loadOptions={(searchText, callback) =>
                OfferOriginService.getOptions(searchText, callback)
              }
              onChange={data => {
                setFieldValue('origin', data);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="agent1"
              label={props.intl.formatMessage(messages.agent_1)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAgentOptions(searchText, callback)
              }
              onChange={data => {
                setFieldValue('agent1', data);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="agent2"
              label={props.intl.formatMessage(messages.agent_2)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAgentOptions(searchText, callback)
              }
              onChange={data => {
                setFieldValue('agent2', data);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="signaller"
              label={props.intl.formatMessage(messages.signaller)}
              loadOptions={(searchText, callback) =>
                ProfileService.getAgentOptions(searchText, callback)
              }
              onChange={data => {
                setFieldValue('signaller', data);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FormikAsyncSelect
              {...rest}
              name="paymentCondition"
              label={props.intl.formatMessage(messages.payment_condition)}
              loadOptions={(searchText, callback) =>
                DestinationPaymentTermService.getOptions(searchText, callback)
              }
              onChange={data => {
                setFieldValue('paymentCondition', data);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...rest}
              name="delivery"
              label={props.intl.formatMessage(messages.delivery)}
              readOnly
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...rest}
              name="requestNumber"
              label={props.intl.formatMessage(messages.requestNumber)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SimpleFormikField
              {...rest}
              name="cig"
              label={props.intl.formatMessage(messages.cig)}
            />
          </Grid.Column>
          <Grid.Column>
            <SimpleFormikField
              {...rest}
              name="cup"
              label={props.intl.formatMessage(messages.cup)}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <SimpleFormikField
              {...rest}
              name="note"
              label={props.intl.formatMessage(messages.note)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {modalSubmit ? (
        <FormActionsModal {...props} onSaveClick={props.submit} />
      ) : (
        <FormActionsBar {...props} showGoBack={false} />
      )}
    </Form>
  );
};

OfferForm.propsType = {
  intl: intlShape.isRequired,
  isValid: propTypes.bool.isRequired,
  isSubmitting: propTypes.bool.isRequired,
  setValid: propTypes.func.isRequired,
  setSubmitting: propTypes.func.isRequired,
};

export default injectIntl(OfferForm);
