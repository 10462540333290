/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { FormField, Icon } from 'semantic-ui-react';
import { Field as FormikField } from 'formik';
import './style.css';

import { THREE_STATE } from './constant';

const FormikThreeState = ({
  name,
  label,
  validate,
  message,
  onChange,
  ...rest
}) => (
  <FormikField name={name} validate={validate}>
    {({
      field: { value, onBlur },
      form: { setFieldValue, setFieldTouched },
      meta,
    }) => (
      <FormField
        control={CheckboxThreeState}
        name={name}
        value={value}
        label={label}
        message={message}
        onChange={(event, data) => {
          setFieldValue(name, data.value);
          setFieldTouched(name, true, false);
          // eslint-disable-next-line no-unused-expressions
          onChange && onChange(event, data);
        }}
        onBlur={onBlur}
        setFieldValue={setFieldValue}
        error={meta && meta.error && meta.touched && { content: meta.error }}
        {...rest}
      />
    )}
  </FormikField>
);

FormikThreeState.propTypes = {
  /**
   * The name of the component.
   */
  name: PropTypes.string.isRequired,

  /**
   * The label of the component.
   */
  label: PropTypes.string,

  /**
   * The validate function for the Formik field.
   */
  validate: PropTypes.func,

  /**
   * Optional function triggered after the change of the state.
   */
  onChange: PropTypes.func,

  /**
   * Optional messages on the right side of the checkbox.
   */
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
};

const CheckboxThreeState = props => {
  const { name, value, label, message, onChange } = props;

  /**
   * The listner of the click or keydown method for the custom checkbox component.
   * @param {Event} event the event
   */
  const onCheck = event => {
    let nextValue = null;

    if (!value && value !== THREE_STATE.NEGATIVE) {
      nextValue = THREE_STATE.POSITIVE;
    }

    if (value === THREE_STATE.POSITIVE) {
      nextValue = THREE_STATE.NEGATIVE;
    }

    if (value === THREE_STATE.NEGATIVE) {
      nextValue = THREE_STATE.INDETERMINATE;
    }

    onChange(event, { value: nextValue });
  };

  return (
    <div
      className="row"
      onClick={onCheck}
      onKeyDown={onCheck}
      style={{ cursor: 'pointer', userSelect: 'none' }}>
      <label htmlFor={name} className="label">
        {label}
      </label>
      <span
        role="checkbox"
        aria-checked={value}
        tabIndex="0"
      >
        {value === THREE_STATE.NEGATIVE && (
          <Icon name="close" />
        )}
        {value === THREE_STATE.INDETERMINATE && (
          <Icon name="pencil" />
        )}
        {value === THREE_STATE.POSITIVE && (
          <Icon name="bars" />
        )}
      </span>
      <span>{typeof message === 'function' ? message(props) : message}</span>
      <input type="hidden" name={name} value={value} />
    </div>
  );
};

CheckboxThreeState.propTypes = {
  /**
   * The name of the component.
   */
  name: PropTypes.string.isRequired,

  /**
   * A three-state domain value for the checkbox.
   */
  value: PropTypes.oneOf([true, false, null]),

  /**
   * The label of the component.
   */
  label: PropTypes.string,

  /**
   * The onChange function triggered from the FormikThreeState component.
   */
  onChange: PropTypes.func,

  /**
   * Optional messages on the right side of the checkbox.
   */
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
};
export default FormikThreeState;
