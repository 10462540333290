import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Grid, Button, Icon } from 'semantic-ui-react';
import _ from 'lodash';
import BaseForm from './form';
import { addNotification } from '../../../utils/notification';
import { ModalFakeActions } from '../../Layout';
import appMessages from '../../../containers/App/messages';
import messages from './messages';
import getError from '../../../utils/getError';
import categoryService from '../../../shared/services/productcategory';

const initialValues = newValues => ({
  id: _.get(newValues, 'id', null),
  description: _.get(newValues, 'description', ''),
  subcategories: _.get(newValues, 'subcategories', []),
});

export default class ProductCategory extends Component {
  static propTypes = {
    category: PropTypes.object,
  };

  onSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      id: values.id || null,
      description: _.get(values, 'description', ''),
      subcategories: _.get(values, 'subcategories', []),
    };

    const method = payload.id ? categoryService.update : categoryService.create;

    method(payload)
      .then(() => {
        setSubmitting(false);
        addNotification({
          title: this.context.intl.formatMessage(appMessages.success),
          message: this.context.intl.formatMessage(messages.category_saved),
          isError: false,
        });
        this.props.update();
        this.props.close();
      })
      .catch(err => {
        setErrors(getError(err));
        setSubmitting(false);
        addNotification({
          title: null,
          message: err.data.detail,
          isError: true,
        });
      });
  };

  submit = () => {
    this.baseForm.submitForm();
  };

  render() {
    return (
      <Grid>
        <Grid.Column width={16}>
          <Formik
            ref={c => {
              this.baseForm = c;
            }}
            initialValues={initialValues(this.props.initialValues || {})}
            onSubmit={this.onSubmit}
            render={props => (
              <BaseForm {...props} contactTypes={this.props.contactTypes} />
            )}
          />
        </Grid.Column>
        <ModalFakeActions>
          <div style={{ display: 'inline-block', width: '50%' }}>
            <Button
              type="button"
              icon
              labelPosition="left"
              onClick={this.props.close}
            >
              <Icon name="remove" />
              {this.context.intl.formatMessage(appMessages.cancel)}
            </Button>
          </div>
          <div
            style={{
              display: 'inline-block',
              width: '50%',
              textAlign: 'right',
            }}
          >
            <Button
              type="button"
              icon
              labelPosition="left"
              color="green"
              onClick={this.submit}
            >
              <Icon name="checkmark" />
              {this.context.intl.formatMessage(appMessages.save)}
            </Button>
          </div>
        </ModalFakeActions>
      </Grid>
    );
  }
}

ProductCategory.contextTypes = {
  intl: PropTypes.object.isRequired,
};
