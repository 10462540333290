import { createSelector } from 'reselect';

/**
 * Direct selector to the tickets state domain
 */
const selectTicketsDomain = state => state.get('tickets');

/**
 * Other specific selectors
 */

/**
 * Default selector used by Tickets
 */

const makeSelectTickets = () =>
  createSelector(selectTicketsDomain, substate => substate.toJS());

export default makeSelectTickets;
export { selectTicketsDomain };
