import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Form, Grid } from 'semantic-ui-react';
import ProductService from '../../../shared/services/product';
import FormikAsyncSelect from '../../FormikAsyncSelect';
import FormikDate from '../../FormikDate';
import FormikToggle from '../../FormikToggle';
import { PaddedColumn } from '../../Layout';
import PercentageFormatter from '../../PercentageFormatter';
import messages from './messages';

export const ExcludeForm = props => (
  <Form
    autoComplete="off"
    onSubmit={props.handleSubmit}
    loading={props.isSubmitting}
  >
    <Grid>
      <Grid.Row columns={3}>
        <PaddedColumn>
          <FormikDate
            required
            {...props}
            name="startDate"
            label={props.intl.formatMessage(messages.commissionStartDate)}
          />
        </PaddedColumn>
        <PaddedColumn>
          <FormikDate
            required
            {...props}
            name="endDate"
            label={props.intl.formatMessage(messages.commissionEndDate)}
          />
        </PaddedColumn>
        <PaddedColumn>
          <FormikAsyncSelect
            required
            {...props}
            name="product"
            label={props.intl.formatMessage(messages.product)}
            loadOptions={(searchText, callback) =>
              ProductService.getOptions(searchText, callback)
            }
          />
        </PaddedColumn>
      </Grid.Row>
      <Grid.Row columns={4}>
        <PaddedColumn>
          <PercentageFormatter
            {...props}
            name="fromPercentage"
            label={props.intl.formatMessage(messages.fromPercentage)}
          />
        </PaddedColumn>
        <PaddedColumn>
          <PercentageFormatter
            {...props}
            name="toPercentage"
            label={props.intl.formatMessage(messages.toPercentage)}
          />
        </PaddedColumn>
        <PaddedColumn>
          <PercentageFormatter
            {...props}
            name="commission"
            label={props.intl.formatMessage(messages.commission)}
          />
        </PaddedColumn>
        <PaddedColumn>
          <PercentageFormatter
            {...props}
            name="refund"
            label={props.intl.formatMessage(messages.refund)}
          />
        </PaddedColumn>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={4}>
          <PercentageFormatter
            {...props}
            name="flatUnit"
            label={props.intl.formatMessage(messages.flat_unit)}
          />
        </Grid.Column>
        <PaddedColumn width={6}>
          <FormikToggle
            {...props}
            name="includedInBonus"
            label={props.intl.formatMessage(messages.award)}
          />
        </PaddedColumn>
      </Grid.Row>
    </Grid>
  </Form>
);

ExcludeForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
};

export default injectIntl(ExcludeForm);
