import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAll = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT_COMMISSION,
    method: 'GET',
  });

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.AGENT_COMMISSION,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.AGENT_COMMISSION}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.AGENT_COMMISSION}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (agentId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.AGENT_COMMISSION}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'agent',
          value: agentId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const ProductCommissionService = {
  create,
  update,
  remove,
  getAll,
  getByOwner,
};

export default ProductCommissionService;
