import { defineMessages } from 'react-intl';

export const scope = 'novigo.entity.grid.createButton';

export default defineMessages({
  createLabel: {
    id: `${scope}.createLabel`,
    defaultMessage: 'New',
  },
});
