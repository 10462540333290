import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const searchByName = searchText =>
  request({
    baseURL: API.BASE_URL,
    url: API.OFFER_TYPOLOGY,
    method: 'GET',
    params: {
      filter: [
        searchText && {
          type: 'like',
          field: 'name',
          value: `%${searchText}%`,
        },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getOptions = (inputValue, callback) => {
  searchByName(inputValue).then(results => {
    callback(
      _.get(results, 'data._embedded.offer-typology', []).map(
        offerTypology => ({
          value: offerTypology.id,
          label: offerTypology.name,
        }),
      ),
    );
  });
  // eslint-disable-next-line no-useless-return
  return;
};

const OfferTypologyService = {
  getOptions,
  searchByName,
};

export default OfferTypologyService;
