import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Accordion from '../../../Accordion';
import messages from '../messages';
import noteService from '../../../../shared/services/destinationnote';
import NotesPart from '../formParts/notesPart';

function NotesTab(props) {
  return (
    <div>
      <Accordion title={props.intl.formatMessage(messages.tab_general_notes)}>
        <NotesPart {...props} noteService={noteService} entity="destination" />
      </Accordion>
    </div>
  );
}

NotesTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(NotesTab);
