import _ from 'lodash';

export const formatDropdownOptions = (entity, relation, isEmbedded) => ({
  value: _.get(
    entity,
    isEmbedded ? `_embedded.${relation}.id` : `${relation}.id`,
    null,
  ),
  label: _.get(
    entity,
    isEmbedded
      ? `_embedded.${relation}.displayName`
      : `${relation}.displayName`,
    null,
  ),
});

export const formatProfileOptions = (profiles, entity) => {
  const data = profiles.find(item => item.role === entity);
  if (!data) {
    return null;
  }
  return {
    value: _.get(data, 'profile', null),
    label: _.get(data, 'displayName', null),
  };
};
