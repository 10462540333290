import React from 'react';
import _ from 'lodash';
import SimpleFormikField from 'novigo-simple-formik-field';
import { TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';

function FormikTextArea(props) {
  return (
    <SimpleFormikField name={props.name} label={props.label} {...props}>
      {({
        // field, // { name, value, onChange, onBlur }
        form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => (
        <TextArea
          onChange={(e, data) => setFieldValue(props.name, data.value)}
          value={_.get(values, `${props.name}`, '')}
          placeholder={props.placeholder}
        />
      )}
    </SimpleFormikField>
  );
}

FormikTextArea.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  placeholder: PropTypes.string,
};

export default FormikTextArea;
