import { get } from 'lodash';
import SimpleFormikField from 'novigo-simple-formik-field';
import React from 'react';
import { intlShape } from 'react-intl';
import { Grid, TextArea } from 'semantic-ui-react';
import offerMessages from '../../../../../containers/Offer/messages';
import OfferTypologyService from '../../../../../shared/services/offertypology';
import OrderTypologyService from '../../../../../shared/services/ordertypology';
import FormikAsyncSelect from '../../../../FormikAsyncSelect';
import FormikDate from '../../../../FormikDate';
import FormikField from '../../../../FormikField';
import { PaddedColumn } from '../../../../Layout';
import messages from '../../../Anagrafica/messages';
import { OFFER_STATE_LOSS } from '../../../../../containers/Offer/constants';

const NoteForm = props => {
  const { isOrder = false } = props;
  return (
    <Grid style={{ width: '100%' }}>
      <Grid.Row>
        <Grid.Column width={2}>
          <FormikAsyncSelect
            {...props}
            name={isOrder ? 'orderType' : 'typology'}
            label={props.intl.formatMessage(
              offerMessages[isOrder ? 'order_type' : 'offer_type'],
            )}
            loadOptions={(searchText, callback) => {
              if (isOrder) {
                return OrderTypologyService.getOptions(searchText, callback);
              }
              return OfferTypologyService.getOptions(searchText, callback);
            }}
          />
        </Grid.Column>
        {isOrder && (
          <>
            <Grid.Column width={4}>
              <SimpleFormikField
                {...props}
                name="delivery"
                label={props.intl.formatMessage(messages.delivery)}
                readOnly
                value={get(props, 'destination.delivery', '')}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <SimpleFormikField
                {...props}
                name="cig"
                label={props.intl.formatMessage(messages.cig)}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <SimpleFormikField
                {...props}
                name="customerOrderNumber"
                label={props.intl.formatMessage(messages.customerOrderNumber)}
              />
            </Grid.Column>
            <Grid.Column width={6}>
              <FormikField
                required={props.values.state === OFFER_STATE_LOSS}
                name="internalNote"
                label={props.intl.formatMessage(messages.noteInternal)}
                {...props}
              >
                <TextArea
                  onChange={(e, data) =>
                    props.setFieldValue('internalNote', data.value)
                  }
                  value={get(props, 'values.internalNote', '')}
                  placeholder={props.intl.formatMessage(
                    messages.note_placeholder,
                  )}
                />
              </FormikField>
            </Grid.Column>
          </>
        )}
        {!isOrder && (
          <>
            <PaddedColumn width={4}>
              <SimpleFormikField
                {...props}
                name="attention"
                label={props.intl.formatMessage(messages.attention)}
              />
            </PaddedColumn>
            <PaddedColumn width={4}>
              <SimpleFormikField
                {...props}
                name="subject"
                label={props.intl.formatMessage(messages.subject)}
              />
            </PaddedColumn>
            <PaddedColumn width={4}>
              <SimpleFormikField
                {...props}
                name="payment"
                label={props.intl.formatMessage(messages.payment)}
              />
            </PaddedColumn>
          </>
        )}
      </Grid.Row>
      {!isOrder && (
        <Grid.Row columns={4}>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="delivery"
              label={props.intl.formatMessage(messages.delivery)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="port"
              label={props.intl.formatMessage(messages.port)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="requestNumber"
              label={props.intl.formatMessage(messages.requestNumber)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikDate
              {...props}
              name="expirationDate"
              label={props.intl.formatMessage(messages.expire_date)}
            />
          </PaddedColumn>
        </Grid.Row>
      )}
      {!isOrder && (
        <PaddedColumn width={16}>
          <FormikField
            required={props.values.state === OFFER_STATE_LOSS}
            name="note"
            label={props.intl.formatMessage(messages.noteInternal)}
            {...props}
          >
            <TextArea
              onChange={(e, data) => props.setFieldValue('note', data.value)}
              value={get(props, 'values.note', '')}
              placeholder={props.intl.formatMessage(messages.note_placeholder)}
            />
          </FormikField>
        </PaddedColumn>
      )}
    </Grid>
  );
};

NoteForm.propTypes = {
  intl: intlShape.isRequired,
};

export default NoteForm;
