/* eslint-disable no-nested-ternary */
import _, { get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

export const handleHeaderLink = values => {
  const isCustomer = !!values.customer;
  const { id = null } = values;
  if (id !== null) {
    return (
      <>
        <Link
          to={`${isCustomer ? '/customer' : '/prospect'}/${id}/view`}
          style={{ color: 'black', textDecorationLine: 'underline' }}
        >
          <h3>
            {get(values, 'code', '--')} - {_.get(values, 'businessName', '')}
          </h3>
        </Link>
        <br />
        <p>{_.get(values, 'businessAddressTitle', '--')}</p>
      </>
    );
  }
  return (
    <>
      <h3>
        {get(values, 'code', '--')} - {_.get(values, 'businessName', '')}
      </h3>
      <br />
      <p>{_.get(values, 'businessAddressTitle', '--')}</p>
    </>
  );
};
