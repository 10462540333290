/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import { Form, Button, Grid, Icon } from 'semantic-ui-react';
// import messages from './messages';

export default class PasswordForm extends Component {
  state = {
    plainPasswordCurrent: '',
    plainPasswordNew: '',
    plainPasswordNewCheck: '',
  };

  static propTypes = {
    onSubmit: PropTypes.func,
    styles: PropTypes.object,
  };

  onRoleChange = opts =>
    this.setState({ role: opts }, () => console.log(this.state.role));

  render() {
    const {
      plainPasswordCurrent,
      plainPasswordNew,
      plainPasswordNewCheck,
    } = this.state;

    return (
      <Grid>
        <Grid.Column width={16}>
          <Form
            onSubmit={() =>
              this.props.onSubmit({
                plainPasswordCurrent,
                plainPasswordNew,
                plainPasswordNewCheck,
              })
            }
            style={{ padding: 16 }}
          >
            <Grid>
              <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                <Form.Field>
                  <label htmlFor="plainPasswordCurrent">
                    Password corrente
                  </label>
                  <input
                    placeholder="Password corrente"
                    id="plainPasswordCurrent"
                    defaultValue={plainPasswordCurrent}
                    type="password"
                    onBlur={evt => {
                      this.setState({ plainPasswordCurrent: evt.target.value });
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                <Form.Field>
                  <label htmlFor="plainPasswordNew">Nuova password</label>
                  <input
                    placeholder="Nuova password"
                    id="plainPasswordNew"
                    defaultValue={plainPasswordNew}
                    type="password"
                    onBlur={evt => {
                      this.setState({ plainPasswordNew: evt.target.value });
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={16}>
                <Form.Field>
                  <label htmlFor="plainPasswordNewCheck">
                    Ripeti nuova password
                  </label>
                  <input
                    placeholder="Ripeti nuova password"
                    id="plainPasswordNewCheck"
                    defaultValue={plainPasswordNewCheck}
                    type="password"
                    onBlur={evt => {
                      this.setState({
                        plainPasswordNewCheck: evt.target.value,
                      });
                    }}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={16}>
                <div
                  style={{
                    backgroundColor: this.props.styles.SEMANTIC_UI_ACTION_GREY,
                    margin: 'auto -16px -16px -16px',
                    padding: 16,
                    borderTop: '1px solid #d8d9da',
                    borderRadius: '0px 0px 4px 4px',
                    textAlign: 'right',
                  }}
                >
                  <Button type="submit" color="green">
                    <Icon name="save" /> Save
                  </Button>
                </div>
              </Grid.Column>
            </Grid>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}
