/* eslint-disable react/prop-types */
/**
 *
 * TableBody
 *
 */

/*

TODO set height with css?

table ,tr td{
    border:1px solid red
}
tbody {
    display:block;
    height:50px;
    overflow:auto;
}
thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed; even columns width , fix width of table too
  }
  thead {
      width: calc( 100% - 1em ) scrollbars is average 1em/16px width, remove it from thead width
  }
  table {
      width:400px;
  }

*/

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'semantic-ui-react';

const TableBody = props => {
  const {
    columns,
    columnsNumber,
    elements,
    emptyResults,
    onSelect,
    rowClassKey,
    rowUniqueKey,
    selectIsActive,
    selected,
  } = props;

  const [selectedElement, setSelectedElement] = useState(null);

  useEffect(
    () => {
      // TODO Find a better way to fetch the selected element
      if (selected)
        setSelectedElement(elements.filter(e => e.id === selected.id)[0]);
    },
    [selected],
  );

  return (
    <Table.Body as={elements ? 'tbody' : 'div'}>
      {elements && elements.length > 0 ? (
        elements.map((element, index) => (
          <Table.Row
            warning={element === selectedElement}
            key={rowUniqueKey ? element[rowUniqueKey] : index}
            className={rowClassKey ? element[rowClassKey] : null}
            onClick={() => {
              if (selectIsActive) {
                setSelectedElement(element);
                onSelect(element, index);
              }
            }}
          >
            {/* {selectIsActive ? (
              <Table.Cell collapsing>
                <Checkbox
                  checked={Boolean(element[isSelected])}
                  onClick={() =>
                    element[isSelected]
                      ? onDeselect(element)
                      : onSelect(element)
                  }
                  slider={false}
                />
              </Table.Cell>
            ) : null} */}
            {columns.map(
              column =>
                column.formatter ? (
                  <Table.Cell key={column.key} {...column.options}>
                    {column.formatter({
                      value: element[column.key],
                      data: element,
                    })}
                  </Table.Cell>
                ) : (
                  <Table.Cell key={column.key}>
                    {element[column.key] !== undefined
                      ? element[column.key]
                      : null}
                  </Table.Cell>
                ),
            )}
          </Table.Row>
        ))
      ) : (
        <Table.Row style={{ width: '100%' }}>
          <Table.Cell colSpan={columnsNumber}>{emptyResults}</Table.Cell>
        </Table.Row>
      )}
    </Table.Body>
  );
};

TableBody.propTypes = {
  /**
   * Array of elements to fill the grid
   */
  elements: PropTypes.array,
  /**
   * Array of columns [{key: string, name: string, formatter: function, sortable, bool}]
   */
  columns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * Show/Hide checkbox for selection
   */
  // selectIsActive: PropTypes.bool,
  /**
   * Select handler
   */
  onSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  /**
   * Deselect handler
   */
  // onDeselect: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  /**
   * Selected flag
   */
  // isSelected: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default TableBody;
