/*
 * Customer Messages
 *
 * This contains all the text for the Customer container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.CustomerDocument';

export default defineMessages({
  document_saved: {
    id: `${scope}.document_saved`,
    defaultMessage: 'Document successfully saved',
  },
  document_removed_success: {
    id: `${scope}.document_removed_success`,
    defaultMessage: 'Document successfully removed.',
  },
});
