import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import { injectIntl, intlShape } from 'react-intl';
import { Feed, Icon } from 'semantic-ui-react';
import Table from 'semantic-table-grid';
import { EditBtn, ViewBtn } from '../../../Buttons';
// import { addNotification } from '../../../../utils/notification';
import appMessages from '../../../../containers/App/messages';
import Accordion from '../../../Accordion';
import messages from '../messages';
import { WrapperTool } from '../../../Layout';
import NoteForm from '../../NoteForm';
import Modal from '../../../Modal';
import NoteService from '../../../../shared/services/note';
import HistoricalService from '../../../../shared/services/destinationhistorical';

const paginationDefault = {
  page: 1,
  page_count: 0,
  page_size: 5,
  total_items: 0,
};

const HistoricalTab = props => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [noteTypes, setNoteTypes] = useState([]);
  const [noteReasons, setNoteReasons] = useState([]);
  const [modalOpened, setModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [paginationData, setPaginationData] = useState({
    ...paginationDefault,
  });

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    NoteService.getNoteTypeOptions().then(res => {
      setNoteTypes(res);
    });
    NoteService.getReasonTypeOptions().then(res => {
      setNoteReasons(res);
    });
  }, []);

  const loadData = () => {
    const filter = {};
    filter.destination = props.values.id;
    HistoricalService.getByOwner(filter).then(response => {
      const data = _.get(response, 'data', { ...paginationDefault });
      setPaginationData({
        page: data.page,
        page_count: data.page_count,
        page_size: data.page_size,
        total_items: data.total_items,
      });
      const historics = _.reverse(
        _.get(response, 'data._embedded.historics', []),
      );

      setItems(historics);
      setLoading(false);
    });
  };

  const closeModal = () => {
    setSelectedItem(null);
    setModalOpened(false);
  };

  const openModal = data => {
    setSelectedItem(data);
    setModalOpened(true);
  };

  const getIcon = type => {
    switch (type) {
      case 'Order':
        return 'shop';
      case 'Offer':
        return 'folder';
      case 'Contract':
        return 'briefcase';
      case 'DestinationNote':
        return 'file';
      case 'CustomerNote':
        return 'file';
      default:
        return '';
    }
  };

  const getDiff = logTime => {
    const now = moment();
    const diff = moment.duration(now.diff(logTime));
    const hours = diff.asHours();
    const days = diff.asDays();
    if (days >= 1) {
      return `${Math.round(days)} ${props.intl.formatMessage(
        appMessages.daysAgo,
      )}`;
    }
    return `${Math.round(hours)}  ${props.intl.formatMessage(
      appMessages.hoursAgo,
    )}`;
  };

  const getViewAction = data => {
    if (data.type === 'DestinationNote' || data.type === 'CustomerNote') {
      return openModal({ ...data, isReadOnly: true });
    }
    return props.history.push(`/${data.type.toLowerCase()}/${data.id}/view`);
  };

  const getEditAction = data => {
    if (data.type === 'DestinationNote' || data.type === 'CustomerNote') {
      return openModal(data);
    }
    return props.history.push(`/${data.type.toLowerCase()}/${data.id}/modify`);
  };

  const columns = () =>
    [
      {
        key: 'code',
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <Feed>
            <Feed.Event>
              <Feed.Label>
                <Icon name={getIcon(data.type)} />
              </Feed.Label>
              <Feed.Content>
                <Feed.Summary>
                  <Feed.User
                    onClick={() =>
                      props.history.push(
                        `/${data.type.toLowerCase()}/${data.id}/view`,
                      )
                    }
                  >
                    {_.get(data, 'number', 'null') ? data.number : null}
                  </Feed.User>
                  {_.get(data, 'type', null) ? ` ${data.type}` : null}
                  {_.get(data, 'logTime', null)
                    ? ` - ${moment(data.logTime).format('DD/MM/YYYY')}`
                    : null}
                  <Feed.Date>{getDiff(data.logTime)}</Feed.Date>
                </Feed.Summary>
                {data.description && (
                  <Feed.Extra text>
                    {_.get(data, 'description', null) ? data.description : null}
                  </Feed.Extra>
                )}
                {data.description && (
                  <Feed.Meta content>
                    {_.get(data, 'extra', null) ? data.extra : null}
                  </Feed.Meta>
                )}
              </Feed.Content>
            </Feed.Event>
          </Feed>
        ),
      },
      !props.readOnly && {
        key: 'actions',
        name: props.intl.formatMessage(appMessages.actions),
        // eslint-disable-next-line react/prop-types
        formatter: ({ data }) => (
          <div style={{ width: 150 }}>
            <ViewBtn action={() => getViewAction(data)} />
            <EditBtn action={() => getEditAction(data)} />
          </div>
        ),
        width: '150px',
      },
    ].filter(o => o);

  return (
    <div>
      <Accordion title={props.intl.formatMessage(messages.tab_historical_data)}>
        <div>
          <Table
            elements={items}
            rowClassKey="role-row-class"
            columns={columns()}
            canSort={{
              active: true,
            }}
            isLoading={loading}
            canPaginate={{
              active: paginationData.total_items > 0,
              pageCount: paginationData.page_count,
              pageSize: paginationData.page_size,
              totalItems: paginationData.total_items,
              page: paginationData.page,
              onSelect: page => {
                setPaginationData({
                  ...paginationData,
                  page,
                });
              },
            }}
            hiddenHeaderIfEmpty
            emptyResults={
              <div>{props.intl.formatMessage(appMessages.no_results)}</div>
            }
          />
          <WrapperTool key="wrapper">
            <Modal
              open={modalOpened}
              onClose={() => {
                closeModal();
              }}
              title={
                <h2>
                  <Icon name="sticky note" />{' '}
                  {selectedItem && selectedItem.isReadOnly
                    ? props.intl.formatMessage(messages.view_note)
                    : props.intl.formatMessage(messages.edit_note)}
                </h2>
              }
              size="mini"
              style={{ width: 650 }}
              scrolling
            >
              <NoteForm
                entityId={props.values.id}
                close={closeModal}
                noteTypes={noteTypes}
                noteReasons={noteReasons}
                data={selectedItem}
                update={() => loadData()}
              />
            </Modal>
          </WrapperTool>
        </div>
      </Accordion>
    </div>
  );
};

HistoricalTab.propTypes = {
  readOnly: PropTypes.bool,
  values: PropTypes.object,
  history: PropTypes.object,
  intl: intlShape.isRequired,
};

export default injectIntl(HistoricalTab);
