/*
 * PriceListMaster Messages
 *
 * This contains all the text for the PriceListMaster container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.PriceListMaster';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the PriceListMaster container!',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Price List',
  },
  code: {
    id: `${scope}.code`,
    defaultMessage: 'Price list code',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Valid from',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'Valid to',
  },
  new_price_list: {
    id: `${scope}.new_price_list`,
    defaultMessage: 'New price list',
  },
  manage: {
    id: `${scope}.manage`,
    defaultMessage: 'Manage',
  },
  manage_price_list: {
    id: `${scope}.manage_price_list`,
    defaultMessage: 'Manage price list',
  },
});
