import messages from './messages';

function Validation(values, intl) {
  const errors = {};

  if (!values.businessName) {
    errors.businessName = intl.formatMessage(messages.businessName_required);
  }
  if (!values.vatNumber) {
    errors.vatNumber = intl.formatMessage(messages.vatNumber_required);
  }

  return errors;
}

export default Validation;
