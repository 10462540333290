import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getContactTypes = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.CONTACT_TYPE,
    method: 'GET',
    params: {},
  });

const getContactTypeOptions = () =>
  getContactTypes().then(res =>
    _.get(res, 'data._embedded.contact_type', []).map(type => ({
      label: type.description,
      value: type.id,
    })),
  );

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.SUPPLIER_CONTACT,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.SUPPLIER_CONTACT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.SUPPLIER_CONTACT}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (supplierId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.SUPPLIER_CONTACT}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'supplier',
          value: supplierId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const ContactService = {
  getContactTypes,
  getContactTypeOptions,
  getByOwner,
  create,
  update,
  remove,
};

export default ContactService;
