import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Segment } from 'semantic-ui-react';

function renderColumn(props) {
  const { label, value } = props;

  return (
    <>
      <Grid.Column key={value}>
        <Grid.Row style={{ display: 'flex' }}>
          <Grid.Column style={{ textAlign: 'right', marginBottom: 5 }}>
            <p style={{ marginRight: 5 }}>
              <strong>{label}: </strong>
            </p>
          </Grid.Column>
          <Grid.Column style={{ textAlign: 'left' }}>
            <span> {value}</span>
          </Grid.Column>
        </Grid.Row>
      </Grid.Column>
    </>
  );
}

const ManageFormHeader = props => {
  const { data } = props;
  return (
    <Segment>
      <Grid columns={2} padded>
        <Grid.Row>{data.map(item => renderColumn(item))}</Grid.Row>
      </Grid>
    </Segment>
  );
};

ManageFormHeader.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ManageFormHeader;
