import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Accordion from '../../../Accordion';
import messages from '../messages';
import DiscountPart from '../formParts/discountPart';
import InvoiceAmountPart from '../formParts/invoiceAmountPart';
import ExcludeCommissionsPart from '../formParts/excludeCommissionsPart';

function CommissionsTab(props) {
  return (
    <div>
      <Accordion title={props.intl.formatMessage(messages.discount_part)}>
        <DiscountPart {...props} entity="destination" />
      </Accordion>
      <Accordion title={props.intl.formatMessage(messages.invoice_amount_part)}>
        <InvoiceAmountPart {...props} entity="destination" />
      </Accordion>
      <Accordion title={props.intl.formatMessage(messages.exclude_part)}>
        <ExcludeCommissionsPart {...props} entity="destination" />
      </Accordion>
    </div>
  );
}

CommissionsTab.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CommissionsTab);
