import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import moment from 'moment/min/moment-with-locales';

const StyledDate = styled.div`
  display: block;
  color: #fff;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
`;

const StyledTime = styled.div`
  display: block;
  color: #fff;
  width: 100%;
  text-align: right;
  font-size: 0.9em;
`;

function Clock(props) {
  const [date, setDate] = useState(moment().format('LL'));
  const [time, setTime] = useState(moment().format('HH:mm A'));

  useEffect(() => {
    moment.locale(props.lang);
    const interval = setInterval(() => {
      setDate(moment().format('LL'));
      setTime(moment().format('HH:mm A'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return [
    <StyledDate key="date">{date}</StyledDate>,
    <StyledTime key="time">
      <Icon name="clock outline" /> {time}
    </StyledTime>,
  ];
}

Clock.propTypes = {
  lang: PropTypes.string,
};

export default Clock;
