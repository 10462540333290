/* eslint-disable no-nested-ternary */
/**
 *
 * Staff
 *
 */
/* eslint-disable indent */

import _ from 'lodash';
import FormManager from 'novigo-form-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Helmet } from 'react-helmet-async';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Header, Label } from 'semantic-ui-react';
import injectReducer from 'utils/injectReducer';
import injectSaga from 'utils/injectSaga';
import ListManager from '../../components/DeterchimicaEntityGrid/index';
import DestinationForm, {
  validation,
} from '../../components/Forms/DestinationForm';
import { ListWrapper, Page } from '../../components/Layout';
import PermissionManager from '../../components/Permission';
import AnagraficaSearchBar from '../../components/SearchBars/shared/Anagrafica/SearchBar';
/*
 * Specific import
 */
import { API } from '../../global-constants';
import destinationService from '../../shared/services/destination';
import MunicipalityService from '../../shared/services/municipality';
import request from '../../shared/services/request';
import actionToBreadcrumb from '../../utils/actionToBreadcrumb';
import { addNotification } from '../../utils/notification';
import appMessages from '../App/messages';
import { getRibbonColor, handleCleanData, transformData } from './functions';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import makeSelectProspect from './selectors';
// import ProfileService from '../../shared/services/profile';
import { getEntityError } from '../../utils/getError';
import { GetCurrentRoles } from '../Can/utils';

/* eslint-disable react/prefer-stateless-function */
export class Prospect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      headerTitle: '',
      aliases: [
        {
          type: 'isnull',
          field: 'customer',
        },
        {
          type: 'leftjoin',
          field: 'destinationState',
          alias: 'destinationState',
        },
        {
          type: 'leftjoin',
          field: 'destinationArea',
          alias: 'area',
        },
        {
          type: 'leftjoin',
          field: 'agent1',
          alias: 'agent',
        },
      ],
      filters: [],
      filterLabels: [],
      searchFilter: [],
      initialValues: {},
      values: {},
      searchedValue: '',
      removedFilter: '',
      reloadIndex: 0,
      initialData: {
        agent1: '',
        businessCity: '',
        businessName: '',
        businessPostalCode: '',
        businessProvinceShort: '',
        contactPerson: '',
        phoneNumber: '',
        registryType: 'L',
      },
      isUserAgent: false,
      userAgentId: '',
    };
  }

  /**
   * Check if you have permission to stay here
   */
  checkPermission() {
    PermissionManager.checkRESTPermission(
      'destination',
      this.props.history,
      this.props.match,
      this.context.intl,
    );
  }

  handleInitialFilters() {
    const { /* aliases, */ filters, initialValues, filterLabels } = this.state;

    const roles = GetCurrentRoles();

    if (
      _.intersection(roles, [
        'ROLE_AGENTE',
        'ROLE_RESPONSABILE_AGENTI',
        'ROLE_RESPONSABILE_AREA_COMMERCIALE',
      ]).length > 0
    ) {
      filterLabels.push({
        key: 'isOnlyMine',
        name: this.context.intl.formatMessage(messages.onlyMine),
        value: this.context.intl.formatMessage(messages.yes),
        filterField: 'isOnlyMine',
      });
      filters.push({
        where: 'and',
        type: 'search_agent_destination',
      });
      initialValues.isOnlyMine = true;

      // If the user is an agent, set state variables
      this.setState(
        {
          // aliases,
          initialValues,
          filterLabels,
          isUserAgent: true,
          // userAgentId: res[0].value,
        },
        () => {
          // this.setState(prev => ({ reloadIndex: prev.reloadIndex + 1 }));
        },
      );
    }

    // if (this.props.user) {
    //   ProfileService.getAgentOptionsByUserId(this.props.user.id, res => {
    //     if (res && res.length > 0) {
    //       filterLabels.push({
    //         key: 'isOnlyMine',
    //         name: this.context.intl.formatMessage(messages.onlyMine),
    //         value: this.context.intl.formatMessage(messages.yes),
    //         filterField: 'isOnlyMine',
    //       });
    //       aliases.push({
    //         type: 'orx',
    //         conditions: [
    //           {
    //             type: 'eq',
    //             where: 'or',
    //             field: 'agent1',
    //             value: res[0].value,
    //           },
    //           {
    //             type: 'eq',
    //             where: 'or',
    //             field: 'agent2',
    //             value: res[0].value,
    //           },
    //           {
    //             type: 'eq',
    //             where: 'or',
    //             field: 'subagent',
    //             value: res[0].value,
    //           },
    //         ],
    //         where: 'and',
    //       });

    //       // If the user is an agent, set state variables
    //       this.setState(
    //         {
    //           aliases,
    //           initialValues,
    //           filterLabels,
    //           isUserAgent: true,
    //           userAgentId: res[0].value,
    //         },
    //         () => {
    //           this.setState(prev => ({ reloadIndex: prev.reloadIndex + 1 }));
    //         },
    //       );
    //     }
    //   });
    // }
  }

  evaluteAction() {
    if (
      this.props.match.params.action === 'view' ||
      this.props.match.params.action === 'delete' ||
      this.props.match.params.action === 'modify' ||
      this.props.match.params.id === 'new'
    ) {
      this.setState({ showForm: true });
    } else {
      this.setState({
        showForm: false,
        headerTitle: this.context.intl.formatMessage(messages.title),
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.key !== this.props.location.key) {
      this.checkPermission();
      this.evaluteAction();
    }
  }

  componentDidMount() {
    this.checkPermission();
    this.evaluteAction();
    this.handleInitialFilters();
    this.setState({
      headerTitle: this.context.intl.formatMessage(messages.title),
    });
  }

  handleSearch = (filters, values, labels) =>
    new Promise(resolve => {
      const { aliases } = this.state;
      const updatedFilters = filters.map(el => {
        if (el.type === 'customer_instance_filter') {
          return { ...el, value: values.type.value };
        }
        return el;
      });

      const updatedAliases = aliases.filter(
        el => el.type !== 'customer_instance_filter',
      );

      this.setState(prev => ({
        filters: updatedFilters || prev.filters,
        // initialValues: values,
        aliases: updatedAliases || prev.aliases,
        filterLabels: labels,
      }));

      resolve(updatedFilters || this.state.filter);
    });

  handleRemovedFilter = name => {
    const { values } = this.state;
    values[name] = null;
    this.setState({
      values,
      removedFilter: '',
    });
  };

  exportPDF = () => {
    destinationService
      .exportPDF()
      .then(response => {
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .replace(/"/g, '')
          .trim();
        const file = new Blob([response.data]);
        const url = window.URL.createObjectURL(file);
        const element = document.createElement('a');
        document.body.appendChild(element);
        element.setAttribute('href', url);
        element.setAttribute('download', filename);
        element.style.display = '';
        element.click();
        document.body.removeChild(element);
      })
      .catch(err => {
        addNotification({
          title: this.context.intl.formatMessage(appMessages.error),
          message: _.get(
            err,
            'data.detail',
            this.context.intl.formatMessage(appMessages.an_error_has_occurred),
          ),
          isError: true,
        });
      });
  };

  onRemoveFilter = filterName => {
    this.setState(prev => ({
      filters:
        prev.filters.filter(el => el.field !== filterName) || prev.filters,
      removedFilter: filterName,
    }));
  };

  handleTitle = title => {
    if (!title) {
      this.setState({
        headerTitle: this.context.intl.formatMessage(messages.title),
      });
    } else {
      this.setState({ headerTitle: title });
    }
  };

  render() {
    return (
      <>
        <Helmet>
          <title>Departments</title>
          <meta
            name="description"
            content={this.context.intl.formatMessage(messages.title)}
          />
        </Helmet>
        <BreadcrumbsItem to="/prospect">
          {this.context.intl.formatMessage(messages.title)}
        </BreadcrumbsItem>
        <Page>
          <Header as="h2" dividing>
            {this.state.headerTitle}
          </Header>
          {this.state.showForm ? (
            <>
              <BreadcrumbsItem to="/prospect/edit">
                {actionToBreadcrumb({
                  action: this.props.match.params.action,
                  intl: this.context.intl,
                })}
              </BreadcrumbsItem>
              <FormManager
                data={
                  this.props.match.params.id === 'new'
                    ? this.state.initialData
                    : null
                }
                key={`${this.props.match.params.action}-${
                  this.props.match.params.id
                }`} // necessary to reload the current view upon redirect
                router={this.props} // necessary to use the internal routing
                client={pars =>
                  request(pars)
                    .then(res => transformData(res.data))
                    .catch(err => {
                      // If 404, navigate back to the previous page
                      if (err.status === 404) {
                        addNotification({
                          title: this.context.intl.formatMessage(
                            appMessages.error,
                          ),
                          message: getEntityError(err, this.context.intl),
                          isError: true,
                        });
                        window.history.back();
                      } else {
                        throw err;
                      }
                    })
                }
                mask={props => (
                  <DestinationForm
                    {...props}
                    id={this.props.match.params.id}
                    municipalityService={MunicipalityService}
                    editRoute={`/prospect/${this.props.match.params.id}/modify`}
                    history={this.props.history}
                    handleTitle={this.handleTitle}
                    mode="prospect"
                    user={this.props.user}
                    initialActiveTab={
                      this.props.history.location.state
                        ? this.props.history.location.state.activeIndex
                        : null
                    }
                  />
                )}
                afterCreate={res => {
                  if (res && res.id) {
                    return this.props.history.push(
                      `${API.PROSPECT}/${res.id}/modify`,
                    );
                  }
                  return null;
                }}
                afterUpdate={() => {}}
                afterDelete={() => this.props.history.push(`${API.PROSPECT}`)}
                validation={values =>
                  validation(values, this.context.intl, true)
                }
                onCleanData={handleCleanData}
                entityName="destination"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                onError={data => {
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.error),
                    message: _.get(
                      data,
                      'detail',
                      this.context.intl.formatMessage(
                        appMessages.an_error_has_occurred,
                      ),
                    ),
                    isError: true,
                  });
                }}
                onSuccess={() =>
                  addNotification({
                    title: this.context.intl.formatMessage(appMessages.success),
                    message: this.context.intl.formatMessage(
                      appMessages.operation_performed_successfully,
                    ),
                    isError: false,
                  })
                }
              />
            </>
          ) : (
            <ListWrapper>
              <ListManager
                ref={list => {
                  this.list = list;
                }}
                locale={_.get(this.props, 'user.defaultLanguage', 'it')}
                translations={{
                  createButton: {
                    label: this.context.intl.formatMessage(
                      messages.new_prospect,
                    ),
                  },
                }}
                sessionKey="prospect-destination"
                reloadIndex={this.state.reloadIndex}
                entityName="destination"
                routerName="prospect"
                basePath={API.BASE_URL}
                permissions={this.props.user}
                router={this.props}
                filterLabels={this.state.filterLabels}
                customSearchFilters={this.state.filters}
                searchedValue={this.state.searchedValue}
                searchFiler={this.state.searchFilter}
                onRemoveFilter={this.onRemoveFilter}
                onDefaultSearch={(val, filters) => {
                  this.setState({
                    searchFilter: filters,
                    searchedValue: val,
                  });
                }}
                client={pars => request(pars).then(res => res.data)}
                defaultOrder={[
                  {
                    type: 'field',
                    field: 'createdAt',
                    direction: 'desc',
                  },
                ]}
                canExport={{
                  exportLabelPrefix: this.context.intl.formatMessage(
                    appMessages.export_to,
                  ),
                  pdf: false,
                }}
                canCreate={{
                  active: PermissionManager.CanI('create', 'destination'),
                  label: <FormattedMessage {...messages.new_prospect} />,
                }}
                canSelect={{
                  active: false,
                  selectAll: false,
                  actions: [
                    // {
                    //   icon: 'download',
                    //   color: 'green',
                    //   label: this.context.intl.formatMessage(
                    //     messages.export_pdf,
                    //   ),
                    //   onClick: () => this.exportPDF(),
                    // },
                  ],
                }}
                aliases={this.state.aliases}
                canSearchCustom={AnagraficaSearchBar({
                  isProspect: true,
                  intl: this.context.intl,
                  filters: this.state.filters,
                  initialSearch: {},
                  removedFilter: this.state.removedFilter,
                  handleRemovedFilter: this.handleRemovedFilter,
                  initialValues: this.state.initialValues,
                  isUserAgent: this.state.isUserAgent,
                  userAgentId: this.state.userAgentId,
                  preSelectOnlyMine: this.state.isUserAgent,
                  onSearch: (filters, values, labels) =>
                    this.handleSearch(filters, values, labels),
                })}
                columns={[
                  {
                    key: 'code',
                    name: this.context.intl.formatMessage(
                      messages.prospectCode,
                    ),
                    searchable: true,
                    sortable: true,
                    formatter: ({ data }) => {
                      const { code } = data;

                      const state = _.get(
                        data,
                        'destinationState.description',
                        null,
                      );

                      return (
                        <>
                          {state && (
                            <Label ribbon color={getRibbonColor(state)}>
                              {this.context.intl.formatMessage(messages[state])}
                              <br />
                            </Label>
                          )}
                          <div style={{ marginTop: state ? '5px' : '0' }}>
                            {code}
                          </div>
                        </>
                      );
                    },
                    width: '10%',
                  },
                  {
                    key: 'businessName',
                    name: (
                      <span>
                        {this.context.intl.formatMessage(messages.businessName)}
                        <br />
                        {this.context.intl.formatMessage(messages.shopSign)}
                      </span>
                    ),
                    searchable: true,
                    sortable: true,
                    width: 200,
                    formatter: ({ data }) => {
                      const businessName =
                        _.get(data, 'companyName', '--') || '--';
                      const shopSign = _.get(data, 'businessName', '--');

                      return (
                        <span>
                          {businessName}
                          <br />
                          {shopSign}
                        </span>
                      );
                    },
                    exportFormatter: ({ data }) =>
                      _.get(data, 'businessName', '--'),
                  },
                  {
                    key: 'agent',
                    useAlias: 'agent',
                    filterName: 'displayName',
                    name: this.context.intl.formatMessage(messages.agent),
                    searchable: false,
                    sortable: true,
                    formatter: ({ data }) =>
                      _.get(data, 'agent1.displayName', '--'),
                    exportFormatter: ({ data }) =>
                      _.get(data, 'agent1.displayName', '--'),
                  },
                  {
                    key: 'businessAddressTitle',
                    name: this.context.intl.formatMessage(messages.address),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'phoneNumber',
                    name: this.context.intl.formatMessage(messages.phone),
                    searchable: true,
                    sortable: true,
                  },
                  {
                    key: 'lastAction',
                    name: this.context.intl.formatMessage(messages.lastAction),
                    searchable: true,
                    sortable: true,
                  },
                ]}
                defaultActions={{
                  visualize: PermissionManager.CanI('read', 'destination'),
                  modify: PermissionManager.CanI('edit', 'destination'),
                  delete: PermissionManager.CanI('remove', 'destination'),
                  grouped: false,
                  moreActions: [],
                }}
                disableInitialLoad={this.state.isUserAgent}
              />
            </ListWrapper>
          )}
        </Page>
      </>
    );
  }
}

Prospect.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  prospect: makeSelectProspect(),
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'prospect', reducer });
const withSaga = injectSaga({ key: 'prospect', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(Prospect);

Prospect.contextTypes = {
  intl: PropTypes.object.isRequired,
};
