/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from 'semantic-ui-react';
import CustomDropdown from './CustomDropdown';
import ExportManager from './ExportManager';
import Paginator from './Paginator';
import { TranslationsShape } from './Shapes';

const DefaultFooter = props => {
  const {
    pageSizeSelection,
    data,
    recordsPerPage,
    changeRecordsPerPage,
    changePage,
    isExport,
    handleExport,
    formatDataParams,
    exportParams,
    translations,
    customPaginator,
    page,
  } = props;

  const PaginatorComponent = customPaginator || Paginator;

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          {pageSizeSelection && (
            <CustomDropdown
              active
              onDropdownChange={changeRecordsPerPage}
              value={recordsPerPage}
              translations={translations}
            />
          )}
        </Grid.Column>
        <Grid.Column width={8} style={{ textAlign: 'center' }}>
          <PaginatorComponent page={page} data={data} changePage={changePage} />
        </Grid.Column>
        <Grid.Column
          width={4}
          style={{ textAlign: 'right', paddingLeft: 0, paddingRight: 0 }}
        >
          {isExport && (
            <ExportManager
              active={isExport}
              handleExport={(serviceName, extension) =>
                handleExport(formatDataParams(serviceName), extension)
              }
              exportParams={exportParams}
              translations={translations}
            />
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DefaultFooter.defaultProps = {
  pageSizeSelection: true,
  isExport: true,
  changeRecordsPerPage: null,
  changePage: null,
  handleExport: null,
  formatDataParams: null,
  exportParams: null,
  translations: {},
  customPaginator: null,
  page: null,
};

DefaultFooter.propTypes = {
  pageSizeSelection: PropTypes.bool,
  isExport: PropTypes.bool,
  data: PropTypes.any.isRequired,
  recordsPerPage: PropTypes.number.isRequired,
  changeRecordsPerPage: PropTypes.func,
  changePage: PropTypes.func,
  handleExport: PropTypes.func,
  formatDataParams: PropTypes.func,
  exportParams: PropTypes.object,
  translations: TranslationsShape,
  customPaginator: PropTypes.node,
  page: PropTypes.number,
};

export default DefaultFooter;
