import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Grid, Form } from 'semantic-ui-react';
import SimpleFormikField from 'novigo-simple-formik-field';
import FormikDate from '../../FormikDate';
import { PaddedColumn } from '../../Layout';
import FormActionsBar from '../../FormActionsBar';
import messages from './messages';
import validation from './validation';

function PriceListMasterForm(props) {
  const { isNew } = props;

  return (
    <Form onSubmit={props.handleSubmit} loading={props.isSubmitting}>
      <Grid columns="equal">
        <Grid.Row columns={2}>
          {!isNew && (
            <PaddedColumn>
              <SimpleFormikField
                {...props}
                name="code"
                readOnly
                label={props.intl.formatMessage(messages.code)}
              />
            </PaddedColumn>
          )}
          <PaddedColumn>
            <SimpleFormikField
              {...props}
              name="description"
              label={props.intl.formatMessage(messages.description)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikDate
              {...props}
              name="startDate"
              label={props.intl.formatMessage(messages.startDate)}
            />
          </PaddedColumn>
          <PaddedColumn>
            <FormikDate
              {...props}
              name="endDate"
              label={props.intl.formatMessage(messages.endDate)}
            />
          </PaddedColumn>
        </Grid.Row>
      </Grid>
      <FormActionsBar {...props} />
    </Form>
  );
}

PriceListMasterForm.propTypes = {
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  intl: intlShape.isRequired,
  isNew: PropTypes.bool.isRequired,
};

export default injectIntl(PriceListMasterForm);

export { validation };
