/*
 * ChooseProductType Messages
 *
 * This contains all the text for the ChooseProductType component.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.ContractForm';

export default defineMessages({
  generaldata: {
    id: `${scope}.generaldata`,
    defaultMessage: 'General data',
  },
  generalnotes: {
    id: `${scope}.generalnotes`,
    defaultMessage: 'General notes',
  },
  // Persona fisica
  natural_person: {
    id: `${scope}.natural_person`,
    defaultMessage: 'Natural person',
  },
  legal_person: {
    id: `${scope}.legal_person`,
    defaultMessage: 'Legal person',
  },
  personal_data: {
    id: `${scope}.personal_data`,
    defaultMessage: 'Personal data',
  },
  contacts: {
    id: `${scope}.contacts`,
    defaultMessage: 'Contacts',
  },
  documents: {
    id: `${scope}.documents`,
    defaultMessage: 'Documents',
  },
  notes: {
    id: `${scope}.notes`,
    defaultMessage: 'Notes',
  },
  company_data: {
    id: `${scope}.company_data`,
    defaultMessage: 'Company data',
  },
  phone_number: {
    id: `${scope}.phone_number`,
    defaultMessage: 'Phone number',
  },
  mobile_number: {
    id: `${scope}.mobile_number`,
    defaultMessage: 'Mobile number',
  },
  web_site: {
    id: `${scope}.web_site`,
    defaultMessage: 'Web site',
  },
  communications: {
    id: `${scope}.communications`,
    defaultMessage: 'Communications',
  },
  payment: {
    id: `${scope}.payment`,
    defaultMessage: 'Payment',
  },
  payment_codition: {
    id: `${scope}.payment_condition`,
    defaultMessage: 'Payment terms',
  },
  payment_charge: {
    id: `${scope}.payment_charge`,
    defaultMessage: 'Payment fees charge',
  },
  tax_rate: {
    id: `${scope}.tax_rate`,
    defaultMessage: 'Tax rate',
  },
  credit_limit: {
    id: `${scope}.credit_limit`,
    defaultMessage: 'Credit limit',
  },
  customer_bank: {
    id: `${scope}.customer_bank`,
    defaultMessage: 'Customer bank',
  },
  company_name: {
    id: `${scope}.company_name`,
    defaultMessage: 'Company name',
  },
  vat_number: {
    id: `${scope}.vat_number`,
    defaultMessage: 'Vat number',
  },
  registrationNumber: {
    id: `${scope}.registrationNumber`,
    defaultMessage: 'Employee Number',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  surname: {
    id: `${scope}.surname`,
    defaultMessage: 'Surname',
  },
  city_of_birth: {
    id: `${scope}.city_of_birth`,
    defaultMessage: 'City of birth',
  },
  date_of_birth: {
    id: `${scope}.date_of_birth`,
    defaultMessage: 'Date of birth',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'Gender',
  },
  citizenship: {
    id: `${scope}.citizenship`,
    defaultMessage: 'Citizenship',
  },
  fiscal_domicile: {
    id: `${scope}.fiscal_domicile`,
    defaultMessage: 'Fisacal domicile',
  },
  fiscal_code: {
    id: `${scope}.fiscal_code`,
    defaultMessage: 'Fiscal code',
  },
  cf_error: {
    id: `${scope}.cf_error`,
    defaultMessage:
      'Verify that you have entered: first name, last name, date of birth, city of birth and gender.',
  },
  resident: {
    id: `${scope}.resident`,
    defaultMessage: 'Resident',
  },
  not_resident: {
    id: `${scope}.not_resident`,
    defaultMessage: 'Non resident',
  },
  contact_type: {
    id: `${scope}.contact_type`,
    defaultMessage: 'Contact type',
  },
  nominative: {
    id: `${scope}.nominative`,
    defaultMessage: 'Nominative',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Phone',
  },
  mobile: {
    id: `${scope}.mobile`,
    defaultMessage: 'Mobile',
  },
  contact_removed_success: {
    id: `${scope}.contact_removed_success`,
    defaultMessage: 'Contact successfully removed.',
  },
  note_removed_success: {
    id: `${scope}.note_removed_success`,
    defaultMessage: 'Note successfully removed.',
  },
  new_contact: {
    id: `${scope}.new_contact`,
    defaultMessage: 'New contact',
  },
  document_type: {
    id: `${scope}.document_type`,
    defaultMessage: 'Document type',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  release_date: {
    id: `${scope}.release_date`,
    defaultMessage: 'Release date',
  },
  expire_date: {
    id: `${scope}.expire_date`,
    defaultMessage: 'Expire date',
  },
  new_document: {
    id: `${scope}.new_document`,
    defaultMessage: 'New document',
  },
  upload: {
    id: `${scope}.upload`,
    defaultMessage: 'Upload',
  },
  document_description_placeholder: {
    id: `${scope}.document_description_placeholder`,
    defaultMessage: 'Document description....',
  },
  note: {
    id: `${scope}.note`,
    defaultMessage: 'Note',
  },
  send_mail_to: {
    id: `${scope}.send_mail_to`,
    defaultMessage: 'Send mail to',
  },
  new_note: {
    id: `${scope}.new_note`,
    defaultMessage: 'New note',
  },
  note_placeholder: {
    id: `${scope}.note_placeholder`,
    defaultMessage: 'Type your note....',
  },
  note_type: {
    id: `${scope}.note_type`,
    defaultMessage: 'Note Type',
  },
  note_motivation: {
    id: `${scope}.note_motivation`,
    defaultMessage: 'Motivation',
  },
  talked_to: {
    id: `${scope}.talked_to`,
    defaultMessage: 'Talked to',
  },
  registration_number: {
    id: `${scope}.registration_number`,
    defaultMessage: 'Registration number',
  },
  department: {
    id: `${scope}.department`,
    defaultMessage: 'Department',
  },
  role: {
    id: `${scope}.role`,
    defaultMessage: 'Role',
  },
  date: {
    id: `${scope}.date`,
    defaultMessage: 'Date',
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: 'User',
  },
  parent: {
    id: `${scope}.parent`,
    defaultMessage: 'Parent',
  },
  customer_code: {
    id: `${scope}.customer_code`,
    defaultMessage: 'Customer code',
  },
  commissions: {
    id: `${scope}.commissions`,
    defaultMessage: 'Commissions',
  },
  new_commission: {
    id: `${scope}.new_commission`,
    defaultMessage: 'New commission',
  },
  tab_general_data: {
    id: `${scope}.tab_general_data`,
    defaultMessage: 'General data',
  },
  tab_destinations: {
    id: `${scope}.tab_destination`,
    defaultMessage: 'Destinations',
  },
  customerCode: {
    id: `${scope}.customerCode`,
    defaultMessage: 'Cust. Code',
  },
  destinationState: {
    id: `${scope}.destinationState`,
    defaultMessage: 'Dest. State',
  },
  destinationCode: {
    id: `${scope}.destinationCode`,
    defaultMessage: 'Dest. Code',
  },
  destinationArea: {
    id: `${scope}.destinationArea`,
    defaultMessage: 'Dest. Area',
  },
  businessName: {
    id: `${scope}.businessName`,
    defaultMessage: 'Shop Sign',
  },
  destinationLogisticArea: {
    id: `${scope}.destinationLogisticArea`,
    defaultMessage: 'Customer Dest. Logistic Area',
  },
  birthCity: {
    id: `${scope}.birthCity`,
    defaultMessage: 'City of birth',
  },
  new_destination: {
    id: `${scope}.new_destination`,
    defaultMessage: 'New destination',
  },
  noteNumber: {
    id: `${scope}.noteNumber`,
    defaultMessage: 'Note number',
  },
});
