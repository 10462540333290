/*
 * Customer note Messages
 *
 * This contains all the text for the Customer note container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.ProductCommission';

export default defineMessages({
  commission_saved: {
    id: `${scope}.commission_saved`,
    defaultMessage: 'Commission successfully saved',
  },
  commissionStartDate: {
    id: `${scope}.commissionStartDate`,
    defaultMessage: 'Valid from',
  },
  commissionEndDate: {
    id: `${scope}.commissionEndDate`,
    defaultMessage: 'Valid to',
  },
  commissionFrom: {
    id: `${scope}.commissionFrom`,
    defaultMessage: 'From',
  },
  commissionTo: {
    id: `${scope}.commissionTo`,
    defaultMessage: 'To',
  },
  commission: {
    id: `${scope}.commission`,
    defaultMessage: 'Commission',
  },
  refund: {
    id: `${scope}.refund`,
    defaultMessage: 'Refund',
  },
  award: {
    id: `${scope}.award`,
    defaultMessage: 'Included in bonus [calculation]',
  },
  discountFrom: {
    id: `${scope}.discountFrom`,
    defaultMessage: 'Discount from',
  },
  discountTo: {
    id: `${scope}.discountTo`,
    defaultMessage: 'Discount to',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  flat_unit: {
    id: `${scope}.flat_unit`,
    defaultMessage: 'Flat unit',
  },
  fromPercentage: {
    id: `${scope}.fromPercentage`,
    defaultMessage: 'Percentage from',
  },
  toPercentage: {
    id: `${scope}.toPercentage`,
    defaultMessage: 'Percentage to',
  },
  product: {
    id: `${scope}.product`,
    defaultMessage: 'Product',
  },
});
