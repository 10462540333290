/*
 * ProductCategoryForm Messages
 *
 * This contains all the text for ProductCategoryForm.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Form.Agenda';

export default defineMessages({
  event_saved: {
    id: `${scope}.event_saved`,
    defaultMessage: 'Event saved with success.',
  },
  customer: {
    id: `${scope}.customer`,
    defaultMessage: 'Customer',
  },
  agent: {
    id: `${scope}.agent`,
    defaultMessage: 'Agent',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Event description',
  },
  description_placeholder: {
    id: `${scope}.description_placeholder`,
    defaultMessage: 'Insert the event description',
  },
  start_date: {
    id: `${scope}.start_date`,
    defaultMessage: 'Event start',
  },
  end_date: {
    id: `${scope}.end_date`,
    defaultMessage: 'Event end',
  },
  subject: {
    id: `${scope}.subject`,
    defaultMessage: 'Subject',
  },
  event_date_changed_success: {
    id: `${scope}.event_date_changed_success`,
    defaultMessage: 'Event date changed successfully.',
  },
  appointment: {
    id: `${scope}.appointment`,
    defaultMessage: 'Appointment',
  },
  workorder: {
    id: `${scope}.workorder`,
    defaultMessage: 'Intervention',
  },
  number: {
    id: `${scope}.number`,
    defaultMessage: 'Number',
  },
  technical: {
    id: `${scope}.technical`,
    defaultMessage: 'Technical',
  },
  destination: {
    id: `${scope}.destination`,
    defaultMessage: 'Destination',
  },
  referent: {
    id: `${scope}.referent`,
    defaultMessage: 'Referent',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  typeODL: {
    id: `${scope}.typeODL`,
    defaultMessage: 'ODL Type',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  planned: {
    id: `${scope}.planned`,
    defaultMessage: 'Planned date',
  },
});
