import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Dropdown, Button } from 'semantic-ui-react';
import { injectIntl, intlShape } from 'react-intl';
import {
  SmallCircularActionBtn,
  ExecuteBtn,
} from '../../../components/Buttons';
import appMessages from '../../App/messages';

const ListActions = props => {
  const history = useHistory();
  const {
    intl,
    canView = false,
    onView = () => null,
    canEdit = false,
    onEdit = () => null,
    canManage = false,
    onManage = () => null,
    canDelete = false,
    onDelete = () => null,
    canExecute = false,
    onExecute,
    isToDo = false,
    canEditWorkorder = false,
    onEditWorkorder = () => null,
    isExecutingNote = false,
  } = props;

  return (
    <React.Fragment>
      {/* execute */}
      {canExecute && (
        <ExecuteBtn loading={isExecutingNote} action={onExecute} />
      )}
      {isToDo && (
        <Button
          loading={isExecutingNote}
          small
          circular
          icon="redo"
          color="yellow"
          onClick={() => onExecute(isToDo)}
        />
      )}
      {/* view */}
      {canView && (
        <SmallCircularActionBtn icon="eye" color="blue" action={onView} />
      )}
      {/* edit */}
      {canEdit && (
        <SmallCircularActionBtn
          icon="pencil alternate"
          color="blue"
          action={onEdit}
        />
      )}
      {/* edit */}
      {canEditWorkorder && (
        <SmallCircularActionBtn
          icon="wrench"
          color="blue"
          action={onEditWorkorder}
        />
      )}
      {/* dont show button if there is no permission for both functions */}
      {!canDelete && !canManage ? null : (
        <Button
          as="div"
          circular
          style={{ color: '#fff', padding: 0, width: '36px', height: '36px' }}
          primary
        >
          <div
            style={{
              position: 'relative',
              height: '100%',
              paddingTop: 10,
              paddingLeft: 3,
            }}
          >
            <Dropdown icon="ellipsis vertical">
              <Dropdown.Menu style={{ right: 0, left: 'auto' }}>
                {canDelete && (
                  <Dropdown.Item
                    key="delete"
                    icon="trash"
                    text={intl.formatMessage(appMessages.delete)}
                    color="red"
                    onClick={onDelete}
                    style={{ color: 'red' }}
                  />
                )}
                {canManage && (
                  <Dropdown.Item
                    key="manage"
                    icon="edit outline"
                    text={intl.formatMessage(appMessages.manage)}
                    onClick={() => onManage({ history })}
                  />
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Button>
      )}
    </React.Fragment>
  );
};

ListActions.propTypes = {
  onView: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onEditWorkorder: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onManage: PropTypes.func.isRequired,
  onExecute: PropTypes.func.isRequired,
  canView: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canEditWorkorder: PropTypes.bool.isRequired,
  canManage: PropTypes.bool.isRequired,
  isToDo: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canExecute: PropTypes.bool.isRequired,
  intl: PropTypes.shape(intlShape),
  isExecutingNote: PropTypes.bool,
};

export default injectIntl(ListActions);
