/* eslint-disable indent */
import _ from 'lodash';
import qs from 'qs';
import request from '../request';
import { API } from '../../../global-constants';

const getAttachmentTypes = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.ATTACHMENT_TYPE,
    method: 'GET',
    params: {},
  });

const getAttachmentTypeOptions = () =>
  getAttachmentTypes().then(res =>
    _.get(res, 'data._embedded.attachment_type', [])
      .filter(type => type.category === 'Product')
      .map(type => ({
        label: type.description,
        value: type.id,
      })),
  );

const create = content =>
  request({
    baseURL: API.BASE_URL,
    url: API.PRODUCT_ATTACHMENT,
    method: 'POST',
    data: content,
  });

const update = content =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_ATTACHMENT}/${content.id}`,
    method: 'PUT',
    data: content,
  });

const remove = id =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_ATTACHMENT}/${id}`,
    method: 'DELETE',
  });

const getByOwner = (productId, page) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_ATTACHMENT}`,
    method: 'GET',
    params: {
      page: page || 1,
      pageSize: 5,
      filter: [
        {
          type: 'eq',
          field: 'product',
          value: productId,
        },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getAttachmentsForId = ids =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_ATTACHMENT}`,
    method: 'GET',
    params: {
      page: 1,
      pageSize: -1,
      filter: [
        { type: 'innerjoin', field: 'product', alias: 'prod' },
        {
          type: 'in',
          field: 'id',
          values: Array.isArray(ids) && ids.length > 0 ? ids : [ids],
          alias: 'prod',
        },
        { type: 'innerjoin', field: 'attachmentTypeId', alias: 'att' },
        // {
        //   type: 'eq',
        //   field: 'description',
        //   values: 'Immagine App',
        //   alias: 'att',
        // },
      ],
    },
    paramsSerializer: params => qs.stringify(params),
  });

const getMultipleByIds = (ids, getTechnicalData = false) =>
  request({
    baseURL: API.BASE_URL,
    url: `${API.PRODUCT_ATTACHMENT}`,
    method: 'GET',
    params: {
      page: 1,
      pageSize: -1,
      filter: [
        { type: 'innerjoin', field: 'product', alias: 'prod' },
        {
          type: 'in',
          field: 'id',
          values: ids,
          alias: 'prod',
        },
        { type: 'innerjoin', field: 'attachmentTypeId', alias: 'att' },
        getTechnicalData
          ? {
              type: 'in',
              field: 'description',
              values: ['Immagine App', 'Scheda Tecnica'],
              alias: 'att',
            }
          : {
              type: 'eq',
              field: 'description',
              values: 'Immagine App',
              alias: 'att',
              where: 'or',
            },
      ].filter(o => o),
    },
    paramsSerializer: params => qs.stringify(params),
  });

const ProductAttachmentService = {
  getAttachmentTypes,
  getAttachmentTypeOptions,
  getByOwner,
  getMultipleByIds,
  create,
  update,
  remove,
  getAttachmentsForId,
};

export default ProductAttachmentService;
