import request from 'shared/services/request';
import { API } from '../../../global-constants';

const getMyself = () =>
  request({
    baseURL: API.BASE_URL,
    url: API.MYSELF,
    method: 'GET',
  });

const changePassword = data =>
  request({
    baseURL: API.BASE_URL,
    url: API.USER_MANAGER,
    method: 'POST',
    data: {
      method: 'change_password',
      params: {
        plainPasswordCurrent: data.plainPasswordCurrent,
        plainPasswordNew: data.plainPasswordNew,
        plainPasswordNewCheck: data.plainPasswordNewCheck,
      },
    },
  });

const requestPasswordReset = data => {
  const username = data.username ? data.username : data;
  return request({
    baseURL: API.BASE_URL,
    url: API.PASSWORD_RECOVERY,
    method: 'POST',
    data: {
      method: 'request_recovery',
      params: {
        username,
      },
    },
  });
};

const setNewPassword = ({ token, plainPassword, plainPasswordCheck }) =>
  request({
    baseURL: API.BASE_URL,
    url: API.PASSWORD_RECOVERY,
    method: 'POST',
    data: {
      method: 'reset_password',
      params: {
        token,
        plainPassword,
        plainPasswordCheck,
      },
    },
  });

const ParcelService = {
  getMyself,
  changePassword,
  requestPasswordReset,
  setNewPassword,
};

export default ParcelService;
